<template>
    <v-dialog scrollable v-model="dialog" :persistent="!viewDetailsStatus" max-width="600">
        <v-card>
            <v-card-title class="py-2">
                <v-row justify="space-between">
                    <v-col cols="auto">
                        Cash Out {{ headerExtension }}
                    </v-col>
                    <v-col cols="auto" class="text-right pe-1">
                    <v-btn
                        icon
                        @click="cancelAction"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text class="py-4">
                <v-form ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="approve">

                    <v-row dense>
                        <v-col cols="12">
                            <div>
                                <template>
                                    <!-- <v-simple-table>
                                        <template v-slot:default>
                                        <tbody>
                                            <tr>
                                                <th>Name</th>
                                                <td>{{ usersData.player_name }}</td>
                                            </tr>
                                            <tr>
                                                <th>Payment Method</th>
                                                <td>{{ usersData.bank }}</td>
                                            </tr>
                                            <tr>
                                                <th>Receiver Account</th>
                                                <td>{{ usersData.receiver_account }}</td>
                                            </tr>
                                            <tr>
                                                <th>Account Name</th>
                                                <td>{{ usersData.account_name }}</td>
                                            </tr>
                                            <tr>
                                                <th>Account Number</th>
                                                <td>{{ usersData.account_number }}</td>
                                            </tr>
                                            <tr>
                                                <th>Reference Number</th>
                                                <td>{{ usersData.reference_number }}</td>
                                            </tr>
                                            <tr>
                                                <th>Amount</th>
                                                <td>{{ usersData.amount }}</td>
                                            </tr>
                                            <tr v-if="viewDetailsStatus">
                                                <th>Remarks</th>
                                                <td>{{ usersData.remarks }}</td>
                                            </tr>
                                        </tbody>
                                        </template>
                                    </v-simple-table> -->

                                    <v-simple-table dense>
                                        <template v-slot:default>
                                        <tbody class="table-borderless">
                                            <tr>
                                                <td style="width: 40%" class="grey--text">Player Name</td>
                                                <td>
                                                    <span>{{ usersData.player_name }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 40%" class="grey--text">Player Mobile Number</td>
                                                <td>
                                                    <span>0{{ usersData.mobile_number }}</span>
                                                </td>
                                            </tr>
                                            <!-- <tr>
                                                <td style="width: 40%" class="grey--text">Player Type</td>
                                                <td>
                                                    <span>{{ usersData.player_type }}</span>
                                                </td>
                                            </tr> -->
                                            <tr v-if="!viewDetailsStatus">
                                                <td style="width: 40%" class="grey--text">Wallet Token</td>
                                                <td>
                                                    <span>{{ moneyFormat(parseFloat(usersData.wallet)) }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 40%" class="grey--text">Cash Out Type</td>
                                                <td>
                                                    <span>{{ usersData.type }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 40%" class="grey--text">Bank</td>
                                                <td>
                                                    <span>{{ usersData.bank }}</span>
                                                </td>
                                            </tr>
                                            <!-- <tr>
                                                <td style="width: 40%" class="grey--text">Receiver Account</td>
                                                <td>
                                                    <span>{{ usersData.receiver_account }}</span>
                                                </td>
                                            </tr> -->
                                            <tr>
                                                <td style="width: 40%" class="grey--text">Account Name</td>
                                                <td>
                                                    <span>{{ usersData.account_name }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 40%" class="grey--text">Account Number</td>
                                                <td>
                                                    <span>{{ usersData.account_number }}</span>
                                                </td>
                                            </tr>
                                            <!-- <tr>
                                                <td style="width: 40%" class="grey--text">Reference Number</td>
                                                <td>
                                                    <span>{{ usersData.reference_number }}</span>
                                                </td>
                                            </tr> -->
                                            <tr>
                                                <td style="width: 40%" class="grey--text">Token</td>
                                                <td>
                                                    <span>{{ moneyFormat(parseFloat(usersData.amount)) }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 40%" class="grey--text">Notes</td>
                                                <td>
                                                    <span v-if="usersData.notes">{{ usersData.notes }}</span>
                                                    <span v-else>-</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 40%" class="grey--text">Requested Date</td>
                                                <td>
                                                    <span>{{ formatTheDate(usersData.created_at) }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 40%" class="grey--text">Status</td>
                                                <td>
                                                    <v-chip
                                                    small
                                                    
                                                    :class="(usersData.status=='Approved' || usersData.status=='Verified') ? 'green lighten-5 green--text' : ((usersData.status=='Pending') ? 'orange lighten-5 orange--text' : 'red lighten-5 red--text')"
                                                    >
                                                    {{ usersData.status }}
                                                    </v-chip>
                                                </td>
                                            </tr>
                                            <!-- <tr v-if="viewDetailsStatus">
                                                <td style="width: 40%" class="grey--text">Evaluated By</td>
                                                <td>
                                                    <span v-if="usersData.evaluated_by">{{ usersData.evaluated_by }}</span>
                                                    <span v-else>-</span>
                                                </td>
                                            </tr> -->
                                            <tr v-if="viewDetailsStatus">
                                                <td style="width: 40%" class="grey--text">Evaluated Date</td>
                                                <td>
                                                    <span v-if="usersData.evaluated_at">{{ formatTheDate(usersData.evaluated_at) }}</span>
                                                    <span v-else>-</span>
                                                </td>
                                            </tr>
                                            <tr v-if="viewDetailsStatus">
                                                <td style="width: 40%" class="grey--text">Remarks</td>
                                                <td>
                                                    <span v-if="usersData.remarks">{{ usersData.remarks }}</span>
                                                    <span v-else>-</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                        </template>
                                    </v-simple-table>
                                </template>
                            </div>

                            <v-row dense v-if="!viewDetailsStatus">
                                <v-col cols="12" class="mt-2">
                                    <v-textarea label="Remarks"
                                        no-resize
                                        outlined
                                        rows="3"
                                        hide-details="auto"
                                        v-model="remarks"
                                        :readonly="remarksReadOnly"
                                        ></v-textarea>
                                </v-col>
                            </v-row>

                            <v-row dense v-if="actionVisibility">
                                <v-col cols="12" md="4">
                                    <v-btn block color="success" type="submit">
                                        {{ action }}
                                    </v-btn>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-btn block color="error" @click="disapprove">
                                        {{ (action == 'Verify') ? 'Reject' : 'Disapproved' }}
                                    </v-btn>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-btn block depressed @click="cancelAction">
                                        Cancel
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-dialog v-model="dialogStatus" max-width="400">
            <v-card class="py-2">
                <v-card-title class="text-h6 d-block text-center">
                    Confirm {{ dialogText }}
                </v-card-title>

                <v-card-text class="text-center">
                    Do you really want to {{ dialogText }}?
                </v-card-text>

                <v-card-actions class="d-block text-center">
                    <v-btn ref="approveYes"
                        color="primary"
                        outlined
                        @click="handleDialogAction"
                        :loading="loading">
                        Yes
                    </v-btn>

                    <v-btn color="black"
                        outlined
                        @click="dialogStatus = false">
                        No
                    </v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex"
import formatDate from '@/helpers/formatDate'

export default {
    props: {
        usersData: {
            type: Object,
        },
        viewDetailsStatus: {
            type: Boolean
        },
        action: {
            type: String
        },
    },
    data: () => ({
        valid: true,
        dialogStatus: false,
        dialogText: '',
        remarks: '',
        data: [],
        headerExtension: 'Validation',
        actionVisibility: true,
        remarksReadOnly: false,
    }),
    watch: {
        dialogStatus(visible) {
            if (visible) {
                setTimeout(() => {
                this.$refs.approveYes.$el.focus()
                }, 200);
            }
        },
    },
    mounted() {
        if (this.viewDetailsStatus) {
            this.headerExtension = 'Details'
            this.actionVisibility = false
            this.remarksReadOnly = true
        } else {
            this.headerExtension = 'Validation'
            this.actionVisibility = true
            this.remarksReadOnly = false
            this.remarks = this.usersData.remarks
        }
    },
    computed: {
        ...mapState("cashout", ["loading", "error", "errors"]),
        dialog: {
            get: function () {
                return true;
            },
            set: function () {
                this.closeModal();
            },
        },
    },
    methods: {
        ...mapActions("cashout", ["verifyCashout", "rejectCashout", "approveCashout", "disapproveCashout"]),
        formatTheDate(theDate) {
            return formatDate(theDate, true)
        },
        approve() {
            this.dialogStatus = true
            this.dialogText = this.action
        },
        disapprove() {
            this.dialogStatus = true
            this.dialogText = (this.action == 'Verify') ? "Reject" : "Disapprove"
        },
        cancelAction() {
            this.data = {
                "action": "",
                "playerId": 0
            }
            this.closeModal()
        },
        closeModal() {
            this.$emit("closeModal", this.data)
        },
        async handleDialogAction() {
            switch(this.dialogText) {
                case "Verify": {
                    const formData = new FormData()
                    formData.append('cashout_id', this.usersData.id)
                    formData.append('remarks', this.remarks)

                    if (!this.approveLoading) {
                        await this.verifyCashout(formData)

                        if (!this.approveError) {
                            this.dialogStatus = false
                            this.data = {
                                "action": "Verify",
                                "playerId": this.usersData.id
                            }
                            this.closeModal()
                        }
                    }
                break;
                }
                case "Approve": {
                    const formData = new FormData()
                    formData.append('cashout_id', this.usersData.id)
                    formData.append('remarks', this.remarks)

                    if (!this.approveLoading) {
                        await this.approveCashout(formData)

                        if (!this.approveError) {
                            this.dialogStatus = false
                            this.data = {
                                "action": "Approve",
                                "playerId": this.usersData.id
                            }
                            this.closeModal()
                        }
                    }
                break;
                }
                case "Reject": {
                    const formData2 = new FormData()
                    formData2.append('cashout_id', this.usersData.id)
                    formData2.append('remarks', this.remarks)

                    if (!this.disapproveLoading) {
                        await this.rejectCashout(formData2)

                        if (!this.disapproveError) {
                            this.dialogStatus = false
                            this.data = {
                                "action": "Rejected",
                                "playerId": this.usersData.id
                            }
                            this.closeModal()
                        }
                    }
                break;
                }                         
                case "Disapprove": {
                    const formData2 = new FormData()
                    formData2.append('cashout_id', this.usersData.id)
                    formData2.append('remarks', this.remarks)

                    if (!this.disapproveLoading) {
                        await this.disapproveCashout(formData2)

                        if (!this.disapproveError) {
                            this.dialogStatus = false
                            this.data = {
                                "action": "Disapprove",
                                "playerId": this.usersData.id
                            }
                            this.closeModal()
                        }
                    }
                break;
                }
                    
            }
        },

        moneyFormat(val) {
            return val.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            })
        }
    }
}
</script>