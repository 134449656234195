<template>
	<div class="pa-4 pa-md-6">
		<v-card>
			<v-progress-linear v-if="userInfoLoading" indeterminate color="indigo" />
			<v-card-title> Update User's Information </v-card-title>

			<v-divider></v-divider>

			<v-card-text class="py-4">
				<v-form ref="form" v-model="valid" lazy-validation @submit.prevent="handleSubmit" :disabled="formAvailability">
					<v-row dense>
						<v-col cols="12" md="4">
							<v-subheader class="pa-0">First Name</v-subheader>
							<v-text-field
								ref="first_name"
								outlined
								dense
								:rules="[rules.required, rules.alphaSpace, rules.minimum(2)]"
								v-model="first_name"
								hide-details="auto" />
						</v-col>

						<v-col cols="12" md="4">
							<v-subheader class="pa-0">Middle Name</v-subheader>
							<v-text-field
								ref="middle_name (Optional)"
								outlined
								dense
								:rules="[rules.alphaSpace, rules.minimum(2)]"
								v-model="middle_name"
								hide-details="auto" />
						</v-col>

						<v-col cols="12" md="4">
							<v-subheader class="pa-0">Last Name</v-subheader>
							<v-text-field
								ref="last_name"
								outlined
								dense
								:rules="[rules.required, rules.alphaSpace, rules.minimum(2)]"
								v-model="last_name"
								hide-details="auto" />
						</v-col>
					</v-row>

					<v-row dense>
						<v-col cols="12" md="4">
							<v-subheader class="pa-0">Mobile Number</v-subheader>
							<v-text-field
								ref="mobile_number"
								outlined
								dense
								:rules="[rules.required, rules.validMobileNumber, mobileNumberExist]"
								maxlength="10"
								v-model="mobile_number"
								hide-details="auto"
								prefix="+63"
								@keyup="removeMobileNumberLeadingZero" />
						</v-col>

						<v-col cols="12" md="4">
							<v-subheader class="pa-0">Email Address</v-subheader>
							<v-text-field
								ref="email_address"
								outlined
								dense
								:rules="[rules.required, rules.validEmail, emailAddressExist]"
								v-model="email_address"
								hide-details="auto" />
						</v-col>

						<v-col cols="12" md="4">
							<v-subheader class="pa-0">Birthday Date</v-subheader>
							<v-menu
								ref="birthdate_menu"
								v-model="birthdate_menu"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
								min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="birthDate"
										:rules="[rules.required]"
										dense
										outlined
										readonly
										v-bind="attrs"
										v-on="on"
										@keyup="birthdate_menu = true"
										@keydown="birthdate_menu = false"
										hide-details="auto" />
								</template>

								<v-date-picker
									v-model="birthDate"
									:active-picker.sync="birthdateActivePicker"
									:max="maxBirthdate()"
									:min="minBirthdate()"
									no-title
									@change="$refs.birthdate_menu.save(birthDate)" />
							</v-menu>
						</v-col>
					</v-row>

					<v-row class="my-8 pl-4">
						<h2>User Access</h2>
					</v-row>

					<!-- DASHBOARD -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="Dashboard"
										v-model="module_dashboard"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										@click="setDashboardModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Dashboard"
														color="indigo"
														value="16"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Dashboard Level A"
														color="indigo"
														value="21"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<!-- AGENT LIST -->
					<!-- <v-row dense>
            <v-col>
              <v-card>
                <v-card-title>
                  <v-checkbox
                    label="Agent List"
                    color="indigo"
                    hide-details
                    class="d-inline-block ma-0 pa-0"
                    v-model="module_agentlist"
                    @click="setAgentListModule"
                  ></v-checkbox>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" md="3">
                      <v-card>
                        <v-card-title>
                          <v-checkbox
                            v-model="access"
                            label="Agent List"
                            color="indigo"
                            value="41"
                            hide-details
                            class="d-inline-block"
                          ></v-checkbox>
                        </v-card-title>
                      </v-card>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-card>
                        <v-card-title>
                          <v-checkbox
                            v-model="access"
                            label="Create Agent"
                            color="indigo"
                            value="42"
                            hide-details
                            class="d-inline-block"
                          ></v-checkbox>
                        </v-card-title>
                      </v-card>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-card>
                        <v-card-title>
                          <v-checkbox
                            v-model="access"
                            label="Commission Payments"
                            color="indigo"
                            value="54"
                            hide-details
                            class="d-inline-block"
                          ></v-checkbox>
                        </v-card-title>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row> -->

					<!-- PLAYER LIST -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="Player List"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										v-model="module_playerlist"
										@click="setPlayerListModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Player List"
														color="indigo"
														value="1"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Player Verify"
														color="indigo"
														value="23"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Player Verifier"
														color="indigo"
														value="40"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Player Setting"
														color="indigo"
														value="24"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<!-- CASHIN -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="Cash In"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										v-model="module_cashin"
										@click="setCashinModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Cash In"
														color="indigo"
														value="2"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Verify"
														color="indigo"
														value="52"
														hide-details
														class="d-inline-block"
														@click="setCashinVerify"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Approve"
														color="indigo"
														value="26"
														hide-details
														class="d-inline-block"
														@click="setCashinApprove"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Manual"
														color="indigo"
														value="28"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Verifier"
														color="indigo"
														value="38"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<!-- CASHOUT -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="Cash Out"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										v-model="module_cashout"
										@click="setCashoutModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Cash Out"
														color="indigo"
														value="3"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Verify"
														color="indigo"
														value="53"
														hide-details
														class="d-inline-block"
														@click="setCashoutVerify"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Approve"
														color="indigo"
														value="29"
														hide-details
														class="d-inline-block"
														@click="setCashoutApprove"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Manual"
														color="indigo"
														value="31"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Verifier"
														color="indigo"
														value="39"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<!-- LUCKY FOUR -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="Lucky Four"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										v-model="module_lucky_four"
										@click="setLuckyFourModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Lucky Four"
														color="indigo"
														value="59"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Enable"
														color="indigo"
														value="60"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Verify"
														color="indigo"
														value="61"
														hide-details
														class="d-inline-block"
														@click="setLuckyFourVerify"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Approve"
														color="indigo"
														value="62"
														hide-details
														class="d-inline-block"
														@click="setLuckyFourApprove"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Prize Settings"
														color="indigo"
														value="63"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<!-- ENDING GAMES -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="Ending Games"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										v-model="module_endinggames"
										@click="setEndingGamesModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="4">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ending Games"
														color="indigo"
														value="17"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="4">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ending Game Winner Verification"
														color="indigo"
														value="8"
														hide-details
														class="d-inline-block"
														@click="setWinnerVerification"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="4">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ending Game Winner Approval 1"
														color="indigo"
														value="9"
														hide-details
														class="d-inline-block"
														@click="setWinnerApproval"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>

									<v-row dense>
										<v-col cols="12" md="4">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ending Game Settings"
														color="indigo"
														value="10"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="4">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ending Game Result Creation"
														color="indigo"
														value="11"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="4">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ending Game Bet/Cards Creation"
														color="indigo"
														value="12"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>

									<v-row dense>
										<v-col cols="12" md="4">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ending Game Create"
														color="indigo"
														value="32"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="4">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ending Game PDF Generation"
														color="indigo"
														value="33"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="4">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ending Game Winners"
														color="indigo"
														value="34"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="4">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ending Game Postponed"
														color="indigo"
														value="55"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<!-- ENDING TEAMS -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="Ending Teams"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										v-model="module_endingteams"
										@click="setEndingTeamsModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ending Teams"
														color="indigo"
														value="18"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<!-- ENDING LEAGUES -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="Ending Leagues"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										v-model="module_endingleagues"
										@click="setEndingLeaguesModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ending Leagues"
														color="indigo"
														value="19"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<!-- ENDING CONFIG -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="Ending Config"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										v-model="module_endingconfig"
										@click="setEndingConfigModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ending Config"
														color="indigo"
														value="20"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<!-- GAME REPORT -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="Game Report"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										v-model="module_gamereport"
										@click="setGameReportModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ending Report Bets"
														color="indigo"
														value="4"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ending Report Cards"
														color="indigo"
														value="5"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ending Game Report"
														color="indigo"
														value="35"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Progress Report"
														color="indigo"
														value="36"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Transactional Report"
														color="indigo"
														value="37"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Cash In Report"
														color="indigo"
														value="46"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Cash Out Report"
														color="indigo"
														value="47"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Cards"
														color="indigo"
														value="48"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Payouts"
														color="indigo"
														value="49"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Top Winning Combination"
														color="indigo"
														value="50"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<!-- <v-col cols="12" md="3">
                      <v-card>
                        <v-card-title>
                          <v-checkbox
                            v-model="access"
                            label="Master Agent's Token"
                            color="indigo"
                            value="51"
                            hide-details
                            class="d-inline-block"
                          ></v-checkbox>
                        </v-card-title>
                      </v-card>
                    </v-col> -->
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<!-- PAGCOR REPORT -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="Pagcor Report"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										v-model="module_pagcorreport"
										@click="setPagcorReportModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Pagcor Game Report"
														color="indigo"
														value="13"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Pagcor Progress Report"
														color="indigo"
														value="14"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Pagcor Transactional Report"
														color="indigo"
														value="22"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<!-- MONITORING -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="Monitoring"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										v-model="module_monitoring"
										@click="setMonitoringModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Game Cards"
														color="indigo"
														value="43"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<!-- AGENT REPORTS -->
					<!-- <v-row dense>
            <v-col>
              <v-card>
                <v-card-title>
                  <v-checkbox
                    label="Agent Reports"
                    color="indigo"
                    hide-details
                    class="d-inline-block ma-0 pa-0"
                    v-model="module_agentreports"
                    @click="setAgentReportsModule"
                  ></v-checkbox>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" md="3">
                      <v-card>
                        <v-card-title>
                          <v-checkbox
                            v-model="access"
                            label="Players Progress"
                            color="indigo"
                            value="45"
                            hide-details
                            class="d-inline-block"
                          ></v-checkbox>
                        </v-card-title>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row> -->

					<!-- AGENT MONITORING -->
					<!-- <v-row dense>
            <v-col>
              <v-card>
                <v-card-title>
                  <v-checkbox
                    label="Agent Monitoring"
                    color="indigo"
                    hide-details
                    class="d-inline-block ma-0 pa-0"
                    v-model="module_agentmonitoring"
                    @click="setAgentMonitoringModule"
                  ></v-checkbox>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" md="3">
                      <v-card>
                        <v-card-title>
                          <v-checkbox
                            v-model="access"
                            label="Agent Players"
                            color="indigo"
                            value="44"
                            hide-details
                            class="d-inline-block"
                          ></v-checkbox>
                        </v-card-title>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row> -->

					<!-- OUTLETS -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="Outlets"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										v-model="module_outlets"
										@click="setOutletsModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Outlets"
														color="indigo"
														value="56"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<!-- USERS -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="Users"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										v-model="module_users"
										@click="setUsersModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="User"
														color="indigo"
														value="15"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<!-- SMS -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="SMS"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										v-model="module_sms"
										@click="setSmsModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="SMS"
														color="indigo"
														value="75"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<!-- ADS     -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="Ads"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										v-model="module_ads"
										@click="setAdsModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Ads"
														color="indigo"
														value="57"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<!-- NEWS AND UPDATES -->
					<v-row dense>
						<v-col>
							<v-card>
								<v-card-title>
									<v-checkbox
										label="News and Updates"
										color="indigo"
										hide-details
										class="d-inline-block ma-0 pa-0"
										v-model="moduleNewsAndUpdates"
										@click="setNewsAndUpdatesModule"></v-checkbox>
								</v-card-title>

								<v-divider></v-divider>

								<v-card-text>
									<v-row dense>
										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="News And Updates"
														color="indigo"
														value="79"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>

										<v-col cols="12" md="3">
											<v-card>
												<v-card-title>
													<v-checkbox
														v-model="access"
														label="Create And Update"
														color="indigo"
														value="80"
														hide-details
														class="d-inline-block"></v-checkbox>
												</v-card-title>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<v-alert v-if="!userAccessStatus" border="right" colored-border type="error" elevation="2" class="mt-8">
						You must select atleast 1 access.
					</v-alert>

					<v-row dense>
						<v-col sm="6" md="6">
							<v-switch v-model="status" color="success" :label="`Status: ${statusText}`" class="d-inline-block" />
						</v-col>
					</v-row>

					<!-- <v-col sm="6" md="6">
              <div class="mt-4 text-right">
                <v-btn color="black" outlined class="me-2"> Cancel </v-btn>

                <v-btn
                  type="submit"
                  color="primary"
                  outlined
                  @click="handleSubmit"
                  :loading="loading"
                >
                  Update
                </v-btn>
              </div>
            </v-col> -->

					<v-row dense>
						<v-col sm="6" md="6">
							<div>
								<v-btn color="black" outlined class="me-2"> Cancel </v-btn>

								<v-btn type="submit" color="primary" outlined @click="handleSubmit" :loading="loading"> Update </v-btn>
							</div>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import formRules from '@/helpers/formRules'
import { format, subYears } from 'date-fns'

export default {
	props: {
		user_id: {
			required: true,
		},
	},
	data: () => ({
		valid: true,
		rules: formRules,
		birthdate_menu: false,
		birthdateActivePicker: null,
		passwordVisibility: false,
		passwordVisibility2: false,
		first_name: '',
		middle_name: '',
		last_name: '',
		mobile_number: '',
		email_address: '',
		birthDate: '',
		password: '',
		confirm_password: '',
		access: [],
		radioAccess: '',
		status: true,
		//statusText: "Active",
		emailAddressExistText: '',
		mobileNumberExistText: '',
		passwordAvailabilityStatus: true,
		userAccessStatus: true,
		winner_verification: '',
		winner_approval: '',
		userInfoLoading: false,
		formAvailability: true,

		// MODULES CHECKBOX DECLARATION
		module_dashboard: false,
		module_agentlist: false,
		module_playerlist: false,
		module_cashin: false,
		module_cashout: false,
		module_lucky_four: false,
		module_endinggames: false,
		module_endingteams: false,
		module_endingleagues: false,
		module_endingconfig: false,
		module_gamereport: false,
		module_pagcorreport: false,
		module_monitoring: false,
		module_agentreports: false,
		module_agentmonitoring: false,
		module_outlets: false,
		module_users: false,
		module_sms: false,
		module_ads: false,
		moduleNewsAndUpdates: false,
	}),
	created() {
		this.getInformation()
	},
	computed: {
		...mapState('ending_users', ['usersInformation', 'loading', 'userAccess', 'error', 'errors']),
		passwordConfirmationRule() {
			return () => this.password === this.confirm_password || 'Password must match'
		},
		statusText() {
			return this.status ? 'Active' : 'Inactive'
		},
	},
	methods: {
		...mapActions('ending_users', ['getUserAccess', 'updateUser', 'getUsersInformation']),
		isUserAccessNotEmpty() {
			if (this.access.length != 0) {
				this.userAccessStatus = true
				return true
			} else {
				this.userAccessStatus = false
				return false
			}
		},
		async getInformation() {
			this.userInfoLoading = true

			await this.getUsersInformation(this.setUserIdFormData())

			this.setUserInformation()
			this.formAvailability = false
			this.userInfoLoading = false
		},
		setUserInformation() {
			this.first_name = this.usersInformation[0].first_name
			this.middle_name = this.usersInformation[0].middle_name
			this.last_name = this.usersInformation[0].last_name
			this.mobile_number = this.usersInformation[0].mobile_number
			this.email_address = this.usersInformation[0].email_address
			this.birthDate = this.usersInformation[0].birthdate
			this.status = this.usersInformation[0].is_active === 'Active'

			if (this.usersInformation[0].access != null) {
				if (this.usersInformation[0].access.length != 0) {
					this.access = this.usersInformation[0].access.split(',').map((s) => s.trim())
				}
			}
		},
		setUserIdFormData() {
			const formData = new FormData()

			formData.append('user_id', this.user_id)

			const urlSearchParams = new URLSearchParams(formData)

			return urlSearchParams
		},
		async handleSubmit() {
			this.isUserAccessNotEmpty()
			if (this.$refs.form.validate() && this.userAccessStatus) {
				if (!this.loading) {
					await this.updateUser(this.setFormData())

					if (!this.error && this.isObjectEmpty(this.errors)) {
						this.$router.push({ name: 'Users' })
					} else this.setErrors()
				}
			}
		},
		setErrors() {
			let errorFocus = ''

			Object.entries(this.errors).forEach(([key, value]) => {
				console.log(key + ' ' + value)
				if (value) {
					if (!errorFocus) {
						errorFocus = key
					}

					switch (key) {
						case 'mobile_number':
							this.mobileNumberExistText = this.mobile_number
							break
						case 'email_address':
							this.emailAddressExistText = this.email_address
							break
					}

					this.$refs.form.validate()
					this.$refs[errorFocus].focus()
				}
			})
		},
		setFormData() {
			const formData = new FormData()

			formData.append('user_id', this.user_id)
			formData.append('first_name', this.first_name)
			formData.append('first_name', this.first_name)
			formData.append('middle_name', this.middle_name)
			formData.append('last_name', this.last_name)
			formData.append('mobile_number', this.mobile_number)
			formData.append('email_address', this.email_address)
			formData.append('birthdate', this.birthDate)
			formData.append('access', this.access)
			formData.append('password', this.password)
			formData.append('is_active', this.status === true ? 'Active' : 'Inactive')

			return formData
		},
		mobileNumberExist(val) {
			if (val == this.mobileNumberExistText) {
				return 'Mobile number already exists.'
			} else return true
		},
		emailAddressExist(val) {
			if (val == this.emailAddressExistText) {
				return 'Email address already exists.'
			} else return true
		},
		maxBirthdate() {
			return format(subYears(new Date(format(new Date(), 'yyyy, MM, dd')), 21), 'yyyy-MM-dd')
		},
		minBirthdate() {
			return format(subYears(new Date(format(new Date(), 'yyyy, MM, dd')), 100), 'yyyy-MM-dd')
		},
		removeMobileNumberLeadingZero() {
			this.mobile_number = this.mobile_number.replace(/^0+/, '')
		},
		//setToggleStatus() {
		//  this.statusText = this.status === true ? "Active" : "Inactive";
		//},
		setWinnerApproval() {
			this.removeAccess('8')
		},
		removeAccess(value) {
			var accessIndex = this.access.indexOf(value)

			if (accessIndex >= 0) {
				this.access.splice(accessIndex, 1)
			}
		},
		setWinnerVerification() {
			this.removeAccess('9')
		},
		isObjectEmpty(obj) {
			return Object.keys(obj).length === 0
		},
		setCashinVerify() {
			this.removeAccess('26')
		},
		setCashinApprove() {
			this.removeAccess('52')
		},
		setCashoutVerify() {
			this.removeAccess('29')
		},
		setCashoutApprove() {
			this.removeAccess('53')
		},
		setLuckyFourVerify() {
			this.removeAccess('62')
		},
		setLuckyFourApprove() {
			this.removeAccess('61')
		},

		setDashboardModule() {
			// if (this.module_dashboard) {
			//     this.access.push("16", "21");
			// } else {
			//     const values = ["16", "21"];

			//     this.access = this.access.filter(
			//         (item) => !values.includes(item)
			//     );
			// }

			const values = ['16', '21']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_dashboard) {
				this.access.push('16', '21')
			}
		},

		setAgentListModule() {
			const values = ['41', '42', '54']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_agentlist) {
				this.access.push('41', '42', '54')
			}
		},

		setPlayerListModule() {
			const values = ['1', '23', '40', '24']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_playerlist) {
				this.access.push('1', '23', '40', '24')
			}
		},

		setCashinModule() {
			const values = ['2', '52', '26', '28', '38']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_cashin) {
				this.access.push('2', '28', '38')
			}
		},

		setCashoutModule() {
			const values = ['3', '53', '29', '31', '39']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_cashout) {
				this.access.push('3', '31', '39')
			}
		},

		setLuckyFourModule() {
			const values = ['59', '60', '61', '62', '63']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_lucky_four) {
				this.access.push('59', '60', '63')
			}
		},

		setEndingGamesModule() {
			const values = ['17', '8', '9', '10', '11', '12', '32', '33', '34', '55']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_endinggames) {
				this.access.push('17', '10', '11', '12', '32', '33', '34', '55')
			}
		},

		setEndingTeamsModule() {
			const values = ['18']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_endingteams) {
				this.access.push('18')
			}
		},

		setEndingLeaguesModule() {
			const values = ['19']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_endingleagues) {
				this.access.push('19')
			}
		},

		setEndingConfigModule() {
			const values = ['20']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_endingconfig) {
				this.access.push('20')
			}
		},

		setGameReportModule() {
			const values = ['4', '5', '35', '36', '37', '46', '47', '48', '49', '50', '51']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_gamereport) {
				this.access.push('4', '5', '35', '36', '37', '46', '47', '48', '49', '50', '51')
			}
		},

		setPagcorReportModule() {
			const values = ['13', '14', '22']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_pagcorreport) {
				this.access.push('13', '14', '22')
			}
		},

		setMonitoringModule() {
			const values = ['43']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_monitoring) {
				this.access.push('43')
			}
		},

		setAgentReportsModule() {
			const values = ['45']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_agentreports) {
				this.access.push('45')
			}
		},

		setAgentMonitoringModule() {
			const values = ['44']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_agentmonitoring) {
				this.access.push('44')
			}
		},

		setUsersModule() {
			const values = ['15']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_users) {
				this.access.push('15')
			}
		},

		setSmsModule() {
			const values = ['75']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_sms) {
				this.access.push('75')
			}
		},

		setOutletsModule() {
			const values = ['56']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_outlets) {
				this.access.push('56')
			}
		},

		setAdsModule() {
			const values = ['57']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.module_ads) {
				this.access.push('57')
			}
		},

		setNewsAndUpdatesModule() {
			const values = ['79', '80']

			this.access = this.access.filter((item) => !values.includes(item))

			if (this.moduleNewsAndUpdates) {
				this.access.push('79', '80')
			}
		},
	},
	watch: {
		birthdate_menu(val) {
			val && setTimeout(() => (this.birthdateActivePicker = 'YEAR'))
		},

		access(val) {
			// DASHBOARD
			const dashboard_sub_modules = ['16', '21']

			const dashboard_module_status = dashboard_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_dashboard = dashboard_module_status

			// AGENT LIST
			const agentlist_sub_modules = ['41', '42', '54']

			const agentlist_sub_status = agentlist_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_agentlist = agentlist_sub_status

			// PLAYER LIST
			const playerlist_sub_modules = ['1', '23', '40', '24']

			const playerlist_sub_status = playerlist_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_playerlist = playerlist_sub_status

			// CASHIN
			const cashin_sub_modules = ['2', '52', '26', '28', '38']

			const cashin_sub_status = cashin_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_cashin = cashin_sub_status

			// CASHOUT
			const cashout_sub_modules = ['3', '53', '29', '31', '39']

			const cashout_sub_status = cashout_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_cashout = cashout_sub_status

			// LUCKY FOUR
			const lucky_four_sub_modules = ['59', '60', '63']

			const lucky_four_sub_status = lucky_four_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_lucky_four = lucky_four_sub_status

			// ENDING GAMES
			const endinggames_sub_modules = ['17', '10', '11', '12', '32', '33', '34', '55']

			const endinggames_sub_status = endinggames_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_endinggames = endinggames_sub_status

			// ENDING TEAMS
			const endingteams_sub_modules = ['18']

			const endingteams_sub_status = endingteams_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_endingteams = endingteams_sub_status

			// ENDING LEAGUES
			const endingleagues_sub_modules = ['19']

			const endingleagues_sub_status = endingleagues_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_endingleagues = endingleagues_sub_status

			// ENDING CONFIG
			const endingconfig_sub_modules = ['20']

			const endingconfig_sub_status = endingconfig_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_endingconfig = endingconfig_sub_status

			// GAME REPORT
			const gamereport_sub_modules = ['4', '5', '35', '36', '37', '46', '47', '48', '49', '50', '51']

			const gamereport_sub_status = gamereport_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_gamereport = gamereport_sub_status

			// PAGCOR REPORT
			const pagcorreport_sub_modules = ['13', '14', '22']

			const pagcorreport_sub_status = pagcorreport_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_pagcorreport = pagcorreport_sub_status

			// MONITORING
			const monitoring_sub_modules = ['43']

			const monitoring_sub_status = monitoring_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_monitoring = monitoring_sub_status

			// AGENT REPORTS
			const agentreports_sub_modules = ['45']

			const agentreports_sub_status = agentreports_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_agentreports = agentreports_sub_status

			// AGENT MONITORING
			const agentmonitoring_sub_modules = ['44']

			const agentmonitoring_sub_status = agentmonitoring_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_agentmonitoring = agentmonitoring_sub_status

			// OUTLETS
			const outlets_sub_modules = ['56']

			const outlets_sub_status = outlets_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_outlets = outlets_sub_status

			// USERS
			const users_sub_modules = ['15']

			const users_sub_status = users_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_users = users_sub_status

			// SMS
			const sms_sub_modules = ['75']

			const sms_sub_status = sms_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_sms = sms_sub_status

			// ADS
			const ads_sub_modules = ['57']

			const ads_sub_status = ads_sub_modules.every((value) => {
				return val.includes(value)
			})

			this.module_ads = ads_sub_status

			// NEWS AND UPDATES
			const newsAndUpdatesSubModules = ['79', '80']

			const newsAndUpdatesSubStatus = newsAndUpdatesSubModules.every((value) => {
				return val.includes(value)
			})

			this.moduleNewsAndUpdates = newsAndUpdatesSubStatus
		},
	},
}
</script>
