<template>
  <div>
    <v-card class="shadow bs-mb-3">
      <div class="d-flex justify-space-between align-center bs-py-3 bs-px-4">
        <h3>Bets Overview</h3>
        <div class="d-flex" style="gap: 1rem">
          <v-menu offset-y left open-on-hover transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                class="text-uppercase px-4"
                :disabled="dashboardBetsLoading"
              >
                {{ filter }}
                <v-icon size="22" right
                  >mdi-chevron-down</v-icon
                >
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in filters"
                :key="item"
                @click="changeFilter(item)"
                :class="item == filter ? 'v-list-item--active primary--text' : ''"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <v-divider></v-divider>

      <div class="pa-6">
        <v-row>
          <v-col cols="12" md="3">
            <div class="d-flex align-center" style="gap: 1rem">
              <v-avatar class="rounded" size="67" color="purple lighten-5" tile>
                <v-icon color="purple"> mdi-equal </v-icon>
              </v-avatar>
              <div>
                <div class="grey--text" style="margin-top: 6px; font-size: 13px">Total Games</div>
                <h2 class="black--text">
                  {{
                    parseInt(
                      dashboardBets.total_games ? dashboardBets.total_games : 0
                    ).toLocaleString()
                  }}
                </h2>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div class="d-flex align-center" style="gap: 1rem">
              <v-tooltip v-if="game_id != 3" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    class="rounded"
                    size="67"
                    color="pink lighten-5 ma-0"
                    tile
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="pink"> mdi-cash-multiple </v-icon>
                  </v-avatar>
                </template>
                <div class="d-flex" style="gap: 0.75rem">
                  <span
                    >Per Quarter:
                    <span >{{
                      moneyFormat2(parseInt(getBetType(1)))
                    }}</span></span
                  >
                  <span
                    >Traditional:
                    <span >{{
                      moneyFormat2(parseInt(getBetType(2)))
                    }}</span></span
                  >
                </div>
              </v-tooltip>
              <v-avatar
                v-else
                class="rounded"
                size="67"
                color="pink lighten-5 ma-0"
                tile
              >
                <v-icon color="pink"> mdi-cash-multiple </v-icon>
              </v-avatar>
              <div>
                <div class="grey--text" style="margin-top: 6px; font-size: 13px">Total Bets</div>
                <h2 class="black--text">
                  {{
                    moneyFormat2(
                      parseInt(dashboardBets.total_bets ? dashboardBets.total_bets : 0)
                    )
                  }}
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <small
                        v-bind="attrs"
                        v-on="on"
                        class="green--text"
                        style="font-weight: normal; font-size: 12px; padding-left: 4px"
                        >{{
                          (parseInt(dashboardBets.total_bets) /
                            parseInt(dashboardBets.target_bet)) *
                          100
                            ? moneyFormat(
                                (parseInt(dashboardBets.total_bets) /
                                  parseInt(dashboardBets.target_bet)) *
                                  100
                              )
                            : "0.00"
                        }}%</small
                      >
                    </template>
                    <span
                      >{{
                        dashboardBets.total_bets != "0"
                          ? moneyFormat(
                              (parseInt(dashboardBets.total_bets) /
                                parseInt(dashboardBets.target_bet)) *
                                100
                            )
                          : "0.00"
                      }}% of Registered Players</span
                    >
                  </v-tooltip>
                </h2>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div class="d-flex align-center" style="gap: 1rem">
              <v-tooltip v-if="game_id != 3" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    size="67"
                    color="orange lighten-5 ma-0"
                    tile
                    v-bind="attrs"
                    v-on="on"
                    class="rounded"
                  >
                    <v-icon color="orange"> mdi-cash-multiple </v-icon>
                  </v-avatar>
                </template>
                <div class="d-flex" style="gap: 0.75rem">
                  <span
                    >Per Quarter:
                    <span >{{
                      "₱" + moneyFormat2(parseInt(getBetAmountType(1)))
                    }}</span></span
                  >
                  <span
                    >Traditional:
                    <span >{{
                      "₱" + moneyFormat2(parseInt(getBetAmountType(2)))
                    }}</span></span
                  >
                </div>
              </v-tooltip>
              <v-avatar
                v-else
                size="67"
                color="orange lighten-5 ma-0"
                tile
              >
                <v-icon color="orange"> mdi-cash-multiple </v-icon>
              </v-avatar>
              <div>
                <div class="grey--text" style="margin-top: 6px; font-size: 13px">Total Bet Amount</div>
                <h2 class="black--text">
                  {{
                    dashboardBets.total_bet_amount
                      ? "₱" + moneyFormat2(parseInt(dashboardBets.total_bet_amount))
                      : "₱0.00"
                  }}
                </h2>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="3">
            <div class="d-flex align-center" style="gap: 1rem">
              <v-avatar class="rounded" size="67" color="blue lighten-5" tile>
                <v-icon color="primary"> mdi-cash-multiple </v-icon>
              </v-avatar>
              <div>
                <div class="grey--text" style="margin-top: 6px; font-size: 13px">Average Bet Amount</div>
                <h2 class="black--text">
                  {{
                    dashboardBets.average_bet_amount
                      ? "₱" + parseInt(dashboardBets.average_bet_amount)
                      : "₱0.00"
                  }}
                </h2>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <div class="bs-row bs-g-3">
      <div class="bs-col-md-6">
        <BetsDailyStatistics :game_id="game_id" />
      </div>
      <div class="bs-col-md-6">
        <BetsMonthlyStatistics :game_id="game_id" />
      </div>
    </div>

    <v-dialog
      ref="filterDialog"
      v-model="filterDialog"
      :return-value.sync="filterDate"
      persistent
      width="290px"
    >
      <v-date-picker v-model="filterDate" scrollable range>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelFilter"> Cancel </v-btn>

        <v-btn text @click="saveFilter"> OK </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formatDatev2 from "@/helpers/formatDatev2";
import { startOfWeek } from "date-fns";
import { endOfWeek } from "date-fns";
import { startOfMonth } from "date-fns";
import { endOfMonth } from "date-fns";
import { startOfYear } from "date-fns";
import { endOfYear } from "date-fns";
import GetPercentage from "@/helpers/getPercentage";
import BetsDailyStatistics from "@/components/dashboard/bets_statistics/BetsDaily";
import BetsMonthlyStatistics from "@/components/dashboard/bets_statistics/BetsMonthly";

export default {
  components: {
    BetsDailyStatistics,
    BetsMonthlyStatistics,
  },
  data: () => ({
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    filter: "Today",
    filters: ["Today", "This Week", "This Month", "This Year", "Custom"],
    filterFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    filterTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    filterDialog: false,
    filterDate: [],
    filterDateText: "",
  }),
  props: {
    game_id: Number,
  },
  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("dashboard", ["dashboardBets", "dashboardBetsLoading"]),
  },
  watch: {
    game_id() {
      if (this.accountInfo.access.includes(21)) {
        this.doGetData();
      }
    },
  },
  mounted() {
    if (this.accountInfo.access.includes(21)) {
      this.doGetData();
    }
  },
  methods: {
    ...mapActions("dashboard", ["getDashboardBets"]),
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    moneyFormat2(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    changeFilter(val) {
      if (val == "Today") {
        this.filterFrom = this.today;
        this.filterTo = this.today;
        this.filter = "Today";
        this.filterDate = [];
        this.doGetData();
      }

      if (val == "This Week") {
        const startWeek = startOfWeek(new Date(this.today), { weekStartsOn: 1 }),
          endWeek = endOfWeek(new Date(this.today), { weekStartsOn: 1 });

        this.filterFrom = formatDatev2(startWeek, "format", "yyyy-MM-dd");
        this.filterTo = formatDatev2(endWeek, "format", "yyyy-MM-dd");
        this.filter = "This Week";
        this.filterDate = [];
        this.doGetData();
      }

      if (val == "This Month") {
        const startMonth = startOfMonth(new Date(this.today)),
          endMonth = endOfMonth(new Date(this.today));

        this.filterFrom = formatDatev2(startMonth, "format", "yyyy-MM-dd");
        this.filterTo = formatDatev2(endMonth, "format", "yyyy-MM-dd");
        this.filter = "This Month";
        this.filterDate = [];
        this.doGetData();
      }

      if (val == "This Year") {
        const startYear = startOfYear(new Date(this.today)),
          endYear = endOfYear(new Date(this.today));

        this.filterFrom = formatDatev2(startYear, "format", "yyyy-MM-dd");
        this.filterTo = formatDatev2(endYear, "format", "yyyy-MM-dd");
        this.filter = "This Year";
        this.filterDate = [];
        this.doGetData();
      }

      if (val == "Custom") {
        this.filterDialog = true;
      }
    },
    cancelFilter() {
      this.filterDialog = false;
    },
    saveFilter() {
      if (!this.filterDate.length) {
        // nothing to do
      } else {
        this.$refs.filterDialog.save(this.filterDate);

        const minDate = new Date(this.filterDate[0]);

        if (typeof this.filterDate[1] != "undefined") {
          const maxDate = new Date(this.filterDate[1]);

          if (minDate > maxDate) {
            this.filterFrom = formatDatev2(maxDate, "format", "yyyy-MM-dd");
            this.filterTo = formatDatev2(minDate, "format", "yyyy-MM-dd");
          } else {
            this.filterFrom = formatDatev2(minDate, "format", "yyyy-MM-dd");
            this.filterTo = formatDatev2(maxDate, "format", "yyyy-MM-dd");
          }

          this.filterDateText =
            formatDatev2(this.filterFrom, "date") +
            " to " +
            formatDatev2(this.filterTo, "date");

          this.doGetData();
        } else {
          this.filterFrom = formatDatev2(minDate, "format", "yyyy-MM-dd");
          this.filterTo = formatDatev2(minDate, "format", "yyyy-MM-dd");
          this.filterDateText = formatDatev2(this.filterFrom, "date");
          this.doGetData();
        }

        this.filter = "Custom";
      }
    },
    async doGetData() {
      if (!this.revenueLoading) {
        this.$store.commit("dashboard/setDashboardBets", []);

        const formData = new FormData();
        formData.append("date_from", this.filterFrom);
        formData.append("date_to", this.filterTo);
        formData.append("game_id", this.game_id);

        const getData = new URLSearchParams(formData);

        await this.getDashboardBets(getData);
      }
    },

    getBetAmountType(flag) {
      if (this.dashboardBets.total_bet_amount) {
        const data = this.dashboardBets.bet_amount_per_type.filter(
          (type) => type.type == (flag == 1 ? "Traditional" : "Per Quarter")
        );

        if (data.length) return data[0].total_bet_amount;
        else return 0;
      }

      return 0;
    },

    getBetType(flag) {
      if (this.dashboardBets.total_bets) {
        const data = this.dashboardBets.bet_per_type.filter(
          (type) => type.type == (flag == 1 ? "Traditional" : "Per Quarter")
        );

        if (data.length) return data[0].bet_count;
        else return 0;
      }

      return 0;
    },

    setPercentage(val, dividend) {
      if (val != 0 && dividend != 0) {
        return GetPercentage(val, dividend);
      } else {
        return 0;
      }
    },
  },
};
</script>
