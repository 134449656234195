const url = process.env.VUE_APP_API_URL + "ending/reports/"

export default {
    namespaced: true,
  
    state: {
      top_provinces: [],
      progressive: [],
      topProvincesLoading: false,
    },

    mutations: {
        setLoading(state, val) {
            state.topProvincesLoading = val
        },

        setRecords(state, val) {
            state.top_provinces = val
        },

        setProgressive(state, val) {
            state.progressive = val
        }
    },
  
    actions: {
        async getRecords({ commit, rootState }, data) {
            commit("setLoading", true)

            try {
                const response = await fetch(`${url}kubrador_top_provinces?${data}` , {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setRecords", res.provinces)
                    commit("setProgressive", res.progressive)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
                } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setLoading", false)
            }
        },
    }
  }