<template>
  <v-dialog persistent v-model="dialog" max-width="500">
    <v-form @submit.prevent="do_submit" class="d-flex flex-column" style="gap: 1rem">
      <v-card class="shadow">
        <div class="d-flex align-center justify-space-between pa-6">
          <h3>{{ sub_user_modal_title }} Sub Account</h3>
          <v-btn icon @click="set_close_dialog">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>

        <v-divider></v-divider>

        <div class="d-flex pa-4" style="flex-direction: column; gap: 1rem">
          <v-text-field
            ref="first_name"
            outlined
            dense
            label="First Names"
            :rules="
              submit_status ? [rules.required, rules.alphaSpace, rules.minimum(2)] : []
            "
            v-model="first_name"
            hide-details="auto"
            clearable
            autofocus
          />

          <v-text-field
            ref="last_name"
            outlined
            dense
            label="Last Name"
            :rules="
              submit_status ? [rules.required, rules.alphaSpace, rules.minimum(2)] : []
            "
            v-model="last_name"
            hide-details="auto"
            clearable
          />

          <v-text-field
            v-if="toggle_password_field"
            ref="mobile_number"
            outlined
            dense
            maxlength="10"
            label="Mobile Number"
            :rules="
              submit_status
                ? [rules.required, rules.validMobileNumber, rules.minimum(2)]
                : []
            "
            v-model="mobile_number"
            hide-details="auto"
            prefix="+63"
            @keyup="remove_leading_zero"
            clearable
          />

          <v-text-field
            v-if="sub_user_modal_title == 'Add'"
            tabindex="-1"
            ref="password"
            outlined
            dense
            label="Password"
            :rules="
              submit_status
                ? [rules.required, rules.minimum(6), rules.strongPassword]
                : []
            "
            v-model="password"
            hide-details="auto"
            :append-icon="password_visibility ? 'mdi-eye' : 'mdi-eye-off'"
            :type="password_visibility ? 'text' : 'password'"
            @click:append="password_visibility = !password_visibility"
            clearable
          />

          <div class="d-flex align-center bs-gap-3">
            <v-switch v-model="status" color="success" hide-details="auto" class="mt-0" />
            <span v-text="status ? 'Active' : 'Inactive'"></span>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="pa-4 d-flex justify-end" style="width: 100%; gap: 0.5rem">
          <v-btn text depressed color="black" @click="set_close_dialog"> Cancel </v-btn>
          <v-btn
            :disabled="loading_status || disable_save_button"
            type="submit"
            depressed
            color="primary"
          >
            Save
          </v-btn>
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import form_rules from "@/helpers/formRules";
import ApiRequestPost from "@/helpers/api_request_post.js";

export default {
  props: {
    investor_id: Number,
    sub_user_info: Object,
    sub_user_modal_title: String,
  },

  data: () => ({
    rules: form_rules,
    submit_status: false,
    sub_user_id: 0,
    first_name: "",
    last_name: "",
    mobile_number: "",
    password: "",
    status: true,
    status_label: "",
    password_visibility: true,
    loading_status: false,
  }),

  mounted() {
    this.sub_user_id = this.sub_user_info.id;
    this.first_name = this.sub_user_info.first_name;
    this.last_name = this.sub_user_info.last_name;
    this.password = this.sub_user_info.password;
    if (this.sub_user_info.status == "Active" && this.sub_user_info.status != undefined) {
      this.status = true;
      this.status_label = "Active";
    } else if (
      this.sub_user_info.status == "Inactive" &&
      this.sub_user_info.status != undefined
    ) {
      this.status = false;
      this.status_label = "Inactive";
    } else if (this.sub_user_info.status != undefined) {
      this.status = true;
      this.status_label = "Active";
    }
  },

  watch: {
    status(value) {
      if (value == true) {
        this.status_label = "Active";
      } else {
        this.status_label = "Inactive";
      }
    },
  },

  computed: {
    toggle_password_field() {
      if (this.sub_user_modal_title == "Add") {
        return true;
      }

      return false;
    },
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.set_close_dialog();
      },
    },
    disable_save_button() {
      if (
        this.sub_user_info.first_name == this.first_name &&
        this.sub_user_info.last_name == this.last_name &&
        this.sub_user_info.status == this.status_label
      ) {
        return true;
      }

      return false;
    },
  },

  methods: {
    set_close_dialog() {
      this.$emit("set_close_add_account_dialog");
    },

    remove_leading_zero() {
      if (this.mobile_number) {
        this.mobile_number = this.mobile_number.replace(/^0+/, "");
        this.mobile_number = this.mobile_number.replace(/[^\d,.]/g, "");
      }
    },

    async do_submit() {
      this.submit_status = true;
      this.loading_status = true;

      const form_data = new FormData();

      form_data.append("id", this.sub_user_id);
      form_data.append("investor_id", this.investor_id);
      form_data.append("first_name", this.first_name);
      form_data.append("last_name", this.last_name);
      form_data.append("mobile_number", this.mobile_number);
      form_data.append("password", this.password);
      form_data.append("status", this.status == true ? "Active" : "Inactive");

      const res = await ApiRequestPost(
        form_data,
        this.sub_user_modal_title == "Add"
          ? "investors/create_sub_account"
          : "investors/update_sub_account"
      );

      if (res.status == 200) {
        this.set_close_dialog();
        this.$emit("refresh_sub_user_list");
      }

      this.loading_status = false;
    },
  },
};
</script>
