import { format } from 'date-fns'

const formatDate = (date, isTime = false) => {
  if (date) {
    if (isTime) {
      return format(new Date(date), 'MMM dd, yyyy hh:mm a')
    } else {
      return format(new Date(date), 'MMM dd, yyyy')
    }
  } else {
    return '-'
  }
}

export default formatDate