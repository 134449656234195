<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Ending Game</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Teams</h2>
    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="handleClick"
                >
                  <v-icon>mdi-plus-box-outline</v-icon>
                </v-btn>
              </template>

              <span>Create Team</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  @click="loadRecords"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="secondary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-file-export-outline</v-icon>
                </v-btn>
              </template>
              <span>Export to EXCEL</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="recordsLoading"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="5"
          :search="search"
          :loading="recordsLoading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  color="primary"
                  @click="
                    setTeamData(
                      item.team_id,
                      item.league_id,
                      item.team_name,
                      item.team_alias,
                      item.logo,
                      item.is_active
                    )
                  "
                >
                  <v-icon size="large"> mdi-square-edit-outline </v-icon>
                </v-btn>
              </template>
              <span>Update</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.logo`]="{ item }">
            <div>
              <v-avatar size="36px" tile>
                <v-img :src="item.logo">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="black"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </div>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ setCreatedAtFormat(item.created_at) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <FormDialog
      v-if="dialogStatus"
      :teamData="teamData"
      class="mr-1"
      @closeModal="setData"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { format, sub } from "date-fns";
import formatDatev2 from "@/helpers/formatDatev2";
import FormDialog from "@/components/ending/teams/FormDialog";

export default {
  components: { FormDialog },
  data: () => ({
    teamData: [],
    dialogStatus: false,
    search: "",
    sortBy: "team_id",
    sortDesc: true,
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      // { text: "ID", value: "team_id", align: "left" },
      {
        text: "League",
        sortable: false,
        value: "league",
        class: "text-uppercase",
      },
      { text: "Logo", sortable: false, value: "logo", class: "text-uppercase" },
      {
        text: "Name",
        sortable: false,
        value: "team_name",
        class: "text-uppercase",
      },
      {
        text: "Alias",
        sortable: false,
        value: "team_alias",
        class: "text-uppercase",
      },
      {
        text: "Created At",
        value: "created_at",
        class: "text-uppercase",
      },
    ],

    // Filter data formattedCreatedAt
    createdAtStart: format(sub(new Date(), { days: 30 }), "yyyy-MM-dd"),
    createdAtEnd: format(new Date(), "yyyy-MM-dd"),
  }),
  created() {
    this.loadRecords();
  },
  computed: {
    ...mapState("ending_teams", ["records", "recordsLoading"]),
  },
  methods: {
    ...mapActions("ending_teams", ["getRecords"]),
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    filter() {
      alert("I am working on this...");
    },
    loadRecords() {
      if (!this.recordsLoading) {
        this.$store.commit("ending_teams/setRecords", []);

        const formData = new FormData();
        formData.append("created_at_start", this.createdAtStart);
        formData.append("created_at_end", this.createdAtEnd);

        const getData = new URLSearchParams(formData);

        this.getRecords(getData);
      }
    },
    handleClick() {
      this.dialogStatus = true;
      this.teamData = {};
    },
    setTeamData(id, league_id, team_name, team_alias, logo, isActive) {
      this.dialogStatus = true;
      this.teamData = { id, league_id, team_name, team_alias, logo, isActive };
    },
    setData(updatedData, insertedData) {
      this.dialogStatus = false;

      if (Object.keys(updatedData).length != 0) {
        const teamData = this.records.find((record) => record.team_id == updatedData.id);

        teamData.league_id = updatedData.league_id;
        teamData.league = updatedData.leagueText;
        teamData.team_name = updatedData.team_name;
        teamData.team_alias = updatedData.team_alias;
        teamData.is_active = updatedData.isActive;
        teamData.logo = updatedData.logo;
      } else if (Object.keys(insertedData).length != 0) {
        this.$store.commit("ending_teams/setRecords", [insertedData, ...this.records]);
      }
    },
    setCreatedAtFormat(date) {
      return formatDatev2(date);
    },
  },
};
</script>
