<template>
  <v-dialog
    :fullscreen="!$vuetify.breakpoint.mdAndUp"
    v-model="dialog"
    persistent
    max-width="800"
  >
    <v-card class="shadow">
      <v-card-title
        class="d-flex justify-space-between text-h6 font-weight-regular"
      >
        <span>{{
          `${Object.keys(investor_data).length ? "Update" : "New"}`
        }}</span>

        <v-btn plain icon @click="set_close_dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          class="d-flex flex-column"
          lazy-validation
          :disabled="outlets_loading && investor_id_loading"
          @submit.prevent="handle_submit"
          style="gap: 1rem"
        >
          <v-alert v-if="alert_status" ref="alert" type="error">
            {{ this.alert_message }}
          </v-alert>

          <div class="d-flex flex-sm-row flex-column" style="gap: 1rem">
            <div class="d-flex flex-column" style="gap: 1rem; width: 100%">
              <v-autocomplete
                v-model="outlet"
                :items="outlets"
                :rules="submit_status ? [rules.required] : []"
                :loading="outlets_loading"
                label="Outlet"
                outlined
                dense
                item-text="outlet_name"
                item-value="id"
                hide-details="auto"
              />

              <v-text-field
                ref="first_name"
                outlined
                dense
                label="First Name"
                :rules="
                  submit_status
                    ? [rules.required, rules.alphaSpace, rules.minimum(2)]
                    : []
                "
                v-model="first_name"
                hide-details="auto"
                clearable
              />

              <v-text-field
                ref="last_name"
                outlined
                dense
                label="Last Name"
                :rules="
                  submit_status
                    ? [rules.required, rules.alphaSpace, rules.minimum(2)]
                    : []
                "
                v-model="last_name"
                hide-details="auto"
                clearable
              />

              <v-text-field
                ref="mobile_number"
                outlined
                dense
                maxlength="10"
                label="Mobile Number"
                :rules="
                  submit_status
                    ? [
                        rules.required,
                        rules.validMobileNumber,
                        rules.minimum(2),
                      ]
                    : []
                "
                v-model="mobile_number"
                hide-details="auto"
                prefix="+63"
                @keyup="remove_leading_zero"
                clearable
              />

              <v-text-field
                ref="password"
                outlined
                dense
                label="Password"
                :rules="
                  submit_status && Object.keys(this.investor_data).length == 0
                    ? [rules.required, rules.minimum(6), rules.strongPassword]
                    : []
                "
                v-model="password"
                hide-details="auto"
                :append-icon="password_visiblity ? 'mdi-eye' : 'mdi-eye-off'"
                :type="password_visiblity ? 'text' : 'password'"
                @click:append="password_visiblity = !password_visiblity"
                clearable
              />
              <v-text-field
                ref="territory"
                outlined
                dense
                label="Territory"
                :rules="submit_status ? [rules.required, rules.minimum(2)] : []"
                v-model="territory"
                hide-details="auto"
                clearable
                :disabled="
                  Object.keys(investor_data).length &&
                  investor_data.territory != '-'
                    ? true
                    : false
                "
              />
            </div>

            <div class="d-flex flex-column" style="gap: 1rem; width: 100%">
              <v-select
                dense
                outlined
                v-model="category"
                :items="categories"
                label="Category"
                hide-details="auto"
              />

              <v-text-field
                ref="base_commission"
                outlined
                dense
                label="Base Commission"
                :rules="submit_status ? [rules.required] : []"
                v-model="base_commission"
                hide-details="auto"
                clearable
                @keyup="remove_alphabet('commission')"
                suffix="%"
                hint="This will be the starting commission for SM."
              />

              <v-text-field
                ref="commission"
                outlined
                dense
                label="Commission"
                :rules="submit_status ? [rules.required] : []"
                v-model="commission"
                hide-details="auto"
                clearable
                @keyup="remove_alphabet('commission')"
                suffix="%"
                hint="This will be applied to master agent's direct regular players."
              />

              <v-text-field
                ref="sub_commission"
                outlined
                dense
                label="Sub Commission"
                :rules="submit_status ? [rules.required] : []"
                v-model="sub_commission"
                hide-details="auto"
                clearable
                @keyup="remove_alphabet('sub-commission')"
                suffix="%"
                hint="This will be applied to master agent's sales manager."
              />

              <v-text-field
                ref="wallet"
                outlined
                dense
                label="Wallet"
                v-model="wallet"
                hide-details="auto"
                clearable
                @keyup="remove_alphabet('wallet')"
                prefix="Php"
              />
              <v-autocomplete
                v-model="link_type"
                :items="link_types"
                :rules="submit_status ? [rules.required] : []"
                label="Link Type"
                outlined
                dense
                item-value="id"
                hide-details="auto"
                :disabled="investor_data.link_type == 'New' ? true : false"
              />
            </div>
          </div>

          <v-alert type="warning" text icon="mdi-alert-box">
            Don't use dashes (-) or spaces on creating a territory, instead use
            underscores (_) on separating words.
            <div class="d-flex flex-column mt-3">
              <div class="font-weight-bold mb-2">For Example:</div>
              <span class="d-flex align-center bs-gap-2">
                <v-icon color="red">mdi-close-circle-outline</v-icon>
                las-casas-gaming
              </span>
              <span class="d-flex align-center bs-gap-2">
                <v-icon color="red">mdi-close-circle-outline</v-icon>
                las casas gaming
              </span>
              <span class="d-flex align-center bs-gap-2">
                <v-icon color="green">mdi-check-circle-outline</v-icon>
                las_casas_gaming
              </span>
            </div>
          </v-alert>

          <div class="d-flex justify-space-between" style="gap: 1rem">
            <!-- :label="status ? 'Active' : 'Inactive'" -->
            <div class="d-flex align-center bs-gap-3">
              <v-switch
                v-model="status"
                color="success"
                hide-details
                class="my-auto pa-0"
              />
              <span v-text="status ? 'Active' : 'Inactive'"></span>
            </div>

            <div class="d-flex justify-end" style="gap: 0.5rem">
              <v-btn text class="px-2" color="grey" @click="set_close_dialog">
                Cancel
              </v-btn>

              <v-btn
                color="primary"
                class="px-2"
                type="submit"
                depressed
                :loading="investor_id_loading"
              >
                {{
                  `${Object.keys(investor_data).length ? "Update" : "Submit"}`
                }}
              </v-btn>
            </div>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import form_rules from "@/helpers/formRules";
import responseGet from "@/helpers/api_request_get";
import responsePost from "@/helpers/api_request_post";

export default {
  props: {
    investor_data: {
      type: Object,
    },
  },

  data: () => ({
    rules: form_rules,
    submit_status: false,
    outlets: [],
    outlets_loading: false,
    outlet: "1",
    territory: null,
    username: null,
    first_name: null,
    last_name: null,
    mobile_number: null,
    password: null,
    base_commission: "9.00",
    commission: "8.00",
    sub_commission: "9.00",
    wallet: 0,
    status: true,
    password_visiblity: true,
    investor_id: null,
    investor_id_loading: false,
    alert_status: false,
    alert_message: "",
    createdUpdatedData: {},
    action: "",
    currentDate: new Date(),
    category: "Regular",
    categories: ["Regular", "Special"],
    link_type: "New",
    link_types: ["Old", "New"],
  }),

  computed: {
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.set_close_dialog();
      },
    },
  },

  mounted() {
    this.get_outlets();

    if (Object.keys(this.investor_data).length) {
      this.investor_id = this.investor_data.investor_id;
      this.outlet = this.investor_data.outlet_id;
      this.territory = this.investor_data.territory;
      this.first_name = this.investor_data.first_name;
      this.last_name = this.investor_data.last_name;
      this.mobile_number = this.investor_data.mobile_number;
      this.base_commission = this.investor_data.base_commission;
      this.commission = this.investor_data.commission;
      this.sub_commission = this.investor_data.sub_commission;
      this.category = this.investor_data.category;
      this.wallet = this.investor_data.wallet;
      this.link_type = this.investor_data.link_type;
      this.status = this.investor_data.status == "Active" ? true : false;
    } else {
      this.username = `${this.make_username_alphabet_uppercase()}${this.make_username_alphabet_lowercase()}${this.make_username_number()}`;
    }
  },

  methods: {
    async get_outlets() {
      this.outlets_loading = true;

      const response = await responseGet("", "outlets");

      if (response.status == 200) {
        this.outlets = response.data.records;
        this.$nextTick(() => this.$refs.first_name.focus());
      }

      this.outlets_loading = false;
    },

    async handle_submit() {
      this.submit_status = true;
      await new Promise((r) => setTimeout(r, 200));

      if (this.$refs.form.validate()) {
        this.investor_id_loading = true;
        this.alert_status = false;

        let response = {};
        if (Object.keys(this.investor_data).length == 0) {
          response = await responsePost(
            this.set_form_data(),
            "investors/create"
          );
          this.action = "create";
        } else {
          response = await responsePost(
            this.set_form_data(),
            "investors/update"
          );
          this.action = "update";
        }

        switch (response.status) {
          case 200:
            this.createdUpdatedData = {
              investor_id:
                this.action == "create"
                  ? response.data.investor_id
                  : this.investor_id,
              outlet_id: this.outlet,
              territory: this.territory,
              first_name: this.first_name,
              last_name: this.last_name,
              investor_name: `${this.first_name} ${this.last_name}`,
              mobile_number: this.mobile_number,
              base_commission: this.base_commission,
              commission: this.commission,
              sub_commission: this.sub_commission,
              category: this.category,
              wallet: this.money_format(parseFloat(this.wallet)),
              link_type: this.link_type,
              status: this.status ? "Active" : "Inactive",
              created_at: this.currentDate,
            };

            this.set_close_dialog();
            break;
          case 422: {
            this.alert_status = true;
            this.alert_message = response.data.messages.mobile_number;
            this.$nextTick(() => this.$refs.mobile_number.focus());
            break;
          }
          case 500:
            this.alert_status = true;
            this.alert_message = "Something went wrong!";
            this.$nextTick(() => this.$refs.alert.focus());
            break;
        }

        this.investor_id_loading = false;
      }
    },

    set_form_data() {
      const form_data = new FormData();

      form_data.append("outlet_id", this.outlet);
      form_data.append("territory", this.territory.toLowerCase());
      form_data.append("first_name", this.first_name);
      form_data.append("last_name", this.last_name);
      form_data.append("mobile_number", this.mobile_number);
      form_data.append("base_commission", this.base_commission);
      form_data.append("commission", this.commission);
      form_data.append("sub_commission", this.sub_commission);
      form_data.append("category", this.category);
      form_data.append("wallet", this.wallet);
      form_data.append("link_type", this.link_type);
      form_data.append("status", this.status ? "Active" : "Inactive");

      if (Object.keys(this.investor_data).length == 0) {
        form_data.append("username", this.username);
        form_data.append("password", this.password);
      } else {
        form_data.append("investor_id", this.investor_id);
      }

      return form_data;
    },

    set_close_dialog() {
      this.$emit(
        "set_close_create_update_dialog",
        this.action,
        this.createdUpdatedData
      );
    },

    make_username_alphabet_uppercase() {
      let result = "";
      const characters = "ABCDEF";
      const charactersLength = characters.length;
      let counter = 0;

      while (counter < 1) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },

    make_username_alphabet_lowercase() {
      let result = "";
      const characters = "abcdefghijklmnopqrstuvwxyz";
      const charactersLength = characters.length;
      let counter = 0;

      while (counter < 3) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },

    make_username_number() {
      let result = "";
      const characters = "0123456789";
      const charactersLength = characters.length;
      let counter = 0;

      while (counter < 2) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },

    remove_leading_zero() {
      if (this.mobile_number) {
        this.mobile_number = this.mobile_number.replace(/^0+/, "");
        this.mobile_number = this.mobile_number.replace(/[^\d,.]/g, "");
      }
    },

    remove_alphabet(component) {
      switch (component) {
        case "commission":
          if (this.commission)
            this.commission = this.commission.replace(/[^\d,.]/g, "");
          break;
        case "sub-commission":
          if (this.sub_commission)
            this.sub_commission = this.sub_commission.replace(/[^\d,.]/g, "");
          break;
        case "wallet":
          if (this.wallet) this.wallet = this.wallet.replace(/[^\d,.]/g, "");
          break;
      }
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },

  watch: {
    alert_status(val) {
      if (val) {
        setTimeout(() => {
          this.alert_status = false;
        }, 5000);
      }
    },
  },
};
</script>
