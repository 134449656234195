<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Ending Reports</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Master Agent's Token</h2>
    <v-card class="shadow mb-4">
      <v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
        Search Master Agent
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="py-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row dense class="mb-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                ref="region"
                v-model="region_id"
                :items="regions"
                item-text="region"
                item-value="id"
                label="Region"
                dense
                outlined
                hide-details="auto"
                :disabled="regionsLoading"
                :loading="regionsLoading"
                clearable
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                ref="province"
                v-model="province_id"
                :items="filteredProvinces"
                item-text="province"
                item-value="id"
                label="Province"
                dense
                outlined
                hide-details="auto"
                :disabled="provincesLoading"
                :loading="provincesLoading"
                clearable
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                ref="city"
                v-model="city_id"
                :items="filteredCities"
                item-text="cityMuns"
                item-value="id"
                label="City"
                dense
                outlined
                hide-details="auto"
                :disabled="citiesLoading"
                :loading="citiesLoading"
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row dense class="mb-4">
            <v-col cols="12" md="4">
              <v-autocomplete
                ref="agent"
                v-model="agent_id"
                :items="filteredAgents"
                item-text="agent"
                item-value="id"
                label="Agent"
                dense
                outlined
                hide-details="auto"
                :disabled="agentsLoading"
                :loading="agentsLoading"
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-btn
                :disabled="!valid"
                type="submit"
                color="primary"
                outlined
                :loading="recordsLoading"
              >
                Search
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="shadow">
      <v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
        <v-row dense align="end" justify="space-between">
          <v-col cols="auto"> Master Agent's Token </v-col>
          <v-col v-if="records.length" cols="auto" class="text-right py- 1">
            <downloadexcel
              :data="exportExcelDataWithTotal()"
              :fields="{
                ID: 'id',
                League: 'league',
                Game: 'game',
                'Game Status': 'game_status',
                'Schedule Date': 'schedule',
                'Gross Sales': 'game_total_bet_amount',
                Payouts: 'game_total_prizes',
                'Gross Gaming Revenue': 'net_amount',
                'PAGCOR Share': 'pagcor_share',
                'Net Amount': 'net_income',
              }"
              :name="downloadExcelName()"
              type="csv"
              class="d-inline-block"
            >
              <v-btn color="primary" outlined small depressed> Export CSV </v-btn>
            </downloadexcel>
            <v-btn
              color="primary"
              outlined
              small
              depressed
              @click="exportToPDF"
              class="mx-2 d-inline-block"
            >
              Export PDF
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-card-text class="pa-0">
          <v-data-table
            :headers="headers"
            :items="records"
            :items-per-page="5"
            :search="search"
            :loading="recordsLoading"
            loading-text="Loading, please wait a moment"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item.game`]="{ item }">
              <div>
                <v-avatar size="36px" tile>
                  <v-img :src="item.team_a_logo">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
                <span class="text-caption"
                  >{{ item.team_a_name }} {{ item.team_a_alias }}</span
                >
                <span class="text-overline my-2 mx-2 font-italic">vs</span>
                <v-avatar size="36px" tile>
                  <v-img :src="item.team_b_logo">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
                <span class="text-caption"
                  >{{ item.team_b_name }} {{ item.team_b_alias }}</span
                >
              </div>
            </template>
            <template v-slot:[`item.schedule`]="{ item }">
              <span>{{ scheduleDate(item.schedule) }}</span>
            </template>
            <template v-slot:[`item.game_total_bet_amount`]="{ item }">
              <span>{{ moneyFormat(parseFloat(item.game_total_bet_amount)) }}</span>
            </template>
            <template v-slot:[`item.game_total_prizes`]="{ item }">
              <span>{{ moneyFormat(parseFloat(item.game_total_prizes)) }}</span>
            </template>
            <template v-slot:[`item.net_amount`]="{ item }">
              <span>{{ moneyFormat(parseFloat(item.net_amount)) }}</span>
            </template>
            <template v-slot:[`item.pagcor_share`]="{ item }">
              <span>{{ moneyFormat(parseFloat(item.pagcor_share)) }}</span>
            </template>
            <template v-slot:[`item.net_income`]="{ item }">
              <span>{{ moneyFormat(parseFloat(item.net_income)) }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({
    valid: true,
    agent_id: null,
    region_id: null,
    province_id: null,
    city_id: null,
    search: "",
    headers: [
      {
        text: "Agent",
        sortable: false,
        value: "agents",
        class: "text-uppercase",
        align: "left",
      },
      {
        text: "Token",
        sortable: false,
        value: "token",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "No. of Kubrador",
        sortable: false,
        value: "kubradors",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Total Bets",
        sortable: false,
        value: "bets",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Total Bet Amount",
        sortable: false,
        value: "bet_amount",
        class: "text-uppercase",
        align: "right",
      },
    ],
  }),

  created() {
    this.doGetAreas();
  },

  computed: {
    ...mapState("ending_reports_master_agents_token", [
      "agents",
      "agentsLoading",
      "records",
      "recordsLoading",
      "regions",
      "regionsLoading",
      "provinces",
      "provincesLoading",
      "cities",
      "citiesLoading",
    ]),

    filteredProvinces() {
      if (this.region_id) {
        return this.provinces.filter((province) => {
          return province.region_id == this.region_id;
        });
      }

      return this.provinces;
    },

    filteredCities() {
      if (this.province_id) {
        return this.cities.filter((city) => {
          return city.province_id == this.province_id;
        });
      }

      return this.cities;
    },

    filteredAgents() {
      if (this.city_id) {
        return this.agents.filter((agent) => {
          return agent.citymun_id == this.city_id;
        });
      }

      return this.agents;
    },
  },

  methods: {
    ...mapActions("ending_reports_master_agents_token", ["getAreas"]),

    async doGetAreas() {
      await this.getAreas();
    },
  },
};
</script>
