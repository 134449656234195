var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"master-agents"},[_c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Investors")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Master Agent List")]),_c('v-card',{staticClass:"shadow"},[_c('div',{staticClass:"d-flex justify-end px-6 py-4"},[_c('div',{staticClass:"d-inline-block"},[_c('v-text-field',{ref:"search",attrs:{"placeholder":"Search","single-line":"","hide-details":"auto","disabled":_vm.loading,"clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":_vm.items_per_page,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading, please wait a moment","sort-by":_vm.sort_by,"sort-desc":_vm.sort_desc},on:{"update:sortBy":function($event){_vm.sort_by=$event},"update:sort-by":function($event){_vm.sort_by=$event},"update:sortDesc":function($event){_vm.sort_desc=$event},"update:sort-desc":function($event){_vm.sort_desc=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.total_sub_agent_count",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.total_sub_agent_count))])]}}],null,true)},[_c('ul',{staticStyle:{"list-style-type":"none"}},_vm._l((item.total_sub_agent_breakdown),function(i,breakdown){return _c('li',{key:i},[_vm._v(" "+_vm._s(breakdown + ": " + i)+" ")])}),0)])]}},{key:"item.sales_manager_count",fn:function(ref){
var item = ref.item;
return [(item.sales_manager_count != '0')?_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.get_master_agents_sales_manager(item.id, item.name)}}},[_vm._v(" "+_vm._s("View (" + item.sales_manager_count + ")")+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.regular_player_count",fn:function(ref){
var item = ref.item;
return [(item.regular_player_count != '0')?_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.get_master_agents_regular_player(item.id, item.name)}}},[_vm._v(" "+_vm._s("View (" + item.regular_player_count + ")")+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.mobile_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("+63" + item.mobile_number)+" ")]}},{key:"item.commission",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.commission)+"% ")]}},{key:"item.sub_commission",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.sub_commission)+"%")])]}},{key:"item.wallet",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("₱" + _vm.money_format(parseFloat(item.wallet)))+" ")]}}],null,true)})],1)],1),(_vm.master_agent_dialog)?_c('MasterAgentSubAgents',{attrs:{"master_agent_sub_agent_title":_vm.master_agent_sub_agent_title,"master_agent_id":_vm.master_agent_id,"master_agent_name":_vm.master_agent_name},on:{"close_master_agent_dialog":_vm.close_master_agent_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }