<template>
  <v-card class="bs-shadow mb-4">
    <div class="pa-4 pa-md-6">
      <h3>Player Fund Accounts</h3>
      <v-alert v-if="message" :type="message_status" text class="mt-4 mt-md-6 mb-0">
        {{ message }}
      </v-alert>
    </div>
    <v-divider></v-divider>
    <v-data-table
      :headers="headers"
      :items="player_funds"
      :items-per-page="5"
      :loading="player_fund_loading"
      loading-text="Loading, please wait a moment"
      :sort-by.sync="sort_by"
      :sort-desc.sync="sort_desc"
      :hide-default-header="player_funds && player_funds.length == 0 ? true : false"
      :hide-default-footer="player_funds && player_funds.length == 0 ? true : false"
    >
      <template v-slot:item.bank="{ item }">
        {{ item.bank }}
      </template>

      <template v-slot:item.account_number="{ item }">
        {{ `+63${item.account_number}` }}
        <span v-if="item.is_default == 'Yes'">
          <v-chip small class="light">Default</v-chip>
        </span>
      </template>

      <template v-slot:item.is_verified="{ item }">
        <v-chip small :class="verificationStatus(item.is_verified)">
          {{ item.is_verified }}
        </v-chip>
      </template>

      <template v-slot:item.action="{ item }">
        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              :disabled="item.is_verified == 'Verified' || item.is_verified == 'Rejected'"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              @click="
                setDialog({
                  fund_id: parseInt(item.id),
                  player_id: parseInt(item.player_id),
                  account_number: item.account_number,
                  account_type: item.bank,
                  action: 'verify',
                })
              "
            >
              <v-list-item-title>Verify</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="
                setDialog({
                  fund_id: parseInt(item.id),
                  player_id: parseInt(item.player_id),
                  account_number: item.account_number,
                  bank: item.bank,
                  action: 'reject',
                })
              "
            >
              <v-list-item-title class="red--text">Reject</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-dialog v-model="verify_dialog" max-width="400">
      <PlayerFundAccountVerify
        :fund_data="{
          player_id: fund_player_id,
          account_id: fund_account_id,
          account_number: fund_account_number,
          account_type: fund_account_type,
        }"
        @close_dialog="closeDialog"
        @send_message="sendMessage"
      />
    </v-dialog>

    <v-dialog v-model="reject_dialog" max-width="400">
      <PlayerFundAccountReject
        :fund_data="{
          player_id: fund_player_id,
          account_id: fund_account_id,
          account_number: fund_account_number,
          account_type: fund_account_type,
        }"
        @close_dialog="closeDialog"
        @send_message="sendMessage"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import api_request_get from "@/helpers/api_request_get.js";
import PlayerFundAccountVerify from "@/components/players/player_fund_account/PlayerFundAccountVerify";
import PlayerFundAccountReject from "@/components/players/player_fund_account/PlayerFundAccountReject";

export default {
  data: () => ({
    headers: [
      { text: "Type", value: "bank", class: "text-uppercase" },
      { text: "Account Number", value: "account_number", class: "text-uppercase" },
      { text: "Status", value: "is_verified", class: "text-uppercase" },
      {
        text: "Action",
        value: "action",
        class: "text-uppercase",
        align: "right",
        sortable: false,
      },
    ],
    player_funds: [],
    player_fund_loading: false,
    sort_by: "id",
    sort_desc: true,
    verify_dialog: false,
    reject_dialog: false,
    fund_account_id: 0,
    fund_player_id: 0,
    fund_account_number: "",
    fund_account_type: "",
    message_status: "",
    message: "",
  }),
  mounted() {
    this.getPlayerFundAccounts();
  },
  components: {
    PlayerFundAccountVerify,
    PlayerFundAccountReject,
  },
  methods: {
    async getPlayerFundAccounts() {
      this.player_fund_loading = true;

      const form_data = new FormData();
      form_data.append("player_id", this.$route.params.id);

      const params = new URLSearchParams(form_data);

      const res = await api_request_get(params, "bank_accounts");
      switch (res.status) {
        case 200: {
          this.player_funds = res.data.data;
        }
      }

      this.player_fund_loading = false;
    },
    verificationStatus(value) {
      if (value == "Verified") {
        return "green lighten-5 green--text";
      } else if (value == "Pending") {
        return "orange lighten-5 orange--text";
      } else {
        return "red lighten-5 red--text";
      }
    },
    setDialog(data) {
      this.fund_account_id = data.fund_id;
      this.fund_player_id = data.player_id;
      this.fund_account_number = data.account_number;
      this.fund_account_type = data.bank;

      if (data.action == "verify") {
        this.verify_dialog = true;
      } else {
        this.reject_dialog = true;
      }
    },
    closeDialog(type) {
      if (type == "verify") {
        this.verify_dialog = false;
      } else {
        this.reject_dialog = false;
      }
    },
    sendMessage(data) {
      if (data.type == "verify") {
        this.verify_dialog = false;
      } else {
        this.reject_dialog = false;
      }

      this.getPlayerFundAccounts();
      this.message_status = data.status;
      this.message = data.message;

      setTimeout(() => {
        this.message_status = "";
        this.message = "";
      }, 5000);
    },
  },
};
</script>
