<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Settings</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">SMS</h2>
    <v-card class="shadow mb-5">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" align-self="end">
            <v-btn
              color="primary"
              small
              @click="showTextBlastDialog"
              class="text-capitalize mr-1"
            >
              <v-icon left dark> mdi-message-processing </v-icon>
              <span>Send Text Blast</span>
            </v-btn>
            <v-btn
              color="primary"
              small
              @click="showSmsDialog"
              class="text-capitalize mr-1"
            >
              <v-icon left dark> mdi-message-processing </v-icon>
              <span>Send SMS</span>
            </v-btn>
            <v-btn
              outlined
              color="primary"
              small
              class="text-lowercase"
              :class="{ 'disable-events': true }"
            >
              {{ numberFormat(parseInt(selected.length)) }}
              {{ parseInt(selected.length) > 1 ? "player's" : "player" }}
              selected
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="4" xl="3" class="d-flex">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search a name"
              single-line
              hide-details="auto"
              :disabled="getRecordsLoading"
              clearable
              outlined
              dense
              @keydown.enter.prevent="searchRecords"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-4">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :options.sync="options"
          :items="getRecords"
          :loading="getRecordsLoading"
          loading-text="Loading... Please wait"
          hide-default-footer
          :single-select="false"
          item-key="id"
          show-select
        >
          <template v-slot:[`item.mobile_number`]="{ item }">
            <span>+63{{ item.mobile_number }}</span>
          </template>
        </v-data-table>
        <v-row class="d-flex flex-row-reverse mt-4">
          <v-col cols="4" lg="2">
            <v-select
              v-model="itemsPerPage"
              :items="rowNumbers"
              label="Row per page"
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col>
            <v-chip color="primary" outlined pill>
              Total:
              {{ numberFormat(parseFloat(getTotalRows)) }}
            </v-chip>
          </v-col>
        </v-row>

        <Pagination
          :storeName="storeName"
          :isDisabled="isDisabled"
          @paginatePage="loadRecords"
        />
      </v-card-text>
    </v-card>

    <v-dialog scrollable v-model="smsDialog" max-width="450px" persistent>
      <v-card :disabled="loadingDialog">
        <v-card-title>
          Send {{ textBlast ? "Text Blast" : "SMS" }}
          <v-btn absolute right icon @click="smsDialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-divider />

        <v-card-text class="py-4">
          <v-form
            ref="form"
            @submit.prevent="submitSms"
            lazy-validation
            :disabled="loadingDialog"
          >
            <v-alert dense type="info" v-if="textBlast"
              >This will be sent to
              {{ numberFormat(parseFloat(getTotalRecords)) }} player's</v-alert
            >
            <v-row dense>
              <v-col>
                <v-textarea
                  ref="messageContent"
                  outlined
                  label="Compose your message here"
                  v-model="messageContent"
                  :rules="[rules.required, rules.maximum(160)]"
                  counter="160"
                  rows="7"
                  hide-details="auto"
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col auto align="end">
                <v-btn class="mr-2" @click="smsDialog = false">Cancel</v-btn>
                <v-btn color="primary" type="submit" :loading="loadingDialog"
                  >Confirm</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ConfirmationMessage
      v-if="confirmationDialog"
      :confirmationData="confirmationData"
      @closeConfirmationDialog="closeConfirmationDialog"
      @onSubmit="onSubmit"
    />

    <Snackbar ref="snackbar" />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapGetters, mapActions, mapMutations } from "vuex";
import formRules from "@/helpers/formRules";
import responsePost from "@/helpers/api_request_post";
import ConfirmationMessage from "@/components/ConfirmationMessage.vue";
import Snackbar from "@/components/Snackbar";

export default {
  components: { Pagination, ConfirmationMessage, Snackbar },

  data: () => ({
    sortBy: "",
    search: "",
    sortDesc: true,
    itemsCountPage: 10,
    rowNumbers: [10, 25, 50, 100, 500],
    headers: [
      {
        text: "Player Name",
        value: "player_name",
        class: "text-uppercase",
      },
      {
        text: "Mobile Number",
        sortable: false,
        value: "mobile_number",
        class: "text-uppercase",
      },
    ],
    options: {},
    storeName: "sms",
    currentSearchValue: "",
    selected: [],
    smsDialog: false,
    loadingDialog: false,
    messageContent: "",
    rules: formRules,
    compiledIds: [],
    confirmationDialog: false,
    confirmationData: {
      title: "Are you sure you want to submit?",
      text: "This action cannot be undone.",
      closeBtnText: "Cancel",
      closeBtnColor: "gray",
      saveBtnText: "Submit",
      saveBtnColor: "success",
    },
    textBlast: false,
  }),

  created() {
    this.$store.commit("sms/setRecords", []);
  },

  computed: {
    ...mapGetters("sms", [
      "getRecords",
      "getRecordsLoading",
      "getCurrentPage",
      "getTotalRows",
      "getTotalRecords",
    ]),

    itemsPerPage: {
      get() {
        return this.itemsCountPage;
      },

      set(val) {
        this.itemsCountPage = val;
      },
    },

    isDisabled() {
      return this.currentSearchValue !== this.search;
    },
  },

  methods: {
    ...mapMutations("sms", ["setCurrentPage"]),
    ...mapActions("sms", ["fetchRecords"]),

    numberFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    searchRecords() {
      this.currentSearchValue = this.search;
      this.setCurrentPage(1);
      this.loadRecords();
    },

    async loadRecords() {
      const formData = new FormData();
      formData.append("search", this.currentSearchValue);
      formData.append("itemsPerPage", this.itemsPerPage);
      formData.append("sortBy", this.sortBy ? this.sortBy : "");
      formData.append("sortDesc", this.sortDesc ? this.sortDesc : "");
      formData.append("page", this.getCurrentPage);

      const getData = new URLSearchParams(formData);

      this.fetchRecords(getData);
    },

    showTextBlastDialog() {
      this.textBlast = true;
      this.smsDialog = true;
      setTimeout(() => {
        this.$refs.messageContent.focus();
      }, 300);
    },

    showSmsDialog() {
      if (this.selected.length) {
        this.textBlast = false;
        this.smsDialog = true;
        setTimeout(() => {
          this.$refs.messageContent.focus();
        }, 300);
      } else {
        alert("Please select a player!");
      }
    },

    submitSms() {
      if (this.$refs.form.validate()) {
        this.confirmationDialog = true;
      }
    },

    async onSubmit() {
      this.loadingDialog = true;
      this.confirmationDialog = false;

      try {
        const form_data = new FormData();
        form_data.append("ids", JSON.stringify(this.compiledIds));
        form_data.append("messageContent", this.messageContent);
        form_data.append("textBlast", this.textBlast);

        const response = await responsePost(form_data, "sms");

        if (response.status == 200) {
          this.$refs.snackbar.show(
            "success",
            "mdi-check-circle-outline",
            "Message sent successfully!",
            4000
          );
        } else {
          alert("An error occurred, Please refresh the page.");
        }
      } catch (error) {
        alert("An error occurred, Please refresh the page.");
      } finally {
        this.loadingDialog = false;
        this.textBlast = false;
        this.smsDialog = false;
      }
    },

    closeConfirmationDialog() {
      this.confirmationDialog = false;
    },
  },

  watch: {
    search(val) {
      if (val === null) {
        this.search = "";
      }
    },

    options() {
      if (this.options.sortBy) {
        switch (this.options.sortBy[0]) {
          case "player_name":
            this.sortBy = "player_name";
            break;
          default:
            this.sortBy = null;
            break;
        }
      }

      if (this.options.sortDesc) {
        this.sortDesc = this.options.sortDesc[0];
      }

      this.loadRecords();
    },

    itemsPerPage(oldVal) {
      if (oldVal) {
        if (!this.getRecordsLoading) {
          this.options.itemsPerPage = this.itemsPerPage;
          this.searchRecords();
        }
      }
    },

    smsDialog(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    },

    selected(val) {
      if (val.length) {
        this.compiledIds = [];

        this.selected.forEach((element) => {
          this.compiledIds.push(parseInt(element.id));
        });
      } else {
        this.compiledIds = [];
      }
    },
  },
};
</script>

<style scoped>
.disable-events {
  pointer-events: none;
}
</style>
