<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Settings</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Advertisement</h2>
    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="setCreateAdsImage()"
                >
                  <v-icon>mdi-file-upload-outline</v-icon>
                </v-btn>
              </template>

              <span>Upload Ads Image</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  @click="dogetRecords"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="recordsLoading"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="5"
          :search="search"
          :loading="recordsLoading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dense
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  color="primary"
                  @click="setAdsId(item.id)"
                >
                  <v-icon size="large"> mdi-square-edit-outline </v-icon>
                </v-btn>
              </template>
              <span>Update</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.logo`]="{ item }">
            <div>
              <v-avatar size="36px" tile>
                <v-img :src="item.logo">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="black"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </div>
          </template>

          <template v-slot:[`item.mobile_number`]="{ item }">
            <span>+63{{ item.mobile_number }}</span>
          </template>

          <template v-slot:[`item.expiration_date`]="{ item }">
            <span>{{ setCreatedAtFormat(item.expiration_date) }}</span>
          </template>

          <template v-slot:[`item.is_active`]="{ item }">
            <v-chip
              small
              :class="
                item.is_active == 'Active'
                  ? 'green lighten-5 green--text'
                  : 'red lighten-5 red--text'
              "
            >
              {{ item.is_active }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <CreateUpdate
      v-if="createUpdateDialog"
      @setCloseDialog="setCloseDialog"
      :adsImagesData="adsImagesData"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import formatDatev2 from "@/helpers/formatDatev2";
import CreateUpdate from "@/components/ads_images/CreateUpdateAdsImages";

export default {
  components: { CreateUpdate },

  data: () => ({
    search: "",
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      {
        text: "Name/ Description",
        sortable: false,
        value: "description",
        class: "text-uppercase",
      },
      {
        text: "Expiration",
        sortable: false,
        value: "expiration_date",
        class: "text-uppercase",
      },
      {
        text: "Status",
        sortable: false,
        value: "is_active",
        class: "text-uppercase",
      },
    ],
    sortBy: "created_at",
    sortDesc: true,
    createUpdateDialog: false,
    adsImagesData: {},
  }),

  computed: {
    ...mapState("ads_images", ["records", "recordsLoading"]),
  },

  mounted() {
    this.dogetRecords();
  },

  methods: {
    ...mapActions("ads_images", ["getRecords"]),

    async dogetRecords() {
      await this.getRecords();
    },

    setCloseDialog(status) {
      this.createUpdateDialog = false;

      if (status) this.dogetRecords();
    },

    setCreatedAtFormat(date) {
      return formatDatev2(date, "date");
    },

    setCreateAdsImage() {
      this.adsImagesData = {};
      this.createUpdateDialog = true;
    },

    setAdsId(id) {
      this.adsImagesData = {
        adsId: id,
      };
      this.createUpdateDialog = true;
    },
  },
};
</script>
