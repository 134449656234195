import Pusher from "pusher-js";

let pusher;

const pusherConnect = () => {
  if (!pusher) {
    pusher = new Pusher(process.env.VUE_APP_PUSHER_ID, {
      cluster: "ap1",
    });
  }
};

/* SUBSCRIBE */
const pusherAddWithdrawalAccountSubscribe = () => {
  const channel = pusher.subscribe("notification");

  return channel;
};

/* UNSUBSCRIBE */
const pusherAddWithdrawalAccountUnsubscribe = () => {
  pusher.unsubscribe("notification");
};

export {
  pusherConnect,
  pusherAddWithdrawalAccountSubscribe,
  pusherAddWithdrawalAccountUnsubscribe,
};
