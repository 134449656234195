<template>
    <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="500">
        <v-card :loading="removePlayerLoading">
            <v-card-title class="text-h5"> Confirm
                <v-btn icon absolute right @click="setCloseModal">
                    <v-icon size="large">
                    mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text class="py-4">
                <v-row dense>
                    <v-col>
                        <p class="font-weight-medium">
                            Are you sure you want to <strong>remove</strong> this player <strong>{{ agent_and_player_id.player_name }}</strong>?
                        </p>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col>
                        <v-btn
                        ref="confirm"
                            block
                            depressed
                            type="submit"
                            color="primary"
                            :disabled="removePlayerLoading"
                            @click="removePlayerFromAgent">
                            Confirm
                        </v-btn>
                    </v-col>

                    <v-col>
                        <v-btn
                            block
                            depressed
                            type="button"
                            :disabled="removePlayerLoading"
                            @click="cancelConfirmation">
                            Cancel
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    props: {
        agent_and_player_id: {
            type: Object
        }
    },

    computed: {
        ...mapState("agents", ["removePlayerLoading"]),

        dialog: {
            get: function () {
                return true;
            },
            set: function () {
                this.confirmationStatus = { "status": "cancel" }
                this.setCloseModal();
            },
        },
    },

    data: () => ({
        confirmationStatus: {}
    }),

    mounted() {
        this.$refs.confirm.$el.focus()
    },

    methods: {
        ...mapActions("agents", ["removePlayer"]),

        async removePlayerFromAgent() {
            if (!this.removePlayerLoading) {
                await this.removePlayer(this.setAgentIdAndPlayerIdFormData())

                this.confirmationStatus = { "status": "confirm" }
                this.setCloseModal();
            }
        },

        setAgentIdAndPlayerIdFormData() {
            const formData = new FormData()
            formData.append("agent_id", this.agent_and_player_id.agent_id)
            formData.append("player_id", this.agent_and_player_id.player_id)

            return formData
        },

        setCloseModal() {
            this.$emit("setConfirmationModal", this.confirmationStatus)
        },

        cancelConfirmation() {
            this.confirmationStatus = { "status": "cancel" }
            this.setCloseModal();
        }
    }
}
</script>