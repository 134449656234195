<template>
    <v-dialog scrollable v-model="dialog" max-width="600">
        <v-card :loading="addPlayerLoading">
            <v-card-title class="text-h5"> Add Player </v-card-title>

            <v-btn icon absolute right class="mt-4" @click="setCloseModal">
                <v-icon size="large">
                mdi-close
                </v-icon>
            </v-btn>

            <v-divider></v-divider>

            <v-card-text class="py-4">
                <v-form v-if="(Object.keys(playerInfo).length == 0)"
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    :disabled="addPlayerLoading"
                    @submit.prevent="search">

                    <v-row>
                        <v-col cols="12">
                            <span inline class="mb-1  mt-2 overline primary--text">Player's Mobile Number</span>
                            <v-text-field ref="mobile_number"
                                outlined
                                dense
                                :rules="(searchStatus) ? [
                                rules.required,
                                rules.validMobileNumber,
                                mobileNumberNotExist,
                                isPlayerAlreadyAgent
                                ] : []"
                                maxlength="10"
                                v-model="mobile_number"
                                hide-details="auto"
                                prefix="+63"
                                @keyup="removeMobileNumberLeadingZero"
                            />
                        </v-col>
                    </v-row>

                    <v-row dense class="mt-4">
                        <v-col cols="12" md="6">
                            <v-btn block depressed :disabled="addPlayerLoading" color="primary" type="submit">
                                Search
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-btn block depressed :disabled="addPlayerLoading" @click="setCloseModal">
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>

                <v-form  ref="form2"
                    v-if="Object.keys(playerInfo).length"
                    v-model="valid2"
                    lazy-validation
                    @submit.prevent="submit">

                    <v-alert
                        dense
                        border="left"
                        type="warning"
                        v-if="playerExistence">
                        Player already added to other agent
                    </v-alert>

                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody class="table-borderless">
                            <tr>
                                <td style="width: 30%" class="grey--text">Mobile Number </td>
                                <td>
                                <span>+63{{ playerInfo.mobile_number }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="grey--text">Player's Name</td>
                                <td>
                                <span>{{ playerInfo.name }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="grey--text">Birthday</td>
                                <td>
                                <span>{{ playerInfo.birthdate }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="grey--text">Email</td>
                                <td>
                                <span>{{ playerInfo.email_address }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="grey--text">Status</td>
                                <td>
                                <v-chip small
                                    
                                    :class="playerInfo.is_active=='Active' ? 'green lighten-5 green--text' : 'red lighten-5 red--text'"
                                    >
                                    {{ playerInfo.is_active }}
                                </v-chip>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <v-row dense class="mt-4">
                        <v-col cols="12" md="6">
                            <v-row dense>
                                <v-col cols="12" md="6">
                                    <v-btn block depressed outlined color="indigo" :disabled="addPlayerLoading" @click="resetData" class="d-none d-md-block">
                                        Search Again
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-row dense>
                                <v-col cols="12" md="6">
                                    <v-btn block depressed color="primary" :disabled="addPlayerLoading" type="submit">
                                        Add
                                    </v-btn>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-btn block depressed :disabled="addPlayerLoading" @click="setCancelModal">
                                        Cancel
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex"
import formRules from "@/helpers/formRules"

export default {
    props: {
        agent_data: {
            type: Object
        }, 
    },

    data: () => ({
        valid: true,
        valid2: true,
        rules: formRules,
        mobile_number: '',
        mobileNumberExistText: '',
        mobileNumberErrorMessage: '',
        searchStatus: false,
        playerExistence: false,
        addedPlayer: {}
    }),

    mounted() {
        this.$refs.mobile_number.focus()
    },

    computed: {
        ...mapState("agents", ["addPlayerLoading", "playerInfo", "error", "errors"]),
        dialog: {
            get: function () {
                return true;
            },
            set: function () {
                this.addedPlayer = { "status" : 'cancel' }
                this.setCloseModal();
            },
        },

        isPlayerAlreadyAgent() {
            if (this.agent_data.mobile_number == this.mobile_number) {
                return 'You cannot add agent (himself/ herself) as a player (itself)'
            } else return true
        },
    },

    methods: {
        ...mapActions("agents", ["getPlayerInfo", "getAgentPlayers", "addPlayer"]),

        async search() {
            this.searchStatus = true

            await new Promise(r => setTimeout(r, 200));

            if (this.$refs.form.validate()) {
                if (!this.addPlayerLoading) {
                    this.mobileNumberErrorMessage = ''
                    await this.getPlayerInfo(this.setSearchFormData())

                    if (this.error || !this.isObjectEmpty(this.errors)) {
                        this.setErrors()
                    }
                }
            }          
        },

        setSearchFormData() {
            const formData = new FormData()
            formData.append("mobile_number", this.mobile_number)

            const getData = new URLSearchParams(formData)

            return getData
        },

        setErrors() {
            Object.entries(this.errors).forEach(([key, value]) => {
                if (value) {
                    if (key == "mobile_number") {
                        this.mobileNumberExistText = this.mobile_number
                        this.mobileNumberErrorMessage = value

                        this.$refs.form.validate();
                        this.$refs['mobile_number'].focus();
                    }             
                    
                    if (key == "player_id") {
                        this.playerExistence = true
                    }
                }
            });
        },

        async submit() {
            if (!this.addPlayerLoading) {
                this.searchStatus = true
                this.mobileNumberErrorMessage = ''
                this.playerExistence = false

                await this.addPlayer(this.setSubmitFormData())

                if (!this.error && this.isObjectEmpty(this.errors)) {
                    this.addedPlayer = {
                        "status": 'add',
                        "agent_id": this.agent_data.agent_id
                    }

                    this.setCloseModal()
                    
                    await this.getAgentPlayers(this.setAgentPlayersFormData())
                } else this.setErrors()
            }
        },

        setAgentPlayersFormData() {
            const formData = new FormData()
            formData.append("agent_id", this.agent_data.agent_id)

            const getData = new URLSearchParams(formData)

            return getData
        },

        setSubmitFormData() {
            const formData = new FormData()
            formData.append("agent_id", this.agent_data.agent_id)
            formData.append("player_id", this.playerInfo.player_id)

            return formData
        },

        setCloseModal() {
            this.$store.commit("agents/setPlayerInfo", [])
            this.$emit("setAddPlayerModal", this.addedPlayer)
        },

        setCancelModal() {
            this.addedPlayer = { "status" : 'cancel' }
            this.setCloseModal()
        },

        mobileNumberNotExist(val) {
            if (val == this.mobileNumberExistText) {
                return this.mobileNumberErrorMessage
            } else return true
        },

        removeMobileNumberLeadingZero() {
            this.mobile_number = this.mobile_number.replace(/^0+/, "");
        },

        resetData() {
            this.mobile_number = ''
            this.searchStatus = false
            this.playerExistence = false
            this.$store.commit("agents/setPlayerInfo", [])

            setTimeout(() => {
                this.$refs.mobile_number.focus();
            }, 200);
        },

        isObjectEmpty(obj) {
          return Object.keys(obj).length === 0;
        },
    }
}
</script>