<template>
  <v-menu
    v-model="menu"
    transition="slide-y-transition"
    left
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="grey" dark v-bind="attrs" v-on="on">
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-sheet class="pa-4 pa-md-6" style="width: 400px">
      <div
        class="d-flex justify-space-between bs-gap-4 mb-4"
        style="width: 100%"
      >
        <span class="font-weight-bold">Other Details</span>
        <a href="#" @click="menu = false">Close</a>
      </div>
      <div class="d-flex flex-column bs-gap-2">
        <div class="d-flex justify-space-between bs-gap-4" style="width: 100%">
          <span class="grey--text">Marital Status</span>
          <span class="text-end">{{ data.marital_status }}</span>
        </div>
        <div class="d-flex justify-space-between bs-gap-4" style="width: 100%">
          <span class="grey--text">Date of Birth</span>
          <span class="text-end">{{ data.birthdate }}</span>
        </div>
        <div class="d-flex justify-space-between bs-gap-4" style="width: 100%">
          <span class="grey--text">Nationality</span>
          <span class="text-end">{{ data.nationality }}</span>
        </div>
        <div class="d-flex justify-space-between bs-gap-4" style="width: 100%">
          <span class="grey--text">Source of Income</span>
          <span class="text-end">{{ data.source_of_income }}</span>
        </div>
        <div class="d-flex justify-space-between bs-gap-4" style="width: 100%">
          <span class="grey--text">Nature of Work</span>
          <span class="text-end">{{ data.nature_of_work }}</span>
        </div>
        <div class="d-flex justify-space-between bs-gap-4" style="width: 100%">
          <span class="grey--text">Address</span>
          <span class="text-end">{{ data.address }}</span>
        </div>
        <div
          class="d-flex justify-space-between bs-gap-4"
          style="width: 100%"
          v-if="data.account_status == 'Inactive'"
        >
          <span class="grey--text">Admin Deactivated Reason</span>
          <span class="text-end">{{ data.admin_reason }}</span>
        </div>
        <div
          class="d-flex justify-space-between bs-gap-4"
          style="width: 100%"
          v-if="data.account_status == 'Inactive'"
        >
          <span class="grey--text">Player Deactivated Reason </span>
          <span class="text-end">{{ data.player_reason }}</span>
        </div>
        <div class="d-flex justify-space-between bs-gap-4" style="width: 100%">
          <span class="grey--text">Active Status </span>
          <!-- <span class="text-end">{{ data.account_status }}</span> -->
          <v-chip
            small
            :class="
              data.account_status == 'Active'
                ? 'green lighten-5 green--text'
                : 'red lighten-5 red--text'
            "
          >
            {{ data.account_status }}
          </v-chip>
        </div>
      </div>
    </v-sheet>

    <!-- <v-list dense>
      <div class="d-flex flex-column" style="gap: 1rem">
        <div class="d-flex justify-space-between align-center px-4">
          <span class="font-weight-medium">Other Details</span>
          <v-btn plain icon @click="menu = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="d-flex flex-column" style="gap: 0.3rem">
          <div class="d-flex justify-space-between white px-4">
            <span>Marital Status</span>
            <span>{{ data.marital_status }}</span>
          </div>
        </div>

        <div class="d-flex flex-column" style="gap: 0.3rem">
          <div class="d-flex justify-space-between white px-4">
            <span>Birthday</span>
            <span>{{ data.birthdate }}</span>
          </div>
        </div>

        <div class="d-flex flex-column" style="gap: 0.3rem">
          <div class="d-flex justify-space-between white px-4">
            <span>Nationality</span>
            <span>{{ data.nationality }}</span>
          </div>
        </div>

        <div class="d-flex flex-column" style="gap: 0.3rem">
          <div class="d-flex justify-space-between white px-4">
            <span>Source Of Income</span>
            <span>{{ data.source_of_income }}</span>
          </div>
        </div>

        <div class="d-flex flex-column" style="gap: 0.3rem">
          <div class="d-flex justify-space-between white px-4">
            <span>Nature of Work</span>
            <span>{{ data.nature_of_work }}</span>
          </div>
        </div>

        <div class="d-flex flex-column" style="gap: 0.3rem">
          <div class="d-flex justify-space-between white px-4">
            <span>Nature of Business</span>
            <span>{{ data.nature_of_business }}</span>
          </div>
        </div>

        <div class="d-flex flex-column px-4" style="gap: 0.3rem">
          <span>Address:</span>
          <span>{{ data.address }}</span>
        </div>
      </div>
    </v-list> -->
  </v-menu>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },

  data: () => ({
    menu: false,
  }),
};
</script>
