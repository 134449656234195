<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card class="shadow">
      <div class="d-flex justify-space-between align-center px-6 py-4">
        <h3>Filter</h3>
        <v-btn icon @click="close_dialog">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <div class="pa-6">
        <v-form ref="form" @submit.prevent="do_filter">
          <div class="d-flex flex-column gap">
            <v-menu
              v-model="date_from_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_from"
                  :rules="[rules.required]"
                  label="Date From"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @keyup="date_from_menu = true"
                  @keydown="date_from_menu = false"
                  hide-details="auto"
                  clearable
                ></v-text-field>
              </template>

              <v-date-picker
                v-model="date_from"
                @input="date_from_menu = false"
              ></v-date-picker>
            </v-menu>

            <v-menu
              v-model="date_to_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_to"
                  label="Date To"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @keyup="date_to_menu = true"
                  @keydown="date_to_menu = false"
                  hide-details="auto"
                  clearable
                ></v-text-field>
              </template>

              <v-date-picker
                v-model="date_to"
                @input="date_to_menu = false"
              ></v-date-picker>
            </v-menu>
          </div>

          <div class="d-flex gap-sm justify-end mt-6">
            <v-btn depressed text color="black" @click="close_dialog"> Cancel </v-btn>
            <v-btn depressed type="submit" color="primary"> Filter </v-btn>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import form_rules from "@/helpers/formRules";

export default {
  props: {
    filter_dialog_data: {
      type: Object,
    },
  },

  data: () => ({
    rules: form_rules,
    date_from: null,
    date_from_menu: false,
    date_to: null,
    date_to_menu: false,
  }),

  mounted() {
    this.date_from = this.filter_dialog_data.date_from;
    this.date_to = this.filter_dialog_data.date_to;
  },

  computed: {
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.close_dialog();
      },
    },
  },

  methods: {
    close_dialog() {
      this.$emit("set_close_filter_dialog", {});
    },

    do_filter() {
      this.$emit("set_close_filter_dialog", {
        date_from: this.date_from,
        date_to: this.date_to ?? this.date_from,
      });
    },
  },
};
</script>
