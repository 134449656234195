var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Ending Reports")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Players")]),_c('v-card',{staticClass:"shadow-none"},[_c('v-card-text',{staticClass:"d-flex flex-column pb-0",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column justify-space-between",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":".30rem"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey lighten-5",attrs:{"icon":"","disabled":_vm.records_loading},on:{"click":_vm.doGetPlayers}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('downloadexcel',{staticClass:"d-inline-block",attrs:{"data":_vm.records,"fields":{
                'TYPE': 'type',
                'AGENT': 'agent',
                'NAME': 'name',
                'MOBILE NUMBER': {
                  callback: function (value) {
                    return ("+63" + (value.mobile_number))
                  },
                },
                'EMAIL': 'email',
                'ACCOUNT STATUS': 'account_status',
                'ACTIVE STATUS': 'status',
                'REGISTERED DATE': {
                  callback: function (value) {
                    return _vm.format_date_time(value.created_at)
                  },
                },
              },"name":_vm.downloadExcelName(),"type":"csv"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"grey lighten-5",attrs:{"icon":"","color":"secondary","disabled":_vm.records_loading}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-delimited-outline")])],1)],1)]}}])},[_c('span',[_vm._v("Export to Excel")])])],1),_c('div',{staticClass:"d-flex",staticStyle:{"gap":".30rem"}},[_c('v-text-field',{attrs:{"autofocus":"","dense":"","outlined":"","label":"Search","placeholder":"Search","hide-details":"","disabled":_vm.records_loading},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey lighten-5",attrs:{"icon":"","disabled":_vm.records_loading},on:{"click":_vm.set_filter_dialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}])},[_c('span',[_vm._v("Filter")])])],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"search":_vm.search,"loading":_vm.records_loading,"loading-text":"Loading, please wait a moment","items-per-page":5},scopedSlots:_vm._u([{key:"item.mobile_number",fn:function(ref){
              var item = ref.item;
return [_vm._v(" +63"+_vm._s(item.mobile_number)+" ")]}},{key:"item.account_status",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{class:item.account_status == 'Verified' ? 'green lighten-5 green--text' : item.account_status == 'Pending' ? 'orange lighten-5 orange--text' : item.account_status == 'Rejected' ? 'red lighten-5 red--text' : 'blue lighten-5 blue--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.account_status)+" ")])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{class:item.status == 'Active' ? 'green lighten-5 green--text' : 'red lighten-5 red--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.format_date(item.created_at))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.format_time(item.created_at)))])])]}}],null,true)})],1)],1),(_vm.filter_dialog)?_c('Filters',{attrs:{"data":_vm.filter_data},on:{"set_close_filter_dialog":_vm.set_close_filter_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }