<template>
    <v-dialog scrollable :fullscreen="fullScreenStatus" v-model="dialog" max-width="1200px">
        <v-card>
            <v-card-title class="text-h6 font-weight-regular grey--text text--darken-1">
                <v-icon class="mr-2"> mdi-account-cash-outline </v-icon> CASHOUT REQUESTS

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon 
                            absolute 
                            right 
                            class="mr-8" 
                            v-bind="attrs"
                            v-on="on" 
                            @click="fullScreenStatus = !fullScreenStatus">
                            <v-icon size="large">
                                {{ `mdi-${(fullScreenStatus) ? 'dock-window' : 'window-maximize'}` }}
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ `${(fullScreenStatus) ? 'Normal View' : 'Maximize'}` }}</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon absolute right @click="closeModal">
                            <v-icon size="large"
                                v-bind="attrs"
                                v-on="on">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Close</span>
                </v-tooltip>
            </v-card-title>

            <v-divider/>

            <v-card-text class="py-4">
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="doGetCashOutRequest">

                    <v-row dense>
                        <v-col cols="12" md="4">
                            <v-select
                                v-model="filter"
                                :items="filterOptions"
                                :rules="[rules.required]"
                                label="Filter By"
                                dense
                                outlined
                                hide-details="auto"
                            />
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-menu
                                v-model="date_from_menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">

                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="date_from"
                                        :rules="[rules.required, lessThanDate]"
                                        :label="`${filter} From`"
                                        hide-details="auto"
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        @keyup="date_from_menu=true"
                                        @keydown="date_from_menu=false"
                                        clearable/>
                                </template>

                                <v-date-picker
                                v-model="date_from"
                                @input="date_from_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" md="4">
                            <v-menu
                                v-model="date_to_menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                :disabled="date_from == ''"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="date_to"
                                    :label="`${filter} To (Optional)`"
                                    hide-details="auto"
                                    readonly
                                    outlined
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="dateToAvailability"
                                    @keyup="date_to_menu=true"
                                    @keydown="date_to_menu=false"
                                    clearable
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date_to"
                                    @input="date_to_menu = false"
                                    :min="(date_from) ? date_from : ''"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row dense class="mt-4">
                        <v-col>
                            <v-row dense>
                                <v-col>
                                    <v-btn
                                        depressed
                                        type="submit"
                                        color="primary"
                                        :loading="cashOutDetailsLoading">
                                        Search
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>

                <v-simple-table dense class="mt-4">
                    <template v-slot:default>
                        <tbody class="table-borderless">
                            <tr>
                                <td style="width: 250px" class="grey--text">NAME</td>
                                <td>
                                    <span>{{ cashout_data.name }}</span>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td style="width: 250px" class="grey--text">TYPE</td>
                                <td>
                                    <span>{{ cashout_data.user_type }}</span>
                                </td>
                            </tr> -->
                            <tr>
                                <td style="width: 250px" class="grey--text">PROVINCE</td>
                                <td>
                                    <span>{{ cashout_data.province }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 250px" class="grey--text">CITY/ MUNICIPALITY</td>
                                <td>
                                    <span>{{ cashout_data.city }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 150px" class="grey--text">TOTAL CASHOUT</td>
                                <td>
                                    <span class="green--text">PHP {{ moneyFormat(parseFloat(totalCashOut)) }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                 <v-row dense justify="space-between" class="mt-4">
                    <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
                        <!-- 'Type': 'type', -->
                        <downloadexcel
                            :data="cashOutDetails" 
                            :fields="{
                                'Payment Method': 'payment_method',
                                'Account Number': 'account_number',
                                'Amount': 'amount',
                                'Remarks': 'remarks',
                                'Requested At': 'created_at',
                            }"
                            name="Cash In Request.xls"
                            type="csv"
                            class="d-inline-block">
                                <v-btn outlined color="primary" >
                                    Export CSV
                                </v-btn>
                        </downloadexcel>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            placeholder="Search"
                            single-line
                            hide-details="auto"
                            :disabled="cashOutDetailsLoading"
                            clearable
                            outlined
                            dense>
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-data-table
                    :headers="headers"
                    :items="cashOutDetails"
                    :items-per-page="5"
                    :search="search"
                    :loading="cashOutDetailsLoading"
                    loading-text="Loading, please wait a moment"
                    class="mt-2">

                    <template v-slot:[`item.amount`]="{ item }">
                        <span>PHP {{ moneyFormat(parseFloat(item.amount)) }} </span>
                    </template>

                    <template v-slot:[`item.created_at`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">{{ setCreatedAtFormat(item.created_at) }} </span>
                            </template>
                            <span>{{ setCreatedAtFormatToolTip(item.created_at) }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import downloadexcel from 'vue-json-excel'
import formRules from '@/helpers/formRules'
import { mapActions, mapState } from 'vuex'
import formatDatev2 from '@/helpers/formatDatev2'

export default {
    props: {
        cashout_data: {
            type: Object
        }    
    },

    components: {
        downloadexcel
    },

    data: () => ({
        valid: true,
        rules: formRules,
        search: '',
        date_from: null,
        date_to: null,
        date_from_menu: false,
        date_to_menu: false,
        filterOptions: ["Pending", "Approved", "Disapproved"],
        filter: "Approved",
        player_id: '',
        fullScreenStatus: false,
        headers: [
            { 
                text: "Payment Method", 
                value: "payment_method", 
                class: "text-uppercase",
                align: "left" },
            // {
            //     text: "Type",
            //     value: "type",
            //     class: "text-uppercase",
            //     align: "left" 
            // },
            {
                text: "Account Number",
                value: "account_number",
                class: "text-uppercase",
                align: "left" 
            },
            {
                text: "Amount",
                value: "amount",
                class: "text-uppercase",
                align: "right"
            },
            {
                text: "Remarks",
                value: "remarks",
                class: "text-uppercase",
                align: "left"
            },
            {
                text: "Requested At",
                value: "created_at",
                class: "text-uppercase",
                align: "left"
            },
        ],
    }),

    mounted() {
        this.player_id = this.cashout_data.player_id
        this.filter = this.cashout_data.filter
        this.date_from = this.cashout_data.date_from
        this.date_to = this.cashout_data.date_to

        this.doLoadCashOutRequest()
    },

    computed: {
        ...mapState("ending_reports_cashout", ["cashOutDetails", "cashOutDetailsLoading"]),

        dialog: {
            get: function() {
                return true
            },
            set: function() {
                this.closeModal()
            }
        },

        dateToAvailability() {
            if (this.date_from == '' || this.date_from == null) {
                return true
            }

            return false
        },

        lessThanDate() { 
            if (this.date_from && this.date_to) {
                if (new Date(this.date_to) < new Date(this.date_from)) {
                    return 'Must be higher or equal than date from.'
                } 
            } 
            
            return true
        },

        totalCashOut() {
            if (this.cashOutDetails) {
                return this.cashOutDetails.reduce((total, obj) => parseInt(obj.amount) + total ,0)
            } else {
                return 0
            }
        }
    },

    methods: {
        ...mapActions("ending_reports_cashout", ["getCashOutRequests"]),

        async doGetCashOutRequest() {
            if (this.$refs.form.validate()) {
                if (!this.cashOutDetailsLoading) {
                    this.$store.commit("ending_reports_cashout/setCashOutDetails", [])

                    await this.getCashOutRequests(this.setFormData())
                }
            }
        },

        async doLoadCashOutRequest() {
            this.$store.commit("ending_reports_cashout/setCashOutDetails", [])

            await this.getCashOutRequests(this.setFormData())
        },

        setFormData() {
            const formData = new FormData()

            formData.append("player_id", this.player_id)
            formData.append("filter", (this.filter) ?? '')
            formData.append("date_from", this.date_from)
            formData.append("date_to", ((this.date_to) ?? this.date_from))

            const getData = new URLSearchParams(formData) 

            return getData
        },

        setCreatedAtFormat(date) {
          return formatDatev2(date, 'date');
        },

        setCreatedAtFormatToolTip(date) {
            return formatDatev2(date)
        },

        moneyFormat(val) {
            return val.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },

        closeModal() {
            this.$emit("setCashOutRequestCloseModal")
        }
    }
}
</script>