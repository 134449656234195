<template>
    <div>
        <v-dialog
            v-model="dialog"
            max-width="500">
            <v-card>
                <v-card-title class="text-h5 d-inline-block">
                    Filter

                    <v-btn icon absolute right @click="cancelSearch">
                        <v-icon size="large">
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-card-title>

                <v-divider/>

                <v-card-text class="py-4">
                    <v-form ref="form"
                        v-model="valid"
                        lazy-validation
                        @submit.prevent="proceedToSearch">

                        <v-row dense>
                            <v-col>
                                <span inline class="mb-1  mt-2 overline primary--text">Filter By</span>
                                <v-select v-model="filter"
                                    :items="filters"
                                    dense
                                    outlined
                                    @change="setFilterFocus"
                                    hide-details="auto"/>
                            </v-col>
                        </v-row>

                        <v-divider class="my-2"/>

                        <v-row dense class="mt-4" v-if="filter == 'Date Created'">
                            <v-col>
                                <v-menu
                                v-model="date_from_menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    v-model="date_from"
                                    :rules="[rules.required, lessThanDate2]"
                                    label="Date From"
                                    hide-details="auto"
                                    readonly
                                    outlined   
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    @keyup="date_from_menu=true"
                                    @keydown="date_from_menu=false"
                                    clearable
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date_from"
                                    @input="date_from_menu = false"
                                ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>

                        <v-row dense class="mt-4" v-if="filter == 'Date Created'">
                            <v-col>
                                <v-menu
                                    v-model="date_to_menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="date_to"
                                        :rules="[lessThanDate2]"
                                        label="Date To (Optional)"
                                        hide-details="auto"
                                        readonly
                                        outlined   
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        @keyup="date_to_menu=true"
                                        @keydown="date_to_menu=false"
                                        :disabled="dateToAvailability"
                                        clearable
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        @input="date_to_menu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>

                        <v-row dense class="mt-4" v-if="filter == 'Mobile Number'">
                            <v-col>
                                <v-text-field ref="mobile_number"
                                    outlined
                                    dense
                                    label="Mobile Number"
                                    :rules="(searchStatus) ? [
                                        rules.required,
                                        rules.validMobileNumber,
                                    ] : []"
                                    maxlength="10"
                                    v-model="mobile_number"
                                    hide-details="auto"
                                    prefix="+63"
                                    clearable
                                    @keyup="removeMobileNumberLeadingZero"/>
                            </v-col>
                        </v-row>

                        <v-row dense class="mt-4" v-if="filter == 'Agent Name'">
                            <v-col>
                                <v-text-field
                                    ref="first_name"
                                    v-model="first_name"
                                    label="First Name"
                                    :rules="(searchStatus) ? [valueValidation] : []"
                                    outlined
                                    dense
                                    clearable
                                    hide-details="auto"/>
                            </v-col>
                        </v-row>

                        <v-row dense class="mt-4" v-if="filter == 'Agent Name'">
                            <v-col>
                                <v-text-field
                                    ref="last_name"
                                    v-model="last_name"
                                    label="Last Name"
                                    :rules="(searchStatus) ? [valueValidation] : []"
                                    outlined
                                    dense
                                    clearable
                                    hide-details="auto"/>
                            </v-col>
                        </v-row>
                            
                        <v-row dense class="mt-4" v-if="filter == 'Email'">
                            <v-col>
                                <v-text-field
                                    ref="email"
                                    v-model="email"
                                    label="Email"
                                    :rules="(searchStatus) ? [rules.required, rules.minimum(2)] : []"
                                    outlined
                                    dense
                                    clearable
                                    hide-details="auto"/>
                            </v-col>
                        </v-row>                            

                        <v-row dense class="mt-3">
                            <v-col>
                                <v-btn
                                    block
                                    depressed
                                    :disabled="!valid"
                                    type="submit"
                                    color="primary">
                                    Search
                                </v-btn>
                            </v-col>

                            <v-col>
                                <v-btn
                                    block
                                    depressed
                                    type="button"
                                    @click="cancelSearch">
                                    Cancel
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import formRules from '@/helpers/formRules'

export default {
    props: {
        dates: {
            type: Object
        }
    },

    data: () => ({
        valid: true,
        rules: formRules,
        searchStatus: false,
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        date_from:'',
        date_from_menu: false,
        date_to: '',
        date_to_menu: false,
        dates_filter: {},
        filter: 'Date Created',
        filters: ['Agent Name', 'Date Created', 'Mobile Number', 'Email'],
    }),

    mounted() {
        this.filter = this.dates.filter_by

        switch(this.dates.filter_by) {
            case "Agent Name":
                this.first_name = this.dates.first_name
                this.last_name = this.dates.last_name
                break;
            case "Date Created":
                this.date_from = this.dates.date_from
                this.date_to = this.dates.date_to
                break;
            case "Mobile Number":
                this.mobile_number = this.dates.mobile_number
                break;
            case "Email":
                this.email = this.dates.email
                break;
        }
    },

    computed: {
        lessThanDate() { 
            if ((this.date_from) && (this.date_to)) {
                if (new Date(this.date_to) < new Date(this.date_from)) {
                    return 'Must be higher or equal than date from.'
                } 
            } 
            
            return true
        },

        lessThanDate2() { 
            if ((this.date_from) && (this.date_to)) {
                if (new Date(this.date_from) > new Date(this.date_to)) {
                return 'Must be lessthan or equal than date to.'
                }
            } 
            
            return true
        },

        dateToAvailability() {
            if (this.date_from == '' || this.date_from == null) {
                return true
            }

            return false
        },

        dialog: {
            get: function () {
                return true;
            },
            set: function () {
                this.cancelSearch()
            },
        },
    },

    methods: {
        closeModal() {
            this.$emit("setFiltereModal", this.dates_filter)
        },
        cancelSearch() {
            this.dates_filter = { "filter_status": 'cancel' }
        
            this.closeModal();
        },

        setFilterFocus() {
            switch(this.filter) {
                case 'Agent Name':
                    setTimeout(() => {
                        this.$refs.first_name.focus()
                    }, 200);
                    break;
                case 'Mobile Number':
                    setTimeout(() => {
                        this.$refs.mobile_number.focus()
                    }, 200);
                    break;
                case 'Email':
                    setTimeout(() => {
                        this.$refs.email.focus()
                    }, 200);
                    break;
            }
        },

        proceedToSearch() {
            this.searchStatus = true

            setTimeout(() => {
                if (this.$refs.form.validate()) {
                    switch(this.filter) {
                        case 'Agent Name':
                            this.dates_filter = {
                                "filter_status": 'search',
                                "filter_by": this.filter,
                                "filter_first_name": this.first_name,
                                "filter_last_name":  this.last_name
                            }
                            break;
                        case 'Date Created':
                            this.dates_filter = {
                                "filter_status": 'search',
                                "filter_by": this.filter,
                                "filter_date_from": this.date_from,
                                "filter_date_to":  (this.date_to) ?? this.date_from
                            }
                            break;
                        case 'Mobile Number':
                            this.dates_filter = {
                                "filter_status": 'search',
                                "filter_by": this.filter,
                                "filter_mobile_number": this.mobile_number
                            }
                            break;
                        case 'Email':
                            this.dates_filter = {
                                "filter_status": 'search',
                                "filter_by": this.filter,
                                "filter_email": this.email
                            }
                            break;
                    }
                
                    this.closeModal();
                }
            }, 200);
        },

        removeMobileNumberLeadingZero() {
            this.mobile_number = this.mobile_number.replace(/^0+/, "");
        },

        valueValidation() {
            if(!this.first_name && !this.last_name) {
                return 'Atleast one of these fields have a value.'
            }

            return true
        },
    },

    watch: {
        date_from (val) {
            if (val == '' || val == null) {
                this.date_to = ''
            }
        }
    }
}
</script>