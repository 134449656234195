var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-md-7",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 py-2 font-weight-regular grey--text text--darken-1"},[_vm._v(" Select Schedule ")]),_c('v-divider'),_c('v-card-text',{staticClass:"py-4"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.rules.required],"label":"Schedule Date Start","hide-details":"auto","readonly":"","outlined":"","dense":"","clearable":""},on:{"keyup":function($event){_vm.schedule_date_start_menu = true},"keydown":function($event){_vm.schedule_date_start_menu = false}},model:{value:(_vm.schedule_date_start),callback:function ($$v) {_vm.schedule_date_start=$$v},expression:"schedule_date_start"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.schedule_date_start_menu),callback:function ($$v) {_vm.schedule_date_start_menu=$$v},expression:"schedule_date_start_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.schedule_date_start_menu = false}},model:{value:(_vm.schedule_date_start),callback:function ($$v) {_vm.schedule_date_start=$$v},expression:"schedule_date_start"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Schedule Date End (Optional)","hide-details":"auto","readonly":"","outlined":"","dense":"","clearable":""},on:{"keyup":function($event){_vm.schedule_date_end_menu = true},"keydown":function($event){_vm.schedule_date_end_menu = false}},model:{value:(_vm.schedule_date_end),callback:function ($$v) {_vm.schedule_date_end=$$v},expression:"schedule_date_end"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.schedule_date_end_menu),callback:function ($$v) {_vm.schedule_date_end_menu=$$v},expression:"schedule_date_end_menu"}},[_c('v-date-picker',{attrs:{"min":(_vm.schedule_date_start) ? _vm.schedule_date_start : ''},on:{"input":function($event){_vm.schedule_date_end_menu = false}},model:{value:(_vm.schedule_date_end),callback:function ($$v) {_vm.schedule_date_end=$$v},expression:"schedule_date_end"}})],1)],1)],1),_c('div',[_c('v-radio-group',{attrs:{"rules":[_vm.rules.required],"hide-details":"auto","row":""},model:{value:(_vm.report_type),callback:function ($$v) {_vm.report_type=$$v},expression:"report_type"}},[_c('v-radio',{attrs:{"label":"Daily","value":"Daily"}}),_c('v-radio',{attrs:{"label":"Weekly","value":"Weekly"}}),_c('v-radio',{attrs:{"label":"Monthly","value":"Monthly"}}),_c('v-radio',{attrs:{"label":"Yearly","value":"Yearly"}})],1)],1),_c('div',{staticClass:"mt-4"},[_c('v-btn',{attrs:{"disabled":!_vm.valid,"type":"submit","color":"primary","outlined":"","loading":_vm.recordsLoading},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 py-2 font-weight-regular grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.searched_report_type)+" Report "),_c('v-spacer'),_c('div',[(_vm.records.length)?_c('downloadexcel',{staticClass:"d-inline-block",attrs:{"data":_vm.exportExcelDataWithTotal(),"fields":{
              'Schedule Date': 'final_schedule_date',
              'Total Games': 'total_games',
              'Total Gross Amount': 'game_total_bet_amount',
              'Total Payouts': 'game_total_prizes',
              'Total Net Amount': 'net_amount',
              'Total Pagcor Share': 'pagcor_share'
            },"name":_vm.downloadExcelName(),"type":"csv"}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","small":"","depressed":""}},[_vm._v(" Export CSV ")])],1):_vm._e(),(_vm.records.length)?_c('v-btn',{staticClass:"ms-2 d-inline-block",attrs:{"color":"primary","outlined":"","small":"","depressed":""},on:{"click":_vm.exportToPDF}},[_vm._v(" Export PDF ")]):_vm._e()],1)],1),_c('v-divider'),_c('div',{ref:"datatable"},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":-1,"loading":_vm.recordsLoading,"loading-text":"Loading... Please wait","mobile-breakpoint":"0","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.final_schedule_date",fn:function(ref){
            var item = ref.item;
return [(item.total_games > 0)?_c('span',[_vm._v(_vm._s(_vm.scheduleDate(item.final_schedule_date)))]):_c('span',[_vm._v(_vm._s(_vm.scheduleDate(item.dates)))])]}},{key:"item.total_games",fn:function(ref){
            var item = ref.item;
return [(item.total_games > 0)?_c('span',[_vm._v(_vm._s(parseInt(item.total_games).toLocaleString()))]):_c('span',[_vm._v("No Activity")])]}},{key:"item.game_total_bet_amount",fn:function(ref){
            var item = ref.item;
return [(item.total_games > 0)?_c('span',[_vm._v(_vm._s('PHP ' + _vm.moneyFormat(parseFloat(item.game_total_bet_amount))))]):_vm._e()]}},{key:"item.game_total_prizes",fn:function(ref){
            var item = ref.item;
return [(item.total_games > 0)?_c('span',[_vm._v(_vm._s('PHP ' + _vm.moneyFormat(parseFloat(item.game_total_prizes))))]):_vm._e()]}},{key:"item.net_amount",fn:function(ref){
            var item = ref.item;
return [(item.total_games > 0)?_c('span',[_vm._v(_vm._s('PHP ' + _vm.moneyFormat(parseFloat(item.net_amount))))]):_vm._e()]}},{key:"item.pagcor_share",fn:function(ref){
            var item = ref.item;
return [(item.total_games > 0)?_c('span',[_vm._v(_vm._s('PHP ' + _vm.moneyFormat(parseFloat(item.pagcor_share))))]):_vm._e()]}},(_vm.records.length)?{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v("TOTAL")])]),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.totalGames.toLocaleString()))])]),_c('td',[_c('strong',[_vm._v(_vm._s('PHP ' + _vm.moneyFormat(parseFloat(_vm.totalGrossAmount))))])]),_c('td',[_c('strong',[_vm._v(_vm._s('PHP ' + _vm.moneyFormat(parseFloat(_vm.totalDistributedPrizes))))])]),_c('td',[_c('strong',[_vm._v(_vm._s('PHP ' + _vm.moneyFormat(parseFloat(_vm.totalNetAmount))))])]),_c('td',[_c('strong',[_vm._v(_vm._s('PHP ' + _vm.moneyFormat(parseFloat(_vm.totalPagcorShare))))])])])]},proxy:true}:null],null,true)})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }