<template>
  <v-dialog scrollable v-model="dialog" max-width="600">
    <v-card :loading="userLoading || outletLoading">
      <v-card-title class="text-h5">
        {{ agent_id != 0 ? "Update" : "New" }} Agent
      </v-card-title>

      <v-btn icon absolute right class="mt-4" @click="setCloseModal">
        <v-icon size="large"> mdi-close </v-icon>
      </v-btn>

      <v-divider></v-divider>

      <v-card-text class="py-4">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          :disabled="userLoading || outletLoading"
          @submit.prevent="submit('create')"
        >
          <p class="mb-1 overline primary--text">Agent Info</p>
          <v-row dense>
            <v-col>
              <v-text-field
                ref="first_name"
                outlined
                dense
                label="First Name"
                :rules="
                  submitStatus
                    ? [rules.required, rules.alphaSpace, rules.minimum(2), firstNameExist]
                    : []
                "
                v-model="first_name"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-3">
            <v-col>
              <v-text-field
                ref="middle_name"
                outlined
                dense
                label="Middle Name (Optional)"
                :rules="submitStatus ? [rules.alphaSpace, rules.minimum(2)] : []"
                v-model="middle_name"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-3">
            <v-col>
              <v-text-field
                ref="last_name"
                outlined
                dense
                label="Last Name"
                :rules="
                  submitStatus
                    ? [rules.required, rules.alphaSpace, rules.minimum(2), lastNameExist]
                    : []
                "
                v-model="last_name"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-3">
            <v-col>
              <v-text-field
                ref="mobile_number"
                outlined
                dense
                label="Mobile Number"
                :rules="[rules.required, rules.validMobileNumber, mobileNumberExist]"
                maxlength="10"
                v-model="mobile_number"
                hide-details="auto"
                prefix="+63"
                @keyup="removeMobileNumberLeadingZero"
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-3">
            <v-col>
              <v-text-field
                ref="email_address"
                outlined
                dense
                label="Email Address"
                :rules="[rules.required, rules.validEmail, emailAddressExist]"
                v-model="email_address"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-3">
            <v-col>
              <v-text-field
                ref="commission"
                outlined
                dense
                label="Commission"
                :rules="submitStatus ? [rules.required] : []"
                v-model="commission"
                hide-details="auto"
                suffix="%"
                @keyup="removeAlphabet('commission')"
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-3">
            <v-col cols="12">
              <v-autocomplete
                v-model="outlet"
                :items="outlets"
                :rules="[rules.required]"
                label="Outlet"
                outlined
                dense
                item-text="outlet_name"
                return-object
              />
            </v-col>
          </v-row>
          <!-- v-if="(agent_id != 0)" -->
          <!-- :rules="(submitStatus) ? [rules.required] : []" -->
          <v-row dense class="mt-3">
            <v-col>
              <v-text-field
                ref="wallet"
                outlined
                dense
                label="Wallet"
                v-model="wallet"
                hide-details="auto"
                @keyup="removeAlphabet('wallet')"
              />
            </v-col>
          </v-row>

          <p class="mb-1 mt-4 overline primary--text">Agent Account</p>

          <v-row dense>
            <v-col>
              <v-text-field
                ref="username"
                outlined
                dense
                label="Username"
                :rules="submitStatus ? [rules.required, rules.minimum(3)] : []"
                v-model="username"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-3" v-if="agent_id == 0">
            <v-col>
              <v-text-field
                ref="password"
                outlined
                dense
                label="Password"
                :rules="[rules.required, rules.minimum(5), rules.strongPassword]"
                v-model="password"
                :append-icon="passwordVisibility ? 'mdi-eye' : 'mdi-eye-off'"
                :type="passwordVisibility ? 'text' : 'password'"
                @click:append="passwordVisibility = !passwordVisibility"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-3" v-if="agent_id == 0">
            <v-col>
              <v-text-field
                ref="confirm_password"
                outlined
                dense
                label="Confirm Password"
                :rules="[
                  rules.required,
                  rules.minimum(6),
                  rules.strongPassword,
                  passwordConfirmationRule,
                ]"
                v-model="confirm_password"
                :append-icon="passwordVisibility2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="passwordVisibility2 ? 'text' : 'password'"
                @click:append="passwordVisibility2 = !passwordVisibility2"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row dense v-if="agentInfo">
            <v-col cols="auto">
              <v-switch
                v-model="is_active"
                :label="is_active ? 'Active' : 'In-Active'"
                color="success"
                hide-details
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-4">
            <v-col cols="12" :md="agent_id == 0 ? '4' : '6'">
              <v-btn
                block
                depressed
                color="primary"
                :disabled="userLoading"
                type="submit"
              >
                {{ buttonText }}
              </v-btn>
            </v-col>

            <v-col cols="12" md="4" v-if="agent_id == 0">
              <v-btn
                block
                depressed
                outlined
                :disabled="userLoading"
                color="primary"
                @click="submit('close')"
              >
                Save and Close
              </v-btn>
            </v-col>

            <v-col cols="12" :md="agent_id == 0 ? '4' : '6'">
              <v-btn block depressed :disabled="userLoading" @click="setCloseModal">
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";

export default {
  props: {
    agent_id: {
      type: Number,
    },
  },

  data: () => ({
    valid: true,
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile_number: "",
    email_address: "",
    commission: "",
    wallet: 0,
    username: "",
    password: "",
    confirm_password: "",
    is_active: true,
    rules: formRules,
    passwordVisibility: false,
    passwordVisibility2: false,
    firstNameExistText: "",
    lastNameExistText: "",
    emailAddressExistText: "",
    mobileNumberExistText: "",
    insertedAgentInfo: {},
    updatedAgentInfo: {},
    submitStatus: false,
    outlet: "",
  }),

  mounted() {
    if (this.agent_id == 0) {
      this.$refs.first_name.focus();
    }
  },

  created() {
    if (this.agent_id) {
      this.requestAgentInfo();
    }

    this.doGetOutlets();
  },

  computed: {
    ...mapState("agents", [
      "userLoading",
      "agentId",
      "agentInfo",
      "error",
      "errors",
      "outlets",
      "outletLoading",
    ]),

    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.setCloseModal();
      },
    },

    passwordConfirmationRule() {
      return () => this.password === this.confirm_password || "Password must match";
    },

    buttonText() {
      let text = "";

      switch (this.agent_id == 0) {
        case true:
          text = "Save and New";
          break;
        case false:
          text = "Update";
          break;
      }

      return text;
    },
  },

  methods: {
    ...mapActions("agents", ["createAgent", "updateAgent", "getAgentInfo", "getOutlets"]),

    async doGetOutlets() {
      this.getOutlets();
    },

    getOUtletData(outlet_id) {
      this.outlets.filter((data) => {
        if (parseInt(data.id) == parseInt(outlet_id)) {
          this.outlet = data;
        }
      });
    },

    async submit(action) {
      this.submitStatus = true;

      await new Promise((r) => setTimeout(r, 200));

      if (this.$refs.form.validate()) {
        if (!this.userLoading) {
          switch (Object.keys(this.agentInfo).length == 0) {
            case true:
              await this.createAgent(this.setAgentFormData());

              if (!this.error && this.isObjectEmpty(this.errors)) {
                switch (action) {
                  case "create": {
                    this.$refs.form.reset();
                    this.$refs.first_name.focus();
                    break;
                  }

                  case "close": {
                    const currentDate = new Date();

                    this.insertedAgentInfo = {
                      id: this.agentId,
                      name: `${this.first_name} ${this.last_name}`,
                      mobile_number: this.mobile_number,
                      email: this.email_address,
                      username: this.username,
                      no_of_player: 0,
                      commission: this.commission,
                      wallet: this.wallet,
                      is_active: this.is_active ? "Active" : "Inactive",
                      created_at: currentDate,
                    };
                    this.setCloseModal();
                    break;
                  }
                }
              } else this.setErrors();
              break;
            case false:
              await this.updateAgent(this.setAgentFormData());

              this.updatedAgentInfo = {
                agent_id: this.agent_id,
                name: `${this.first_name} ${this.last_name}`,
                mobile_number: this.mobile_number,
                email: this.email_address,
                username: this.username,
                no_of_player: 0,
                commission: this.commission,
                wallet: this.wallet,
                is_active: this.is_active ? "Active" : "Inactive",
              };

              this.setCloseModal();
              break;
          }
        }
      }
    },

    setAgentFormData() {
      const formData = new FormData();

      if (this.agent_id) {
        formData.append("agent_id", this.agent_id);
      }

      formData.append("first_name", this.first_name);
      formData.append("middle_name", this.middle_name);
      formData.append("last_name", this.last_name);
      formData.append("mobile_number", this.mobile_number);
      formData.append("email", this.email_address);
      formData.append("commission", this.commission);
      formData.append("outlet_id", this.outlet.id);
      formData.append("wallet", this.wallet);
      formData.append("username", this.username);
      formData.append("password", this.password);
      formData.append("is_active", this.is_active ? "Active" : "Inactive");

      return formData;
    },

    async requestAgentInfo() {
      if (!this.userLoading) {
        await this.getAgentInfo(this.setAgentInfoFormData());

        if (Object.keys(this.agentInfo).length) {
          this.first_name = this.agentInfo[0].first_name;
          this.middle_name = this.agentInfo[0].middle_name;
          this.last_name = this.agentInfo[0].last_name;
          this.mobile_number = this.agentInfo[0].mobile_number;
          this.email_address = this.agentInfo[0].email;
          this.commission = this.agentInfo[0].commission;
          this.wallet = this.agentInfo[0].wallet;
          this.username = this.agentInfo[0].username;
          this.is_active = this.agentInfo[0].is_active == "Active" ? true : false;
          this.getOUtletData(this.agentInfo[0].outlet_id);

          this.$refs.first_name.focus();
        }
      }
    },

    setAgentInfoFormData() {
      const formData = new FormData();
      formData.append("agent_id", this.agent_id);

      const getData = new URLSearchParams(formData);

      return getData;
    },

    setErrors() {
      let errorFocus = "";

      Object.entries(this.errors).forEach(([key, value]) => {
        if (value) {
          if (!errorFocus) {
            errorFocus = key;
          }

          switch (key) {
            case "player_name":
              this.firstNameExistText = this.first_name;
              this.lastNameExistText = this.last_name;
              break;
            case "mobile_number":
              this.mobileNumberExistText = this.mobile_number;
              break;
            case "email":
              this.emailAddressExistText = this.email_address;
              break;
          }

          this.$refs.form.validate();
          this.$refs[errorFocus].focus();
        }
      });
    },

    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    removeAlphabet(component) {
      switch (component) {
        case "commission":
          this.commission = this.commission.replace(/[^\d,.]/g, "");
          break;
        case "wallet":
          this.wallet = this.wallet.replace(/[^\d,.]/g, "");
          break;
      }
    },
    setCloseModal() {
      this.$store.commit("agents/setAgentInfo", []);
      this.$emit("setCloseModal", this.insertedAgentInfo, this.updatedAgentInfo);
    },
    firstNameExist(val) {
      if (val == this.firstNameExistText) {
        return "First name already exists.";
      } else return true;
    },
    lastNameExist(val) {
      if (val == this.lastNameExistText) {
        return "Last name already exists.";
      } else return true;
    },
    mobileNumberExist(val) {
      if (val == this.mobileNumberExistText) {
        return "Mobile number already exists.";
      } else return true;
    },
    emailAddressExist(val) {
      if (val == this.emailAddressExistText) {
        return "Email address already exists.";
      } else return true;
    },
    removeMobileNumberLeadingZero() {
      this.mobile_number = this.mobile_number.replace(/^0+/, "");
    },
  },
};
</script>
