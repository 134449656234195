<template>
  <div class="d-inline-block">
    <v-switch
      class="my-0 py-0 d-inline-block"
      dense
      v-model="switchStatus"
      hide-details="true"
      @click.native.prevent.stop.capture="changeConfirm"
    ></v-switch>

    <v-dialog v-model="dialog" max-width="300">
      <v-card class="shadow">
        <div class="pa-4 pa-md-6">
          <h3 class="font-weight-bold mb-3">Confirmation</h3>
          <p class="grey--text mb-4 mb-md-6">
            Do you really want to {{ switchStatus ? "disable" : "enable" }} cash out?
          </p>
          <div class="d-flex justify-end gap-sm">
            <v-btn text @click="dialog = false"> No </v-btn>
            <v-btn
              ref="yes"
              color="primary"
              depressed
              @click="handleClick"
              :loading="cashoutLoading"
            >
              Yes
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    compData: {
      // Component Data
      type: Object,
      required: true,
    },
  },
  data: () => ({
    switchStatus: false,
    dialog: false,
  }),
  mounted() {
    if (this.compData && this.compData.cashout == "Enabled") {
      this.switchStatus = true;
    } else {
      this.switchStatus = false;
    }
  },
  computed: {
    ...mapState("player_details", ["cashoutError", "cashoutLoading"]),
  },
  methods: {
    ...mapActions("player_details", ["cashoutSetting"]),
    changeConfirm() {
      if (this.compData.account_status == "Verified") {
        this.dialog = true;
      }
    },
    async handleClick() {
      if (!this.cashoutLoading) {
        const formData = new FormData();
        formData.append("player_id", this.compData.id);
        formData.append("cashout", !this.switchStatus);

        await this.cashoutSetting(formData);

        if (!this.cashoutError) {
          this.dialog = false;

          this.switchStatus = !this.switchStatus;

          // no need to emit the new status
        }
      }
    },
  },

  watch: {
    dialog(visible) {
      if (visible) {
        setTimeout(() => {
          this.$refs.yes.$el.focus();
        }, 200);
      }
    },
  },
};
</script>
