const url = process.env.VUE_APP_API_URL

export default {
  namespaced: true,

  state: {
    records: [],
    recordsLoading: false,
    depositConfigId: '',
    createError: null,
    createErrors: [],
    createLoading: false,
  },
  mutations: {
    setRecords(state, val) {
      state.records = val
    },
    setRecordsLoading(state, val) {
      state.recordsLoading = val
    },
    setDepositConfigIdId(state, val) {
      state.depositConfigId = val
    },
    setCreateError(state, val) {
      state.createError = val
    },
    setCreateErrors(state, val) {
      state.createErrors = val
    },
    setCreateLoading(state, val) {
      state.createLoading = val
    }
  },

  actions: {
    async getRecords({ commit, rootState }) {
      commit("setRecordsLoading", true)

      try {
        const response = await fetch(`${url}deposit_config`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setRecords", res.records)
        }
      } catch (error) {
        const errorStatus = error.response.status

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setRecordsLoading", false)
      }
    },

    async create({ commit, rootState }, data) {
      commit("setCreateLoading", true)
      commit("setCreateError", null)
      commit("setCreateErrors", [])

      try {
        const response = await fetch(`${url}deposit_config/create`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`
          },
          body: data
        })

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setDepositConfigIdId", res.id)
        }
      } catch (error) {
        const errorStatus = error.response.status

        switch (errorStatus) {
          case 401:
            commit("setError", "Unauthorized", { root: true })
            break;
          case 404:
            commit("setError", error.res.messages.error)
            break;
          case 417:
            commit("setPopUpError", error.res.error, { root: true })
            break;
          case 422:
            commit("setCreateTeamErrors", error.res.messages)
            break;
          default:
            commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setCreateLoading", false)
      }
    },

    async update({ commit, rootState }, data) {
      commit("setCreateLoading", true)
      commit("setCreateError", null)
      commit("setCreateErrors", [])

      try {
        const response = await fetch(`${url}deposit_config/update`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`
          },
          body: data
        })

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        }
      } catch (error) {
        const errorStatus = error.response.status

        switch (errorStatus) {
          case 401:
            commit("setError", "Unauthorized", { root: true })
            break;
          case 404:
            commit("setError", error.res.messages.error)
            break;
          case 417:
            commit("setPopUpError", error.res.error, { root: true })
            break;
          case 422:
            commit("setCreateTeamErrors", error.res.messages)
            break;
          default:
            commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setCreateLoading", false)
      }
    }
  }
}