<template>
  <div>
    <v-card class="shadow">
      <v-card-title>
        <v-row no-gutters justify="space-between">
          <v-col cols="auto">
            <span class="text-h6 font-weight-regular grey--text text--darken-2">Daily Active Player Statistics</span>
          </v-col>
          <v-col cols="auto" class="text-right">
            <v-btn
              outlined
              small
              class="text-capitalize font-weight-regular px-2 grey--text text--darken-2"
              @click="filterDialog = true"
              :disabled="activePlayerDailyLoading"
            >
              {{ activePlayerDaily.label }}
              <v-icon small class="ps-1">mdi-chevron-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row dense v-if="activePlayerDaily.active_players_daily_average">
          <v-col class="my-0" v-if="!playersNewStatisticsLoading && !activePlayerDailyLoading">
            <span>Daily Average <span style="color: #4DD0E1">{{ moneyFormat(parseInt(activePlayerDaily.active_players_daily_average)) }}</span></span><br/>
            <span>Lowest <span v-if="!activePlayerDailyLoading" style="color: #4DD0E1">{{ moneyFormat(parseFloat(lowest)) }}</span></span><br/>
            <span>Highest <span v-if="!activePlayerDailyLoading" style="color: #4DD0E1">{{ moneyFormat(parseFloat(highest)) }}</span></span><br/>
            <span>Percentage vs Registered <span style="color: #4DD0E1">{{ moneyFormat2(parseFloat((activePlayerDaily.active_players_daily_average / playersNewStatistics['registered'][0]['registered_player']) * 100)) }}%</span></span><br/>
            <span>Percentage vs YTD Target <span style="color: #4DD0E1">{{ moneyFormat2(parseFloat((activePlayerDaily.active_players_daily_average / this.playersNewStatistics.target_as_of_registration.target) * 100)) }}%</span></span>
          </v-col>
        </v-row>

        <div v-if="activePlayerDailyLoading" class="text-center">
          <v-progress-circular
            :size="70"
            :width="5"
            color="secondary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <LineChart :theChartData="activePlayerDaily" />
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
      ref="filterDialog"
      v-model="filterDialog"
      :return-value.sync="month"
      persistent
      width="290px"
    >
      <v-date-picker
        v-model="month"
        full-width
        type="month"
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="cancelFilter"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="saveFilter"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import formatDatev2 from "@/helpers/formatDatev2"
import LineChart from "@/components/dashboard/active_player_statistics/ActivePlayerDailyLineChart"

export default {
  components: { LineChart },
  data: () => ({
    month: formatDatev2((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 'format', 'yyyy-MM'),
    filterDialog: false,
    activePicker: null,
    registered: 0,
    target_registration_as_of: 0
  }),
  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("dashboard", [
      "activePlayerDaily",
      "activePlayerDailyLoading"
    ]),
    ...mapState("dashboard", [
      "playersNewStatistics",
      "playersNewStatisticsLoading"
    ]),

    lowest() {
      const arr = Object.values(this.activePlayerDaily.active_daily_statisitcs)
      const filtered_array = arr.filter(val => val != 0)
      
      if (filtered_array.length != 0) return Math.min(...filtered_array)

      return 0
    },

    highest() {
      const arr = Object.values(this.activePlayerDaily.active_daily_statisitcs)
      return Math.max(...arr)
    }
  },
  mounted() {
    if (this.accountInfo.access.includes(21)) {
      this.doGetData()
    }
  },
  methods: {
    ...mapActions("dashboard", [
      "getActivePlayerDailyStatistics"
    ]),
    cancelFilter() {
      this.filterDialog = false
    },
    saveFilter() {
      this.$refs.filterDialog.save(this.month)
      this.doGetData()
    },
    async doGetData() {
      if (!this.cashinMonthlyLoading) {
        this.$store.commit("dashboard/setActivePlayerDaily", [])

        const formData = new FormData()
        formData.append('val', this.month)
        formData.append('type', 'Daily')

        const getData = new URLSearchParams(formData)

        await this.getActivePlayerDailyStatistics(getData)
      }
    },
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    },
    moneyFormat2(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
  },
}
</script>