<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Agent Monitoring</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Agent Kubradors</h2>
    <v-card class="shadow mb-4">
      <v-card-title
        class="text-h6 pa-4 font-weight-regular grey--text text--darken-1 overline"
      >
        AGENT PLAYERS FILTER
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="py-4">
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="doSearch">
          <!-- <v-row dense>
                                <v-col cols="12" md="4">
                                    <v-select
                                        v-model="filter"
                                        :items="filterOptions"
                                        :rules="[rules.required]"
                                        label="Filter By"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    />
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-menu
                                        v-model="date_from_menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto">

                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="date_from"
                                                :rules="[rules.required, lessThanDate]"
                                                :label="`${filter} From`"
                                                hide-details="auto"
                                                readonly
                                                outlined
                                                dense
                                                v-bind="attrs"
                                                v-on="on"
                                                @keyup="date_from_menu=true"
                                                @keydown="date_from_menu=false"
                                                clearable/>
                                        </template>

                                        <v-date-picker
                                        v-model="date_from"
                                        @input="date_from_menu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-menu
                                        v-model="date_to_menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="date_to"
                                            :rules="[lessThanDate2]"
                                            :label="`${filter} To (Optional)`"
                                            hide-details="auto"
                                            readonly
                                            outlined
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                            @keyup="date_to_menu=true"
                                            @keydown="date_to_menu=false"
                                            clearable
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="date_to"
                                        @input="date_to_menu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row> -->

          <v-row dense>
            <v-col cols="12" md="4">
              <v-autocomplete
                ref="agent"
                v-model="agent_id"
                :rules="[rules.required, filterStatus]"
                :items="agents"
                item-text="agent"
                item-value="id"
                label="Agent"
                dense
                outlined
                hide-details="auto"
                :disabled="agentsLoading"
                :loading="agentsLoading"
                clearable
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                ref="province"
                v-model="province_id"
                :rules="[filterStatus]"
                :items="provinces"
                item-text="province"
                item-value="id"
                label="Province (Optional)"
                dense
                outlined
                hide-details="auto"
                :disabled="provincesLoading"
                :loading="provincesLoading"
                clearable
                @change="city_mun_id = 0"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <v-autocomplete
                ref="cityMun"
                v-model="city_mun_id"
                :rules="[filterStatus]"
                :items="province_id ? filteredCityMunicipalities : cityMuns"
                item-text="cityMuns"
                item-value="id"
                label="City/ Municipality (Optional)"
                dense
                outlined
                hide-details="auto"
                :loading="cityMunsLoading"
                :disabled="cityMunsLoading"
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row dense class="mt-4">
            <v-col cols="12" md="4">
              <v-btn
                depressed
                type="submit"
                color="primary"
                outlined
                :disabled="provincesLoading || cityMunsLoading || agentsLoading"
                :loading="recordsLoading"
                :block="$vuetify.breakpoint.mdAndUp ? false : true"
              >
                Search
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <downloadexcel
              :data="records"
              :fields="{
                ID: 'id',
                Province: 'province',
                City: 'city_mun',
                'Agent Name': 'agent',
                'Kubrador Name': 'player',
                'Fund Token': 'fund_token',
                'Total Bets': 'bets',
                'Total Bets Amount': 'bets_amount',
                'Total Payouts': 'payout',
                'Total CashIn': 'total_cashin',
                'Wallet Token': 'wallet_token',
                'Unremitted Amount': 'unremitted_amount',
              }"
              name="Agent Players.xls"
              type="csv"
              class="d-inline-block"
            >
              <v-btn outlined color="primary"> Export CSV </v-btn>
            </downloadexcel>

            <!-- <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        color="secondary"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="mr-1">
                                        <v-icon>mdi-cached</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Refresh</span>
                                </v-tooltip> -->
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="recordsLoading"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-row dense class="mb-2">
          <!-- <v-col cols="12" md="2" align="center" class="px-1">
                                <v-card class="pa-1">
                                    <p class="font-weight-regular ma-0 overline">TOTAL AGENTS</p>
                                    <v-divider class="mb-4"/>
                                    <p class="font-weight-medium">{{ parseInt(Object.keys(totalAgents).length).toLocaleString() }}</p>
                                </v-card>
                            </v-col> -->

          <v-col cols="12" md="3" align="center" class="px-1">
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL PLAYERS</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                {{ parseInt(Object.keys(totalKobradors).length).toLocaleString() }}
              </p>
            </v-card>
          </v-col>

          <v-col cols="12" md="3" align="center" class="px-1">
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL BETS</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                {{ parseInt(totalBets).toLocaleString() }}
              </p>
            </v-card>
          </v-col>

          <v-col cols="12" md="3" align="center" class="px-1">
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL BETS AMOUNT</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                {{ parseInt(totalBetAmount).toLocaleString() }}
              </p>
            </v-card>
          </v-col>

          <v-col cols="12" md="3" align="center" class="px-1">
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL PAYOUTS</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                {{ parseInt(totalPayouts).toLocaleString() }}
              </p>
            </v-card>
          </v-col>
        </v-row>

        <v-row dense class="mb-6">
          <v-col cols="12" md="2" align="center" class="px-1">
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL FUND TOKEN</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                {{ parseInt(totalFundToken).toLocaleString() }}
              </p>
            </v-card>
          </v-col>

          <v-col cols="12" md="2" align="center" class="px-1">
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL CASHIN</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                {{ parseInt(totalCashIn).toLocaleString() }}
              </p>
            </v-card>
          </v-col>

          <v-col cols="12" md="2" align="center" class="px-1">
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL CASHOUT</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                {{ parseInt(totalCashout).toLocaleString() }}
              </p>
            </v-card>
          </v-col>

          <v-col cols="12" md="2" align="center" class="px-1">
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL WALLET TOKEN</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                {{ parseInt(totalWalletToken).toLocaleString() }}
              </p>
            </v-card>
          </v-col>

          <v-col cols="12" md="2" align="center" class="px-1">
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">WITHDRAWABLE FUND</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                {{ parseInt(withdrawableFund).toLocaleString() }}
              </p>
            </v-card>
          </v-col>

          <v-col cols="12" md="2" align="center" class="px-1">
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL UNREMITTED AMOUNT</p>
              <v-divider class="mb-4" />
              <p
                class="font-weight-medium"
                :class="totalUnremittedAmount != 0 ? 'red--text' : ''"
              >
                {{ parseInt(totalUnremittedAmount).toLocaleString() }}
              </p>
            </v-card>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="5"
          :search="search"
          :loading="recordsLoading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.action`]="{ item }">
            <!-- <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                            icon
                                            small
                                            color="primary"
                                            @click="showGames(item)"
                                            class="ma-1">
                                            <v-icon size="large"> mdi-basketball </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>View Games</span>
                                </v-tooltip> -->

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dense
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  color="primary"
                  @click="showKobradorProgressive(item)"
                  class="ma-1"
                >
                  <v-icon size="large"> mdi-chart-timeline-variant </v-icon>
                </v-btn>
              </template>
              <span>Show Progressive</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.fund_token`]="{ item }">
            {{ parseInt(item.fund_token).toLocaleString() }}
          </template>

          <template v-slot:[`item.bets`]="{ item }">
            {{ parseInt(item.bets).toLocaleString() }}
          </template>

          <template v-slot:[`item.bets_amount`]="{ item }">
            {{ parseInt(item.bets_amount).toLocaleString() }}
          </template>

          <!-- <template v-slot:[`item.total_cashin`]="{ item }">
                                <span :class="(item.total_cashin > 0) ? 'green--text' : ''"><v-icon v-if="item.total_cashin" color="green" size="large"> mdi-arrow-up-thin </v-icon> {{ moneyFormat(parseFloat(item.total_cashin)) }} </span>
                            </template> -->

          <template v-slot:[`item.total_cashin`]="{ item }">
            <span> {{ parseInt(item.total_cashin).toLocaleString() }} </span>
          </template>

          <template v-slot:[`item.cashout`]="{ item }">
            <span> {{ parseInt(item.cashout).toLocaleString() }} </span>
          </template>

          <template v-slot:[`item.payout`]="{ item }">
            <span> {{ parseInt(item.payout).toLocaleString() }} </span>
          </template>

          <template v-slot:[`item.wallet_token`]="{ item }">
            {{ parseInt(item.wallet_token).toLocaleString() }}
          </template>

          <template v-slot:[`item.withdrawable_fund`]="{ item }">
            {{ moneyFormat(parseFloat(item.payout - item.cashout)) }}
          </template>

          <template v-slot:[`item.withdrawable_fund`]="{ item }">
            {{ moneyFormat(parseFloat(item.payout - item.cashout)) }}
          </template>

          <template v-slot:[`item.unremitted_amount`]="{ item }">
            <span :class="item.unremitted_amount != 0 ? 'red--text' : ''">
              {{ parseInt(item.unremitted_amount).toLocaleString() }}
            </span>
          </template>

          <!-- <template v-slot:[`item.created_at`]="{ item }">
                                <span>{{ setCreatedAtFormat(item.created_at) }}</span>
                            </template> -->

          <!-- <template v-if="records.length" v-slot:[`body.append`]>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td class="text-right"><strong>TOTAL</strong></td>
                                    <td><strong>{{ parseInt(totalBets).toLocaleString() }}</strong></td>
                                    <td><strong>{{ 'PHP ' + moneyFormat(parseFloat(totalBetAmount)) }}</strong></td>
                                </tr>
                            </template> -->
        </v-data-table>
      </v-card-text>
    </v-card>

    <Games
      v-if="gamesDialog"
      :game_data="game_data"
      @setGameCloseModal="setGameCloseModal"
    />
    <KobradorProgressive
      v-if="kobradorPorgressiveDialog"
      :default_filters="default_filters"
      @setKobradorProgressiveCloseModal="setKobradorProgressiveCloseModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import formRules from "@/helpers/formRules";
import downloadexcel from "vue-json-excel";
import Games from "@/components/agent_reports/Games";
import KobradorProgressive from "@/components/monitoring/agents/KobradorProgressive";

export default {
  components: {
    downloadexcel,
    Games,
    KobradorProgressive,
  },

  data: () => ({
    search: "",
    rules: formRules,
    valid: true,
    province_id: "",
    city_mun_id: "",
    agent_id: "1",
    date_from: "",
    date_to: "",
    date_from_menu: false,
    date_to_menu: false,
    filterOptions: ["Bet Date", "Scheduled Date"],
    filter: "Bet Date",
    gamesDialog: false,
    game_data: {},
    query_filters: {},
    kobradorPorgressiveDialog: false,
    default_filters: {},
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      {
        text: "Province",
        value: "province",
        class: "text-uppercase",
        align: "left",
      },
      {
        text: "City/ Municipality",
        value: "city_mun",
        class: "text-uppercase",
        align: "left",
      },
      {
        text: "Kubrador",
        value: "player",
        class: "text-uppercase",
        align: "left",
      },
      {
        text: "Fund Token",
        value: "fund_token",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Total Bets",
        value: "bets",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Total Bets Amount",
        value: "bets_amount",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Total Payout",
        value: "payout",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Total Cashin",
        value: "total_cashin",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Total Cashout",
        value: "cashout",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Wallet Token",
        value: "wallet_token",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Withdrawable Fund",
        value: "withdrawable_fund",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Unremitted Amount",
        value: "unremitted_amount",
        class: "text-uppercase",
        align: "right",
      },
    ],
    sortBy: "id",
    sortDesc: true,
  }),

  created() {
    this.$store.commit("agents_monitoring/setRecords", []);

    if (Object.keys(this.provinces).length == 0) {
      this.doGetProvinces();
    }

    if (Object.keys(this.cityMuns).length == 0) {
      this.doGetCityMuns();
    }

    if (Object.keys(this.agents).length == 0) {
      this.doGetAgents();
    }

    const urlQuery = this.$route.query;

    if (Object.keys(urlQuery).length) {
      this.filter = urlQuery.filter_by;
      this.agent_id = urlQuery.agent_id;
      this.province_id = urlQuery.province_id;
      this.city_mun_id = urlQuery.city_mun_id;
      this.date_from = urlQuery.date_from;
      this.date_to = urlQuery.date_to;
    }

    this.loadRecords();
  },

  computed: {
    ...mapState("agents_monitoring", [
      "records",
      "recordsLoading",
      "provinces",
      "provincesLoading",
      "cityMuns",
      "cityMunsLoading",
      "agents",
      "agentsLoading",
    ]),

    filteredCityMunicipalities() {
      if (this.province_id == 0 || this.province_id == null) {
        return this.cityMuns.filter((cityMun) => {
          return (cityMun.province_id = !0);
        });
      } else {
        return this.cityMuns.filter((cityMun) => {
          return cityMun.province_id == this.province_id;
        });
      }
    },

    filterStatus() {
      if (
        this.date_from == null &&
        this.date_from == "" &&
        (this.agent_id == 0 || this.angent_id == null) &&
        (this.province_id == 0 || this.province_id == null) &&
        (this.city_mun_id == 0 || this.city_mun_id == null)
      ) {
        return "Must select atleast one of this filter.";
      }

      return true;
    },

    lessThanDate() {
      if (this.date_from && this.date_to) {
        if (new Date(this.date_to) < new Date(this.date_from)) {
          return "Must be higher or equal than date from.";
        }
      }

      return true;
    },

    lessThanDate2() {
      if (this.date_from && this.date_to) {
        if (new Date(this.date_from) > new Date(this.date_to)) {
          return "Must be lessthan or equal than date to.";
        }
      }

      return true;
    },

    totalAgents() {
      return this.groupByKey(this.records, "agent");
    },

    totalKobradors() {
      return this.groupByKey(this.records, "player");
    },

    totalFundToken() {
      if (this.records) {
        return this.records.reduce((total, obj) => obj.fund_token + total, 0);
      } else {
        return 0;
      }
    },

    totalBets() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseInt(obj.bets) + total, 0);
      } else {
        return 0;
      }
    },

    totalBetAmount() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.bets_amount) + total,
          0
        );
      } else {
        return 0;
      }
    },

    totalCashIn() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.total_cashin) + total,
          0
        );
      } else {
        return 0;
      }
    },

    totalCashout() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseFloat(obj.cashout) + total, 0);
      } else {
        return 0;
      }
    },

    totalPayouts() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseFloat(obj.payout) + total, 0);
      } else {
        return 0;
      }
    },

    totalWalletToken() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.wallet_token) + total,
          0
        );
      } else {
        return 0;
      }
    },

    totalUnremittedAmount() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.unremitted_amount) + total,
          0
        );
      } else {
        return 0;
      }
    },

    withdrawableFund() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.payout - obj.cashout) + total,
          0
        );
      } else {
        return 0;
      }
    },
  },

  methods: {
    ...mapActions("agents_monitoring", [
      "getProvinces",
      "getCityMuns",
      "getAgents",
      "getKobradors",
      "getPlayersByBet",
      "getPlayersBySchedule",
    ]),

    async doGetProvinces() {
      await this.getProvinces();
    },

    async doGetCityMuns() {
      await this.getCityMuns();
    },

    async doGetAgents() {
      await this.getAgents();
    },

    async doSearch() {
      if (this.$refs.form.validate()) {
        if (!this.recordsLoading) {
          this.$store.commit("agents_monitoring/setRecords", []);

          await this.getKobradors(this.setFormData());
          // switch(this.filter) {
          //     case 'Bet Date':
          //         await this.getPlayersByBet(this.setFormData())
          //         break;
          //     case 'Scheduled Date':
          //         await this.getPlayersBySchedule(this.setFormData())
          //         break;
          // }

          this.setQueryString();
        }
      }
    },

    async loadRecords() {
      // switch(this.filter) {
      //     case 'Bet Date':
      //         await this.getPlayersByBet(this.setFormData())
      //         break;
      //     case 'Scheduled Date':
      //         await this.getPlayersBySchedule(this.setFormData())
      //         break;
      // }

      await this.getKobradors(this.setFormData());
    },

    setFormData() {
      const formData = new FormData();

      formData.append("agent_id", this.agent_id ?? 0);
      formData.append("city_mun_id", this.city_mun_id ? this.city_mun_id : "");
      formData.append("province_id", this.province_id ? this.province_id : "");

      const getData = new URLSearchParams(formData);

      return getData;
    },

    showGames(items) {
      this.$store.commit("agents_report/setGames", []);
      this.game_data = {
        agent_id: items.agent_id,
        player_id: items.id,
        agent_name: items.agent,
        player_name: items.player,
        date_from: this.date_from,
        date_to: this.date_to ? this.date_to : this.date_from,
      };

      this.gamesDialog = true;
    },

    setGameCloseModal() {
      this.gamesDialog = false;
    },

    setQueryString() {
      const urlQuery = this.$route.query;

      if (
        urlQuery.filter_by != this.filter ||
        urlQuery.agent_id != this.agent_id ||
        urlQuery.province_id != this.province_id ||
        urlQuery.city_mun_id != this.city_mun_id ||
        urlQuery.date_from != this.date_from ||
        urlQuery.date_to != this.date_to
      ) {
        this.query_filters = {
          filter_by: this.filter,
          agent_id: this.agent_id,
          city_mun_id: this.city_mun_id,
          province_id: this.province_id,
          date_from: this.date_from,
          date_to: this.date_to,
        };

        this.$router.push({
          name: this.$route.name,
          query: this.query_filters,
        });
      }
    },

    showKobradorProgressive(items) {
      this.default_filters = {
        filter_by: this.filter,
        agent_id: items.agent_id,
        player_id: items.id,
        agent: items.agent,
        player: items.player,
        date_from: this.date_from,
        date_to: this.date_to,
      };

      this.kobradorPorgressiveDialog = true;
    },

    setKobradorProgressiveCloseModal() {
      this.kobradorPorgressiveDialog = false;
    },

    groupByKey(array, key) {
      return array.reduce((hash, obj) => {
        if (obj[key] === undefined) return hash;
        return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
      }, {});
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
  },

  // watch: {
  //     agent_id(val) {
  //         if (val) {
  //             this.province_id = 0
  //             this.city_mun_id = 0
  //         }
  //     },

  //     province_id(val) {
  //         if (val) {
  //             this.agent_id = 0
  //         }
  //     },

  //     city_mun_id(val) {
  //         if (val) {
  //             this.agent_id = 0
  //         }
  //     }
  // }
};
</script>
