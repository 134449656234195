const url = process.env.VUE_APP_API_URL 

export default {
    namespaced: true,

    state: {
        loading: false,
        leagueId: 0,
        leagues: [],
        error: null,
        errors: []
    },
    mutations: {
        setLoading(state, val) {
            state.loading = val
        },
        setLeagueId(state, val) {
            state.leagueId = val
        },
        setLeagues(state, val) {
            state.leagues = val
        },
        setError(state, val) {
            state.error = val
        },
        setErrors(state, val) {
            state.errors = val
        }
    },
    actions: {
        async getLeagues({ commit, rootState }) {
            commit("setLoading", true)

            try {
                const response = await fetch(`${url}ending/leagues`, {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                })

                const res = await response.json()

                if (!response.ok) {
                    throw { response, res }
                } else commit("setLeagues", res.records)
            } catch (error) {
                const errorStatus = error.response.status

                if (errorStatus == 401) {
                    commit("setError", "Unauthorized", { rootState: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setLoading", false)
            }
        },
        async createLeague({ commit, rootState }, data) {
            commit("setLoading", true)
            commit("setError", null)
            commit("setErrors", [])

            try {
                const response = await fetch(`${url}ending/leagues/create`, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    },
                    body: data
                })

                const res = await response.json()

                if (!response.ok) {
                    throw { response, res }
                } else commit("setLeagueId", res.league_id)
            } catch (error) {
                const errorStatus = error.response.status

                switch(errorStatus) {
                    case 401:
                      commit("setError", "Unauthorized", { root: true })
                      break;
                    case 404:
                      commit("setError", error.res.messages.error)
                      break;
                    case 417:
                      commit("setPopUpErrror", error.res.error, { root: true })
                      break;
                    case 422:
                        commit("setErrors", error.res.messages) 
                      break;
                    default:
                      commit("setRefreshError", "An error occured, please try again!", { root: true })
                  } 
            } finally {
                commit("setLoading", false)
            }
        },
        async updateLeague({ commit, rootState }, data) {
            commit("setLoading", true)
            commit("setError", null)
            commit("setErrors", [])

            try {
                const response = await fetch(`${url}ending/leagues/update`, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    },
                    body: data
                })

                const res = await response.json()

                if (!response.ok) {
                    throw { response, res }
                } 
            } catch (error) {
                const errorStatus = error.response.status

                switch(errorStatus) {
                    case 401:
                      commit("setError", "Unauthorized", { root: true })
                      break;
                    case 404:
                      commit("setError", error.res.messages.error)
                      break;
                    case 417:
                      commit("setPopUpErrror", error.res.error, { root: true })
                      break;
                    case 422:
                        commit("setErrors", error.res.messages) 
                      break;
                    default:
                      commit("setRefreshError", "An error occured, please try again!", { root: true })
                  } 
            } finally {
                commit("setLoading", false)
            }
        }
    }
}