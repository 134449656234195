<template>
 <v-dialog v-model="dialog" max-width="500">
  <v-carousel height="auto" :show-arrows="false" hide-delimiters>
    <v-btn
      fab
      style="top: 3px; right: 3px; opacity: 0.8;"
      absolute
      right
      id="closeBtn"
      small
      @click="$emit('setCloseModal')"
    >
      <v-icon color="white">mdi-close</v-icon>
    </v-btn>

    <v-carousel-item>
      <v-sheet 
        height="100%"
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
          no-gutters
        >
          <v-img :src="url"/>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
 </v-dialog>
</template>

<script>
export default {
  props: {
    url: {
      type: String
    }
  },

  computed: {
    dialog: {
      get: function() {
        return true
      },
      set: function() {
        this.closeModal()
      }
    },
  },

  methods: {
    closeModal() {
      this.$emit("setCloseModal")
    }
  }
}
</script>