var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Players")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Deposit Config")]),_c('v-card',{staticClass:"shadow"},[_c('v-card-title',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"2","align-self":"end"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-box-outline")])],1)]}}])},[_c('span',[_vm._v("Create")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","color":"secondary"},on:{"click":_vm.loadRecords}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cached")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search","single-line":"","hide-details":"auto","disabled":_vm.recordsLoading,"clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":10,"search":_vm.search,"loading":_vm.recordsLoading,"loading-text":"Loading, please wait a moment","sort-by":_vm.sortBy,"sort-asc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortAsc":function($event){_vm.sortDesc=$event},"update:sort-asc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.setDepositConfigData(
                    item.id,
                    item.from,
                    item.to,
                    item.amount
                  )}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Update")])])]}},{key:"item.from",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(("₱" + (_vm.money_format(parseFloat(item.from)))))+" ")]}},{key:"item.to",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(("₱" + (_vm.money_format(parseFloat(item.to)))))+" ")]}},{key:"item.amount",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.number_format(parseInt(item.amount)))+" ")]}}],null,true)})],1)],1),(_vm.dialogStatus)?_c('FormDialog',{staticClass:"mr-1",attrs:{"depositConfigData":_vm.depositConfigData},on:{"closeModal":_vm.setData}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }