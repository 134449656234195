<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Ending Reports</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Payouts</h2>
    <v-card class="shadow mb-4">
      <v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
        Payouts
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="py-4">
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="doGetPayouts">
          <v-row dense>
            <v-col cols="12" md="4" class="mt-2">
              <v-select
                v-model="filter_type"
                :items="filter_types"
                label="Filter By"
                dense
                outlined
                hide-details="auto"
              ></v-select>
            </v-col>

            <v-col cols="12" md="4" class="mt-2">
              <v-menu
                v-model="date_from_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_from"
                    :rules="[rules.required, lessThanDate]"
                    :label="`${filter_type} From`"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @keyup="date_from_menu = true"
                    @keydown="date_from_menu = false"
                    clearable
                  />
                </template>

                <v-date-picker
                  v-model="date_from"
                  @input="date_from_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4" class="mt-2">
              <v-menu
                v-model="date_to_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="date_from == ''"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_to"
                    :label="`${filter_type} To (Optional)`"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :disabled="dateToAvailability"
                    @keyup="date_to_menu = true"
                    @keydown="date_to_menu = false"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_to"
                  @input="date_to_menu = false"
                  :min="date_from ? date_from : ''"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row dense class="mt-2">
            <v-col>
              <v-btn depressed type="submit" color="primary"> Search </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <downloadexcel
              :data="records"
              :fields="{
                Id: 'id',
                'Schedule Game': 'scheduled_game',
                Traditional: 'traditional',
                'Per Quarter': 'per_quarter',
                'Total Payouts': 'payouts',
                Schedule: 'schedule',
              }"
              name="Payouts Report.xls"
              type="csv"
              class="d-inline-block"
            >
              <v-btn outlined color="primary"> Export CSV </v-btn>
            </downloadexcel>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-row dense>
        <v-col>
          <v-card-text>
            <v-row dense>
              <v-col align="center" cols="12" md="4" class="py-4">
                <v-card class="pa-1">
                  <p class="font-weight-regular ma-0 overline">TOTAL TRADITIONAL</p>
                  <v-divider class="mb-4" />
                  <p class="font-weight-medium">
                    PHP {{ moneyFormat(parseFloat(totalTraditional)) }}
                  </p>
                </v-card>
              </v-col>

              <v-col align="center" cols="12" md="4" class="py-4">
                <v-card class="pa-1">
                  <p class="font-weight-regular ma-0 overline">TOTAL PER QUARTER</p>
                  <v-divider class="mb-4" />
                  <p class="font-weight-medium">
                    PHP {{ moneyFormat(parseFloat(totalPerQuarter)) }}
                  </p>
                </v-card>
              </v-col>

              <v-col align="center" cols="12" md="4" class="py-4">
                <v-card class="pa-1">
                  <p class="font-weight-regular ma-0 overline">TOTAL PAYOUTS</p>
                  <v-divider class="mb-4" />
                  <p class="font-weight-medium">
                    PHP {{ moneyFormat(parseFloat(totalPayouts)) }}
                  </p>
                </v-card>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="records"
                  :items-per-page="5"
                  :search="search"
                  :loading="loading"
                  loading-text="Loading, please wait a moment"
                >
                  <template v-slot:[`item.action`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          dense
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          color="primary"
                          @click="
                            showPayoutsPerCard(
                              item.id,
                              item.league,
                              item.team_a_name,
                              item.team_a_alias,
                              item.team_a_logo,
                              item.team_b_name,
                              item.team_b_alias,
                              item.team_b_logo
                            )
                          "
                          class="ma-1"
                        >
                          <v-icon size="large"> mdi-cards </v-icon>
                        </v-btn>
                      </template>
                      <span>Show Per Cards</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:[`item.scheduled_game`]="{ item }">
                    <v-avatar size="36px" tile>
                      <v-img :src="item.team_a_logo">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                    <span class="text-caption"
                      >{{ item.team_a_name }} {{ item.team_a_alias }}</span
                    >
                    <span class="text-overline my-2 mx-2 font-italic">vs</span>
                    <v-avatar size="36px" tile>
                      <v-img :src="item.team_b_logo">
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular
                              indeterminate
                              color="black"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                    <span class="text-caption"
                      >{{ item.team_b_name }} {{ item.team_b_alias }}</span
                    >
                  </template>

                  <template v-slot:[`item.traditional`]="{ item }">
                    <span>PHP {{ moneyFormat(parseFloat(item.traditional)) }} </span>
                  </template>

                  <template v-slot:[`item.per_quarter`]="{ item }">
                    <span>PHP {{ moneyFormat(parseFloat(item.per_quarter)) }} </span>
                  </template>

                  <template v-slot:[`item.payouts`]="{ item }">
                    <span>PHP {{ moneyFormat(parseFloat(item.payouts)) }} </span>
                  </template>

                  <template v-slot:[`item.payout`]="{ item }">
                    <span>PHP {{ moneyFormat(parseFloat(item.payout)) }} </span>
                  </template>

                  <template v-slot:[`item.schedule`]="{ item }">
                    <span>{{ setCreatedAtFormat(item.schedule) }}</span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <PayoutPerCard
      v-if="payoutsPerCardDialog"
      :filters="filters"
      @setPayoutsPerCardCloseModal="setPayoutsPerCardCloseModal"
    />
  </div>
</template>

<script>
import formatDatev2 from "@/helpers/formatDatev2";
import downloadexcel from "vue-json-excel";
import formRules from "@/helpers/formRules";
import { mapActions, mapState } from "vuex";
import PayoutPerCard from "@/components/reports/PayoutsPerCardDialog";

export default {
  components: {
    downloadexcel,
    PayoutPerCard,
  },

  data: () => ({
    valid: true,
    search: "",
    rules: formRules,
    date_from: null,
    date_to: null,
    date_from_menu: false,
    date_to_menu: false,
    filter_type: "Scheduled Date",
    filter_types: ["Bet Date", "Scheduled Date"],
    query_filters: {},
    filters: {},
    payoutsPerCardDialog: false,
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      {
        text: "Schedule Game",
        value: "scheduled_game",
        class: "text-uppercase",
        align: "left",
      },
      {
        text: "Traditional",
        value: "traditional",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Per Quarter",
        value: "per_quarter",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Payouts",
        value: "payout",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Schedule",
        value: "schedule",
        class: "text-uppercase",
        align: "left",
      },
    ],
  }),

  created() {
    this.$store.commit("ending_reports_payouts/setRecords", []);

    const urlQuery = this.$route.query;

    if (Object.keys(urlQuery).length) {
      if (Object.prototype.hasOwnProperty.call(urlQuery, "filter_by"))
        this.filter_type = urlQuery.filter_by;
      if (Object.prototype.hasOwnProperty.call(urlQuery, "date_from"))
        this.date_from = urlQuery.date_from;
      if (Object.prototype.hasOwnProperty.call(urlQuery, "date_to"))
        this.date_to = urlQuery.date_to;

      this.doLoadPayoutRecords();
    }
  },

  computed: {
    ...mapState("ending_reports_payouts", ["loading", "records"]),

    lessThanDate() {
      if (this.date_from && this.date_to) {
        if (new Date(this.date_to) < new Date(this.date_from)) {
          return "Must be higher or equal than date from.";
        }
      }

      return true;
    },

    dateToAvailability() {
      if (this.date_from == "" || this.date_from == null) {
        return true;
      }

      return false;
    },

    totalTraditional() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.traditional) + total,
          0
        );
      } else {
        return 0;
      }
    },

    totalPerQuarter() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.per_quarter) + total,
          0
        );
      } else {
        return 0;
      }
    },

    totalPayouts() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseFloat(obj.payout) + total, 0);
      } else {
        return 0;
      }
    },
  },

  methods: {
    ...mapActions("ending_reports_payouts", ["getRecords"]),

    async doGetPayouts() {
      if (this.$refs.form.validate()) {
        if (!this.loading) {
          this.$store.commit("ending_reports_payouts/setRecords", []);

          await this.getRecords(this.setFilterFormData());

          this.setQueryString();
        }
      }
    },

    async doLoadPayoutRecords() {
      this.$store.commit("ending_reports_payouts/setRecords", []);

      await this.getRecords(this.setFilterFormData());
    },

    setFilterFormData() {
      const formData = new FormData();

      formData.append("filter_by", this.filter_type);
      formData.append("date_from", this.date_from);
      formData.append("date_to", this.date_to ?? this.date_from);

      return new URLSearchParams(formData);
    },

    setQueryString() {
      const urlQuery = this.$route.query;

      if (
        urlQuery.filter_by != this.filter_type ||
        urlQuery.date_from != this.date_from ||
        urlQuery.date_to != this.date_to
      ) {
        this.query_filters = {};

        if (this.filter_type != null) this.query_filters["filter_by"] = this.filter_type;
        if (this.date_from != null) this.query_filters["date_from"] = this.date_from;
        if (this.date_to != null) this.query_filters["date_to"] = this.date_to;

        this.$router.push({
          name: this.$route.name,
          query: this.query_filters,
        });
      }
    },

    showPayoutsPerCard(
      id,
      league,
      team_a_name,
      team_a_alias,
      team_a_logo,
      team_b_name,
      team_b_alias,
      team_b_logo
    ) {
      this.filters = {
        game_id: id,
        league: league,
        team_a_name: team_a_name,
        team_a_alias: team_a_alias,
        team_a_logo: team_a_logo,
        team_b_name: team_b_name,
        team_b_alias: team_b_alias,
        team_b_logo: team_b_logo,
        date_from: this.date_from,
        date_to: this.date_to,
      };
      this.payoutsPerCardDialog = true;
    },

    setPayoutsPerCardCloseModal() {
      this.payoutsPerCardDialog = false;
    },

    setCreatedAtFormat(date) {
      return formatDatev2(date);
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },

  watch: {
    date_from(val) {
      if (val == "" || val == null) {
        this.date_to = "";
      }
    },
  },
};
</script>
