const url = process.env.VUE_APP_API_URL + "ending/monitoring/"

export default {
    namespaced: true,
  
    state: {
      records: [],
      loading: false,
      addCardLoading: false,
      league: {},
      game: {},
      cardTreshHold: '0',
      addCardError: null,
    },

    mutations: {
        setLoading(state, val) {
            state.loading = val
        },

        setAddCardLoading(state, val) {
            state.addCardLoading = val
        },

        setAddCardError(state, val) {
            state.addCardError = val
        },

        setRecords(state, val) {
            state.records = val
        },

        setLeague(state, val) {
            state.league = val
        },

        setGame(state, val) {
            state.game = val
        },

        setCardTreshHold(state, val) {
            state.cardTreshHold = val
        }
    },
  
    actions: {
        async getRecords({ commit, rootState }, data) {
            commit("setLoading", true)

            try {
                const response = await fetch(`${url}game_card?${data}` , {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setCardTreshHold", res.card_treshhold)
                    commit("setRecords", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
                } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setLoading", false)
            }
        },

        async getRecordsPerGame({ commit, rootState }, data) {
            commit("setLoading", true)

            try {
                const response = await fetch(`${url}game_card/per_game?${data}` , {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setCardTreshHold", res.card_treshhold)
                    commit("setLeague", res.league)
                    commit("setRecords", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setLoading", false)
            }
        },

        async getRecordsPerBet({ commit, rootState }, data) {
            commit("setLoading", true)

            try {
                const response = await fetch(`${url}game_card/per_bet?${data}` , {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setCardTreshHold", res.card_treshhold)
                    commit("setGame", res.game)
                    commit("setRecords", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
                } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setLoading", false)
            }
        },

        async addCard({ commit, rootState }, data) {
            commit("setAddCardLoading", true)
      
            try {
                const response = await fetch(`${url}/add_card`, {
                    method: 'POST',
                    headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                    },
                    body: data
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setAddCardError", null)
                }
            } catch(error) {
                const errorStatus = error.response.status
        
                commit("setAddCardError", "Error") // Decoy error to detect success
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else if (errorStatus==417) {
                    commit("setPopUpError", error.res.error, { root: true })
                } else {
                    commit("setRefreshError", "An error occurred, please try again!", { root: true })
                }
            } finally {
                commit("setAddCardLoading", false)
            }
        },
    }
  }