<template>
    <v-dialog scrollable :fullscreen="fullscreen_status" v-model="dialog" max-width="1200px">
        <v-card>
            <v-card-title>
                <v-icon class="mr-2"> mdi-cards </v-icon> 

                PAYOUTS PER GAME CARD

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon 
                            absolute 
                            right 
                            class="mr-8" 
                            v-bind="attrs"
                            v-on="on" 
                            @click="fullscreen_status = !fullscreen_status">
                            <v-icon size="large">
                                {{ `mdi-${(fullscreen_status) ? 'dock-window' : 'window-maximize'}` }}
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ `${(fullscreen_status) ? 'Normal View' : 'Maximize'}` }}</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon absolute right @click="closeModal">
                            <v-icon size="large"
                                v-bind="attrs"
                                v-on="on">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Close</span>
                </v-tooltip>
            </v-card-title>

            <v-divider/>

            <v-card-text class="py-4">
                <!-- <v-row dense>
                    <v-col>
                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                            @submit.prevent="doGetPayoutsPerCard">

                            <v-row dense>
                                <v-col cols="12" md="4" class="mt-2">
                                    <v-select
                                        v-model="filter_type"
                                        :items="filter_types"
                                        label="Filter By"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" md="4" class="mt-2">
                                    <v-menu
                                        v-model="date_from_menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto">

                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="date_from"
                                                :rules="[rules.required, lessThanDate]"
                                                :label="`${filter_type} From`"
                                                hide-details="auto"
                                                readonly
                                                outlined
                                                dense
                                                v-bind="attrs"
                                                v-on="on"
                                                @keyup="date_from_menu=true"
                                                @keydown="date_from_menu=false"
                                                clearable/>
                                        </template>

                                        <v-date-picker
                                        v-model="date_from"
                                        @input="date_from_menu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" md="4" class="mt-2">
                                    <v-menu
                                        v-model="date_to_menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        :disabled="date_from == ''"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="date_to"
                                            :label="`${filter_type} To (Optional)`"
                                            hide-details="auto"
                                            readonly
                                            outlined
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="dateToAvailability"
                                            @keyup="date_to_menu=true"
                                            @keydown="date_to_menu=false"
                                            clearable
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date_to"
                                            @input="date_to_menu = false"
                                            :min="(date_from) ? date_from : ''"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                            <v-row dense class="mt-2">
                                <v-col>
                                    <v-btn
                                        depressed
                                        type="submit"
                                        color="primary">
                                        Search
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row> -->

                <v-row dense justify="center" align="center">
                    <v-col cols="auto">
                        {{ filters.league }}
                    </v-col>
                </v-row>

                <v-row dense justify="center" align="center">
                    <v-col
                        cols="auto"
                        class="text-center"
                        align-self="center"
                    >
                        <v-avatar
                        size="100px"
                        tile
                        >
                        <v-img
                            :src="filters.team_a_logo"
                        >
                            <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                indeterminate
                                color="black"
                                ></v-progress-circular>
                            </v-row>
                            </template>
                        </v-img>
                        </v-avatar>
                        <div>
                        <span class="text-caption">{{ filters.team_a_name }} {{ filters.team_a_alias }}</span>
                        </div>
                    </v-col>

                    <v-col
                        cols="auto"
                        class="text-center"
                    >
                        <span class="text-overline my-2 mx-2 font-italic">vs</span>
                    </v-col>
                    
                    <v-col
                        cols="auto"
                        class="text-center"
                    >
                        <v-avatar
                        size="100px"
                        tile
                        >
                        <v-img
                            :src="filters.team_b_logo"
                        >
                            <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                indeterminate
                                color="black"
                                ></v-progress-circular>
                            </v-row>
                            </template>
                        </v-img>
                        </v-avatar>
                        <div>
                        <span class="text-caption">{{ filters.team_b_name }} {{ filters.team_b_alias }}</span>
                        </div>
                    </v-col>
                </v-row>

                <v-row dense justify="space-around">
                    <v-col align="center" class="py-4">
                        <v-card class="pa-1">
                            <p class="font-weight-regular ma-0 overline">NO. OF CARDS</p>
                            <v-divider class="mb-4"/>
                            <p class="font-weight-medium">{{ parseInt(recordsPayoutsPerCard.length).toLocaleString() }}</p>
                        </v-card>
                    </v-col>

                    <v-col align="center" class="py-4">
                        <v-card class="pa-1">
                            <p class="font-weight-regular ma-0 overline">NO. OF WINNER</p>
                            <v-divider class="mb-4"/>
                            <p class="font-weight-medium">{{ parseInt(totalNumberOfWinners).toLocaleString() }}</p>
                        </v-card>
                    </v-col>

                    <v-col align="center" class="py-4">
                        <v-card class="pa-1">
                            <p class="font-weight-regular ma-0 overline">TOTAL TRADITIONAL</p>
                            <v-divider class="mb-4"/>
                            <p class="font-weight-medium">PHP {{ moneyFormat(parseFloat(totalTraditional)) }}</p>
                        </v-card>
                    </v-col>

                    <v-col align="center" class="py-4">
                        <v-card class="pa-1">
                            <p class="font-weight-regular ma-0 overline">TOTAL PER QUARTER</p>
                            <v-divider class="mb-4"/>
                            <p class="font-weight-medium">PHP {{ moneyFormat(parseFloat(totalPerQuarter)) }}</p>
                        </v-card>
                    </v-col>

                    <v-col align="center" class="py-4">
                        <v-card class="pa-1">
                            <p class="font-weight-regular ma-0 overline">TOTAL PAYOUTS</p>
                            <v-divider class="mb-4"/>
                            <p class="font-weight-medium">PHP {{ moneyFormat(parseFloat(totalPayouts)) }}</p>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col>
                        <v-data-table
                            :headers="headers"
                            :items="recordsPayoutsPerCard"
                            :items-per-page="5"
                            :search="search"
                            :loading="recordsPayoutsPerCardLoading"
                            loading-text="Loading, please wait a moment">

                            <template v-slot:[`item.action`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                            icon
                                            small
                                            color="primary"
                                            @click="setListOfWinnersModal(item.id, item.payout)"
                                            class="ma-1">
                                            <v-icon size="large"> mdi-seal-variant </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Show List of Winners</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:[`item.id`]="{ item }">
                                <span># {{ setCardPadding(item.id) }} </span>
                            </template>

                            <template v-slot:[`item.scheduled_game`]="{ item }">
                                <v-avatar
                                    size="36px"
                                    tile
                                >
                                    <v-img
                                    :src="item.team_a_logo"
                                    >
                                    <template v-slot:placeholder>
                                        <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                        >
                                        <v-progress-circular
                                            indeterminate
                                            color="black"
                                        ></v-progress-circular>
                                        </v-row>
                                    </template>
                                    </v-img>
                                </v-avatar>
                                <span class="text-caption">{{ item.team_a_name }} {{ item.team_a_alias }}</span>
                                <span class="text-overline my-2 mx-2 font-italic">vs</span>
                                <v-avatar
                                    size="36px"
                                    tile
                                >
                                    <v-img
                                    :src="item.team_b_logo"
                                    >
                                    <template v-slot:placeholder>
                                        <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                        >
                                        <v-progress-circular
                                            indeterminate
                                            color="black"
                                        ></v-progress-circular>
                                        </v-row>
                                    </template>
                                    </v-img>
                                </v-avatar>
                                <span class="text-caption">{{ item.team_b_name }} {{ item.team_b_alias }}</span>
                            </template>

                            <template v-slot:[`item.traditional`]="{ item }">
                                <span>PHP {{ moneyFormat(parseFloat(item.traditional)) }} </span>
                            </template>

                            <template v-slot:[`item.per_quarter`]="{ item }">
                                <span>PHP {{ moneyFormat(parseFloat(item.per_quarter)) }} </span>
                            </template>

                            <template v-slot:[`item.payouts`]="{ item }">
                                <span>PHP {{ moneyFormat(parseFloat(item.payouts)) }} </span>
                            </template>

                            <template v-slot:[`item.payout`]="{ item }">
                                <span>PHP {{ moneyFormat(parseFloat(item.payout )) }} </span>
                            </template>

                            <template v-slot:[`item.schedule`]="{ item }">
                                <span>{{ setCreatedAtFormat(item.schedule) }}</span>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <ListOfWinners v-if="listOfWinnersDialog" :card_id="card_id" :game_details="filters" :payouts="payouts" @setListOfWinnersCloseModal="setListOfWinnersCloseModal" />
    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import formRules from '@/helpers/formRules'
import ListOfWinners from '@/components/reports/PayoutsListOfWinners'

export default {
    components: { ListOfWinners },
    
    props: {
        filters: {
            type: Object
        }
    },

    data: () => ({
        fullscreen_status: false,
        valid: true,
        search: '',
        rules: formRules,
        date_from: null,
        date_to: null,
        date_from_menu: false,
        date_to_menu: false,
        filter_type: 'Scheduled Date',
        filter_types: ['Bet Date', 'Scheduled Date'],
        listOfWinnersDialog: false,
        card_id: 0,
        payouts: 0,
        headers: [
            {
                text: "Action",                
                sortable: false,
                value: "action",
                class: "text-uppercase",
                align: "center",
            },
            { 
                text: "Card Number", 
                value: "id", 
                class: "text-uppercase",
                align: "left" 
            },            
            { 
                text: "Bet Amount", 
                value: "bet_amount", 
                class: "text-uppercase",
                align: "right" 
            },
            {
                text: "No. of Winners",
                value: "winners",
                class: "text-uppercase",
                align: "right" 
            },
            {
                text: "Traditional",
                value: "traditional",
                class: "text-uppercase",
                align: "right" 
            },
            {
                text: "Per Quarter",
                value: "per_quarter",
                class: "text-uppercase",
                align: "right" 
            },
            {
                text: "Payouts",
                value: "payout",
                class: "text-uppercase",
                align: "right" 
            }
        ],
    }),

    mounted() {
        this.date_from = this.filters.date_from
        this.date_to = this.filters.date_to
    },

    created() {
        this.doGetPayoutsPerGame()
    },

    computed: {
        ...mapState("ending_reports_payouts", ["recordsPayoutsPerCardLoading", "recordsPayoutsPerCard"]),

        dialog: {
            get: function() {
                return true
            },
            set: function() {
                this.closeModal()
            }
        },

        lessThanDate() { 
            if (this.date_from && this.date_to) {
                if (new Date(this.date_to) < new Date(this.date_from)) {
                    return 'Must be higher or equal than date from.'
                } 
            } 
            
            return true
        },

        dateToAvailability() {
            if (this.date_from == '' || this.date_from == null) {
                return true
            }

            return false
        },

        totalNumberOfWinners() {
            if (this.recordsPayoutsPerCard) {
                return this.recordsPayoutsPerCard.reduce((total, obj) => parseInt(obj.winners) + total ,0)
            } else {
                return 0
            }
        },

        totalTraditional() {
            if (this.recordsPayoutsPerCard) {
                return this.recordsPayoutsPerCard.reduce((total, obj) => parseFloat(obj.traditional) + total ,0)
            } else {
                return 0
            }
        },

        totalPerQuarter() {
            if (this.recordsPayoutsPerCard) {
                return this.recordsPayoutsPerCard.reduce((total, obj) => parseFloat(obj.per_quarter) + total ,0)
            } else {
                return 0
            }
        },

        totalPayouts() {
            if (this.recordsPayoutsPerCard) {
                return this.recordsPayoutsPerCard.reduce((total, obj) => parseFloat(obj.payout) + total ,0)
            } else {
                return 0
            }
        },
    },

    methods: {
        ...mapActions("ending_reports_payouts", ["getPayoutsPerGame"]),

        setCardPadding(value) {
            return String(value).padStart(6, '0')
        },

        moneyFormat(val) {
            return val.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },

        async doGetPayoutsPerGame() {
            await this.getPayoutsPerGame(this.filters.game_id)
        },

        setListOfWinnersModal(id, payouts) {
            this.card_id = parseInt(id)
            this.payouts = payouts
            this.listOfWinnersDialog = true
        },

        setListOfWinnersCloseModal() {  
            this.listOfWinnersDialog = false
        },

        closeModal() {
            this.$emit("setPayoutsPerCardCloseModal")
        }
    },

    watch: {
        date_from (val) {
            if (val == '' || val == null) {
                this.date_to = ''
            }
        }
    }
}
</script>