<template>
  <v-dialog
    v-model="dialog"
    :max-width="!is_regular_player_or_sales_representative ? 1300 : 1200"
    persistent
  >
    <v-card>
      <div class="d-flex align-center justify-space-between px-6 py-4">
        <h3>
          {{ sales_manager_name + " — " + sales_manager_sub_agent_title + " List" }}
        </h3>
        <v-btn icon @click="close_sales_manager_dialog()">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <div class="d-flex justify-end px-6 py-4">
        <div class="search-wrapper">
          <v-text-field
            ref="search"
            v-model="search"
            placeholder="Search"
            single-line
            hide-details="auto"
            :disabled="loading"
            clearable
            outlined
            dense
          >
          </v-text-field>
        </div>
      </div>

      <v-divider></v-divider>

      <v-data-table
        v-if="!is_regular_player_or_sales_representative"
        :headers="table_headers"
        :items="sales_manager_sub_agent"
        :items-per-page="items_per_page"
        :search="search"
        :loading="loading"
        loading-text="Loading, please wait a moment"
        :sort-by.sync="sort_by"
        :sort-desc.sync="sort_desc"
      >
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>

        <template v-slot:[`item.sales_representative_count`]="{ item }">
          <a
            v-if="item.sales_representative_count != '0'"
            href="#"
            style="text-decoration: none;"
            @click.prevent="get_supervisor_sales_representative(item.id, item.name)"
          >
            {{ "View (" + item.sales_representative_count + ")" }}
          </a>
          <span v-else>-</span>
        </template>

        <template v-slot:[`item.regular_player_count`]="{ item }">
          <a
            v-if="item.regular_player_count != '0'"
            href="#"
            style="text-decoration: none;"
            @click.prevent="get_supervisor_regular_player(item.id, item.name)"
          >
            {{ "View (" + item.regular_player_count + ")" }}
          </a>
          <span v-else>-</span>
        </template>

        <template v-slot:[`item.mobile_number`]="{ item }">
          {{ "+63" + item.mobile_number }}
        </template>

        <template v-slot:[`item.commission`]="{ item }">
          {{ item.commission }}%
        </template>

        <template v-slot:[`item.sub_commission`]="{ item }">
          <span>{{ item.sub_commission }}%</span>
        </template>

        <template v-slot:[`item.wallet`]="{ item }">
          {{ "PHP " + money_format(parseFloat(item.wallet)) }}
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          {{ format_created_at(item.created_at) }}
        </template>
      </v-data-table>

      <v-data-table
        v-else
        :headers="regular_player_table_headers"
        :items="sales_manager_sub_agent"
        :items-per-page="items_per_page"
        :search="search"
        :loading="loading"
        loading-text="Loading, please wait a moment"
        :sort-by.sync="sort_by"
        :sort-desc.sync="sort_desc"
      >
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>

        <template v-slot:[`item.mobile_number`]="{ item }">
          {{ "+63" + item.mobile_number }}
        </template>

        <template v-slot:[`item.betting`]="{ item }">
          <v-chip
            small
            :class="
              item.betting == 'Enabled'
                ? 'green lighten-5 green--text'
                : 'red lighten-5 red--text'
            "
          >
            {{ item.betting }}
          </v-chip>
        </template>

        <template v-slot:[`item.cashin`]="{ item }">
          <v-chip
            small
            :class="
              item.cashin == 'Enabled'
                ? 'green lighten-5 green--text'
                : 'red lighten-5 red--text'
            "
          >
            {{ item.cashin }}
          </v-chip>
        </template>

        <template v-slot:[`item.cashout`]="{ item }">
          <v-chip
            small
            :class="
              item.cashout == 'Enabled'
                ? 'green lighten-5 green--text'
                : 'red lighten-5 red--text'
            "
          >
            {{ item.cashout }}
          </v-chip>
        </template>

        <template v-slot:[`item.is_active`]="{ item }">
          <v-chip
            small
            :class="
              item.is_active == 'Active'
                ? 'green lighten-5 green--text'
                : 'red lighten-5 red--text'
            "
          >
            {{ item.is_active }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <SalesSupervisorSubAgents
      v-if="supervisor_dialog"
      :supervisor_sub_agent_title="supervisor_sub_agent_title"
      :supervisor_id="supervisor_id"
      :supervisor_name="supervisor_name"
      @close_supervisor_dialog="close_supervisor_dialog"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import SalesSupervisorSubAgents from "@/components/agents/SalesSupervisorSubAgents.vue";

export default {
  data: () => ({
    table_headers: [
      { text: "Name", value: "name", class: "text-uppercase" },
      {
        text: "SR",
        value: "sales_representative_count",
        class: "text-uppercase",
      },
      { text: "RP", value: "regular_player_count", class: "text-uppercase" },
      { text: "Mobile Number", value: "mobile_number", class: "text-uppercase" },
      { text: "Commission", value: "commission", class: "text-uppercase" },
      { text: "Sub Commission", value: "sub_commission", class: "text-uppercase" },
      { text: "Wallet", value: "wallet", class: "text-uppercase" },
    ],
    regular_player_table_headers: [
      { text: "Name", value: "name", class: "text-uppercase" },
      { text: "Mobile Number", value: "mobile_number", class: "text-uppercase" },
      { text: "Betting", value: "betting", class: "text-uppercase" },
      { text: "Cash In", value: "cashin", class: "text-uppercase" },
      { text: "Cash Out", value: "cashout", class: "text-uppercase" },
      { text: "Status", value: "is_active", class: "text-uppercase" },
    ],
    sales_manager_sub_agent: [],
    supervisor_sub_agent: [],
    supervisor_sub_agent_title: null,
    supervisor_id: 0,
    supervisor_name: null,
    supervisor_dialog: false,
    loading: false,
    search: null,
    items_per_page: 5,
    sort_by: "name",
    sort_desc: true,
  }),
  mounted() {
    if (this.sales_manager_sub_agent_title == "Sales Supervisor") {
      this.get_sales_managers_sales_supervisor();
    } else if (this.sales_manager_sub_agent_title == "Sales Representative") {
      this.get_sales_managers_sales_representative();
    } else if (this.sales_manager_sub_agent_title == "Regular Player") {
      this.get_sales_managers_regular_player();
    }
  },

  props: {
    sales_manager_sub_agent_title: String,
    sales_manager_id: String,
    sales_manager_name: String,
  },
  name: "MasterAgentSubAgents",
  components: {
    SalesSupervisorSubAgents,
  },
  methods: {
    async get_sales_managers_sales_supervisor() {
      let url = process.env.VUE_APP_API_URL;
      let token = localStorage.getItem("userToken");

      this.loading = true;

      await axios
        .get(url + "agents/sales_manager_sales_supervisor", {
          params: { id: this.sales_manager_id },
          headers: { Authorization: `Bearer ` + token },
        })
        .then((res) => {
          this.sales_manager_sub_agent = res.data.records;
        });

      this.loading = false;
    },
    async get_sales_managers_sales_representative() {
      let url = process.env.VUE_APP_API_URL;
      let token = localStorage.getItem("userToken");

      this.loading = true;

      await axios
        .get(url + "agents/sales_manager_sales_representative", {
          params: { id: this.sales_manager_id },
          headers: { Authorization: `Bearer ` + token },
        })
        .then((res) => {
          this.sales_manager_sub_agent = res.data.records;
        });

      this.loading = false;
    },
    async get_sales_managers_regular_player() {
      let url = process.env.VUE_APP_API_URL;
      let token = localStorage.getItem("userToken");

      this.loading = true;

      await axios
        .get(url + "agents/sales_manager_regular_player", {
          params: { id: this.sales_manager_id },
          headers: { Authorization: `Bearer ` + token },
        })
        .then((res) => {
          this.sales_manager_sub_agent = res.data.records;
        });

      this.loading = false;
    },

    // ==================================================================

    get_supervisor_sales_representative(id, name) {
      this.supervisor_sub_agent_title = "Sales Representative";
      this.supervisor_id = id;
      this.supervisor_name = name;
      this.supervisor_dialog = true;
    },
    get_supervisor_regular_player(id, name) {
      this.supervisor_sub_agent_title = "Regular Player";
      this.supervisor_id = id;
      this.supervisor_name = name;
      this.supervisor_dialog = true;
    },
    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    close_sales_manager_dialog() {
      this.$emit("close_sales_manager_dialog", false);
    },
    close_supervisor_dialog() {
      this.supervisor_dialog = false;
      this.supervisor_sub_agent = [];
    },
  },
  computed: {
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.close_sales_manager_dialog();
      },
    },
    is_regular_player_or_sales_representative() {
      if (
        this.sales_manager_sub_agent_title == "Regular Player" ||
        this.sales_manager_sub_agent_title == "Sales Representative"
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style scoped>
.search-wrapper {
  display: inline-block;
}
.search {
  width: 400px;
}

@media (max-width: 768px) {
  .search-wrapper {
    width: 100% !important;
    display: block !important;
  }
  .search {
    width: 100% !important;
  }
}
</style>
