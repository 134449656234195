<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Settings</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">News and Updates</h2>
    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <v-tooltip
              top
              v-if="accountInfo.access && accountInfo.access.includes(80)"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="[(createUpdateDialog = true), (action = 'Create')]"
                >
                  <v-icon>mdi-plus-box-outline</v-icon>
                </v-btn>
              </template>

              <span>Create</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  @click="loadRecords"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="loading"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="5"
          :search="search"
          :loading="loading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template
            v-slot:[`item.action`]="{ item }"
            v-if="accountInfo.access && accountInfo.access.includes(80)"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dense
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  color="primary"
                  @click="showUpdateDialog(item)"
                >
                  <v-icon size="large"> mdi-square-edit-outline </v-icon>
                </v-btn>
              </template>
              <span>Update</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.image`]="{ item }">
            <v-img
              :src="item.image"
              max-width="50"
              max-height="50"
              contain
              @click="viewImage(item.image)"
              style="cursor: pointer"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="secondary"
                  ></v-progress-circular>
                </v-row> </template
            ></v-img>
          </template>

          <template v-slot:[`item.cover_image`]="{ item }">
            <v-img
              :src="item.cover_image"
              max-width="50"
              max-height="50"
              contain
              @click="viewImage(item.cover_image)"
              style="cursor: pointer"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="secondary"
                  ></v-progress-circular>
                </v-row> </template
            ></v-img>
          </template>

          <template v-slot:[`item.hashtags`]="{ item }">
            <v-chip
              v-for="(hash, hashIndex) in item.hashtags"
              :key="hashIndex"
              outlined
              color="grey"
              small
              style="padding-inline: 0.75rem !important"
            >
              #{{ hash }}
            </v-chip>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              small
              :class="
                item.status == 'Active'
                  ? 'green lighten-5 green--text'
                  : 'red lighten-5 red--text'
              "
            >
              {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ dateFormat(item.created_at) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Create and Update Dialog -->
    <v-dialog v-model="createUpdateDialog" max-width="500">
      <v-card :loading="dialogLoading">
        <v-card-title>
          {{ action }}
          <v-btn icon absolute right @click="createUpdateDialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-divider />

        <v-card-text class="py-4">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            :disabled="dialogLoading"
            @submit.prevent="doSubmit"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  ref="title"
                  v-model="title"
                  label="Title"
                  :rules="[
                    rules.required,
                    rules.minimum(2),
                    rules.maximum(100),
                  ]"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  ref="game"
                  v-model="game"
                  label="Game"
                  :rules="[rules.minimum(2), rules.maximum(100)]"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-file-input
                  ref="image"
                  v-model="image"
                  :rules="action == 'Create' ? [rules.required] : []"
                  accept="image/*"
                  prepend-icon=""
                  append-icon="mdi-open-in-app"
                  show-size
                  label="Choose Image"
                  placeholder="Choose Image"
                  dense
                  outlined
                  validate-on-blur
                  counter
                  hide-details="auto"
                ></v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-file-input
                  ref="coverImage"
                  v-model="coverImage"
                  :rules="action == 'Create' ? [rules.required] : []"
                  accept="image/*"
                  prepend-icon=""
                  append-icon="mdi-open-in-app"
                  show-size
                  label="Choose Cover Image"
                  placeholder="Choose Cover Image"
                  dense
                  outlined
                  validate-on-blur
                  counter
                  hide-details="auto"
                ></v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <div class="mb-1">
                  <v-chip
                    v-for="(tag, index) in tags"
                    :key="index"
                    closable
                    @click="removeTag(index)"
                    class="mr-2 mb-2"
                  >
                    {{ tag }}
                  </v-chip>
                </div>
                <v-text-field
                  ref="currentTag"
                  v-model="currentTag"
                  @input="updateTag"
                  outlined
                  dense
                  hide-details="auto"
                  label="Tags (e.g., GameX Sports, Happy Ending)"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-textarea
                  ref="description"
                  outlined
                  dense
                  label="Description"
                  :rules="[rules.minimum(2), rules.maximum(5000)]"
                  v-model="description"
                  hide-details="auto"
                  rows="4"
                />
              </v-col>
            </v-row>

            <v-row dense v-if="action == 'Update'">
              <v-col>
                <v-switch
                  v-model="status"
                  :label="status ? 'Active' : 'Inactive'"
                  color="success"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>

            <v-row dense class="mt-4">
              <v-col class="text-end">
                <v-btn
                  depressed
                  @click="createUpdateDialog = false"
                  class="mr-2"
                >
                  Cancel
                </v-btn>

                <v-btn
                  depressed
                  color="primary"
                  type="submit"
                  :loading="dialogLoading"
                >
                  {{ action }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewImageDialog" max-width="500">
      <v-carousel height="auto" :show-arrows="false" hide-delimiters>
        <v-btn
          fab
          style="top: 3px; right: 3px; opacity: 0.8"
          absolute
          right
          id="closeBtn"
          small
          @click="viewImageDialog = false"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>

        <v-carousel-item>
          <v-sheet height="100%">
            <v-row
              class="fill-height"
              align="center"
              justify="center"
              no-gutters
            >
              <v-img :src="viewImageUrl" />
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import responseGet from "@/helpers/api_request_get";
import responsePost from "@/helpers/api_request_post";
import formatDate from "@/helpers/formatDatev2";
import formRules from "@/helpers/formRules";

export default {
  data: () => ({
    loading: false,
    records: [],
    search: "",
    sortBy: "created_at",
    sortDesc: true,
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      {
        text: "Image",
        sortable: false,
        value: "image",
        class: "text-uppercase",
      },
      {
        text: "Cover Image",
        sortable: false,
        value: "cover_image",
        class: "text-uppercase",
      },
      {
        text: "Title",
        value: "title",
        class: "text-uppercase",
      },
      {
        text: "Game",
        value: "game",
        class: "text-uppercase",
      },
      {
        text: "Hashtags",
        sortable: false,
        value: "hashtags",
        class: "text-uppercase",
      },
      {
        text: "Status",
        sortable: false,
        value: "status",
        class: "text-uppercase",
      },
      {
        text: "Created_at",
        sortable: false,
        value: "created_at",
        class: "text-uppercase",
      },
    ],
    createUpdateDialog: false,
    dialogLoading: false,
    valid: true,
    rules: formRules,
    title: null,
    game: null,
    currentTag: null,
    tags: [],
    image: null,
    coverImage: null,
    description: null,
    status: true,
    id: null,
    action: null,
    viewImageDialog: false,
    viewImageUrl: null,
  }),

  mounted() {
    this.loadRecords();
  },

  computed: {
    ...mapState(["accountInfo"]),
  },

  methods: {
    ...mapActions("logout", ["sendLogoutRequest"]),

    async loadRecords() {
      if (!this.loading) {
        this.loading = true;

        const response = await responseGet("", "news_and_updates");

        switch (response.status) {
          case 200:
            this.records = response.data;
            break;
          case 401:
            await this.sendLogoutRequest();
            this.$router.push({ name: "Home" });
            break;
        }

        this.loading = false;
      }
    },

    dateFormat(date) {
      return formatDate(date);
    },

    removeTag(index) {
      this.tags.splice(index, 1);
    },

    updateTag() {
      if (this.currentTag) {
        if (this.currentTag.includes(",")) {
          const newTags = this.currentTag.split(",").map((tag) => tag.trim());
          this.tags = [...this.tags, ...newTags.filter((tag) => tag !== "")];
          this.currentTag = "";
        }
      }
    },

    async doSubmit() {
      if (this.$refs.form.validate()) {
        this.dialogLoading = true;

        try {
          let hashtags = this.currentTag;
          if (this.tags.length) {
            hashtags = this.tags.toString();
          }

          const form_data = new FormData();
          form_data.append("title", this.title);
          form_data.append("game", this.game);
          form_data.append("hashtags", hashtags);
          form_data.append("image", this.image);
          form_data.append("coverImage", this.coverImage);
          form_data.append("description", this.description);

          let response = [];
          if (this.action == "Create") {
            response = await responsePost(form_data, "news_and_updates");
          } else {
            form_data.append("id", this.id);
            form_data.append("status", this.status ? "Active" : "Inactive");

            response = await responsePost(form_data, "news_and_updates/update");
          }

          switch (response.status) {
            case 200:
              this.createUpdateDialog = false;
              if (this.action == "Create") {
                this.records = [response.data.insertedData, ...this.records];
              } else {
                const records = this.records.find(
                  (record) => record.id == response.data.updatedData.id
                );

                records.id = response.data.updatedData.id;
                records.title = response.data.updatedData.title;
                records.game = response.data.updatedData.game;
                records.hashtags = response.data.updatedData.hashtags;
                records.description = response.data.updatedData.description;
                records.status = response.data.updatedData.status;
                if (response.data.updatedData.image) {
                  records.image = response.data.updatedData.image;
                }
                if (response.data.updatedData.cover_image) {
                  records.cover_image = response.data.updatedData.cover_image;
                }
              }
              break;
            case 401:
              await this.sendLogoutRequest();
              this.$router.push({ name: "Home" });
              break;
            default:
              alert("An error occurred, Please refresh the page.");
              break;
          }
        } catch (error) {
          alert("An error occurred, Please refresh the page.");
        } finally {
          this.dialogLoading = false;
        }
      }
    },

    showUpdateDialog(data) {
      this.id = data.id;
      this.title = data.title;
      this.game = data.game;
      this.currentTag = data.hashtags;
      this.description = data.description;
      this.action = "Update";
      this.status = data.status == "Active" ? true : false;
      this.createUpdateDialog = true;
    },

    viewImage(image) {
      this.viewImageUrl = image;
      this.viewImageDialog = true;
    },
  },

  watch: {
    createUpdateDialog(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.title.focus();
        }, 200);
      } else {
        this.$refs.form.reset();
        this.tags = [];
      }
    },
  },
};
</script>
