<template>
  <v-dialog
    persistent
    :fullscreen="!$vuetify.breakpoint.mdAndUp"
    v-model="dialog"
    max-width="1100"
  >
    <v-card class="shadow">
      <div class="d-flex align-center justify-space-between pa-6">
        <h3>Manage Sub Accounts</h3>
        <v-btn icon @click="set_close_dialog">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <div class="d-flex flex-column" style="gap: 1.5rem">
          <div class="d-flex justify-space-between align-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="black"
                  v-bind="attrs"
                  v-on="on"
                  @click="set_add_account_dialog('Add', {})"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>New</span>
            </v-tooltip>
            <v-card-title class="pa-0">
              <v-text-field
                ref="search"
                v-model="search"
                placeholder="Search"
                single-line
                hide-details="auto"
                clearable
                outlined
                dense
              ></v-text-field>
            </v-card-title>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :items="sub_agents"
        :items-per-page="$vuetify.breakpoint.mdAndUp ? 10 : 5"
        :search="search"
        :loading="sub_agents_loading"
        loading-text="Loading, Please wait ..."
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-slot:[`item.action`]="{ item }">
          <div style="display: flex">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="pointer-events: none"
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  :color="item.status == 'Active' ? 'red' : 'green'"
                >
                  <v-icon size="large">
                    {{
                      `mdi-${
                        item.status == "Active" ? "account-lock" : "account-lock-open"
                      }`
                    }}
                  </v-icon>
                </v-btn>
              </template>
              {{ item.status == "Active" ? "Deactivate" : "Activate" }}
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="set_add_account_dialog('Edit', item)"
                  icon
                  small
                  color="primary"
                >
                  <v-icon size="large"> mdi-square-edit-outline </v-icon>
                </v-btn>
              </template>
              Edit
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.mobile_number`]="{ item }">
          {{ `+63${item.mobile_number}` }}
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            small
            
            :class="
              item.status == 'Active'
                ? 'green lighten-5 green--text'
                : 'red lighten-5 red--text'
            "
          >
            {{ item.status }}
          </v-chip>
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          <span>{{ format_date(item.created_at) }}</span>
        </template>
      </v-data-table>
    </v-card>

    <AddAccount
      v-if="add_account_dialog"
      @set_close_add_account_dialog="set_close_add_account_dialog"
      @refresh_sub_user_list="get_sub_agents"
      :sub_user_info="sub_user_info"
      :sub_user_modal_title="sub_user_modal_title"
      :investor_id="investor_id"
    />
  </v-dialog>
</template>

<script>
import responseGet from "@/helpers/api_request_get";
import AddAccount from "@/components/investors/CreateUpdateSubUsers";
import formatDatev2 from "@/helpers/formatDatev2";

export default {
  components: { AddAccount },

  props: {
    investor_id: Number,
  },

  data: () => ({
    add_account_dialog: false,
    sub_agents: [],
    sub_agents_loading: false,
    search: "",
    sortBy: "created_at",
    sortDesc: true,
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
      },
      {
        text: "First Name",
        value: "first_name",
        class: "text-uppercase",
      },
      { text: "Last Name", value: "last_name", class: "text-uppercase" },
      { text: "Mobile Number", value: "mobile_number", class: "text-uppercase" },
      { text: "Status", value: "status", class: "text-uppercase" },
      { text: "Date Created", value: "created_at", class: "text-uppercase" },
    ],
    sub_user_modal_title: "",
    sub_user_info: {},
  }),

  computed: {
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.set_close_dialog();
      },
    },
  },

  mounted() {
    this.get_sub_agents();
  },

  methods: {
    async get_sub_agents() {
      this.sub_agents_loading = !this.sub_agents_loading;

      const response = await responseGet(this.set_form_data(), "investors/sub_accounts");

      if (response.status == 200) {
        this.sub_agents = response.data.records;
      }

      this.sub_agents_loading = !this.sub_agents_loading;
    },

    set_form_data() {
      const form_data = new FormData();

      form_data.append("investor_id", this.investor_id);

      return new URLSearchParams(form_data);
    },

    set_add_account_dialog(title, info) {
      this.sub_user_info = info;
      this.sub_user_modal_title = title;
      this.add_account_dialog = !this.add_account_dialog;
    },

    set_close_add_account_dialog() {
      this.add_account_dialog = !this.add_account_dialog;
    },

    set_close_dialog() {
      this.$emit("set_close_manage_sub_users_dialog");
    },

    format_date(date) {
      return formatDatev2(date);
    },
  },
};
</script>
