<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Players</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Deposit Config</h2>
    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="handleClick"
                >
                  <v-icon>mdi-plus-box-outline</v-icon>
                </v-btn>
              </template>

              <span>Create</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  @click="loadRecords"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="recordsLoading"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="10"
          :search="search"
          :loading="recordsLoading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sortBy"
          :sort-asc.sync="sortDesc"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  color="primary"
                  @click="
                    setDepositConfigData(
                      item.id,
                      item.from,
                      item.to,
                      item.amount
                    )
                  "
                >
                  <v-icon size="large"> mdi-square-edit-outline </v-icon>
                </v-btn>
              </template>
              <span>Update</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.from`]="{ item }">
            {{ `₱${money_format(parseFloat(item.from))}` }}
          </template>

          <template v-slot:[`item.to`]="{ item }">
            {{ `₱${money_format(parseFloat(item.to))}` }}
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            {{ number_format(parseInt(item.amount)) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <FormDialog
      v-if="dialogStatus"
      :depositConfigData="depositConfigData"
      class="mr-1"
      @closeModal="setData"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import FormDialog from "@/components/deposit_config/FormDialog";

export default {
  components: { FormDialog },
  data: () => ({
    depositConfigData: [],
    dialogStatus: false,
    search: "",
    sortBy: "id",
    sortDesc: true,
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      {
        text: "From",
        value: "from",
        class: "text-uppercase",
      },
      {
        text: "To",
        value: "to",
        class: "text-uppercase",
      },
      {
        text: "Free Token",
        value: "amount",
        class: "text-uppercase",
      },
    ],
  }),
  created() {
    this.loadRecords();
  },

  computed: {
    ...mapState("deposit_config", ["records", "recordsLoading"]),
  },

  methods: {
    ...mapActions("deposit_config", ["getRecords"]),

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    loadRecords() {
      if (!this.recordsLoading) {
        this.$store.commit("deposit_config/setRecords", []);
        this.getRecords();
      }
    },

    handleClick() {
      this.dialogStatus = true;
      this.depositConfigData = {};
    },

    setDepositConfigData(id, from, to, amount) {
      this.dialogStatus = true;
      this.depositConfigData = { id, from, to, amount };
    },

    setData(updatedData, insertedData) {
      this.dialogStatus = false;

      if (Object.keys(updatedData).length != 0) {
        const depositConfigData = this.records.find(
          (record) => record.id == updatedData.id
        );

        depositConfigData.from = updatedData.from;
        depositConfigData.to = updatedData.to;
        depositConfigData.amount = updatedData.amount;
      } else if (Object.keys(insertedData).length != 0) {
        this.$store.commit("deposit_config/setRecords", [
          insertedData,
          ...this.records,
        ]);
      }
    }
  },
};
</script>
