<template>
  <div class="d-inline-block">
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          small
          color="primary"
          @click="dialog = true"
        >
          <v-icon
            size="large"
          >
            mdi-plus-box-multiple-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Add Card</span>
    </v-tooltip>

    <v-dialog
      v-model="dialog"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h6">
          Add Card
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="py-4"
        >
          <p class="mb-0">Amount: {{ 'PHP ' + moneyFormat(parseFloat(compData.amount)) }}</p>
          <p>Bet Type: {{ compData.bet_type }}</p>
          
          <v-form 
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleSubmit"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  ref="card_count"
                  v-model="card_count"
                  :rules="[rules.required]"
                  label="Card Count"
                  type="number"
                  placeholder="Enter the card count"
                  dense
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <div class="mt-4 text-right">
              <v-btn
                color="black"
                outlined
                class="me-2"
                @click="dialog = false"
              >
                Cancel
              </v-btn>

              <v-btn
                :disabled="!valid"
                type="submit"
                color="primary"
                outlined
                @click="handleSubmit"
                :loading="addCardLoading"
              >
                Add
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import formRules from '@/helpers/formRules'

export default {
  props: {
    compData: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    valid: true,
    rules: formRules,
    card_count: ''
  }),
  computed: {
    ...mapState("ending_game_details", ["addCardError", "addCardLoading"]),
  },
  methods: {
    ...mapActions("ending_game_details", ["addCard"]),
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('game_id', this.compData.game_id)
        formData.append('bet_amount_id', this.compData.bet_amount_id)
        formData.append('card_count', this.card_count)

        if (!this.addCardLoading) {
          await this.addCard(formData)

          if (!this.addCardError) {
            this.$refs.form.reset()

            this.dialog = false

            this.$emit('created')
          }
        }
      }
    }
  },
  watch: {
    dialog(visible) {
      if (visible) {
        setTimeout(() => {
          this.$refs.card_count.focus()
        }, 200);
      }
    }
  }
}
</script>