<template>
  <v-dialog :fullscreen="!$vuetify.breakpoint.mdAndUp" scrollable persistent v-model="dialog" max-width="600">
    <v-card class="bs-shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-regular">
        <span>Commission Confirm</span>

        <v-btn icon @click="close_dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="d-flex flex-column">
        <v-alert v-if="alert_dialog" dense type="error" class="mx-1">
          {{ alert_message }}
        </v-alert>

        <v-data-table :headers="headers" :items="commission_details_confirmation_data.selected" :search="search"
          :items-per-page="-1" item-key="ivnestor_id" class="mx-1" :hide-default-footer="true">

          <template v-slot:top>
            <v-text-field ref="search" v-model="search" label="Search" single-line outlined dense autofocus
              placeholder="Search" hide-details="auto"/>
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <span>{{ `Php ${money_format(parseFloat(item.amount))}` }}</span>
          </template>

          <template v-slot:[`item.commission_percent`]="{ item }">
            <span>{{ `${number_format(parseInt(item.commission_percent))}%` }}</span>
          </template>

          <template v-slot:[`item.commission_amount`]="{ item }">
            <span>{{ `Php ${money_format(parseFloat(item.commission_amount))}` }}</span>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ format_date(item.created_at) }}</span>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <div class="d-flex flex-column" style="gap:.75rem; width: 100%;">
          <v-text-field v-model="mode_of_payment" autofocus outlined dense label="Mode of payment (Optional)"
            placeholder="eg. GCash, Paymaya or any banks" hide-details="auto" />

          <v-text-field v-model="reference" outlined dense label="Reference (Optional)" placeholder="eg. 9812345678"
            hide-details="auto" />

          <v-textarea v-model="remarks" auto-grow outlined label="Remarks (Optional)" rows="2" row-height="20"
            placeholder="For additional info purposes" hide-details="auto" />

          <div class="d-flex" :class="$vuetify.breakpoint.mdAndUp ? 'justify-space-between align-end' : 'flex-column'"
            :style="$vuetify.breakpoint.mdAndUp ? 'gap: 0' : 'gap: .5rem'" style="width: 100%;">
            <div class="d-flex">
              <span>
                {{ `Total item${parseInt(commission_details_confirmation_data.selected.length) > 1 ? 's' : ''} ` }}
                <span class="success--text font-weight-regular">
                  {{ `${number_format(parseInt(commission_details_confirmation_data.selected.length))}` }}
                </span></span>
              <v-divider vertical class="mx-2" />
              <span>Total amount of <span class="success--text font-weight-regular">
                  {{ `Php ${commission_details_confirmation_data.total_commission}` }}
                </span></span>
            </div>

            <div class="d-flex justify-end">
              <v-btn text depressed color="grey" class="mr-1 px-2" @click="close_dialog">
                Cancel
              </v-btn>
              <v-btn depressed color="primary" class="px-2" @click="doApproveCommissions" :loading="approval_loading"
                :disabled="approval_loading || approval_status">
                Confirm
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-actions>
    </v-card>

    <CommissionDetailsPrint v-if="commission_print_dialog" :commission_print_data="approval_data"
      @set_close_commission_print_dialog="set_close_commission_print_dialog" />
  </v-dialog>
</template>

<script>
import formatDatev2 from "@/helpers/formatDatev2"
import responsePost from '@/helpers/api_request_post'
import CommissionDetailsPrint from '@/components/investors/CommissionDetailsPrint'

export default {
  components: { CommissionDetailsPrint },

  props: {
    commission_details_confirmation_data: {
      type: Object
    }
  },

  data: () => ({
    search: '',
    base_compiled_ids: [],
    referral_compiled_ids: [],
    approval_status: false,
    approval_data: [],
    approval_loading: false,
    alert_dialog: false,
    alert_message: '',
    commission_print_dialog: false,
    mode_of_payment: '',
    reference: '',
    remarks: '',
    headers: [
      {
        text: "Type",
        value: "type",
        class: "text-uppercase",
      },
      {
        text: "Agent",
        value: "agent",
        class: "text-uppercase",
      },
      {
        text: "Commission",
        value: "commission_amount",
        align: "right",
        class: "text-uppercase",
      },
    ],
  }),

  mounted() {
    this.get_compiled_ids()
  },

  computed: {
    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    }
  },

  methods: {
    async doApproveCommissions() {
      this.approval_loading = !this.approval_loading

      const response = await responsePost(this.set_form_data(), 'investor/commission/approve')

      switch (parseInt(response.status)) {
        case 200:
          this.approval_status = !this.approval_status
          this.approval_data = response.data.records
          this.commission_print_dialog = !this.commission_print_dialog
          break
        case 422:
          this.alert_dialog = !this.alert_dialog
          this.alert_message = 'Failed to settle commissions.'
          break
        default:
          this.alert_dialog = !this.alert_dialog
          this.alert_message = 'Something went wrong.'
      }

      this.approval_loading = !this.approval_loading
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('investor_id', this.commission_details_confirmation_data.investor_id)
      form_data.append('base_commission_ids', JSON.stringify(this.base_compiled_ids))
      form_data.append('referral_commission_ids', JSON.stringify(this.referral_compiled_ids))
      form_data.append('total_commissions', this.commission_details_confirmation_data.total_commission)
      form_data.append('date_from', this.commission_details_confirmation_data.date_from)
      form_data.append('date_to', this.commission_details_confirmation_data.date_to)
      form_data.append('mode_of_payment', this.mode_of_payment)
      form_data.append('reference', this.reference)
      form_data.append('remarks', this.remarks)

      return new URLSearchParams(form_data)
    },

    set_close_commission_print_dialog() {
      this.commission_print_dialog = !this.commission_print_dialog
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    get_compiled_ids() {
      if (this.commission_details_confirmation_data.selected) {
        this.commission_details_confirmation_data.selected.forEach(element => {
          if (element.type == 'Base Commission') this.base_compiled_ids.push(parseInt(element.investor_id))
          if (element.type == 'Referral Commission') this.referral_compiled_ids.push(parseInt(element.investor_id))
        });
      }
    },

    format_date(date) {
      return formatDatev2(date)
    },

    close_dialog() {
      this.$emit("set_close_commission_details_dialog", this.approval_status)
    }
  },

  watch: {
    alert_dialog(val) {
      if (val) {
        setTimeout(() => {
          this.alert_dialog = !this.alert_dialog
        }, 5000)
      }
    }
  }
}
</script>