<template>
  <v-dialog :persistent="approve_loading" v-model="dialog" max-width="400">
    <v-card class="shadow">
      <v-card-title class="text-h6 font-weigth-regular">
        <span>{{ `Approve — ${data.agent}` }}</span>
      </v-card-title>

      <v-card-text>
        <v-form class="d-flex flex-column" style="gap:1rem" @submit.prevent="doApprove"
          :disabled="approve_loading || approve_status">
          <v-alert v-if="alert_dialog" dense class="mb-0"
            :class="approve_status ? 'green lighten-5 green--text' : 'red lighten-5 red--text'">
            {{ alert_message }}
          </v-alert>

          <span>You're about to approve commission of <strong>{{ `Php ${data.amount}` }}</strong>, do you really want to
            proceed?</span>

          <v-text-field v-model="mode_of_payment" autofocus outlined dense label="Mode of payment (Optional)"
            placeholder="eg. GCash, Paymaya or any banks" hide-details="auto" />

          <v-text-field v-model="reference" outlined dense label="Reference (Optional)" placeholder="eg. 9812345678"
            hide-details="auto" />

          <v-textarea v-model="remarks" auto-grow outlined label="Remarks (Optional)" rows="2" row-height="20"
            placeholder="For additional info purposes" hide-details="auto" />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <div class="d-flex justify-end" style="width: 100%; gap:.30rem">
          <v-btn depressed class="grey lighten-5 px-2" @click="close_dialog"
            :disabled="approve_loading || approve_status">
            No
          </v-btn>

          <v-btn depressed color="primary" @click="doApprove" :loading="approve_loading" :disabled="approve_status">
            Yes
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import responsePost from '@/helpers/api_request_post'

export default {
  props: {
    data: {
      type: Object
    }
  },

  data: () => ({
    approve_status: false,
    approve_loading: false,
    alert_dialog: false,
    alert_message: '',
    mode_of_payment: '',
    reference: '',
    remarks: ''
  }),

  computed: {
    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    }
  },

  methods: {
    async doApprove() {
      this.approve_loading = !this.approve_loading

      const response = await responsePost(this.set_form_data(), 'investor/special_commission/approve')
      this.alert_dialog = true

      switch (response.status) {
        case 200:
          this.approve_status = true
          this.alert_message = 'Successfully approved'
          break
        case 409:
          this.alert_message = 'Something went wrong'
          break
        case 401:
          await this.sendLogoutRequest();
          this.$router.push({ name: "Home" });
          break
      }

      this.approve_loading = !this.approve_loading
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('special_settlement_id', this.data.id)
      form_data.append('mode_of_payment', this.mode_of_payment)
      form_data.append('reference', this.reference)
      form_data.append('remarks', this.remarks)

      return form_data
    },

    close_dialog() {
      this.$emit("set_close_special_commission_approve_dialog", { type: false })
    }
  },

  watch: {
    approve_status(val) {
      if (val) {
        setTimeout(() => {
          this.$emit("set_close_special_commission_approve_dialog", { 'type': true, 'id': this.data.id })
        }, 2000);
      }
    }
  }
}
</script>