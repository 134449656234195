<template>
  <div>
    <div class="d-flex align-center justify-space-between gap px-4 px-md-6 py-4">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="secondary"
            @click="loadRecords"
            v-bind="attrs"
            v-on="on"
            
          >
            <v-icon>mdi-cached</v-icon>
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>
      <div class="d-flex align-center gap">
        <v-text-field
          v-model="search"
          placeholder="Search"
          single-line
          hide-details="auto"
          clearable
          outlined
          dense
        >
        </v-text-field>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn icon color="secondary" @click="filter" v-on="on" :disabled="loading">
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
          </template>
          Filter
        </v-tooltip>
      </div>
    </div>

    <v-divider></v-divider>

    <v-card-text class="pa-0">
      <v-data-table
        :headers="headersTransaction"
        :items="records"
        :items-per-page="5"
        :search="search"
        :loading="loading"
        loading-text="Loading, please wait a moment"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :hide-default-header="records && records.length == 0 ? true : false"
        :hide-default-footer="records && records.length == 0 ? true : false"
      >
        <template v-slot:[`item.created_at`]="{ item }">
          <span>{{ setCreatedAtFormat(item.created_at) }}</span>
        </template>
      </v-data-table>
    </v-card-text>

    <PlayerLogsFilter v-if="dialog" :dates="dates" @setCloseModal="closeModal" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formatDatev2 from "@/helpers/formatDatev2";
import { format, sub } from "date-fns";
import PlayerLogsFilter from "@/components/playerDetails/PlayerLogsFilter";

export default {
  props: {
    id: {
      required: true,
    },
  },
  components: { PlayerLogsFilter },
  data: () => ({
    dialog: false,
    date_from: format(sub(new Date(), { days: 30 }), "yyyy-MM-dd"),
    date_from_menu: false,
    date_to: format(new Date(), "yyyy-MM-dd"),
    date_to_menu: false,
    dates: {},
    search: "",
    sortBy: "created_at",
    sortDesc: true,
    headersTransaction: [
      // { text: 'ID', align: 'end', value: 'id', },
      { text: "DETAILS", value: "details" },
      { text: "CREATED AT", value: "created_at" },
    ],
  }),
  computed: {
    ...mapState("player_details", ["loading", "records"]),
  },
  created() {
    this.loadRecords();
  },
  methods: {
    ...mapActions("player_details", ["getPlayersLogs"]),
    loadRecords() {
      if (!this.recordsLoading) {
        this.$store.commit("player_details/setRecords", []);

        const formData = new FormData();
        formData.append("id", this.id);
        formData.append("date_from", this.date_from);
        formData.append("date_to", this.date_to);

        const getData = new URLSearchParams(formData);

        this.getPlayersLogs(getData);
      }
    },
    setCreatedAtFormat(date) {
      return formatDatev2(date);
    },
    closeModal(dates_filter) {
      this.dialog = false;
      console.log(dates_filter.filter_status);
      if (dates_filter.filter_status == "search") {
        this.date_from = dates_filter.filter_date_from;
        this.date_to = dates_filter.filter_date_to;
        this.loadRecords();
      }
    },
    filter() {
      if (!this.loading) {
        this.dates = {
          date_from: this.date_from,
          date_to: this.date_to,
        };
        this.dialog = true;
      }
    },
  },
};
</script>
