<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Players</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Player List</h2>

    <v-card class="shadow">
      <div class="d-flex justify-space-between align-center bs-py-3 bs-px-4">
        <div class="d-flex">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="secondary"
                v-bind="attrs"
                v-on="on"
                @click="doGetPlayers"
              >
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </template>
            <span>Refresh</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <downloadexcel
                :data="records"
                :fields="{
                  'PLAYER ID': 'id',
                  'FIRST NAME': 'first_name',
                  'MIDDLE NAME': 'middle_name',
                  'LAST NAME': 'last_name',
                  'MOBILE NUMBER': {
                    callback: (value) => {
                      return `+63${value.mobile_number}`;
                    },
                  },
                  EMAIL: 'email',
                  'MARITAL STATUS': 'marital_status',
                  BIRTHDATE: 'birthdate',
                  NATIONALITY: 'nationality',
                  STREET: 'street',
                  BARANGAY: 'barangay',
                  'CITY/ MUNICIPALITY': 'city_municipality',
                  PROVINCE: 'province',
                  'SOURCE OF INCOME': 'source_of_income',
                  'NATURE OF WORK': 'nature_of_work',
                  'NATURE OF BUSINESS': 'nature_of_business',
                  'VERIFICATION STATUS': 'account_verified',
                  'ACCOUNT STATUS': 'account_status',
                  'ADMIN DEACTIVATE REASON': 'admin_deactivate_reason',
                  'PLAYER DEACTIVATE REASON': 'player_deactivate_reason',
                  'REGISTERED DATE': {
                    callback: (value) => {
                      return format_date(value.created_at);
                    },
                  },
                }"
                :name="downloadExcelName()"
                type="csv"
                class="d-inline-block"
              >
                <v-btn icon color="secondary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-file-delimited-outline</v-icon>
                </v-btn>
              </downloadexcel>
            </template>
            <span>Export to EXCEL</span>
          </v-tooltip>
        </div>
        <div class="d-flex" style="align-items: center; gap: 1rem">
          <v-text-field
            v-model="search"
            placeholder="Search"
            single-line
            hide-details="auto"
            :disabled="records_loading"
            clearable
            outlined
            dense
          >
          </v-text-field>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  color="secondary"
                  v-on="on"
                  :disabled="records_loading"
                  @click="set_filter_dialog"
                >
                  <v-icon>mdi-filter-variant</v-icon>
                </v-btn>
              </template>
              Filter
            </v-tooltip>
          </template>
        </div>
      </div>

      <v-divider></v-divider>

      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="item_per_page_value"
          :search="search"
          :loading="records_loading"
          loading-text="Loading, please wait a moment"
          hide-default-footer
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="show_details(item.id)"
                  icon
                  small
                  color="primary"
                >
                  <v-icon size="large"> mdi-text-box-search-outline </v-icon>
                </v-btn>
              </template>
              <span>Details</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.mobile_number`]="{ item }">
            +63{{ item.mobile_number }}
          </template>

          <template v-slot:[`item.account_verified`]="{ item }">
            <v-chip
              small
              :class="
                item.account_verified == 'Verified'
                  ? 'green lighten-5 green--text'
                  : item.account_verified == 'Pending'
                  ? 'orange lighten-5 orange--text'
                  : item.account_verified == 'Rejected'
                  ? 'red lighten-5 red--text'
                  : 'blue lighten-5 blue--text'
              "
            >
              {{ item.account_verified }}
            </v-chip>
          </template>

          <template v-slot:[`item.verified_at`]="{ item }">
            <span>{{ format_date(item.verified_at) }}</span>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ format_date(item.created_at) }}</span>
          </template>

          <template v-slot:[`item.others`]="{ item }">
            <PlayerOtherDetails
              :data="{
                marital_status: item.marital_status,
                birthdate: item.birthdate,
                nationality: item.nationality,
                source_of_income: item.source_of_income,
                nature_of_work: item.nature_of_work,
                nature_of_business: item.nature_of_business,
                address: item.address,
                account_status: item.account_status,
                admin_reason: item.admin_deactivate_reason,
                player_reason: item.player_deactivate_reason,
                active_status: item.account_status,
              }"
            />
          </template>
        </v-data-table>

        <div v-if="records && records.length != 0">
          <v-divider></v-divider>
          <div
            class="d-flex align-center justify-space-between gap py-4 px-4 px-md-6"
          >
            <div class="d-flex align-center gap">
              <v-select
                :value="item_per_page_value"
                :items="item_per_page"
                outlined
                dense
                label="Items"
                :hide-details="true"
                style="width: 100px"
                @change="item_per_page_value = parseInt($event, 10)"
              ></v-select>
              <div>
                Page
                {{
                  page
                    ? number_format(parseInt(page))
                    : "0" + "-" + item_per_page_value
                    ? item_per_page_value
                    : "0"
                }}
                of {{ pageCount ? number_format(parseInt(pageCount)) : "0" }} -
                {{ ` ${number_format(parseInt(records.length))}` }}
              </div>
            </div>
            <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="0"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
    <Filters
      v-if="filter_dialog"
      :data="filter_data"
      @set_close_filter_dialog="set_close_filter_dialog"
    />
  </div>
</template>

<script>
import { format, sub } from "date-fns";
import formRules from "@/helpers/formRules";
import downloadexcel from "vue-json-excel";
import formatDatev2 from "@/helpers/formatDatev2";
import Filters from "@/components/players/PlayersFilter";
import PlayerOtherDetails from "@/components/players/PlayersOthers";
import responseGet from "@/helpers/api_request_get";
import { mapActions } from "vuex";

export default {
  components: { downloadexcel, Filters, PlayerOtherDetails },
  data: () => ({
    records: [],
    records_loading: false,
    valid: true,
    filter: 0,
    status: "Files Submitted",
    active_status: "Active",
    first_name: "",
    last_name: "",
    mobile_number: "",
    search: "",

    sortBy: "registered_date",
    sortDesc: true,

    page: 1,
    pageCount: 0,
    item_per_page: [5, 10, 15, 20, 25],
    item_per_page_value: 5,

    rules: formRules,
    date_from: "2022-07-01",
    date_from_menu: false,
    date_to: format(new Date(), "yyyy-MM-dd"),
    date_to_menu: false,
    filter_dialog: false,
    filter_data: {},
    data_filter: {},
    default_date_from: format(sub(new Date(), { days: 30 }), "yyyy-MM-dd"),
    default_date_to: format(new Date(), "yyyy-MM-dd"),
    query_filters: {},

    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
      },
      { text: "ID", value: "id", class: "text-uppercase" },
      { text: "Name", value: "player", class: "text-uppercase" },
      {
        text: "Mobile Number",
        sortable: false,
        value: "mobile_number",
        class: "text-uppercase",
      },
      {
        text: "Email Address",
        sortable: false,
        value: "email",
        class: "text-uppercase",
      },
      {
        text: "Verification Status",
        value: "account_verified",
        class: "text-uppercase",
      },
      {
        text: "Verified date",
        value: "verified_at",
        class: "text-uppercase",
      },
      { text: "Registered Date", value: "created_at", class: "text-uppercase" },
      {
        text: "Others",
        sortable: false,
        value: "others",
        class: "text-uppercase",
      },
    ],
  }),

  mounted() {
    this.check_query_string();
    this.doGetPlayers();
  },

  methods: {
    ...mapActions("logout", ["sendLogoutRequest"]),

    async doGetPlayers() {
      this.records = [];
      this.records_loading = !this.records_loading;

      const response = await responseGet(
        this.set_form_data(),
        "players/report_download"
      );

      switch (response.status) {
        case 200:
          this.records = response.data.records;
          break;
        case 401:
          await this.sendLogoutRequest();
          this.$router.push({ name: "Home" });
          break;
      }

      this.records_loading = !this.records_loading;
    },

    set_form_data() {
      const form_data = new FormData();

      form_data.append("filter", this.filter);
      form_data.append("active_status", this.active_status);

      switch (this.filter) {
        case 0:
          form_data.append("date_from", this.date_from);
          form_data.append("date_to", this.date_to);
          form_data.append("account_verified", this.status);
          break;
        case 1:
          form_data.append("first_name", this.first_name);
          form_data.append("last_name", this.last_name);
          break;
        case 2:
          form_data.append("mobile_number", this.mobile_number);
          break;
        case 3:
          form_data.append("date_from", this.date_from);
          form_data.append("date_to", this.date_to);
          break;
      }

      return new URLSearchParams(form_data);
    },

    set_filter_dialog() {
      this.filter_data = {
        filter: this.filter,
        first_name: this.first_name,
        last_name: this.last_name,
        mobile_number: this.mobile_number,
        date_from: this.date_from,
        date_to: this.date_to,
        status: this.status,
        active_status: this.active_status,
      };
      this.filter_dialog = !this.filter_dialog;
    },

    set_close_filter_dialog(data) {
      if (data.type) {
        this.filter = data.filter;
        this.first_name = "";
        this.last_name = "";
        this.mobile_number = "";
        this.date_from = null;
        this.date_to = null;
        this.status = "Files Submitted";
        this.active_status = data.active_status;
        this.page = 1;

        switch (data.filter) {
          case 0:
            this.date_from = data.date_from;
            this.date_to = data.date_to;
            this.status = data.status;
            break;
          case 1:
            this.first_name = data.first_name;
            this.last_name = data.last_name;
            break;
          case 2:
            this.mobile_number = data.mobile_number;
            break;
          case 3:
            this.date_from = data.date_from;
            this.date_to = data.date_to;
            break;
        }

        this.doGetPlayers();
        this.set_query_string(0);
      }

      this.filter_dialog = !this.filter_dialog;
    },

    show_details(id) {
      const url = this.$router.resolve({
        name: "PlayerDetails",
        params: { id },
      }).href;

      window.open(url, "_blank");
    },

    check_query_string() {
      const urlQuery = this.$route.query;

      if (Object.keys(urlQuery).length) {
        if (Object.prototype.hasOwnProperty.call(urlQuery, "filter"))
          this.filter = parseInt(urlQuery.filter);
        if (Object.prototype.hasOwnProperty.call(urlQuery, "first_name"))
          this.first_name = urlQuery.first_name;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "last_name"))
          this.last_name = urlQuery.last_name;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "mobile_number"))
          this.mobile_number = urlQuery.mobile_number;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "date_from"))
          this.date_from = urlQuery.date_from;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "date_to"))
          this.date_to = urlQuery.date_to;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "status"))
          this.status = urlQuery.status;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "page"))
          this.page = parseInt(urlQuery.page);
        if (Object.prototype.hasOwnProperty.call(urlQuery, "active_status"))
          this.active_status = urlQuery.active_status;
      }
    },

    set_query_string(current_page) {
      const urlQuery = this.$route.query;
      this.query_filters = {};

      switch (parseInt(this.filter)) {
        case 0:
          if (
            urlQuery.filter != this.filter ||
            urlQuery.active_status != this.active_status ||
            urlQuery.date_from != this.date_from ||
            urlQuery.date_to != this.date_to ||
            urlQuery.status != this.status ||
            urlQuery.page != this.page
          ) {
            this.query_filters["filter"] = this.filter;
            if (this.active_status != null)
              this.query_filters["active_status"] = this.active_status;
            if (this.date_from != null)
              this.query_filters["date_from"] = this.date_from;
            if (this.date_to != null)
              this.query_filters["date_to"] = this.date_to;
            if (this.status != null) this.query_filters["status"] = this.status;
            if (this.page != null)
              this.query_filters["page"] = current_page
                ? current_page
                : this.page;

            this.$router.replace({
              name: this.$route.name,
              query: this.query_filters,
            });
          }
          break;
        case 1:
          if (
            urlQuery.filter != this.filter ||
            urlQuery.active_status != this.active_status ||
            urlQuery.first_name != this.first_name ||
            urlQuery.last_name != this.last_name ||
            urlQuery.page != this.page
          ) {
            this.query_filters["filter"] = this.filter;
            if (this.active_status != null)
              this.query_filters["active_status"] = this.active_status;
            if (this.first_name != null)
              this.query_filters["first_name"] = this.first_name;
            if (this.last_name != null)
              this.query_filters["last_name"] = this.last_name;
            if (this.page != null)
              this.query_filters["page"] = current_page
                ? current_page
                : this.page;

            this.$router.replace({
              name: this.$route.name,
              query: this.query_filters,
            });
          }
          break;
        case 2:
          if (
            urlQuery.filter != this.filter ||
            urlQuery.active_status != this.active_status ||
            urlQuery.mobile_number != this.mobile_number ||
            urlQuery.page != this.page
          ) {
            this.query_filters["filter"] = this.filter;
            if (this.active_status != null)
              this.query_filters["active_status"] = this.active_status;
            if (this.mobile_number != null)
              this.query_filters["mobile_number"] = this.mobile_number;
            if (this.page != null)
              this.query_filters["page"] = current_page
                ? current_page
                : this.page;

            this.$router.replace({
              name: this.$route.name,
              query: this.query_filters,
            });
          }
          break;
        case 3:
          if (
            urlQuery.filter != this.filter ||
            urlQuery.active_status != this.active_status ||
            urlQuery.date_from != this.date_from ||
            urlQuery.date_to != this.date_to ||
            urlQuery.page != this.page
          ) {
            this.query_filters["filter"] = this.filter;
            if (this.active_status != null)
              this.query_filters["active_status"] = this.active_status;
            if (this.date_from != null)
              this.query_filters["date_from"] = this.date_from;
            if (this.date_to != null)
              this.query_filters["date_to"] = this.date_to;
            if (this.status != null) this.query_filters["status"] = this.status;
            if (this.page != null)
              this.query_filters["page"] = current_page
                ? current_page
                : this.page;

            this.$router.replace({
              name: this.$route.name,
              query: this.query_filters,
            });
          }
      }
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    format_date(date) {
      return formatDatev2(date);
    },

    downloadExcelName() {
      return "Registered Players Report " + formatDatev2(new Date()) + ".xls";
    },
  },

  watch: {
    page(val) {
      this.set_query_string(val);
    },
  },
};
</script>
