var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Settings")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Users")]),_c('v-card',{staticClass:"shadow"},[_c('v-card-title',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"2","align-self":"end"}},[(_vm.userData.uid == 76)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","to":{ name: 'UsersCreate' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-box-outline")])],1)]}}],null,false,3383731965)},[_c('span',[_vm._v("Create User")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","color":"secondary"},on:{"click":_vm.getUsers}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cached")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search","single-line":"","hide-details":"auto","disabled":_vm.loading,"clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"items-per-page":5,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading, please wait a moment","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([(_vm.userData.uid == 76)?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","icon":"","small":"","color":"primary","to":{ name: 'UsersUpdate', params: { user_id: item.user_id } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Update")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.setUserId(item.user_id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-key-change ")])],1)]}}],null,true)},[_c('span',[_vm._v("Reset Password")])])]}}:null,{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-avatar',{attrs:{"size":"36px","tile":""}},[_c('v-img',{attrs:{"src":item.logo},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black"}})],1)]},proxy:true}],null,true)})],1)],1)]}},{key:"item.mobile_number",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("+63"+_vm._s(item.mobile_number))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.setCreatedAtFormat(item.created_at)))])]}}],null,true)})],1)],1),(_vm.dialogStatus)?_c('FormDialog',{attrs:{"usersData":_vm.usersData},on:{"setData":_vm.setData}}):_vm._e(),(_vm.changePasswordDialogStatus)?_c('ResetPassword',{attrs:{"userId":_vm.id},on:{"successChange":function($event){_vm.changePasswordDialogStatus = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }