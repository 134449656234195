var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Manage Agent Player ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"icon":"","absolute":"","right":""},on:{"click":_vm.setCloseModal}},[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-close ")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"py-4"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('span',{staticClass:"mb-1 mt-2 overline",attrs:{"inline":""}},[_vm._v("Agent:")]),_c('span',{staticClass:"mb-1 mt-2 overline primary--text",attrs:{"inline":""}},[_vm._v(" "+_vm._s(_vm.agentName))])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"disabled":_vm.userLoading,"color":"primary"},on:{"click":_vm.setAddPlayerModal}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-plus ")]),_vm._v(" Add Player ")],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{ref:"search",attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search","single-line":"","disabled":_vm.userLoading,"hide-details":"auto","clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-divider',{staticClass:"mt-2"}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{staticClass:"mt-2",attrs:{"headers":_vm.headers,"items":_vm.agentPlayers,"items-per-page":5,"search":_vm.search,"loading":_vm.userLoading,"loading-text":"Loading, please wait a moment","sort-by.sync":"name"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.account_verified === 'Verified')?_c('v-badge',{attrs:{"content":"verified","color":"blue","left":""}},[_c('span',[_vm._v(" "+_vm._s(item.name))])]):_c('span',[_vm._v(" "+_vm._s(item.name))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","icon":"","small":"","disabled":_vm.userLoading,"color":"red"},on:{"click":function($event){return _vm.removePlayer(item.id, item.name)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-minus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Remove")])])],1)]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.is_active == 'Active' ? 'green lighten-5 green--text' : 'red lighten-5 red--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.is_active)+" ")])]}}],null,true)})],1)],1)],1)],1),(_vm.confirmationDialogStatus)?_c('ManageConfirmation',{attrs:{"agent_and_player_id":_vm.agent_and_player_id},on:{"setConfirmationModal":_vm.confirmationModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }