var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Ending Reports")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Top Winning Combination")]),_c('v-card',{staticClass:"shadow mb-4"},[_c('v-card-text',{staticClass:"py-4"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSearch.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.rules.required, _vm.lessThanDate],"label":"Scheduled Date From","hide-details":"auto","readonly":"","outlined":"","dense":"","clearable":""},on:{"keyup":function($event){_vm.date_from_menu = true},"keydown":function($event){_vm.date_from_menu = false}},model:{value:(_vm.date_from),callback:function ($$v) {_vm.date_from=$$v},expression:"date_from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_from_menu),callback:function ($$v) {_vm.date_from_menu=$$v},expression:"date_from_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.date_from_menu = false}},model:{value:(_vm.date_from),callback:function ($$v) {_vm.date_from=$$v},expression:"date_from"}})],1)],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.date_from == ''},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Scheduled Date To Optional","hide-details":"auto","readonly":"","outlined":"","dense":"","disabled":_vm.dateToAvailability,"clearable":""},on:{"keyup":function($event){_vm.date_to_menu = true},"keydown":function($event){_vm.date_to_menu = false}},model:{value:(_vm.date_to),callback:function ($$v) {_vm.date_to=$$v},expression:"date_to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_to_menu),callback:function ($$v) {_vm.date_to_menu=$$v},expression:"date_to_menu"}},[_c('v-date-picker',{attrs:{"min":_vm.date_from ? _vm.date_from : ''},on:{"input":function($event){_vm.date_to_menu = false}},model:{value:(_vm.date_to),callback:function ($$v) {_vm.date_to=$$v},expression:"date_to"}})],1)],1)],1),_c('v-row',{staticClass:"mt-2",attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{attrs:{"depressed":"","type":"submit","color":"primary"}},[_vm._v(" Search ")])],1)],1)],1)],1)],1),_c('v-card',{staticClass:"shadow"},[_c('v-card-title',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"2","align-self":"end"}},[_c('downloadexcel',{staticClass:"d-inline-block",attrs:{"data":_vm.records,"fields":{
              Slot: 'slot',
              'Number of Winners': 'bets',
              'Total Bet Amount': 'total_bet_amount',
              'Total Payout': 'total_payout',
            },"name":"Top Winning Combination Report.xls","type":"csv"}},[_c('v-btn',{attrs:{"outlined":"","color":"primary"}},[_vm._v(" Export CSV ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search","single-line":"","hide-details":"auto","clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider'),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-4",attrs:{"align":"center","cols":"12","md":"4"}},[_c('v-card',{staticClass:"pa-1"},[_c('p',{staticClass:"font-weight-regular ma-0 overline"},[_vm._v("TOTAL NUMBER OF WINNERS")]),_c('v-divider',{staticClass:"mb-4"}),_c('p',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(parseInt(_vm.totalNumberOfWinners).toLocaleString())+" ")])],1)],1),_c('v-col',{staticClass:"py-4",attrs:{"align":"center","cols":"12","md":"4"}},[_c('v-card',{staticClass:"pa-1"},[_c('p',{staticClass:"font-weight-regular ma-0 overline"},[_vm._v("TOTAL BET AMOUNT")]),_c('v-divider',{staticClass:"mb-4"}),_c('p',{staticClass:"font-weight-medium"},[_vm._v(" PHP "+_vm._s(_vm.moneyFormat(parseFloat(_vm.totalBetAmount)))+" ")])],1)],1),_c('v-col',{staticClass:"py-4",attrs:{"align":"center","cols":"12","md":"4"}},[_c('v-card',{staticClass:"pa-1"},[_c('p',{staticClass:"font-weight-regular ma-0 overline"},[_vm._v("TOTAL PAYOUTS")]),_c('v-divider',{staticClass:"mb-4"}),_c('p',{staticClass:"font-weight-medium"},[_vm._v(" PHP "+_vm._s(_vm.moneyFormat(parseFloat(_vm.totalPayouts)))+" ")])],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":5,"search":_vm.search,"loading":_vm.recordsLoading,"loading-text":"Loading, please wait a moment"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"dense":"","icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.setWinnersModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-seal-variant ")])],1)]}}],null,true)},[_c('span',[_vm._v("Show Winners")])])]}},{key:"item.total_winning",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseInt(item.total_winning).toLocaleString())+" ")])]}},{key:"item.bets",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseInt(item.bets).toLocaleString())+" ")])]}},{key:"item.bet_amount",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v("PHP "+_vm._s(_vm.moneyFormat(parseFloat(item.bet_amount)))+" ")])]}},{key:"item.total_bet_amount",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v("PHP "+_vm._s(_vm.moneyFormat(parseFloat(item.total_bet_amount)))+" ")])]}},{key:"item.total_payout",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v("PHP "+_vm._s(_vm.moneyFormat(parseFloat(item.total_payout)))+" ")])]}}],null,true)})],1)],1)],1)],1)],1)],1),(_vm.top_winning_combination_dialog)?_c('TopWinningCombination',{attrs:{"filters":_vm.filters},on:{"setCloseModal":_vm.setCloseModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }