const url = process.env.VUE_APP_API_URL

export default {
  namespaced: true,

  state: {
    error: null,
    errors: [],
    forgotLoading: false,
    typeResponse: '',
    errorMessage: ''
  },
  mutations: {
    setError(state, val) {
      state.error = val
    },
    setErrors(state, val) {
      state.errors = val
    },
    setForgotLoading(state, val) {
      state.forgotLoading = val
    },
    setTypeResponse(state, val) {
      state.typeResponse = val
    },
    setErrorMessage(state, val) {
      state.errorMessage = val
    }
  },

  actions: {
    async doForgotPassword({ commit, rootState }, data) {
      commit("setForgotLoading", true)

      try {
        const response = await fetch(`${url}forgot_password`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        })

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } 
        else {
          commit("setError", null)
          commit("setErrors", [])
        }
      } catch (error) {
        const errorStatus = error.response.status

        if (errorStatus == 403) {
          commit("setError", error.res.messages)
          commit("setErrorMessage", error.res.messages.error)
        } else if (errorStatus == 422) {
          commit("setErrors", error.res.messages)
        } else {
          commit("setError", "Something went wrong, please try again!")
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setForgotLoading", false)
      }
    },

    async doResetPassword({ commit, rootState }, data) {
      commit("setForgotLoading", true)

      try {
        const response = await fetch(`${url}reset_password`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        })

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } 
        else {
          commit("setError", null)
          commit("setErrors", [])
          commit("setErrorMessage", '')
        }
      } catch (error) {
        const errorStatus = error.response.status

        if (errorStatus == 403) {
          commit("setError", error.res.messages)
          commit("setErrorMessage", error.res.messages.error)
        } else if (errorStatus == 422) {
          commit("setError", error.res.messages)
          commit("setErrorMessage", error.res.messages.error)
        } else {
          commit("setError", "Something went wrong, please try again!")
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setForgotLoading", false)
      }
    }
  }
}