const url = process.env.VUE_APP_API_URL

export default {
    namespaced: true,

    state: {
        details: [],
        cards: [],
        gameCards: [],
        loading: false,
        error: null,
        errors: []
    },

    mutations: {
        setDetails(state, val) {
            state.details = val
        },
        setCards(state, val) {
            state.cards = val
        },
        setLoading(state, val) {
            state.loading = val
        },
        setError(state, val) {
            state.error = val
        },
        setErrors(state, val) {
            state.errors = val
        }
    },

    actions: {
        async getCards({ commit, rootState }, data) {
            commit("setLoading", true)

            try {
                const response = await fetch(`${url}ending/games/game_card?${data}` , {
                    method: 'GET',
                    headers: {
                      'Authorization': `Bearer ${rootState.userToken}`,
                    }
                  });
          
                  const res = await response.json()
                  
                  if (!response.ok) {
                    throw { response, res }
                  } else {
                    commit("setDetails", res.details)
                    commit("setCards", res.records)
                  }
            } catch (error) {
                const errorStatus = error.response.status

                if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
                } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setLoading", false)
            }
        },

        async getGameCards({ commit, rootState }, data) {
            commit("setLoading", true)

            try {
                const response = await fetch(`${url}ending/games/game_cards?${data}` , {
                    method: 'GET',
                    headers: {
                      'Authorization': `Bearer ${rootState.userToken}`,
                    }
                  });
          
                  const res = await response.json()
                  
                  if (!response.ok) {
                    throw { response, res }
                  } else {
                    commit("setDetails", res.details)
                    commit("setCards", res.records)
                  }
            } catch (error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
                } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setLoading", false)
            }
        }
    }
}