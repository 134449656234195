var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Ending Game")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Scheduled Games")]),_c('v-card',{staticClass:"shadow"},[_c('v-card-title',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"2","align-self":"end"}},[(_vm.accountInfo.access.includes(32))?_c('CreateGame',{staticClass:"mr-1"}):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","color":"secondary"},on:{"click":_vm.loadRecords}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cached")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c('downloadexcel',{staticClass:"d-inline-block",attrs:{"data":_vm.records,"fields":{
              ID: 'game_id',
              League: 'league',
              Game: {
                callback: function (value) {
                  return ((value.team_a_name) + " " + (value.team_a_alias) + " vs " + (value.team_b_name) + " " + (value.team_b_alias));
                },
              },
              Schedule: 'schedule',
              Betting: 'betting',
              Status: 'status',
              'Enabled At': 'enabled_at',
              'Created At': 'created_at',
            },"name":_vm.exportExcelName(),"type":"csv"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-delimited-outline")])],1)]}}])},[_c('span',[_vm._v("Export to EXCEL")])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search","single-line":"","hide-details":"auto","disabled":_vm.recordsLoading,"clearable":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"secondary","small":""},on:{"click":_vm.filter}},on),[_c('v-icon',[_vm._v("mdi-filter-variant")])],1)]}}])},[_vm._v(" Filter ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":-1,"search":_vm.search,"loading":_vm.recordsLoading,"loading-text":"Loading, please wait a moment","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.showDetails(item.game_id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-text-box-search-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Details")])])]}},{key:"item.game",fn:function(ref){
            var item = ref.item;
return [_c('div',[_c('v-avatar',{attrs:{"size":"36px","tile":""}},[_c('v-img',{attrs:{"src":item.team_a_logo},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black"}})],1)]},proxy:true}],null,true)})],1),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.team_a_name)+" "+_vm._s(item.team_a_alias))]),_c('span',{staticClass:"text-overline my-2 mx-2 font-italic"},[_vm._v("vs")]),_c('v-avatar',{attrs:{"size":"36px","tile":""}},[_c('v-img',{attrs:{"src":item.team_b_logo},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black"}})],1)]},proxy:true}],null,true)})],1),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.team_b_name)+" "+_vm._s(item.team_b_alias))])],1)]}},{key:"item.betting",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{class:item.betting == 'Enabled'
                ? 'green lighten-5 green--text'
                : 'orange lighten-5 orange--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.betting)+" ")])]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{class:item.status
                ? 'green lighten-5 green--text'
                : 'orange lighten-5 orange--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status ? item.status : "Not yet started")+" ")])]}},{key:"item.schedule",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatTheDate(item.schedule)))])]}},{key:"item.enabled_at",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatTheDate(item.enabled_at)))])]}},{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatTheDate(item.created_at)))])]}}],null,true)})],1)],1),(_vm.filterDialog)?_c('FilterGame',{attrs:{"filter_data":_vm.filter_data},on:{"setCloseModal":_vm.setCloseModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }