<template>
    <v-dialog scrollable v-model="dialog" max-width="600">
        <v-card>
            <v-card-title class="text-h5"> Manual Cash In </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
                <v-form v-if="!searchStatus" 
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="handleSearch">

                    <v-row dense class="mt-2">
                        <v-col>
                            <span inline class="mb-1 mt-1 overline primary--text">Mobile Number</span>
                            <v-text-field ref="mobile_number"
                                outlined
                                dense
                                :rules="[
                                rules.required,
                                rules.validMobileNumber,
                                mobileNumberExist,
                                ]"
                                maxlength="10"
                                v-model="mobile_number"
                                hide-details="auto"
                                prefix="+63"
                                @keyup="removeMobileNumberLeadingZero"/>
                        </v-col>
                    </v-row>

                    <v-row dense class="mt-4">
                        <v-col cols="12" md="6">
                            <v-btn block depressed color="primary" :loading="loading" type="submit">
                                Search
                            </v-btn>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-btn block depressed @click="setCloseModal">
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>

                <v-form  ref="form2"
                    v-if="searchStatus"
                    v-model="valid2"
                    lazy-validation
                    @submit.prevent="handleSubmit">

                    <v-simple-table dense class="mt-2">
                        <template v-slot:default>
                            <tbody class="table-borderless">
                            <tr>
                                <td style="width: 30%" class="grey--text">Mobile Number </td>
                                <td>
                                <span>+63{{ agentInfo.mobile_number }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="grey--text">Agent's Name</td>
                                <td>
                                <span>{{ agentInfo.agent_name }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td class="grey--text">Email</td>
                                <td>
                                <span>{{ agentInfo.email }}</span>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <v-divider class="mt-4"/>

                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                ref="amount"
                                label="Amount"
                                outlined
                                clearable
                                dense
                                :rules="[rules.required, rules.numeric]"
                                v-model="amount"
                                hide-details="auto"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col cols="12" md="6">
                            <v-row dense>
                                <v-col cols="12" md="6">
                                    <v-btn block depressed outlined color="indigo" @click="resetData" class="d-none d-md-block">
                                        Search Again
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-row dense>
                                <v-col cols="12" md="6">
                                    <v-btn block depressed color="primary" :loading="loading" type="submit">
                                        Submit
                                    </v-btn>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-btn block depressed @click="setCloseModal">
                                        Cancel
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import formRules from "@/helpers/formRules"
import { mapState, mapActions } from "vuex"

export default {
    data: () => ({
        valid: true,
        valid2: true,
        rules: formRules,
        mobile_number: '',
        mobileNumberExistText: '',
        amount: '',
        progressStatus: false,
        searchStatus: false,
        insertedData: [],
        submitStatus: false
    }),

    computed: {
        ...mapState("agents_cashin", ["loading", "error", "errors", "agentInfo"]),
        dialog: {
            get: function () {
                return true;
            },
            set: function () {
                this.setCloseModal();
            },
        },
        passwordConfirmationRule() {
            return () => this.password === this.confirm_password || "Password must match"
        }
    },

    mounted() {
        this.$refs.mobile_number.focus()
    },

    methods: {
        ...mapActions("agents_cashin", ["getAgentInfo", "cashInManual"]),

        async handleSearch() {
            if (this.$refs.form.validate()) {
                if (!this.loading) {
                    await this.getAgentInfo(this.setMobileNumberFormData())

                    if (!this.error && this.isObjectEmpty(this.errors)) {
                        this.searchStatus = true
                        
                        setTimeout(() => {
                            this.$refs.amount.focus();
                        }, 200);
                    } else {
                        this.setErrors()
                    }
                }
            }
        },

        resetData() {
            this.mobile_number = ''
            this.searchStatus = false

            setTimeout(() => {
                this.$refs.mobile_number.focus();
            }, 200);
        },

        setErrors() {
            let errorFocus = "";

            Object.entries(this.errors).forEach(([key, value]) => {
                if (value) {
                    if (!errorFocus) {
                        errorFocus = key;
                    }

                    if (key == "mobile_number") {
                        this.mobileNumberExistText = this.mobile_number
                    }

                    this.$refs.form.validate();
                    this.$refs[errorFocus].focus();
                }
            });
        },

        mobileNumberExist(val) {
            if (val == this.mobileNumberExistText) {
                return "This mobile number was not registered."
            } else return true
        },

        setMobileNumberFormData() {
            const formData = new FormData()
            formData.append("mobile_number", this.mobile_number)
            const getData = new URLSearchParams(formData)

            return getData
        },

        isObjectEmpty(obj) {
            return Object.keys(obj).length === 0;
        },

        async handleSubmit() {
            if (this.$refs.form2.validate()) {
                if (!this.loading) {
                    await this.cashInManual(this.setAmountFormData())

                    if (!this.error && this.isObjectEmpty(this.errors)) {
                        this.searchStatus = true
                        this.submitStatus = true
                        this.setCloseModal()
                    } else {
                        this.setErrors()
                    }
                }
            }
        },

        setAmountFormData() {
            const formData = new FormData
            formData.append("agent_id", this.agentInfo.agent_id)
            formData.append("amount", this.amount)

            return formData
        },

        setCloseModal() {
            this.$emit("setManualCloseModal", this.submitStatus)
        },

        removeMobileNumberLeadingZero() {
            this.mobile_number = this.mobile_number.replace(/^0+/, "");
        },
    }
}
</script>