<template>
  <v-dialog :fullscreen="!$vuetify.breakpoint.mdAndUp" persistent scrollable v-model="dialog" max-width="700">
    <v-card class="bs-shadow">
      <v-card-title class="d-flex align-center justify-space-between">
        <span>Commission Details</span>

        <v-btn icon @click="close_dialog" :disabled="records_loading">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-data-table v-model="selected" :headers="headers" :items="records" :search="search" :items-per-page="-1"
          :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :hide-default-footer="true" :single-select="false"
          item-key="reference" show-select :loading="records_loading" loading-text="Loading, please wait a moment">
          <template v-slot:top>
            <v-text-field ref="search" v-model="search" label="Search" single-line outlined dense
              :disabled="records_loading" autofocus placeholder="Search" hide-details="auto" />
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <span>{{ `Php ${money_format(parseFloat(item.amount))}` }}</span>
          </template>

          <template v-slot:[`item.commission_percent`]="{ item }">
            <span>{{ `${number_format(parseInt(item.commission_percent))}%` }}</span>
          </template>

          <template v-slot:[`item.commission_amount`]="{ item }">
            <span>{{ `Php ${money_format(parseFloat(item.commission_amount))}` }}</span>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ format_date(item.created_at) }}</span>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <div class="d-flex align-center justify-space-between gap" style="width: 100%;">
          <span>
            {{ number_format(parseInt(selected.length)) }}
            Selected
            {{
              ` out of ${records.length} amounting of Php ${total_commission}`
            }}
          </span>

          <div class="d-flex gap-sm">
            <v-btn text depressed color="grey" @click="close_dialog" :disabled="records_loading">
              Cancel
            </v-btn>
            <v-btn depressed color="primary" :disabled="records_loading || !selected.length"
              @click="set_commission_details_dialog">
              Submit
            </v-btn>
          </div>
        </div>
      </v-card-actions>
    </v-card>

    <CommissionDetailsConfirmation :commission_details_confirmation_data="commission_details_confirmation_data"
      v-if="commission_details_confirmation_dialog"
      @set_close_commission_details_dialog="set_close_commission_details_dialog" />
  </v-dialog>
</template>

<script>
import formatDatev2 from "@/helpers/formatDatev2";
import responseGet from "@/helpers/api_request_get";
import CommissionDetailsConfirmation from "@/components/investors/CommissionDetailsConfirmation";

export default {
  components: {
    CommissionDetailsConfirmation,
  },

  props: {
    commission_details_data: {
      type: Object,
    },
  },

  data: () => ({
    selected: [],
    search: "",
    records: [],
    records_loading: false,
    commission_details_confirmation_dialog: false,
    commission_details_confirmation_data: {},
    approval_status: false,
    sortBy: "type",
    sortDesc: false,
    headers: [
      {
        text: "Type",
        value: "type",
        class: "text-uppercase",
      },
      {
        text: "Agent",
        value: "agent",
        class: "text-uppercase",
      },
      {
        text: "Commission",
        value: "commission_amount",
        align: "right",
        class: "text-uppercase",
      },
    ],
  }),

  mounted() {
    this.doGetCommissionDetails();
  },

  computed: {
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.close_dialog();
      },
    },

    total_commission() {
      if (this.selected) {
        return this.money_format(
          this.selected.reduce(
            (total, obj) => parseFloat(obj.commission_amount) + total,
            0
          )
        );
      } else {
        return 0;
      }
    },
  },

  methods: {
    async doGetCommissionDetails() {
      this.records_loading = !this.records_loading;

      const response = await responseGet(this.set_form_data(), "investor/commission/details");

      if (response.status == 200) {
        this.records = response.data.records;
      }

      this.records_loading = !this.records_loading;
    },

    set_form_data() {
      const form_data = new FormData();

      form_data.append("investor_id", this.commission_details_data.investor_id);
      form_data.append("date_from", this.commission_details_data.date_from);
      form_data.append("date_to", this.commission_details_data.date_to);

      return new URLSearchParams(form_data);
    },

    set_commission_details_dialog() {
      this.commission_details_confirmation_data = {
        'investor_id': this.commission_details_data.investor_id,
        'selected': this.selected,
        'total_commission': this.total_commission,
        'date_from': this.commission_details_data.date_from,
        'date_to': this.commission_details_data.date_to
      };
      this.commission_details_confirmation_dialog = !this.commission_details_confirmation_dialog;
    },

    set_close_commission_details_dialog(status) {
      if (status) {
        this.selected = [];
        this.approval_status = !this.approval_status;
        this.doGetCommissionDetails();
      }

      this.commission_details_confirmation_dialog = !this
        .commission_details_confirmation_dialog;
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    format_date(date) {
      return formatDatev2(date);
    },

    close_dialog() {
      this.$emit("set_close_commission_dialog", this.approval_status);
    },
  },
};
</script>
