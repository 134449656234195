var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Agents")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Cash In")]),_c('v-card',{staticClass:"shadow"},[_c('v-card-title',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"2","align-self":"end"}},[(_vm.accountInfo.access.includes(28))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":_vm.setManualModal}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cash-plus")])],1)]}}],null,false,4124370298)},[_c('span',[_vm._v("Manual Cash In")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","color":"secondary"},on:{"click":_vm.loadRecords}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cached")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search","single-line":"","hide-details":"auto","disabled":_vm.loading,"clearable":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"secondary","small":"","disabled":_vm.loading},on:{"click":_vm.showFilter}},on),[_c('v-icon',[_vm._v("mdi-filter-variant")])],1)]}}])},[_vm._v(" Filter ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-4"},[_c('v-row',{staticClass:"mt-2",attrs:{"dense":""}},[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","md":"3","align":"center"}},[_c('v-card',{staticClass:"pa-1"},[_c('p',{staticClass:"font-weight-regular ma-0 overline"},[_vm._v("TOTAL CASHIN")]),_c('v-divider',{staticClass:"mb-4"}),_c('p',{staticClass:"font-weight-medium"},[_vm._v(" PHP "+_vm._s(parseInt(_vm.totalCashIn).toLocaleString())+" ")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":5,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading, please wait a moment","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center d-flex align-center justify-space-around"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.setViewDetails(item, true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-account-details ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Details")])]),(item.status == 'Pending' && _vm.accountInfo.access.includes(42))?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-sm-inline",attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.setViewDetails(item, false)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-folder-check-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Verify")])]):(_vm.accountInfo.access.includes(42))?_c('v-btn',{staticClass:"d-none d-sm-inline",staticStyle:{"pointer-events":"none"},attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-folder-check-outline ")])],1):_vm._e(),(item.status == 'Pending' && _vm.accountInfo.access.includes(42))?_c('v-btn',{staticClass:"d-inline d-sm-none",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.setViewDetails(item, false)}}},[_vm._v(" Verify ")]):(_vm.accountInfo.access.includes(42))?_c('v-btn',{staticClass:"d-inline d-sm-none",attrs:{"depressed":"","outlined":"","small":"","color":"primary","disabled":""}},[_vm._v(" Verify ")]):_vm._e()],1)]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" PHP "+_vm._s(_vm.moneyFormat(parseFloat(item.amount)))+" ")]}},{key:"item.evaluated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCreatedAtFormat(item.evaluated_at))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCreatedAtFormat(item.created_at))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.status == 'Approved'
                ? 'green lighten-5 green--text'
                : item.status == 'Pending'
                ? 'orange lighten-5 orange--text'
                : 'red lighten-5 red--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)})],1)],1),(_vm.filterDialog)?_c('FilterDate',{attrs:{"data_filters":_vm.data_filters},on:{"setFilterCloseModal":_vm.setFilterCloseModal}}):_vm._e(),(_vm.validationDialog)?_c('Validation',{attrs:{"agent_data":_vm.agent_data,"viewStatus":_vm.viewStatus},on:{"setValidationCloseModal":_vm.setValidationCloseModal}}):_vm._e(),(_vm.manualDialog)?_c('Manual',{on:{"setManualCloseModal":_vm.setManualCloseModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }