const url = process.env.VUE_APP_API_URL + "ending/games"

export default {
  namespaced: true,

  state: {
    gameDetails: [],
    loading: false,
    records: [],
    recordsLoading: false,
    leagues: [],
    teams: [],
    createGameError: null,
    createGameLoading: false,
    updateGameError: null
  },
  mutations: {
    setGameDetails (state, val) {
      state.gameDetails = val
    },
    setLoading (state, val) {
      state.loading = val
    },
    setRecords (state, val) {
      state.records = val
    },
    setRecordsLoading (state, val) {
      state.recordsLoading = val
    },
    setLeagues (state, val) {
      state.leagues = val
    },
    setTeams (state, val) {
      state.teams = val
    },
    setCreateGameError(state, val) {
      state.createGameError = val
    },
    setUpdateGameError(state, val) {
      state.updateGameError = val
    },
    setCreateGameLoading(state, val) {
      state.createGameLoading = val
    }
  },

  actions: {
    async getRecords({ commit, state, rootState }, data) {
      commit("setRecordsLoading", true)

      try {
        const has_leagues = (!state.leagues.length) ? 1 : 0

        const response = await fetch(`${url}?${data}&get_leagues=${has_leagues}` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setRecords", res.records)

          if (res.leagues) {
            commit("setLeagues", res.leagues)
          }

          if (res.teams) {
            commit("setTeams", res.teams)
          }
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setRecordsLoading", false)
      }
    },

    async getFilteredData({ commit, state, rootState }, data) {
      commit("setRecordsLoading", true)

      try {
        const has_leagues = (!state.leagues.length) ? 1 : 0

        const response = await fetch(`${url}/filtered_games?${data}&get_leagues=${has_leagues}` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setRecords", res.records)

          if (res.leagues) {
            commit("setLeagues", res.leagues)
          }

          if (res.teams) {
            commit("setTeams", res.teams)
          }
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setRecordsLoading", false)
      }
    },

    async createGame({ commit, rootState }, data) {
      commit("setCreateGameLoading", true)

      try {
        const response = await fetch(`${url}/create`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setCreateGameError", null)

          return res
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setCreateGameError", "Error") // Decoy error to detect success
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setCreateGameLoading", false)
      }
    },

    async updateGame({ commit, rootState }, data) {
      commit("setLoading", true)

      try {
        const response = await fetch(`${url}/update`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setUpdateGameError", null)
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setUpdateGameError", "Error") // Decoy error to detect success
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setLoading", false)
      }      
    },

    async getGameDetails({ commit, rootState }, data) {
      commit("setLoading", true)

      try {

        const response = await fetch(`${url}/game_details/${data}` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setGameDetails", res.records)
        }
      } catch (error) {
        const errorStatus = error.response.status

        commit("setCreateGameError", "Error") // Decoy error to detect success
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setLoading", false)
      }
    },
  }
}