<template>
  <div class="maintenance pa-4">
    <div class="text-center">
      <h1 class="mb-3">Our site is getting a little tune up.</h1>
      We apologize for the inconvenience, but we're performing some maintenance.
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
//   name: "Maintenance",
};
</script>

<style scope>
.maintenance {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
