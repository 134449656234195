<template>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler
);

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: "area",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: undefined,
    },
    height: {
      type: Number,
      default: 300,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [
        {
          beforeInit(chart) {
            // Get a reference to the original fit function
            const originalFit = chart.legend.fit;

            // Override the fit function
            chart.legend.fit = function fit() {
              // Call the original function and bind scope in order to use `this` correctly inside it
              originalFit.bind(chart.legend)();
              // Change the height as suggested in other answers
              this.height += 24;
            };
          },
        },
      ],
    },
    theChartData: {
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        labels: this.theChartData.labels,
        datasets: [
          {
            label: "Bets",
            data: this.theChartData.bet_statistics,
            hidden: false,
            backgroundColor: "RGBA(0, 182, 254, 0.1)",
            pointBackgroundColor: "#FFFFFF",
            pointRadius: 2,
            pointHoverRadius: 3,
            pointHoverBackgroundColor: "#FFFFFF",
            borderWidth: 1,
            borderColor: "#00B6FE",
            fill: true,
          },
          {
            label: "Target",
            data: this.theChartData.target,
            hidden: false,
            backgroundColor: "RGBA(207, 216, 220, 0.1)",
            pointBackgroundColor: "#FFFFFF",
            pointRadius: 2,
            pointHoverRadius: 3,
            pointHoverBackgroundColor: "#FFFFFF",
            borderWidth: 1,
            borderColor: "#CFD8DC",
            fill: true,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        lineTension: 0.4,
        plugins: {
          legend: {
            display: true,
            position: "top",
            labels: {
              font: {
                size: 13,
                family: "Poppins",
              },
              padding: 16,
              boxWidth: 12,
              boxHeight: 12,
            },
          },
        },
        scales: {
          xAxes: {
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: true,
              tickColor: "#FFFFFF",
            },
            ticks: {
              display: true,
              font: {
                family: "Poppins"
              },
            },
          },
          yAxes: {
            grid: {
              drawBorder: false,
              drawTicks: true,
              tickColor: "#FFFFFF",
              color: "#DDDDDD",
              borderDash: [5, 5],
              borderDashOffset: 2,
            },
            ticks: {
              display: true,
              font: {
                family: "Poppins"
              },
            },
          },
        },
      },
    };
  },
};
</script>
