<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Agents</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Commission Payments</h2>
    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                  @click="loadHandler()"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <downloadexcel
                  :data="records"
                  :fields="{
                    'Agent Name': 'agent',
                    'Mobile Number': 'mobile_number',
                    Email: 'email_address',
                    Commission: 'commission',
                    Wallet: 'wallet',
                    'Unpaid Commission': 'unpaid_commission',
                    'Created At': {
                      callback: (val) => {
                        return setCreatedAtFormat(val.created_at);
                      },
                    },
                  }"
                  name="Unpaid Commission Report.xls"
                  type="csv"
                  class="d-inline-block"
                >
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-file-delimited-outline</v-icon>
                  </v-btn>
                </downloadexcel>
              </template>

              <span>Download</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="recordsLoading"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="5"
          :search="search"
          :loading="recordsLoading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.action`]="{ item }">
            <div class="text-center d-flex align-center justify-space-around">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                    color="primary"
                    @click="setPayCommissionDialog(item)"
                    :disabled="item.unpaid_commission == '0.00'"
                  >
                    <v-icon size="large"> mdi-cash-plus </v-icon>
                  </v-btn>
                </template>
                <span>Pay Commission</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.mobile_number`]="{ item }">
            +63{{ item.mobile_number }}
          </template>

          <template v-slot:[`item.commission`]="{ item }">
            {{ moneyFormat(parseFloat(item.commission)) }}
          </template>

          <template v-slot:[`item.wallet`]="{ item }">
            {{ moneyFormat(parseFloat(item.wallet)) }}
          </template>

          <template v-slot:[`item.unpaid_commission`]="{ item }">
            {{ moneyFormat(parseFloat(item.unpaid_commission)) }}
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            {{ setCreatedAtFormat(item.created_at) }}
          </template>

          <template v-slot:[`item.is_active`]="{ item }">
            <v-chip
              small
              :class="
                item.is_active == 'Active'
                  ? 'green lighten-5 green--text'
                  : 'red lighten-5 red--text'
              "
            >
              {{ item.is_active }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <PayCommissionDialog
      v-if="payCommissionDialog"
      :payCommissionData="payCommissionData"
      @setPayCommissionCloseDialog="setPayCommissionCloseDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import formatDatev2 from "@/helpers/formatDatev2";
import PayCommissionDialog from "@/components/agents/CommissionPaymentsDialog";
import downloadexcel from "vue-json-excel";

export default {
  components: {
    PayCommissionDialog,
    downloadexcel,
  },

  data: () => ({
    search: "",
    sortBy: "created_at",
    sortDesc: true,
    payCommissionDialog: false,
    payCommissionData: {},
    headers: [
      {
        text: "Actions",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
        width: 90,
      },
      {
        text: "Agent Name",
        value: "agent",
        class: "text-uppercase",
        sortable: false,
      },
      {
        text: "Mobile Number",
        value: "mobile_number",
        class: "text-uppercase",
      },
      { text: "Email", value: "email_address", class: "text-uppercase" },
      {
        text: "Commission %",
        value: "commission",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Wallet",
        value: "wallet",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Unpaid Commission",
        value: "unpaid_commission",
        class: "text-uppercase",
        align: "right",
      },
      { text: "status", value: "is_active", class: "text-uppercase" },
      {
        text: "Created At",
        value: "created_at",
        class: "text-uppercase",
      },
    ],
  }),

  mounted() {
    this.loadHandler();
  },

  computed: {
    ...mapState("agents_commission_payments", ["records", "recordsLoading"]),
  },

  methods: {
    ...mapActions("agents_commission_payments", ["getRecords"]),

    async loadHandler() {
      if (!this.recordsLoading) {
        this.$store.commit("agents_commission_payments/setRecords", []);
        await this.getRecords();
      }
    },

    setPayCommissionDialog(item) {
      this.payCommissionData = item;
      this.payCommissionDialog = true;
    },

    setPayCommissionCloseDialog(payment_status) {
      if (payment_status) {
        this.loadHandler();
      }

      this.payCommissionDialog = false;
    },

    setCreatedAtFormat(date) {
      return formatDatev2(date);
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>
