const url = process.env.VUE_APP_API_URL + "players/reject_reasons"

export default {
    namespaced: true,

    state: {
        records: [],
        recordsLoading: false,
        upsertLoading: false,
        showSnackbar: false,
        totalPage: 0,
        totalRows: 0,
        currentPage: 1,
    },

    getters: {
        getRecords: state => state.records,
        getRecordsLoading: state => state.recordsLoading,
        getUpsertLoading: state => state.upsertLoading,
        getShowSnackbar: state => state.showSnackbar,
        getTotalPage: state => state.totalPage,
        getTotalRows: state => state.totalRows,
        getCurrentPage: state => state.currentPage,
    },

    mutations: {
        setRecords(state, val) {
            state.records = val
        },
        setRecordsLoading(state, val) {
            state.recordsLoading = val
        },
        setUpsertLoading(state, val) {
            state.upsertLoading = val
        },
        setShowSnackbar(state, val) {
            state.showSnackbar = val
        },
        setTotalPage(state, val) {
            state.totalPage = val
        },
        setTotalRows(state, val) {
            state.totalRows = val
        },
        setCurrentPage(state, val) {
            state.currentPage = val
        },
    },
    actions: {
        async fetchRecords({ commit, rootState }, data) {
            commit("setRecordsLoading", true)

            try {
                const response = await fetch(`${url}?${data}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });

                const res = await response.json()

                if (!response.ok) {
                    throw { response, res }
                }
                commit("setRecords", res.records)
                commit("setTotalPage", res.totalPage)
                commit("setTotalRows", res.totalRows)

            } catch (error) {
                const errorStatus = error.response.status

                if (errorStatus == 401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setRecordsLoading", false)
            }
        },

        async insertRecords({ commit, rootState }, data) {
            try {
                commit("setUpsertLoading", true)
                const response = await fetch(`${url}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${rootState.userToken}`,
                    },
                    body: JSON.stringify(data)
                });

                if (!response.ok) {
                    const res = await response.json()
                    throw { response, res }
                }
                commit("setShowSnackbar", true)
            } catch (error) {
                console.log(error)
                const errorStatus = error.response.status
                if (errorStatus == 401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    const errorMessages = Object.keys(error.res.messages).map(key => `${error.res.messages[key]}`).join("\n ");
                    commit("setRefreshError", errorMessages ?? "There was an error. The page will refresh.", { root: true })
                }
            } finally {
                commit("setUpsertLoading", false)
            }
        },

        async updateRecords({ commit, rootState }, { data, id }) {
            try {
                commit("setUpsertLoading", true)
                const response = await fetch(`${url}/${id}`, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: JSON.stringify(data)
                });

                if (!response.ok) {
                    const res = await response.json()
                    throw { response, res }
                }
                commit("setShowSnackbar", true)
            } catch (error) {
                const errorStatus = error.response.status

                if (errorStatus == 401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    const errorMessages = Object.keys(error.res.messages).map(key => `${error.res.messages[key]}`).join("\n ");
                    commit("setRefreshError", errorMessages ?? "There was an error. The page will refresh.", { root: true })
                }

            } finally {
                commit("setUpsertLoading", false)
            }
        }
    }
}