<template>
  <div>
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          color="secondary"
          v-on="on"
          :disabled="records_loading"
          @click="dialog = true"
        >
          <v-icon>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      Filter
    </v-tooltip>

    <v-dialog v-model="dialog" max-width="400">
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-md-6">
          <h3 class="font-weight-bold mb-3">Filter</h3>
          <v-form ref="form" @submit.prevent="handleFilter">
            <div class="d-flex flex-column bs-gap-3">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Date From"
                    prepend-icon="mdi-calendar"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    :rules="[rules.required]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  scrollable
                  :min="first_withdrawal_account"
                  :max="date1"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                  <v-btn depressed color="primary" @click="$refs.dialog.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
              <v-dialog
                ref="dialog1"
                v-model="modal1"
                :return-value.sync="date1"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date1"
                    label="Date To"
                    prepend-icon="mdi-calendar"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    hide-details="auto"
                    :rules="[rules.required]"
                    :readonly="true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date1"
                  scrollable
                  :min="first_withdrawal_account"
                  :max="date1"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal1 = false"> Cancel </v-btn>
                  <v-btn depressed color="primary" @click="$refs.dialog1.save(date1)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>
            <div class="d-flex justify-end bs-gap-2 mt-4 mt-md-6">
              <v-btn text @click="dialog = false">Cancel</v-btn>
              <v-btn type="submit" depressed color="primary">Filter</v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import formRules from "@/helpers/formRules.js";
export default {
  data: () => ({
    rules: formRules,
    dialog: false,
    date: "",
    modal: false,
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    modal1: false,
  }),
  props: {
    records_loading: {
      type: Boolean,
      required: false,
    },
    first_withdrawal_account: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleFilter() {
      if (this.$refs.form.validate()) {
        this.dialog = false;

        this.$emit("handleFilter", {
          date_from: this.date,
          date_to: this.date1,
        });
      }
    },
  },
};
</script>
