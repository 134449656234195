<template>
    <div fluid class="pa-4 pa-md-6">
        <v-card>
            <v-card-title>
                <v-row class="d-flex space-between" align="end">
                    <v-col cols="12" md="6" lg="9">
                        <v-row dense>
                            <v-col class="ma-0 pa-0">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        color="secondary"
                                        @click="loadRecords"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="mr-1"
                                        >
                                        <v-icon>mdi-cached</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Refresh</span>
                                </v-tooltip>
                                
                                <span class="text-h6 font-weight-regular grey--text text--darken-1 overline"> Monitoring per bet type </span> 
                            </v-col>
                        </v-row>

                        <v-row dense class="mt-2">
                            <v-col>
                                <v-avatar size="36px" tile>
                                    <v-img :src="game.team_a_logo">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0"
                                                align="center"
                                                justify="center">
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="black"/>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-avatar>
                                <span> {{ game.team_a }} </span> 
                                <span class="text-overline my-2 mx-2 font-italic">vs</span>
                                <v-avatar size="36px" tile>
                                    <v-img :src="game.team_b_logo">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0"
                                                align="center"
                                                justify="center">
                                                    <v-progress-circular
                                                        indeterminate
                                                        color="black"/>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </v-avatar>
                                <span class="mr-2"> {{ game.team_b }} </span> <span class="font-weight-thin subtitle-1"> {{ (Object.keys(game).length) ? `( Schedule : ${setCreatedAtFormat(game.schedule)} )`: '' }} </span> 
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" md="6" lg="3">
                        <v-text-field
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            placeholder="Search"
                            single-line
                            hide-details="auto"
                            clearable
                            outlined
                            dense>

                            <!-- <template v-slot:append-outer>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                        icon
                                        color="secondary"
                                        v-on="on"
                                        small
                                        @click="showFilter">
                                        <v-icon>mdi-filter-variant</v-icon>
                                        </v-btn>
                                    </template>
                                    Filter
                                </v-tooltip>
                            </template>     -->
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-divider/>

            <v-card-text class="pa-4">
                <v-data-table
                    :headers="getHeader"
                    :items="records"
                    :items-per-page="5"
                    :search="search"
                    :loading="loading"
                    loading-text="Loading, please wait a moment"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc">

                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    :icon="($vuetify.breakpoint.mdAndUp) ? true : false"
                                    small                                    
                                    :outlined="($vuetify.breakpoint.mdAndUp) ? false : true"
                                    color="primary"
                                    @click="setAddCard(item.amount_id, item.bet_type, item.amount)">
                                    <v-icon size="large" v-if="$vuetify.breakpoint.mdAndUp"> mdi-plus </v-icon>

                                    {{ ($vuetify.breakpoint.mdAndUp) ? '' : 'Add Card' }} 
                                </v-btn>
                            </template>
                            <span>Add Card</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:[`item.game_filter`]="{ item }">
                        <div>
                            <v-avatar size="36px" tile>
                                <v-img :src="item.team_a_logo">
                                    <template v-slot:placeholder>
                                        <v-row class="fill-height ma-0"
                                            align="center"
                                            justify="center">
                                                <v-progress-circular
                                                    indeterminate
                                                    color="black"/>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-avatar>
                            <span> {{ item.team_a_name }} {{ item.team_a_alias }} </span> 
                            <span class="text-overline my-2 mx-2 font-italic">vs</span>
                            <v-avatar size="36px" tile>
                                <v-img :src="item.team_b_logo">
                                    <template v-slot:placeholder>
                                        <v-row class="fill-height ma-0"
                                            align="center"
                                            justify="center">
                                                <v-progress-circular
                                                    indeterminate
                                                    color="black"/>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-avatar>
                            <span> {{ item.team_b_name }} {{ item.team_b_alias }} </span> 
                        </div>
                    </template>

                    <template v-slot:[`item.cards`]="{ item }">
                        {{ parseInt(item.cards).toLocaleString() }}
                    </template>

                    <template v-slot:[`item.used_cards`]="{ item }">
                        {{ parseInt(item.used_cards).toLocaleString() }} ({{ getPercentage(item.cards, item.used_cards) }}%)
                    </template>

                    <template v-slot:[`item.un_used_cards`]="{ item }">
                        {{ parseInt(item.un_used_cards).toLocaleString() }} ({{ getPercentage(item.cards, item.un_used_cards) }}%)
                    </template>

                    <template v-slot:[`item.schedule`]=" { item }">
                        {{ setCreatedAtFormat(item.schedule) }}
                    </template>

                    <template v-slot:[`item.greater_than`]="{ item }">
                        <v-chip
                            small
                            
                            :class="getColor(item.cards, item.greater_than)"
                            >
                            {{ parseInt(item.greater_than).toLocaleString() }} ({{ getPercentage(item.cards, item.greater_than) }}%)
                        </v-chip>
                    </template>

                    <template v-slot:[`item.less_than`]="{ item }">
                        {{ (parseInt(item.less_than) + parseInt(item.un_used_cards)).toLocaleString() }} ({{ getPercentage(item.cards, parseInt(item.less_than) + parseInt(item.un_used_cards)) }}%)
                    </template>
                </v-data-table> 
            </v-card-text>
        </v-card>

        <DateFilter v-if="dateFilterDialog" :data_filters="data_filters" @setFilterCloseModal="setFilterCloseModal"/>
        <AddCard v-if="addCardDialog" :game_data="game_data" @setAddCardCloseModal="setAddCardCloseModal"/>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
// import { format, sub } from 'date-fns'
import formatDatev2 from "@/helpers/formatDatev2";
import DateFilter from '@/components/monitoring/cardMonitoring/Filter'
import AddCard from '@/components/monitoring/cardMonitoring/AddCard'

export default {
    props: {
        id: {
            required: true
        },
        p_date_from: {
            required: true
        },
        p_date_to: {
            required: true
        },
    },

    components: { DateFilter, AddCard },

    data: () => ({
        search: '',
        sortBy: "bet_type",
        sortDesc: false,
        game_id: '',
        date_from: '',
        date_to:  '',
        dateFilterDialog: false,
        addCardDialog: false,
        game_data: {},
        data_filters: {}
    }),

    created() {
        // const urlQuery = this.$route.query

        // if (Object.keys(urlQuery).length) { 
        //     this.date_from = urlQuery.date_from
        //     this.date_to = urlQuery.date_to
        // } else {
            
        // }
        this.date_from = this.p_date_from
        this.date_to = this.p_date_to
        this.game_id = this.id

        this.loadRecords()
    },

    computed: {
        ...mapState("card_monitoring", ["loading", "records", "error", "errors", "cardTreshHold", "game"]),

        getHeader() {
            return [
                {
                    text: "Action",
                    sortable: false,
                    value: "action",
                    class: "text-uppercase",
                    align: "center",
                },
                {
                    text: "Bet Type",
                    sortable: false,
                    value: "bet_type",
                    class: "text-uppercase",
                    align: "center",
                },
                {
                    text: "Amount",
                    sortable: false,
                    value: "amount",
                    class: "text-uppercase",
                    align: "right",
                },
                {
                    text: "Card(s)",
                    sortable: false,
                    value: "cards",
                    class: "text-uppercase",
                    align: "right"
                },
                // {
                //     text: "Card(s) with Bet",
                //     sortable: false,
                //     value: "used_cards",
                //     class: "text-uppercase",
                //     align: "right"
                // },
                // {
                //     text: "Card(s) w/o Bet",
                //     sortable: false,
                //     value: "un_used_cards",
                //     class: "text-uppercase",
                //     align: "right"
                // },
                {
                    text: `With >= ${this.cardTreshHold}%`,
                    sortable: false,
                    value: "greater_than",
                    class: "text-uppercase",
                    align: "right"
                },
                {
                    text: `With < ${this.cardTreshHold}%`,
                    sortable: false,
                    value: "less_than",
                    class: "text-uppercase",
                    align: "right"
                }
            ]
        },
    },

    methods: {
        ...mapActions("card_monitoring", ["getRecordsPerBet"]),

        async loadRecords() {
            this.$store.commit("card_monitoring/setCardTreshHold", 0)
            this.$store.commit("card_monitoring/setRecords", [])

            await this.getRecordsPerBet(this.setRecordFormdata())
        },

        setRecordFormdata() {
            const formData = new FormData()

            formData.append("game_id", this.game_id)
            formData.append("date_from", this.date_from)
            formData.append("date_to", this.date_to)

            const getData = new URLSearchParams(formData)

            return getData
        },

        getPercentage(cards, val) {
            return (val / cards * 100).toFixed(2)
        },

        getColor(cards, val) {
            const percentage = (val / cards * 100)
            let color = ''
            
            if (percentage == 100) {
                color = 'red lighten-5 red--text'
            } else if (percentage > 90) {
                color = 'orange lighten-5 orange--text'
            } else if (percentage > 70) {
                color = 'amber lighten-5 amber--text'
            } else if (percentage > 50) {
                color = 'green lighten-5 green--text'
            }  

            return color
        },

        showFilter() {
            this.dateFilterDialog = true

            this.data_filters = {
                "date_from": this.date_from,
                "date_to": this.date_to,
            }
        },

        setFilterCloseModal(data_filter) {
            this.dateFilterDialog = false

            if (data_filter.status == 'search') {
                this.date_from = data_filter.date_from
                this.date_to = data_filter.date_to

                this.loadRecords()
                // const urlQuery = this.$route.query

                // if (urlQuery.date_from != this.date_from || urlQuery.date_to != this.date_to) {
                //     this.query_filters = {
                //         "date_from": this.date_from,
                //         "date_to": this.date_to
                //     }

                //     this.$router.push({
                //         name: this.$route.name,
                //         query: this.query_filters
                //     })
                // }
                this.$router.replace({ name: 'GameCardPerBetMonitoring', params: { id: this.id, p_date_from: data_filter.date_from, p_date_to: this.date_to } }) 
            }
        },

        setAddCard(bet_amount_id, bet_type, amount) {
             this.game_data = {
                "game_id": this.id,
                "bet_type": bet_type,
                "bet_amount_id": bet_amount_id,
                "amount": amount
            }

            this.addCardDialog = true
        },

        setAddCardCloseModal(card_data) {
            this.addCardDialog = false

            if (card_data.status == 'addCard') {
                this.loadRecords()
                // const recs = this.records.find((record) => record.amount_id == card_data.bet_amount_id);
                // recs.cards = (parseInt(recs.cards) + parseInt(card_data.card_count));
                // // recs.greater_than = `${(parseInt(recs.greater_than) + parseInt(card_data.card_count)).toLocaleString()}` + `(${this.getPercentage((parseInt(recs.cards) + parseInt(card_data.card_count)), recs.greater_than)}%)`
                // // recs.less_than = recs.less_than
            }
        },

        moneyFormat(val) {
            return val.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },

        setCreatedAtFormat(date) {
            return formatDatev2(date)
        },
    }
}
</script>