var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Agent Reports")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Bets")]),_c('v-card',{staticClass:"shadow mb-4"},[_c('v-card-title',{staticClass:"text-h6 py-2 font-weight-regular grey--text text--darken-1"},[_vm._v(" Select Bet Date ")]),_c('v-divider'),_c('v-card-text',{staticClass:"py-4"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":"","disabled":_vm.loading},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.rules.required, _vm.lessThanDate],"label":"Bet Date From","hide-details":"auto","readonly":"","outlined":"","dense":"","clearable":""},on:{"keyup":function($event){_vm.date_from_menu = true},"keydown":function($event){_vm.date_from_menu = false}},model:{value:(_vm.date_from),callback:function ($$v) {_vm.date_from=$$v},expression:"date_from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_from_menu),callback:function ($$v) {_vm.date_from_menu=$$v},expression:"date_from_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.date_from_menu = false}},model:{value:(_vm.date_from),callback:function ($$v) {_vm.date_from=$$v},expression:"date_from"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.lessThanDate2],"label":"Bet Date To (Optional)","hide-details":"auto","readonly":"","outlined":"","dense":"","disabled":_vm.dateToAvailability,"clearable":""},on:{"keyup":function($event){_vm.date_to_menu = true},"keydown":function($event){_vm.date_to_menu = false}},model:{value:(_vm.date_to),callback:function ($$v) {_vm.date_to=$$v},expression:"date_to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_to_menu),callback:function ($$v) {_vm.date_to_menu=$$v},expression:"date_to_menu"}},[_c('v-date-picker',{attrs:{"min":_vm.date_from ? _vm.date_from : ''},on:{"input":function($event){_vm.date_to_menu = false}},model:{value:(_vm.date_to),callback:function ($$v) {_vm.date_to=$$v},expression:"date_to"}})],1)],1)],1),_c('v-row',{staticClass:"mt-4",attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{attrs:{"disabled":!_vm.valid,"type":"submit","color":"primary","outlined":"","loading":_vm.loading,"block":_vm.$vuetify.breakpoint.mdAndUp ? false : true}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1),_c('v-card',{staticClass:"shadow"},[_c('v-card-title',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"2","align-self":"end"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","color":"secondary"},on:{"click":_vm.loadRecords}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cached")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search","single-line":"","hide-details":"auto","disabled":_vm.loading,"clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":-1,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading, please wait a moment","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"mobile-breakpoint":"0","hide-default-footer":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.showPlayers(item.id, item.agent_name)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-account-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Player(s)")])])]}},{key:"item.total_bets",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.total_bets).toLocaleString())+" ")]}},{key:"item.total_bets_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" PHP "+_vm._s(_vm.moneyFormat(parseFloat(item.total_bets_amount)))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.setCreatedAtFormat(item.created_at)))])]}},(_vm.records.length)?{key:"body.append",fn:function(){return [_c('tr',[_c('td'),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v("TOTAL")])]),_c('td',[_c('strong',[_vm._v(_vm._s(parseInt(_vm.totalBets).toLocaleString()))])]),_c('td',[_c('strong',[_vm._v(_vm._s("PHP " + _vm.moneyFormat(parseFloat(_vm.totalBetAmount))))])])])]},proxy:true}:null],null,true)})],1)],1),(_vm.playerDialog)?_c('Players',{attrs:{"player_data":_vm.player_data},on:{"setPlayerCloseModal":_vm.setPlayerCloseModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }