<template>
  <v-container class="px-md-7" fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
            Select Schedule
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-4">
            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="handleSubmit">
              <v-row>
                <v-col cols="12" md="6">
                  <v-menu v-model="schedule_date_start_menu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="schedule_date_start" :rules="[rules.required]" label="Schedule Date Start"
                        hide-details="auto" readonly outlined dense v-bind="attrs" v-on="on"
                        @keyup="schedule_date_start_menu = true" @keydown="schedule_date_start_menu = false"
                        clearable></v-text-field>
                    </template>
                    <v-date-picker v-model="schedule_date_start"
                      @input="schedule_date_start_menu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu v-model="schedule_date_end_menu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="schedule_date_end" label="Schedule Date End (Optional)" hide-details="auto"
                        readonly outlined dense v-bind="attrs" v-on="on" @keyup="schedule_date_end_menu = true"
                        @keydown="schedule_date_end_menu = false" clearable></v-text-field>
                    </template>
                    <v-date-picker v-model="schedule_date_end" @input="schedule_date_end_menu = false"
                      :min="(schedule_date_start) ? schedule_date_start : ''"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <div>
                <v-radio-group :rules="[rules.required]" hide-details="auto" v-model="report_type" row>
                  <v-radio label="Daily" value="Daily"></v-radio>
                  <v-radio label="Weekly" value="Weekly"></v-radio>
                  <v-radio label="Monthly" value="Monthly"></v-radio>
                  <v-radio label="Yearly" value="Yearly"></v-radio>
                </v-radio-group>
              </div>
              <div class="mt-4">
                <v-btn :disabled="!valid" type="submit" color="primary" outlined @click.prevent="handleSubmit"
                  :loading="recordsLoading">
                  Submit
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
            {{ searched_report_type }} Report
            <v-spacer></v-spacer>
            <div>
              <downloadexcel :data="exportExcelDataWithTotal()" :fields="{
                'Schedule Date': 'final_schedule_date',
                'Total Games': 'total_games',
                'Total Gross Amount': 'game_total_bet_amount',
                'Total Payouts': 'game_total_prizes',
                'Total Net Amount': 'net_amount',
                'Total Pagcor Share': 'pagcor_share'
              }" :name="downloadExcelName()" type="csv" class="d-inline-block" v-if="records.length">
                <v-btn color="primary" outlined small depressed>
                  Export CSV
                </v-btn>
              </downloadexcel>
              <v-btn color="primary" outlined small depressed @click="exportToPDF" class="ms-2 d-inline-block"
                v-if="records.length">
                Export PDF
              </v-btn>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <div ref="datatable">
            <v-card-text class="pa-0">
              <v-data-table :headers="headers" :items="records" :items-per-page="-1" :loading="recordsLoading"
                loading-text="Loading... Please wait" mobile-breakpoint="0" hide-default-footer>
                <template v-slot:[`item.final_schedule_date`]="{ item }">
                  <span v-if="item.total_games > 0">{{ scheduleDate(item.final_schedule_date) }}</span>
                  <span v-else>{{ scheduleDate(item.dates) }}</span>
                </template>
                <template v-slot:[`item.total_games`]="{ item }">
                  <span v-if="item.total_games > 0">{{ parseInt(item.total_games).toLocaleString() }}</span>
                  <span v-else>No Activity</span>
                </template>
                <template v-slot:[`item.game_total_bet_amount`]="{ item }">
                  <span v-if="item.total_games > 0">{{ 'PHP ' + moneyFormat(parseFloat(item.game_total_bet_amount)) }}</span>
                </template>
                <template v-slot:[`item.game_total_prizes`]="{ item }">
                  <span v-if="item.total_games > 0">{{ 'PHP ' + moneyFormat(parseFloat(item.game_total_prizes)) }}</span>
                </template>
                <template v-slot:[`item.net_amount`]="{ item }">
                  <span v-if="item.total_games > 0">{{ 'PHP ' + moneyFormat(parseFloat(item.net_amount)) }}</span>
                </template>
                <template v-slot:[`item.pagcor_share`]="{ item }">
                  <span v-if="item.total_games > 0">{{ 'PHP ' + moneyFormat(parseFloat(item.pagcor_share)) }}</span>
                </template>
                <template v-if="records.length" v-slot:[`body.append`]>
                  <tr>
                    <td class="text-right"><strong>TOTAL</strong></td>
                    <td><strong>{{ totalGames.toLocaleString() }}</strong></td>
                    <td><strong>{{ 'PHP ' + moneyFormat(parseFloat(totalGrossAmount)) }}</strong></td>
                    <td><strong>{{ 'PHP ' + moneyFormat(parseFloat(totalDistributedPrizes)) }}</strong></td>
                    <td><strong>{{ 'PHP ' + moneyFormat(parseFloat(totalNetAmount)) }}</strong></td>
                    <td><strong>{{ 'PHP ' + moneyFormat(parseFloat(totalPagcorShare)) }}</strong></td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex"
import formRules from '@/helpers/formRules'
import formatDatev2 from '@/helpers/formatDatev2'
import downloadexcel from "vue-json-excel"
import html2pdf from 'html2pdf.js'

export default {
  components: { downloadexcel },
  data: () => ({
    valid: true,
    rules: formRules,
    schedule_date_start: '',
    schedule_date_start_menu: false,
    schedule_date_end: '',
    schedule_date_end_menu: false,
    report_type: 'Daily',
    sortDesc: true,
    headers: [
      { text: 'Schedule Date', value: 'final_schedule_date', class: 'text-uppercase' },
      { text: 'Total Games', value: 'total_games', class: 'text-uppercase' },
      { text: 'Total Gross Amount', value: 'game_total_bet_amount', class: 'text-uppercase' },
      { text: 'Total Payouts', value: 'game_total_prizes', class: 'text-uppercase' },
      { text: 'Total Net Amount', value: 'net_amount', class: 'text-uppercase' },
      { text: 'Total Pagcor Share', value: 'pagcor_share', class: 'text-uppercase' },
    ],
    searched_report_type: ''
  }),
  created() {
    this.$store.commit("lucky_four_pagcor_reports_progress/setRecords", [])
  },
  computed: {
    ...mapState("lucky_four_pagcor_reports_progress", ["records", "recordsLoading"]),
    totalGames() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseInt(obj.total_games) + total, 0)
      } else {
        return 0
      }
    },
    totalGrossAmount() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseFloat(obj.game_total_bet_amount) + total, 0)
      } else {
        return 0
      }
    },
    totalDistributedPrizes() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseFloat(obj.game_total_prizes) + total, 0)
      } else {
        return 0
      }
    },
    totalNetAmount() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseFloat(obj.net_amount) + total, 0)
      } else {
        return 0
      }
    },
    totalPagcorShare() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseFloat(obj.pagcor_share) + total, 0)
      } else {
        return 0
      }
    },
    exportExcelData() {
      return this.records.map((record) => {
        return {
          final_schedule_date: (record.total_games > 0) ? this.scheduleDate(record.final_schedule_date) : this.scheduleDate(record.dates),
          total_games: (record.total_games > 0) ? parseInt(record.total_games).toLocaleString() : "No Activity",
          game_total_bet_amount: (record.total_games > 0) ? 'PHP ' + this.moneyFormat(parseFloat(record.game_total_bet_amount)) : "",
          game_total_prizes: (record.total_games > 0) ? 'PHP ' + this.moneyFormat(parseFloat(record.game_total_prizes)) : "",
          net_amount: (record.total_games > 0) ? 'PHP ' + this.moneyFormat(parseFloat(record.net_amount)) : "",
          pagcor_share: (record.total_games > 0) ? 'PHP ' + this.moneyFormat(parseFloat(record.pagcor_share)) : ""
        }
      })
    },
  },
  methods: {
    ...mapActions("lucky_four_pagcor_reports_progress", ["getRecords"]),
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    scheduleDate(sched) {
      if (this.searched_report_type == 'Daily') {
        return formatDatev2(sched, 'date')
      }

      if (this.searched_report_type == 'Weekly') {
        return formatDatev2(sched, 'monthweek')
      }

      if (this.searched_report_type == 'Monthly') {
        return formatDatev2(sched, 'monthyear')
      }

      if (this.searched_report_type == 'Yearly') {
        return sched
      }
    },
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('schedule_date_start', (this.schedule_date_start === null) ? '' : this.schedule_date_start)
        formData.append('schedule_date_end', (this.schedule_date_end === null) ? '' : this.schedule_date_end)
        formData.append('report_type', (this.report_type === null) ? '' : this.report_type)

        const getData = new URLSearchParams(formData)

        if (!this.recordsLoading) {
          this.$store.commit("lucky_four_pagcor_reports_progress/setRecords", [])

          const res = await this.getRecords(getData)

          this.searched_report_type = res.report_type
        }
      }
    },
    exportExcelDataWithTotal() {
      const dataTotal = {
        final_schedule_date: 'TOTAL',
        total_games: this.totalGames.toLocaleString(),
        game_total_bet_amount: 'PHP ' + this.moneyFormat(parseFloat(this.totalGrossAmount)),
        game_total_prizes: 'PHP ' + this.moneyFormat(parseFloat(this.totalDistributedPrizes)),
        net_amount: 'PHP ' + this.moneyFormat(parseFloat(this.totalNetAmount)),
        pagcor_share: 'PHP ' + this.moneyFormat(parseFloat(this.totalPagcorShare))
      }

      return [...this.exportExcelData, dataTotal]
    },
    exportToPDF() {
      html2pdf(this.$refs.datatable, {
        filename: 'Progress Report ' + formatDatev2(new Date()) + '.pdf',
        image: { type: 'jpeg' },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'government-letter', orientation: 'portrait' }
      })
    },
    downloadExcelName() {
      return 'Progress Report ' + formatDatev2(new Date()) + '.xls'
    },
  }
}
</script>


