<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: undefined,
    },
    height: {
      type: Number,
      default: 300,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [
        {
          beforeInit(chart) {
            // Get a reference to the original fit function
            const originalFit = chart.legend.fit;

            // Override the fit function
            chart.legend.fit = function fit() {
              // Call the original function and bind scope in order to use `this` correctly inside it
              originalFit.bind(chart.legend)();
              // Change the height as suggested in other answers
              this.height += 24;
            };
          },
        },
      ],
    },
    theChartData: {
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        labels: this.theChartData.labels,
        datasets: [
          {
            label: "Gross Sales",
            backgroundColor: "RGBA(171, 71, 188, 0.1)",
            pointBackgroundColor: "#FFFFFF",
            borderColor: "#9C27B0",
            data: this.theChartData.grossAmounts,
            borderRadius: 0,
            borderWidth: 1,
            borderSkipped: false,
            hidden: false,
          },
          {
            label: "Payouts",
            backgroundColor: "RGBA(236, 64, 122, 0.1)",
            pointBackgroundColor: "#FFFFFF",
            borderColor: "#EC407A",
            data: this.theChartData.distributedPrizes,
            borderRadius: 0,
            borderWidth: 1,
            borderSkipped: false,
            hidden: false,
          },
          {
            label: "Gross Gaming Revenue",
            backgroundColor: "RGBA(255, 167, 38, 0.1)",
            pointBackgroundColor: "#FFFFFF",
            borderColor: "#FFA726",
            data: this.theChartData.netAmount,
            borderRadius: 0,
            borderWidth: 1,
            borderSkipped: false,
            hidden: false,
          },
          {
            label: "PAGCOR Share",
            backgroundColor: "RGBA(92, 107, 192, 0.1)",
            pointBackgroundColor: "#FFFFFF",
            borderColor: "#5C6BC0",
            data: this.theChartData.pagcorShare,
            borderRadius: 0,
            borderWidth: 1,
            borderSkipped: false,
            hidden: false,
          },
          {
            label: "Net Amount",
            backgroundColor: "RGBA(129, 199, 132, 0.1)",
            pointBackgroundColor: "#FFFFFF",
            borderColor: "#81C784",
            data: this.theChartData.netIncome,
            borderRadius: 0,
            borderWidth: 1,
            borderSkipped: false,
            hidden: false,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: true,
            position: "top",
            labels: {
              font: {
                size: 13,
                family: "Poppins",
              },
              padding: 16,
              boxWidth: 12,
              boxHeight: 12,
            },
          },
        },
        scales: {
          xAxes: {
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: true,
              tickColor: "#FFFFFF",
            },
            ticks: {
              display: true,
              font: {
                family: "Poppins",
              },
            },
          },
          yAxes: {
            grid: {
              drawBorder: false,
              drawTicks: true,
              tickColor: "#FFFFFF",
              color: "#DDDDDD",
              borderDash: [5, 5],
              borderDashOffset: 2,
            },
            ticks: {
              display: true,
              font: {
                family: "Poppins",
              },
            },
          },
        },
      },
    };
  },
};
</script>
