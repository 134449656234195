<template>
    <v-dialog scrollable v-model="dialog" max-width="500">
        <v-card>
            <v-card-title>
                <v-icon class="mr-2">
                        mdi-{{ (data.flag == 'Create') ? 'plus-box-outline' : 'square-edit-outline' }}
                </v-icon>
                {{ data.flag }}

                <v-btn icon absolute right @click="closeDialog('', {})">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text class="py-4">
                <v-form ref="form"
                    v-model="valid"
                    lazy-validation
                    :disabled="saveLoading"
                    @submit.prevent="submit()">

                    <v-alert v-if="alertDialog" dense type="error">
                        Something went wrong.
                    </v-alert>
                    
                    <v-row dense>
                        <v-col>
                            <v-text-field
                                ref="outlet"
                                outlined
                                dense
                                label="Outlet"
                                :rules="(submitStatus) ? [rules.required, rules.minimum(2), outletExist] : []"
                                v-model="outlet_name"
                                hide-details="auto"
                            />
                        </v-col>
                    </v-row>

                    <!-- <v-row dense class="mt-3">
                        <v-col>
                            <v-text-field
                                ref="address"
                                outlined
                                dense
                                label="Address"
                                :rules="(submitStatus) ? [rules.required, rules.minimum(5)] : []"
                                v-model="address"
                                hide-details="auto"
                            />
                        </v-col>
                    </v-row> -->

                    <v-row no-gutters class="mt-4">
                        <p class="overline mb-0">Address</p>

                        <v-col cols="12">
                            <v-autocomplete
                                v-model="citymun"
                                :items="citymuns"
                                :rules="[rules.required]"
                                label="City / Municipality"
                                outlined
                                dense
                                item-text="citymun"
                                return-object/>
                        </v-col>

                        <v-col cols="12">
                            <v-autocomplete
                                v-model="barangay"
                                :items="filteredBarangays"
                                :rules="[rules.required]"
                                label="Barangay"
                                outlined
                                dense
                                item-text="barangay"
                                return-object/>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="address"
                                :rules="[rules.required, rules.minimum(5), rules.maximum(200)]"
                                label="Street, House / Lot & Block No., Room / Floor / Unit No."
                                outlined
                                dense
                                hide-details="auto"/>
                        </v-col>
                    </v-row>

                    <v-row dense v-if="data.flag == 'Update'" class="mt-0">
                        <v-col cols="auto">
                            <v-switch
                            v-model="is_active"
                            :label="is_active ? 'Active' : 'Inactive'"
                            color="success"
                            hide-details/>
                        </v-col>
                    </v-row>

                    <v-row dense class="mt-2">
                        <v-col cols="12" md="6">
                            <v-btn block depressed color="primary" :disabled="saveLoading" :loading="saveLoading" type="submit">
                                {{ data.flag }}
                            </v-btn>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-btn block depressed :disabled="saveLoading" @click="closeDialog('', {})">
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import formRules from '@/helpers/formRules'
import { mapActions, mapState } from 'vuex'
import Citymuns from '@/data/citymuns.json'
import Barangays from '@/data/barangays.json'

export default {
    props: {
        data: {
            type: Object
        }
    },

    data: () => ({
        valid: true,
        outlet_name: '',
        citymun: '',
        barangay: '',
        address: '',
        is_active: '',
        rules: formRules,
        submitStatus: false,
        outletText: '',
        alertDialog: false,
        citymuns: Citymuns,
        barangays: Barangays
    }),

    computed: {
        ...mapState("outlets", ["saveRecord", "saveLoading", "saveError", "updateRecord"]),

        dialog: {
            get: function() {
                return true
            }, 
            set: function() {
                this.closeDialog('', {})
            }
        },

        filteredBarangays() {
            if (this.citymun) {
                return this.barangays.filter((bar) => {
                    if (parseInt(bar.id) == parseInt(this.data.barangay_id)) {
                        this.barangay = bar
                    }

                    return parseInt(bar.citymun_id) == parseInt(this.citymun.id)
                })
            } else {
                return []
            }
        },

        // filteredCityMUns() {
        //     if (this.data.citymun_id) {
        //         return this.citymuns.filter((citymun) => {
        //             if (parseInt(citymun.id) == parseInt(this.data.citymun_id)) {
        //                 this.citymun = citymun
        //             }
                    
        //             return parseInt(citymun.id) == parseInt(this.data.citymun_id)
        //         })
        //     } else {
        //         return []
        //     }
        // }
    },

    created() {
        this.outlet_name = this.data.outlet_name
        
        if (this.data.citymun_id) {
            this.getCityMunData()
        }

        this.address = this.data.address
        this.is_active = (this.data.is_active == 'Active') ? true : false
    },

    methods: {
        ...mapActions("outlets", ["saveOutlet", "updateOutlet"]),

        closeDialog(action, insertedData) {
            this.data['flag'] = ''
            this.data['id'] = 0
            this.$emit("closeDialog", action, insertedData)
        },

        outletExist(val) {
            if (val == this.outletText) {
                return "Outlet already exists.";
            } else return true;
        },

        getCityMunData() {
            this.citymuns.filter((citymun) => {
                if (parseInt(citymun.id) == parseInt(this.data.citymun_id)) {
                    this.citymun = citymun
                }
            })
        },

        async submit() {
            this.submitStatus = true

            await new Promise(r => setTimeout(r, 200));

            if (this.$refs.form.validate()) {
                if (!this.saveLoading) {
                    switch(this.data.flag) {
                        case 'Create': {
                            await this.saveOutlet(this.setFormData())

                            if (this.saveError) {
                                switch(this.saveError) {
                                    case 403: {
                                        this.outletText = this.outlet_name
                                        break
                                    }
                                    case 417: {
                                        this.alertDialog = true
                                        break
                                    }
                                }
                            } else {
                                this.closeDialog('create', this.saveRecord)
                            }
                            break
                        }
                        case 'Update':
                            await this.updateOutlet(this.setFormData())

                            if (this.saveError) {
                                switch(this.saveError) {
                                    case 417: {
                                        this.alertDialog = true
                                        break
                                    }
                                }
                            } else {
                                this.closeDialog('update', this.updateRecord)
                            }
                            break                 
                    }           
                }
            }      
        },

        setFormData() {
            const formData = new FormData()

            switch(this.data.flag) {
                case 'Create': {
                    formData.append('outlet_name', this.outlet_name)
                    formData.append('barangay_id', this.barangay.id)
                    formData.append('address', this.address)
                    break
                }
                case 'Update': {
                    formData.append('id', this.data.id)
                    formData.append('outlet_name', this.outlet_name)
                    formData.append('barangay_id', this.barangay.id)
                    formData.append('address', this.address)
                    formData.append('is_active', (this.is_active) ? 'Active' : 'Inactive')
                    break
                }
            }

            return formData
        }
    },

    watch: {
        alertDialog(val) {
            if (val) {
                setTimeout(() => {
                    this.alertDialog = false
                }, 3000);
            }
        }
    }
}
</script>