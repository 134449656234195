const url = process.env.VUE_APP_API_URL + "lucky_four/reports/game"

export default {
    namespaced: true,

    state: {
        records: [],
        recordsLoading: false
    },
    mutations: {
        setRecords(state, val) {
            state.records = val
        },
        setRecordsLoading(state, val) {
            state.recordsLoading = val
        }
    },

    actions: {
        async getRecords({ commit, rootState }, data) {
            commit("setRecordsLoading", true)

            try {
                const response = await fetch(`${url}?${data}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });

                const res = await response.json()

                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setRecords", res.records)
                }
            } catch (error) {
                const errorStatus = error.response.status

                if (errorStatus == 401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setRecordsLoading", false)
            }
        }
    }
}