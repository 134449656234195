<template>
  <v-dialog v-model="dialog" max-width="350">
    <v-card class="shadow">
      <div class="pa-4 pa-md-6">
        <h3 class="font-weight-bold mb-3">Filter</h3>
        <v-form
          ref="form"
          @submit.prevent="do_filter"
          lazy-validation
          class="d-flex flex-column"
          style="gap: 1rem"
        >
          <v-select
            dense
            outlined
            v-model="filter"
            :items="filters"
            hide-details
            class="mb-2"
          />

          <v-text-field
            autofocus
            v-if="filter == 'Name'"
            dense
            outlined
            v-model="first_name"
            label="First Name"
            hide-details="auto"
            clearable
            :rules="[rules.minimum(2), value_validation]"
          />

          <v-text-field
            v-if="filter == 'Name'"
            dense
            outlined
            v-model="last_name"
            label="Last Name"
            hide-details="auto"
            clearable
            :rules="[rules.minimum(2), value_validation]"
          />

          <v-text-field
            autofocus
            v-if="filter == 'Mobile Number'"
            dense
            outlined
            v-model="mobile_number"
            label="Mobile Number"
            prefix="+63"
            hide-details="auto"
            clearable
            maxlength="10"
            @keyup="remove_leading_zero"
            :rules="[rules.required, rules.validMobileNumber]"
          />

          <v-menu
            v-if="filter == 'Date Registered' || filter == 'Date Verified'"
            v-model="date_from_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_from"
                :rules="[rules.required]"
                label="Date From"
                hide-details="auto"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                @keyup="date_from_menu = true"
                @keydown="date_from_menu = false"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_from"
              @input="date_from_menu = false"
            ></v-date-picker>
          </v-menu>

          <v-menu
            v-if="filter == 'Date Registered' || filter == 'Date Verified'"
            v-model="date_to_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_to"
                label="Date To (Optional)"
                hide-details="auto"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                @keyup="date_to_menu = true"
                @keydown="date_to_menu = false"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_to"
              @input="date_to_menu = false"
              :min="date_from"
            ></v-date-picker>
          </v-menu>

          <v-select
            v-if="filter == 'Date Registered'"
            v-model="status"
            :items="statuses"
            :rules="[rules.required]"
            label="Verification Status"
            dense
            outlined
            hide-details="auto"
          ></v-select>

          <v-select
            v-model="active_status"
            :items="active_statuses"
            :rules="[rules.required]"
            label="Active Status"
            dense
            outlined
            hide-details="auto"
          ></v-select>

          <div class="d-flex justify-end bs-gap-2 mt-2">
            <v-btn text @click="close_dialog"> Cancel </v-btn>

            <v-btn depressed color="primary" type="submit"> Filter </v-btn>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import form_rules from "@/helpers/formRules";

export default {
  props: {
    data: {
      type: Object,
    },
  },

  data: () => ({
    rules: form_rules,
    filter: "Date Registered",
    filters: ["Date Registered", "Date Verified", "Name", "Mobile Number"],
    status: "Files Submitted",
    statuses: ["All", "Pending", "Verified", "Files Submitted", "Rejected"],
    active_status: "Active",
    active_statuses: ["All", "Active", "Inactive"],
    first_name: "",
    last_name: "",
    mobile_number: "",
    date_from: null,
    date_from_menu: false,
    date_to: null,
    date_to_menu: false,
  }),

  computed: {
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.close_dialog();
      },
    },

    value_validation() {
      if (!this.first_name && !this.last_name) {
        return "Atleast one of these fields have a value.";
      }

      return true;
    },
  },

  mounted() {
    this.filter =
      this.data.filter == 0
        ? "Date Registered"
        : this.data.filter == 1
        ? "Name"
        : this.data.filter == 2
        ? "Mobile Number"
        : this.data.filter == 3
        ? "Date Verified"
        : null;
    this.first_name = this.data.first_name;
    this.last_name = this.data.last_name;
    this.mobile_number = this.data.mobile_number;
    this.date_from = this.data.date_from;
    this.date_to = this.data.date_to;
    this.status = this.data.status;
    this.active_status = this.data.active_status;
  },

  methods: {
    do_filter() {
      if (this.$refs.form.validate()) {
        this.$emit("set_close_filter_dialog", {
          type: true,
          filter:
            this.filter == "Date Registered"
              ? 0
              : this.filter == "Name"
              ? 1
              : this.filter == "Mobile Number"
              ? 2
              : this.filter == "Date Verified"
              ? 3
              : null,
          first_name: this.first_name,
          last_name: this.last_name,
          mobile_number: this.mobile_number,
          date_from: this.date_from,
          date_to: this.date_to ?? this.date_from,
          status: this.status,
          active_status: this.active_status,
        });
      }
    },

    remove_leading_zero() {
      this.mobile_number = this.mobile_number.replace(/^0+/, "");
    },

    close_dialog() {
      this.$emit("set_close_filter_dialog", { type: false });
    },
  },

  watch: {
    date_from(val) {
      if (new Date(val) > new Date(this.date_to)) {
        this.date_to = val;
      }
    },
  },
};
</script>
