import jwtdecode from "../helpers/jwtdecode";

const url = process.env.VUE_APP_API_URL + "login";

export default {
  namespaced: true,

  state: {
    error: null,
    accountError: null,
    errors: [],
    loading: false,
    verifyOtpError: null,
    verifyOtpLoading: false,
    sendOtpError: null,
    sendOtpLoading: false,
    isSuccessResponse: false,
  },
  mutations: {
    setError(state, val) {
      state.error = val;
    },
    setAccountError(state, val) {
      state.accountError = val;
    },
    setErrors(state, val) {
      state.errors = val;
    },
    setLoading(state, val) {
      state.loading = val;
    },
    setVerifyOtpError(state, val) {
      state.verifyOtpError = val;
    },
    setVerifyOtpLoading(state, val) {
      state.verifyOtpLoading = val;
    },
    setSendOtpError(state, val) {
      state.sendOtpError = val;
    },
    setSendOtpLoading(state, val) {
      state.sendOtpLoading = val;
    },
    setIsSuccessResponse(state, val) {
      state.isSuccessResponse = val;
    },
  },

  actions: {
    async doLogin({ commit, rootState }, data) {
      commit("setLoading", true);
      commit("setError", null);
      commit("setAccountError", null);
      commit("setErrors", []);

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization:
              "Basic YWRtaW5VbkF1dGg6SEpDTGFzY2FzYXNHYW1pbmdAMjAyMiFAQWRtaW5VbkF1dGg9PS0=",
          },
          body: data,
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {

          // Reset all main states
          commit("resetStates", "", { root: true });

          commit("setUserData", jwtdecode(res.userData), { root: true });
          commit("setUserToken", res.userData, { root: true });
          localStorage.setItem("userToken", res.userData);
          localStorage.setItem(
            "mobile_number",
            rootState.userData.mobile_number
          );

          commit("setAccountInfo", res.accountInfo, { root: true });
          localStorage.setItem("accountInfo", JSON.stringify(res.accountInfo));

          // const admin_id = res.accountInfo.id;
          // if (
          //   admin_id == 59 ||
          //   admin_id == 57 ||
          //   admin_id == 48 ||
          //   admin_id == 60 ||
          //   admin_id == 46 ||
          //   admin_id == 18
          // ) {
          //   this.$router.push({
          //     name: "Maintenance",
          //   });
          // }

          return res.ladingPage;
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 403) {
          commit("setAccountError", error.res.messages.error);
        } else if (errorStatus == 422) {
          commit("setErrors", error.res.messages);
        } else {
          commit("setError", "Something went wrong, please try again!");
          commit("setPopUpError", "Something went wrong, please try again!", {
            root: true,
          });
        }
        /* if ([401].includes(errorStatus)) {
          commit("setError", error.res.messages.error) 
          commit("setPopUpError", error.res.messages.error, { root: true })
        } else if (errorStatus==422) {
          commit("setErrors", error.res.messages) 
        } else {
          commit("setError", "Something went wrong, please try again!") 
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        } */
      } finally {
        commit("setLoading", false);
      }
    },

    async doVerify({ commit, rootState }, data) {
      commit("setVerifyOtpLoading", true);

      try {
        const response = await fetch(url + "/otp_verify", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
          body: data,
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          const accountInfo = rootState.accountInfo;

          // console.log('Current ', accountInfo)

          let newAccountInfo = { ...accountInfo };
          newAccountInfo.login_verified = true;

          // console.log('New ', newAccountInfo)

          commit("setAccountInfo", newAccountInfo, { root: true });
          localStorage.setItem("accountInfo", JSON.stringify(newAccountInfo));

          commit("setVerifyOtpError", null);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if ([404, 403].includes(errorStatus)) {
          commit("setVerifyOtpError", error.res.messages.error);
          commit("setPopUpError", error.res.messages.error, { root: true });
        } else if (errorStatus == 422) {
          commit("setVerifyOtpError", error.res.messages.otp);
          commit("setPopUpError", error.res.messages.otp, { root: true });
        } else {
          commit(
            "setVerifyOtpError",
            "Something went wrong, please try again!"
          );
          commit("setPopUpError", "Something went wrong, please try again!", {
            root: true,
          });
        }
      } finally {
        commit("setVerifyOtpLoading", false);
      }
    },

    async doRequest({ commit, rootState }) {
      commit("setSendOtpLoading", true);

      try {
        const response = await fetch(url + "/otp", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setSendOtpError", null);
        }
      } catch (error) {
        commit("setSendOtpError", "Something went wrong, please try again!");
        commit("setPopUpError", "Something went wrong, please try again!", {
          root: true,
        });
      } finally {
        commit("setSendOtpLoading", false);
      }
    },

    async requestLoginOTP({ commit }, data) {
      commit("setSendOtpError", null);
      commit("setSendOtpLoading", true);
      commit("setIsSuccessResponse", true)

      try {
        const response = await fetch(url + "/request_otp", {
          method: "POST",
          headers: {
            Authorization: "Basic YWRtaW5VbkF1dGg6SEpDTGFzY2FzYXNHYW1pbmdAMjAyMiFAQWRtaW5VbkF1dGg9PS0=",
          },
          body: data
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        }

      } catch (error) {
        const statusCode = error.response.status;
        commit("setIsSuccessResponse", false)

        switch (statusCode) {
          case 422:
            commit("setSendOtpError", error.res.messages.mobile_number);
            break;
          case 403:
            commit("setSendOtpError", error.res.messages.error);
            break;
          default:
            commit("setRefreshError", "Something went wrong, please try again!", {
              root: true,
            });
        }

      } finally {
        commit("setSendOtpLoading", false);
      }
    },

    async otpLogin({ commit, rootState }, data) {
      commit("setLoading", true);
      commit("setError", null);
      commit("setErrors", []);
      commit("setIsSuccessResponse", true)

      try {
        const response = await fetch(url + "/otp_login", {
          method: "POST",
          headers: {
            Authorization: "Basic YWRtaW5VbkF1dGg6SEpDTGFzY2FzYXNHYW1pbmdAMjAyMiFAQWRtaW5VbkF1dGg9PS0=",
          },
          body: data
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        }

        // Reset all main states
        commit("resetStates", "", { root: true });
        commit("setUserData", jwtdecode(res.userData), { root: true });
        commit("setUserToken", res.userData, { root: true });
        localStorage.setItem("userToken", res.userData);
        localStorage.setItem(
          "mobile_number",
          rootState.userData.mobile_number
        );

        commit("setAccountInfo", res.accountInfo, { root: true });
        localStorage.setItem("accountInfo", JSON.stringify(res.accountInfo));

        return res.ladingPage;

      } catch (error) {
        const statusCode = error.response.status;
        commit("setIsSuccessResponse", false)

        switch (statusCode) {
          case 422:
            commit("setErrors", error.res.messages);
            break;
          case 403:
            commit("setError", error.res.messages.error);
            break;
          default:
            commit("setRefreshError", "Something went wrong, please try again!", {
              root: true,
            });
        }
      } finally {
        commit("setLoading", false);
      }

    }
  },
};
