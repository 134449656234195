<template>
  <div class="dashboard">
    <div class="pa-4 pa-md-6" style="position: relative">
      <h2 class="font-weight-bold mb-4">Dashboard</h2>

      <div
        style="position: sticky; top: 0; z-index: 3"
        :class="{ navbarScrolled: scrollPosition > 0 }"
      >
        <v-card class="shadow mb-6" style="overflow: hidden">
          <v-tabs>
            <v-tab @click="filter_statistics_by_game_id(0)" class="px-6 py-4">All</v-tab>
            <v-tab @click="filter_statistics_by_game_id(1)" class="px-6 py-4">
              Basketball Ending
            </v-tab>
            <v-tab @click="filter_statistics_by_game_id(3)" class="px-6 py-4">
              Lucky Four
            </v-tab>
          </v-tabs>
        </v-card>
      </div>

      <div class="mb-4">
        <Revenue :game_id="game_id" />
      </div>

      <div class="mb-4" v-if="accountInfo.access && accountInfo.access.includes(21)">
        <div class="bs-row bs-g-3">
          <div class="bs-col-md-6">
            <MonthRevenueStatistics :game_id="game_id" />
          </div>
          <div class="bs-col-md-6">
            <YearRevenueStatistics :game_id="game_id" />
          </div>
        </div>
      </div>

      <div class="mb-4" v-if="accountInfo.access && accountInfo.access.includes(21)">
        <Bets :game_id="game_id" />
      </div>

      <div class="mb-4" v-if="accountInfo.access && accountInfo.access.includes(21) && game_id == 0">
        <RegisteredPlayersDoughnut />
      </div>

      <div class="mb-3" v-if="accountInfo.access && accountInfo.access.includes(21) && game_id == 0">
        <div class="bs-row bs-g-3">
          <div class="bs-col-md-6">
            <CashinDaily />
          </div>
          <div class="bs-col-md-6">
            <CashinMonthly />
          </div>
        </div>
      </div>

      <div class="mb-4">
        <v-row v-if="accountInfo.access && accountInfo.access.includes(21) && game_id == 0">
          <v-col cols="12" md="6">
            <RegisteredPlayersDaily />
          </v-col>

          <v-col cols="12" md="6">
            <RegisteredPlayers />
          </v-col>
        </v-row>
      </div>

      <div class="mb-4" v-if="accountInfo.access && accountInfo.access.includes(21) && game_id == 0">
        <ActivePlayerHour />
      </div>

      <v-row v-if="accountInfo.access && accountInfo.access.includes(21) && game_id == 0">
        <v-col cols="12" md="6">
          <ActivePlayerDaily />
        </v-col>

        <v-col cols="12" md="6">
          <ActivePlayerMonthly />
        </v-col>
      </v-row>

      <v-row v-if="accountInfo.access && accountInfo.access.includes(21) && game_id == 1">
        <v-col cols="12" md="4">
          <TopGameGross />
        </v-col>
        <v-col cols="12" md="4">
          <TopBetsCombination />
        </v-col>
        <v-col cols="12" md="4">
          <TopGrossCombination />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Revenue from "@/components/dashboard/Revenue";
import Bets from "@/components/dashboard/Bets";
import RegisteredPlayersDaily from "@/components/dashboard/registered_players/RegisteredPlayersDaily";
import RegisteredPlayers from "@/components/dashboard/registered_players/RegisteredPlayers";
import ActivePlayerHour from "@/components/dashboard/active_player_statistics/ActivePlayerHour";
import ActivePlayerDaily from "@/components/dashboard/active_player_statistics/ActivePlayerDaily";
import ActivePlayerMonthly from "@/components/dashboard/active_player_statistics/ActivePlayerMonthly";
import RegisteredPlayersDoughnut from "@/components/dashboard/registered_players/RegisteredPlayersDoughnut";
import MonthRevenueStatistics from "@/components/dashboard/MonthRevenueStatistics";
import YearRevenueStatistics from "@/components/dashboard/YearRevenueStatistics";
import TopBetsCombination from "@/components/dashboard/TopBetsCombination";
import TopGrossCombination from "@/components/dashboard/TopGrossCombination";
import TopGameGross from "@/components/dashboard/TopGameGross";
import CashinDaily from "@/components/dashboard/cashin_statistics/CashinDaily";
import CashinMonthly from "@/components/dashboard/cashin_statistics/CashinMonthly";

export default {
  components: {
    Revenue,
    Bets,
    MonthRevenueStatistics,
    YearRevenueStatistics,
    TopBetsCombination,
    TopGrossCombination,
    TopGameGross,
    CashinDaily,
    CashinMonthly,
    RegisteredPlayers,
    RegisteredPlayersDaily,
    RegisteredPlayersDoughnut,
    ActivePlayerHour,
    ActivePlayerDaily,
    ActivePlayerMonthly,
  },
  data: () => ({
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    game_id: 0,
    scrollPosition: 0,
    filter: "Today",
    filters: ["Today", "This Week", "This Month", "This Year", "Custom"],
  }),
  created() {
    if (this.accountInfo.access.includes(21)) {
      this.getPlayersDashboard();
      this.doGetTotals();
    }
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("dashboard", ["totalsLoading", "totalBets", "totalBetAmount"]),
  },
  methods: {
    ...mapActions("dashboard", ["getTotals", "getPlayers"]),

    filter_statistics_by_game_id(id) {
      this.game_id = id;
    },
    async doGetTotals() {
      if (!this.totalsLoading) {
        this.$store.commit("dashboard/setTotalBetAmount", null);
        this.$store.commit("dashboard/setTotalGames", null);
        this.$store.commit("dashboard/setTotalBets", null);

        const formData = new FormData();
        formData.append("date_from", this.today);

        const getData = new URLSearchParams(formData);

        await this.getTotals(getData);
      }
    },

    async getPlayersDashboard() {
      this.$store.commit("dashboard/setRegisteredPlayer", 0);
      this.$store.commit("dashboard/setRegisteredPlayer", 0);
      this.$store.commit("dashboard/setActivePlayer", 0);

      const formData = new FormData();
      formData.append("is_first_load", true);
      formData.append("date_from", this.today);
      formData.append("date_to", this.today);

      const getData = new URLSearchParams(formData);

      await this.getPlayers(getData);
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
</script>

<style scoped>
.navbarScrolled {
  box-shadow: 0 0.125rem 0.25rem rgb(11 11 28 / 5%) !important;
}
</style>
