<template>
    <div class="d-inline-block ma-1">
        <v-btn small
            color="primary"
            outlined
            :to="{ name: 'EndingGameCards', params: { id: id } }" target="_blank"
            @click.stop="dialog = true">
            View All Cards
        </v-btn>
    </div>
</template>

<script>
export default {
    props: {
        id: {
        required: true
        }
    },
    data: () => ({
        dialog: false,
    })
}
</script>