<template>
  <div class="d-flex justify-center">
    <v-pagination
      :disabled="loading || isDisabled"
      v-model="currentPage"
      :length="totalPage"
      :total-visible="7"
    ></v-pagination>
  </div>
</template>


<script>
export default {
  props: ["storeName", "isDisabled"],

  computed: {
    loading() {
      const getterName = `${this.storeName}/getRecordsLoading`;
      return this.$store.getters[getterName];
    },
    currentPage: {
      get() {
        const getterName = `${this.storeName}/getCurrentPage`;
        return this.$store.getters[getterName];
      },
      set(val) {
        const mutationName = `${this.storeName}/setCurrentPage`;
        this.$store.commit(mutationName, val);
      },
    },
    totalPage() {
      const getterName = `${this.storeName}/getTotalPage`;
      return this.$store.getters[getterName];
    },
  },

  watch: {
    currentPage(oldVal) {
      if (oldVal) {
        this.$emit("paginatePage");
      }
    },
  },
};
</script>