<template>
    <v-dialog
        v-model="dialog"
        max-width="500">
        <v-card>
            <v-card-title class="text-h5 d-inline-block">
                Filter

                <v-btn icon absolute right @click="cancelSearch">
                    <v-icon size="large">
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text class="py-4">
                <v-form ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="proceedToSearch">

                    <v-row dense>
                        <v-col>
                            <v-menu
                            v-model="date_from_menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="date_from"
                                :rules="[rules.required, lessThanDate2]"
                                label="Date From"
                                hide-details="auto"
                                readonly
                                outlined   
                                dense
                                v-bind="attrs"
                                v-on="on"
                                @keyup="date_from_menu=true"
                                @keydown="date_from_menu=false"
                                clearable
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="date_from"
                                @input="date_from_menu = false"
                            ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row dense class="mt-4">
                        <v-col>
                            <v-menu
                                v-model="date_to_menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    v-model="date_to"
                                    :rules="[lessThanDate2]"
                                    label="Date To (Optional)"
                                    hide-details="auto"
                                    readonly
                                    outlined   
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    @keyup="date_to_menu=true"
                                    @keydown="date_to_menu=false"
                                    clearable
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date_to"
                                    @input="date_to_menu = false"
                                    :min="minDate"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>             

                    <v-row dense class="mt-3">
                        <v-col>
                            <v-btn
                                block
                                depressed
                                :disabled="!valid"
                                type="submit"
                                color="primary">
                                Search
                            </v-btn>
                        </v-col>

                        <v-col>
                            <v-btn
                                block
                                depressed
                                type="button"
                                @click="cancelSearch">
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import formRules from "@/helpers/formRules";
import { format } from 'date-fns'

export default {
    props: {
        data_filters: {
            type: Object
        }
    },

    data: () => ({
        valid: true,
        rules: formRules,
        date_from:'',
        date_from_menu: false,
        date_to: '',
        date_to_menu: false,
        data_filter: {},
        minDate: format(new Date(), 'yyyy-MM-dd')
    }),

    mounted() {
        this.date_from = this.data_filters.date_from
        this.date_to = this.data_filters.date_to
        this.filter = this.data_filters.filter_by
    },

    computed: {
        lessThanDate() { 
            if ((this.date_from) && (this.date_to)) {
                if (new Date(this.date_to) < new Date(this.date_from)) {
                    return 'Must be higher or equal than date from.'
                } 
            } 
            
            return true
        },
        lessThanDate2() { 
            if ((this.date_from) && (this.date_to)) {
                if (new Date(this.date_from) > new Date(this.date_to)) {
                return 'Must be lessthan or equal than date to.'
                }
            } 
            
            return true
        },
        dialog: {
            get: function () {
                return true;
            },
            set: function () {
                this.cancelSearch()
            },
        },
    },

    methods: {
        proceedToSearch() {
            this.data_filter = { 
                "status": "search",
                "date_from": this.date_from,
                "date_to": (this.date_to) ?? this.date_from
            }

            this.setCloseModal()
        },

        cancelSearch() {
            this.data_filter = { "status": "cancel" }
            this.setCloseModal()
        },

        setCloseModal() {
            this.$emit("setFilterCloseModal", this.data_filter)
        }
    },

    watch: {
        date_from (val) {
            if (val == '' || val == null) {
                this.date_to = ''
            }
        }
    }
}
</script>