import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueViewer from "v-viewer";
import VueHtmlToPaper from "vue-html-to-paper";

// Global Styles
import "viewerjs/dist/viewer.css";
import "./assets/bootstrap.css";
import "./assets/main.css";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  // Viewer,
  render: (h) => h(App),
}).$mount("#app");

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
  ],
};

Vue.use(VueViewer);
Vue.use(VueHtmlToPaper, options);
