<template>
  <div class="pa-4 pa-md-6">
    <v-row>
      <v-col cols="12">
        <v-card :loading="detailsLoading">
          <v-card-text class="pb-0">
            <div class="text-center title">
              {{ details.league }}

              <v-btn
                small
                color="secondary"
                @click="$router.go(-1)"
                absolute
                right
                class="pe-2 d-none d-sm-inline-block"
              >
                <v-icon left> mdi-keyboard-backspace </v-icon>
                Back
              </v-btn>

              <v-btn
                icon
                color="secondary"
                @click="$router.go(-1)"
                absolute
                right
                class="d-inline-block d-sm-none"
              >
                <v-icon size="large"> mdi-keyboard-backspace </v-icon>
              </v-btn>
            </div>
          </v-card-text>

          <v-card-title>
            <v-row justify="center" align="center">
              <v-col cols="auto" class="text-center" align-self="center">
                <v-avatar size="100px" tile>
                  <v-img :src="details.team_a_logo">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <div>
                  <span class="text-caption"
                    >{{ details.team_a_name }} {{ details.team_a_alias }}</span
                  >
                </div>
              </v-col>

              <v-col cols="auto" class="text-center">
                <span class="text-overline my-2 mx-2 font-italic">vs</span>
              </v-col>

              <v-col cols="auto" class="text-center">
                <v-avatar size="100px" tile>
                  <v-img :src="details.team_b_logo">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <div>
                  <span class="text-caption"
                    >{{ details.team_b_name }} {{ details.team_b_alias }}</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <p class="mb-1 overline primary--text">Game Details</p>

                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody class="table-borderless">
                      <tr>
                        <td style="width: 30%" class="grey--text">Schedule</td>

                        <td>
                          <span>{{ formattedSchedule }}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="grey--text">Status</td>

                        <td>
                          <span>{{ details.status || "-" }}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="grey--text">Created At</td>

                        <td>
                          <span>{{ formattedCreatedAt }}</span>
                        </td>
                      </tr>

                      <tr>
                        <td style="width: 30%" class="grey--text">Bet Count</td>
                        <td>
                          {{ parseInt(details.bet_count) | numberWithCommas }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <p
                  class="mb-1 mt-3 overline primary--text"
                  v-if="accountInfo.access.includes(10)"
                >
                  Settings
                </p>

                <v-simple-table dense v-if="accountInfo.access.includes(10)">
                  <template v-slot:default>
                    <tbody class="table-borderless">
                      <tr v-if="accountInfo.access.includes(71)">
                        <td style="width: 30%" class="grey--text">
                          Power Ball
                        </td>

                        <td>
                          <SettingsPowerBall
                            v-if="details"
                            :compData="{
                              id: id,
                              is_power_ball: details.is_power_ball,
                            }"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>

              <v-col cols="12" md="4">
                <v-row>
                  <v-col>
                    <p class="mb-1 mt-0 md-mt-3 overline primary--text">
                      Result
                    </p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="2" class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="secondary"
                          v-bind="attrs"
                          v-on="on"
                          class="mr-1"
                          @click="getDetails(id)"
                        >
                          <v-icon>mdi-cached</v-icon>
                        </v-btn>
                      </template>
                      <span>Refresh</span>
                    </v-tooltip>
                  </v-col>
                </v-row>

                <v-simple-table dense class="table-bordered">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-uppercase text-center">Quarter</th>
                        <th class="text-uppercase text-center">Result</th>
                      </tr>
                    </thead>
                    <tbody v-if="details.results && details.results.length">
                      <tr
                        v-for="item in details.results"
                        :key="item.quarter_id"
                      >
                        <td class="text-center">
                          {{ item.quarter }}
                        </td>
                        <td class="text-center">
                          {{ item.combination }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td
                          class="text-center text-caption grey--text"
                          colspan="5"
                        >
                          No result yet
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mt-6">
          <v-card-text>
            <v-row>
              <v-col>
                <p class="ma-0 overline primary--text">Prizes</p>
              </v-col>
            </v-row>
            <v-simple-table dense class="table-bordered">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-uppercase text-center" v-if="$store.state.accountInfo.access.includes(76)">Action</th>
                    <th class="text-uppercase text-center">Initial Amount</th>
                    <th class="text-uppercase text-center">Amount Added</th>
                    <th class="text-uppercase text-center">Two Digits Prize</th>
                    <th class="text-uppercase text-center">
                      Three Digits Prize
                    </th>
                    <th class="text-uppercase text-center">Jackpot Prize</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center" v-if="$store.state.accountInfo.access.includes(76)">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :disabled="disableRefreshJP"
                            icon
                            small
                            color="primary"
                            @click="confirmDialog = true"
                          >
                            <v-icon size="large">
                              mdi-database-refresh-outline
                            </v-icon>
                          </v-btn>
                        </template>

                        <span>Refresh Jackpot Prize</span>
                      </v-tooltip>
                    </td>
                    <td class="text-center">
                      ₱{{ moneyFormat(parseFloat(details.initial_amount)) }}
                    </td>
                    <td class="text-center">
                      ₱{{ moneyFormat(parseFloat(details.amount_added)) }}
                    </td>
                    <td class="text-center">
                      ₱{{ moneyFormat(parseFloat(details.two_digits_prize)) }}
                    </td>
                    <td class="text-center">
                      ₱{{ moneyFormat(parseFloat(details.three_digits_prize)) }}
                    </td>
                    <td class="text-center">
                      ₱{{ moneyFormat(parseFloat(details.jackpot_prize)) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>

        <Winners
          class="mt-6"
          v-if="$store.state.accountInfo.access.includes(72)"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="confirmDialog" max-width="360">
      <v-card class="py-2">
        <v-card-title class="text-h6 d-block text-center">
          Refresh Jackpot Prize
        </v-card-title>
        <v-card-text class="text-center">
          Are you sure you want to refresh jackpot prize? This action cannot be
          undone.
        </v-card-text>

        <v-card-actions class="d-block text-center">
          <v-btn
            ref="yes"
            color="primary"
            outlined
            small
            @click="refreshJackpotPrize()"
            :loading="loading"
          >
            Yes
          </v-btn>

          <v-btn color="grey" outlined small @click="confirmDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formatDate from "@/helpers/formatDate";
import SettingsPowerBall from "@/components/power_ball/EnablePowerBallBtn";
import Winners from "@/components/power_ball/Winners";
import responsePost from "@/helpers/api_request_post";

export default {
  components: {
    SettingsPowerBall,
    Winners,
  },

  props: {
    id: {
      required: true,
    },
  },

  data: () => ({
    confirmDialog: false,
    loading: false,
    // dialog: false,
    // emailSent: 0,
    // result_data: {},
  }),

  created() {
    if (!this.detailsLoading) {
      this.$store.commit("power_ball_game_details/setDetails", {});
      this.getDetails(this.id);
    }
  },

  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("power_ball_game_details", [
      "details",
      "detailsLoading",
      //   "generatePdfError",
      //   "generatePdfLoading",
      //   "sendGeneratePdfError",
      //   "sendGeneratePdfLoading",
    ]),

    formattedSchedule() {
      return formatDate(this.details.schedule, true);
    },

    formattedCreatedAt() {
      return formatDate(this.details.created_at, true);
    },

    disableRefreshJP() {
      if (this.loading || this.details.game_status_id) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions("power_ball_game_details", [
      "getDetails",
      //   "generateCardsPdf",
      //   "sendGenerateCardsPdf",
    ]),

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    async refreshJackpotPrize() {
      this.loading = true;
      this.confirmDialog = false;
      const form_data = new FormData();
      form_data.append("game_id", this.id);

      const response = await responsePost(
        form_data,
        "power_ball/games/refresh_jackpot_prize"
      );

      switch (response.status) {
        case 200:
          this.details.amount_added = response.data.amountToAdd;
          this.details.jackpot_prize = response.data.updatedPrize;
          break;
        case 401:
          alert("Something went wrong, please inform I.T. Support.");
          break;
      }

      this.loading = false;
    },

    // generatePdf() {
    //   const text = "Are you sure you want to generate?";
    //   if (confirm(text) == true) {
    //     this.doGeneratePdf();
    //   }
    // },

    // async doGeneratePdf() {
    //   const formData = new FormData();
    //   formData.append("game_id", this.id);

    //   if (!this.generatePdfLoading) {
    //     const res = await this.generateCardsPdf(formData);

    //     if (!this.generatePdfError) {
    //       this.details.generated_pdf_cards =
    //         parseInt(this.details.generated_pdf_cards) + res.generated;
    //     }
    //   }
    // },

    // sendGeneratePdf() {
    //   const text = "Are you sure you want to send email?";
    //   if (confirm(text) == true) {
    //     this.doSendGeneratePdf();
    //   }
    // },

    // async doSendGeneratePdf() {
    //   const formData = new FormData();
    //   formData.append("game_id", this.id);

    //   if (!this.sendGeneratePdfLoading) {
    //     await this.sendGenerateCardsPdf(formData);

    //     if (!this.sendGeneratePdfError) {
    //       this.emailSent = 1;

    //       setTimeout(() => {
    //         this.emailSent = 0;
    //       }, 1000);
    //     }
    //   }
    // },
  },

  filters: {
    numberWithCommas(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>