<template>
	<div class="pa-4 pa-md-6">
		<div class="grey--text" style="font-size: 13px">Settings</div>
		<h2 class="font-weight-bold mb-3 mb-md-5">Users</h2>
		<v-card class="shadow">
			<v-card-title>
				<v-row justify="space-between">
					<v-col cols="12" sm="6" md="4" xl="2" align-self="end">
						<v-tooltip top v-if="userData.uid == 76">
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon color="primary" v-bind="attrs" v-on="on" :to="{ name: 'UsersCreate' }">
									<v-icon>mdi-plus-box-outline</v-icon>
								</v-btn>
							</template>

							<span>Create User</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon color="secondary" @click="getUsers" v-bind="attrs" v-on="on" class="mr-1">
									<v-icon>mdi-cached</v-icon>
								</v-btn>
							</template>
							<span>Refresh</span>
						</v-tooltip>
					</v-col>
					<v-col cols="12" sm="6" md="4" xl="3">
						<v-text-field
							v-model="search"
							prepend-inner-icon="mdi-magnify"
							placeholder="Search"
							single-line
							hide-details="auto"
							:disabled="loading"
							clearable
							outlined
							dense>
						</v-text-field>
					</v-col>
				</v-row>
			</v-card-title>
			<v-divider></v-divider>
			<v-card-text class="pa-0">
				<v-data-table
					:headers="headers"
					:items="users"
					:items-per-page="5"
					:search="search"
					:loading="loading"
					loading-text="Loading, please wait a moment"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc">
					<template v-slot:[`item.action`]="{ item }" v-if="userData.uid == 76">
						<!-- <div class="text-center d-flex align-center justify-space-around"> -->
						<v-tooltip right>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									dense
									v-bind="attrs"
									v-on="on"
									icon
									small
									color="primary"
									:to="{ name: 'UsersUpdate', params: { user_id: item.user_id } }">
									<v-icon size="large"> mdi-square-edit-outline </v-icon>
								</v-btn>
							</template>
							<span>Update</span>
						</v-tooltip>

						<v-tooltip right>
							<template v-slot:activator="{ on, attrs }">
								<v-btn dense v-bind="attrs" v-on="on" icon small color="primary" @click="setUserId(item.user_id)">
									<v-icon size="large"> mdi-key-change </v-icon>
								</v-btn>
							</template>
							<span>Reset Password</span>
						</v-tooltip>
						<!-- </div> -->
					</template>

					<!-- <template v-slot:[`item.reset_password`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  color="primary"
                  @click="setUserId(item.user_id)">
                  <v-icon size="large"> mdi-key-change </v-icon>
                </v-btn>
              </template>
              <span>Reset Password</span>
            </v-tooltip>
          </template> -->

					<template v-slot:[`item.logo`]="{ item }">
						<div>
							<v-avatar size="36px" tile>
								<v-img :src="item.logo">
									<template v-slot:placeholder>
										<v-row class="fill-height ma-0" align="center" justify="center">
											<v-progress-circular indeterminate color="black"></v-progress-circular>
										</v-row>
									</template>
								</v-img>
							</v-avatar>
						</div>
					</template>

					<template v-slot:[`item.mobile_number`]="{ item }">
						<span>+63{{ item.mobile_number }}</span>
					</template>

					<template v-slot:[`item.created_at`]="{ item }">
						<span>{{ setCreatedAtFormat(item.created_at) }}</span>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>

		<FormDialog v-if="dialogStatus" :usersData="usersData" @setData="setData" />
		<ResetPassword v-if="changePasswordDialogStatus" :userId="id" @successChange="changePasswordDialogStatus = false" />
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import formatDatev2 from '@/helpers/formatDatev2'
import FormDialog from '@/components/ending/users/FormDialog'
import ResetPassword from '@/components/ending/users/ResetPassword'

export default {
	components: {
		FormDialog,
		ResetPassword,
	},
	data: () => ({
		dialogStatus: false,
		changePasswordDialogStatus: false,
		search: '',
		sortBy: 'user_id',
		sortDesc: true,
		usersData: {},
	}),
	created() {
		this.getUsers()
		// this.getUserAccess();
	},
	computed: {
		...mapState(['userData']),
		...mapState('ending_users', ['users', 'loading', 'error', 'errors']),

		headers() {
			const headers = [
				// { text: "ID", value: "user_id", align: "left" },
				{
					text: 'First Name',
					sortable: false,
					value: 'first_name',
					class: 'text-uppercase',
				},
				{
					text: 'Middle Name',
					sortable: false,
					value: 'middle_name',
					class: 'text-uppercase',
				},
				{
					text: 'Last Name',
					sortable: false,
					value: 'last_name',
					class: 'text-uppercase',
				},
				{
					text: 'Mobile Number',
					sortable: false,
					value: 'mobile_number',
					class: 'text-uppercase',
				},
				{
					text: 'Email Address',
					sortable: false,
					value: 'email_address',
					class: 'text-uppercase',
				},
				{
					text: 'BirthDate',
					sortable: false,
					value: 'birthdate',
					class: 'text-uppercase',
				},
				{
					text: 'Created At',
					value: 'created_at',
					class: 'text-uppercase',
				},
			]

			if (this.userData?.uid == 76) {
				headers.unshift({
					text: 'Action',
					sortable: false,
					value: 'action',
					class: 'text-uppercase',
					align: 'center',
				})
			}

			return headers
		},
	},
	methods: {
		...mapActions('ending_users', ['getUsers']),
		createUser() {
			this.dialogStatus = true
			this.usersData = {}
		},
		setUserId(user_id) {
			this.changePasswordDialogStatus = true
			this.id = user_id
		},
		setData(insertedData, updatedData) {
			this.dialogStatus = false

			if (Object.keys(insertedData).length != 0) {
				this.$store.commit('ending_users/setUsers', [insertedData, ...this.users])
			} else if (Object.keys(updatedData).length != 0) {
				const usersData = this.users.find((user) => user.user_id == updatedData.user_id)

				usersData.user_id = updatedData.user_id
				usersData.first_name = updatedData.first_name
				usersData.middle_name = updatedData.middle_name
				usersData.last_name = updatedData.last_name
				usersData.mobile_number = updatedData.mobile_number
				usersData.email_address = updatedData.email_address
				usersData.birthdate = updatedData.birthdate
				usersData.access = updatedData.access
				usersData.is_active = updatedData.is_active
			}
		},
		setCreatedAtFormat(date) {
			return formatDatev2(date)
		},
	},
}
</script>
