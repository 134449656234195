<template>
  <div class="referral-links">
    <div class="pa-4 pa-md-6">
      <div class="grey--text" style="font-size: 13px">Investors</div>
      <h2 class="font-weight-bold mb-3 mb-md-5">Referral Links</h2>

      <v-card class="shadow">
        <div class="d-flex justify-end px-6 py-4">
          <div class="d-inline-flex bs-gap-3">
            <v-select
              v-model="link_type"
              :items="link_types"
              outlined
              dense
              hide-details="true"
              @change="getInvestorReferralLinks"
            ></v-select>
            <v-text-field
              ref="search"
              v-model="search"
              placeholder="Search"
              single-line
              hide-details="true"
              :disabled="loading"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </div>
        </div>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="records"
          :search="search"
          :loading="loading"
          loading-text="Loading, please wait a moment"
        >
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:[`item.link_type`]="{ item }">
            {{ item.link_type }}
          </template>
          <template v-slot:[`item.link`]="{ item }">
            {{ item.link }}
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Hashids from "hashids";

export default {
  data: () => ({
    headers: [
      { text: "Name", value: "name", class: "text-uppercase" },
      { text: "LInk For", value: "link_type", class: "text-uppercase" },
      { text: "Link", value: "link", class: "text-uppercase" },
    ],
    records: [],
    loading: false,
    search: null,
    items_per_page: 5,
    link_types: ["New", "Old"],
    link_type: "New",
  }),

  mounted() {
    this.getInvestorReferralLinks();
  },

  methods: {
    async getInvestorReferralLinks() {
      this.loading = true;
      this.records = [];

      try {
        let url = process.env.VUE_APP_API_URL;
        let token = localStorage.getItem("userToken");

        const res = await axios.get(
          `${url}investor/referral_links?link_type=${this.link_type}`,
          {
            headers: { Authorization: `Bearer ` + token },
          }
        );

        if (res.status == 200) {
          if (this.link_type == "New") {
            var newRecords = res.data.referral_links.map((item) => ({
              ...item,
              link_type: this.generateNewLinkFor(item.link_type),
              link: this.generateNewReferralLink(item.link_type, item.territory, item.id),
            }));

            this.records = newRecords;
          } else {
            var resRecords = res.data.referral_links;
            var oldRecords = [];
            var hashids = new Hashids("referral_code", 5);

            resRecords.forEach((record) => {
              if (record.link_type == "MA") {
                oldRecords.push({
                  name: `${record.name} (MA)`,
                  territory: record.territory,
                  id: `${record.id}-SM`,
                  link_type: "Sales Manager",
                  link: `https://partners.gamexsports.com.ph/register/${hashids.encode(
                    2
                  )}/${hashids.encode(record.id)}`,
                });
                oldRecords.push({
                  name: `${record.name} (MA)`,
                  territory: record.territory,
                  id: `${record.id}-RP`,
                  link_type: "Regular Player",
                  link: `https://gamexsports.com.ph/${hashids.encode(2)}/${hashids.encode(
                    1
                  )}/${hashids.encode(record.id)}`,
                });
              } else if (record.link_type == "SM") {
                oldRecords.push({
                  name: `${record.name} (SM)`,
                  territory: record.territory,
                  id: `${record.id}-SS`,
                  link_type: "Sales Supervisor",
                  link: `https://partners.gamexsports.com.ph/register/${hashids.encode(
                    2
                  )}/${hashids.encode(record.id)}`,
                });
                oldRecords.push({
                  name: `${record.name} (SM)`,
                  territory: record.territory,
                  id: `${record.id}-SR`,
                  link_type: "Sales Representative",
                  link: `https://gamexsports.com.ph/${hashids.encode(2)}/${hashids.encode(
                    1
                  )}/${hashids.encode(record.id)}`,
                });
                oldRecords.push({
                  name: `${record.name} (SM)`,
                  territory: record.territory,
                  id: `${record.id}-RP`,
                  link_type: "Regular Player",
                  link: `https://gamexsports.com.ph/${hashids.encode(2)}/${hashids.encode(
                    1
                  )}/${hashids.encode(record.id)}`,
                });
              } else {
                oldRecords.push({
                  name: `${record.name} (SS)`,
                  territory: record.territory,
                  id: `${record.id}-SR`,
                  link_type: "Sales Representative",
                  link: `https://gamexsports.com.ph/${hashids.encode(2)}/${hashids.encode(
                    1
                  )}/${hashids.encode(record.id)}`,
                });
                oldRecords.push({
                  name: `${record.name} (SS)`,
                  territory: record.territory,
                  id: `${record.id}-RP`,
                  link_type: "Regular Player",
                  link: `https://gamexsports.com.ph/${hashids.encode(2)}/${hashids.encode(
                    1
                  )}/${hashids.encode(record.id)}`,
                });
              }
            });

            this.records = oldRecords;
          }
        }
      } catch (error) {
        if (error.response) {
          console.error(error.response);
        } else {
          console.error(error);
        }
      }

      this.loading = false;
    },
    generateNewReferralLink(linkType, territory, id) {
      var link;

      switch (linkType) {
        case "SM":
        case "SS":
          link = `https://partners.gamexsports.com.ph/register/${territory}-${id}`;
          break;
        case "SR":
        case "RP":
          link = `https://gamexsports.com.ph/?referral_code=${territory}-${id}`;
          break;
        default:
          link = "N/A";
          break;
      }

      return link;
    },
    generateOldReferralLink(linkType, id) {
      var hashids = new Hashids("referral_code", 5);

      var link;

      switch (linkType) {
        case "SM":
        case "SS":
          link = `https://partners.gamexsports.com.ph/register/${hashids.encode(
            2
          )}/${hashids.encode(id)}`;
          break;
        case "SR":
        case "RP":
          link = `https://gamexsports.com.ph/${hashids.encode(2)}/${hashids.encode(
            1
          )}/${hashids.encode(id)}`;
          break;
        default:
          link = "N/A";
          break;
      }

      return link;
    },
    generateNewLinkFor(linkType) {
      var type;

      switch (linkType) {
        case "SM":
          type = "Sales Manager";
          break;
        case "SS":
          type = "Sales Supervisor";
          break;
        case "SR":
          type = "Sales Representative";
          break;
        case "RP":
          type = "Regular Player";
          break;
        default:
          type = "N/A";
          break;
      }

      return type;
    },
    generateOldLinkFor(linkType) {
      var type;

      switch (linkType) {
        case "SM":
          type = "Sales Manager";
          break;
        case "SS":
          type = "Sales Supervisor";
          break;
        case "SR":
          type = "Sales Representative";
          break;
        case "RP":
          type = "Regular Player";
          break;
        default:
          type = "N/A";
          break;
      }

      return type;
    },
  },
};
</script>
