<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Ending Reports</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Cards</h2>
    <v-card class="shadow mb-4">
      <v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
        CARDS REPORT
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="py-4">
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="doGetSummary">
          <v-row dense>
            <v-col cols="12" md="4" class="mt-2">
              <v-select
                v-model="game_status"
                :items="game_statuses"
                label="Game Status"
                dense
                outlined
                hide-details="auto"
              ></v-select>
            </v-col>

            <v-col cols="12" md="4" class="mt-2">
              <v-menu
                v-model="date_from_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_from"
                    :rules="[rules.required, lessThanDate]"
                    label="Scheduled Date From"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @keyup="date_from_menu = true"
                    @keydown="date_from_menu = false"
                    clearable
                  />
                </template>

                <v-date-picker
                  v-model="date_from"
                  @input="date_from_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4" class="mt-2">
              <v-menu
                v-model="date_to_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="date_from == ''"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_to"
                    label="Scheduled Date To (Optional)"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :disabled="dateToAvailability"
                    @keyup="date_to_menu = true"
                    @keydown="date_to_menu = false"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_to"
                  @input="date_to_menu = false"
                  :min="date_from ? date_from : ''"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row dense class="mt-2">
            <v-col>
              <v-btn depressed type="submit" color="primary" :loading="loading">
                Search
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="shadow">
      <v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
        <v-row justify="space-between" align="center">
          <v-col> SUMMARY </v-col>

          <v-col align="end">
            <v-row dense justify="end">
              <downloadexcel
                :data="records"
                :fields="{
                  Type: 'type',
                  Bets: 'bets',
                  Cards: 'cards',
                  Bets: 'bets',
                  'Gross Sales': 'bets_amount',
                  Payout: 'payout',
                  'Gross Gaming Revenue': 'net_amount',
                  'PAGCOR Share': 'pagcor_share',
                  'Net Amount': 'net_income',
                }"
                name="Cards Report.xls"
                type="csv"
                class="d-inline-block"
              >
                <v-btn outlined color="primary" class="mr-2 my-1"> Export CSV </v-btn>
              </downloadexcel>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="py-4">
        <v-row dense>
          <v-col align="center" cols="12" md="2">
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL CARDS</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                {{ parseInt(cardsTotal).toLocaleString() }}
              </p>
            </v-card>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col align="center" cols="12" md="2" class="py-4">
            <!-- <v-card>
                            <v-card-title class="font-weight-regular pa-0 overline justify-center">
                                TOTAL BETS
                            </v-card-title>

                            <v-card-text class="text-h6 py-2 text-center">
                                {{ parseInt(betsTotal).toLocaleString() }}
                            </v-card-text>
                        </v-card> -->
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL BETS</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                {{ parseInt(betsTotal).toLocaleString() }}
              </p>
            </v-card>
          </v-col>

          <v-col align="center" cols="12" md="2" class="py-4">
            <!-- <v-card>
                            <v-card-title class="font-weight-regular pa-0 overline justify-center">
                                TOTAL GROSS AMOUNT
                            </v-card-title>

                            <v-card-text class="text-h6 py-2 text-center">
                                PHP {{ moneyFormat(parseFloat(grossAmountTotal)) }}
                            </v-card-text>
                        </v-card> -->
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL GROSS AMOUNT</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                PHP {{ moneyFormat(parseFloat(grossAmountTotal)) }}
              </p>
            </v-card>
          </v-col>

          <v-col align="center" cols="12" md="2" class="py-4">
            <!-- <v-card>
                            <v-card-title class="font-weight-regular pa-0 overline justify-center">
                                TOTAL PAYOUT
                            </v-card-title>

                            <v-card-text class="text-h6 py-2 text-center">
                                <span class="red--text">PHP {{ moneyFormat(parseFloat(payoutTotal)) }}</span> 
                            </v-card-text>
                        </v-card> -->
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL PAYOUT</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium red--text d-inline-flex">
                PHP {{ moneyFormat(parseFloat(payoutTotal)) }}
              </p>
              <!-- <small> ({{ moneyFormat(parseFloat(setPercentage(payoutTotal, grossAmountTotal))) }})%</small> -->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <small v-bind="attrs" v-on="on">
                    {{
                      moneyFormat(
                        parseFloat(setPercentage(payoutTotal, grossAmountTotal))
                      )
                    }}%</small
                  >
                </template>
                <span
                  >{{
                    moneyFormat(parseFloat(setPercentage(payoutTotal, grossAmountTotal)))
                  }}% of Total Gross</span
                >
              </v-tooltip>
            </v-card>
          </v-col>

          <v-col align="center" cols="12" md="2" class="py-4">
            <!-- <v-card>
                            <v-card-title class="font-weight-regular pa-0 overline justify-center">
                                TOTAL NET AMOUNT
                            </v-card-title>

                            <v-card-text class="text-h6 py-2 text-center">
                                PHP {{ moneyFormat(parseFloat(netAmountTotal)) }}
                            </v-card-text>
                        </v-card> -->
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL NET AMOUNT</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium d-inline-flex">
                PHP {{ moneyFormat(parseFloat(netAmountTotal)) }}
              </p>
              <!-- <small> ({{ moneyFormat(parseFloat(setPercentage(netAmountTotal, grossAmountTotal))) }})%</small> -->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <small v-bind="attrs" v-on="on">
                    {{
                      moneyFormat(
                        parseFloat(setPercentage(netAmountTotal, grossAmountTotal))
                      )
                    }}%</small
                  >
                </template>
                <span
                  >{{
                    moneyFormat(
                      parseFloat(setPercentage(netAmountTotal, grossAmountTotal))
                    )
                  }}% of Total Gross</span
                >
              </v-tooltip>
            </v-card>
          </v-col>

          <v-col align="center" cols="12" md="2" class="py-4">
            <!-- <v-card>
                            <v-card-title class="font-weight-regular pa-0 overline justify-center">
                                TOTAL PAGCOR SHARE
                            </v-card-title>

                            <v-card-text class="text-h6 py-2 text-center">
                                <span class="red--text">PHP {{ moneyFormat(parseFloat(pagcorShareTotal)) }}</span>
                            </v-card-text>
                        </v-card> -->
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL PAGCOR SHARE</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium red--text d-inline-flex">
                PHP {{ moneyFormat(parseFloat(pagcorShareTotal)) }}
              </p>
              <!-- <small> ({{ moneyFormat(parseFloat(setPercentage(pagcorShareTotal, grossAmountTotal))) }})%</small> -->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <small v-bind="attrs" v-on="on">
                    {{
                      moneyFormat(
                        parseFloat(setPercentage(pagcorShareTotal, grossAmountTotal))
                      )
                    }}%</small
                  >
                </template>
                <span
                  >{{
                    moneyFormat(
                      parseFloat(setPercentage(pagcorShareTotal, grossAmountTotal))
                    )
                  }}% of Total Gross</span
                >
              </v-tooltip>
            </v-card>
          </v-col>

          <v-col align="center" cols="12" md="2" class="py-4">
            <!-- <v-card>
                            <v-card-title class="font-weight-regular pa-0 overline justify-center">
                                TOTAL NET INCOME
                            </v-card-title>

                            <v-card-text class="text-h6 py-2 text-center">
                                <span :class="(netIncomeTotal >= 0) ? 'green--text' : 'red--text'">PHP {{ moneyFormat(parseFloat(netIncomeTotal)) }}</span>
                            </v-card-text>
                        </v-card> -->

            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL NET INCOME</p>
              <v-divider class="mb-4" />
              <p
                class="font-weight-medium d-inline-flex"
                :class="netIncomeTotal >= 0 ? 'green--text' : 'red--text'"
              >
                PHP {{ moneyFormat(parseFloat(netIncomeTotal)) }}
              </p>
              <!-- <small> ({{ moneyFormat(parseFloat(setPercentage(netIncomeTotal, grossAmountTotal))) }})%</small> -->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <small v-bind="attrs" v-on="on">
                    {{
                      moneyFormat(
                        parseFloat(setPercentage(netIncomeTotal, grossAmountTotal))
                      )
                    }}%</small
                  >
                </template>
                <span
                  >{{
                    moneyFormat(
                      parseFloat(setPercentage(netIncomeTotal, grossAmountTotal))
                    )
                  }}% of Total Gross</span
                >
              </v-tooltip>
            </v-card>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="records"
          :search="search"
          :loading="loading"
          loading-text="Loading, please wait a moment"
          class="mt-4"
          :hide-default-footer="true"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dense
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  color="primary"
                  @click="setCardsDialog(item.type)"
                  class="ma-1"
                >
                  <v-icon size="large"> mdi-basketball </v-icon>
                </v-btn>
              </template>
              <span>Show Per Game</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.bets`]="{ item }">
            <span>{{ parseInt(item.bets).toLocaleString() }} </span>
          </template>

          <template v-slot:[`item.bets_amount`]="{ item }">
            <span>PHP {{ moneyFormat(parseFloat(item.bets_amount)) }} </span>
          </template>

          <template v-slot:[`item.cards`]="{ item }">
            <span>{{ parseInt(item.cards).toLocaleString() }} </span>
          </template>

          <template v-slot:[`item.payout`]="{ item }">
            <span class="red--text">PHP {{ moneyFormat(parseFloat(item.payout)) }} </span>
          </template>

          <template v-slot:[`item.net_amount`]="{ item }">
            <span>PHP {{ moneyFormat(parseFloat(item.net_amount)) }} </span>
          </template>

          <template v-slot:[`item.pagcor_share`]="{ item }">
            <span class="red--text"
              >PHP {{ moneyFormat(parseFloat(item.pagcor_share)) }}
            </span>
          </template>

          <template v-slot:[`item.net_income`]="{ item }">
            <span :class="item.net_income >= 0 ? 'green--text' : 'red--text'"
              >PHP {{ moneyFormat(parseFloat(item.net_income)) }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <CardDialog
      v-if="cards_dialog"
      :cards_details="cards_details"
      @setCardsDialogCloseModal="setCardsDialogCloseModal"
    />
  </div>
</template>

<script>
import downloadexcel from "vue-json-excel";
import formRules from "@/helpers/formRules";
import { mapActions, mapState } from "vuex";
import CardDialog from "@/components/reports/CardsDialog";
import GetPercentage from "@/helpers/getPercentage";

export default {
  components: {
    downloadexcel,
    CardDialog,
  },

  data: () => ({
    valid: true,
    search: "",
    rules: formRules,
    date_from: null,
    date_to: null,
    date_from_menu: false,
    date_to_menu: false,
    game_status: "All",
    game_statuses: ["All", "Not Started", "Ongoing", "Finished"],
    cards_dialog: false,
    cards_details: {},
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      {
        text: "Type",
        value: "type",
        class: "text-uppercase",
        align: "left",
      },
      {
        text: "Cards",
        value: "cards",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Bets",
        value: "bets",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Gross Sales",
        value: "bets_amount",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Payout",
        value: "payout",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Gross Gaming Revenue",
        value: "net_amount",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "PAGCOR Share",
        value: "pagcor_share",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Net Amount",
        value: "net_income",
        class: "text-uppercase",
        align: "right",
      },
    ],
  }),

  created() {
    this.$store.commit("ending_reports_cards/setRecords", []);

    const urlQuery = this.$route.query;

    if (Object.keys(urlQuery).length) {
      if (Object.prototype.hasOwnProperty.call(urlQuery, "game_status"))
        this.game_status = urlQuery.game_status;
      if (Object.prototype.hasOwnProperty.call(urlQuery, "date_from"))
        this.date_from = urlQuery.date_from;
      if (Object.prototype.hasOwnProperty.call(urlQuery, "date_to"))
        this.date_to = urlQuery.date_to;

      this.doLoadRecords();
    }
  },

  computed: {
    ...mapState("ending_reports_cards", ["loading", "records"]),

    lessThanDate() {
      if (this.date_from && this.date_to) {
        if (new Date(this.date_to) < new Date(this.date_from)) {
          return "Must be higher or equal than date from.";
        }
      }

      return true;
    },

    dateToAvailability() {
      if (this.date_from == "" || this.date_from == null) {
        return true;
      }

      return false;
    },

    cardsTotal() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseInt(obj.cards) + total, 0);
      } else {
        return 0;
      }
    },

    betsTotal() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseInt(obj.bets) + total, 0);
      } else {
        return 0;
      }
    },

    grossAmountTotal() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.bets_amount) + total,
          0
        );
      } else {
        return 0;
      }
    },

    payoutTotal() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseFloat(obj.payout) + total, 0);
      } else {
        return 0;
      }
    },

    netAmountTotal() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseFloat(obj.net_amount) + total, 0);
      } else {
        return 0;
      }
    },

    pagcorShareTotal() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.pagcor_share) + total,
          0
        );
      } else {
        return 0;
      }
    },

    netIncomeTotal() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseFloat(obj.net_income) + total, 0);
      } else {
        return 0;
      }
    },
  },

  methods: {
    ...mapActions("ending_reports_cards", ["getRecords"]),

    async doLoadRecords() {
      await this.getRecords(this.setFormData());
    },

    async doGetSummary() {
      if (this.$refs.form.validate()) {
        if (!this.loading) {
          this.$store.commit("ending_reports_cards/setRecords", []);

          await this.getRecords(this.setFormData());

          this.setQueryString();
        }
      }
    },

    setQueryString() {
      const urlQuery = this.$route.query;

      if (
        urlQuery.game_status != this.game_status ||
        urlQuery.date_from != this.date_from ||
        urlQuery.date_to != this.date_to
      ) {
        this.query_filters = {};

        if (this.game_status != null)
          this.query_filters["game_status"] = this.game_status;
        if (this.date_from != null) this.query_filters["date_from"] = this.date_from;
        if (this.date_to != null) this.query_filters["date_to"] = this.date_to;

        this.$router.push({
          name: this.$route.name,
          query: this.query_filters,
        });
      }
    },

    setFormData() {
      const formData = new FormData();

      formData.append("game_status", this.game_status);
      formData.append("date_from", this.date_from);
      formData.append("date_to", this.date_to ?? this.date_from);

      const getData = new URLSearchParams(formData);

      return getData;
    },

    setCardsDialog(game_type) {
      this.cards_details = {};

      if (this.game_status != null) this.cards_details["game_status"] = this.game_status;
      if (this.date_from != null) this.cards_details["date_from"] = this.date_from;
      if (this.date_to != null) this.cards_details["date_to"] = this.date_to;
      this.cards_details["game_type"] = game_type;

      this.cards_dialog = true;
    },

    setCardsDialogCloseModal() {
      this.cards_dialog = false;
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    setPercentage(val, dividend) {
      if (val != 0 && dividend != 0) {
        return GetPercentage(val, dividend);
      }

      return 0;
    },
  },

  watch: {
    date_from(val) {
      if (val == "" || val == null) {
        this.date_to = "";
      }
    },
  },
};
</script>
