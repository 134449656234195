const url = process.env.VUE_APP_API_URL + "power_ball/games"

export default {
  namespaced: true,

  state: {
    details: {},
    detailsLoading: false,
    enableDisableError: null,
    enableDisableLoading: false,
    // refreshJackpotPrizeLoading: false,
    // luckyFourPrizes: {},
    // generatePdfError: null,
    // generatePdfLoading: false,
    // sendGeneratePdfError: null,
    // sendGeneratePdfLoading: false,
  },
  mutations: {
    setDetails(state, val) {
      state.details = val
    },

    setDetailsLoading(state, val) {
      state.detailsLoading = val
    },

    setEnableDisableError(state, val) {
      state.enableDisableError = val
    },

    setEnableDisableLoading(state, val) {
      state.enableDisableLoading = val
    },

    // setRefreshJackpotPrizeLoading(state, val) {
    //   state.refreshJackpotPrizeLoading = val
    // },

    // setLuckyFourPrizes(state, val) {
    //   state.luckyFourPrizes = val
    // },

    // setGeneratePdfError(state, val) {
    //   state.generatePdfError = val
    // },

    // setGeneratePdfLoading(state, val) {
    //   state.generatePdfLoading = val
    // },

    // setSendGeneratePdfError(state, val) {
    //   state.sendGeneratePdfError = val
    // },

    // setSendGeneratePdfLoading(state, val) {
    //   state.sendGeneratePdfLoading = val
    // },
  },

  actions: {
    async getDetails({ commit, rootState }, data) {
      commit("setDetailsLoading", true)

      try {
        const response = await fetch(`${url}/details/${data}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setDetails", res.details)
        }
      } catch (error) {
        const errorStatus = error.response.status

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setDetailsLoading", false)
      }
    },

    async enableDisable({ commit, rootState }, data) {
      commit("setEnableDisableLoading", true)

      try {
        const response = await fetch(`${url}/enable_disable`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setEnableDisableError", null)
        }
      } catch (error) {
        const errorStatus = error.response.status

        commit("setEnableDisableError", "Error") // Decoy error to detect success

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus == 417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setEnableDisableLoading", false)
      }
    },

    // async doRefreshJackpotPrize({ commit, rootState }, data) {
    //   commit("setRefreshJackpotPrizeLoading", true)

    //   try {
    //     const response = await fetch(`${url}/refresh_jackpot_prize`, {
    //       method: 'POST',
    //       headers: {
    //         'Authorization': `Bearer ${rootState.userToken}`,
    //       },
    //       body: data
    //     });

    //     const res = await response.json()

    //     if (!response.ok) {
    //       throw { response, res }
    //     } else {
    //       commit("setLuckyFourPrizes", res.lucky_four_prizes);
    //       alert('Refreshed Successfully.')
    //     }
    //   } catch (error) {
    //     const errorStatus = error.response.status

    //     if (errorStatus == 401) {
    //       commit("setError", "Unauthorized", { root: true })
    //     } else if (errorStatus == 417) {
    //       commit("setPopUpError", error.res.error, { root: true })
    //     } else {
    //       commit("setRefreshError", "An error occurred, please try again!", { root: true })
    //     }
    //   } finally {
    //     commit("setRefreshJackpotPrizeLoading", false)
    //   }
    // },

    // async generateBetsPdf({ commit, rootState }, data) {
    //   commit("setGeneratePdfLoading", true)

    //   try {
    //     const response = await fetch(`${url}/generate_pdf`, {
    //       method: 'POST',
    //       headers: {
    //         'Authorization': `Bearer ${rootState.userToken}`,
    //       },
    //       body: data
    //     });

    //     const res = await response.json()

    //     if (!response.ok) {
    //       throw { response, res }
    //     } else {
    //       commit("setGeneratePdfError", null)

    //       return res
    //     }
    //   } catch (error) {
    //     const errorStatus = error.response.status

    //     commit("setGeneratePdfError", "Error") // Decoy error to detect success

    //     if (errorStatus == 401) {
    //       commit("setError", "Unauthorized", { root: true })
    //     } else if (errorStatus == 417) {
    //       commit("setPopUpError", error.res.error, { root: true })
    //     } else {
    //       commit("setRefreshError", "An error occurred, please try again!", { root: true })
    //     }
    //   } finally {
    //     commit("setGeneratePdfLoading", false)
    //   }
    // },

    // async sendGenerateBetsPdf({ commit, rootState }, data) {
    //   commit("setSendGeneratePdfLoading", true)

    //   try {
    //     const response = await fetch(`${url}/email_generated_pdf` , {
    //       method: 'POST',
    //       headers: {
    //         'Authorization': `Bearer ${rootState.userToken}`,
    //       },
    //       body: data
    //     });

    //     const res = await response.json()

    //     if (!response.ok) {
    //       throw { response, res }
    //     } else {
    //       commit("setSendGeneratePdfError", null)
    //     }
    //   } catch(error) {
    //     const errorStatus = error.response.status

    //     commit("setSendGeneratePdfError", "Error") // Decoy error to detect success

    //     if (errorStatus==401) {
    //       commit("setError", "Unauthorized", { root: true })
    //     } else if (errorStatus==417) {
    //       commit("setPopUpError", error.res.error, { root: true })
    //     } else {
    //       commit("setRefreshError", "An error occurred, please try again!", { root: true })
    //     }
    //   } finally {
    //     commit("setSendGeneratePdfLoading", false)
    //   }
    // },
  }
}