var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Investors")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Special Commissions List")]),_c('v-card',{staticClass:"shadow-none"},[_c('v-card-text',{staticClass:"d-flex flex-column",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"0.3rem"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('downloadexcel',{staticClass:"d-inline-block",attrs:{"data":_vm.records,"fields":{
                  Agents: 'agent',
                  Items: 'items',
                  Commissions: 'amount',
                  Status: 'status',
                  'Process Date': {
                    callback: function (value) {
                      return _vm.format_date(value.process_date);
                    },
                  },
                },"name":_vm.downloadExcelName(),"type":"csv"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"grey lighten-5",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-delimited-outline")])],1)],1)]}}])},[_c('span',[_vm._v("Export to Excel")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey lighten-5",attrs:{"icon":""},on:{"click":_vm.doGetSpecialCommissions}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1),(_vm.accountInfo)?_c('div',{staticClass:"d-flex justify-end",staticStyle:{"gap":"0.3rem"}},[_c('v-text-field',{attrs:{"autofocus":"","dense":"","outlined":"","label":"Search","placeholder":"Search","hide-details":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey lighten-5",attrs:{"icon":""},on:{"click":_vm.set_special_commission_filter_dialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}],null,false,1760470594)},[_c('span',[_vm._v("Filter")])])],1):_vm._e()]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"search":_vm.search,"loading":_vm.records_loading,"loading-text":"Loading, please wait a moment","items-per-page":5},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey lighten-5",attrs:{"icon":""},on:{"click":function($event){return _vm.doHandleAction(item.id, item.agent, item.status, item.amount)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.get_access == 'verifier' && item.status == 'Requested'
                      ? 'green'
                      : _vm.get_access == 'approver' && item.status == 'Verified'
                      ? 'blue'
                      : 'red'}},[_vm._v(" "+_vm._s(("mdi-" + (_vm.get_access == "verifier" && item.status == "Requested" ? "check-outline" : _vm.get_access == "approver" && item.status == "Verified" ? "check-bold" : "cancel")))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.get_access == "verifier" && item.status == "Requested" ? "Verify" : _vm.get_access == "approver" && item.status == "Verified" ? "Approve" : "No action"))])])]}},{key:"item.items",fn:function(ref){
                      var item = ref.item;
return [_c('a',{staticStyle:{"font-size":"13px"},on:{"click":function($event){$event.preventDefault();return _vm.set_special_commission_dialog(item.id)}}},[_vm._v(" "+_vm._s(("View (" + (_vm.number_format(parseInt(item.items))) + ")"))+" ")])]}},{key:"item.amount",fn:function(ref){
                      var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(("Php " + (_vm.money_format(parseFloat(item.amount))))))])]}},{key:"item.status",fn:function(ref){
                      var item = ref.item;
return [_c('v-chip',{class:item.status == 'Requested'
                ? 'blue-grey lighten-5 blue-grey--text'
                : item.status == 'Verified'
                ? 'green lighten-5 green--text'
                : 'blue lighten-5 blue--text'},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)})],1)],1),(_vm.special_commission_raw_dialog)?_c('SpecialCommissionItems',{attrs:{"data":_vm.special_commission_raw_data},on:{"set_close_special_commission_dialog":_vm.set_close_special_commission_dialog}}):_vm._e(),(_vm.special_commission_filter_dialog)?_c('SpecialCommissionFilter',{attrs:{"data":_vm.special_commission_filter_data},on:{"set_close_special_commission_filter_dialog":_vm.set_close_special_commission_filter_dialog}}):_vm._e(),(_vm.special_commission_verify_dialog)?_c('SpecialCommissionVerify',{attrs:{"data":_vm.special_commission_verify_data},on:{"set_close_special_commission_verify_dialog":_vm.set_close_special_commission_verify_dialog}}):_vm._e(),(_vm.special_commission_approve_dialog)?_c('SpecialCommissionApprove',{attrs:{"data":_vm.special_commission_approve_data},on:{"set_close_special_commission_approve_dialog":_vm.set_close_special_commission_approve_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }