var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Ending Reports")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("League Bets")]),_c('v-card',{staticClass:"shadow-none"},[_c('v-card-text',{staticClass:"d-flex flex-column pb-0",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column justify-space-between",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"d-flex",staticStyle:{"gap":".30rem"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey lighten-5",attrs:{"icon":"","disabled":_vm.records_loading},on:{"click":_vm.doGetLeagueBets}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('downloadexcel',{staticClass:"d-inline-block",attrs:{"data":_vm.records,"fields":{
                'LEAGUE': 'league',
                'GAME': 'game',
                'BET': 'bet',
                'BET AMOUNT': 'amount'
              },"name":_vm.downloadExcelName(),"type":"csv"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary","disabled":_vm.records_loading}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-delimited-outline")])],1)],1)]}}])},[_c('span',[_vm._v("Export to Excel")])])],1),_c('div',{staticClass:"d-flex",staticStyle:{"gap":".30rem"}},[_c('v-text-field',{attrs:{"autofocus":"","dense":"","outlined":"","label":"Search","placeholder":"Search","hide-details":"","disabled":_vm.records_loading},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey lighten-5",attrs:{"icon":"","disabled":_vm.records_loading},on:{"click":_vm.set_filter_dialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}])},[_c('span',[_vm._v("Filter")])])],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"search":_vm.search,"loading":_vm.records_loading,"loading-text":"Loading, please wait a moment","items-per-page":5},scopedSlots:_vm._u([{key:"item.game",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.number_format(parseInt(item.game)))+" ")]}},{key:"item.bet",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.number_format(parseInt(item.bet)))+" ")]}},{key:"item.amount",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(("Php " + (_vm.money_format(parseFloat(item.amount)))))+" ")]}}],null,true)})],1)],1),(_vm.league_filter_dialog)?_c('LeagueBetFilter',{attrs:{"data":_vm.league_filter_data},on:{"set_close_filter_dialog":_vm.set_close_filter_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }