var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":_vm.records_loading,"max-width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"shadow"},[_c('v-card-title',{staticClass:"d-flex justify-space-between text-h6 font-weight-regular"},[_c('span',[_vm._v(_vm._s(("Verify — " + (_vm.data.agent))))]),_c('v-btn',{attrs:{"icon":"","plain":""},on:{"click":_vm.close_dialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"d-flex flex-column pb-0",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"d-flex flex-sm-row flex-column justify-space-between",staticStyle:{"gap":"1rem"}},[_c('span',[_vm._v("Total Commission "),_c('strong',[_vm._v(_vm._s(("Php " + (_vm.money_format(parseFloat(_vm.total_commission))))))])]),_c('v-btn',{attrs:{"depressed":"","color":"primary","disabled":_vm.records_loading},on:{"click":_vm.set_conirmation_dialog}},[_vm._v(" Verify ")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"loading":_vm.records_loading,"items-per-page":5,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.number_format(parseInt(item.amount)))+" ")]}},{key:"item.commission_percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((_vm.money_format(parseFloat(item.commission_percent))) + "%"))+" ")]}},{key:"item.commission_amount",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(("Php " + (_vm.money_format(parseFloat(item.commission_amount))))))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format_date(item.created_at))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.status == 'Pending' ? 'blue-grey lighten-5 blue-grey--text' : item.status == 'Requested' ? 'green lighten-5 green--text' : 'blue lighten-5 blue--text'},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)})],1)],1),(_vm.confirmation_dialog)?_c('SpecialCommissionConfirm',{attrs:{"id":parseInt(_vm.data.id)},on:{"set_close_confirmation_dialog":_vm.set_close_confirmation_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }