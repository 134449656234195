const url = process.env.VUE_APP_API_URL + "players";

export default {
  namespaced: true,

  state: {
    records: [],
    loading: false,
    details: [],
    detailsLoading: false,
    verifyError: null,
    verifyLoading: false,
    rejectError: null,
    rejectLoading: false,
    bettingError: null,
    bettingLoading: false,
    cashinError: null,
    cashinLoading: false,
    cashoutError: null,
    cashoutLoading: false,
    accountError: null,
    accountLoading: false,
  },
  mutations: {
    setRecords(state, val) {
      state.records = val;
    },
    setLoading(state, val) {
      state.loading = val;
    },
    setDetails(state, val) {
      state.details = val;
    },
    setDetailsLoading(state, val) {
      state.detailsLoading = val;
    },
    setVerifyError(state, val) {
      state.verifyError = val;
    },
    setVerifyLoading(state, val) {
      state.verifyLoading = val;
    },
    setRejectError(state, val) {
      state.rejectError = val;
    },
    setRejectLoading(state, val) {
      state.rejectLoading = val;
    },
    setBettingError(state, val) {
      state.bettingError = val;
    },
    setBettingLoading(state, val) {
      state.bettingLoading = val;
    },
    setCashinError(state, val) {
      state.cashinError = val;
    },
    setCashinLoading(state, val) {
      state.cashinLoading = val;
    },
    setCashoutError(state, val) {
      state.cashoutError = val;
    },
    setCashoutLoading(state, val) {
      state.cashoutLoading = val;
    },
    setAccountError(state, val) {
      state.accountError = val;
    },
    setAccountLoading(state, val) {
      state.accountLoading = val;
    },
  },

  actions: {
    async getDetails({ commit, rootState }, data) {
      commit("setDetailsLoading", true);

      try {
        const response = await fetch(`${url}/details/${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setDetails", res.details);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", {
            root: true,
          });
        }
      } finally {
        commit("setDetailsLoading", false);
      }
    },

    async doVerify({ commit, rootState }, data) {
      commit("setVerifyLoading", true);

      try {
        const response = await fetch(`${url}/verify`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
          body: data,
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setVerifyError", null);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        commit("setVerifyError", "Error");

        if ([404].includes(errorStatus)) {
          commit("setPopUpError", error.res.messages.error, { root: true });
        } else if ([401].includes(errorStatus)) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", {
            root: true,
          });
        }
      } finally {
        commit("setVerifyLoading", false);
      }
    },

    async doReject({ commit, rootState }, data) {
      commit("setRejectLoading", true);

      try {
        const response = await fetch(`${url}/reject`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
          body: data,
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setRejectError", null);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        commit("setRejectError", "Error");

        if ([404].includes(errorStatus)) {
          commit("setPopUpError", error.res.messages.error, { root: true });
        } else if ([401].includes(errorStatus)) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", {
            root: true,
          });
        }
      } finally {
        commit("setRejectLoading", false);
      }
    },

    async bettingSetting({ commit, rootState }, data) {
      commit("setBettingLoading", true);

      try {
        const response = await fetch(`${url}/betting_setting`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
          body: data,
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setBettingError", null);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        commit("setBettingError", "Error"); // Decoy error to detect success

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else if (errorStatus == 417) {
          commit("setPopUpError", error.res.error, { root: true });
        } else {
          commit("setRefreshError", "An error occurred, please try again!", {
            root: true,
          });
        }
      } finally {
        commit("setBettingLoading", false);
      }
    },

    async cashinSetting({ commit, rootState }, data) {
      commit("setCashinLoading", true);

      try {
        const response = await fetch(`${url}/cashin_setting`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
          body: data,
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setCashinError", null);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        commit("setCashinError", "Error"); // Decoy error to detect success

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else if (errorStatus == 417) {
          commit("setPopUpError", error.res.error, { root: true });
        } else {
          commit("setRefreshError", "An error occurred, please try again!", {
            root: true,
          });
        }
      } finally {
        commit("setCashinLoading", false);
      }
    },

    async cashoutSetting({ commit, rootState }, data) {
      commit("setCashoutLoading", true);

      try {
        const response = await fetch(`${url}/cashout_setting`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
          body: data,
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setCashoutError", null);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        commit("setCashoutError", "Error"); // Decoy error to detect success

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else if (errorStatus == 417) {
          commit("setPopUpError", error.res.error, { root: true });
        } else {
          commit("setRefreshError", "An error occurred, please try again!", {
            root: true,
          });
        }
      } finally {
        commit("setCashoutLoading", false);
      }
    },

    async accountSetting({ commit, rootState }, data) {
      commit("setAccountLoading", true);

      try {
        const response = await fetch(`${url}/account_setting`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
          body: data,
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setAccountError", null);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        commit("setAccountError", "Error"); // Decoy error to detect success

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else if (errorStatus == 417) {
          commit("setPopUpError", error.res.error, { root: true });
        } else {
          commit("setRefreshError", "An error occurred, please try again!", {
            root: true,
          });
        }
      } finally {
        commit("setAccountLoading", false);
      }
    },

    async getTransactions({ commit, rootState }, data) {
      commit("setLoading", true);

      try {
        const response = await fetch(`${url}/details/transactions?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setRecords", res.records);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        commit("setError", "Error"); // Decoy error to detect success

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else if (errorStatus == 417) {
          commit("setPopUpError", error.res.error, { root: true });
        } else {
          commit("setRefreshError", "An error occurred, please try again!", {
            root: true,
          });
        }
      } finally {
        commit("setLoading", false);
      }
    },

    async getAdminLogs({ commit, rootState }, data) {
      commit("setLoading", true);

      try {
        const response = await fetch(`${url}/details/admin_logs?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setRecords", res.records);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        commit("setError", "Error"); // Decoy error to detect success

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else if (errorStatus == 417) {
          commit("setPopUpError", error.res.error, { root: true });
        } else {
          commit("setRefreshError", "An error occurred, please try again!", {
            root: true,
          });
        }
      } finally {
        commit("setLoading", false);
      }
    },

    async getPlayersLogs({ commit, rootState }, data) {
      commit("setLoading", true);

      try {
        const response = await fetch(`${url}/details/players_logs?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setRecords", res.records);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        commit("setError", "Error"); // Decoy error to detect success

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else if (errorStatus == 417) {
          commit("setPopUpError", error.res.error, { root: true });
        } else {
          commit("setRefreshError", "An error occurred, please try again!", {
            root: true,
          });
        }
      } finally {
        commit("setLoading", false);
      }
    },
  },
};
