<template>
  <v-dialog :fullscreen="!$vuetify.breakpoint.mdAndUp" v-model="dialog" max-width="700">
    <v-card>
      <div class="d-flex align-center justify-space-between px-6 py-4">
        <h3>Filter</h3>
        <v-btn icon>
          <v-icon @click="close_dialog">mdi-close</v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <v-form ref="form" @submit.prevent="filter_agents">
        <v-card-text class="d-flex flex-column gap pa-6">
          <v-select
            v-model="filter"
            :items="filters"
            label="Choose your filter"
            dense
            outlined
            hide-details="auto"
          />

          <div v-if="filter == 'Date Created'">
            <v-row dense class="mt-4">
              <v-col>
                <v-menu
                  v-model="date_from_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_from"
                      :rules="[rules.required]"
                      label="Date From"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @keyup="date_from_menu = true"
                      @keydown="date_from_menu = false"
                      hide-details="auto"
                      clearable
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="date_from"
                    @input="date_from_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row dense class="mt-4">
              <v-col>
                <v-menu
                  v-model="date_to_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_to"
                      label="Date To"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @keyup="date_to_menu = true"
                      @keydown="date_to_menu = false"
                      hide-details="auto"
                      clearable
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="date_to"
                    @input="date_to_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>

          <div v-if="filter == 'Investor Name'">
            <v-row dense class="mt-4">
              <v-col>
                <v-text-field
                  v-model="first_name"
                  label="First Name"
                  outlined
                  dense
                  :rules="[rules.minimum(2), value_validation]"
                  hide-details="auto"
                  clearable
                  autofocus
                />
              </v-col>
            </v-row>

            <v-row dense class="mt-3">
              <v-col>
                <v-text-field
                  v-model="last_name"
                  label="Last Name"
                  outlined
                  dense
                  :rules="[rules.minimum(2), value_validation]"
                  hide-details="auto"
                  clearable
                />
              </v-col>
            </v-row>
          </div>

          <div v-if="filter == 'Investor Mobile No.'">
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="mobile_number"
                  :rules="[rules.required, rules.validMobileNumber]"
                  label="Mobile Number"
                  outlined
                  prefix="+63"
                  dense
                  autofocus
                  clearable
                  hide-details="auto"
                />
              </v-col>
            </v-row>
          </div>
        </v-card-text>


        <div class="d-flex justify-end gap-sm px-6 pb-6">
          <v-btn depressed text @click="close_dialog"> Cancel </v-btn>

          <v-btn depressed color="primary" type="submit"> Filter </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from "@/helpers/formRules";

export default {
  props: {
    filter_data: {
      type: Object,
    },
  },

  data: () => ({
    date_from: null,
    date_from_menu: false,
    date_to: null,
    date_to_menu: false,
    first_name: null,
    last_name: null,
    mobile_number: null,
    rules: formRules,
    filters: ["Date Created", "Investor Name", "Investor Mobile No."],
    filter: "Date Created",
    filter_selected: 1,
  }),

  created() {
    this.filter_selected = this.filter_data.filter;

    switch (this.filter_selected) {
      case 1:
        this.filter = "Date Created";
        this.date_from = this.filter_data.date_from;
        this.date_to = this.filter_data.date_to;
        break;
      case 2:
        this.filter = "Investor Name";
        this.first_name = this.filter_data.first_name;
        this.last_name = this.filter_data.last_name;
        this.date_from = null;
        this.date_to = null;
        break;
      case 3:
        this.filter = "Investor Mobile No.";
        this.mobile_number = this.filter_data.mobile_number;
        this.date_from = null;
        this.date_to = null;
        break;
    }
  },

  computed: {
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.close_dialog();
      },
    },

    value_validation() {
      if (!this.first_name && !this.last_name) {
        return "Atleast one of these fields have a value.";
      }

      return true;
    },
  },

  methods: {
    close_dialog() {
      this.$emit("close_filter_dialog", {});
    },

    filter_agents() {
      if (this.$refs.form.validate()) {
        let data = {};

        switch (this.filter_selected) {
          case 1:
            this.filter = "Date Created";

            data = {
              date_from: this.date_from,
              date_to: this.date_to,
              filter: this.filter_selected,
            };
            break;
          case 2:
            this.filter = "Investor Name";

            data = {
              first_name: this.first_name,
              last_name: this.last_name,
              filter: this.filter_selected,
            };
            break;
          case 3:
            this.filter = "Investor Mobile No.";
            data = {
              mobile_number: this.mobile_number,
              filter: this.filter_selected,
            };
            break;
        }

        this.$emit("close_filter_dialog", data);
      }
    },
  },

  watch: {
    filter(val) {
      switch (val) {
        case "Date Created":
          this.filter_selected = 1;
          break;
        case "Investor Name":
          this.filter_selected = 2;
          break;
        case "Investor Mobile No.":
          this.filter_selected = 3;
          break;
      }
    },
  },
};
</script>
