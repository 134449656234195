<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Ending Reports</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Top Winning Combination</h2>
    <v-card class="shadow mb-4">
      <v-card-text class="py-4">
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="handleSearch">
          <v-row dense
            ><v-col cols="12" md="6" class="mt-2">
              <v-menu
                v-model="date_from_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_from"
                    :rules="[rules.required, lessThanDate]"
                    label="Scheduled Date From"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @keyup="date_from_menu = true"
                    @keydown="date_from_menu = false"
                    clearable
                  />
                </template>

                <v-date-picker
                  v-model="date_from"
                  @input="date_from_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="6" class="mt-2">
              <v-menu
                v-model="date_to_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :disabled="date_from == ''"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_to"
                    label="Scheduled Date To Optional"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :disabled="dateToAvailability"
                    @keyup="date_to_menu = true"
                    @keydown="date_to_menu = false"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_to"
                  @input="date_to_menu = false"
                  :min="date_from ? date_from : ''"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row dense class="mt-2">
            <v-col>
              <v-btn depressed type="submit" color="primary"> Search </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <downloadexcel
              :data="records"
              :fields="{
                Slot: 'slot',
                'Number of Winners': 'bets',
                'Total Bet Amount': 'total_bet_amount',
                'Total Payout': 'total_payout',
              }"
              name="Top Winning Combination Report.xls"
              type="csv"
              class="d-inline-block"
            >
              <v-btn outlined color="primary"> Export CSV </v-btn>
            </downloadexcel>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-row dense>
        <v-col>
          <v-card-text>
            <v-row dense>
              <v-col align="center" cols="12" md="4" class="py-4">
                <v-card class="pa-1">
                  <p class="font-weight-regular ma-0 overline">TOTAL NUMBER OF WINNERS</p>
                  <v-divider class="mb-4" />
                  <p class="font-weight-medium">
                    {{ parseInt(totalNumberOfWinners).toLocaleString() }}
                  </p>
                </v-card>
              </v-col>

              <v-col align="center" cols="12" md="4" class="py-4">
                <v-card class="pa-1">
                  <p class="font-weight-regular ma-0 overline">TOTAL BET AMOUNT</p>
                  <v-divider class="mb-4" />
                  <p class="font-weight-medium">
                    PHP {{ moneyFormat(parseFloat(totalBetAmount)) }}
                  </p>
                </v-card>
              </v-col>

              <v-col align="center" cols="12" md="4" class="py-4">
                <v-card class="pa-1">
                  <p class="font-weight-regular ma-0 overline">TOTAL PAYOUTS</p>
                  <v-divider class="mb-4" />
                  <p class="font-weight-medium">
                    PHP {{ moneyFormat(parseFloat(totalPayouts)) }}
                  </p>
                </v-card>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-data-table
                  :headers="headers"
                  :items="records"
                  :items-per-page="5"
                  :search="search"
                  :loading="recordsLoading"
                  loading-text="Loading, please wait a moment"
                >
                  <template v-slot:[`item.action`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          dense
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          color="primary"
                          class="ma-1"
                          @click="setWinnersModal(item)"
                        >
                          <v-icon size="large"> mdi-seal-variant </v-icon>
                        </v-btn>
                      </template>
                      <span>Show Winners</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:[`item.total_winning`]="{ item }">
                    <span>{{ parseInt(item.total_winning).toLocaleString() }} </span>
                  </template>

                  <template v-slot:[`item.bets`]="{ item }">
                    <span>{{ parseInt(item.bets).toLocaleString() }} </span>
                  </template>

                  <template v-slot:[`item.bet_amount`]="{ item }">
                    <span
                      >PHP
                      {{ moneyFormat(parseFloat(item.bet_amount)) }}
                    </span>
                  </template>

                  <template v-slot:[`item.total_bet_amount`]="{ item }">
                    <span
                      >PHP
                      {{ moneyFormat(parseFloat(item.total_bet_amount)) }}
                    </span>
                  </template>

                  <template v-slot:[`item.total_payout`]="{ item }">
                    <span
                      >PHP
                      {{ moneyFormat(parseFloat(item.total_payout)) }}
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <TopWinningCombination
      v-if="top_winning_combination_dialog"
      :filters="filters"
      @setCloseModal="setCloseModal"
    />
  </div>
</template>

<script>
import formRules from "@/helpers/formRules";
import downloadexcel from "vue-json-excel";
import { mapActions, mapState } from "vuex";
import TopWinningCombination from "@/components/reports/TopWinningCombinationDialog";

export default {
  components: {
    downloadexcel,
    TopWinningCombination,
  },

  data: () => ({
    valid: true,
    search: "",
    rules: formRules,
    date_from: null,
    date_to: null,
    date_from_menu: false,
    date_to_menu: false,
    top_winning_combination_dialog: false,
    filters: {},
    headers: [
      {
        text: "Action",
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      {
        text: "Slot",
        value: "slot",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Number of Winners",
        value: "total_winning",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Total Bet Amount",
        value: "total_bet_amount",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Total Payout",
        value: "total_payout",
        class: "text-uppercase",
        align: "right",
      },
    ],
  }),

  computed: {
    ...mapState("ending_reports_top_winning_combination", ["records", "recordsLoading"]),

    lessThanDate() {
      if (this.date_from && this.date_to) {
        if (new Date(this.date_to) < new Date(this.date_from)) {
          return "Must be higher or equal than date from.";
        }
      }

      return true;
    },

    dateToAvailability() {
      if (this.date_from == "" || this.date_from == null) {
        return true;
      }

      return false;
    },

    totalNumberOfWinners() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseInt(obj.total_winning) + total,
          0
        );
      } else {
        return 0;
      }
    },

    totalBetAmount() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.total_bet_amount) + total,
          0
        );
      } else {
        return 0;
      }
    },

    totalPayouts() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.total_payout) + total,
          0
        );
      } else {
        return 0;
      }
    },
  },

  created() {
    this.$store.commit("ending_reports_top_winning_combination/setRecords", []);
  },

  methods: {
    ...mapActions("ending_reports_top_winning_combination", ["getRecords"]),

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    async handleSearch() {
      if (this.$refs.form.validate()) {
        if (!this.recordsLoading) {
          this.$store.commit("ending_reports_top_winning_combination/setRecords", []);

          await this.getRecords(this.setFormData());

          // this.setQueryString();
        }
      }
    },

    async handleLoadRecod() {
      this.$store.commit("ending_reports_top_winning_combination/setRecords", []);

      await this.getRecords(this.setFormData());
    },

    setFormData() {
      const formData = new FormData();

      formData.append("date_from", this.date_from);
      formData.append("date_to", this.date_to ?? this.date_from);

      const getData = new URLSearchParams(formData);

      return getData;
    },

    setWinnersModal(item) {
      this.filters = {
        slot_id: item.slot_id,
        slot: item.slot,
        date_from: this.date_from,
        date_to: this.date_to ?? this.date_from,
      };

      this.top_winning_combination_dialog = true;
    },

    setCloseModal() {
      this.top_winning_combination_dialog = false;
    },
  },

  watch: {
    date_from(val) {
      if (val == "" || val == null) {
        this.date_to = "";
      }
    },
  },
};
</script>
