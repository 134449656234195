const url = process.env.VUE_APP_API_URL + "ending/games"
const configUrl = process.env.VUE_APP_API_URL

export default {
  namespaced: true,

  state: {
    betAmountConfigs: [],
    prizeConfiguration: [],
    prizeConfigurationLoading: false,
    details: [],
    detailsLoading: false,
    createCardError: null,
    createCardLoading: false,
    createResultError: null,
    createResultLoading: false,
    bettingError: null,
    bettingLoading: false,
    cardThreshold: '',
    betAmounts: [],
    betAmountsLoading: false,
    gameWinners: [],
    gameWinnersLoading: false,
    verifyWinnerError: null,
    verifyWinnerLoading: false,
    unverifyWinnerError: null,
    unverifyWinnerLoading: false,
    approveWinnerError: null,
    approveWinnerLoading: false,
    generatePdfError: null,
    generatePdfLoading: false,
    sendGeneratePdfError: null,
    sendGeneratePdfLoading: false,
    addCardError: null,
    addCardLoading: false,
    sendSMSNotifToPlayersHasError: false,
    sendSMSNotifToPlayersLoading: false,
    updateResult: [],
    updateResultLoading: false,
    updateResultError: null,
    postponeLoading: false,
    postponeError: null,
    subtractCardError: null,
    subtractCardLoading: false,
  },
  mutations: {
    setBetAmountConfigs(state, val) {
      state.betAmountConfigs = val
    },

    setPrizeConfiguration(state, val) {
      state.prizeConfiguration = val
    },

    setPrizeConfigurationLoading(state, val) {
      state.prizeConfigurationLoading = val
    },

    setDetails (state, val) {
      state.details = val
    },

    setDetailsLoading (state, val) {
      state.detailsLoading = val
    },

    setCreateCardError(state, val) {
      state.createCardError = val
    },

    setCreateCardLoading(state, val) {
      state.createCardLoading = val
    },

    setCreateResultError(state, val) {
      state.createResultError = val
    },

    setCreateResultLoading(state, val) {
      state.createResultLoading = val
    },

    setBettingError (state, val) {
      state.bettingError = val
    },

    setBettingLoading (state, val) {
      state.bettingLoading = val
    },

    setCardThreshold(state, val) {
      state.cardThreshold = val
    },

    setBetAmounts (state, val) {
      state.betAmounts = val
    },

    setBetAmountsLoading (state, val) {
      state.betAmountsLoading = val
    },

    setGameWinners (state, val) {
      state.gameWinners = val
    },

    setGameWinnersLoading (state, val) {
      state.gameWinnersLoading = val
    },

    setVerifyWinnerError (state, val) {
      state.verifyWinnerError = val
    },

    setVerifyWinnerLoading (state, val) {
      state.verifyWinnerLoading = val
    },

    setUnverifyWinnerError (state, val) {
      state.unverifyWinnerError = val
    },

    setUnverifyWinnerLoading (state, val) {
      state.unverifyWinnerLoading = val
    },

    setApproveWinnerError (state, val) {
      state.approveWinnerError = val
    },

    setApproveWinnerLoading (state, val) {
      state.approveWinnerLoading = val
    },

    setGeneratePdfError (state, val) {
      state.generatePdfError = val
    },
    
    setGeneratePdfLoading (state, val) {
      state.generatePdfLoading = val
    },

    setSendGeneratePdfError (state, val) {
      state.sendGeneratePdfError = val
    },

    setSendGeneratePdfLoading (state, val) {
      state.sendGeneratePdfLoading = val
    },
    
    setAddCardError (state, val) {
      state.addCardError = val
    },
    setAddCardLoading (state, val) {
      state.addCardLoading = val
    },

    setSendSMSNotifToPlayersHasError (state, val) {
      state.sendSMSNotifToPlayersHasError = val
    },

    setSendSMSNotifToPlayersLoading (state, val) {
      state.sendSMSNotifToPlayersLoading = val
    },

    setUpdateResult(state, val) {
      state.updateResult = val
    },

    setUpdateLoading(state, val) {
      state.updateResultLoading = val
    },

    setUpdateResultError(state, val) {
      state.updateResultError = val
    },

    setPostoneLoading(state, val) {
      state.postponeLoading = val
    },

    setPostponeError(state, val) {
      state.postponeError = val
    },

    setSubtractCardError (state, val) {
      state.subtractCardError = val
    },
    setSubtractCardLoading (state, val) {
      state.subtractCardLoading = val
    },
  },

  actions: {
    async getDetails({ commit, rootState }, data) {
      commit("setDetailsLoading", true)

      try {
        const response = await fetch(`${url}/details/${data}` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setDetails", res.details)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setDetailsLoading", false)
      }
    },

    async getPrizeConfiguration ( { commit, rootState }) {
      commit("setPrizeConfigurationLoading", true)

      try {
        const response = await fetch(`${configUrl}ending/configuration/prize_configuration` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setPrizeConfiguration", res.records)
          commit("setBetAmountConfigs", res.bet_amounts)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setPrizeConfigurationLoading", false)
      }
    },

    async createCard({ commit, rootState }, data) {
      commit("setCreateCardLoading", true)

      try {
        const response = await fetch(`${url}/create_card`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setCreateCardError", null)

          // return res
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setCreateCardError", "Error") // Decoy error to detect success
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setCreateCardLoading", false)
      }
    },

    async createResult({ commit, rootState }, data) {
      commit("setCreateResultLoading", true)

      try {
        const response = await fetch(`${url}/create_result`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setCreateResultError", null)

          return res
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setCreateResultError", "Error") // Decoy error to detect success
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setCreateResultLoading", false)
      }
    },

    async bettingSetting({ commit, rootState }, data) {
      commit("setBettingLoading", true)

      try {
        const response = await fetch(`${url}/betting_setting` , {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setBettingError", null) 
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setBettingError", "Error") // Decoy error to detect success
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setBettingLoading", false)
      }
    },

    async getBetAmounts({ commit, rootState }, data) {
      commit("setBetAmountsLoading", true)

      try {
        const response = await fetch(`${url}/bet_amounts/${data}` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setCardThreshold", res.config)
          commit("setBetAmounts", res.bet_amounts)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setBetAmountsLoading", false)
      }
    },

    async getGameWinners({ commit, rootState }, data) {
      commit("setGameWinnersLoading", true)

      try {
        const response = await fetch(`${url}/game_winners/${data}` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setGameWinners", res.winners)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setGameWinnersLoading", false)
      }
    },

    async verifyWinner({ commit, rootState }, data) {
      commit("setVerifyWinnerLoading", true)

      try {
        const response = await fetch(`${url}/verify_winner` , {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setVerifyWinnerError", null) 
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setVerifyWinnerError", "Error") // Decoy error to detect success
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setVerifyWinnerLoading", false)
      }
    },

    async unverifyWinner({ commit, rootState }, data) {
      commit("setUnverifyWinnerLoading", true)

      try {
        const response = await fetch(`${url}/unverify_winner` , {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setUnverifyWinnerError", null) 
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setUnverifyWinnerError", "Error") // Decoy error to detect success
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setUnverifyWinnerLoading", false)
      }
    },

    async approveWinner({ commit, rootState }, data) {
      commit("setApproveWinnerLoading", true)

      try {
        const response = await fetch(`${url}/approve_winner` , {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setApproveWinnerError", null) 
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setApproveWinnerError", "Error") // Decoy error to detect success
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==403) {
          commit("setApproveWinnerError", 'Agent points are insufficient.')
        } else if (errorStatus==417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setApproveWinnerLoading", false)
      }
    },

    async generateCardsPdf({ commit, rootState }, data) {
      commit("setGeneratePdfLoading", true)

      try {
        const response = await fetch(`${url}/generate_pdf` , {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setGeneratePdfError", null) 

          return res
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setGeneratePdfError", "Error") // Decoy error to detect success
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setGeneratePdfLoading", false)
      }
    },

    async sendGenerateCardsPdf({ commit, rootState }, data) {
      commit("setSendGeneratePdfLoading", true)

      try {
        const response = await fetch(`${url}/email_generated_pdf` , {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setSendGeneratePdfError", null)
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setSendGeneratePdfError", "Error") // Decoy error to detect success
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setSendGeneratePdfLoading", false)
      }
    },

    async addCard({ commit, rootState }, data) {
      commit("setAddCardLoading", true)

      try {
        const response = await fetch(`${url}/add_card`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setAddCardError", null)
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setAddCardError", "Error") // Decoy error to detect success
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setAddCardLoading", false)
      }
    },

    async sendGameSchedule({ commit, rootState }, data) {
      commit("setSendSMSNotifToPlayersLoading", true)
      commit("setSendSMSNotifToPlayersHasError", false)

      try {
        const response = await fetch(`${url}/send_game_schedule` , {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setSendSMSNotifToPlayersHasError", true) // Decoy error to detect success
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==404) {
          commit("setPopUpError", error.res.messages.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setSendSMSNotifToPlayersLoading", false)
      }
    },

    async sendSMSNotifToPlayers({ commit, rootState }, data) {
      commit("setSendSMSNotifToPlayersLoading", true)
      commit("setSendSMSNotifToPlayersHasError", false)

      try {
        const response = await fetch(`${url}/send_notification_to_players` , {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setSendSMSNotifToPlayersHasError", true) // Decoy error to detect success
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==404) {
          commit("setPopUpError", error.res.messages.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setSendSMSNotifToPlayersLoading", false)
      }
    },

    async updateGameResult({ commit, rootState }, data) {
      commit("setUpdateLoading", true)

      try {
        const response = await fetch(`${url}/update_result` , {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setUpdateResult", res)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==422) {
          commit("setUpdateResultError", "No longer available for editing")
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setUpdateLoading", false)
      }
    },

    async doPostponeGame({ commit,  rootState}, data) {
      commit("setPostponeLoading", true)

      try {
        const response = await fetch(`${url}/postpone_game` , {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } 
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==422) {
          commit("setPostponeError", "Something went wrong!")
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setPostponeLoading", false)
      }
    },

    async subtractCard({ commit, rootState }, data) {
      commit("setSubtractCardLoading", true)

      try {
        const response = await fetch(`${url}/subtract_card`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setSubtractCardError", null)
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setSubtractCardError", "Error") // Decoy error to detect success
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setSubtractCardLoading", false)
      }
    },
  }
}