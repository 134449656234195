const url = process.env.VUE_APP_API_URL 

export default {
  namespaced: true,

  state: {
    records: [],
    recordsLoading: false,
    leagues: [],
    teams: [],
    teamId: '',
    createTeamError: null,
    createTeamErrors: [],
    createTeamLoading: false,
  },
  mutations: {
    setRecords (state, val) {
      state.records = val
    },
    setRecordsLoading (state, val) {
      state.recordsLoading = val
    },
    setLeagues(state, val) {
      state.leagues = val
    },
    setTeamId(state, val) {
      state.teamId = val
    },
    setTeams(state, val) {
      state.teams = val
    },
    setCreateTeamError(state, val) {
      state.createTeamError = val
    },
    setCreateTeamErrors(state, val) {
      state.createTeamErrors = val
    },
    setCreateTeamLoading(state, val) {
      state.createTeamLoading = val
    }
  },

  actions: {
    async getRecords({ commit, rootState }) {
      commit("setRecordsLoading", true)

      try {
        const response = await fetch(`${url}ending/teams`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setRecords", res.records)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setRecordsLoading", false)
      }
    },
    async createTeam({ commit, rootState}, data) {
      commit("setCreateTeamLoading", true)
      commit("setCreateTeamError", null)
      commit("setCreateTeamErrors", [])

      try {
        const response = await fetch(`${url}ending/teams/create`, { 
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`
          },
          body: data
        })

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setTeamId", res.team_id)
        }
      } catch(error) {
        const errorStatus = error.response.status

        switch(errorStatus) {
          case 401:
            commit("setError", "Unauthorized", { root: true })
            break;
          case 404:
            commit("setError", error.res.messages.error)
            break;
          case 417:
            commit("setPopUpErrror", error.res.error, { root: true })
            break;
          case 422:
              commit("setCreateTeamErrors", error.res.messages) 
            break;
          default:
            commit("setRefreshError", "An error occured, please try again!", { root: true })
        } 
      } finally {
        commit("setCreateTeamLoading", false)
      }
    },
    async updateTeam({ commit, rootState }, data) {
      commit("setCreateTeamLoading", true)
      commit("setCreateTeamError", null)
      commit("setCreateTeamErrors", [])

      try {
        const response = await fetch(`${url}ending/teams/update`, { 
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`
          },
          body: data
        })

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setTeamId", res.team_id)
        }
      } catch(error) {
        const errorStatus = error.response.status

        switch(errorStatus) {
          case 401:
            commit("setError", "Unauthorized", { root: true })
            break;
          case 404:
            commit("setError", error.res.messages.error)
            break;
          case 417:
            commit("setPopUpErrror", error.res.error, { root: true })
            break;
          case 422:
              commit("setCreateTeamErrors", error.res.messages) 
            break;
          default:
            commit("setRefreshError", "An error occured, please try again!", { root: true })
        } 
      } finally {
        commit("setCreateTeamLoading", false)
      }
    }
  }
}