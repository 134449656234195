<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Ending Reports</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Progress</h2>

    <v-card class="shadow mb-4">
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="handleSubmit" class="d-flex flex-column"
          style="gap:1rem" :style="$vuetify.breakpoint.mdAndUp ? 'width: 40%' : 'width: 100%'"
          :disabled="leagues_loading">

          <v-select dense v-model="league" :items="leagues" label="League" item-value="id" item-text="league" outlined
            hide-details="auto" />

          <v-select v-model="filterBy" :items="filterByStatus" :rules="[rules.required]" label="Filter By" dense outlined
            hide-details="auto" clearable />

          <v-menu v-model="schedule_date_start_menu" :close-on-content-click="false" transition="scale-transition"
            offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="schedule_date_start" :rules="[rules.required]" :label="`${filterBy} From`"
                hide-details="auto" readonly outlined dense v-bind="attrs" v-on="on"
                @keyup="schedule_date_start_menu = true" @keydown="schedule_date_start_menu = false"
                clearable></v-text-field>
            </template>
            <v-date-picker v-model="schedule_date_start" @input="schedule_date_start_menu = false"></v-date-picker>
          </v-menu>

          <v-menu v-model="schedule_date_end_menu" :close-on-content-click="false" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="schedule_date_end" :label="`${filterBy} To (Optional)`" hide-details="auto" readonly
                outlined dense v-bind="attrs" v-on="on" @keyup="schedule_date_end_menu = true"
                @keydown="schedule_date_end_menu = false" clearable></v-text-field>
            </template>
            <v-date-picker v-model="schedule_date_end" @input="schedule_date_end_menu = false"
              :min="schedule_date_start ? schedule_date_start : ''"></v-date-picker>
          </v-menu>

          <v-radio-group :rules="[rules.required]" hide-details="auto" class="mt-0" v-model="report_type"
            :row="$vuetify.breakpoint.mdAndUp ? true : false" :column="!$vuetify.breakpoint.mdAndUp ? true : false">
            <v-radio label="Daily" value="Daily"></v-radio>
            <v-radio label="Weekly" value="Weekly"></v-radio>
            <v-radio label="Monthly" value="Monthly"></v-radio>
            <v-radio label="Yearly" value="Yearly"></v-radio>
          </v-radio-group>

          <v-btn :disabled="!valid" type="submit" color="primary" outlined @click.prevent="handleSubmit"
            :loading="recordsLoading">
            Submit
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="shadow">
      <v-card-title class="text-h6 font-weight-regular grey--text text--darken-1">
        {{ searched_report_type }} Report
        <v-spacer></v-spacer>
        <div>
          <downloadexcel :data="exportExcelDataWithTotal()" :fields="{
            'Schedule Date': 'final_schedule_date',
            'Total Games': 'total_games',
            'Total Bettors': 'total_bettors',
            'Total Bets': 'total_bets',
            'Total Gross Sales': 'game_total_bet_amount',
            'Total Payouts': 'game_total_prizes',
            'Total Gross Gaming Revenue': 'net_amount',
            'Total PAGCOR Share': 'pagcor_share',
            'Total Net Amount': 'net_income',
          }" :name="downloadExcelName()" type="csv" class="d-inline-block" v-if="records.length">
            <v-btn color="primary" outlined small depressed> Export CSV </v-btn>
          </downloadexcel>
          <v-btn color="primary" outlined small depressed @click="exportToPDF" class="ms-2 d-inline-block"
            v-if="records.length">
            Export PDF
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="d-flex flex-column" style="gap:1rem">
        <div class="d-flex flex-sm-row flex-column" style="gap:1rem">
          <v-card class="pa-1 text-center" style="width: 100%;">
            <p class="font-weight-regular ma-0 overline">TOTAL GAMES</p>
            <v-divider class="mb-4" />
            <p class="font-weight-medium">
              {{ parseInt(totalGames).toLocaleString() }}
            </p>
          </v-card>

          <v-card class="pa-1 text-center" style="width: 100%;">
            <p class="font-weight-regular ma-0 overline">TOTAL BETTORS</p>
            <v-divider class="mb-4" />
            <p class="font-weight-medium">
              {{ parseInt(totalBettors).toLocaleString() }}
            </p>
          </v-card>

          <v-card class="pa-1 text-center" style="width: 100%;">
            <p class="font-weight-regular ma-0 overline">TOTAL BETS</p>
            <v-divider class="mb-4" />
            <p class="font-weight-medium">
              {{ parseInt(totalBets).toLocaleString() }}
            </p>
          </v-card>
        </div>

        <div class="d-flex flex-sm-row flex-column" style="gap:1rem">
          <v-card class="pa-1 text-center" style="width: 100%;">
            <p class="font-weight-regular ma-0 overline">TOTAL GROSS</p>
            <v-divider class="mb-4" />
            <p class="font-weight-medium">
              {{ `Php ${moneyFormat(parseFloat(totalGrossAmount))}` }}
            </p>
          </v-card>

          <v-card class="pa-1 text-center" style="width: 100%;">
            <p class="font-weight-regular ma-0 overline">TOTAL PAYOUTS</p>
            <v-divider class="mb-4" />
            <p class="font-weight-medium d-inline-flex">
              {{ `Php ${moneyFormat(parseFloat(totalDistributedPrizes))}` }}
            </p>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <small v-bind="attrs" v-on="on">
                  {{ `${moneyFormat(parseFloat(setPercentage(totalDistributedPrizes, totalGrossAmount)))}%` }}
                </small>
              </template>
              <span>{{ `${moneyFormat(parseFloat(setPercentage(totalDistributedPrizes, totalGrossAmount)))}% of Total
                Gross`}}
              </span>
            </v-tooltip>
          </v-card>

          <v-card class="pa-1 text-center" style="width: 100%;">
            <p class="font-weight-regular ma-0 overline">TOTAL NET AMOUNT</p>
            <v-divider class="mb-4" />
            <p class="font-weight-medium d-inline-flex">
              {{ `Php ${moneyFormat(parseFloat(totalNetAmount))}` }}
            </p>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <small v-bind="attrs" v-on="on">
                  {{ `${moneyFormat(parseFloat(setPercentage(totalNetAmount, totalGrossAmount)))}%` }}
                </small>
              </template>
              <span>
                {{ `${moneyFormat(parseFloat(setPercentage(totalNetAmount, totalGrossAmount)))}% of Total Gross` }}
              </span>
            </v-tooltip>
          </v-card>

          <v-card class="pa-1 text-center" style="width: 100%;">
            <p class="font-weight-regular ma-0 overline">TOTAL NET INCOME</p>
            <v-divider class="mb-4" />
            <p class="font-weight-medium d-inline-flex">
              {{ `Php ${moneyFormat(parseFloat(totalNetIncome))}` }}
            </p>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <small v-bind="attrs" v-on="on">
                  {{ `${moneyFormat(parseFloat(setPercentage(totalNetIncome, totalGrossAmount)))}%` }}
                </small>
              </template>
              <span>
                {{ `${moneyFormat(parseFloat(setPercentage(totalNetIncome, totalGrossAmount)))}% of Total Gross` }}</span>
            </v-tooltip>
          </v-card>
        </div>
      </v-card-text>

      <div ref="datatable">
        <v-card-text class="pa-0">
          <v-data-table :headers="headers" :items="records" :items-per-page="5" :loading="recordsLoading"
            loading-text="Loading, please wait a moment" mobile-breakpoint="0">
            <template v-slot:[`item.final_schedule_date`]="{ item }">
              <span>{{ scheduleDate(item.final_schedule_date) }}</span>
            </template>
            <template v-slot:[`item.total_games`]="{ item }">
              <span>{{ parseInt(item.total_games).toLocaleString() }}</span>
            </template>
            <template v-slot:[`item.game_total_bettors`]="{ item }">
              <span>{{ parseInt(item.game_total_bettors).toLocaleString() }}</span>
            </template>
            <template v-slot:[`item.game_total_bets`]="{ item }">
              <span>{{ parseInt(item.game_total_bets).toLocaleString() }}</span>
            </template>
            <template v-slot:[`item.game_total_bet_amount`]="{ item }">
              <span>{{ `Php ${moneyFormat(parseFloat(item.game_total_bet_amount))}` }}</span>
            </template>
            <template v-slot:[`item.game_total_prizes`]="{ item }">
              <span>{{ `Php ${moneyFormat(parseFloat(item.game_total_prizes))}` }}</span>
            </template>
            <template v-slot:[`item.net_amount`]="{ item }">
              <span>{{ `Php ${moneyFormat(parseFloat(item.net_amount))}` }}</span>
            </template>
            <template v-slot:[`item.pagcor_share`]="{ item }">
              <span>{{ `Php ${moneyFormat(parseFloat(item.pagcor_share))}` }}</span>
            </template>
            <template v-slot:[`item.net_income`]="{ item }">
              <span>{{ `Php ${moneyFormat(parseFloat(item.net_income))}` }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import formRules from "@/helpers/formRules";
import formatDatev2 from "@/helpers/formatDatev2";
import downloadexcel from "vue-json-excel";
import html2pdf from "html2pdf.js";
import GetPercentage from "@/helpers/getPercentage";
import responseGet from "@/helpers/api_request_get";

export default {
  components: { downloadexcel },

  data: () => ({
    league: "0",
    leagues: [],
    leagues_loading: false,
    records: [],
    recordsLoading: false,
    valid: true,
    rules: formRules,
    schedule_date_start: "",
    schedule_date_start_menu: false,
    schedule_date_end: "",
    schedule_date_end_menu: false,
    report_type: "Daily",
    sortDesc: true,
    filterByStatus: ["Bet Date", "Scheduled Date"],
    filterBy: "Bet Date",
    headers: [
      { text: "Date", value: "final_schedule_date", class: "text-uppercase" },
      { text: "League", value: "league", class: "text-uppercase" },
      { text: "Total Games", value: "total_games", class: "text-uppercase", align: "right" },
      { text: "Total Bettors", value: "game_total_bettors", class: "text-uppercase", align: "right" },
      { text: "Total Bets", value: "game_total_bets", class: "text-uppercase", align: "right" },
      {
        text: "Total Gross Sales",
        value: "game_total_bet_amount",
        class: "text-uppercase", align: "right"
      },
      { text: "Total Payouts", value: "game_total_prizes", class: "text-uppercase", align: "right" },
      {
        text: "Total Gross Gaming Revenue",
        value: "net_amount",
        class: "text-uppercase", align: "right"
      },
      { text: "Total PAGCOR Share", value: "pagcor_share", class: "text-uppercase", align: "right" },
      { text: "Total Net Amount", value: "net_income", class: "text-uppercase", align: "right" },
    ],
    searched_report_type: "",
  }),

  mounted() {
    this.doGetLeagues()
  },

  computed: {
    totalGames() {
      if (this.records) return this.records.reduce((total, obj) => parseInt(obj.total_games) + total, 0)
      return 0
    },

    totalBettors() {
      if (this.records) return this.records.reduce((total, obj) => parseInt(obj.game_total_bettors) + total, 0)
      return 0
    },

    totalBets() {
      if (this.records) return this.records.reduce((total, obj) => parseInt(obj.game_total_bets) + total, 0)
      return 0
    },

    totalGrossAmount() {
      if (this.records) return this.records.reduce((total, obj) => parseFloat(obj.game_total_bet_amount) + total, 0)
      return 0
    },

    totalDistributedPrizes() {
      if (this.records) return this.records.reduce((total, obj) => parseFloat(obj.game_total_prizes) + total, 0)
      return 0
    },

    totalNetAmount() {
      if (this.records) return this.records.reduce((total, obj) => parseFloat(obj.net_amount) + total, 0)
      return 0
    },

    totalPagcorShare() {
      if (this.records) return this.records.reduce((total, obj) => parseFloat(obj.pagcor_share) + total, 0)
      return 0
    },

    totalNetIncome() {
      if (this.records) return this.records.reduce((total, obj) => parseFloat(obj.net_income) + total, 0)
      return 0
    },

    exportExcelData() {
      return this.records.map((record) => {
        return {
          final_schedule_date: this.scheduleDate(record.final_schedule_date, "Export"),
          total_games: record.total_games,
          total_bettors: record.game_total_bettors,
          total_bets: record.game_total_bets,
          game_total_bet_amount: parseFloat(record.game_total_bet_amount),
          game_total_prizes: parseFloat(record.game_total_prizes),
          net_amount: parseFloat(record.net_amount),
          pagcor_share: parseFloat(record.pagcor_share),
          net_income: parseFloat(record.net_income),
        };
      });
    },
  },

  methods: {
    ...mapActions("logout", ["sendLogoutRequest"]),

    async handleSubmit() {
      if (this.$refs.form.validate()) {
        const formData = new FormData();

        formData.append("schedule_date_start", this.schedule_date_start === null ? "" : this.schedule_date_start)
        formData.append("schedule_date_end", this.schedule_date_end === null ? "" : this.schedule_date_end)
        formData.append("report_type", this.report_type === null ? "" : this.report_type)
        formData.append("filter_by", this.filterBy)
        formData.append("league", this.league)

        const getData = new URLSearchParams(formData);

        // if (!this.recordsLoading) {
        //   this.$store.commit("ending_reports_progress/setRecords", []);

        //   const res = await this.getRecords(getData);

        //   this.searched_report_type = res.report_type;
        // }

        this.records = []
        this.recordsLoading = !this.recordsLoading

        const response = await responseGet(getData, 'ending/reports/progress')

        switch (response.status) {
          case 200:
            this.records = response.data.records
            this.searched_report_type = response.data.report_type
            break;
          case 401:
            await this.sendLogoutRequest();
            this.$router.push({ name: "Home" });
            break;
        }

        this.recordsLoading = !this.recordsLoading
      }
    },

    async doGetLeagues() {
      this.leagues_loading = !this.leagues_loading

      const response = await responseGet('', 'ending/reports/progress/leagues')

      switch (response.status) {
        case 200:
          this.leagues = response.data.records
          this.leagues = [
            { "id": "0", "league": "All" }, ...this.leagues
          ]
          console.log(this.leagues)
          break;
      }

      this.leagues_loading = !this.leagues_loading
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    scheduleDate(sched, type = "Display") {
      if (this.searched_report_type == "Daily") {
        if (type == "Export") {
          return formatDatev2(sched, "format", "yyyy-MM-dd");
        }

        return formatDatev2(sched, "date");
      }

      if (this.searched_report_type == "Weekly") {
        return formatDatev2(sched, "monthweek");
      }

      if (this.searched_report_type == "Monthly") {
        return formatDatev2(sched, "monthyear");
      }

      if (this.searched_report_type == "Yearly") {
        return sched;
      }
    },

    exportExcelDataWithTotal() {
      const dataTotal = {
        final_schedule_date: "TOTAL",
        total_games: this.totalGames,
        total_bettors: this.totalBettors,
        total_bets: this.totalBets,
        game_total_bet_amount: parseFloat(this.totalGrossAmount),
        game_total_prizes: parseFloat(this.totalDistributedPrizes),
        net_amount: parseFloat(this.totalNetAmount),
        pagcor_share: parseFloat(this.totalPagcorShare),
        net_income: parseFloat(this.totalNetIncome),
      };

      return [...this.exportExcelData, dataTotal];
    },

    exportToPDF() {
      html2pdf(this.$refs.datatable, {
        filename: "Progress Report " + formatDatev2(new Date()) + ".pdf",
        image: { type: "jpeg" },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "government-letter", orientation: "portrait" },
      });
    },

    downloadExcelName() {
      return "Progress Report " + formatDatev2(new Date()) + ".xls";
    },

    setPercentage(val, dividend) {
      if (val != 0 && dividend != 0) {
        return GetPercentage(val, dividend);
      }

      return 0;
    },
  },
};
</script>
