var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Investors")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Payouts Settlement")]),_c('v-card',{staticClass:"shadow"},[_c('div',{staticClass:"d-flex justify-end align-center bs-py-3 bs-px-4",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"d-inline-flex align-center justify-end gap"},[_c('v-text-field',{ref:"search",attrs:{"placeholder":"Search","single-line":"","hide-details":"auto","disabled":_vm.records_loading,"clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"black","disabled":_vm.records_loading},on:{"click":_vm.set_filter_dialog}},on),[_c('v-icon',[_vm._v(" mdi-filter-variant ")])],1)]}}])},[_vm._v(" Filter ")])],1)]),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"search":_vm.search,"loading":_vm.records_loading,"loading-text":"Loading, please wait a moment","items-per-page":5,"item-key":"investor_id"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","icon":"","color":"primary","disabled":_vm.records_loading},on:{"click":function($event){return _vm.set_payout_details_dialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-database-eye-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Payouts")])])]}},{key:"item.payout",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(("Php " + (_vm.money_format(parseFloat(item.payout))))))])]}},{key:"item.dates",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((_vm.format_date(item.min_date)) + " — " + (_vm.format_date(item.max_date))))+" ")]}}],null,true)})],1),(_vm.filter_dialog)?_c('PayoutsFilter',{attrs:{"filter_dialog_data":_vm.filter_dialog_data},on:{"set_close_filter_dialog":_vm.set_close_filter_dialog}}):_vm._e(),(_vm.payout_details_dialog)?_c('PayoutDetails',{attrs:{"payout_details_data":_vm.payout_details_data},on:{"set_close_payout_details_dialog":_vm.set_close_payout_details_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }