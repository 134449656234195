<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Settings</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Outlets</h2>
    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="setCreateUpdateDialog('Create', [])"
                >
                  <v-icon>mdi-plus-box-outline</v-icon>
                </v-btn>
              </template>

              <span>Add Outlet</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  @click="loadRecords"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="recordsLoading"
              clearable
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="py-4">
        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="5"
          :search="search"
          :loading="recordsLoading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dense
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  color="primary"
                  @click="setCreateUpdateDialog('Update', item)"
                >
                  <v-icon size="large"> mdi-square-edit-outline </v-icon>
                </v-btn>
              </template>

              <span>Update</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.address`]="{ item }">
            {{ `${item.citymun_desc} ${item.brgy_desc} ${item.address}` }}
          </template>

          <template v-slot:[`item.is_active`]="{ item }">
            <v-chip
              :class="
                item.is_active == 'Active'
                  ? 'green lighten-5 green--text'
                  : 'red lighten-5 red--text'
              "
              >{{ item.is_active }}</v-chip
            >
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ setCreatedAtFormat(item.created_at) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <CreateUpdateDialog
      v-if="createUpdateDialog"
      :data="data"
      @closeDialog="closeDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CreateUpdateDialog from "@/components/outlets/CreateUpdateDialog";
import formatDatev2 from "@/helpers/formatDatev2";

export default {
  components: {
    CreateUpdateDialog,
  },

  data: () => ({
    search: "",
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      {
        text: "Outlet",
        value: "outlet_name",
        class: "text-uppercase",
      },
      {
        text: "Address",
        value: "address",
        class: "text-uppercase",
      },
      {
        text: "Status",
        value: "is_active",
        class: "text-uppercase",
      },
      {
        text: "Created At",
        value: "created_at",
        class: "text-uppercase",
      },
    ],
    sortBy: "created_at",
    sortDesc: true,
    createUpdateDialog: false,
    data: {},
  }),

  mounted() {
    this.loadRecords();
  },

  computed: {
    ...mapState("outlets", ["records", "recordsLoading"]),
  },

  methods: {
    ...mapActions("outlets", ["getRecords"]),

    async loadRecords() {
      this.$store.commit("outlets/setRecords", []);
      await this.getRecords();
    },

    setCreateUpdateDialog(flag, item) {
      this.data["flag"] = flag;

      if (item) {
        this.data["id"] = item.id;
        this.data["outlet_name"] = item.outlet_name;
        this.data["citymun_id"] = item.citymun_id;
        this.data["barangay_id"] = item.barangay_id;
        this.data["address"] = item.address;
        this.data["is_active"] = item.is_active;
      }

      this.createUpdateDialog = true;
    },

    closeDialog(action, data) {
      if (Object.keys(data).length != 0) {
        switch (action) {
          case "create": {
            this.$store.commit("outlets/setRecords", [data, ...this.records]);
            break;
          }
          case "update": {
            const outletData = this.records.find((record) => record.id == data.id);

            outletData.outlet_name = data.outlet_name;
            outletData.citymun_id = data.citymun_id;
            outletData.barangay_id = data.barangay_id;
            outletData.address = data.address;
            outletData.is_active = data.is_active;
            outletData.created_at = data.created_at;
            break;
          }
        }
      }

      this.createUpdateDialog = false;
    },

    setCreatedAtFormat(date) {
      return formatDatev2(date);
    },
  },
};
</script>
