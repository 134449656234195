<template>
  <div class="pa-4 pa-md-6">
    <v-row>
      <v-col cols="12">
        <div class="grey--text" style="font-size: 13px">Ending Games</div>
        <h2 class="font-weight-bold mb-3 mb-md-5">Config</h2>
        <v-card class="shadow">
          <v-card-title>
            <v-row justify="space-between">
              <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="dialog = true"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>

                  <span>Create New</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="secondary"
                      @click="loadRecords"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-1"
                    >
                      <v-icon>mdi-cached</v-icon>
                    </v-btn>
                  </template>
                  <span>Refresh</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" sm="6" md="4" xl="3">
                <v-text-field
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Search"
                  single-line
                  hide-details="auto"
                  :disabled="loading"
                  clearable
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="pa-4">
            <v-data-table
              :headers="headers"
              :items-per-page="5"
              :search="search"
              :items="records"
              :loading="loading"
              loading-text="Loading, please wait a moment"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            >
              <template v-slot:[`item.action`]="{ item }">
                <div class="text-center d-flex align-center justify-space-around">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        small
                        color="primary"
                        @click="setConfigurationDetails(item)"
                      >
                        <v-icon size="large"> mdi-cog-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>Update Configuration</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <Configuration
      v-if="dialog"
      :configurationDetails="configurationDetails"
      @closeModal="setDialogStatus"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Configuration from "@/components/ending/configuration/Configuration";

export default {
  components: { Configuration },
  data: () => ({
    dialog: false,
    search: "",
    sortBy: "id",
    sortDesc: true,
    headers: [
      {
        text: "Actions",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
        width: 90,
      },
      // { text: 'ID', value: 'id', align: 'left' },
      { text: "Name", value: "config_name", class: "text-uppercase" },
      { text: "Description", value: "config_desc", class: "text-uppercase" },
      { text: "Value", value: "config_value", class: "text-uppercase" },
    ],
    insertedData: [],
    configurationDetails: {},
  }),

  created() {
    this.loadRecords();
  },

  computed: {
    ...mapState("ending_configuration", ["loading", "records"]),
  },

  methods: {
    ...mapActions("ending_configuration", ["getRecords"]),
    loadRecords() {
      this.$store.commit("ending_configuration/setRecords", []);
      this.getRecords();
    },
    setDialogStatus(data) {
      this.configurationDetails = {};
      this.dialog = false;

      switch (data.action) {
        case "Submit": {
          this.insertedData = {
            id: data.id,
            config_name: data.name,
            config_desc: data.description,
            config_value: data.value,
          };

          this.$store.commit("ending_configuration/setRecords", [
            this.insertedData,
            ...this.records,
          ]);
          break;
        }
        case "Update": {
          const configuration = this.records.find((record) => record.id == data.id);

          configuration.config_name = data.name;
          configuration.config_desc = data.description;
          configuration.config_value = data.value;
          break;
        }
      }
    },
    setConfigurationDetails(item) {
      this.configurationDetails = item;
      this.dialog = true;
    },
  },
};
</script>
