<template>
    <v-dialog v-model="dialog" max-width="600">
        <v-card :loading="removePlayerLoading">
            <v-card-title class="text-h5"> Reset Password
                <v-btn icon absolute right @click="setCloseModal">
                    <v-icon size="large">
                    mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="py-4">
                <v-form ref="form"
                    v-model="valid"
                    lazy-validation
                    :disabled="removePlayerLoading"
                    @submit.prevent="submit">

                    <v-alert
                        dense
                        border="left"
                        type="warning"
                        v-if="errorMessage">
                        {{ errorMessage }}
                    </v-alert>

                    <v-row dense class="mt-2">
                        <v-col>
                            <v-text-field
                                ref="password"
                                outlined
                                dense
                                label="Password"
                                :rules="(submitStatus) ? [rules.required, rules.minimum(3), rules.strongPassword] : []"
                                v-model="password"
                                :append-icon="passwordVisibility ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="passwordVisibility ? 'text' : 'password'"
                                @click:append="passwordVisibility = !passwordVisibility"
                                hide-details="auto"/>
                        </v-col>
                    </v-row>

                    <v-row dense class="mt-3">
                        <v-col>
                            <v-text-field
                                ref="confirm_password"
                                outlined
                                dense
                                label="Confirm Password"
                                :rules="[rules.required, rules.minimum(3), rules.strongPassword, passwordConfirmationRule]"
                                v-model="confirm_password"
                                :append-icon="passwordVisibility2 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="passwordVisibility2 ? 'text' : 'password'"
                                @click:append="passwordVisibility2 = !passwordVisibility2"
                                hide-details="auto"/>
                        </v-col>
                    </v-row>

                    <v-row dense class="mt-3">
                        <v-col cols="12" md="6">
                            <v-btn block depressed color="primary" class="ma-1" type="submit" :disabled="removePlayerLoading">
                                Submit
                            </v-btn>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-btn block depressed class="ma-1" @click="setCloseModal" :disabled="removePlayerLoading">
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";

export default {
    props: {
        agent_id: {
            type: Number
        },
    },

    data: () => ({
        valid: true,
        password: '',
        confirm_password: '',
        submitStatus: false,
        rules: formRules,
        passwordVisibility: false,
        passwordVisibility2: false,
        errorMessage: ''
    }),

    computed: {
        ...mapState("agents", ["removePlayerLoading", "error", "errors"]),

        dialog: {
            get: function () {
                return true;
            },
            set: function () {
                this.setCloseModal();
            },
        },

        passwordConfirmationRule() {
            return () => this.password === this.confirm_password || "Password must match"
        }
    },

    mounted() {
        this.$refs.password.focus()
    },

    methods: {
        ...mapActions("agents", ["resetPassword"]),

        setCloseModal() {
            this.$emit("closeResetPasswordModal")
        },

        async submit() {
            this.errorMessage = ''
            this.submitStatus = true

            await new Promise(r => setTimeout(r, 200));

            if (this.$refs.form.validate()) {
                if (!this.removePlayerLoading) {
                    await this.resetPassword(this.setResetPasswordFormData())

                    if (!this.error && this.isObjectEmpty(this.errors)) {
                        this.setCloseModal()
                    } else this.setErrors()
                }
            }
        },

        setResetPasswordFormData() {
            const formData = new FormData()
            formData.append("agent_id", this.agent_id)
            formData.append("password", this.password)
            formData.append("confirm_password", this.confirm_password)

            return formData
        },

        setErrors() {
            let errorFocus = "";

            Object.entries(this.errors).forEach(([key, value]) => {
                console.log(key + ' ' + value)
                if (value) {
                    if (!errorFocus) {
                        errorFocus = (key == 'error') ? 'password' : key;
                    }

                    switch (key) {
                        case "error":
                            this.errorMessage = "The new password must not equal the old password."
                            break;
                    }

                    setTimeout(() => {
                        this.$refs.form.validate();
                        this.$refs[errorFocus].focus();
                    }, 200);
                }
            });
        },   

        isObjectEmpty(obj) {
            return Object.keys(obj).length === 0;
        },
    },

    watch: {
        errorMessage(val) {
            if (val) {
                setTimeout(() => {
                    this.errorMessage = ''
                }, 3000);
            }
        }
    }
}
</script>