<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Agent Reports</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Progress</h2>
    <v-card class="shadow mb-4">
      <v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
        Select Bet Date
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="py-4">
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="handleSubmit">
          <v-row>
            <v-col cols="12" md="6">
              <v-menu
                v-model="date_from_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_from"
                    :rules="[rules.required, lessThanDate]"
                    label="Bet Date From"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @keyup="date_from_menu = true"
                    @keydown="date_from_menu = false"
                    clearable
                  ></v-text-field>
                </template>

                <v-date-picker v-model="date_from" @input="date_from_menu = false" />
              </v-menu>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                v-model="date_to_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_to"
                    :rules="[lessThanDate2]"
                    label="Bet Date To (Optional)"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :disabled="dateToAvailability"
                    @keyup="date_to_menu = true"
                    @keydown="date_to_menu = false"
                    clearable
                  />
                </template>

                <v-date-picker
                  v-model="date_to"
                  @input="date_to_menu = false"
                  :min="date_from ? date_from : ''"
                />
              </v-menu>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-radio-group
                :rules="[rules.required]"
                hide-details="auto"
                v-model="report_type"
                row
              >
                <v-row dense>
                  <v-col cols="auto" class="ma-1">
                    <v-radio label="Daily" value="Daily" />
                  </v-col>

                  <v-col cols="auto" class="ma-1">
                    <v-radio label="Weekly" value="Weekly" />
                  </v-col>

                  <v-col cols="auto" class="ma-1">
                    <v-radio label="Monthly" value="Monthly" />
                  </v-col>

                  <v-col cols="auto" class="ma-1">
                    <v-radio label="Yearly" value="Yearly" />
                  </v-col>
                </v-row>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row dense class="mt-4">
            <v-col>
              <v-btn
                :disabled="!valid"
                type="submit"
                color="primary"
                outlined
                :loading="loading"
                :block="$vuetify.breakpoint.mdAndUp ? false : true"
              >
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="shadow">
      <v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
        {{ searched_report_type }} Report
        <v-spacer></v-spacer>
        <!-- <div>
                            <downloadexcel
                                :data="exportExcelDataWithTotal()" 
                                :fields="{
                                'Schedule Date': 'final_schedule_date',
                                'Total Games': 'total_games',
                                'Total Gross Sales': 'game_total_bet_amount',
                                'Total Payouts': 'game_total_prizes',
                                'Total Gross Gaming Revenue': 'net_amount',
                                'Total PAGCOR Share': 'pagcor_share'
                                }"
                                :name="downloadExcelName()"
                                type="csv"
                                class="d-inline-block"
                                v-if="records.length"
                            >
                                <v-btn
                                color="primary"
                                outlined
                                small
                                depressed
                                
                                >
                                Export CSV
                                </v-btn>
                            </downloadexcel>
                        <v-btn
                            color="primary"
                            outlined
                            small
                            depressed
                            @click="exportToPDF"
                            class="ms-2 d-inline-block"
                            v-if="records.length"
                        >
                            Export PDF
                        </v-btn>
                        </div> -->
      </v-card-title>
      <v-divider></v-divider>
      <div ref="datatable">
        <v-card-text class="pa-4">
          <v-data-table
            :headers="headers"
            :items="records"
            :items-per-page="-1"
            :loading="loading"
            loading-text="Loading, please wait a moment"
            mobile-breakpoint="0"
            hide-default-footer
          >
            <template v-slot:[`item.final_schedule_date`]="{ item }">
              <span>{{ scheduleDate(item.final_schedule_date) }}</span>
            </template>

            <template v-slot:[`item.total_bets`]="{ item }">
              {{ parseInt(item.total_bets).toLocaleString() }}
            </template>

            <template v-slot:[`item.total_bets_amount`]="{ item }">
              PHP {{ moneyFormat(parseFloat(item.total_bets_amount)) }}
            </template>

            <template v-if="records.length" v-slot:[`body.append`]>
              <tr>
                <td class="text-right"><strong>TOTAL</strong></td>
                <td>
                  <strong>{{ totalGames.toLocaleString() }}</strong>
                </td>
                <td>
                  <strong>{{ "PHP " + moneyFormat(parseFloat(totalBetsAmount)) }}</strong>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import formatDatev2 from "@/helpers/formatDatev2";
import { format, sub } from "date-fns";
import formRules from "@/helpers/formRules";

export default {
  data: () => ({
    valid: true,
    rules: formRules,
    date_from: format(sub(new Date(), { days: 30 }), "yyyy-MM-dd"),
    date_to: format(new Date(), "yyyy-MM-dd"),
    date_from_menu: false,
    date_to_menu: false,
    report_type: "Daily",
    headers: [
      { text: "Bet Date", value: "final_schedule_date", class: "text-uppercase" },
      { text: "Total Bets", value: "total_bets", class: "text-uppercase" },
      { text: "Total Bet Amount", value: "total_bets_amount", class: "text-uppercase" },
    ],
    searched_report_type: "",
  }),

  created() {
    const urlQuery = this.$route.query;

    if (Object.keys(urlQuery).length) {
      this.report_type = urlQuery.report_type;
      this.date_from = urlQuery.date_from;
      this.date_to = urlQuery.date_to;
    }

    this.loadRecords();
  },

  computed: {
    ...mapState("agents_report", ["loading", "error", "errors", "records"]),

    lessThanDate() {
      if (this.date_from && this.date_to) {
        if (new Date(this.date_to) < new Date(this.date_from)) {
          return "Must be higher or equal than date from.";
        }
      }

      return true;
    },

    lessThanDate2() {
      if (this.date_from && this.date_to) {
        if (new Date(this.date_from) > new Date(this.date_to)) {
          return "Must be lessthan or equal than date to.";
        }
      }

      return true;
    },

    dateToAvailability() {
      if (this.date_from == "" || this.date_from == null) {
        return true;
      }

      return false;
    },

    totalGames() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseInt(obj.total_bets) + total, 0);
      } else {
        return 0;
      }
    },

    totalBetsAmount() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.total_bets_amount) + total,
          0
        );
      } else {
        return 0;
      }
    },
  },

  methods: {
    ...mapActions("agents_report", ["getProgress"]),

    async loadRecords() {
      this.$store.commit("agents_report/setRecords", []);
      await this.getProgress(this.setProgressFormData());

      if (!this.error && this.isObjectEmpty(this.errors)) {
        this.searched_report_type = this.report_type;
      }
    },

    async handleSubmit() {
      if (this.$refs.form.validate()) {
        this.$store.commit("agents_report/setRecords", []);
        await this.getProgress(this.setProgressFormData());

        if (!this.error && this.isObjectEmpty(this.errors)) {
          this.searched_report_type = this.report_type;
          this.setQueryString();
        }
      }
    },

    setProgressFormData() {
      const formData = new FormData();
      formData.append("report_type", this.report_type);
      formData.append("date_from", this.date_from);
      formData.append("date_to", this.date_to);
      const getData = new URLSearchParams(formData);

      return getData;
    },

    scheduleDate(sched) {
      if (this.searched_report_type == "Daily") {
        return formatDatev2(sched, "date");
      }

      if (this.searched_report_type == "Weekly") {
        return formatDatev2(sched, "monthweek");
      }

      if (this.searched_report_type == "Monthly") {
        return formatDatev2(sched, "monthyear");
      }

      if (this.searched_report_type == "Yearly") {
        return sched;
      }
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    setQueryString() {
      const urlQuery = this.$route.query;

      if (
        urlQuery.date_from != this.date_from ||
        urlQuery.date_to != this.date_to ||
        urlQuery.report_type != this.report_type
      ) {
        this.query_filters = {
          report_type: this.report_type,
          date_from: this.date_from,
          date_to: this.date_to,
        };

        this.$router.push({
          name: this.$route.name,
          query: this.query_filters,
        });
      }
    },

    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
  },

  watch: {
    date_from(val) {
      if (val == "" || val == null) {
        this.date_to = "";
      }
    },
  },
};
</script>
