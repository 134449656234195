<template>
    <v-dialog v-model="dialog" max-width="800">
        <v-card>
            <v-card-title class="text-h5"> Create League </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="py-4">
                <v-form ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="handleSubmit">
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Name"
                            ref="league"
                            outlined
                            dense
                            :rules="[
                            rules.required,
                            rules.alphaSpace,
                            rules.minimum(3),
                            leagueExist
                            ]"
                            v-model="league"
                            hide-details="auto"/>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Logo Url"
                            ref="logo"
                            outlined
                            dense
                            :rules="[
                            rules.required,
                            rules.minimum(3),
                            ]"
                            v-model="logo"
                            hide-details="auto"/>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="6">
                        <v-radio-group v-model="isLocal"
                            row
                            hide-details="auto">
                            <v-radio
                                label="Local"
                                value="Local"></v-radio>
                            <v-radio
                                label="International"
                                value="International"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" md="6">
                    <v-switch
                        v-model="status"
                        color="success"
                        :label="`Status: ${statusText}`"
                        @change="setToggleStatus"
                        class="d-inline-block"
                    />
                    </v-col>

                    <v-col cols="12" md="6">
                        <div class="mt-4 text-right">
                            <v-btn
                            color="black"
                            outlined
                            class="me-2"
                            @click.stop="setData"
                            >
                            Cancel
                            </v-btn>

                            <v-btn
                            type="submit"
                            color="primary"
                            outlined
                            :loading="loading"
                            >
                            {{ buttonText }}
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>

                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex"
import formRules from "@/helpers/formRules"

export default {
    props: {
        leagueData: {
            type: Object,
        },
    },
    data: () => ({
        actionFlag: 1,
        valid: true,
        rules: formRules,
        id: '',
        league: '',
        leagueText: '',
        leagueTextExist: '',
        logo: '',
        status: true,
        statusText: 'Active',
        isLocal: 'Local',
        buttonText: 'Create',
        insertedData: [],
        updatedData: []
    }),
    mounted() {
        if (Object.keys(this.leagueData).length != 0) {
            this.id = this.leagueData.league_id
            this.league = this.leagueData.league
            this.logo = this.leagueData.logo
            this.isLocal = this.leagueData.is_local
            this.status = this.leagueData.is_active === "Active" ? true : false
            this.buttonText = "Update";
        }

        setTimeout(() => {
            this.$refs.league.focus();
        }, 300);
    },
    computed: {
        ...mapState("ending_leagues", ["loading", "leagueId", "error", "errors"]),
        dialog: {
            get: function () {
                return true
            },
            // setter
            set: function () {
                this.setData()
            },
        },
    },
    methods: {
        ...mapActions("ending_leagues", ["createLeague", "updateLeague"]),
        setData() {
            this.$emit("setData", this.insertedData, this.updatedData)
        },
        async handleSubmit() {
            if (this.$refs.form.validate()) {
                if (!this.loading) {
                    switch(this.isObjectEmpty(this.leagueData)) {
                        case true: 
                            await this.createLeague(this.setFormData())

                            if (!this.error && this.isObjectEmpty(this.errors)) {
                                this.setInsertedData()
                                this.setData()
                                this.$refs.form.reset();
                            } else this.setErrors()
                            break;
                        case false:
                            await this.updateLeague(this.setFormData())

                            if (!this.error && this.isObjectEmpty(this.errors)) {
                                this.setUpdatedData()
                                this.setData()
                                this.$refs.form.reset();
                            } else this.setErrors()
                            break;
                    }
                }
            }
        },
        setFormData() {
            const formData = new FormData()

            if (!this.isObjectEmpty(this.leagueData)) {
                formData.append("league_id", this.id)
            }

            formData.append("league", this.league)
            formData.append("logo", this.logo)
            formData.append("is_local", this.isLocal)
            formData.append("is_active", this.status === true ? "Active" : "Inactive")

            return formData
        },
        setErrors() {
            console.log(this.isObjectEmpty(this.errors))
            let errorFocus = "";

            Object.entries(this.errors).forEach(([key, value]) => {
                if (value) {
                    if (!errorFocus) {
                        errorFocus = key;
                    }

                    if (key == "league") {
                        this.leagueTextExist = this.league
                    }

                    this.$refs.form.validate();
                    this.$refs[errorFocus].focus();
                }
            });
        },
        setInsertedData() {
            var today = new Date();

            this.insertedData = {
                league_id: this.leagueId,
                league: this.league,
                logo: this.logo,
                is_local: this.isLocal,
                is_active: this.status === "Active" ? "Active" : "Inactive",
                created_at: today,
            };
        },
        setUpdatedData() {
            this.updatedData = {
                "league_id": this.id,
                "league": this.league,
                "logo": this.logo,
                "is_local": this.isLocal,
                "is_active": this.status === true ? 'Active' : "Inactive"
            }
        },
        leagueExist(val) {
            if (val == this.leagueTextExist) {
                return `"${val}" already exist`;
            } else return true;
        },
        setToggleStatus() {
            this.statusText = this.status === true ? "Active" : "Inactive";
        },
        isObjectEmpty(obj) {
            return Object.keys(obj).length === 0;
        },
    }
}
</script>

