<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Agents</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Cash In</h2>
    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <v-tooltip top v-if="accountInfo.access.includes(28)">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="setManualModal"
                >
                  <v-icon>mdi-cash-plus</v-icon>
                </v-btn>
              </template>

              <span>Manual Cash In</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                  @click="loadRecords"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="loading"
              clearable
              outlined
              dense
            >
              <template v-slot:append-outer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      color="secondary"
                      @click="showFilter"
                      v-on="on"
                      small
                      :disabled="loading"
                    >
                      <v-icon>mdi-filter-variant</v-icon>
                    </v-btn>
                  </template>
                  Filter
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-4">
        <v-row dense class="mt-2">
          <v-col cols="12" md="3" align="center" class="px-1">
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL CASHIN</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                PHP {{ parseInt(totalCashIn).toLocaleString() }}
              </p>
            </v-card>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="5"
          :search="search"
          :loading="loading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.action`]="{ item }">
            <div class="text-center d-flex align-center justify-space-around">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                    color="primary"
                    @click="setViewDetails(item, true)"
                  >
                    <v-icon size="large"> mdi-account-details </v-icon>
                  </v-btn>
                </template>
                <span>View Details</span>
              </v-tooltip>

              <v-tooltip
                v-if="item.status == 'Pending' && accountInfo.access.includes(42)"
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                    color="primary"
                    @click="setViewDetails(item, false)"
                    class="d-none d-sm-inline"
                  >
                    <v-icon size="large"> mdi-folder-check-outline </v-icon>
                  </v-btn>
                </template>
                <span>Verify</span>
              </v-tooltip>

              <v-btn
                v-else-if="accountInfo.access.includes(42)"
                icon
                small
                style="pointer-events: none"
                class="d-none d-sm-inline"
              >
                <v-icon size="large"> mdi-folder-check-outline </v-icon>
              </v-btn>

              <v-btn
                v-if="item.status == 'Pending' && accountInfo.access.includes(42)"
                outlined
                small
                color="primary"
                @click="setViewDetails(item, false)"
                class="d-inline d-sm-none"
              >
                Verify
              </v-btn>

              <v-btn
                v-else-if="accountInfo.access.includes(42)"
                depressed
                outlined
                small
                color="primary"
                disabled
                class="d-inline d-sm-none"
              >
                Verify
              </v-btn>
            </div>
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            PHP {{ moneyFormat(parseFloat(item.amount)) }}
          </template>

          <template v-slot:[`item.evaluated_at`]="{ item }">
            {{ setCreatedAtFormat(item.evaluated_at) }}
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            {{ setCreatedAtFormat(item.created_at) }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              small
              :class="
                item.status == 'Approved'
                  ? 'green lighten-5 green--text'
                  : item.status == 'Pending'
                  ? 'orange lighten-5 orange--text'
                  : 'red lighten-5 red--text'
              "
            >
              {{ item.status }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <FilterDate
      v-if="filterDialog"
      :data_filters="data_filters"
      @setFilterCloseModal="setFilterCloseModal"
    />
    <Validation
      v-if="validationDialog"
      :agent_data="agent_data"
      :viewStatus="viewStatus"
      @setValidationCloseModal="setValidationCloseModal"
    />
    <Manual v-if="manualDialog" @setManualCloseModal="setManualCloseModal" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { format } from "date-fns";
import formatDatev2 from "@/helpers/formatDatev2";
import FilterDate from "@/components/agents_cashin/Filter";
import Validation from "@/components/agents_cashin/Validation";
import Manual from "@/components/agents_cashin/Manual";

export default {
  components: {
    FilterDate,
    Validation,
    Manual,
  },

  data: () => ({
    search: "",
    filter_by: "All",
    date_from: format(new Date(), "yyyy-MM-dd"),
    date_to: format(new Date(), "yyyy-MM-dd"),
    sortBy: "created_at",
    sortDesc: true,
    filterDialog: false,
    validationDialog: false,
    manualDialog: false,
    agent_data: {},
    viewStatus: false,
    data_filters: {},
    headers: [
      {
        text: "Actions",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
        width: 90,
      },
      // { text: 'ID', value: 'id', align: 'left' },
      {
        text: "Agent Name",
        value: "agent_name",
        class: "text-uppercase",
        sortable: false,
      },
      {
        text: "Agent Type",
        value: "agent_type",
        class: "text-uppercase",
        sortable: false,
      },
      {
        text: "Player Name",
        value: "player_name",
        class: "text-uppercase",
        sortable: false,
      },
      // { text: "Player Type", value: "player_type", class: "text-uppercase" },
      { text: "Payment Method", value: "payment_method", class: "text-uppercase" },
      { text: "Amount", value: "amount", class: "text-uppercase" },
      { text: "Notes", value: "notes", class: "text-uppercase" },
      { text: "Remarks", value: "remarks", class: "text-uppercase" },
      // { text: "Evaluated By", value: "evaluated_by", class: "text-uppercase" },
      { text: "Evaluated At", value: "evaluated_at", class: "text-uppercase" },
      { text: "Status", value: "status", class: "text-uppercase" },
      { text: "Created At", value: "created_at", class: "text-uppercase" },
    ],
  }),

  created() {
    const urlQuery = this.$route.query;

    if (Object.keys(urlQuery).length) {
      this.date_from = urlQuery.date_from;
      this.date_to = urlQuery.date_to;
      this.filter_by = urlQuery.filter_by;
    }

    this.loadRecords();
  },

  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("agents_cashin", ["loading", "records"]),

    totalCashIn() {
      if (this.records) {
        //return this.records.reduce((total, obj) => ((this.player_type == "Player") ? ((obj.user_type == this.player_type.toUpperCase()) ? parseFloat(obj.total_cashin) : 0) : 0) + total,0)
        return this.records.reduce((total, obj) => parseFloat(obj.amount) + total, 0);
      } else {
        return 0;
      }
    },
  },

  methods: {
    ...mapActions("agents_cashin", ["getRecords"]),

    async loadRecords() {
      if (!this.recordsLoading) {
        this.$store.commit("agents_cashin/setRecords", []);

        await this.getRecords(this.setRecordsFormData());
      }
    },

    setRecordsFormData() {
      const formData = new FormData();
      formData.append("date_from", this.date_from);
      formData.append("date_to", this.date_to);
      formData.append("filter_by", this.filter_by);

      const getData = new URLSearchParams(formData);

      return getData;
    },

    setFilterCloseModal(data_filter) {
      this.filterDialog = false;

      if (data_filter.status == "search") {
        const urlQuery = this.$route.query;

        this.date_from = data_filter.date_from;
        this.date_to = data_filter.date_to;
        this.filter_by = data_filter.filter_by;

        this.data_filters = {
          date_from: this.date_from,
          date_to: this.date_to,
          filter_by: this.filter_by,
        };

        if (
          urlQuery.date_from != this.date_from ||
          urlQuery.date_to != this.date_to ||
          urlQuery.filter_by != this.filter_by
        ) {
          this.$router.push({
            name: this.$route.name,
            query: this.data_filters,
          });
        }

        this.loadRecords();
      }
    },

    showFilter() {
      this.data_filters = {
        date_from: this.date_from,
        date_to: this.date_to,
        filter_by: this.filter_by,
      };

      this.filterDialog = true;
    },

    setCreatedAtFormat(date) {
      return formatDatev2(date);
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    setViewDetails(item, status) {
      this.agent_data = item;
      this.viewStatus = status;
      this.validationDialog = true;
    },

    setValidationCloseModal(data) {
      switch (data.action) {
        case "Approve":
          this.records.find((record) => record.id == data.id).status = "Approved";

          // if (!this.records.find((record) => record.status == 'Pending')) {
          //     this.loadPending()
          // }
          break;
        case "Disapprove":
          this.records.find((record) => record.id == data.id).status = "Disapproved";
          break;
      }

      this.validationDialog = false;
    },

    setManualModal() {
      this.manualDialog = true;
    },

    setManualCloseModal() {
      this.manualDialog = false;
    },
  },
};
</script>
