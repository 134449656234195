<template>
    <v-dialog scrollable v-model="dialog" max-width="800px">
        <v-card>
            <v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
                <v-icon class="mr-2"> mdi-seal-variant </v-icon> 

                Winners (Slot {{ filters.slot }})

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon absolute right @click="closeModal">
                            <v-icon v-bind="attrs"
                                v-on="on">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Close</span>
                </v-tooltip>
            </v-card-title>

            <v-divider/>

            <v-card-text class="py-4">
                <v-row dense>
                    <v-col
                        cols="12"
                        md="6"
                    >
                        <downloadexcel
                            :data="winners"
                            :fields="{
                                'Bet Type': 'bet_type',
                                'Name': 'name',
                                'Bet Amount': 'bet_amount',
                                'Total Payout': 'total_payout',
                            }"
                            name="Combination Winners.xls"
                            type="csv"
                            class="d-inline-block"
                        >
                            <v-btn outlined color="primary">
                                Export CSV
                            </v-btn>
                        </downloadexcel>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            placeholder="Search"
                            single-line
                            hide-details="auto"
                            clearable
                            outlined
                            dense
                        >
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-divider class="mt-4"/>

                <v-row dense>
                    <v-col align="center" cols="12" md="4" class="py-4">
                        <v-card class="pa-1">
                        <p class="font-weight-regular ma-0 overline">
                            TOTAL NUMBER OF WINNERS 
                        </p>
                        <v-divider class="mb-4" />
                        <p class="font-weight-medium">
                            {{ parseInt(winners.length).toLocaleString() }}
                        </p>
                        </v-card>
                    </v-col>

                    <v-col align="center" cols="12" md="4" class="py-4">
                        <v-card class="pa-1">
                        <p class="font-weight-regular ma-0 overline">
                            TOTAL BET AMOUNT
                        </p>
                        <v-divider class="mb-4" />
                        <p class="font-weight-medium">
                            PHP {{ moneyFormat(parseFloat(totalBetAmount)) }}
                        </p>
                        </v-card>
                    </v-col>

                    <v-col align="center" cols="12" md="4" class="py-4">
                        <v-card class="pa-1">
                        <p class="font-weight-regular ma-0 overline">
                            TOTAL PAYOUTS
                        </p>
                        <v-divider class="mb-4" />
                        <p class="font-weight-medium">
                            PHP {{ moneyFormat(parseFloat(totalPayouts)) }}
                        </p>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col>
                        <v-data-table
                            :headers="headers"
                            :items="winners"
                            :items-per-page="5"
                            :search="search"
                            :loading="winnersLoading"
                            loading-text="Loading, please wait a moment"
                        >
                           
                            <template v-slot:[`item.bet_amount`]="{ item, }">
                                <span>PHP {{ moneyFormat(parseFloat(item.bet_amount)) }} </span>
                            </template>

                            <template v-slot:[`item.total_payout`]="{ item, }">
                                <span>PHP {{ moneyFormat(parseFloat(item.total_payout)) }} </span>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import downloadexcel from "vue-json-excel";

export default {
    props: {
        filters: {
            type: Object
        }
    },

    components: { downloadexcel },

    data: () => ({
        search: '',
        headers: [
            {
                text: "Bet Type",
                value: "bet_type",
                class: "text-uppercase",
                align: "left",
            },
            {
                text: "Name",
                value: "name",
                class: "text-uppercase",
                align: "left",
            },
            {
                text: "Bet Amount",
                value: "bet_amount",
                class: "text-uppercase",
                align: "right",
            },
            {
                text: "Total Payout",
                value: "total_payout",
                class: "text-uppercase",
                align: "right",
            },
        ],
    }),

    mounted() {
        this.$store.commit("ending_reports_top_winning_combination/setWinners", [])
    },

    computed: {
        ...mapState("ending_reports_top_winning_combination", ["winners", "winnersLoading"]),

        dialog: {
            get: function() {
                return true
            },
            set: function () {
                this.closeModal()
            }
        },

		totalBetAmount() {
            if (this.winners) {
                return this.winners.reduce((total, obj) => parseFloat(obj.bet_amount) + total ,0)
            } else {
                return 0
            }
        },

		totalPayouts() {
            if (this.winners) {
                return this.winners.reduce((total, obj) => parseFloat(obj.total_payout) + total ,0)
            } else {
                return 0
            }
        },
    },

    created() {
        this.doGetWinners()
    },

    methods: {
        ...mapActions("ending_reports_top_winning_combination", ["getWinners"]),

        async doGetWinners() {
            await this.getWinners(this.setFormData())
        },

        setFormData() {
            const formData = new FormData()

            formData.append("slot_id", this.filters.slot_id)
            formData.append("date_from", this.filters.date_from)
            formData.append("date_to", this.filters.date_to)

            const urlData = new URLSearchParams(formData)

            return urlData
        },

        moneyFormat(val) {
            return val.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },

        closeModal() {
            this.$emit("setCloseModal")
        }
    }
}
</script>