<template>
  <div class="revenue">
    <v-card class="shadow">
      <div
        class="d-flex flex-column flex-md-row justify-md-space-between align-md-center gap bs-py-3 bs-px-4"
      >
        <h3>Revenue</h3>
        <div class="d-flex align-center" style="gap: 0.5rem">
          <v-btn
            text
            :to="{
              name: game_id == 3 ? 'LuckyFourGameReport' : 'GameReport',
              query: { date_from: filterFrom, date_to: filterTo },
            }"
          >
            View
          </v-btn>
          <v-menu offset-y transition="slide-y-transition" left open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                class="text-uppercase px-4"
                :disabled="revenueLoading"
              >
                {{ filter }}
                <v-icon size="22" right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in filters"
                :key="item"
                @click="changeFilter(item)"
                :class="
                  item == filter ? 'v-list-item--active primary--text' : ''
                "
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="pa-6">
        <v-row no-gutters>
          <v-col cols="12" md="4" class="mb-6 mb-md-0">
            <div class="d-flex flex-column" style="gap: 1rem">
              <div class="d-flex align-center" style="gap: 1rem">
                <v-avatar
                  class="rounded"
                  size="67"
                  color="purple lighten-5 "
                  tile
                >
                  <v-icon color="purple"> mdi-chart-timeline </v-icon>
                </v-avatar>
                <div>
                  <div
                    class="grey--text"
                    style="font-size: 13px; margin-top: 4px"
                  >
                    Gross Sales
                  </div>
                  <h2 class="dark--text">
                    {{
                      revenue.game_total_bet_amount
                        ? `₱${moneyFormat(
                            parseFloat(revenue.game_total_bet_amount)
                          )}`
                        : "₱0.00"
                    }}
                  </h2>
                </div>
              </div>

              <div class="d-flex align-center" style="gap: 1rem">
                <v-avatar
                  class="rounded"
                  size="67"
                  color="teal lighten-5 "
                  tile
                >
                  <v-icon color="teal"> mdi-cash </v-icon>
                </v-avatar>
                <div>
                  <div
                    class="grey--text"
                    style="font-size: 13px; margin-top: 4px"
                  >
                    Winnings
                  </div>
                  <h2 class="dark--text">
                    {{
                      revenue.game_total_winnings
                        ? `₱${formattedNumber(
                            parseInt(revenue.game_total_winnings)
                          )}`
                        : "₱0.00"
                    }}
                  </h2>
                </div>
              </div>

              <div class="d-flex align-center mb-4 mb-md-9" style="gap: 1rem">
                <v-avatar
                  class="rounded"
                  size="67"
                  color="pink lighten-5 "
                  tile
                >
                  <v-icon color="pink"> mdi-hand-coin-outline </v-icon>
                </v-avatar>
                <div>
                  <div
                    class="grey--text"
                    style="font-size: 13px; margin-top: 4px"
                  >
                    Payouts
                  </div>
                  <h2 class="dark--text">
                    {{
                      revenue.game_total_prizes
                        ? `₱${formattedNumber(
                            parseFloat(revenue.game_total_prizes)
                          )}`
                        : "₱0.00"
                    }}
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <small
                          v-bind="attrs"
                          v-on="on"
                          style="
                            font-weight: normal;
                            font-size: 12px;
                            padding-left: 4px;
                          "
                        >
                          <span
                            v-if="
                              !revenueLoading &&
                              revenue.game_total_bet_amount !== 0
                            "
                            :class="
                              formattedNumber(
                                (revenue.game_total_prizes /
                                  revenue.game_total_bet_amount) *
                                  100
                              ) < 50
                                ? 'green--text'
                                : 'red--text'
                            "
                          >
                            {{
                              `${formattedNumber(
                                (revenue.game_total_prizes /
                                  revenue.game_total_bet_amount) *
                                  100
                              )}%`
                            }}
                          </span>
                        </small>
                      </template>
                      <span>
                        {{
                          `${formattedNumber(
                            (revenue.game_total_prizes /
                              revenue.game_total_bet_amount) *
                              100
                          )}%`
                        }}
                        of Gross Sales
                      </span>
                    </v-tooltip>
                  </h2>
                </div>
              </div>
              <div class="d-flex align-center" style="gap: 1rem">
                <v-avatar
                  class="rounded"
                  size="67"
                  color="orange lighten-5 "
                  tile
                >
                  <v-icon color="orange"> mdi-camera-switch-outline </v-icon>
                </v-avatar>
                <div>
                  <div
                    class="grey--text"
                    style="font-size: 13px; margin-top: 4px"
                  >
                    Gross Gaming Revenue
                  </div>
                  <h2 class="dark--text">
                    {{
                      revenue.net_amount
                        ? `₱${moneyFormat(revenue.net_amount)}`
                        : "₱0.00"
                    }}

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <small
                          v-bind="attrs"
                          v-on="on"
                          style="
                            font-weight: normal;
                            font-size: 12px;
                            padding-left: 4px;
                          "
                        >
                          <span
                            v-if="
                              !revenueLoading &&
                              revenue.game_total_bet_amount !== 0
                            "
                            :class="
                              formattedNumber(
                                (revenue.net_amount /
                                  revenue.game_total_bet_amount) *
                                  100
                              ) > 50
                                ? 'green--text'
                                : 'red--text'
                            "
                          >
                            {{
                              `${formattedNumber(
                                (revenue.net_amount /
                                  revenue.game_total_bet_amount) *
                                  100
                              )}%`
                            }}
                          </span>
                        </small>
                      </template>
                      <span>
                        {{
                          `${formattedNumber(
                            (revenue.net_amount /
                              revenue.game_total_bet_amount) *
                              100
                          )}%`
                        }}
                        of Gross Sales
                      </span>
                    </v-tooltip>
                  </h2>
                </div>
              </div>
              <div class="d-flex align-center" style="gap: 1rem">
                <v-avatar
                  class="rounded"
                  size="67"
                  color="blue lighten-5 "
                  tile
                >
                  <v-icon color="primary"> mdi-share-variant-outline </v-icon>
                </v-avatar>
                <div>
                  <div
                    class="grey--text"
                    style="font-size: 13px; margin-top: 4px"
                  >
                    PAGCOR Share
                  </div>
                  <h2 class="dark--text">
                    <span v-if="revenue.pagcor_share">{{
                      `₱${formattedNumber(revenue.pagcor_share)}`
                    }}</span>
                    <span v-else> ₱0.00 </span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <small
                          v-bind="attrs"
                          v-on="on"
                          style="
                            font-weight: normal;
                            font-size: 12px;
                            padding-left: 4px;
                          "
                        >
                          <span
                            v-if="
                              !revenueLoading &&
                              revenue.game_total_bet_amount !== 0
                            "
                            :class="
                              formattedNumber(
                                (revenue.pagcor_share /
                                  revenue.game_total_bet_amount) *
                                  100
                              ) < 50
                                ? 'green--text'
                                : 'red--text'
                            "
                          >
                            {{
                              `${formattedNumber(
                                (revenue.pagcor_share /
                                  revenue.game_total_bet_amount) *
                                  100
                              )}%`
                            }}
                          </span>
                        </small>
                      </template>
                      <span
                        >{{
                          `${formattedNumber(
                            (revenue.pagcor_share /
                              revenue.game_total_bet_amount) *
                              100
                          )}%`
                        }}
                        of total gross
                      </span>
                    </v-tooltip>
                  </h2>
                </div>
              </div>
              <div class="d-flex align-center" style="gap: 1rem">
                <v-avatar
                  class="rounded"
                  size="67"
                  color="light-blue lighten-5"
                  tile
                >
                  <v-icon color="primary"> mdi-share-variant-outline </v-icon>
                </v-avatar>
                <div>
                  <div
                    class="grey--text"
                    style="font-size: 13px; margin-top: 4px"
                  >
                    Daily PAGCOR Share
                  </div>
                  <h2 class="dark--text">
                    <span v-if="revenue.daily_pagcor_share">{{
                      `₱${formattedNumber(revenue.daily_pagcor_share)}`
                    }}</span>
                    <span v-else> ₱0.00 </span>
                  </h2>
                </div>
              </div>
              <div class="d-flex align-center" style="gap: 1rem">
                <v-avatar
                  class="rounded"
                  size="67"
                  color="green lighten-5 "
                  tile
                >
                  <v-icon color="green"> mdi-database-check-outline </v-icon>
                </v-avatar>
                <div>
                  <div
                    class="grey--text"
                    style="font-size: 13px; margin-top: 4px"
                  >
                    Net Amount
                  </div>
                  <h2 class="dark--text">
                    <span v-if="revenue.net_income">{{
                      `₱${moneyFormat(parseFloat(revenue.net_income))}`
                    }}</span>
                    <span v-else> ₱0.00 </span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <small
                          v-bind="attrs"
                          v-on="on"
                          class="green--text"
                          style="
                            font-weight: normal;
                            font-size: 12px;
                            padding-left: 4px;
                          "
                        >
                          <span
                            v-if="
                              !revenueLoading &&
                              revenue.game_total_bet_amount !== 0
                            "
                            :class="
                              formattedNumber(
                                (revenue.net_income /
                                  revenue.game_total_bet_amount) *
                                  100
                              ) > 50
                                ? 'green--text'
                                : 'red--text'
                            "
                          >
                            {{
                              `${formattedNumber(
                                (revenue.net_income /
                                  revenue.game_total_bet_amount) *
                                  100
                              )}%`
                            }}
                          </span>
                        </small>
                      </template>
                      <span
                        >{{
                          `${formattedNumber(
                            (revenue.net_income /
                              revenue.game_total_bet_amount) *
                              100
                          )}%`
                        }}
                        of total gross</span
                      >
                    </v-tooltip>
                  </h2>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="8">
            <div
              v-if="!revenueLoading && Object.keys(revenue).length"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
              "
            >
              <PolarAreaChart :theChartData="revenue" />
            </div>
            <div
              v-else
              class="d-flex align-center justify-center"
              style="width: 100%; height: 100%"
            >
              <div class="d-flex align-center" style="gap: 1rem">
                <v-progress-circular
                  :size="20"
                  :width="3"
                  color="secondary"
                  indeterminate
                ></v-progress-circular>
                <span>Fetching chart information</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-dialog
      ref="filterDialog"
      v-model="filterDialog"
      :return-value.sync="filterDate"
      persistent
      width="290px"
    >
      <v-date-picker v-model="filterDate" scrollable range>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelFilter"> Cancel </v-btn>
        <v-btn text @click="saveFilter"> OK </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formatDatev2 from "@/helpers/formatDatev2";
import { startOfWeek } from "date-fns";
import { endOfWeek } from "date-fns";
import { startOfMonth } from "date-fns";
import { endOfMonth } from "date-fns";
import { startOfYear } from "date-fns";
import { endOfYear } from "date-fns";
import GetPercentage from "@/helpers/getPercentage";
import PolarAreaChart from "@/components/dashboard/revenue_polar_chart/RevenuePolarChart.vue";

export default {
  components: { PolarAreaChart },
  data: () => ({
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    filter: "Today",
    filters: ["Today", "This Week", "This Month", "This Year", "Custom"],
    filterFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    filterTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    filterDialog: false,
    filterDate: [],
    filterDateText: "",
  }),
  props: {
    game_id: Number,
  },
  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("dashboard", ["revenue", "revenueLoading"]),
  },
  watch: {
    game_id() {
      if (this.accountInfo.access.includes(21)) {
        this.doGetData();
      }
    },
  },
  mounted() {
    if (this.accountInfo.access.includes(21)) {
      this.doGetData();
    }
  },

  methods: {
    ...mapActions("dashboard", ["getRevenue"]),
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    changeFilter(val) {
      if (val == "Today") {
        this.filterFrom = this.today;
        this.filterTo = this.today;
        this.filter = val;
        this.filterDate = [];
        this.doGetData();
      }

      if (val == "This Week") {
        const startWeek = startOfWeek(new Date(this.today), {
            weekStartsOn: 1,
          }),
          endWeek = endOfWeek(new Date(this.today), { weekStartsOn: 1 });

        this.filterFrom = formatDatev2(startWeek, "format", "yyyy-MM-dd");
        this.filterTo = formatDatev2(endWeek, "format", "yyyy-MM-dd");
        this.filter = val;
        this.filterDate = [];
        this.doGetData();
      }

      if (val == "This Month") {
        const startMonth = startOfMonth(new Date(this.today)),
          endMonth = endOfMonth(new Date(this.today));

        this.filterFrom = formatDatev2(startMonth, "format", "yyyy-MM-dd");
        this.filterTo = formatDatev2(endMonth, "format", "yyyy-MM-dd");
        this.filter = val;
        this.filterDate = [];
        this.doGetData();
      }

      if (val == "This Year") {
        const startYear = startOfYear(new Date(this.today)),
          endYear = endOfYear(new Date(this.today));

        this.filterFrom = formatDatev2(startYear, "format", "yyyy-MM-dd");
        this.filterTo = formatDatev2(endYear, "format", "yyyy-MM-dd");
        this.filter = val;
        this.filterDate = [];
        this.doGetData();
      }

      if (val == "Custom") {
        this.filterDialog = true;
        this.filter = val;
      }
    },
    cancelFilter() {
      this.filterDialog = false;
    },
    saveFilter() {
      if (!this.filterDate.length) {
        // nothing to do
      } else {
        this.$refs.filterDialog.save(this.filterDate);
        const minDate = new Date(this.filterDate[0]);

        if (typeof this.filterDate[1] != "undefined") {
          const maxDate = new Date(this.filterDate[1]);

          if (minDate > maxDate) {
            this.filterFrom = formatDatev2(maxDate, "format", "yyyy-MM-dd");
            this.filterTo = formatDatev2(minDate, "format", "yyyy-MM-dd");
          } else {
            this.filterFrom = formatDatev2(minDate, "format", "yyyy-MM-dd");
            this.filterTo = formatDatev2(maxDate, "format", "yyyy-MM-dd");
          }

          this.filterDateText =
            formatDatev2(this.filterFrom, "date") +
            " to " +
            formatDatev2(this.filterTo, "date");

          this.doGetData();
        } else {
          this.filterFrom = formatDatev2(minDate, "format", "yyyy-MM-dd");
          this.filterTo = formatDatev2(minDate, "format", "yyyy-MM-dd");
          this.filterDateText = formatDatev2(this.filterFrom, "date");
          this.doGetData();
        }

        this.filter = "Custom";
      }
    },
    async doGetData() {
      if (!this.revenueLoading) {
        this.$store.commit("dashboard/setRevenue", []);

        const formData = new FormData();
        formData.append("date_from", this.filterFrom);
        formData.append("date_to", this.filterTo);
        formData.append("filter_type", this.filter);
        formData.append("game_id", this.game_id);

        const getData = new URLSearchParams(formData);

        await this.getRevenue(getData);
      }
    },
    setPercentage(val, dividend) {
      if (val != 0 && dividend != 0) {
        return GetPercentage(val, dividend);
      } else {
        return 0;
      }
    },
    formattedNumber(number) {
      if (number) {
        return number.toLocaleString("en-PH", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        number = 0;
        return number.toLocaleString("en-PH", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
    },
    // abbreviateNumber(num) {
    //   const formatCash = (n) => {
    //     if (n < 1e3) return n;
    //     if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "k";
    //     if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "m";
    //     if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "b";
    //     if (n >= 1e12) return +(n / 1e12).toFixed(1) + "t";
    //   };

    //   return formatCash(num);
    // },
  },
};
</script>