const url = process.env.VUE_APP_API_URL + "ending/reports/"

export default {
    namespaced: true,

    state: {
        records: [],
        recordsLoading: false,
        winners: [],
        winnersLoading: false
    },
    mutations: {
        setRecords(state, val) {
            state.records = val
        },

        setRecordsLoading(state, val) {
            state.recordsLoading = val
        },

        setWinners(state, val) {
            state.winners = val
        },

        setWinnersLoading(state, val) {
            state.winnersLoading = val
        }
    },

    actions: {
        async getRecords({ commit, rootState }, data) {
            commit("setRecordsLoading", true)

            try {
                const response = await fetch(`${url}top_winning_combination?${data}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                })

                const res = await response.json()

                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setRecords", res.records)

                    return res
                }
            } catch (error) {
                const errorStatus = error.response.status

                if (errorStatus == 401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setRecordsLoading", false)
            }
        },

        async getWinners({ commit, rootState }, data) {
            commit("setWinnersLoading", true)

            try {
                const response = await fetch(`${url}combination_winners?${data}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                })

                const res = await response.json()

                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setWinners", res.records)

                    return res
                }
            } catch (error) {
                const errorStatus = error.response.status

                if (errorStatus == 401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setWinnersLoading", false)
            }
        },
    }
}