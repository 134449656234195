<template>
	<div class="pa-4 pa-md-6">
		<div class="grey--text" style="font-size: 13px">Ending Reports</div>
		<h2 class="font-weight-bold mb-3 mb-md-5">Bets</h2>
		<v-card class="shadow mb-4">
			<v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1"> Select {{ filterBy }} </v-card-title>

			<v-divider></v-divider>

			<v-card-text class="py-4">
				<v-form ref="form" v-model="valid" lazy-validation @submit.prevent="loadRecords">
					<!-- <v-row dense>
            <v-col cols="12" md="4" class="mt-3">
              <v-select
                v-model="filter"
                :items="filterStatus"
                :rules="[rules.required]"
                label="Type"
                dense
                outlined
                hide-details="auto"
                clearable
              />
            </v-col>
          </v-row> -->

					<v-row dense>
						<v-col cols="12" md="4" class="mt-3">
							<v-select
								v-model="filterBy"
								:items="filterByStatus"
								:rules="[rules.required]"
								label="Filter By"
								dense
								outlined
								hide-details="auto"
								clearable />
						</v-col>

						<v-col cols="12" md="4" class="mt-3">
							<v-menu v-model="date_from_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="date_from"
										:rules="[rules.required, lessThanDate2]"
										:label="`${filterBy} From`"
										hide-details="auto"
										readonly
										outlined
										dense
										v-bind="attrs"
										v-on="on"
										@keyup="date_from_menu = true"
										@keydown="date_from_menu = false"
										clearable></v-text-field>
								</template>
								<v-date-picker v-model="date_from" @input="date_from_menu = false"></v-date-picker>
							</v-menu>
						</v-col>

						<v-col cols="12" md="4" class="mt-3">
							<v-menu v-model="date_to_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="date_to"
										:rules="[lessThanDate]"
										:label="`${filterBy} To (Optional)`"
										hide-details="auto"
										readonly
										outlined
										dense
										v-bind="attrs"
										v-on="on"
										:disabled="dateToAvailability"
										@keyup="date_to_menu = true"
										@keydown="date_to_menu = false"
										clearable></v-text-field>
								</template>
								<v-date-picker v-model="date_to" @input="date_to_menu = false" :min="date_from ? date_from : ''"></v-date-picker>
							</v-menu>
						</v-col>
					</v-row>

					<div class="mt-4">
						<v-btn :disabled="!valid" type="submit" color="primary" outlined @click.prevent="loadRecords" :loading="recordsLoading">
							Search
						</v-btn>
					</div>
				</v-form>
			</v-card-text>
		</v-card>

		<v-card class="shadow">
			<v-card-title>
				<v-row justify="space-between">
					<v-col cols="12" sm="6" md="4" xl="2" align-self="end">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon color="secondary" @click="loadRecords" v-bind="attrs" v-on="on" class="mr-1">
									<v-icon>mdi-cached</v-icon>
								</v-btn>
							</template>
							<span>Refresh</span>
						</v-tooltip>
						<downloadexcel
							class="d-inline-block"
							:data="records"
							:fields="{
								ID: 'bet_id',
								'Player Name': 'player_name',
								'Mobile Number': 'mobile_number',
								League: 'league',
								Game: {
									callback: (value) => {
										return `${value.team_a_name} ${value.team_a_alias} vs ${value.team_b_name} ${value.team_b_alias}`
									},
								},
								Schedule: {
									callback: (value) => {
										return this.dateFormat(value.schedule)
									},
								},
								'Card Number': {
									callback: (value) => {
										return `#${value.card_number}`
									},
								},
								'Bet Amount': 'bet_amount',
								'Combination#': {
									field: 'combination_number',
									callback: (value) => {
										return `${value} `
									},
								},
								'Game Type': 'game_type',
								Quarter: 'quarter',
								'Bet Status': 'is_winner',
								Payout: 'payout',
								'Bet Date': {
									callback: (value) => {
										return this.dateFormat(value.created_at)
									},
								},
								'Bet Datetime': {
									callback: (value) => {
										return this.datetimeFormat(value.created_at)
									},
								},
							}"
							:name="exportExcelName()"
							type="csv">
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon color="secondary" v-bind="attrs" v-on="on">
										<v-icon>mdi-file-delimited-outline</v-icon>
									</v-btn>
								</template>
								<span>Export to CSV</span>
							</v-tooltip>
						</downloadexcel>
					</v-col>
					<v-col cols="12" sm="6" md="4" xl="3">
						<v-text-field
							v-model="search"
							prepend-inner-icon="mdi-magnify"
							placeholder="Search"
							single-line
							hide-details="auto"
							:disabled="recordsLoading"
							clearable
							outlined
							dense>
							<!-- <template v-slot:append-outer>
						<v-tooltip
						top
						>
						<template v-slot:activator="{ on }">
							<v-btn
							icon
							color="secondary"
							@click="filter"
							v-on="on"
							small
							>
							<v-icon>mdi-filter-variant</v-icon>
							</v-btn>
						</template>
						Filter
						</v-tooltip>
					</template> -->
						</v-text-field>
					</v-col>
				</v-row>
			</v-card-title>
			<v-divider></v-divider>
			<v-card-text class="pa-4">
				<v-data-table
					:headers="headers"
					:items="recordsFormatted"
					:items-per-page="5"
					:search="search"
					:loading="recordsLoading"
					loading-text="Loading, please wait a moment"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc">
					<template v-slot:[`item.game`]="{ item }">
						<div>
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<!-- <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        Button
                      </v-btn> -->
									<div v-bind="attrs" v-on="on">
										<v-avatar size="36px" tile>
											<v-img :src="item.team_a_logo">
												<template v-slot:placeholder>
													<v-row class="fill-height ma-0" align="center" justify="center">
														<v-progress-circular indeterminate color="black"></v-progress-circular>
													</v-row>
												</template>
											</v-img>
										</v-avatar>

										<span class="text-overline my-2 mx-2 font-italic">vs</span>

										<v-avatar size="36px" tile>
											<v-img :src="item.team_b_logo">
												<template v-slot:placeholder>
													<v-row class="fill-height ma-0" align="center" justify="center">
														<v-progress-circular indeterminate color="black"></v-progress-circular>
													</v-row>
												</template>
											</v-img>
										</v-avatar>
									</div>
								</template>
								<span>
									<span class="text-caption">{{ item.team_a_name }} {{ item.team_a_alias }}</span>
									<span class="text-overline my-2 mx-2 font-italic">vs</span>
									<span class="text-caption">{{ item.team_b_name }} {{ item.team_b_alias }}</span>
								</span>
							</v-tooltip>
							<!-- <span class="text-caption"
                    >{{ item.team_b_name }} {{ item.team_b_alias }}</span
                  > -->
						</div>
					</template>

					<template v-slot:[`item.is_winner`]="{ item }">
						<v-chip small :class="item.is_winner == 'Win' ? 'green lighten-5 green--text' : 'red lighten-5 red--text'">
							{{ item.is_winner }}
						</v-chip>
					</template>

					<template v-slot:[`item.payout`]="{ item }">
						{{ `₱${item.payout}` }}
					</template>

					<template v-slot:[`item.game_type`]="{ item }">
						<v-badge :content="item.quarter" v-if="item.quarter">
							{{ item.game_type }}
						</v-badge>

						<span v-else>{{ item.game_type }}</span>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { format, sub } from 'date-fns'
import formRules from '@/helpers/formRules'
import downloadexcel from 'vue-json-excel'
import formatDate from '@/helpers/formatDate'
import formatDatev2 from '@/helpers/formatDatev2'

export default {
	components: { downloadexcel },
	data: () => ({
		valid: true,
		rules: formRules,
		date_from: format(new Date(), 'yyyy-MM-dd'),
		date_from_menu: false,
		date_to: '',
		date_to_menu: false,
		search: '',
		sortBy: 'bet_id',
		sortDesc: true,
		// filterStatus: ["All", "Agent", "Player"],
		filter: 'All',
		filterByStatus: ['Bet Date', 'Scheduled Date'],
		filterBy: 'Bet Date',
		headers: [
			//   { text: "ID", value: "bet_id", align: "left" },
			{
				text: 'Mobile Number',
				sortable: false,
				value: 'mobile_number',
			},
			{
				text: 'Player Name',
				sortable: false,
				value: 'player_name',
				class: 'text-uppercase',
			},
			// {
			//   text: "Player Type",
			//   value: "player_type",
			//   class: "text-uppercase",
			// },
			{ text: 'League', sortable: false, value: 'league', class: 'text-uppercase' },
			{ text: 'Game', sortable: false, value: 'game', class: 'text-uppercase' },
			{
				text: 'Schedule',
				value: 'formattedSchedule',
				class: 'text-uppercase',
			},
			{
				text: 'Card Number',
				sortable: false,
				value: 'formattedCardNumber',
				class: 'text-uppercase',
			},
			{
				text: 'Bet Amount',
				value: 'formattedBetAmount',
				class: 'text-uppercase',
				align: 'right',
			},
			{
				text: 'Combination#',
				sortable: false,
				value: 'combination_number',
				class: 'text-uppercase',
			},
			{
				text: 'Game Type',
				value: 'game_type',
				class: 'text-uppercase',
			},
			{
				text: 'Bet Status',
				value: 'is_winner',
				class: 'text-uppercase',
			},
			{
				text: 'Payout',
				value: 'payout',
				class: 'text-uppercase',
				align: 'right',
			},
			{ text: 'Bet At', value: 'formattedCreatedAt', class: 'text-uppercase' },
		],

		// Filter data
		createdAtStart: format(sub(new Date(), { days: 30 }), 'yyyy-MM-dd'),
		createdAtEnd: format(new Date(), 'yyyy-MM-dd'),
	}),
	mounted() {
		this.doGetRecords()
	},
	computed: {
		...mapState('ending_bets', ['records', 'recordsLoading']),
		recordsFormatted() {
			return this.records.map((record) => {
				return {
					...record,
					formattedCardNumber: '#' + record.card_number,
					formattedBetAmount: '₱ ' + this.moneyFormat(parseFloat(record.bet_amount)),
					formattedCreatedAt: formatDate(record.created_at, true),
					formattedSchedule: formatDate(record.schedule, true),
				}
			})
		},
		lessThanDate() {
			if (this.date_from && this.date_to) {
				if (new Date(this.date_to) < new Date(this.date_from)) {
					return 'Must be higher or equal than date from.'
				}
			}

			return true
		},
		lessThanDate2() {
			if (this.date_from && this.date_to) {
				if (new Date(this.date_from) > new Date(this.date_to)) {
					return 'Must be lessthan or equal than date to.'
				}
			}

			return true
		},

		dateToAvailability() {
			if (this.date_from == '' || this.date_from == null) {
				return true
			}

			return false
		},
	},
	methods: {
		...mapActions('ending_bets', ['getRecords']),
		moneyFormat(val) {
			return val.toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			})
		},
		dateFormat(date) {
			return formatDatev2(date, 'format', 'yyyy-MM-dd')
		},
		datetimeFormat(date) {
			return formatDatev2(date, 'format', 'yyyy-MM-dd HH:mm:ss')
		},
		async doGetRecords() {
			if (!this.recordsLoading) {
				this.$store.commit('ending_bets/setRecords', [])

				const formData = new FormData()
				formData.append('filter', this.filter)
				formData.append('filter_by', this.filterBy)
				formData.append('created_at_start', this.date_from)
				formData.append('created_at_end', this.date_to)

				const getData = new URLSearchParams(formData)

				await this.getRecords(getData)
			}
		},
		loadRecords() {
			if (this.$refs.form.validate()) {
				if (!this.recordsLoading) {
					this.$store.commit('ending_bets/setRecords', [])

					const formData = new FormData()
					formData.append('filter', this.filter)
					formData.append('filter_by', this.filterBy)
					formData.append('created_at_start', this.date_from)
					formData.append('created_at_end', this.date_to)

					const getData = new URLSearchParams(formData)

					this.getRecords(getData)
				}
			}
		},
		exportExcelName() {
			return 'Bets ' + format(new Date(), 'yyyy-MM-dd hh:mm:ss') + '.xls'
		},
	},

	watch: {
		date_from(val) {
			if (val == '' || val == null) {
				this.date_to = ''
			}
		},
	},
}
</script>
