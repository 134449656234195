<template>
  <v-dialog :max-width="width" v-model="isDialogOpen">
    <v-card class="shadow">
      <div class="pa-4 pa-md-6">
        <h3 class="font-weight-bold mb-3">
          {{ isCreate ? "Create" : "Update" }} Fields
        </h3>
        <v-form
          ref="form"
          @submit.prevent="submit"
          lazy-validation
          class="d-flex flex-column"
          style="gap: 1rem"
        >
          <slot name="textFields"></slot>
          <slot name="selectFields"></slot>
          <slot name="switchFields"></slot>

          <div class="d-flex justify-end bs-gap-2 mt-2">
            <v-btn text @click="closeDialog"> Cancel </v-btn>
            <v-btn depressed color="primary" type="submit" :loading="loading">
              Submit
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  data: () => ({
    isDialogOpen: false,
  }),

  props: ["isCreate", "loading", "width"],

  methods: {
    closeDialog() {
      this.$refs.form.reset();
      this.isDialogOpen = false;
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("upsertDialog", {
          method: this.isCreate ? "create" : "update",
        });
      }
    },
  },
};
</script>