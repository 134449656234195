<template>
  <v-dialog scrollable v-model="dialog" :persistent="!viewStatus" max-width="1000">
    <v-card>
      <v-card-title class="py-2">
        <v-row justify="space-between">
          <v-col cols="auto"> Cash In {{ headerExtension }} </v-col>
          <v-col cols="auto" class="text-right pe-1">
            <v-btn icon @click="cancelAction">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="py-4">
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="approve">
          <v-row dense>
            <v-col cols="12" md="5">
              <v-img
                max-height="500"
                max-width="500"
                :src="agent_data.screen_shot"
                style="cursor: pointer"
                @click="showImage"
                contain
              >
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey" />
                  </v-row>
                </template>
              </v-img>
            </v-col>

            <v-col cols="12" md="7">
              <div>
                <template>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody class="table-borderless">
                        <tr>
                          <td style="width: 40%" class="grey--text">Name</td>
                          <td>
                            <span>{{ agent_data.player_name }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 40%" class="grey--text">Payment Method</td>
                          <td>
                            <span>{{ agent_data.payment_method }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 40%" class="grey--text">Receiver Account</td>
                          <td>
                            <span>{{ agent_data.receiver_account }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 40%" class="grey--text">Account Number</td>
                          <td>
                            <span>{{ agent_data.account_number }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 40%" class="grey--text">Reference Number</td>
                          <td>
                            <span>{{ agent_data.reference_number }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 40%" class="grey--text">Amount</td>
                          <td>
                            <span
                              >PHP {{ moneyFormat(parseFloat(agent_data.amount)) }}</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <td style="width: 40%" class="grey--text">Notes</td>
                          <td>
                            <span>{{ agent_data.notes }}</span>
                          </td>
                        </tr>
                        <tr v-if="viewStatus">
                          <td style="width: 40%" class="grey--text">Remarks</td>
                          <td>
                            <span>{{ agent_data.remarks }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </div>

              <v-row dense v-if="!viewStatus">
                <v-col cols="12" class="mt-2">
                  <v-textarea
                    label="Remarks"
                    no-resize
                    outlined
                    rows="3"
                    hide-details="auto"
                    v-model="remarks"
                    :readonly="remarksReadOnly"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row dense v-if="actionVisibility">
                <v-col cols="12" md="4">
                  <v-btn class="ma-1" block color="success" type="submit">
                    Approved
                  </v-btn>
                </v-col>

                <v-col cols="12" md="4">
                  <v-btn
                    class="ma-1"
                    block
                    color="error"
                    value="disapprove"
                    @click="disapprove"
                  >
                    Disapproved
                  </v-btn>
                </v-col>

                <v-col cols="12" md="4">
                  <v-btn class="ma-1" block depressed @click="cancelAction">
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-dialog v-model="dialogStatus" max-width="400">
        <v-card class="py-2">
          <v-card-title class="text-h6 d-block text-center">
            Confirm {{ dialogText }}
          </v-card-title>

          <v-card-text class="text-center">
            Do you really want to {{ dialogText }}?
          </v-card-text>

          <v-card-actions class="d-block text-center">
            <v-btn
              ref="approveYes"
              color="primary"
              outlined
              @click="handleDialogAction"
              :loading="confirmationLoading"
            >
              Yes
            </v-btn>

            <v-btn color="grey" outlined @click="dialogStatus = false"> No </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    agent_data: {
      type: Object,
    },
    viewStatus: {
      type: Boolean,
    },
  },
  data: () => ({
    valid: true,
    dialogStatus: false,
    dialogText: "",
    remarks: "",
    data: [],
    headerExtension: "Validation",
    actionVisibility: true,
    remarksReadOnly: false,
  }),
  computed: {
    ...mapState("agents_cashin", ["confirmationLoading", "error", "errors"]),
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.closeModal();
      },
    },
  },
  mounted() {
    if (this.viewStatus) {
      this.headerExtension = "Details";
      this.actionVisibility = false;
      this.remarksReadOnly = true;
    } else {
      this.headerExtension = "Validation";
      this.actionVisibility = true;
      this.remarksReadOnly = false;
    }
  },
  watch: {
    dialogStatus(visible) {
      if (visible) {
        setTimeout(() => {
          this.$refs.approveYes.$el.focus();
        }, 200);
      }
    },
  },
  methods: {
    ...mapActions("agents_cashin", ["approveCashin", "disapproveCashin"]),
    approve() {
      this.dialogStatus = true;
      this.dialogText = "Approve";
    },
    disapprove() {
      this.dialogStatus = true;
      this.dialogText = "Disapprove";
    },
    cancelAction() {
      this.data = { action: "cancel" };
      this.closeModal();
    },
    closeModal() {
      this.$emit("setValidationCloseModal", this.data);
    },
    async handleDialogAction() {
      switch (this.dialogText) {
        case "Approve": {
          const formData = new FormData();
          formData.append("cashin_id", this.agent_data.id);
          formData.append("remarks", this.remarks);

          if (!this.confirmationLoading) {
            await this.approveCashin(formData);

            if (!this.error) {
              this.dialogStatus = false;
              this.data = {
                action: "Approve",
                id: this.agent_data.id,
              };
              this.closeModal();
            }
          }
          break;
        }
        case "Disapprove": {
          const formData2 = new FormData();
          formData2.append("cashin_id", this.agent_data.id);
          formData2.append("remarks", this.remarks);

          if (!this.confirmationLoading) {
            await this.disapproveCashin(formData2);

            if (!this.error) {
              this.dialogStatus = false;
              this.data = {
                action: "Disapprove",
                id: this.agent_data.id,
              };
              this.closeModal();
            }
          }
          break;
        }
      }
    },
    showImage() {
      this.$viewerApi({
        images: [this.agent_data.screen_shot],
      });
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>
