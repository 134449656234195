<template>
  <div class="my-10">
    <v-row no-gutters justify="center">
      <v-col cols="12" md="5" lg="5">
        <v-card
          elevation="2"
          class="pa-6"
        >
          <v-card-title class="mb-5">Login Verification</v-card-title>
          <v-card-subtitle>We sent the 6-digit One-Time Pin to your mobile number</v-card-subtitle>
          <v-card-text class="mt-4">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="handleSubmit"
            >
              <v-text-field
                ref="otp"
                v-model="otp"
                :rules="[rules.required, rules.numeric]"
                label="Enter One-Time Pin"
                prepend-inner-icon="mdi-lock-off-outline"
                outlined
                maxlength="6"
              ></v-text-field>
              <v-btn
                class="py-6"
                block
                color="primary"
                depressed
                type="submit"
                :disabled="!valid"
                :loading="verifyOtpLoading"
              >
                Submit
              </v-btn>
            </v-form>
            <p class="mt-4 font-italic">One-Time Pin is valid only <strong>5 minutes</strong> from the date and time of logging in.</p>
            <p class="mt-7 mb-2">Did not receive One-Time Pin?</p>
            <v-btn 
              block
              class="py-6"
              depressed
              :disabled="sendOtpLoading || !requestOtp"
              :loading="sendOtpLoading"
              @click.prevent="handleClick"
            >
              Click here to send
              <span v-if="countDown && !requestOtp">({{ countDown }})</span>
              <template v-slot:loader>
                <span>Sending One-Time Pin </span>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import formRules from '@/helpers/formRules'

export default {
  data: () => ({
    valid: true,
    rules: formRules,
    outlined: true,
    otp: '',
    countDown : 300,
    requestOtp: true
  }),
  computed: {
    ...mapState("login", ["verifyOtpError", "verifyOtpLoading", "sendOtpError", "sendOtpLoading"])
  },
  methods: {
    ...mapActions("login", ["doRequest", "doVerify"]),
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)

        this.requestOtp = false
      } else {
        this.countDown = 300
        this.requestOtp = true
      }
    },
    async handleClick() {
      if (!this.sendOtpLoading && this.requestOtp) {
        await this.doRequest()

        if (!this.sendOtpError) {
          this.countDownTimer()
        }
      }
    },
    async handleSubmit () {
      if (this.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('otp', this.otp)

        if (!this.verifyOtpLoading) {
          await this.doVerify(formData)

          if (!this.verifyOtpError) {
            // this.$store.commit("setLoginVerified", true)
            this.$router.push({ name: "Dashboard" })
          }
        }
      }
    },
  }
}
</script>

<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
