var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Settings")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Admin Access")]),_c('v-card',{staticClass:"shadow"},[_c('v-card-title',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"2","align-self":"end"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":_vm.insertReason}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-box-outline")])],1)]}}])},[_c('span',[_vm._v("Add Access")])])],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('v-text-field',{ref:"search",attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search access name","single-line":"","hide-details":"auto","disabled":_vm.getRecordsLoading,"clearable":"","outlined":"","dense":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.searchRecords.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getRecords,"options":_vm.options,"loading":_vm.getRecordsLoading,"server-items-length":_vm.getTotalRows,"sort-desc":_vm.sortDesc,"loading-text":"Loading... Please wait","hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.updateReason(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Update")])])]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.is_active === 'Yes'
                ? 'green lighten-5 green--text'
                : 'red lighten-5 red--text'},[_vm._v(_vm._s(item.is_active === "Yes" ? "Active" : "Inactive"))])]}},{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created_at, true)))])]}}],null,true)}),_c('div',{staticClass:"pa-4"},[_c('v-row',{staticClass:"d-flex flex-row-reverse mt-4"},[_c('v-col',{attrs:{"cols":"4","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.rowNumbers,"label":"Row per page","outlined":"","dense":""},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-col',[_c('v-chip',{attrs:{"color":"primary","outlined":"","pill":""}},[_vm._v(" Total: "+_vm._s(_vm.getTotalRows)+" ")])],1)],1),_c('Pagination',{attrs:{"storeName":_vm.storeName,"isDisabled":_vm.isDisabled},on:{"paginatePage":_vm.loadRecords}})],1)],1)],1),_c('UpsertDialog',{ref:"upsertDialog",attrs:{"isCreate":_vm.isCreate,"loading":_vm.getUpsertLoading,"width":400},on:{"upsertDialog":_vm.upsertDialog},scopedSlots:_vm._u([{key:"textFields",fn:function(){return [_c('v-text-field',{ref:"access",attrs:{"rules":[_vm.rules.required],"label":"Access Name","outlined":"","dense":"","hide-details":"auto","clearable":""},model:{value:(_vm.form.access),callback:function ($$v) {_vm.$set(_vm.form, "access", $$v)},expression:"form.access"}}),_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.numeric],"type":"number","label":"Module Order","outlined":"","dense":"","hide-details":"auto","clearable":""},model:{value:(_vm.form.moduleOrder),callback:function ($$v) {_vm.$set(_vm.form, "moduleOrder", $$v)},expression:"form.moduleOrder"}}),_c('v-text-field',{ref:"vueModule",attrs:{"label":"Vue Module (Optional)","outlined":"","dense":"","hide-details":"auto","clearable":""},model:{value:(_vm.form.vueModule),callback:function ($$v) {_vm.$set(_vm.form, "vueModule", $$v)},expression:"form.vueModule"}})]},proxy:true}])}),_c('v-snackbar',{attrs:{"timeout":2000,"top":"","color":"success"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.snackbarModel = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbarModel),callback:function ($$v) {_vm.snackbarModel=$$v},expression:"snackbarModel"}},[_vm._v(" "+_vm._s(_vm.isCreate ? "Created" : "Updated")+" successfully ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }