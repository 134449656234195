var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center justify-space-between gap px-4 px-md-6 py-4"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":_vm.loadRecords}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cached")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c('div',{staticClass:"d-flex align-center gap"},[_c('v-text-field',{attrs:{"placeholder":"Search","single-line":"","hide-details":"auto","clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"secondary","disabled":_vm.loading},on:{"click":_vm.filter}},on),[_c('v-icon',[_vm._v("mdi-filter-variant")])],1)]}}])},[_vm._v(" Filter ")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headersTransaction,"items":_vm.records,"items-per-page":5,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading, please wait a moment","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"hide-default-header":_vm.records && _vm.records.length == 0 ? true : false,"hide-default-footer":_vm.records && _vm.records.length == 0 ? true : false},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.setCreatedAtFormat(item.created_at)))])]}}],null,true)})],1),(_vm.dialog)?_c('AdminLogsFilter',{attrs:{"dates":_vm.dates},on:{"setCloseModal":_vm.closeModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }