<template>
  <v-dialog max-width="350" v-model="dialogModel">
    <v-card class="shadow">
      <div class="pa-4 pa-md-6">
        <h3 class="font-weight-bold mb-3">Filter</h3>
        <v-form
          ref="form"
          @submit.prevent="doFilter"
          lazy-validation
          class="d-flex flex-column"
          style="gap: 1rem"
        >
          <slot name="selectFields"></slot>

          <v-menu
            v-model="dateFromMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.dateFrom"
                label="From (Optional)"
                hide-details="auto"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                @keyup="dateFromMenu = true"
                @keydown="dateFromMenu = false"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.dateFrom"
              @input="dateFromMenu = false"
            ></v-date-picker>
          </v-menu>

          <v-menu
            v-model="dateToMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="form.dateTo"
                label="To (Optional)"
                hide-details="auto"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                :disabled="dateToAvailability"
                @keyup="dateToMenu = true"
                @keydown="dateToMenu = false"
                clearable
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.dateTo"
              @input="dateToMenu = false"
              :min="form.dateFrom ? form.dateFrom : ''"
            ></v-date-picker>
          </v-menu>

          <div class="d-flex justify-end bs-gap-2 mt-2">
            <v-btn text @click="closeDialog"> Cancel </v-btn>

            <v-btn depressed color="primary" type="submit"> Filter </v-btn>
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>


<script>
import formRules from "@/helpers/formRules";

export default {
  data: () => ({
    isDialogOpen: false,
    rules: formRules,
    dateFromMenu: false,
    dateToMenu: false,
    form: {
      dateFrom: null,
      dateTo: null,
    },
  }),

  methods: {
    doFilter() {
      if (this.$refs.form.validate()) {
        const filterObject = {
          type: true,
          dateFrom: this.form.dateFrom ? this.form.dateFrom : null,
          dateTo: this.form.dateTo ? this.form.dateTo : null,
        };

        this.$emit("closeFilterDialog", filterObject);
      }
    },

    closeDialog() {
      this.$emit("closeFilterDialog", { type: false });
    },
  },

  computed: {
    dialogModel: {
      get() {
        return this.isDialogOpen;
      },

      set(val) {
        this.isDialogOpen = val;
      },
    },

    dateToAvailability() {
      if (this.form.dateFrom == "" || this.form.dateFrom == null) {
        return true;
      }
      return false;
    },
  },

  watch: {
    "form.dateFrom": function (newVal) {
      if (newVal == "" || newVal == null) {
        this.form.dateTo = null;
      }
    },
  },
};
</script>
