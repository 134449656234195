<template>
  <div class="d-inline-block">
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click.stop="dialog = true"
        >
          <v-icon>mdi-plus-box-outline</v-icon>
        </v-btn>
      </template>
      <span>Create Game</span>
    </v-tooltip>

    <v-dialog
      v-model="dialog"
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-h5">
          Create Game
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="py-4"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleSubmit"
          >
            <v-row>
              <v-col cols="12" md="12">
                <v-autocomplete
                  ref="league"
                  v-model="league"
                  :rules="[rules.required]"
                  :items="leagues"
                  item-text="league"
                  item-value="id"
                  label="League"
                  dense
                  outlined
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row dense class="mt-4">
              <v-col cols="12" md="5">
                <v-autocomplete
                  v-model="teama"
                  :rules="[rules.required]"
                  :items="leagueTeama"
                  :item-text="leagueTeamName"
                  item-value="id"
                  label="Team"
                  dense
                  outlined
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="text-center" md="2">
                <span class="text-overline my-2 mx-2 font-italic">vs</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-autocomplete
                  v-model="teamb"
                  :rules="[rules.required]"
                  :items="leagueTeamb"
                  :item-text="leagueTeamName"
                  item-value="id"
                  label="Team"
                  dense
                  outlined
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="6" class="mt-4">
                <v-menu
                  v-model="schedule_date_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="schedule_date"
                      :rules="[rules.required]"
                      label="Schedule Date"
                      hide-details="auto"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @keyup="schedule_date_menu=true"
                      @keydown="schedule_date_menu=false"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="schedule_date"
                    @input="schedule_date_menu = false"
                    :min="new Date().toISOString().substr(0, 10)"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6" class="mt-4">
                <v-menu
                  ref="schedule_time_menu"
                  v-model="schedule_time_menu"
                  :close-on-content-click="false"
                  :return-value.sync="schedule_time"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="schedule_time"
                      :rules="[rules.required]"
                      label="Schedule Time"
                      hide-details="auto"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @keyup="schedule_time_menu=true"
                      @keydown="schedule_time_menu=false"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="schedule_time_menu"
                    v-model="schedule_time"
                    @click:minute="$refs.schedule_time_menu.save(schedule_time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            
            <v-row dense>
              <v-col cols="12" md="6" class="mt-2">
                <v-btn
                  block
                  :disabled="!valid"
                  type="submit"
                  color="primary"
                  @click.prevent="handleSubmit"
                  :loading="createGameLoading"
                >
                  Create
                </v-btn>
              </v-col>

              <v-col cols="12" md="6" class="mt-2">
                <v-btn
                  block 
                  color="black"
                  outlined
                  class="me-2"
                  @click="dialog = false"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import formRules from '@/helpers/formRules'

export default {
  data: () => ({
    dialog: false,
    valid: true,
    rules: formRules,
    league: '',
    teama: '',
    teamb: '',
    schedule_date: '',
    schedule_date_menu: false,
    schedule_time: '',
    schedule_time_menu: false,
  }),
  computed: {
    ...mapState("ending_games", ["leagues", "teams", "createGameError", "createGameLoading"]),
    leagueTeama() {
      return this.teams.filter((team) => {
        return team.league_id == parseInt(this.league) && team.id != this.teamb
      })
    },
    leagueTeamb() {
      return this.teams.filter((team) => {
        return team.league_id == parseInt(this.league) && team.id != this.teama
      })
    }
  },
  methods: {
    ...mapActions("ending_games", ["createGame"]),
    leagueTeamName: team => team.team_name + ' ' + team.team_alias,
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('league', this.league)
        formData.append('teama', this.teama)
        formData.append('teamb', this.teamb)
        formData.append('schedule_date', this.schedule_date)
        formData.append('schedule_time', this.schedule_time)

        if (!this.createGameLoading) {
          const res = await this.createGame(formData)

          if (!this.createGameError) {
            this.$refs.form.reset()

            this.$router.push({ name: "EndingGameDetails", params: { id: res.game_id } })
          }
        }
      }
    }
  },
  watch: {
    dialog(visible) {
      if (visible) {
        setTimeout(() => {
          this.$refs.league.focus()
        }, 200);
      }
    }
  }
}
</script>