<template>
  <div class="d-inline-block">
    <v-btn color="primary" depressed @click.stop="dialog = true">
      Verify
    </v-btn>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <div class="pa-4 pa-md-6">
          <h3 class="font-weight-bold mb-3">Verify</h3>
          <p class="grey--text mb-4 mb-md-6">
            Do you really want to verify this player?
          </p>

          <div class="d-flex justify-end align-center gap-sm">
            <v-btn text @click="dialog = false"> Cancel </v-btn>
            <v-btn
              color="primary"
              depressed
              @click="handleClick()"
              :loading="verifyLoading"
            >
              Verify
            </v-btn>
            <!-- <v-btn
              color="primary"
              depressed
              @click="saveAndNext()"
              :loading="verifyLoading"
              :disabled="!next_player_id"
            >
              Verify & Next
            </v-btn> -->
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    modalData: {
      type: Object,
      required: true,
    },
    // next_player_id: String,
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapState("player_details", ["verifyError", "verifyLoading"]),
  },
  methods: {
    ...mapActions("player_details", ["doVerify"]),
    async handleClick() {
      if (!this.verifyLoading) {
        const formData = new FormData();
        formData.append("player_id", this.modalData.id);

        await this.doVerify(formData);

        if (!this.verifyError) {
          this.$emit("updateAccountStatus", "Verified");
          this.dialog = false;
        }
      }
    },
    // saveAndNext() {
    //   this.handleClick();
    //   this.$emit("proceed_to_next_player");
    // },
  },
};
</script>
