<template>
  <div style="height: 100%">
    <v-card class="shadow" style="height: 100%">
      <div class="d-flex justify-space-between align-center bs-py-3 bs-px-4">
        <h3>Daily Cash-In</h3>
        <v-btn
          text
          class="text-uppercase px-4"
          @click="filterDialog = true"
          :disabled="cashinDailyLoading"
        >
          {{ cashinDaily.label }}
        </v-btn>
      </div>

      <v-divider></v-divider>

      <div class="d-flex align-center justify-center pa-6">
        <div v-if="cashinDailyLoading" class="text-center" style="height: 100%">
          <div class="d-flex align-center" style="gap: 1rem">
            <v-progress-circular
              :size="20"
              :width="3"
              color="secondary"
              indeterminate
            ></v-progress-circular>
            <span>Fetching chart information</span>
          </div>
        </div>
        <div style="width: 100%" v-else>
          <LineChart :theChartData="cashinDaily" style="margin-top: -15px" />
        </div>
      </div>

      <div v-if="!cashinDailyLoading">
        <v-divider></v-divider>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th class="text-right grey--text">Average</th>
                <th class="text-right grey--text">
                  Lowest
                </th>
                <th class="text-right grey--text">
                  Highest
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="grey--text">
                  <i class="far fa-circle fa-xs mr-4" style="color: #00b6fe"></i>Cashin
                </td>
                <td class="text-right">
                  <span>
                    {{
                      cashinDaily.cashin_daily_average
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(parseInt(cashinDaily.cashin_daily_average))}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      cashinDaily.cashin_daily_average && lowest
                        ? "₱" + `${moneyFormatHundredthsPlace(parseFloat(lowest))}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      cashinDaily.cashin_daily_average && highest
                        ? "₱" + `${moneyFormatHundredthsPlace(parseFloat(highest))}`
                        : "₱0"
                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="grey--text">
                  <i class="far fa-circle fa-xs mr-4" style="color: #ec407a"></i>Payouts
                </td>
                <td class="text-right">
                  <span>
                    {{
                      cashinDaily.payouts_daily_average
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(parseInt(cashinDaily.payouts_daily_average))}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      cashinDaily.payouts_daily_average && lowestPayout
                        ? "₱" + `${moneyFormatHundredthsPlace(parseFloat(lowestPayout))}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      cashinDaily.payouts_daily_average && highestPayout
                        ? "₱" + `${moneyFormatHundredthsPlace(parseFloat(highestPayout))}`
                        : "₱0"
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>

    <v-dialog
      ref="filterDialog"
      v-model="filterDialog"
      :return-value.sync="month"
      persistent
      width="290px"
    >
      <v-date-picker v-model="month" full-width type="month">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cancelFilter"> Cancel </v-btn>
        <v-btn text color="primary" @click="saveFilter"> OK </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formatDatev2 from "@/helpers/formatDatev2";
import LineChart from "@/components/dashboard/cashin_statistics/CashinDailyLineChart.vue";

export default {
  components: { LineChart },
  data: () => ({
    month: formatDatev2(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      "format",
      "yyyy-MM"
    ),
    filterDialog: false,
    activePicker: null,
  }),
  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("dashboard", ["cashinDaily", "cashinDailyLoading"]),

    lowest() {
      const arr = Object.values(this.cashinDaily.cashin_statisitcs);
      const filtered_array = arr.filter((val) => val != 0);

      if (filtered_array.length != 0) return Math.min(...filtered_array);

      return 0;
    },

    highest() {
      const arr = Object.values(this.cashinDaily.cashin_statisitcs);
      return Math.max(...arr);
    },

    lowestPayout() {
      const arr = Object.values(this.cashinDaily.payouts_statistics);
      const filtered_array = arr.filter((val) => val != 0);

      if (filtered_array.length != 0) return Math.min(...filtered_array);

      return 0;
    },

    highestPayout() {
      const arr = Object.values(this.cashinDaily.payouts_statistics);
      return Math.max(...arr);
    },
  },
  mounted() {
    if (this.accountInfo.access.includes(21)) {
      this.doGetData();
    }
  },
  methods: {
    ...mapActions("dashboard", ["getCashinDaily"]),
    cancelFilter() {
      this.filterDialog = false;
    },
    saveFilter() {
      this.$refs.filterDialog.save(this.month);
      this.doGetData();
    },
    async doGetData() {
      if (!this.cashinDailyLoading) {
        this.$store.commit("dashboard/setCashinDaily", []);

        const formData = new FormData();
        formData.append("val", this.month);
        formData.append("type", "Daily");

        const getData = new URLSearchParams(formData);

        await this.getCashinDaily(getData);
      }
    },
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    moneyFormatHundredthsPlace(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>
