<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Ending Reports</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Cash In</h2>
    <v-card class="shadow mb-4">
      <v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
        CASHIN REPORT
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="py-4">
        <v-row>
          <v-col>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="doGetRecords"
            >
              <!-- <v-row dense>
                <v-col cols="12" md="4" class="mt-2">
                  <v-select
                    v-model="player_type"
                    :items="player_types"
                    :rules="[rules.required]"
                    label="Player Type"
                    dense
                    outlined
                    hide-details="auto"
                  />
                </v-col>
              </v-row> -->

              <v-row dense>
                <v-col cols="12" md="4" class="mt-2">
                  <v-select
                    v-model="filter"
                    :items="filterOptions"
                    :rules="[rules.required]"
                    label="Filter By"
                    dense
                    outlined
                    hide-details="auto"
                  />
                </v-col>

                <v-col cols="12" md="4" class="mt-2">
                  <v-menu
                    v-model="date_from_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_from"
                        :rules="[rules.required, lessThanDate]"
                        :label="`${filter} From`"
                        hide-details="auto"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @keyup="date_from_menu = true"
                        @keydown="date_from_menu = false"
                        clearable
                      />
                    </template>

                    <v-date-picker
                      v-model="date_from"
                      @input="date_from_menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="4" class="mt-2">
                  <v-menu
                    v-model="date_to_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :disabled="date_from == ''"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_to"
                        :label="`${filter} To (Optional)`"
                        hide-details="auto"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        :disabled="dateToAvailability"
                        @keyup="date_to_menu = true"
                        @keydown="date_to_menu = false"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_to"
                      @input="date_to_menu = false"
                      :min="date_from ? date_from : ''"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row dense class="mt-4">
                <v-col>
                  <v-row dense>
                    <v-col>
                      <v-btn depressed type="submit" color="primary" :loading="loading">
                        Search
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <downloadexcel
              :data="filtered_data"
              :fields="{
                Province: 'province',
                City: 'city',
                'Player ID': 'player_id',
                Name: 'name',
                'Mobile Number': {
                  callback: (value) => {
                    return `+63${value.mobile_number}`;
                  },
                },
                'Total CashIn': 'total_cashin',
              }"
              name="Cash In Report.xls"
              type="csv"
              class="d-inline-block"
            >
              <v-btn outlined color="primary"> Export CSV </v-btn>
            </downloadexcel>
          </v-col>

          <!-- 'User Type': 'user_type',
                Type: 'type', -->

          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="loading"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-row dense class="mb-2">
          <v-col
            md
            align="center"
            class="px-1"
            v-if="player_type == 'Player' || player_type == 'All'"
          >
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL PLAYERS</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                {{ parseInt(totalPlayers).toLocaleString() }}
              </p>
            </v-card>
          </v-col>

          <v-col
            md
            align="center"
            class="px-1"
            v-if="player_type == 'Player' || player_type == 'All'"
          >
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL PLAYERS CASHIN</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                PHP {{ parseInt(totalPlayersCashIn).toLocaleString() }}
              </p>
            </v-card>
          </v-col>

          <!-- <v-col
            md
            align="center"
            class="px-1"
            v-if="player_type == 'Kubrador' || player_type == 'All'"
          >
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL KUBRADORS</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                {{ parseInt(totalKubradors).toLocaleString() }}
              </p>
            </v-card>
          </v-col> -->

          <!-- <v-col
            md
            align="center"
            class="px-1"
            v-if="player_type == 'Kubrador' || player_type == 'All'"
          >
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL KUBRADORS CASHIN</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium">
                PHP {{ parseInt(totalKubradorsCashIn).toLocaleString() }}
              </p>
            </v-card>
          </v-col> -->

          <v-col md align="center" class="px-1" v-if="player_type == 'All'">
            <v-card class="pa-1">
              <p class="font-weight-regular ma-0 overline">TOTAL CASHIN</p>
              <v-divider class="mb-4" />
              <p class="font-weight-medium green--text">
                PHP {{ parseInt(totalCashIn).toLocaleString() }}
              </p>
            </v-card>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="filtered_data"
          :items-per-page="5"
          :search="search"
          :loading="loading"
          loading-text="Loading, please wait a moment"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dense
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  color="primary"
                  @click="setCashInRequestModal(item)"
                  class="ma-1"
                >
                  <v-icon size="large"> mdi-account-cash-outline </v-icon>
                </v-btn>
              </template>
              <span>Show Cashin Request</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.total_cashin`]="{ item }">
            <span>PHP {{ parseInt(item.total_cashin).toLocaleString() }} </span>
          </template>

          <template v-slot:[`item.mobile_number`]="{ item }">
            <span>{{ `+63${item.mobile_number}` }} </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <CashInRequest
      v-if="cashin_dialog"
      :cashin_data="cashin_data"
      @setCashInRequestCloseModal="setCashInRequestCloseModal"
    />
  </div>
</template>

<script>
import downloadexcel from "vue-json-excel";
import formRules from "@/helpers/formRules";
import { mapActions, mapState } from "vuex";
import CashInRequest from "@/components/reports/CashInDialog";

export default {
  components: {
    downloadexcel,
    CashInRequest,
  },

  data: () => ({
    valid: true,
    rules: formRules,
    search: "",
    date_from: null,
    date_to: null,
    date_from_menu: false,
    date_to_menu: false,
    filterOptions: ["Pending", "Approved", "Disapproved"],
    filter: "Approved",
    // player_types: ["All", "Player", "Kubrador"],
    player_type: "All",
    query_filters: {},
    cashin_dialog: false,
    cashin_data: {},
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      {
        text: "Province",
        value: "province",
        class: "text-uppercase",
        align: "left",
      },
      {
        text: "City/ Municipality",
        value: "city",
        class: "text-uppercase",
        align: "left",
      },
      {
        text: "Player ID",
        value: "player_id",
        class: "text-uppercase",
        align: "left",
      },
      {
        text: "NAME",
        value: "name",
        class: "text-uppercase",
        align: "left",
      },
      {
        text: "Mobile Number",
        value: "mobile_number",
        class: "text-uppercase",
        align: "left",
      },
      // {
      //   text: "USER TYPE",
      //   value: "user_type",
      //   class: "text-uppercase",
      //   align: "left",
      // },
      // {
      //   text: "TYPE",
      //   value: "type",
      //   class: "text-uppercase",
      //   align: "left",
      // },
      {
        text: "Total Cashin",
        value: "total_cashin",
        class: "text-uppercase",
        align: "right",
      },
    ],
  }),

  created() {
    const urlQuery = this.$route.query;

    this.$store.commit("ending_reports_cashin/setRecords", []);

    if (Object.keys(urlQuery).length) {
      if (Object.prototype.hasOwnProperty.call(urlQuery, "type"))
        this.player_type = urlQuery.type;
      if (Object.prototype.hasOwnProperty.call(urlQuery, "filter"))
        this.filter = urlQuery.filter;
      if (Object.prototype.hasOwnProperty.call(urlQuery, "date_from"))
        this.date_from = urlQuery.date_from;
      if (Object.prototype.hasOwnProperty.call(urlQuery, "date_to"))
        this.date_to = urlQuery.date_to;

      this.doLoadRecords();
    }
  },

  computed: {
    ...mapState("ending_reports_cashin", ["loading", "records"]),

    lessThanDate() {
      if (this.date_from && this.date_to) {
        if (new Date(this.date_to) < new Date(this.date_from)) {
          return "Must be higher or equal than date from.";
        }
      }

      return true;
    },

    dateToAvailability() {
      if (this.date_from == "" || this.date_from == null) {
        return true;
      }

      return false;
    },

    filtered_data() {
      return this.records.filter((card) => {
        if (this.player_type == "All") {
          return card.user_type != "";
        } else return card.user_type == this.player_type.toUpperCase();
      });
    },

    totalCashIn() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) =>
            (this.player_type == "All"
              ? parseFloat(obj.total_cashin)
              : obj.user_type == this.player_type.toUpperCase()
              ? parseFloat(obj.total_cashin)
              : 0) + total,
          0
        );
      } else {
        return 0;
      }
    },

    totalPlayersCashIn() {
      if (this.records) {
        //return this.records.reduce((total, obj) => ((this.player_type == "Player") ? ((obj.user_type == this.player_type.toUpperCase()) ? parseFloat(obj.total_cashin) : 0) : 0) + total,0)
        return this.records.reduce(
          (total, obj) =>
            (obj.user_type == "PLAYER" &&
            (this.player_type == "Player" || this.player_type == "All")
              ? parseFloat(obj.total_cashin)
              : 0) + total,
          0
        );
      } else {
        return 0;
      }
    },

    totalKubradorsCashIn() {
      if (this.records) {
        //return this.records.reduce((total, obj) => ((this.player_type == "All") ? ((obj.user_type == "KUBRADOR" && (this.player_type == "Kubrador" || this.player_type == "All" )) ? obj.total_cashin : 0) : ((obj.user_type == "KUBRADOR" && this.player_type == "Kubrador") ? obj.total_cashin : 0)) + total,0)
        return this.records.reduce(
          (total, obj) =>
            (obj.user_type == "KUBRADOR" &&
            (this.player_type == "Kubrador" || this.player_type == "All")
              ? parseFloat(obj.total_cashin)
              : 0) + total,
          0
        );
      } else {
        return 0;
      }
    },

    totalPlayers() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) =>
            (obj.user_type == "PLAYER" &&
            (this.player_type == "Player" || this.player_type == "All")
              ? 1
              : 0) + total,
          0
        );
      } else {
        return 0;
      }
    },

    totalKubradors() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) =>
            (obj.user_type == "KUBRADOR" &&
            (this.player_type == "Kubrador" || this.player_type == "All")
              ? 1
              : 0) + total,
          0
        );
      } else {
        return 0;
      }
    },
  },

  methods: {
    ...mapActions("ending_reports_cashin", ["getRecords"]),

    async doGetRecords() {
      if (this.$refs.form.validate()) {
        if (!this.loading) {
          this.$store.commit("ending_reports_cashin/setRecords", []);

          await this.getRecords(this.setFormData());

          this.setQueryString();
        }
      }
    },

    async doLoadRecords() {
      await this.getRecords(this.setFormData());
    },

    setFormData() {
      const formData = new FormData();

      formData.append("filter", this.filter ?? "");
      formData.append("date_from", this.date_from);
      formData.append("date_to", this.date_to ?? this.date_from);

      const getData = new URLSearchParams(formData);

      return getData;
    },

    setQueryString() {
      const urlQuery = this.$route.query;

      if (
        urlQuery.type != this.player_type ||
        urlQuery.filter != this.filter ||
        urlQuery.date_from != this.date_from ||
        urlQuery.date_to != this.date_to
      ) {
        this.query_filters = {};

        if (this.player_type != null) this.query_filters["type"] = this.player_type;
        if (this.filter != null) this.query_filters["filter"] = this.filter;
        if (this.date_from != null) this.query_filters["date_from"] = this.date_from;
        if (this.date_to != null) this.query_filters["date_to"] = this.date_to;

        this.$router.push({
          name: this.$route.name,
          query: this.query_filters,
        });
      }
    },

    setCashInRequestModal(item) {
      item["filter"] = this.filter;
      this.cashin_data = {};

      if (this.date_from != null) item["date_from"] = this.date_from;
      if (this.date_to != null) item["date_to"] = this.date_to;

      this.cashin_data = item;
      this.cashin_dialog = true;
    },

    setCashInRequestCloseModal() {
      this.cashin_dialog = false;
    },
  },

  watch: {
    date_from(val) {
      if (val == "" || val == null) {
        this.date_to = "";
      }
    },
  },
};
</script>
