import Vue from 'vue'
import Vuex from 'vuex'
import jwtdecode from '../helpers/jwtdecode'
import login from './login'
import dashboard from './dashboard'
import players from './players'
import player_details from './player_details'
import cashin from './cashin'
import cashout from './cashout'
import ending_bets from './ending/bets'
import ending_cards from './ending/cards'
import ending_card_details from './ending/card_details'
import ending_games from './ending/games'
import ending_game_details from './ending/game_details'
import ending_teams from './ending/teams'
import ending_leagues from './ending/leagues'
import ending_reports_game from './ending/reports/game'
import ending_reports_progress from './ending/reports/progress'
import outlets from './ending/outlets/outlets'
import ending_users from './ending/users'
import ads_images from './ending/ads_images/ads_images'
import game_card from './ending/game_card'
import ending_reports_transactional from './ending/reports/transactional'
import ending_reports_cashin from './ending/reports/cashin'
import ending_reports_cashout from './ending/reports/cashout'
import ending_reports_cards from './ending/reports/cards'
import ending_reports_payouts from './ending/reports/payouts'
import ending_reports_top_winning_combination from './ending/reports/top_winning_combination'
import ending_reports_master_agents_token from './ending/reports/master_agents_token'
import ending_pagcor_reports_game from './ending/pagcor_reports/game'
import ending_pagcor_reports_progress from './ending/pagcor_reports/progress'
import ending_pagcor_reports_transactional from './ending/pagcor_reports/transactional'
import ending_configuration from './ending/configuration/configuration'
import card_monitoring from './ending/monitoring/card_monitoring'
import agents from './ending/agents/agents'
import agents_cashin from './ending/agents/agents_cashin'
import agents_report from './ending/agents_reports/agent_reports'
import agents_monitoring from './ending/monitoring/agent_players'
import agents_commission_payments from './ending/agents/commission_payments'
import kobrador_progressive from './ending/monitoring/kobrador_progressive'
import forgot_password from './ending/forgot_password/forgot_password'
import logout from './logout'
import kubrador_progressive_provinces from './ending/monitoring/kobrador_progressive provinces'
import lucky_four_games from './lucky_four/games'
import lucky_four_game_details from './lucky_four/game_details'
import lucky_four_prizes_settings from './lucky_four/prizes_settings'
import lucky_four_bets from './lucky_four/bets'
import lucky_four_game_report from './lucky_four/reports/game'
import lucky_four_pagcor_reports_game from './lucky_four/pagcor_reports/game'
import lucky_four_pagcor_reports_progress from './lucky_four/pagcor_reports/progress'
import restricted_players from './restricted_players'
import deposit_config from './deposit_config'
import power_ball_games from './power_ball/games'
import power_ball_game_details from './power_ball/game_details'
import power_ball_winners from './power_ball/winners'
import sms from './sms'
import player_reject_reasons from './player_reject_reasons'
import admin_access from './admin_access'

Vue.use(Vuex)

const url = process.env.VUE_APP_API_URL + "ending/components"

// console.log('Manok', JSON.parse(localStorage.getItem('accountInfo')))

export default new Vuex.Store({
  state: {
    userData: jwtdecode(localStorage.getItem('userToken')) || null,
    userToken: localStorage.getItem('userToken') || {},
    accountInfo: JSON.parse(localStorage.getItem('accountInfo')) || {},
    error: null,
    refreshError: null,
    popUpError: null,
    leagues: [],
    teams: [],
  },
  getters: {
    isAuthenticated: state => !!state.userData,
    isLoggedInVerified: state => (typeof state.accountInfo.login_verified != "undefined") ? state.accountInfo.login_verified : false,
    gAccountInfo: state => !!state.accountInfo
    // getAccountInfo: state => state.accountInfo
  },
  mutations: {
    setUserData(state, val) {
      state.userData = val
    },
    setUserToken(state, val) {
      state.userToken = val
    },
    setAccountInfo(state, val) {
      state.accountInfo = val
    },
    setError(state, val) {
      state.error = val
    },
    setRefreshError(state, val) {
      state.refreshError = val
    },
    setPopUpError(state, val) {
      state.popUpError = val
    },
    setLeagues(state, val) {
      state.leagues = val
    },
    setTeams(state, val) {
      state.teams = val
    },
    resetStates(state) {
      state.userData = null
      state.userToken = {}
      state.accountInfo = {}
      state.error = null
      state.refreshError = null
      state.popUpError = null
      state.leagues = []
      state.teams = []
    }
  },
  actions: {
    /* async getAccountInfo({ commit, rootState }) {
      try {
        const response = await fetch(url + '/info', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          // console.log('Successfully got account info', res)
          commit("setAccountInfo", res)
        }
      } catch(error) {
        console.log('Failed to get account info')
      }
    }, */
    async getEndingComponents({ commit, state }) {
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${state.userToken}`,
          }
        });

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          if (res.leagues) {
            commit("setLeagues", res.leagues)
          }

          if (res.teams) {
            commit("setTeams", res.teams)
          }
        }
      } catch (error) {
        // nothing
      }
    },
  },
  modules: {
    login,
    dashboard,
    agents,
    agents_cashin,
    agents_report,
    agents_commission_payments,
    players,
    player_details,
    cashin,
    cashout,
    ending_bets,
    ending_cards,
    ending_card_details,
    ending_games,
    ending_game_details,
    ending_teams,
    ending_leagues,
    ending_reports_game,
    ending_reports_progress,
    outlets,
    ending_users,
    ads_images,
    game_card,
    ending_reports_transactional,
    ending_reports_cashin,
    ending_reports_cashout,
    ending_reports_cards,
    ending_reports_payouts,
    ending_reports_top_winning_combination,
    ending_reports_master_agents_token,
    ending_pagcor_reports_game,
    ending_pagcor_reports_progress,
    ending_pagcor_reports_transactional,
    ending_configuration,
    card_monitoring,
    agents_monitoring,
    kobrador_progressive,
    logout,
    forgot_password,
    kubrador_progressive_provinces,
    lucky_four_games,
    lucky_four_game_details,
    lucky_four_prizes_settings,
    lucky_four_bets,
    lucky_four_game_report,
    lucky_four_pagcor_reports_game,
    lucky_four_pagcor_reports_progress,
    restricted_players,
    deposit_config,
    power_ball_games,
    power_ball_game_details,
    power_ball_winners,
    sms,
    player_reject_reasons,
    admin_access,
  }
})
