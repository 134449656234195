var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","fullscreen":!_vm.$vuetify.breakpoint.mdAndUp,"max-width":"1100"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"shadow"},[_c('div',{staticClass:"d-flex align-center justify-space-between pa-6"},[_c('h3',[_vm._v("Manage Sub Accounts")]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.set_close_dialog}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"1.5rem"}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"black"},on:{"click":function($event){return _vm.set_add_account_dialog('Add', {})}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("New")])]),_c('v-card-title',{staticClass:"pa-0"},[_c('v-text-field',{ref:"search",attrs:{"placeholder":"Search","single-line":"","hide-details":"auto","clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)])]),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sub_agents,"items-per-page":_vm.$vuetify.breakpoint.mdAndUp ? 10 : 5,"search":_vm.search,"loading":_vm.sub_agents_loading,"loading-text":"Loading, Please wait ...","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"pointer-events":"none"},attrs:{"icon":"","small":"","color":item.status == 'Active' ? 'red' : 'green'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" "+_vm._s(("mdi-" + (item.status == "Active" ? "account-lock" : "account-lock-open")))+" ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(item.status == "Active" ? "Deactivate" : "Activate")+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.set_add_account_dialog('Edit', item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_vm._v(" Edit ")])],1)]}},{key:"item.mobile_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("+63" + (item.mobile_number)))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.status == 'Active'
              ? 'green lighten-5 green--text'
              : 'red lighten-5 red--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.format_date(item.created_at)))])]}}],null,true)})],1),(_vm.add_account_dialog)?_c('AddAccount',{attrs:{"sub_user_info":_vm.sub_user_info,"sub_user_modal_title":_vm.sub_user_modal_title,"investor_id":_vm.investor_id},on:{"set_close_add_account_dialog":_vm.set_close_add_account_dialog,"refresh_sub_user_list":_vm.get_sub_agents}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }