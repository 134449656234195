const url = process.env.VUE_APP_API_URL + "ending/monitoring/"

export default {
    namespaced: true,
  
    state: {
      records: [],
      loading: false,
    },

    mutations: {
        setLoading(state, val) {
            state.loading = val
        },

        setRecords(state, val) {
            state.records = val
        },
    },
  
    actions: {
        async getRecordsByBets({ commit, rootState }, data) {
            commit("setLoading", true)

            try {
                const response = await fetch(`${url}kobrador_progressive?${data}` , {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setRecords", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
                } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setLoading", false)
            }
        },
    }
  }