<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Investors</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Special Commissions List</h2>

    <v-card class="shadow-none">
      <v-card-text class="d-flex flex-column" style="gap: 1rem">
        <div class="d-flex justify-space-between">
          <div class="d-flex" style="gap: 0.3rem">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <downloadexcel
                  :data="records"
                  :fields="{
                    Agents: 'agent',
                    Items: 'items',
                    Commissions: 'amount',
                    Status: 'status',
                    'Process Date': {
                      callback: (value) => {
                        return format_date(value.process_date);
                      },
                    },
                  }"
                  :name="downloadExcelName()"
                  type="csv"
                  class="d-inline-block"
                >
                  <v-btn icon class="grey lighten-5" v-bind="attrs" v-on="on">
                    <v-icon>mdi-file-delimited-outline</v-icon>
                  </v-btn>
                </downloadexcel>
              </template>
              <span>Export to Excel</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="grey lighten-5"
                  v-bind="attrs"
                  v-on="on"
                  @click="doGetSpecialCommissions"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </div>

          <div v-if="accountInfo" class="d-flex justify-end" style="gap: 0.3rem">
            <v-text-field
              autofocus
              dense
              outlined
              label="Search"
              placeholder="Search"
              hide-details
            />

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="grey lighten-5"
                  v-bind="attrs"
                  v-on="on"
                  @click="set_special_commission_filter_dialog"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </div>
        </div>

        <v-data-table
          :headers="headers"
          :items="records"
          :search="search"
          :loading="records_loading"
          loading-text="Loading, please wait a moment"
          :items-per-page="5"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="grey lighten-5"
                  v-bind="attrs"
                  v-on="on"
                  @click="doHandleAction(item.id, item.agent, item.status, item.amount)"
                >
                  <v-icon
                    :color="
                      get_access == 'verifier' && item.status == 'Requested'
                        ? 'green'
                        : get_access == 'approver' && item.status == 'Verified'
                        ? 'blue'
                        : 'red'
                    "
                  >
                    {{
                      `mdi-${
                        get_access == "verifier" && item.status == "Requested"
                          ? "check-outline"
                          : get_access == "approver" && item.status == "Verified"
                          ? "check-bold"
                          : "cancel"
                      }`
                    }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{
                get_access == "verifier" && item.status == "Requested"
                  ? "Verify"
                  : get_access == "approver" && item.status == "Verified"
                  ? "Approve"
                  : "No action"
              }}</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.items`]="{ item }">
            <a
              @click.prevent="set_special_commission_dialog(item.id)"
              style="font-size: 13px"
            >
              {{ `View (${number_format(parseInt(item.items))})` }}
            </a>
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <strong>{{ `Php ${money_format(parseFloat(item.amount))}` }}</strong>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :class="
                item.status == 'Requested'
                  ? 'blue-grey lighten-5 blue-grey--text'
                  : item.status == 'Verified'
                  ? 'green lighten-5 green--text'
                  : 'blue lighten-5 blue--text'
              "
            >
              {{ item.status }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <SpecialCommissionItems
      v-if="special_commission_raw_dialog"
      :data="special_commission_raw_data"
      @set_close_special_commission_dialog="set_close_special_commission_dialog"
    />
    <SpecialCommissionFilter
      v-if="special_commission_filter_dialog"
      :data="special_commission_filter_data"
      @set_close_special_commission_filter_dialog="
        set_close_special_commission_filter_dialog
      "
    />
    <SpecialCommissionVerify
      v-if="special_commission_verify_dialog"
      :data="special_commission_verify_data"
      @set_close_special_commission_verify_dialog="
        set_close_special_commission_verify_dialog
      "
    />
    <SpecialCommissionApprove
      v-if="special_commission_approve_dialog"
      :data="special_commission_approve_data"
      @set_close_special_commission_approve_dialog="
        set_close_special_commission_approve_dialog
      "
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";
import responseGet from "@/helpers/api_request_get";
import formatDate from "@/helpers/formatDate";
import downloadexcel from "vue-json-excel";
import SpecialCommissionItems from "@/components/investors/SpecialCommissionItems";
import SpecialCommissionFilter from "@/components/investors/SpecialCommissionFilter";
import SpecialCommissionVerify from "@/components/investors/SpecialCommissionVerify";
import SpecialCommissionApprove from "@/components/investors/SpecialCommissionApprove";
import { mapActions } from "vuex";

export default {
  components: {
    SpecialCommissionItems,
    SpecialCommissionFilter,
    SpecialCommissionVerify,
    SpecialCommissionApprove,
    downloadexcel,
  },

  data: () => ({
    search: "",
    records: [],
    records_loading: false,
    date_from: "",
    date_to: "",
    status: "",
    special_commission_raw_dialog: false,
    special_commission_raw_data: {},
    special_commission_filter_dialog: false,
    special_commission_filter_data: {},
    special_commission_verify_dialog: false,
    special_commission_verify_data: {},
    special_commission_approve_dialog: false,
    special_commission_approve_data: {},
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
      },
      {
        text: "Agent",
        value: "agent",
        class: "text-uppercase",
      },
      {
        text: "Items",
        value: "items",
        align: "right",
        class: "text-uppercase",
      },
      {
        text: "Commissions",
        value: "amount",
        align: "right",
        class: "text-uppercase",
      },
      {
        text: "Status",
        value: "status",
        class: "text-uppercase",
      },
      {
        text: "Process Date",
        value: "process_date",
        class: "text-uppercase",
      },
    ],
  }),

  computed: {
    ...mapState(["accountInfo"]),

    get_access() {
      if (this.accountInfo) {
        if (this.accountInfo.access.includes(65)) return "verifier";
        if (this.accountInfo.access.includes(66)) return "approver";
      }

      return "view";
    },
  },

  mounted() {
    this.set_status();
    this.get_first_and_last_day_of_month();
    this.doGetSpecialCommissions();
  },

  methods: {
    ...mapActions("logout", ["sendLogoutRequest"]),

    async doGetSpecialCommissions() {
      this.records = [];
      this.records_loading = !this.records_loading;

      const response = await responseGet(
        this.set_form_data(),
        "investor/special_commissions"
      );

      switch (response.status) {
        case 200:
          this.records = response.data.records;
          break;
        case 401:
          await this.sendLogoutRequest();
          this.$router.push({ name: "Home" });
          break;
      }

      this.records_loading = !this.records_loading;
    },

    set_form_data() {
      const form_data = new FormData();

      form_data.append("date_from", this.date_from);
      form_data.append("date_to", this.date_to);
      form_data.append("status", this.status);

      return new URLSearchParams(form_data);
    },

    doHandleAction(id, agent, status, amount) {
      switch (this.get_access) {
        case "verifier":
          if (status == "Requested") this.set_special_commission_verify_dialog(id, agent);
          break;
        case "approver":
          if (status == "Verified")
            this.set_special_commission_approve_dialog(id, agent, amount);
          break;
      }
    },

    set_status() {
      this.status =
        this.get_access == "verifier"
          ? "Requested"
          : this.get_access == "approver"
          ? "Verified"
          : "All";
    },

    set_special_commission_dialog(id) {
      this.special_commission_raw_data = { id: id };
      this.special_commission_raw_dialog = !this.special_commission_raw_dialog;
    },

    set_close_special_commission_dialog() {
      this.special_commission_raw_dialog = !this.special_commission_raw_dialog;
    },

    set_special_commission_filter_dialog() {
      this.special_commission_filter_data = {
        date_from: this.date_from,
        date_to: this.date_to,
        status: this.status,
      };
      this.special_commission_filter_dialog = !this.special_commission_filter_dialog;
    },

    set_close_special_commission_filter_dialog(data) {
      if (Object.keys(data).length) {
        this.date_from = data.date_from;
        this.date_to = data.date_to;
        this.status = data.status;

        this.doGetSpecialCommissions();
      }
      this.special_commission_filter_dialog = !this.special_commission_filter_dialog;
    },

    set_special_commission_verify_dialog(id, agent) {
      this.special_commission_verify_data = { id: id, agent: agent };
      this.special_commission_verify_dialog = !this.special_commission_verify_dialog;
    },

    set_close_special_commission_verify_dialog(data) {
      if (data.type) {
        const records_data = this.records.find((record) => record.id == data.id);

        records_data.status = "Verified";
      }
      this.special_commission_verify_dialog = !this.special_commission_verify_dialog;
    },

    set_special_commission_approve_dialog(id, agent, amount) {
      this.special_commission_approve_data = { id: id, agent: agent, amount: amount };
      this.special_commission_approve_dialog = !this.special_commission_approve_dialog;
    },

    set_close_special_commission_approve_dialog(data) {
      if (data.type) {
        const records_data = this.records.find((record) => record.id == data.id);

        records_data.status = "Approved";
      }
      this.special_commission_approve_dialog = !this.special_commission_approve_dialog;
    },

    get_first_and_last_day_of_month() {
      const date = new Date();

      this.date_from = format(
        new Date(date.getFullYear(), date.getMonth(), 1),
        "yyyy-MM-dd"
      );
      this.date_to = format(
        new Date(date.getFullYear(), date.getMonth() + 1, 0),
        "yyyy-MM-dd"
      );
    },

    format_date(date) {
      return formatDate(date);
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    downloadExcelName() {
      return "Special Commission Report " + formatDate(new Date()) + ".xls";
    },
  },
};
</script>
