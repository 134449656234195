const url = process.env.VUE_APP_API_URL + "agents"

export default {
    namespaced: true,   

    state: {
        loading: false,
        confirmationLoading: false,
        records: [],
        agentInfo: [],
        error: null,
        errors: []
    },

    mutations: {
        setLoading(state, val) {
            state.loading = val
        },

        setConfirmationLoading(state, val) {
            state.confirmationLoading = val
        },

        setRecords(state, val) {
            state.records = val
        },

        setAgentInfo(state, val) {
            state.agentInfo = val
        },

        setError(state, val) {
            state.error = val
        },

        setErrors(state, val) {
            state.errors = val
        }
    },

    actions: {

        async getRecords({ commit, rootState }, data) {
            commit("setLoading", true)
      
            try {
                const response = await fetch(`${url}/cashin_request?${data}` , {
                    method: 'GET',
                    headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setRecords", res.records)
                }
            } catch(error) {
              const errorStatus = error.response.status
              
              if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
              } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
              }
            } finally {
              commit("setLoading", false)
            }
          },

          async approveCashin({ commit, rootState }, data) {
            commit("setConfirmationLoading", true)
      
            try {
              const response = await fetch(`${url}/cashin/approve`, {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${rootState.userToken}`,
                },
                body: data
              });
      
              const res = await response.json()
              
              if (!response.ok) {
                throw { response, res }
              } else {
                commit("setError", null)
              }
            } catch(error) {
              const errorStatus = error.response.status
      
              commit("setError", "Error") // Decoy error to detect success
              
              if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
              } else if (errorStatus==417) {
                commit("setPopUpError", error.res.error, { root: true })
              } else {
                commit("setRefreshError", "An error occurred, please try again!", { root: true })
              }
            } finally {
              commit("setConfirmationLoading", false)
            }
          },
      
          async disapproveCashin({ commit, rootState }, data) {
            commit("setConfirmationLoading", true)
      
            try {
                const response = await fetch(`${url}/cashin/disapprove`, {
                    method: 'POST',
                    headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                    },
                    body: data
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setError", null)
                }
            } catch(error) {
                const errorStatus = error.response.status
        
                commit("setError", "Error") // Decoy error to detect success
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else if (errorStatus==417) {
                    commit("setPopUpError", error.res.error, { root: true })
                } else {
                    commit("setRefreshError", "An error occurred, please try again!", { root: true })
                }
            } finally {
              commit("setConfirmationLoading", false)
            }
          },

          async getAgentInfo({ commit, rootState }, data) {
            commit("setLoading", true)
            commit("setError", null)
            commit("setErrors", [])
      
            try {
                const response = await fetch(`${url}/manual/get_user?${data}`, {
                    method: 'GET',
                    headers: {
                    'Authorization': `Bearer ${rootState.userToken}`
                    }
                })
            
                const res = await response.json()
            
                if(!response.ok) {
                    throw { response, res}
                } else {
                    commit("setAgentInfo", res.records)
                }
            } catch (error) {
                const errorStatus = error.response.status
        
                switch(errorStatus) {
                    case 401: 
                        commit("setError", "Unauthorized", { root: true })
                        break;
                    case 417:
                        commit("setError", error.res.error, { root: true })
                        break;
                    case 422:
                        commit("setErrors", error.res.messages)
                        break;
                    default: 
                        commit("setRefreshError", "An error occurred, please try again!", { root: true })  
                        break;
                }
            } finally {
              commit("setLoading", false)
            }
        },

        async cashInManual({ commit, rootState }, data) {
            commit("setLoading", true)
            commit("setError", null)
            commit("setErrors", [])
      
            try {
                const response = await fetch(`${url}/manual/cashin`, {
                    method: 'POST',
                    headers: {
                    'Authorization': `Bearer ${rootState.userToken}`
                    },
                    body: data
                })
            
                const res = await response.json()
            
                if(!response.ok) {
                    throw { response, res}
                } else {
                    commit("setCashInId", res.cashin_id)
                }
            } catch (error) {
                const errorStatus = error.response.status
        
                switch(errorStatus) {
                    case 401: 
                        commit("setError", "Unauthorized", { root: true })
                        break;
                    case 417:
                        commit("setError", error.res.error, { root: true })
                        break;
                    default: 
                        commit("setRefreshError", "An error occurred, please try again!", { root: true })  
                        break;
                }
            } finally {
              commit("setLoading", false)
            }
        }
    }
}