<template>
    <v-dialog v-model="dialog" max-width="400">
        <v-card :loading="forgotLoading">
            <v-card-title>
                Forgot Password 

                 <v-btn icon absolute right @click="setCloseModal">
                    <v-icon size="large">
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text class="py-4">
                <v-form ref="form" :disabled="forgotLoading" @submit.prevent="handleSubmit">
                    <p class="font-weight-regular">Enter your email address or mobile number and we'll send you instructions to reset your password.</p>

                    <v-alert
                        v-model="successAlert"
                        outlined
                        type="success"
                        text
                        class="mb-5"
                        >
                    Successfully Sent! We have sent your reset password link to your {{ typeResponse }}.
                    </v-alert>

                    <p class="caption font-italic">Example of mobile number format '+639293158123' or '09293158123'</p>

                    <v-row dense class="mt-3">
                        <v-col>
                            <v-text-field
                                ref="inputValue"
                                v-model="inputValue"
                                :rules="(submitStatus) ? [rules.required, forgotValError] : []"
                                label="Email address or Mobile address"
                                outlined
                                dense
                                hide-details="auto"/>
                        </v-col>
                    </v-row>

                    <v-row dense class="mt-4">
                        <v-col>
                            <v-btn block
                                color="primary"
                                type="submit"
                                :disabled="forgotLoading"
                            >
                                SEND RESET LINK
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import formRules from '@/helpers/formRules'
import { mapActions, mapState } from 'vuex';

export default {
    data: () => ({
        inputValue: '',
        submitStatus: false,
        rules: formRules,
        successAlert: false,
        forgotValErrorText: ''
    }),

    computed: {
        ...mapState("forgot_password", ["forgotLoading", "typeResponse", "error", "errors", "errorMessage"]),

        dialog: {
            get: function () {
                return true;
            },
            set: function () {
                this.addedPlayer = { "status" : 'cancel' }
                this.setCloseModal();
            },
        },

        forgotValError() {
            if (this.inputValue == this.forgotValErrorText) {
                return this.errorMessage
            } else {
                return true
            }
        },
    },

    methods: {
        ...mapActions("forgot_password", ["doForgotPassword"]),

        setCloseModal() {
            this.$emit("setCloseModal")
        },

        async handleSubmit() {
            this.submitStatus = true

            await new Promise(r => setTimeout(r, 200));

            if (this.$refs.form.validate()) {
                const formData = new FormData()
                formData.append('forgot_val', this.inputValue)

                await this.doForgotPassword(formData)

                 if (!this.error && !Object.keys(this.errors).length) {
                    this.successAlert = true
                    this.successUsed = this.typeResponse
                    this.$refs.form.reset()
                } else {
                    this.forgotValErrorText = this.inputValue
                    this.$refs.form.validate()
                    this.$refs.inputValue.focus()
                }
            }
        }
    }
}
</script>