<template>
    <v-dialog v-model="dialog" max-width="500">
        <v-card :loading="(Object.keys(this.adsImagesData).length != 0) ? recordLoading : false">
            <v-card-title>
                <v-icon class="mr-2">
                    mdi-file-upload-outline
                </v-icon>

                {{ (Object.keys(adsImagesData).length != 0) ? 'Update' : 'New' }}

                <v-btn icon absolute right @click="closeDialog(false)">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text class="py-4">
                <v-form 
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    :disabled="saveLoading || recordLoading"
                    @submit.prevent="doSubmit">

                    <v-alert type="error" v-if="alertDialog">
                        {{ alertDialogMessage }}
                    </v-alert>

                    <v-row>
                        <v-col cols="12">
                            <v-text-field ref="description"
                                outlined
                                dense
                                label="Description"
                                :rules="(submitStatus) ? [rules.required,rules.minimum(5)] : []"    
                                v-model="description"
                                hide-details="auto"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col :cols="(Object.keys(adsImagesData).length != 0 ? '8' : '12')" :class="(Object.keys(adsImagesData).length != 0) ? 'mr-0 pr-0' : ''">
                            <v-file-input
                                ref="ads_image"
                                v-model="ads_image"
                                :rules="Object.keys(adsImagesData).length == 0 ? (submitStatus) ? rules.required : [] : []"
                                accept="image/*"
                                prepend-icon=""
                                append-icon="mdi-open-in-app"
                                show-size
                                @change="validIDOnFileChange"
                                @click:clear="validIDClearFile"
                                label="Select Ads Image"
                                placeholder="Choose Image"
                                dense
                                outlined
                                validate-on-blur
                                counter
                            ></v-file-input>
                        </v-col>

                        <v-col cols="4" v-if="Object.keys(adsImagesData).length != 0 ">
                            <v-btn outlined depressed block class="pt-5 pb-4" @click="adsPreviewDialog = true">
                                <v-icon>
                                    mdi-file-image
                                </v-icon>
                                Preview
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-menu
                                v-model="expiration_menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    v-model="expiration"
                                    :rules="[rules.required]"
                                    label="Ads Expiration"
                                    hide-details="auto"
                                    readonly
                                    outlined   
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    @keyup="expiration_menu=true"
                                    @keydown="expiration_menu=false"
                                    clearable
                                    ></v-text-field>
                                </template>

                                <v-date-picker
                                    v-model="expiration"
                                    @input="expiration_menu = false"
                                    :min="minDate"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row dense v-if="Object.keys(adsImagesData).length">
                        <v-col cols="auto">
                            <v-switch
                            v-model="is_active"
                            :label="is_active ? 'Active' : 'Inactive'"
                            color="success"
                            hide-details/>
                        </v-col>
                    </v-row>

                    <v-row dense class="mt-4">
                        <v-col class="text-end">
                            <v-btn depressed @click="closeDialog(false)"  class="mr-2">
                                Cancel
                            </v-btn>

                            <v-btn depressed color="primary" type="submit" :loading="saveLoading">
                                {{ buttonText }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>

        <AdsPreview :url="ads_image_url" v-if="adsPreviewDialog" @setCloseModal="setCloseModal"/>
    </v-dialog>
</template>

<script>
import formRules from '@/helpers/formRules'
import { format } from 'date-fns'
import AdsPreview from '@/components/ads_images/AdsPreview'
import { mapActions, mapState } from 'vuex'

export default {
    components: {
        AdsPreview
    },

    props: {
        adsImagesData: {
            type: Object
        }
    },

    data: () => ({
        rules: formRules,
        valid: true,
        description: '',
        submitStatus: false,
        ads_image: null,
        ads_image_url: null,
        expiration: format(new Date(), 'yyyy-MM-dd'),
        expiration_menu: false,
        adsPreviewDialog: false,
        alertDialog: false,
        alertDialogMessage: '',
        minDate: format(new Date(), 'yyyy-MM-dd'),
        is_active: true,
        buttonText: 'Save'
    }),

    computed: {
        ...mapState("ads_images", ["saveLoading", "saveError", "record", "recordLoading"]),

        dialog: {
            get: function() {
                return true
            },
            set: function() {
                this.closeDialog(false)
            }
        }
    },

    mounted() {
        this.$refs.description.focus()

        if (Object.keys(this.adsImagesData).length != 0) {
            this.doGetRecord()
        }
    },

    methods: {
        ...mapActions("ads_images", ["saveAdsImage", "updateAdsImage", "getRecord"]),

        closeDialog(status) {
            this.$emit("setCloseDialog", status)
        },

        async doSubmit() {
            this.submitStatus = true

            await new Promise(r => setTimeout(r, 200));

            if (this.$refs.form.validate()) {
                this.alertDialog = false

                if (!this.saveLoading) {
                    if (Object.keys(this.adsImagesData).length != 0) {
                        this.doUpdate()
                    } else {
                        this.doSave()
                    }
                }
            }  
        },

        async doSave() {
            await this.saveAdsImage(this.setAdsImagesFormData())

            if (!this.saveError) {
                this.closeDialog(true)
            } else if (this.saveError == '417') {
                this.alertDialogMessage = 'Failed to upload, please try again!'
                this.alertDialog = true
            } else if (this.saveError == '422') {
                this.alertDialogMessage = 'Ads already exist, please search description and update it!'
                this.alertDialog = true
            } 
        },

        async doUpdate() {
            await this.updateAdsImage(this.setAdsImagesFormData())

            if (!this.saveError) {
                this.closeDialog(true)
            } 
        },

        async doGetRecord() {
            const formData = new FormData()
            formData.append('id', this.adsImagesData.adsId)

            const getData = new URLSearchParams(formData)

            await this.getRecord(getData)
            
            this.description = this.record.description
            this.expiration = this.record.expiration_date
            this.ads_image_url = this.record.path
            this.is_active = (this.record.is_active == 'Active') ? true : false
            this.buttonText = 'Update'
        },

        setAdsImagesFormData() {
            const formData = new FormData()

            formData.append('description', this.description)
            formData.append('ads_image', (this.ads_image) ?? '')
            formData.append('expiration', this.expiration)

            if (Object.keys(this.adsImagesData).length != 0) {
                formData.append('id', this.adsImagesData.adsId)
                formData.append('is_active', (this.is_active) ? 'Active' : 'Inactive')
            }

            return formData
        },

        validIDOnFileChange(e) {
            if (e) { 
                this.ads_image_url = URL.createObjectURL(e) 
                this.adsPreviewDialog = true
            }
        },

        validIDClearFile() {
            this.ads_image_url = null
        },

        setCloseModal() {
            this.adsPreviewDialog = false
        },
    },

    watch: {
        alertDialog(val) {
            if (val) {
                setTimeout(() => {
                    this.alertDialog = false
                    this.$refs.description.focus();
                }, 5000)
            }
        }
    }
}
</script>