<template>
    <v-dialog scrollable transition="dialog-top-transition" v-model="dialog" max-width="1000">
        <v-card>
            <v-card-title class="text-h6 py-4 font-weight-regular grey--text text--darken-1">
                Games

                <v-btn icon absolute right @click="setCloseModal">
                    <v-icon size="large">
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text>
                <v-row dense class="py-4" align="end">
                    <v-col cols="12" md="8">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <tbody class="table-borderless">
                                    <tr>
                                        <td style="width: 10%" class="grey--text">Agent </td>
                                        <td>
                                        <span inline class="overline"></span> <span inline class="overline primary--text"> {{ game_data.agent_name }} </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width: 10%" class="grey--text">Player</td>
                                        <td>
                                        <span inline class="overline"></span> <span inline class="overline primary--text"> {{ game_data.player_name }} </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>

                    <v-col cols="12" md="4">
                        <v-text-field
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            placeholder="Search"
                            single-line
                            hide-details="auto"
                            :disabled="gamesLoading"
                            clearable
                            outlined
                            dense>
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-divider/>

                <v-data-table
                    :headers="headers"
                    :items="games"
                    :items-per-page="5"
                    :search="search"
                    :loading="gamesLoading"
                    loading-text="Loading, please wait a moment"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc">

                    <!-- <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    small
                                    color="primary"
                                    @click="showGames(item.id, item.player_name)">
                                    <v-icon size="large"> mdi-basketball </v-icon>
                                </v-btn>
                            </template>
                            <span>View Per Game</span>
                        </v-tooltip>
                    </template> -->
                    
                    <template v-slot:[`item.game`]="{ item }">
                        <v-avatar size="36px" tile>
                            <v-img :src="item.team_a_logo">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                            indeterminate
                                            color="black"/>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-avatar>
                        <span class="text-caption">{{ item.team_a }}</span>
                        <span class="text-overline my-2 mx-2 font-italic">vs</span>
                        <v-avatar size="36px" tile>
                            <v-img :src="item.team_b_logo">
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                            indeterminate
                                            color="black"/>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-avatar>
                        <span class="text-caption">{{ item.team_b }}</span>
                    </template>

                    <template v-slot:[`item.total_bets`]="{ item }">
                        {{ parseInt(item.total_bets).toLocaleString() }}
                    </template>

                    <template v-slot:[`item.total_bets_amount`]="{ item }">
                        PHP {{ moneyFormat(parseFloat(item.total_bets_amount)) }}
                    </template>

                    <template v-slot:[`item.created_at`]="{ item }">
                        <span>{{ setCreatedAtFormat(item.created_at) }}</span>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    props: {
        game_data: {
            type: Object
        }
    },

    data: () => ({
        search: '',
        sortBy: "id",
        sortDesc: true,
        headers: [
            // { text: "ID", value: "id", align: "left" },
            {
                text: "Game",
                sortable: false,
                value: "game",
                class: "text-uppercase",
            },
            {
                text: "Total Bets",
                sortable: false,
                value: "total_bets",
                class: "text-uppercase",
            },
            {
                text: "Total Amount",
                sortable: false,
                value: "total_bets_amount",
                class: "text-uppercase",
            },
        ],
    }),

    created() {
        this.loadPlayers()
    },

    computed: {
        ...mapState("agents_report", ["gamesLoading", "games"]),

        dialog: {
            get: function () {
                return true;
            },
            set: function () {
                this.setCloseModal();
            },
        },
    },

    methods: {
        ...mapActions("agents_report", ["getGames"]),

        async loadPlayers() {
            await this.getGames(this.setGamesFormData())
        },

        setGamesFormData() {
            const formData = new FormData()
            formData.append("agent_id", this.game_data.agent_id)
            formData.append("player_id", this.game_data.player_id)
            formData.append("date_from", this.game_data.date_from)
            formData.append("date_to", this.game_data.date_to)

            const getData = new URLSearchParams(formData)

            return getData;
        },

        setCloseModal() {
            this.$emit("setGameCloseModal")
        },

        // showGames(id, player_name) {
        //     console.log(`${id} ${player_name}`)
        // },

        moneyFormat(val) {
            return val.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },
    }
}
</script>