<template>
  <div class="master-agents">
    <div class="pa-4 pa-md-6">
      <div class="grey--text" style="font-size: 13px">Investors</div>
      <h2 class="font-weight-bold mb-3 mb-md-5">Master Agent List</h2>

      <v-card class="shadow">
        <!-- <div class="d-flex justify-space-between align-center bs-py-3 bs-px-4">
          <h3>Master Agent List</h3>
        </div>

        <v-divider></v-divider> -->

        <div class="d-flex justify-end px-6 py-4">
          <div class="d-inline-block">
            <v-text-field
              ref="search"
              v-model="search"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="loading"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </div>
        </div>

        <v-divider></v-divider>

        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="items_per_page"
          :search="search"
          :loading="loading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sort_by"
          :sort-desc.sync="sort_desc"
        >
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>

          <template v-slot:[`item.total_sub_agent_count`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ item.total_sub_agent_count }}</span>
              </template>
              <ul style="list-style-type: none">
                <li v-for="(i, breakdown) in item.total_sub_agent_breakdown" :key="i">
                  {{ breakdown + ": " + i }}
                </li>
              </ul>
            </v-tooltip>
          </template>

          <template v-slot:[`item.sales_manager_count`]="{ item }">
            <a
              v-if="item.sales_manager_count != '0'"
              href="#"
              style="text-decoration: none"
              @click.prevent="get_master_agents_sales_manager(item.id, item.name)"
            >
              {{ "View (" + item.sales_manager_count + ")" }}
            </a>
            <span v-else>-</span>
          </template>

          <template v-slot:[`item.regular_player_count`]="{ item }">
            <a
              v-if="item.regular_player_count != '0'"
              href="#"
              style="text-decoration: none"
              @click.prevent="get_master_agents_regular_player(item.id, item.name)"
            >
              {{ "View (" + item.regular_player_count + ")" }}
            </a>
            <span v-else>-</span>
          </template>

          <template v-slot:[`item.mobile_number`]="{ item }">
            {{ "+63" + item.mobile_number }}
          </template>

          <template v-slot:[`item.commission`]="{ item }">
            {{ item.commission }}%
          </template>

          <template v-slot:[`item.sub_commission`]="{ item }">
            <span>{{ item.sub_commission }}%</span>
          </template>

          <template v-slot:[`item.wallet`]="{ item }">
            {{ "₱" + money_format(parseFloat(item.wallet)) }}
          </template>
        </v-data-table>
      </v-card>
    </div>

    <MasterAgentSubAgents
      v-if="master_agent_dialog"
      :master_agent_sub_agent_title="master_agent_sub_agent_title"
      :master_agent_id="master_agent_id"
      :master_agent_name="master_agent_name"
      @close_master_agent_dialog="close_master_agent_dialog"
    />
  </div>
</template>

<script>
import axios from "axios";
import MasterAgentSubAgents from "@/components/agents/MasterAgentSubAgents.vue";

export default {
  data: () => ({
    headers: [
      { text: "Name", value: "name", class: "text-uppercase" },
      {
        text: "Total Account",
        value: "total_sub_agent_count",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "SM",
        value: "sales_manager_count",
        class: "text-uppercase",
      },
      {
        text: "RP",
        value: "regular_player_count",
        class: "text-uppercase",
      },
      { text: "Mobile Number", value: "mobile_number", class: "text-uppercase" },
      {
        text: "Commission",
        value: "commission",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Sub Commission",
        value: "sub_commission",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Wallet",
        value: "wallet",
        class: "text-uppercase",
        align: "right",
      },
    ],
    records: [],
    loading: false,
    search: null,
    items_per_page: 5,
    sort_by: "date",
    sort_desc: true,
    master_agent_dialog: false,
    master_agent_sub_agent_title: null,
    master_agent_name: null,
  }),
  mounted() {
    this.get_master_agent_list();
  },

  name: "MasterAgents",
  components: {
    MasterAgentSubAgents,
  },
  methods: {
    async get_master_agent_list() {
      this.loading = true;
      this.records = [];

      let url = process.env.VUE_APP_API_URL;
      let token = localStorage.getItem("userToken");

      await axios
        .get(url + "agents/master_agent", {
          headers: { Authorization: `Bearer ` + token },
        })
        .then((res) => {
          this.records = res.data.records;
        });

      this.loading = false;
    },

    get_master_agents_regular_player(id, name) {
      this.master_agent_sub_agent_title = "Regular Player";
      this.master_agent_id = id;
      this.master_agent_name = name;
      this.master_agent_dialog = true;
    },
    get_master_agents_sales_manager(id, name) {
      this.master_agent_sub_agent_title = "Sales Manager";
      this.master_agent_id = id;
      this.master_agent_name = name;
      this.master_agent_dialog = true;
    },

    close_master_agent_dialog() {
      this.master_agent_dialog = false;
      this.master_agent_sub_agent = [];
    },
    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>
