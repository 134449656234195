<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Players</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Restricted Player List</h2>
    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="handleClick"
                >
                  <v-icon>mdi-plus-box-outline</v-icon>
                </v-btn>
              </template>

              <span>Create</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  @click="loadRecords"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="recordsLoading"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="5"
          :search="search"
          :loading="recordsLoading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  color="primary"
                  @click="
                    setPlayerData(
                      item.restricted_player_id,
                      item.full_name,
                      item.gender
                    )
                  "
                >
                  <v-icon size="large"> mdi-square-edit-outline </v-icon>
                </v-btn>
              </template>
              <span>Update</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ setCreatedAtFormat(item.created_at) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <FormDialog
      v-if="dialogStatus"
      :playerData="playerData"
      class="mr-1"
      @closeModal="setData"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formatDatev2 from "@/helpers/formatDatev2";
import FormDialog from "@/components/restricted_players/FormDialog";

export default {
  components: { FormDialog },
  data: () => ({
    playerData: [],
    dialogStatus: false,
    search: "",
    sortBy: "restricted_player_id",
    sortDesc: true,
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      {
        text: "Name",
        value: "full_name",
        class: "text-uppercase",
      },
      {
        text: "Gender",
        value: "gender",
        class: "text-uppercase",
      },
      {
        text: "Created At",
        value: "created_at",
        class: "text-uppercase",
      },
    ],
  }),
  created() {
    this.loadRecords();
  },
  computed: {
    ...mapState("restricted_players", ["records", "recordsLoading"]),
  },
  methods: {
    ...mapActions("restricted_players", ["getRecords"]),
    loadRecords() {
      if (!this.recordsLoading) {
        this.$store.commit("restricted_players/setRecords", []);
        this.getRecords();
      }
    },
    handleClick() {
      this.dialogStatus = true;
      this.playerData = {};
    },
    setPlayerData(id, full_name, gender) {
      this.dialogStatus = true;
      this.playerData = { id, full_name, gender };
    },
    setData(updatedData, insertedData) {
      this.dialogStatus = false;

      if (Object.keys(updatedData).length != 0) {
        const playerData = this.records.find(
          (record) => record.restricted_player_id == updatedData.id
        );

        playerData.full_name = updatedData.full_name;
        playerData.gender = updatedData.gender;
      } else if (Object.keys(insertedData).length != 0) {
        this.$store.commit("restricted_players/setRecords", [
          insertedData,
          ...this.records,
        ]);
      }
    },
    setCreatedAtFormat(date) {
      return formatDatev2(date);
    },
  },
};
</script>
