import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import Maintenance from "../views/Maintenance.vue";

Vue.use(VueRouter);

// console.log(store.state.userData)
// console.log(store.state.accountInfo.access)
// console.log(store.getters.isLoggedInVerified)
// console.log(store.state.accountInfo)

const guest = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    return next();
  } else {
    if (store.getters.isLoggedInVerified) {
      return next({ name: "Dashboard" });
    } else {
      return next({ name: "LoginVerification" });
    }
  }
};

const auth = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (!store.getters.isLoggedInVerified) {
      if (to.name != "LoginVerification") {
        return next({ name: "LoginVerification" });
      } else {
        return next();
      }
    } else {
      if (to.name != "LoginVerification") {
        return next();
      } else {
        return next({ name: "Dashboard" });
      }
    }
  } else {
    return next({ name: "Home" });
  }
};

const superAdmin = (to, from, next) => {
	auth(to, from, next)
	const user = store.state.userData
	if(user.uid != 76) {
		return next({ name: "Home" });
	}

	return next();
};

/* const checkAccess = (access) => {
  if (store.state.accountInfo.access.includes(access)) {
    console.log('Meron')
  } else {
    console.log('Wala')
  }
  console.log(access)
} */

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: guest,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/reset_password/:id",
    name: "ResetPassword",
    // route level code-splitting
    // this generates a separate chunk (login_verification.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "login_verification" */ "../views/reset_password/ResetPassword.vue"
      ),
    beforeEnter: guest,
    props: true,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/login_verification",
    name: "LoginVerification",
    // route level code-splitting
    // this generates a separate chunk (login_verification.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "login_verification" */ "../views/LoginVerification.vue"
      ),
    beforeEnter: auth,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
    beforeEnter: auth,
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    beforeEnter: auth,
  },
  {
    path: "/investors",
    name: "Investors",
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "investors" */ "../views/investors/Investors.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/investor/payouts",
    name: "PayoutsSettlement",
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "investors" */ "../views/investors/Payouts.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/investor/commissions",
    name: "CommissionsSettlement",
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "investors" */ "../views/investors/Commissions.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/investor/special_commissions",
    name: "SpecialCommissions",
    // route level code-splitting
    // this generates a separate chunk (specialcommissions.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "specialcommissions" */ "../views/investors/SpecialCommissions.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/investor/referral_links",
    name: "ReferralLinks",
    // route level code-splitting
    // this generates a separate chunk (specialcommissions.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "specialcommissions" */ "../views/investors/ReferralLinks.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/agent_list",
    name: "AgentList",
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/agents/AgentList.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/investor/master_agent_list",
    name: "MasterAgents",
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/agents/MasterAgents.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/agent_cashin",
    name: "AgentCashIn",
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/agents/CashIn.vue"),
    beforeEnter: auth,
  },
  {
    path: "/commission_payments",
    name: "AgentCommissionPayments",
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "commission_payments" */ "../views/agents/CommissionPayments.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/players/list",
    name: "PlayerList",
    // route level code-splitting
    // this generates a separate chunk (playerlist.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "playerlist" */ "../views/players/PlayerList.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/player/:id",
    name: "PlayerDetails",
    // route level code-splitting
    // this generates a separate chunk (playerdetails.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "playerdetails" */ "../views/players/PlayerDetails.vue"
      ),
    props: true,
    beforeEnter: auth,
  },
  {
    path: "/players/withdrawal_accounts",
    name: "PlayersWithdrawalAccounts",
    // route level code-splitting
    // this generates a separate chunk (playerlist.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "player_withdrawal_accounts" */ "../views/players/PlayersWithdrawalAccounts.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/players/cashin",
    name: "CashIn",
    // route level code-splitting
    // this generates a separate chunk (cashin.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "cashin" */ "../views/players/CashIn.vue"),
    beforeEnter: auth,
  },
  {
    path: "/players/cashout",
    name: "CashOut",
    // route level code-splitting
    // this generates a separate chunk (cashout.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "cashout" */ "../views/players/CashOut.vue"),
    beforeEnter: auth,
  },
  {
    path: "/players/reject_reasons",
    name: "PlayerRejectReasons",
    // route level code-splitting
    // this generates a separate chunk (playerRejectReasons.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "playerRejectReasons" */ "../views/players/RejectReasons.vue"
      ),
    beforeEnter: auth,
  },
  // ENDING
  {
    path: "/ending_bets",
    name: "EndingBets",
    // route level code-splitting
    // this generates a separate chunk (endingbets.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "endingbets" */ "../views/ending/Bets.vue"),
    beforeEnter: auth,
  },
  {
    path: "/ending_cards",
    name: "EndingCards",
    // route level code-splitting
    // this generates a separate chunk (endingcards.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "endingcards" */ "../views/ending/Cards.vue"),
    beforeEnter: auth,
  },
  {
    path: "/ending_card/:id",
    name: "EndingCardDetails",
    // route level code-splitting
    // this generates a separate chunk (endingcarddetails.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endingcarddetails" */ "../views/ending/CardDetails.vue"
      ),
    props: true,
    beforeEnter: auth,
  },
  {
    path: "/ending_scheduled_games",
    name: "EndingScheduledGames",
    // route level code-splitting
    // this generates a separate chunk (endingscheduledgames.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endingscheduledgames" */ "../views/ending/ScheduledGames.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/ending_game/:id",
    name: "EndingGameDetails",
    // route level code-splitting
    // this generates a separate chunk (endinggamedetails.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endinggamedetails" */ "../views/ending/GameDetails.vue"
      ),
    props: true,
    beforeEnter: auth,
  },
  {
    path: "/game_card/:game_id/:league/:bet_amount_id/:bet_type_id",
    name: "EndingGameCard",
    // route level code-splitting
    // this generates a separate chunk (endinggamedetails.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endinggamedetails" */ "../views/ending/GameCard.vue"
      ),
    props: true,
    beforeEnter: auth,
  },
  {
    path: "/game_cards/:id",
    name: "EndingGameCards",
    // route level code-splitting
    // this generates a separate chunk (endinggamedetails.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endinggamedetails" */ "../views/ending/GameCards.vue"
      ),
    props: true,
    beforeEnter: auth,
  },
  {
    path: "/ending_teams",
    name: "EndingTeams",
    // route level code-splitting
    // this generates a separate chunk (endingteams.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "endingteams" */ "../views/ending/Teams.vue"),
    beforeEnter: auth,
  },
  {
    path: "/ending_leagues",
    name: "EndingLeagues",
    // route level code-splitting
    // this generates a separate chunk (endingleagues.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endingleagues" */ "../views/ending/Leagues.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/ending_config",
    name: "EndingConfig",
    // route level code-splitting
    // this generates a separate chunk (endingconfig.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endingconfig" */ "../views/ending/Config.vue"
      ),
    beforeEnter: auth,
  },
  // ENDING REPORT
  {
    path: "/game_report",
    name: "GameReport",
    // route level code-splitting
    // this generates a separate chunk (endinggamereport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endinggamereport" */ "../views/ending/reports/Game.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/progress_report",
    name: "ProgressReport",
    // route level code-splitting
    // this generates a separate chunk (endingprogressreport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endingprogressreport" */ "../views/ending/reports/Progress.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/transactional_report",
    name: "TransactionalReport",
    // route level code-splitting
    // this generates a separate chunk (transactionalreport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "transactionalreport" */ "../views/ending/reports/Transactional.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/cashin_report",
    name: "CashInReport",
    // route level code-splitting
    // this generates a separate chunk (cashinreport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "cashinreport" */ "../views/ending/reports/CashIn.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/cashout_report",
    name: "CashOutReport",
    // route level code-splitting
    // this generates a separate chunk (cashoutreport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "cashoutreport" */ "../views/ending/reports/CashOut.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/cards",
    name: "CardsReport",
    // route level code-splitting
    // this generates a separate chunk (cards.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "cards" */ "../views/ending/reports/Cards.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/payouts",
    name: "PayoutsReport",
    // route level code-splitting
    // this generates a separate chunk (payoutsreport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "cards" */ "../views/ending/reports/Payouts.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/players",
    name: "Players",
    // route level code-splitting
    // this generates a separate chunk (players.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "players" */ "../views/ending/reports/Players.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/leagues_bet",
    name: "LeaguesBet",
    // route level code-splitting
    // this generates a separate chunk (leaguesbet.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "leaguesbet" */ "../views/ending/reports/LeagueBet.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/top_winning_combination",
    name: "TopWinningCombination",
    // route level code-splitting
    // this generates a separate chunk (payoutsreport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "top_winning_combination" */ "../views/ending/reports/TopWinningCombination.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/master_agents_token",
    name: "MasterAgentsToken",
    // route level code-splitting
    // this generates a separate chunk (payoutsreport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "master_agents_token" */ "../views/ending/reports/MasterAgentsToken.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/cards_per_game/:id/:type_id",
    name: "CardsPerGame",
    // route level code-splitting
    // this generates a separate chunk (cardspergame.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "cardspergame" */ "../views/ending/reports/CardsPerGame.vue"
      ),
    props: true,
    beforeEnter: auth,
  },
  // PAGCOR REPORT
  {
    path: "/pagcor_game_report",
    name: "PagcorGameReport",
    // route level code-splitting
    // this generates a separate chunk (endinggamereport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endinggamereport" */ "../views/ending/pagcor_reports/Game.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/pagcor_progress_report",
    name: "PagcorProgressReport",
    // route level code-splitting
    // this generates a separate chunk (endinggamereport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endinggamereport" */ "../views/ending/pagcor_reports/Progress.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/pagcor_transactional_report",
    name: "PagcorTransactionalReport",
    // route level code-splitting
    // this generates a separate chunk (endinggamereport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endinggamereport" */ "../views/ending/pagcor_reports/Transactional.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/lucky_four_pagcor_game_report",
    name: "LuckyFourPagcorGameReport",
    // route level code-splitting
    // this generates a separate chunk (luckyFourGameReport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "luckyFourGameReport" */ "../views/lucky_four/pagcor_reports/Game.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/lucky_four_pagcor_progress_report",
    name: "LuckyFourPagcorProgressReport",
    // route level code-splitting
    // this generates a separate chunk (luckyFourGameReport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "luckyFourGameReport" */ "../views/lucky_four/pagcor_reports/Progress.vue"
      ),
    beforeEnter: auth,
  },
  // AGENT REPORT
  {
    path: "/agent_bets",
    name: "AgentBets",
    // route level code-splitting
    // this generates a separate chunk (endinggamereport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endinggamereport" */ "../views/agents_reports/AgentBets.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/agent_progress",
    name: "AgentProgress",
    // route level code-splitting
    // this generates a separate chunk (endinggamereport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endinggamereport" */ "../views/agents_reports/AgentProgress.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/agent_kubrador_progress",
    name: "AgentKubradorProgress",
    // route level code-splitting
    // this generates a separate chunk (endinggamereport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endinggamereport" */ "../views/agents_reports/AgentKubradorProgress.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/agent_commission",
    name: "AgentCommission",
    // route level code-splitting
    // this generates a separate chunk (agentcommission.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "agentcommission" */ "../views/agents_reports/AgentCommission.vue"
      ),
    beforeEnter: auth,
  },

  {
    path: "/agent_players_monitoring",
    name: "AgentPlayersMonitoring",
    // route level code-splitting
    // this generates a separate chunk (endinggamereport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endinggamereport" */ "../views/agents_monitoring/MonitoringAgentPlayers.vue"
      ),
    beforeEnter: auth,
  },
  // AGENT REPORT
  // {
  //   path: '/agent_bets',
  //   name: 'AgentBets',
  //   // route level code-splitting
  //   // this generates a separate chunk (endinggamereport.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "endinggamereport" */ '../views/agents_reports/AgentBets.vue'),
  //   beforeEnter: auth
  // },
  {
    path: "/monitoring/game_card",
    name: "GameCardMonitoring",
    // route level code-splitting
    // this generates a separate chunk (endinggamereport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endinggamereport" */ "../views/monitoring/GameCardMonitoring.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/monitoring/game_card/per_game/:id/:p_default_load/:p_date_from/:p_date_to",
    name: "GameCardPerGameMonitoring",
    // route level code-splitting
    // this generates a separate chunk (endinggamereport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endinggamereport" */ "../views/monitoring/GameCardPerGameMonitoring.vue"
      ),
    props: true,
    beforeEnter: auth,
  },
  {
    path: "/monitoring/game_card/per_bet/:id/:p_date_from/:p_date_to",
    name: "GameCardPerBetMonitoring",
    // route level code-splitting
    // this generates a separate chunk (endinggamereport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "endinggamereport" */ "../views/monitoring/GameCardPerBetMonitoring.vue"
      ),
    props: true,
    beforeEnter: auth,
  },
  {
    path: "/outlets",
    name: "Outlets",
    // route level code-splitting
    // this generates a separate chunk (users.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/outlets/Outlets.vue"),
    beforeEnter: auth,
  },
  {
    path: "/users",
    name: "Users",
    // route level code-splitting
    // this generates a separate chunk (users.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Users.vue"),
    beforeEnter: auth,
  },
  {
    path: "/users/create",
    name: "UsersCreate",
    // route level code-splitting
    // this generates a separate chunk (users.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/UsersCreate.vue"),
    beforeEnter:  superAdmin
  },
  {
    path: "/users/change_password",
    name: "ChangePassword",
    // route level code-splitting
    // this generates a separate chunk (users.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/ChangePassword.vue"),
    beforeEnter: auth,
  },
  {
    path: "/users/update/:user_id",
    name: "UsersUpdate",
    // route level code-splitting
    // this generates a separate chunk (users.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/UsersUpdate.vue"),
    props: true,
    beforeEnter: superAdmin,
  },
  {
    path: "/ads_images",
    name: "AdsImages",
    // route level code-splitting
    // this generates a separate chunk (users.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "adsimages" */ "../views/ads_images/AdsImages.vue"
      ),
    props: true,
    beforeEnter: auth,
  },

  // {
  //   path: "/advisories",
  //   name: "Advisories",
  //   // route level code-splitting
  //   // this generates a separate chunk (users.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "adsimages" */ "../views/advisories/Advisories.vue"
  //     ),
  //   props: true,
  //   beforeEnter: auth,
  // },

  {
    path: "/lucky_four_games",
    name: "LuckyFourGames",
    // route level code-splitting
    // this generates a separate chunk (luckyFourGames.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "luckyFourGames" */ "../views/lucky_four/Games.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/lucky_four_games/:id",
    name: "LuckyFourGameDetails",
    // route level code-splitting
    // this generates a separate chunk (luckyFourGameDetails.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "luckyFourGameDetails" */ "../views/lucky_four/GameDetails.vue"
      ),
    props: true,
    beforeEnter: auth,
  },
  {
    path: "/lucky_four_prizes_settings",
    name: "LuckyFourPrizesSettings",
    // route level code-splitting
    // this generates a separate chunk (luckyFourPrizesSettings.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "luckyFourPrizesSettings" */ "../views/lucky_four/PrizesSettings.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/lucky_four_bets",
    name: "LuckyFourBets",
    // route level code-splitting
    // this generates a separate chunk (luckyFourBets.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "luckyFourBets" */ "../views/lucky_four/Bets.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/lucky_four/reports/game",
    name: "LuckyFourGameReport",
    // route level code-splitting
    // this generates a separate chunk (luckyFourGameReport.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "luckyFourGameReport" */ "../views/lucky_four/reports/Game.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/restricted_player_list",
    name: "RestrictedPlayerList",
    // route level code-splitting
    // this generates a separate chunk (playerlist.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "playerlist" */ "../views/players/RestrictedPlayerList.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/deposit_config",
    name: "DepositConfig",
    // route level code-splitting
    // this generates a separate chunk (playerlist.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "playerlist" */ "../views/players/DepositConfig.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/power_ball_scheduled_games",
    name: "PowerBallScheduledGames",
    // route level code-splitting
    // this generates a separate chunk (powerBallScheduledGames.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "powerBallScheduledGames" */ "../views/power_ball/ScheduledGames.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/power_ball_scheduled_games/:id",
    name: "PowerBallGameDetails",
    // route level code-splitting
    // this generates a separate chunk (powerBallGameDetails.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "powerBallGameDetails" */ "../views/power_ball/GameDetails.vue"
      ),
    props: true,
    beforeEnter: auth,
  },
  {
    path: "/power_ball_bets",
    name: "PowerBallBets",
    // route level code-splitting
    // this generates a separate chunk (powerBallBets.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "powerBallBets" */ "../views/power_ball/Bets.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/sms",
    name: "Sms",
    // route level code-splitting
    // this generates a separate chunk (sms.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "sms" */ "../views/Sms.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/news_and_updates",
    name: "NewsAndUpdates",
    // route level code-splitting
    // this generates a separate chunk (newsAndUpdates.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "newsAndUpdates" */ "../views/NewsAndUpdates.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/admin_access",
    name: "AdminAccess",
    // route level code-splitting
    // this generates a separate chunk (adminAccess.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "adminAccess" */ "../views/AdminAccess.vue"
      ),
    beforeEnter: auth,
  },
  {
    path: "/active_users",
    name: "ActiveUsers",
    // route level code-splitting
    // this generates a separate chunk (adminAccess.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "adminAccess" */ "../views/ActiveUsers.vue"
      ),
    beforeEnter: superAdmin,
  },

  // catchall 404
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "pagenotfound" */ "../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior() {
  //   document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  // },
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

export default router;
