const url = process.env.VUE_APP_API_URL 

export default {
    namespaced: true,
    state: {
        userId: 0,
        users: [],
        usersInformation: [],
        userAccess: [],
        loading: false,
        error: null,
        errors: []
    },
    mutations: {
        setUserId(state, val) {
            state.userId = val
        },
        setUsers(state, val) {
            state.users = val
        },
        setUsersInformation(state, val) {
            state.usersInformation = val
        },
        setUserAccess(state, val) {
            state.userAccess = val
        },
        setLoading(state, val) {
            state.loading = val
        },
        setError(state, val) {
            state.error = val
        },
        setErrors(state, val) {
            state.errors = val
        }
    },
    actions: {
        async getUsers({ commit, rootState }) {
            commit("setLoading", true)

            try {
                const response = await fetch(`${url}users`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`
                    }
                })

                const res = await response.json()

                if (!response.ok) {
                    throw { response, res }
                } else { commit("setUsers", res.records) }
            } catch (error) {
                const errorStatus = error.response.status

                if (errorStatus == 401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setLoading", false)
            }
        },
        async getUsersInformation( { commit, rootState }, data) {
            //commit("setLoading", true)

            try {
                const response = await fetch(`${url}users/get_user_information?${data}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`
                    }
                })

                const res = await response.json()

                if (!response.ok) {
                    throw { response, res }
                } else { commit("setUsersInformation", res.records) }
            } catch (error) {
                const errorStatus = error.response.status

                if (errorStatus == 401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setLoading", false)
            }
        },
        async getUserAccess({ commit, rootState }) {
            commit("setLoading", true)

            try {
                const response = await fetch(`${url}users/get_access`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`
                    }
                })

                const res = await response.json()

                if (!response.ok) {
                    throw { response, res }
                } else { commit("setUserAccess", res.records) }
            } catch (error) {
                const errorStatus = error.response.status

                if (errorStatus == 401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setLoading", false)
            }
        },
        async createUser({ commit, rootState }, data) {
            commit("setLoading", true)
            commit("setError", null)
            commit("setErrors", [])

            try {
                const response = await fetch(`${url}users/create`, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    },
                    body: data
                })

                const res = await response.json()

                if (!response.ok) {
                    throw { response, res }
                } else commit("setUserId", res.user_id)
            } catch (error) {
                const errorStatus = error.response.status

                switch(errorStatus) {
                    case 401:
                      commit("setError", "Unauthorized", { root: true })
                      break;
                    case 404:
                      commit("setError", error.res.messages.error)
                      break;
                    case 417:
                      commit("setPopUpErrror", error.res.error, { root: true })
                      break;
                    case 422:
                        commit("setErrors", error.res.messages) 
                      break;
                    default:
                      commit("setRefreshError", "An error occured, please try again!", { root: true })
                  } 
            } finally {
                commit("setLoading", false)
            }
        },
        async updateUser({ commit, rootState }, data) {
            commit("setLoading", true)
            commit("setError", null)
            commit("setErrors", [])

            try {
                const response = await fetch(`${url}users/update`, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    },
                    body: data
                })

                const res = await response.json()

                if (!response.ok) {
                    throw { response, res }
                } 
            } catch (error) {
                const errorStatus = error.response.status

                switch(errorStatus) {
                    case 401:
                      commit("setError", "Unauthorized", { root: true })
                      break;
                    case 404:
                      commit("setError", error.res.messages.error)
                      break;
                    case 417:
                      commit("setPopUpErrror", error.res.error, { root: true })
                      break;
                    case 422:
                        commit("setErrors", error.res.messages) 
                      break;
                    default:
                      commit("setRefreshError", "An error occured, please try again!", { root: true })
                  } 
            } finally {
                commit("setLoading", false)
            }
        },
        async resetPassword({ commit, rootState }, data) {
            commit("setLoading", true)
            commit("setError", null)
            commit("setErrors", [])

            try {
                const response = await fetch(`${url}users/reset_password`, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    },
                    body: data
                })

                const res = await response.json()

                if (!response.ok) {
                    throw { response, res }
                } 
            } catch (error) {
                const errorStatus = error.response.status

                switch(errorStatus) {
                    case 401:
                      commit("setError", "Unauthorized", { root: true })
                      break;
                    case 403:
                      commit("setError", error.res.messages.error)
                      break;
                    case 404:
                      commit("setError", error.res.messages.error)
                      break;
                    case 417:
                      commit("setPopUpErrror", error.res.error, { root: true })
                      break;
                    case 422:
                        commit("setErrors", error.res.messages) 
                      break;
                    default:
                      commit("setRefreshError", "An error occured, please try again!", { root: true })
                  } 
            } finally {
                commit("setLoading", false)
            }
        },

        async changePassword({ commit, rootState }, data) {
            commit("setLoading", true)
            commit("setError", null)
            commit("setErrors", [])

            try {
                const response = await fetch(`${url}users/change_password`, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    },
                    body: data
                })

                const res = await response.json()

                if (!response.ok) {
                    throw { response, res }
                } 
            } catch (error) {
                const errorStatus = error.response.status

                switch(errorStatus) {
                    case 401:
                      commit("setError", "Unauthorized", { root: true })
                      break;
                    case 403:
                      commit("setErrors", error.res.messages)
                      break;
                    case 404:
                      commit("setError", error.res.messages.error)
                      break;
                    case 417:
                      commit("setPopUpErrror", error.res.error, { root: true })
                      break;
                    case 422:
                        commit("setErrors", error.res.messages) 
                      break;
                    default:
                      commit("setRefreshError", "An error occured, please try again!", { root: true })
                  } 
            } finally {
                commit("setLoading", false)
            }
        }
    }
}