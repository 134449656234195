<template>
  <div class="pa-4">
    <div class="grey--text" style="font-size: 13px">Ending Reports</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">League Bets</h2>

    <v-card class="shadow-none">
      <v-card-text class="d-flex flex-column pb-0" style="gap:1rem">
        <div class="d-flex flex-sm-row flex-column justify-space-between" style="gap:1rem">
          <div class="d-flex" style="gap:.30rem">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="grey lighten-5" v-bind="attrs" v-on="on" @click="doGetLeagueBets"
                  :disabled="records_loading">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <downloadexcel :data="records" :fields="{
                  'LEAGUE': 'league',
                  'GAME': 'game',
                  'BET': 'bet',
                  'BET AMOUNT': 'amount'
                }" :name="downloadExcelName()" type="csv" class="d-inline-block">
                  <v-btn icon color="secondary" v-bind="attrs" v-on="on" :disabled="records_loading">
                    <v-icon>mdi-file-delimited-outline</v-icon>
                  </v-btn>
                </downloadexcel>
              </template>
              <span>Export to Excel</span>
            </v-tooltip>
          </div>

          <div class="d-flex" style="gap:.30rem">
            <v-text-field v-model="search" autofocus dense outlined label="Search" placeholder="Search" hide-details
              :disabled="records_loading" />

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="grey lighten-5" v-bind="attrs" v-on="on" :disabled="records_loading"
                  @click="set_filter_dialog">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </div>
        </div>

        <v-data-table :headers="headers" :items="records" :search="search" :loading="records_loading"
          loading-text="Loading, please wait a moment" :items-per-page="5">

          <template v-slot:[`item.game`]="{ item }">
            {{ number_format(parseInt(item.game)) }}
          </template>

          <template v-slot:[`item.bet`]="{ item }">
            {{ number_format(parseInt(item.bet)) }}
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            {{ `Php ${money_format(parseFloat(item.amount))}` }}
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>

    <LeagueBetFilter v-if="league_filter_dialog" :data="league_filter_data"
      @set_close_filter_dialog="set_close_filter_dialog" />
  </div>
</template>

<script>
import responseGet from '@/helpers/api_request_get'
import { format } from 'date-fns'
import formatDatev2 from '@/helpers/formatDatev2'
import { mapActions } from 'vuex'
import LeagueBetFilter from '@/components/reports/LeagueBetFilter'
import downloadexcel from "vue-json-excel"

export default {
  components: { LeagueBetFilter, downloadexcel },

  data: () => ({
    records: [],
    records_loading: false,
    search: '',
    date_from: format(new Date(), "yyyy-MM-dd"),
    date_to: format(new Date(), "yyyy-MM-dd"),
    league_filter_dialog: false,
    league_filter_data: {},
    headers: [
      {
        text: "League",
        value: "league",
        class: "text-uppercase",
      },
      {
        text: "Game",
        value: "game",
        class: "text-uppercase",
        align: "right"
      },
      {
        text: "Bet",
        value: "bet",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Bet Amount",
        value: "amount",
        class: "text-uppercase",
        align: "right",
      }
    ]
  }),

  mounted() {
    this.check_query_string()
    this.doGetLeagueBets()
  },

  methods: {
    ...mapActions("logout", ["sendLogoutRequest"]),

    async doGetLeagueBets() {
      this.records = []
      this.records_loading = !this.records_loading

      const response = await responseGet(this.set_form_data(), 'ending_reports/leagues')

      switch (response.status) {
        case 200:
          this.records = response.data.records
          break;
        case 401:
          await this.sendLogoutRequest();
          this.$router.push({ name: "Home" });
          break;
      }

      this.records_loading = !this.records_loading
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('date_from', this.date_from)
      form_data.append('date_to', this.date_to)

      return new URLSearchParams(form_data)
    },

    set_filter_dialog() {
      this.league_filter_data = {
        'date_from': this.date_from,
        'date_to': this.date_to
      }

      this.league_filter_dialog = !this.league_filter_dialog
    },

    set_close_filter_dialog(data) {
      if (data.type) {
        this.date_from = data.date_from
        this.date_to = data.date_to

        this.doGetLeagueBets()
        this.set_query_string()
      }

      this.league_filter_dialog = !this.league_filter_dialog
    },

    set_query_string() {
      const urlQuery = this.$route.query;
      this.query_filters = {};

      if (urlQuery.date_from != this.date_from ||
        urlQuery.date_to != this.date_to) {

        if (this.date_from != null) this.query_filters["date_from"] = this.date_from;
        if (this.date_to != null) this.query_filters["date_to"] = this.date_to;

        this.$router.replace({
          name: this.$route.name,
          query: this.query_filters,
        });
      }
    },

    check_query_string() {
      const urlQuery = this.$route.query;

      if (Object.keys(urlQuery).length) {
        if (Object.prototype.hasOwnProperty.call(urlQuery, "date_from"))
          this.date_from = urlQuery.date_from;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "date_to"))
          this.date_to = urlQuery.date_to;
      }
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    downloadExcelName() {
      return "League Bet Count Report " + formatDatev2(new Date()) + ".xls";
    },
  }
}
</script>