<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Lucky Four Reports</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Game</h2>
    <v-card class="shadow">
      <v-card-title
        class="text-h6 py-2 font-weight-regular grey--text text--darken-1"
      >
        Search Games
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-4">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="handleSubmit"
        >
          <v-row dense>
            <v-col cols="12" md="6" class="mt-4">
              <v-menu
                v-model="schedule_date_start_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="schedule_date_start"
                    :rules="[rules.required]"
                    label="Schedule Date Start"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @keyup="schedule_date_start_menu = true"
                    @keydown="schedule_date_start_menu = false"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="schedule_date_start"
                  @input="schedule_date_start_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" class="mt-4">
              <v-menu
                v-model="schedule_date_end_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="schedule_date_end"
                    :rules="[lessThanDate]"
                    label="Schedule Date End (Optional)"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @keyup="schedule_date_end_menu = true"
                    @keydown="schedule_date_end_menu = false"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="schedule_date_end"
                  @input="schedule_date_end_menu = false"
                  :min="schedule_date_start ? schedule_date_start : ''"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row dense class="mt-2">
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="league"
                :items="leagues"
                item-text="league"
                item-value="id"
                label="League (Optional)"
                dense
                outlined
                hide-details="auto"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="team"
                :items="leagueTeams"
                :item-text="leagueTeamName"
                item-value="id"
                label="Team (Optional)"
                dense
                outlined
                hide-details="auto"
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row dense class="mt-2">
            <v-col cols="12" md="6">
              <v-select
                v-model="game_status"
                :items="game_statuses"
                label="Game Status"
                dense
                outlined
                hide-details="auto"
                clearable
              ></v-select>
            </v-col>
          </v-row>
          <div class="mt-4">
            <v-btn
              :disabled="!valid"
              type="submit"
              color="primary"
              outlined
              @click.prevent="handleSubmit"
              :loading="recordsLoading"
            >
              Search
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-row align="end" justify="space-between">
              <v-col cols="auto" class="py-1">
                <span
                  class="text-h6 py-2 font-weight-regular grey--text text--darken-1"
                >
                  Games
                </span>
              </v-col>
              <v-col v-if="records.length" cols="auto" class="text-right py-1">
                <downloadexcel
                  :data="exportExcelDataWithTotal()"
                  :fields="{
                    ID: 'id',
                    League: 'league',
                    Game: 'game',
                    'Game Status': 'game_status',
                    'Schedule Date': 'schedule',
                    'Gross Sales': 'game_total_bet_amount',
                    Payouts: 'game_total_prizes',
                    'Gross Gaming Revenue': 'net_amount',
                    'PAGCOR Share': 'pagcor_share',
                    'Net Amount': 'net_income',
                  }"
                  :name="downloadExcelName()"
                  type="csv"
                  class="d-inline-block"
                >
                  <v-btn color="primary" outlined small depressed>
                    Export CSV
                  </v-btn>
                </downloadexcel>
                <v-btn
                  color="primary"
                  outlined
                  small
                  depressed
                  @click="exportToPDF"
                  class="mx-2 d-inline-block"
                >
                  Export PDF
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>

          <v-row dense class="ma-4">
            <v-col md align="center" class="px-1">
              <v-card class="pa-1">
                <p class="font-weight-regular ma-0 overline">
                  TOTAL GROSS AMOUNT
                </p>
                <v-divider class="mb-4" />
                <p class="font-weight-medium">
                  PHP {{ moneyFormat(parseFloat(totalGrossAmount)) }}
                </p>
              </v-card>
            </v-col>

            <v-col md align="center" class="px-1">
              <v-card class="pa-1">
                <p class="font-weight-regular ma-0 overline">TOTAL PAYOUTS</p>
                <v-divider class="mb-4" />
                <p class="font-weight-medium d-inline-flex">
                  PHP {{ moneyFormat(parseFloat(totalDistributedPrizes)) }}
                </p>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <small v-bind="attrs" v-on="on">
                      {{
                        moneyFormat(
                          parseFloat(
                            setPercentage(
                              totalDistributedPrizes,
                              totalGrossAmount
                            )
                          )
                        )
                      }}%</small
                    >
                  </template>
                  <span
                    >{{
                      moneyFormat(
                        parseFloat(
                          setPercentage(
                            totalDistributedPrizes,
                            totalGrossAmount
                          )
                        )
                      )
                    }}% of Total Gross</span
                  >
                </v-tooltip>
              </v-card>
            </v-col>

            <v-col md align="center" class="px-1">
              <v-card class="pa-1">
                <p class="font-weight-regular ma-0 overline">
                  TOTAL NET AMOUNT
                </p>
                <v-divider class="mb-4" />
                <p class="font-weight-medium d-inline-flex">
                  PHP {{ moneyFormat(parseFloat(totalNetAmount)) }}
                </p>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <small v-bind="attrs" v-on="on">
                      {{
                        moneyFormat(
                          parseFloat(
                            setPercentage(totalNetAmount, totalGrossAmount)
                          )
                        )
                      }}%</small
                    >
                  </template>
                  <span
                    >{{
                      moneyFormat(
                        parseFloat(
                          setPercentage(totalNetAmount, totalGrossAmount)
                        )
                      )
                    }}% of Total Gross</span
                  >
                </v-tooltip>
              </v-card>
            </v-col>

            <v-col md align="center" class="px-1">
              <v-card class="pa-1">
                <p class="font-weight-regular ma-0 overline">
                  TOTAL PAGCOR SHARE
                </p>
                <v-divider class="mb-4" />
                <p class="font-weight-medium d-inline-flex">
                  PHP {{ moneyFormat(parseFloat(totalPagcorShare)) }}
                </p>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <small v-bind="attrs" v-on="on">
                      {{
                        moneyFormat(
                          parseFloat(
                            setPercentage(totalPagcorShare, totalGrossAmount)
                          )
                        )
                      }}%</small
                    >
                  </template>
                  <span
                    >{{
                      moneyFormat(
                        parseFloat(
                          setPercentage(totalPagcorShare, totalGrossAmount)
                        )
                      )
                    }}% of Total Gross</span
                  >
                </v-tooltip>
              </v-card>
            </v-col>

            <v-col md align="center" class="px-1">
              <v-card class="pa-1">
                <p class="font-weight-regular ma-0 overline">
                  TOTAL NET INCOME
                </p>
                <v-divider class="mb-4" />
                <p class="font-weight-medium d-inline-flex">
                  PHP {{ moneyFormat(parseFloat(totalNetIncome)) }}
                </p>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <small v-bind="attrs" v-on="on">
                      {{
                        moneyFormat(
                          parseFloat(
                            setPercentage(totalNetIncome, totalGrossAmount)
                          )
                        )
                      }}%</small
                    >
                  </template>
                  <span
                    >{{
                      moneyFormat(
                        parseFloat(
                          setPercentage(totalNetIncome, totalGrossAmount)
                        )
                      )
                    }}% of Total Gross</span
                  >
                </v-tooltip>
              </v-card>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-card-title>
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              clearable
              outlined
              dense
            ></v-text-field>
          </v-card-title>
          <v-divider></v-divider>
          <div ref="datatable">
            <v-card-text class="pa-0">
              <v-data-table
                :headers="headers"
                :items="records"
                :items-per-page="5"
                :search="search"
                :loading="recordsLoading"
                loading-text="Loading, please wait a moment"
                mobile-breakpoint="0"
              >
                <template v-slot:[`item.game`]="{ item }">
                  <div>
                    <v-avatar size="36px" tile>
                      <v-img :src="item.team_a_logo">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="black"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                    <span class="text-caption"
                      >{{ item.team_a_name }} {{ item.team_a_alias }}</span
                    >
                    <span class="text-overline my-2 mx-2 font-italic">vs</span>
                    <v-avatar size="36px" tile>
                      <v-img :src="item.team_b_logo">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="black"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                    <span class="text-caption"
                      >{{ item.team_b_name }} {{ item.team_b_alias }}</span
                    >
                  </div>
                </template>
                <template v-slot:[`item.schedule`]="{ item }">
                  <span>{{ scheduleDate(item.schedule) }}</span>
                </template>
                <template v-slot:[`item.game_total_bet_amount`]="{ item }">
                  <span>{{
                    moneyFormat(parseFloat(item.game_total_bet_amount))
                  }}</span>
                </template>
                <template v-slot:[`item.game_total_prizes`]="{ item }">
                  <span>{{
                    moneyFormat(parseFloat(item.game_total_prizes))
                  }}</span>
                </template>
                <template v-slot:[`item.net_amount`]="{ item }">
                  <span>{{ moneyFormat(parseFloat(item.net_amount)) }}</span>
                </template>
                <template v-slot:[`item.pagcor_share`]="{ item }">
                  <span>{{ moneyFormat(parseFloat(item.pagcor_share)) }}</span>
                </template>
                <template v-slot:[`item.net_income`]="{ item }">
                  <span>{{ moneyFormat(parseFloat(item.net_income)) }}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";
import formatDatev2 from "@/helpers/formatDatev2";
import downloadexcel from "vue-json-excel";
import html2pdf from "html2pdf.js";
import GetPercentage from "@/helpers/getPercentage";

export default {
  components: { downloadexcel },
  data: () => ({
    valid: true,
    rules: formRules,
    search: "",
    schedule_date_start: null,
    schedule_date_start_menu: false,
    schedule_date_end: null,
    schedule_date_end_menu: false,
    league: null,
    team: null,
    headers: [
      {
        text: "League",
        sortable: false,
        value: "league",
        class: "text-uppercase",
      },
      { text: "Game", sortable: false, value: "game", class: "text-uppercase" },
      {
        text: "Game Status",
        sortable: false,
        value: "game_status",
        class: "text-uppercase",
      },
      { text: "Schedule Date", value: "schedule", class: "text-uppercase" },
      {
        text: "Gross Sales",
        value: "game_total_bet_amount",
        class: "text-uppercase",
      },
      { text: "Payouts", value: "game_total_prizes", class: "text-uppercase" },
      {
        text: "Gross Gaming Revenue",
        value: "net_amount",
        class: "text-uppercase",
      },
      { text: "PAGCOR Share", value: "pagcor_share", class: "text-uppercase" },
      { text: "Net Amount", value: "net_income", class: "text-uppercase" },
    ],
    game_status: "All",
    game_statuses: ["All", "Not Started", "Ongoing", "Finished"],
  }),
  created() {
    this.$store.commit("lucky_four_game_report/setRecords", []);

    const urlQuery = this.$route.query;

    if (Object.keys(urlQuery).length) {
      if (Object.prototype.hasOwnProperty.call(urlQuery, "date_from"))
        this.schedule_date_start = urlQuery.date_from;
      if (Object.prototype.hasOwnProperty.call(urlQuery, "date_to"))
        this.schedule_date_end = urlQuery.date_to;

      this.loadRecords();
    } else {
      if (!this.$store.state.leagues.length) {
        this.$store.dispatch("getEndingComponents");
      }
    }
  },
  computed: {
    ...mapState(["leagues", "teams"]),
    ...mapState("lucky_four_game_report", ["records", "recordsLoading"]),
    leagueTeams() {
      return this.teams.filter((team) => {
        return team.league_id == parseInt(this.league);
      });
    },
    totalGrossAmount() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.game_total_bet_amount) + total,
          0
        );
      } else {
        return 0;
      }
    },
    totalDistributedPrizes() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.game_total_prizes) + total,
          0
        );
      } else {
        return 0;
      }
    },
    totalNetAmount() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.net_amount) + total,
          0
        );
      } else {
        return 0;
      }
    },
    totalPagcorShare() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.pagcor_share) + total,
          0
        );
      } else {
        return 0;
      }
    },
    totalNetIncome() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.net_income) + total,
          0
        );
      } else {
        return 0;
      }
    },
    exportExcelData() {
      return this.records.map((record) => {
        return {
          id: record.game_id,
          league: record.league,
          game:
            record.team_a_name +
            " " +
            record.team_a_alias +
            " vs " +
            record.team_b_name +
            " " +
            record.team_b_alias,
          game_status: record.game_status,
          schedule: formatDatev2(record.schedule, "format", "yyyy-MM-dd"),
          game_total_bet_amount: parseFloat(record.game_total_bet_amount),
          game_total_prizes: parseFloat(record.game_total_prizes),
          net_amount: parseFloat(record.net_amount),
          pagcor_share: parseFloat(record.pagcor_share),
          net_income: parseFloat(record.net_income),
        };
      });
    },
  },
  methods: {
    ...mapActions("lucky_four_game_report", ["getRecords"]),
    lessThanDate(val) {
      if (val) {
        if (new Date(val) < new Date(this.schedule_date_start)) {
          return "Must be higher or equal than schedule date start.";
        }
      }

      return true;
    },
    leagueTeamName: (team) => team.team_name + " " + team.team_alias,
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    scheduleDate(sched) {
      return formatDatev2(sched);
    },
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        if (!this.recordsLoading) {
          await this.getRecords(this.setFormData());
          this.setQueryString();
        }
      }
    },
    async loadRecords() {
      if (!this.recordsLoading) {
        await this.getRecords(this.setFormData());
        this.setQueryString();
      }
    },
    setFormData() {
      const formData = new FormData();
      formData.append(
        "schedule_date_start",
        this.schedule_date_start === null ? "" : this.schedule_date_start
      );
      formData.append(
        "schedule_date_end",
        this.schedule_date_end === null ? "" : this.schedule_date_end
      );
      formData.append("league_id", this.league === null ? "" : this.league);
      formData.append("team_id", this.team === null ? "" : this.team);
      formData.append(
        "game_status",
        this.game_status === null ? "" : this.game_status
      );

      const getData = new URLSearchParams(formData);

      return getData;
    },
    setQueryString() {
      const urlQuery = this.$route.query;

      if (
        urlQuery.date_from != this.schedule_date_start ||
        urlQuery.date_to != this.schedule_date_end ||
        urlQuery.league != this.league ||
        urlQuery.team != this.team ||
        urlQuery.game_status != this.game_status
      ) {
        this.query_filters = {};

        if (this.schedule_date_start != null)
          this.query_filters["date_from"] = this.schedule_date_start;
        if (this.schedule_date_end != null)
          this.query_filters["date_to"] = this.schedule_date_end;
        if (this.league != null) this.query_filters["league"] = this.league;
        if (this.team != null) this.query_filters["team"] = this.team;
        if (this.game_status != null)
          this.query_filters["game_status"] = this.game_status;

        this.$router.push({
          name: this.$route.name,
          query: this.query_filters,
        });
      }
    },
    exportExcelDataWithTotal() {
      const dataTotal = {
        game_id: "",
        league: "",
        game: "",
        game_status: "",
        schedule: "TOTAL",
        game_total_bet_amount: parseFloat(this.totalGrossAmount),
        game_total_prizes: parseFloat(this.totalDistributedPrizes),
        net_amount: parseFloat(this.totalNetAmount),
        pagcor_share: parseFloat(this.totalPagcorShare),
        net_income: parseFloat(this.totalNetIncome),
      };

      return [...this.exportExcelData, dataTotal];
    },
    exportToPDF() {
      html2pdf(this.$refs.datatable, {
        filename: "Game Report " + formatDatev2(new Date()) + ".pdf",
        image: { type: "jpeg" },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: {
          unit: "in",
          format: "government-letter",
          orientation: "portrait",
        },
      });
    },
    downloadExcelName() {
      return "Game Report " + formatDatev2(new Date()) + ".xls";
    },
    setPercentage(val, dividend) {
      if (val != 0 && dividend != 0) {
        return GetPercentage(val, dividend);
      } else {
        return 0;
      }
    },
  },
};
</script>
