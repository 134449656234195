<template>
  <div style="height: 100%">
    <v-card class="shadow" style="height: 100%">
      <div class="d-flex justify-space-between align-center bs-py-3 bs-px-4">
        <h3>Monthly Revenue</h3>
        <v-btn
          text
          class="text-uppercase px-4"
          @click="filterDialog = true"
          :disabled="revenueYearStatisticsLoading"
        >
          {{ revenueYearStatistics.label }}
        </v-btn>
      </div>

      <v-divider></v-divider>

      <div class="d-flex align-center justify-center pa-6">
        <div v-if="revenueYearStatisticsLoading" class="text-center" style="height: 100%">
          <div class="d-flex align-center" style="gap: 1rem">
            <v-progress-circular
              :size="20"
              :width="3"
              color="secondary"
              indeterminate
            ></v-progress-circular>
            <span>Fetching chart information</span>
          </div>
        </div>
        <div style="width: 100%" v-else>
          <BarChart :theChartData="revenueYearStatistics" style="margin-top: -15px" />
        </div>
      </div>

      <div v-if="!revenueYearStatisticsLoading">
        <v-divider></v-divider>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th class="text-right grey--text">Average</th>
                <th class="text-right grey--text">Lowest</th>
                <th class="text-right grey--text">Highest</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span class="grey--text d-flex align-center"
                    ><i
                      class="fas fa-circle mr-4"
                      style="font-size: 6px; color: #ab47bc"
                    ></i>
                    Gross Sales</span
                  >
                </td>
                <td class="text-right">
                  <span>
                    {{
                      revenueYearStatistics
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(
                            parseFloat(revenueYearStatistics.avgGrossSales)
                          )}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      revenueYearStatistics && lowestGrossAmount
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(parseFloat(lowestGrossAmount))}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      revenueYearStatistics && highestGrossAmount
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(parseFloat(highestGrossAmount))}`
                        : "₱0"
                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="grey--text d-flex align-center">
                    <i
                      class="fas fa-circle fa-xs mr-4"
                      style="font-size: 6px; color: #ec407a"
                    ></i
                    >Payouts
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      revenueYearStatistics
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(
                            parseFloat(revenueYearStatistics.avgPayouts)
                          )}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      revenueYearStatistics && lowestPayout
                        ? "₱" + `${moneyFormatHundredthsPlace(parseFloat(lowestPayout))}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      revenueYearStatistics && highestPayout
                        ? "₱" + `${moneyFormatHundredthsPlace(parseFloat(highestPayout))}`
                        : "₱0"
                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="grey--text d-flex align-center">
                    <i
                      class="fas fa-circle fa-xs mr-4"
                      style="font-size: 6px; color: #ffa726"
                    ></i
                    >GGR
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      revenueYearStatistics
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(
                            parseFloat(revenueYearStatistics.avgGGR)
                          )}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      revenueYearStatistics && lowestGrossGamingRevenue
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(
                            parseFloat(lowestGrossGamingRevenue)
                          )}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      revenueYearStatistics && highestGrossGamingRevenue
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(
                            parseFloat(highestGrossGamingRevenue)
                          )}`
                        : "₱0"
                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="grey--text d-flex align-center">
                    <i
                      class="fas fa-circle fa-xs mr-4"
                      style="font-size: 6px; color: #5c6bc0"
                    ></i
                    >PAGCOR Share
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      revenueYearStatistics
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(
                            parseFloat(revenueYearStatistics.avgPagcorShare)
                          )}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      revenueYearStatistics && lowestPagcorShare
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(parseFloat(lowestPagcorShare))}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      revenueYearStatistics && highestPagcorShare
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(parseFloat(highestPagcorShare))}`
                        : "₱0"
                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="grey--text d-flex align-center">
                    <i
                      class="fas fa-circle fa-xs mr-4"
                      style="font-size: 6px; color: #81c784"
                    ></i
                    >Net Amount
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      revenueYearStatistics
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(
                            parseFloat(revenueYearStatistics.avgNetAmount)
                          )}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      revenueYearStatistics && lowestNetAmount
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(parseFloat(lowestNetAmount))}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      revenueYearStatistics && highestNetAmount
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(parseFloat(highestNetAmount))}`
                        : "₱0"
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>

    <v-dialog ref="filterDialog" v-model="filterDialog" persistent width="290px">
      <v-date-picker
        v-model="year"
        :active-picker.sync="activePicker"
        full-width
        reactive
        no-title
        @click:year="saveFilter"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cancelFilter"> Cancel </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formatDatev2 from "@/helpers/formatDatev2";
import BarChart from "@/components/dashboard/yearrevenuestatistics/BarChart.vue";

export default {
  components: { BarChart },
  data: () => ({
    year: formatDatev2(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      "format",
      "yyyy"
    ),
    filterDialog: false,
    activePicker: null,
  }),
  props: {
    game_id: Number,
  },
  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("dashboard", ["revenueYearStatistics", "revenueYearStatisticsLoading"]),

    highestGrossAmount() {
      if (this.revenueYearStatistics.grossAmounts) {
        const arr = this.revenueYearStatistics.grossAmounts;
        return Math.max(...arr);
      }
      return 0;
    },

    highestPayout() {
      if (this.revenueYearStatistics.distributedPrizes) {
        const arr = this.revenueYearStatistics.distributedPrizes;
        return Math.max(...arr);
      }
      return 0;
    },

    highestGrossGamingRevenue() {
      if (this.revenueYearStatistics.netAmount) {
        const arr = this.revenueYearStatistics.netAmount;
        return Math.max(...arr);
      }
      return 0;
    },

    highestPagcorShare() {
      if (this.revenueYearStatistics.pagcorShare) {
        const arr = this.revenueYearStatistics.pagcorShare;
        return Math.max(...arr);
      }
      return 0;
    },

    highestNetAmount() {
      if (this.revenueYearStatistics.netIncome) {
        const arr = this.revenueYearStatistics.netIncome;
        return Math.max(...arr);
      }
      return 0;
    },

    lowestGrossAmount() {
      if (this.revenueYearStatistics.grossAmounts) {
        const arr = this.revenueYearStatistics.grossAmounts;
        const filtered_array = arr.filter((val) => val != 0);
        if (filtered_array.length != 0) return Math.min(...filtered_array);
      }
      return 0;
    },

    lowestPayout() {
      if (this.revenueYearStatistics.distributedPrizes) {
        const arr = this.revenueYearStatistics.distributedPrizes;
        const filtered_array = arr.filter((val) => val != 0);
        if (filtered_array.length != 0) return Math.min(...filtered_array);
      }
      return 0;
    },

    lowestGrossGamingRevenue() {
      if (this.revenueYearStatistics.netAmount) {
        const arr = this.revenueYearStatistics.netAmount;
        const filtered_array = arr.filter((val) => val != 0);
        if (filtered_array.length != 0) return Math.min(...filtered_array);
      }
      return 0;
    },

    lowestPagcorShare() {
      if (this.revenueYearStatistics.pagcorShare) {
        const arr = this.revenueYearStatistics.pagcorShare;
        const filtered_array = arr.filter((val) => val != 0);
        if (filtered_array.length != 0) return Math.min(...filtered_array);
      }
      return 0;
    },

    lowestNetAmount() {
      if (this.revenueYearStatistics.netIncome) {
        const arr = this.revenueYearStatistics.netIncome;
        const filtered_array = arr.filter((val) => val != 0);
        if (filtered_array.length != 0) return Math.min(...filtered_array);
      }
      return 0;
    },
  },
  mounted() {
    if (this.accountInfo.access.includes(21)) {
      this.doGetData();
    }
  },
  methods: {
    ...mapActions("dashboard", ["getRevenueYearStatistics"]),
    cancelFilter() {
      this.filterDialog = false;
    },
    saveFilter() {
      const expYear = this.year.split("-");

      this.year = expYear[0];

      this.$refs.filterDialog.save(this.year);

      this.doGetData();
    },
    async doGetData() {
      if (!this.revenueYearStatisticsLoading) {
        this.$store.commit("dashboard/setRevenueYearStatistics", []);

        const formData = new FormData();
        formData.append("type", "Months");
        formData.append("val", this.year);
        formData.append("game_id", this.game_id);

        const getData = new URLSearchParams(formData);

        await this.getRevenueYearStatistics(getData);
      }
    },
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    moneyFormatHundredthsPlace(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },

  watch: {
    filterDialog(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    game_id() {
      if (this.accountInfo.access.includes(21)) {
        this.doGetData();
      }
    },
  },
};
</script>
