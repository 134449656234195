var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":!_vm.is_regular_player_or_sales_representative ? 1300 : 1200,"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('div',{staticClass:"d-flex align-center justify-space-between px-6 py-4"},[_c('h3',[_vm._v(" "+_vm._s(_vm.master_agent_name + " — " + _vm.master_agent_sub_agent_title + " List")+" ")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.close_master_agent_dialog()}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-divider'),_c('div',{staticClass:"d-flex justify-end px-6 py-4"},[_c('div',{staticClass:"search-wrapper"},[_c('v-text-field',{ref:"search",attrs:{"placeholder":"Search","single-line":"","hide-details":"auto","disabled":_vm.loading,"clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-divider'),(!_vm.is_regular_player_or_sales_representative)?_c('v-data-table',{attrs:{"headers":_vm.table_headers,"items":_vm.master_agent_sub_agent,"items-per-page":_vm.items_per_page,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading, please wait a moment","sort-by":_vm.sort_by,"sort-desc":_vm.sort_desc},on:{"update:sortBy":function($event){_vm.sort_by=$event},"update:sort-by":function($event){_vm.sort_by=$event},"update:sortDesc":function($event){_vm.sort_desc=$event},"update:sort-desc":function($event){_vm.sort_desc=$event}},scopedSlots:_vm._u([{key:"item.sales_supervisor_count",fn:function(ref){
var item = ref.item;
return [(item.sales_supervisor_count != '0')?_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.get_sales_managers_sales_supervisor(item.id, item.name)}}},[_vm._v(" "+_vm._s("View (" + item.sales_supervisor_count + ")")+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.sales_representative_count",fn:function(ref){
var item = ref.item;
return [(item.sales_representative_count != '0')?_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.get_sales_managers_sales_representative(item.id, item.name)}}},[_vm._v(" "+_vm._s("View (" + item.sales_representative_count + ")")+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.regular_player_count",fn:function(ref){
var item = ref.item;
return [(item.regular_player_count != '0')?_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.get_sales_managers_regular_player(item.id, item.name)}}},[_vm._v(" "+_vm._s("View (" + item.regular_player_count + ")")+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.mobile_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("+63" + item.mobile_number)+" ")]}},{key:"item.commission",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.commission)+"% ")]}},{key:"item.sub_commission",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.sub_commission)+"%")])]}},{key:"item.wallet",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("PHP " + _vm.money_format(parseFloat(item.wallet)))+" ")]}}],null,true)}):_c('v-data-table',{attrs:{"headers":_vm.regular_player_table_headers,"items":_vm.master_agent_sub_agent,"items-per-page":_vm.items_per_page,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading, please wait a moment","sort-by":_vm.sort_by,"sort-desc":_vm.sort_desc},on:{"update:sortBy":function($event){_vm.sort_by=$event},"update:sort-by":function($event){_vm.sort_by=$event},"update:sortDesc":function($event){_vm.sort_desc=$event},"update:sort-desc":function($event){_vm.sort_desc=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.mobile_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("+63" + item.mobile_number)+" ")]}},{key:"item.betting",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.betting == 'Enabled'
              ? 'green lighten-5 green--text'
              : 'red lighten-5 red--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.betting)+" ")])]}},{key:"item.cashin",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{class:item.cashin == 'Enabled'
              ? 'green lighten-5 green--text'
              : 'red lighten-5 red--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.cashin)+" ")])]}},{key:"item.cashout",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{class:item.cashout == 'Enabled'
              ? 'green lighten-5 green--text'
              : 'red lighten-5 red--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.cashout)+" ")])]}},{key:"item.is_active",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{class:item.is_active == 'Active'
              ? 'green lighten-5 green--text'
              : 'red lighten-5 red--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.is_active)+" ")])]}}],null,true)})],1),(_vm.sales_manager_dialog)?_c('SalesManagerSubAgents',{attrs:{"sales_manager_sub_agent_title":_vm.sales_manager_sub_agent_title,"sales_manager_id":_vm.sales_manager_id,"sales_manager_name":_vm.sales_manager_name},on:{"close_sales_manager_dialog":_vm.close_sales_manager_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }