<template>
  <v-dialog persistent v-model="dialog" fullscreen>
    <v-card class="shadow">
      <div style="display: flex; justify-content: flex-end; width: 21cm; margin: 0 auto; padding-top: 1.5rem;">
        <v-btn outlined depressed color="primary" class="px-2 mr-1" @click="close_dialog">Cancel</v-btn>

        <v-btn depressed color="primary" class="px-2" @click="print">Print <v-icon
            class="ml-1">mdi-printer-outline</v-icon></v-btn>
      </div>

      <div class="col" id="print_payouts">
        <div class="page" size="A4" style="page-break-after: always;">
          <div style="text-align: center; margin-bottom: 1rem;">
            <h3 style="font-weight: bold; margin-bottom: 0; line-height: 0.7">Payouts Settlement</h3>
          </div>

          <div style="display: flex; align-items: flex-end; justify-content: space-between; padding: .5rem;">
            <div style="display: inline-flex; line-height: 0.7; gap: .75rem">
              <div style="font-weight: bold;">Transaction Code:</div>
              <div>{{ payout_print_data.settlement_code }}</div>
            </div>
            <div style="display: inline-flex; line-height: 0.7; gap: .75rem">
              <div style="font-weight: bold;">Date Processed:</div>
              <div>{{ today }}</div>
            </div>
          </div>

          <div
            style="display: flex; align-items: flex-end; justify-content: space-between; padding: .5rem; margin-bottom: 8px;">
            <div style="display: inline-flex; line-height: 0.7; gap: .75rem">
              <div style="font-weight: bold;">Total Payouts:</div>
              <div>{{ `Php ${money_format(parseFloat(payout_print_data.total_payouts))}` }}</div>
            </div>
            <div style="display: inline-flex; line-height: 0.7; gap: .75rem">
              <div style="font-weight: bold;">Covered Dates:</div>
              <div>{{ `${payout_print_data.min_date} - ${payout_print_data.max_date}` }}</div>
            </div>
          </div>

          <table style="width: 100%;">
            <thead>
              <tr>
                <th>Name</th>
                <th>Payout</th>
                <th>Date Won</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in payout_print_data.selected" :key="index">
                <td>{{ item.name }}</td>
                <td style="text-align: right">{{ `Php ${money_format(parseFloat(item.payout))}` }}</td>
                <td>{{ format_date(item.created_at) }}</td>
              </tr>
            </tbody>
          </table>

          <div
            style="display: flex; align-items: flex-end; justify-content: space-between; padding: 0 1.5rem 1.5rem 1.5rem;">
            <div style="padding: 0 .5rem 0 .5rem; display: inline-block; border-bottom: 1px solid #ddd;">
              <span>Prepared by</span><br>

              <p style="margin-top: 18px; margin-bottom: 0;">{{ userData.name }}</p>
            </div>
            <div style="padding: 0 .5rem 1.5rem .5rem; display: inline-block; border-bottom: 1px solid #ddd;">
              Approved by
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import "@/assets/bootstrap.css";
import { mapState } from "vuex";
import formatDatev2 from '@/helpers/formatDatev2'
import { format } from 'date-fns'

export default {
  props: {
    payout_print_data: {
      type: Object
    }
  },

  data: () => ({
    today: format(new Date(), 'MMM dd, yyyy hh:mm a'),
  }),

  computed: {
    ...mapState(["userData"]),

    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    },

    min_date() {
      if (Object.keys(this.payout_print_data).length) {
        return this.payout_print_data[Object.keys(this.payout_print_data)[0]]
      }

      return "---"
    },

    max_date() {
      if (Object.keys(this.payout_print_data).length) {
        return new Date(Math.max.apply(null, this.payout_print_data.created_at))
      }

      return "---"
    }
  },

  methods: {
    print() {
      this.$htmlToPaper('print_payouts')
    },

    format_date(date) {
      return formatDatev2(date)
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    close_dialog() {
      this.$emit("set_close_payout_print_dialog")
    }
  }
}
</script>

<style scoped>
table,
tr,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 2px
}

div.page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5 cm;
}

div.page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
}

@media print {
  div.page {
    margin: 0;
    box-shadow: 0;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>