const url = process.env.VUE_APP_API_URL + "dashboard";

export default {
  namespaced: true,

  state: {
    playersLoading: false,

    registeredPlayerLoading: false,
    registeredPlayer: 0,
    newlyRegisteredPlayerLoading: false,
    newlyRegisteredPlayer: 0,
    activePlayerLoading: false,
    activePlayer: 0,

    // cashin statistics
    cashinDaily: [],
    cashinDailyLoading: false,
    cashinMonthly: [],
    cashinMonthlyLoading: false,

    // players statistics
    playersStatistics: [],
    playersStatisticsLoading: false,

    playersStatisticsDaily: [],
    playersStatisticsDailyLoading: false,

    // players new statistics
    playersNewStatistics: [],
    playersNewStatisticsLoading: false,

    // active player statistics
    activePlayerHour: [],
    activePlayerHourLoading: false,
    activePlayerDaily: [],
    activePlayerDailyLoading: false,

    activePlayerMonthly: [],
    activePlayerMonthlyLoading: false,

    // totalsData: [],
    totalsLoading: false,

    // Group(Total)
    totalBetAmount: null,
    totalBetAmountLoading: false,
    totalGames: null,
    totalGamesLoading: false,
    totalBets: null,
    totalBetsLoading: false,
    totalBetsPerType: null,

    // Group
    revenue: [],
    revenueLoading: false,

    // Group
    revenueStatistics: [],
    revenueStatisticsLoading: false,

    // Group
    revenueYearStatistics: [],
    revenueYearStatisticsLoading: false,

    // Group
    topBetsCombination: [],
    topBetsCombinationLoading: false,

    // Group
    topGrossCombination: [],
    topGrossCombinationLoading: false,

    // Group
    topGameGross: [],
    topGameGrossLoading: false,

    dashboardBets: [],
    dashboardBetsLoading: false,

    betsDailyStatistics: [],
    betsDailyStatisticsLoading: false,
    betsMonthlyStatistics: [],
    betsMonthlyStatisticsLoading: false,
  },
  mutations: {
    /* setTotalsData (state, val) {
		  state.totalsData = val
		}, */
    setPlayersLoading(state, val) {
      state.playersLoading = val;
    },

    setRegisteredPlayerLoading(state, val) {
      state.registeredPlayerLoading = val;
    },

    setRegisteredPlayer(state, val) {
      state.registeredPlayer = val;
    },

    setNewlyRegisteredPlayerLoading(state, val) {
      state.newlyRegisteredPlayerLoading = val;
    },

    setNewlyRegisteredPlayer(state, val) {
      state.newlyRegisteredPlayer = val;
    },

    setActivePlayerLoading(state, val) {
      state.activePlayerLoading = val;
    },

    setActivePlayerHour(state, val) {
      state.activePlayerHour = val;
    },

    setActivePlayerHourLoading(state, val) {
      state.activePlayerHourLoading = val;
    },

    setActivePlayerDaily(state, val) {
      state.activePlayerDaily = val;
    },

    setActivePlayerDailyLoading(state, val) {
      state.activePlayerDailyLoading = val;
    },

    setActivePlayerMonthly(state, val) {
      state.activePlayerMonthly = val;
    },

    setActivePlayerMonthlyLoading(state, val) {
      state.activePlayerMonthlyLoading = val;
    },

    setActivePlayer(state, val) {
      state.activePlayer = val;
    },

    setTotalsLoading(state, val) {
      state.totalsLoading = val;
    },

    // Group(Total)
    setTotalBetAmount(state, val) {
      state.totalBetAmount = val;
    },
    setTotalBetAmountLoading(state, val) {
      state.totalBetAmountLoading = val;
    },
    setTotalGames(state, val) {
      state.totalGames = val;
    },
    setTotalGamesLoading(state, val) {
      state.totalGamesLoading = val;
    },
    setTotalBets(state, val) {
      state.totalBets = val;
    },
    setTotalBetsLoading(state, val) {
      state.totalBetsLoading = val;
    },
    setTotalBetsPerType(state, val) {
      state.totalBetsPerType = val;
    },

    // Group
    setRevenue(state, val) {
      state.revenue = val;
    },
    setRevenueLoading(state, val) {
      state.revenueLoading = val;
    },

    // Group
    setRevenueStatistics(state, val) {
      state.revenueStatistics = val;
    },
    setRevenueStatisticsLoading(state, val) {
      state.revenueStatisticsLoading = val;
    },

    // Group
    setRevenueYearStatistics(state, val) {
      state.revenueYearStatistics = val;
    },
    setRevenueYearStatisticsLoading(state, val) {
      state.revenueYearStatisticsLoading = val;
    },

    // Group
    setTopBetsCombination(state, val) {
      state.topBetsCombination = val;
    },
    setTopBetsCombinationLoading(state, val) {
      state.topBetsCombinationLoading = val;
    },

    // Group
    setTopGrossCombination(state, val) {
      state.topGrossCombination = val;
    },
    setTopGrossCombinationLoading(state, val) {
      state.topGrossCombinationLoading = val;
    },

    // Group
    setTopGameGross(state, val) {
      state.topGameGross = val;
    },
    setTopGameGrossLoading(state, val) {
      state.topGameGrossLoading = val;
    },

    // Players Statistics
    setPlayersStatistics(state, val) {
      state.playersStatistics = val;
    },

    setPlayersStatisticsLoading(state, val) {
      state.playersStatisticsLoading = val;
    },

    setPlayersStatisticsDaily(state, val) {
      state.playersStatisticsDaily = val;
    },

    setPlayersStatisticsDailyLoading(state, val) {
      state.playersStatisticsDailyLoading = val;
    },

    // Players New Statistics
    setPlayersNewStatistics(state, val) {
      state.playersNewStatistics = val;
    },

    setPlayersNewStatisticsLoading(state, val) {
      state.playersNewStatisticsLoading = val;
    },

    // Cashin Statistics
    setCashinDaily(state, val) {
      state.cashinDaily = val;
    },

    setCashinDailyLoading(state, val) {
      state.cashinDailyLoading = val;
    },

    setCashinMonthly(state, val) {
      state.cashinMonthly = val;
    },

    setCashinMonthlyLoading(state, val) {
      state.cashinMonthlyLoading = val;
    },

    setDashboardBets(state, val) {
      state.dashboardBets = val;
    },

    setDashboardBetsLoading(state, val) {
      state.dashboardBetsLoading = val;
    },

    setBetsDailyStatistics(state, val) {
      state.betsDailyStatistics = val;
    },

    setBetsDailyStatisticsLoading(state, val) {
      state.betsDailyStatisticsLoading = val;
    },

    setBetsMonthlyStatistics(state, val) {
      state.betsMonthlyStatistics = val;
    },

    setBetsMonthlyStatisticsLoading(state, val) {
      state.betsMonthlyStatisticsLoading = val;
    },
  },

  actions: {
    async getTotals({ commit, rootState }, data) {
      commit("setTotalsLoading", true);

      try {
        const response = await fetch(`${url}/totals?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setTotalBetAmount", res.data.total_bet_amount);
          commit("setTotalGames", res.data.total_games);
          commit("setTotalBets", res.data.total_bets);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setTotalsLoading", false);
      }
    },

    async getPlayers({ commit, rootState }, data) {
      commit("setPlayersLoading", true);

      try {
        const response = await fetch(`${url}/players?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit(
            "setRegisteredPlayer",
            res.registeredPlayer[0].registered_player
          );
          commit(
            "setNewlyRegisteredPlayer",
            res.newlyRegisteredPlayer[0].newly_registered_player
          );
          commit("setActivePlayer", res.activePlayer[0].active_player);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setPlayersLoading", false);
      }
    },

    async getRegisteredPlayer({ commit, rootState }, data) {
      commit("setRegisteredPlayerLoading", true);

      try {
        const response = await fetch(`${url}/registered_players?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setRegisteredPlayer", res.records[0].registered_player);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setRegisteredPlayerLoading", false);
      }
    },

    async getNewlyRegisteredPlayer({ commit, rootState }, data) {
      commit("setNewlyRegisteredPlayerLoading", true);

      try {
        const response = await fetch(
          `${url}/newly_registered_players?${data}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${rootState.userToken}`,
            },
          }
        );

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit(
            "setNewlyRegisteredPlayer",
            res.records[0].newly_registered_player
          );
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setNewlyRegisteredPlayerLoading", false);
      }
    },

    async getActivePlayer({ commit, rootState }, data) {
      commit("setActivePlayerLoading", true);

      try {
        const response = await fetch(`${url}/active_player?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setActivePlayer", res.records[0].active_player);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setActivePlayerLoading", false);
      }
    },

    // Group(Total)
    async getTotalBetAmount({ commit, rootState }, data) {
      commit("setTotalBetAmountLoading", true);

      try {
        const response = await fetch(`${url}/total_bet_amount?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setTotalBetAmount", res.data);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setTotalBetAmountLoading", false);
      }
    },

    async getTotalGames({ commit, rootState }, data) {
      commit("setTotalGamesLoading", true);

      try {
        const response = await fetch(`${url}/total_games?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setTotalGames", res.data);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setTotalGamesLoading", false);
      }
    },

    async getTotalBets({ commit, rootState }, data) {
      commit("setTotalBetsLoading", true);

      try {
        const response = await fetch(`${url}/total_bets?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setTotalBets", res.data);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setTotalBetsLoading", false);
      }
    },

    async getRevenue({ commit, rootState }, data) {
      commit("setRevenueLoading", true);

      try {
        const response = await fetch(`${url}/revenue?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setRevenue", res.data);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setRevenueLoading", false);
      }
    },

    async getRevenueStatistics({ commit, rootState }, data) {
      commit("setRevenueStatisticsLoading", true);

      try {
        const response = await fetch(`${url}/revenue_statistics?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setRevenueStatistics", res);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setRevenueStatisticsLoading", false);
      }
    },

    async getRevenueYearStatistics({ commit, rootState }, data) {
      commit("setRevenueYearStatisticsLoading", true);

      try {
        const response = await fetch(`${url}/revenue_statistics?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setRevenueYearStatistics", res);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setRevenueYearStatisticsLoading", false);
      }
    },

    async getTopBetsCombination({ commit, rootState }, data) {
      commit("setTopBetsCombinationLoading", true);

      try {
        const response = await fetch(`${url}/top_bets_combination?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setTopBetsCombination", res.data);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setTopBetsCombinationLoading", false);
      }
    },

    async getTopGrossCombination({ commit, rootState }, data) {
      commit("setTopGrossCombinationLoading", true);

      try {
        const response = await fetch(`${url}/top_gross_combination?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setTopGrossCombination", res.data);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setTopGrossCombinationLoading", false);
      }
    },

    async getTopGameGross({ commit, rootState }, data) {
      commit("setTopGameGrossLoading", true);

      try {
        const response = await fetch(`${url}/top_game_gross?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setTopGameGross", res.data);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setTopGameGrossLoading", false);
      }
    },

    async getPlayersStatistics({ commit, rootState }, data) {
      commit("setPlayersStatisticsLoading", true);

      try {
        const response = await fetch(`${url}/players_statistics?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setPlayersStatistics", res);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setPlayersStatisticsLoading", false);
      }
    },

    async getPlayersStatisticsDaily({ commit, rootState }, data) {
      commit("setPlayersStatisticsDailyLoading", true);

      try {
        const response = await fetch(`${url}/players_statistics?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setPlayersStatisticsDaily", res);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setPlayersStatisticsDailyLoading", false);
      }
    },

    async getPlayersNewStatistics({ commit, rootState }, data) {
      commit("setPlayersNewStatisticsLoading", true);

      try {
        const response = await fetch(`${url}/players_new_statistics?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setPlayersNewStatistics", res.records);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setPlayersNewStatisticsLoading", false);
      }
    },

    async getCashinDaily({ commit, rootState }, data) {
      commit("setCashinDailyLoading", true);

      try {
        const response = await fetch(`${url}/cashin?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setCashinDaily", res);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setCashinDailyLoading", false);
      }
    },

    async getCashinMonthly({ commit, rootState }, data) {
      commit("setCashinMonthlyLoading", true);

      try {
        const response = await fetch(`${url}/cashin?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setCashinMonthly", res);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setCashinMonthlyLoading", false);
      }
    },

    async getActivePlayerHourStatistics({ commit, rootState }, data) {
      commit("setActivePlayerHourLoading", true);

      try {
        const response = await fetch(`${url}/active_player_per_hour?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setActivePlayerHour", res);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setActivePlayerHourLoading", false);
      }
    },

    async getActivePlayerDailyStatistics({ commit, rootState }, data) {
      commit("setActivePlayerDailyLoading", true);

      try {
        const response = await fetch(
          `${url}/active_players_statistics?${data}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${rootState.userToken}`,
            },
          }
        );

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setActivePlayerDaily", res);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setActivePlayerDailyLoading", false);
      }
    },

    async getActivePlayerMonthylStatistics({ commit, rootState }, data) {
      commit("setActivePlayerMonthlyLoading", true);

      try {
        const response = await fetch(
          `${url}/active_players_statistics?${data}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${rootState.userToken}`,
            },
          }
        );

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setActivePlayerMonthly", res);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setActivePlayerMonthlyLoading", false);
      }
    },

    async getDashboardBets({ commit, rootState }, data) {
      commit("setDashboardBetsLoading", true);

      try {
        const response = await fetch(`${url}/dashboard_bets?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setDashboardBets", res);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setDashboardBetsLoading", false);
      }
    },

    async getBetsDailyStatistics({ commit, rootState }, data) {
      commit("setBetsDailyStatisticsLoading", true);

      try {
        const response = await fetch(`${url}/bets_statistics?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setBetsDailyStatistics", res);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setBetsDailyStatisticsLoading", false);
      }
    },

    async getBetsMonthylStatistics({ commit, rootState }, data) {
      commit("setBetsMonthlyStatisticsLoading", true);

      try {
        const response = await fetch(`${url}/bets_statistics?${data}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.userToken}`,
          },
        });

        const res = await response.json();

        if (!response.ok) {
          throw { response, res };
        } else {
          commit("setBetsMonthlyStatistics", res);
        }
      } catch (error) {
        const errorStatus = error.response.status;

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true });
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true });
        }
      } finally {
        commit("setBetsMonthlyStatisticsLoading", false);
      }
    },
  },
};
