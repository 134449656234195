<template>
  <div class="d-inline-block">
    <v-switch
      class="my-0 py-0 d-inline-block"
      dense
      v-model="switchStatus"
      hide-details="true"
      @click.native.prevent.stop.capture="changeConfirm"
    ></v-switch>

    <v-dialog v-model="dialog" :max-width="switchStatus ? 500 : 300">
      <v-card class="shadow">
        <div class="pa-4 pa-md-6">
          <h3 class="font-weight-bold mb-3">Confirmation</h3>
          <p class="grey--text mb-0">
            Do you really want to {{ switchStatus ? "disable" : "enable" }} account?
          </p>
          <v-form @submit.prevent="handleSubmit" ref="form">
            <v-text-field
              :autofocus="!switchStatus"
              v-if="switchStatus"
              v-model="reason"
              dense
              outlined
              label="Reason"
              placeholder="Enter your reason"
              counter="50"
              :rules="[rules.required, rules.maximum(50)]"
              class="mt-3"
            ></v-text-field>
            <div class="d-flex justify-end gap-sm mt-4">
              <v-btn :disabled="accountLoading" text @click="dialog = false">
                Cancel
              </v-btn>
              <v-btn color="primary" depressed type="submit" :loading="accountLoading">
                Yes
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import form_rules from "@/helpers/formRules.js";

export default {
  props: {
    compData: {
      // Component Data
      type: Object,
      required: true,
    },
  },
  data: () => ({
    rules: form_rules,
    reason: "",
    switchStatus: false,
    dialog: false,
  }),
  mounted() {
    if (this.compData && this.compData.active_status == "Active") {
      this.switchStatus = true;
    } else {
      this.switchStatus = false;
    }
  },
  computed: {
    ...mapState("player_details", ["accountError", "accountLoading"]),
  },
  methods: {
    ...mapActions("player_details", ["accountSetting"]),
    changeConfirm() {
      this.dialog = true;
    },
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        if (!this.accountLoading) {
          const formData = new FormData();
          formData.append("player_id", this.compData.id);
          formData.append("reason", this.reason);
          formData.append("account", !this.switchStatus);

          await this.accountSetting(formData);

          if (!this.accountError) {
            this.dialog = false;

            this.switchStatus = !this.switchStatus;

            // no need to emit the new status
          }
        }
      }
    },
  },

  watch: {
    dialog(value) {
      if (!value) {
        this.$refs.form.reset();
      }
    },
  },
};
</script>
