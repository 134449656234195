<template>
  <v-dialog v-model="dialog" max-width="1000">
    <v-card class="shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-regular">
        <span>{{ `Special Commission Items — Php ${money_format(parseFloat(total_commission))}` }}</span>

        <v-btn icon @click="close_dialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pb-0">
        <v-data-table :headers="headers" :items="records" :loading="records_loading" :items-per-page="5"
          loading-text="Loading... Please wait">

          <template v-slot:[`item.amount`]="{ item }">
            {{ number_format(parseInt(item.amount)) }}
          </template>

          <template v-slot:[`item.commission_percent`]="{ item }">
            {{ `${money_format(parseFloat(item.commission_percent))}%` }}
          </template>

          <template v-slot:[`item.commission_amount`]="{ item }">
            {{ `Php ${money_format(parseFloat(item.commission_amount))}` }}
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            {{ format_date(item.created_at) }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :class="item.status == 'Pending' ? 'blue-grey lighten-5 blue-grey--text' : item.status == 'Requested' ? 'green lighten-5 green--text' : 'blue lighten-5 blue--text'">
              {{ item.status }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import responseGet from '@/helpers/api_request_get'
import formatDate from '@/helpers/formatDate'
import { mapActions } from 'vuex'

export default {
  props: {
    data: {
      type: Object
    }
  },

  data: () => ({
    records: [],
    records_loading: false,
    headers: [
      {
        text: "Slot",
        value: "slot",
        class: "text-uppercase",
      },
      {
        text: "Bet Amount",
        value: "amount",
        align: "right",
        class: "text-uppercase",
      },
      {
        text: "Commission %",
        value: "commission_percent",
        align: "right",
        class: "text-uppercase",
      },
      {
        text: "Commission",
        value: "commission_amount",
        align: "right",
        class: "text-uppercase",
      },
      {
        text: "Status",
        value: "status",
        class: "text-uppercase",
      },
      {
        text: "Bet Date",
        value: "created_at",
        class: "text-uppercase",
      }
    ]
  }),

  computed: {
    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    },

    total_commission() {
      if (this.records)
        return this.records.reduce((acc, curr) => acc + parseFloat(curr.commission_amount), 0);

      return 0;
    },
  },

  mounted() {
    this.doGetSpecialCommissionRaw()
  },

  methods: {
    ...mapActions("logout", ["sendLogoutRequest"]),

    async doGetSpecialCommissionRaw() {
      this.records_loading = !this.records_loading

      const response = await responseGet(this.set_form_data(), 'investor/special_commission/raw')

      switch (response.status) {
        case 200:
          this.records = response.data.records
          break;
        case 401:
          await this.sendLogoutRequest();
          this.$router.push({ name: "Home" });
          break;
      }

      this.records_loading = !this.records_loading
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('special_settlement_id', this.data.id)

      return new URLSearchParams(form_data)
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    format_date(date) {
      return formatDate(date, 'datetime')
    },

    close_dialog() {
      this.$emit("set_close_special_commission_dialog")
    }
  }
}
</script>