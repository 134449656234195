<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Lucky Four Game</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Scheduled Games</h2>
    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <!-- <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  @click="loadRecords"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip> -->
          </v-col>
          <v-col cols="12" sm="6" md="4" xl="3" class="d-flex">
            <v-text-field
              v-model="search"
              ref="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="getRecordsLoading"
              @keydown.enter.prevent="searchRecords"
              clearable
              outlined
              dense
            >
            </v-text-field>
            <template>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    color="secondary"
                    v-on="on"
                    :disabled="getRecordsLoading || leaguesLoading"
                    @click="openFilterDialog"
                  >
                    <v-icon>mdi-filter-variant</v-icon>
                  </v-btn>
                </template>
                Filter
              </v-tooltip>
            </template>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="getRecords"
          :options.sync="options"
          :loading="getRecordsLoading"
          :server-items-length="getTotalRows"
          :sort-desc="sortDesc"
          loading-text="Loading... Please wait"
          hide-default-footer
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="showDetails(item.game_id)"
                  icon
                  small
                  color="primary"
                >
                  <v-icon size="large"> mdi-text-box-search-outline </v-icon>
                </v-btn>
              </template>
              <span>Details</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.game`]="{ item }">
            <div>
              <v-avatar size="36px" tile>
                <v-img :src="item.team_a_logo">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
              <span class="text-caption"
                >{{ item.team_a_name }} {{ item.team_a_alias }}</span
              >
              <span class="text-overline my-2 mx-2 font-italic">vs</span>
              <v-avatar size="36px" tile>
                <v-img :src="item.team_b_logo">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
              <span class="text-caption"
                >{{ item.team_b_name }} {{ item.team_b_alias }}</span
              >
            </div>
          </template>

          <template v-slot:[`item.betting`]="{ item }">
            <v-chip
              small
              class="font-weight-bold"
              :class="
                item.betting == 'Enabled'
                  ? 'green lighten-5 green--text'
                  : 'orange lighten-5 orange--text'
              "
            >
              {{ item.betting }}
            </v-chip>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              small
              class="font-weight-bold"
              :class="
                item.status
                  ? 'green lighten-5 green--text'
                  : 'orange lighten-5 orange--text'
              "
            >
              {{ item.status ? item.status : "Not yet started" }}
            </v-chip>
          </template>

          <template v-slot:[`item.schedule`]="{ item }">
            <span>{{ formatDate(item.schedule, true) }}</span>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ formatDate(item.created_at, true) }}</span>
          </template>
        </v-data-table>
        <div class="pa-4">
          <v-row class="d-flex flex-row-reverse">
            <v-col cols="4" lg="2">
              <v-select
                v-model="itemsPerPage"
                :items="rowNumbers"
                label="Row per page"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col>
              <v-chip color="primary" outlined pill>
                Total:
                {{ getTotalRows }}
              </v-chip>
            </v-col>
          </v-row>
          <Pagination
            :storeName="storeName"
            :isDisabled="isDisabled"
            @paginatePage="loadRecords"
          />
        </div>
      </v-card-text>
    </v-card>
    <FilterBetsDialog
      ref="filterBetsDialog"
      @closeFilterDialog="closeFilterDialog"
    >
      <template #selectFields>
        <v-select
          v-model="filterByLeague"
          :items="filterByLeagueStatus"
          item-text="league"
          item-value="league"
          label="Select League"
          dense
          outlined
          hide-details="auto"
          clearable
        />
      </template>
    </FilterBetsDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import formatDate from "@/helpers/formatDate";
import { format } from "date-fns";
import Pagination from "@/components/Pagination";
import FilterBetsDialog from "@/components/FilterBetsDialog";
import responseGet from "@/helpers/api_request_get.js";

export default {
  components: { FilterBetsDialog, Pagination },

  data: () => ({
    initialLoad: true,
    search: "",
    sortBy: "",
    sortDesc: true,
    itemsCountPage: 5,
    rowNumbers: [5, 10, 20, 50, 100, 500],
    filterByLeagueStatus: [],
    filterByLeague: null,
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      {
        text: "League",
        sortable: false,
        value: "league",
        class: "text-uppercase",
      },
      { text: "Game", sortable: false, value: "game", class: "text-uppercase" },
      { text: "Schedule", value: "schedule", class: "text-uppercase" },
      { text: "Betting", value: "betting", class: "text-uppercase" },
      {
        text: "Status",
        sortable: false,
        value: "status",
        class: "text-uppercase",
      },
      { text: "Created At", value: "created_at", class: "text-uppercase" },
    ],
    options: {},
    storeName: "lucky_four_games",
    currentFilterByLeague: null,
    currentSearchValue: "",
    currentDateFrom: format(new Date(), "yyyy-MM-dd"),
    currentDateTo: null,
    leaguesLoading: false,
  }),

  computed: {
    ...mapGetters("lucky_four_games", [
      "getRecords",
      "getRecordsLoading",
      "getTotalRows",
      "getCurrentPage",
    ]),

    itemsPerPage: {
      get() {
        return this.itemsCountPage;
      },

      set(val) {
        this.itemsCountPage = val;
      },
    },

    isDisabled() {
      return this.currentSearchValue !== this.search;
    },
  },

  created() {
    this.$store.commit("lucky_four_games/setRecords", []);
    this.setCurrentPage(1);
    if (!this.filterByLeagueStatus.length) {
      this.getAllLeagues();
    }
    this.checkQueryParams();
    this.loadRecords();
  },

  methods: {
    ...mapActions("lucky_four_games", ["fetchRecords"]),
    ...mapMutations("lucky_four_games", ["setCurrentPage"]),

    formatDate,

    checkQueryParams() {
      const urlQuery = this.$route.query;

      if (Object.keys(urlQuery).length) {
        if (Object.prototype.hasOwnProperty.call(urlQuery, "league")) {
          this.currentFilterByLeague = urlQuery.league;
          this.filterByLeague = urlQuery.league;
        }
        if (Object.prototype.hasOwnProperty.call(urlQuery, "date_from"))
          this.currentDateFrom = urlQuery.date_from;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "date_to"))
          this.currentDateTo = urlQuery.date_to;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "page"))
          this.setCurrentPage(parseInt(urlQuery.page));
        if (Object.prototype.hasOwnProperty.call(urlQuery, "items_per_page"))
          this.itemsPerPage = parseInt(urlQuery.items_per_page);
        if (Object.prototype.hasOwnProperty.call(urlQuery, "search")) {
          this.currentSearchValue = urlQuery.search;
          this.search = urlQuery.search;
        }
        if (Object.prototype.hasOwnProperty.call(urlQuery, "sort_by"))
          this.sortBy = urlQuery.sort_by;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "sort_desc"))
          this.sortDesc = JSON.parse(urlQuery.sort_desc);
      }
    },

    setQueryParams() {
      const urlQuery = this.$route.query;
      const queryFilters = {
        league: this.currentFilterByLeague,
        date_from: this.currentDateFrom,
        date_to: this.currentDateTo,
        page: this.getCurrentPage.toString(),
        items_per_page: this.itemsPerPage.toString(),
        search: this.currentSearchValue,
        sort_by: this.sortBy,
        sort_desc: this.sortDesc.toString(),
      };

      if (
        urlQuery.league != queryFilters.league ||
        urlQuery.date_from != queryFilters.date_from ||
        urlQuery.date_to != queryFilters.date_to ||
        urlQuery.page != queryFilters.page ||
        urlQuery.items_per_page != queryFilters.items_per_page ||
        urlQuery.search != queryFilters.search ||
        urlQuery.sort_by != queryFilters.sort_by ||
        urlQuery.sort_desc != queryFilters.sort_desc
      ) {
        this.$router.replace({ query: queryFilters });
      }
    },

    async getAllLeagues() {
      try {
        this.leaguesLoading = true;
        const response = await responseGet("", "/lucky_four/games/get_leagues");

        if (response.status == 200) {
          this.filterByLeagueStatus = response.data.records;
        }
      } catch (error) {
        alert("Something went wrong, please refresh the page!!");
      } finally {
        this.leaguesLoading = false;
      }
    },

    searchRecords() {
      this.currentSearchValue = this.search;
      this.setCurrentPage(1);
      this.loadRecords();
      setTimeout(() => {
        this.$refs.search.focus();
      }, 300);
    },

    loadRecords() {
      if (!this.getRecordsLoading) {
        const formData = new FormData();

        formData.append(
          "filterByLeague",
          this.currentFilterByLeague ? this.currentFilterByLeague : ""
        );
        formData.append(
          "dateFrom",
          this.currentDateFrom ? this.currentDateFrom : ""
        );
        formData.append("dateTo", this.currentDateTo ? this.currentDateTo : "");
        formData.append("search", this.currentSearchValue);
        formData.append("itemsPerPage", this.itemsPerPage);
        formData.append("page", this.getCurrentPage);
        formData.append("sortBy", this.sortBy ? this.sortBy : "");
        formData.append("sortDesc", this.sortDesc ? true : "");

        const getData = new URLSearchParams(formData);
        this.fetchRecords(getData);
        this.setQueryParams();
      }
    },

    openFilterDialog() {
      this.filterByLeague = this.currentFilterByLeague;
      this.$refs.filterBetsDialog.form.dateFrom = this.currentDateFrom;
      this.$refs.filterBetsDialog.form.dateTo = this.currentDateTo;
      this.$refs.filterBetsDialog.dialogModel = true;
    },

    closeFilterDialog(data) {
      this.$refs.filterBetsDialog.dialogModel = false;
      if (data.type) {
        this.currentDateFrom = data.dateFrom;
        this.currentDateTo = data.dateTo;
        this.currentFilterByLeague = this.filterByLeague;
        this.searchRecords();
      }
    },

    showDetails(game_id) {
      this.setQueryParams();
      this.$router.push({
        name: "LuckyFourGameDetails",
        params: { id: game_id },
      });
    },
  },

  watch: {
    search(val) {
      if (val === null) {
        this.search = "";
      }
    },

    options() {
      if (this.initialLoad) {
        this.initialLoad = false;
        return;
      }

      if (this.options.sortBy[0] != undefined) {
        this.sortBy = this.options.sortBy[0];
      }

      if (this.options.sortDesc[0] != undefined) {
        this.sortDesc = this.options.sortDesc[0];
      }

      this.loadRecords();
    },

    itemsPerPage(oldVal) {
      if (oldVal) {
        if (!this.getRecordsLoading) {
          this.options.itemsPerPage = this.itemsPerPage;
          this.searchRecords();
        }
      }
    },
  },
};
</script>