const url = process.env.VUE_APP_API_URL + "logout"

export default {
  namespaced: true,

  state: {
    isLoading: false
  },
  mutations: {
    setIsLoading (state, val) {
      state.isLoading = val
    }
  },

  actions: {
    async sendLogoutRequest({ commit, rootState }) {
      commit("setIsLoading", true)
       
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        })

        await response.json()
      } catch(error) {
        console.log(error)
      } finally {
        commit("setIsLoading", false)
      }

      // Reset all main states
      commit("resetStates", "", { root: true }) 

      // logout
      commit("setUserData", null, { root: true })
      commit("setUserToken", {}, { root: true })
      commit("setAccountInfo", {}, { root: true })
      localStorage.removeItem("userToken")
      localStorage.removeItem("accountInfo")
    }
  }
}