<template>
    <v-dialog :fullscreen="fullScreenStatus" v-model="dialog" scrollable max-width="1400px">
        <v-card>
            <v-card-title class="text-h6 pa-4 font-weight-regular grey--text text--darken-1 overline">
                <v-icon size="large" class="mr-2"> mdi-chart-timeline-variant</v-icon> KUBRADOR PROGRESSIVE

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon 
                            absolute 
                            right 
                            class="mr-8" 
                            v-bind="attrs"
                            v-on="on" 
                            @click="fullScreenStatus = !fullScreenStatus">
                            <v-icon size="large">
                                {{ `mdi-${(fullScreenStatus) ? 'dock-window' : 'window-maximize'}` }}
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ `${(fullScreenStatus) ? 'Normal View' : 'Maximize'}` }}</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon absolute right @click="closeModal">
                            <v-icon size="large"
                                v-bind="attrs"
                                v-on="on">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Close</span>
                </v-tooltip>
            </v-card-title>

            <v-divider/>

            <v-card-text class="pa-4">
                <v-row dense>
                    <v-col cols="12" md="8">
                        <v-row dense>
                            <v-col>
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <tbody class="table-borderless">
                                            <tr>
                                                <td style="width: 100px" class="grey--text">AGENT </td>
                                                <td>
                                                    <span inline class="mb-1 mt-1 overline primary--text"> {{ default_filters.agent }} </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="width: 100px" class="grey--text">KUBRADOR</td>
                                                <td>
                                                    <span inline class="mb-1 mt-1 overline primary--text"> {{ default_filters.player }} </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-divider class="mt-4"/>

                <v-row dense class="mt-4">
                    <v-col>
                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                            @submit.prevent="doSearch"
                        >
                            <v-row dense>
                                <v-col cols="12" md="4">
                                    <v-select
                                        v-model="filter"
                                        :items="filterOptions"
                                        :rules="[rules.required]"
                                        label="Filter By"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    />
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-menu
                                        v-model="date_from_menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto">

                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="date_from"
                                                :rules="[lessThanDate]"
                                                :label="`${filter} From (Optional)`"
                                                hide-details="auto"
                                                readonly
                                                outlined
                                                dense
                                                v-bind="attrs"
                                                v-on="on"
                                                @keyup="date_from_menu=true"
                                                @keydown="date_from_menu=false"
                                                clearable/>
                                        </template>

                                        <v-date-picker
                                        v-model="date_from"
                                        @input="date_from_menu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-menu
                                        v-model="date_to_menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="date_to"
                                            :rules="[lessThanDate2]"
                                            :label="`${filter} To (Optional)`"
                                            hide-details="auto"
                                            readonly
                                            outlined
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                            :disabled="dateToAvailability"
                                            @keyup="date_to_menu=true"
                                            @keydown="date_to_menu=false"
                                            clearable
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="date_to"
                                        @input="date_to_menu = false"
                                        :min="(date_from) ? date_from : ''"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                            <!-- <v-row dense class="mt-4">
                                <v-col cols="12" md="4">
                                    <v-autocomplete
                                    ref="agent"
                                    v-model="agent_id"
                                    :rules="[filterStatus]"
                                    :items="agents"
                                    item-text="agent"
                                    item-value="id"
                                    label="Agent"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :disabled="agentsLoading"
                                    :loading="agentsLoading"
                                    clearable
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col cols="12" md="4">
                                    <v-autocomplete
                                    ref="province"
                                    v-model="province_id"
                                    :rules="[filterStatus]"
                                    :items="provinces"
                                    item-text="province"
                                    item-value="id"
                                    label="Province"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :disabled="provincesLoading"
                                    :loading="provincesLoading"
                                    clearable
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col cols="12" md="4">
                                    <v-autocomplete
                                    ref="cityMun"
                                    v-model="city_mun_id"
                                    :rules="[filterStatus]"
                                    :items="(province_id) ? filteredCityMunicipalities : cityMuns"
                                    item-text="cityMuns"
                                    item-value="id"
                                    label="City/ Municipality"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :loading="cityMunsLoading"
                                    :disabled="cityMunsLoading"
                                    clearable
                                    ></v-autocomplete>
                                </v-col>
                            </v-row> -->

                            <v-row dense>
                                <v-col>
                                    <v-radio-group
                                        :rules="[rules.required]"
                                        hide-details="auto" 
                                        v-model="report_type" 
                                        row>
                                            <v-row dense>
                                                <v-col cols="auto" class="ma-1">
                                                    <v-radio
                                                    label="Daily"
                                                    value="Daily"/>
                                                </v-col>

                                                <v-col cols="auto" class="ma-1">
                                                    <v-radio
                                                    label="Weekly"
                                                    value="Weekly"/>
                                                </v-col>

                                                <v-col cols="auto" class="ma-1">
                                                    <v-radio
                                                    label="Monthly"
                                                    value="Monthly"/>
                                                </v-col>

                                                <v-col cols="auto" class="ma-1">
                                                    <v-radio
                                                    label="Yearly"
                                                    value="Yearly"/>
                                                </v-col>
                                            </v-row>
                                        </v-radio-group>
                                </v-col>
                            </v-row>

                            <v-row dense class="mt-4">
                                <v-col>
                                    <v-row dense>
                                        <v-col>
                                            <v-btn
                                                depressed
                                                type="submit"
                                                color="primary"
                                                :loading="loading">
                                                Search
                                            </v-btn>
                                        </v-col>

                                        <!-- <v-col cols="12" md="6">
                                            <downloadexcel
                                                :data="records" 
                                                :fields="{
                                                    'Date': 'bet_date',
                                                    'Total Bets': 'bets',
                                                    'Total Bets Amount': 'bets_amount',
                                                }"
                                                :name="`${filter} (Agent Kobrador Porgressive)`"
                                                type="csv"
                                                >
                                                    <v-btn block outlined color="primary" >
                                                        Export CSV
                                                    </v-btn>
                                            </downloadexcel>
                                        </v-col> -->
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
                
                <v-row dense justify="center" class="my-4">
                    <v-col cols="12" md align="center" class="px-1">
                        <v-card class="pa-1">
                            <p class="font-weight-regular ma-0 overline">TOTAL BETS</p>
                            <v-divider class="mb-4" width="200px"/>
                            <p class="font-weight-medium">{{ parseInt(totalBets).toLocaleString() }}</p>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md align="center" class="px-1">
                        <v-card class="pa-1">
                            <p class="font-weight-regular ma-0 overline">TOTAL BET AMOUNT</p>
                            <v-divider class="mb-4" width="200px"/>
                            <p class="font-weight-medium">PHP {{ parseInt(totalBetAmount).toLocaleString() }}</p>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- : {
                                            callback: () => {
                                                return `${details.team_a_name} vs ${details.team_b_name}`;
                                            },
                                        }, -->

                <v-row dense justify="end">
                    <v-col>
                        <downloadexcel
                            :data="records" 
                            :fields="{
                                'Date': 'bet_date',
                                'Agent': 'agent',
                                'Kobrador': 'kobrador',
                                'Total Bets': 'bets',
                                'Total Bets Amount': 'bets_amount',
                            }"
                            :name="`${filter} (Agent Kobrador Porgressive).xls`"
                            type="csv"
                            class="d-inline-block">
                                <v-btn outlined color="primary" >
                                    Export CSV
                                </v-btn>
                        </downloadexcel>
                    </v-col>

                    <v-col cols="12" md="4" align="end">
                        <v-text-field
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            placeholder="Search"
                            single-line
                            hide-details="auto"
                            :disabled="loading"
                            clearable
                            outlined
                            dense>

                            <!-- <template v-slot:append-outer>
                                <v-tooltip
                                top
                                >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        icon
                                        color="secondary"
                                        @click="showFilter"
                                        v-on="on"
                                        small
                                        >
                                        <v-icon :color="(filterVisibility) ? 'primary' : ''">{{ `mdi-filter-${(filterVisibility) ? 'check' : 'off'}` }}</v-icon>
                                    </v-btn>
                                </template>
                                    {{ `${(filterVisibility) ? 'Hide' : 'Show'} Filter` }}
                                </v-tooltip>
                            </template> -->
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col>
                        <v-data-table
                            :headers="headers"
                            :items="records"
                            :items-per-page="5"
                            :search="search"
                            :loading="loading"
                            loading-text="Loading, please wait a moment">

                            <template v-slot:[`item.final_schedule_date`]="{ item }">
                                <span>{{ scheduleDate(item.final_schedule_date) }}</span>
                            </template> 

                            <template v-slot:[`item.bets`]="{ item }">
                            {{ parseInt(item.bets).toLocaleString() }}
                            </template>

                            <template v-slot:[`item.bets_amount`]="{ item }">
                                PHP {{ moneyFormat(parseFloat(item.bets_amount)) }}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import formRules from '@/helpers/formRules'
import { mapActions, mapState } from 'vuex'
import downloadexcel from 'vue-json-excel'
import formatDatev2 from '@/helpers/formatDatev2'

export default {
    props: {
        default_filters: {
            type: Object
        }
    },

    components: {
        downloadexcel
    },

    data: () => ({
        search: '',
        valid: true,
        rules: formRules,
        province_id: '',
        city_mun_id: '',
        agent_id: '',
        player_id: '',
        agent: '',
        player: '',
        date_from: '',
        date_to: '',
        date_from_menu: false,
        date_to_menu: false,
        filterOptions: ["Bet Date", "Scheduled Date"],
        filter: "Bet Date",
        report_type: 'Daily',
        searched_report_type: '',
        fullScreenStatus: false,
        headers: [
            { 
                text: "DATE", 
                value: "final_schedule_date", 
                class: "text-uppercase",
                align: "left" 
            },
            {
                text: "TOTAL BETS",
                value: "bets",
                class: "text-uppercase",
                align: "right" 
            },
            {
                text: "TOTAL BETS AMOUNT",
                value: "bets_amount",
                class: "text-uppercase",
                align: "right" 
            }
        ],
    }),

    computed: {
        ...mapState("kobrador_progressive", ["records", "loading"]),

        dialog: {
            get: function () {
                return true
            },
            set: function () {
                this.closeModal()
            },
        },

        dateToAvailability() {
            if (this.date_from == '' || this.date_from == null) {
                return true
            }

            return false
        },

        filteredCityMunicipalities() {
            return this.cityMuns.filter((cityMun) => {
                return cityMun.province_id == this.province_id
            })
        },

        filterStatus() {
            if ((this.date_from == null && this.date_from == '') && (this.agent_id == 0 || this.angent_id == null) && (this.province_id == 0 || this.province_id == null) && (this.city_mun_id == 0 || this.city_mun_id == null)) {
                return 'Must select atleast one of this filter.'
            }
            
            return true
        },

        lessThanDate() { 
            if (this.date_from && this.date_to) {
                if (new Date(this.date_to) < new Date(this.date_from)) {
                    return 'Must be higher or equal than date from.'
                } 
            } 
            
            return true
        },

        lessThanDate2() { 
            if (this.date_from && this.date_to) {
                if (new Date(this.date_from) > new Date(this.date_to)) {
                    return 'Must be lessthan or equal than date to.'
                }
            } 
            
            return true
        },

        totalAgents() {
            return this.groupByKey(this.records, 'agent')
        },

        totalKobradors() {
            return this.groupByKey(this.records, 'kobrador')
        },

        totalBets() {
            if (this.records) {
                return this.records.reduce((total, obj) => parseFloat(obj.bets) + total,0)
            } else {
                return 0
            }
        },
        
        totalBetAmount() {
            if (this.records) {
                return this.records.reduce((total, obj) => parseFloat(obj.bets_amount) + total,0)
            } else {
                return 0
            }
        },
    },

    mounted() {
        if (Object.keys(this.default_filters).length) {
            this.filter = this.default_filters.filter_by,
            this.agent_id = this.default_filters.agent_id,
            this.player_id = this.default_filters.player_id,
            this.date_from = this.default_filters.date_from,
            this.date_to = this.default_filters.date_to

            this.doGetRecords()
        }
    },

    created() {
        this.$store.commit("kobrador_progressive/setRecords", [])
    },

    methods: {
        ...mapActions("kobrador_progressive", ["getRecordsByBets"]),

         async doSearch() {
            if (this.$refs.form.validate()) {
                if (!this.loading) {
                    this.searched_report_type = this.report_type
                    this.$store.commit("kobrador_progressive/setRecords", [])

                    await this.getRecordsByBets(this.setFormData())
                }
            }
        },

        async doGetRecords() {
            this.searched_report_type = this.report_type
            await this.getRecordsByBets(this.setFormData())
        },

        setFormData() {
            const formData = new FormData()

            formData.append("filter_by", this.filter)
            formData.append("report_type", this.report_type)
            formData.append("agent_id", this.agent_id)
            formData.append("player_id", this.player_id)
            formData.append("city_mun_id", this.city_mun_id)
            formData.append("province_id", this.province_id)
            formData.append("date_from", (this.date_from ? this.date_from : ''))
            formData.append("date_to", (this.date_to ? this.date_to : (this.date_from ? this.date_from : '')))

            const getData = new URLSearchParams(formData) 

            return getData
        },

        closeModal() {
            this.$emit("setKobradorProgressiveCloseModal")
        },

        scheduleDate(sched) {
            if (this.searched_report_type=='Daily') {
                return formatDatev2(sched, 'date')
            }

            if (this.searched_report_type=='Weekly') {
                return formatDatev2(sched, 'monthweek')
            }

            if (this.searched_report_type=='Monthly') {
                return formatDatev2(sched, 'monthyear')
            }

            if (this.searched_report_type=='Yearly') {
                return sched
            }
        },

        groupByKey(array, key) {
            return array
                .reduce((hash, obj) => {
                    if(obj[key] === undefined) 
                        return hash
                        return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
                    }, {}
                )
        },

        moneyFormat(val) {
            return val.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },
    },

    watch: {
        date_from (val) {
            if (val == '' || val == null) {
                this.date_to = ''
            }
        }
    }
}
</script>
