var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Ending Reports")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Cards")]),_c('v-card',{staticClass:"shadow mb-4"},[_c('v-card-title',{staticClass:"text-h6 py-2 font-weight-regular grey--text text--darken-1"},[_vm._v(" CARDS REPORT ")]),_c('v-divider'),_c('v-card-text',{staticClass:"py-4"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.doGetSummary.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.game_statuses,"label":"Game Status","dense":"","outlined":"","hide-details":"auto"},model:{value:(_vm.game_status),callback:function ($$v) {_vm.game_status=$$v},expression:"game_status"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.rules.required, _vm.lessThanDate],"label":"Scheduled Date From","hide-details":"auto","readonly":"","outlined":"","dense":"","clearable":""},on:{"keyup":function($event){_vm.date_from_menu = true},"keydown":function($event){_vm.date_from_menu = false}},model:{value:(_vm.date_from),callback:function ($$v) {_vm.date_from=$$v},expression:"date_from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_from_menu),callback:function ($$v) {_vm.date_from_menu=$$v},expression:"date_from_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.date_from_menu = false}},model:{value:(_vm.date_from),callback:function ($$v) {_vm.date_from=$$v},expression:"date_from"}})],1)],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.date_from == ''},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Scheduled Date To (Optional)","hide-details":"auto","readonly":"","outlined":"","dense":"","disabled":_vm.dateToAvailability,"clearable":""},on:{"keyup":function($event){_vm.date_to_menu = true},"keydown":function($event){_vm.date_to_menu = false}},model:{value:(_vm.date_to),callback:function ($$v) {_vm.date_to=$$v},expression:"date_to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_to_menu),callback:function ($$v) {_vm.date_to_menu=$$v},expression:"date_to_menu"}},[_c('v-date-picker',{attrs:{"min":_vm.date_from ? _vm.date_from : ''},on:{"input":function($event){_vm.date_to_menu = false}},model:{value:(_vm.date_to),callback:function ($$v) {_vm.date_to=$$v},expression:"date_to"}})],1)],1)],1),_c('v-row',{staticClass:"mt-2",attrs:{"dense":""}},[_c('v-col',[_c('v-btn',{attrs:{"depressed":"","type":"submit","color":"primary","loading":_vm.loading}},[_vm._v(" Search ")])],1)],1)],1)],1)],1),_c('v-card',{staticClass:"shadow"},[_c('v-card-title',{staticClass:"text-h6 py-2 font-weight-regular grey--text text--darken-1"},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_vm._v(" SUMMARY ")]),_c('v-col',{attrs:{"align":"end"}},[_c('v-row',{attrs:{"dense":"","justify":"end"}},[_c('downloadexcel',{staticClass:"d-inline-block",attrs:{"data":_vm.records,"fields":{
                Type: 'type',
                Bets: 'bets',
                Cards: 'cards',
                Bets: 'bets',
                'Gross Sales': 'bets_amount',
                Payout: 'payout',
                'Gross Gaming Revenue': 'net_amount',
                'PAGCOR Share': 'pagcor_share',
                'Net Amount': 'net_income',
              },"name":"Cards Report.xls","type":"csv"}},[_c('v-btn',{staticClass:"mr-2 my-1",attrs:{"outlined":"","color":"primary"}},[_vm._v(" Export CSV ")])],1)],1)],1)],1)],1),_c('v-card-text',{staticClass:"py-4"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"align":"center","cols":"12","md":"2"}},[_c('v-card',{staticClass:"pa-1"},[_c('p',{staticClass:"font-weight-regular ma-0 overline"},[_vm._v("TOTAL CARDS")]),_c('v-divider',{staticClass:"mb-4"}),_c('p',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(parseInt(_vm.cardsTotal).toLocaleString())+" ")])],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-4",attrs:{"align":"center","cols":"12","md":"2"}},[_c('v-card',{staticClass:"pa-1"},[_c('p',{staticClass:"font-weight-regular ma-0 overline"},[_vm._v("TOTAL BETS")]),_c('v-divider',{staticClass:"mb-4"}),_c('p',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(parseInt(_vm.betsTotal).toLocaleString())+" ")])],1)],1),_c('v-col',{staticClass:"py-4",attrs:{"align":"center","cols":"12","md":"2"}},[_c('v-card',{staticClass:"pa-1"},[_c('p',{staticClass:"font-weight-regular ma-0 overline"},[_vm._v("TOTAL GROSS AMOUNT")]),_c('v-divider',{staticClass:"mb-4"}),_c('p',{staticClass:"font-weight-medium"},[_vm._v(" PHP "+_vm._s(_vm.moneyFormat(parseFloat(_vm.grossAmountTotal)))+" ")])],1)],1),_c('v-col',{staticClass:"py-4",attrs:{"align":"center","cols":"12","md":"2"}},[_c('v-card',{staticClass:"pa-1"},[_c('p',{staticClass:"font-weight-regular ma-0 overline"},[_vm._v("TOTAL PAYOUT")]),_c('v-divider',{staticClass:"mb-4"}),_c('p',{staticClass:"font-weight-medium red--text d-inline-flex"},[_vm._v(" PHP "+_vm._s(_vm.moneyFormat(parseFloat(_vm.payoutTotal)))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('small',_vm._g(_vm._b({},'small',attrs,false),on),[_vm._v(" "+_vm._s(_vm.moneyFormat( parseFloat(_vm.setPercentage(_vm.payoutTotal, _vm.grossAmountTotal)) ))+"%")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.moneyFormat(parseFloat(_vm.setPercentage(_vm.payoutTotal, _vm.grossAmountTotal))))+"% of Total Gross")])])],1)],1),_c('v-col',{staticClass:"py-4",attrs:{"align":"center","cols":"12","md":"2"}},[_c('v-card',{staticClass:"pa-1"},[_c('p',{staticClass:"font-weight-regular ma-0 overline"},[_vm._v("TOTAL NET AMOUNT")]),_c('v-divider',{staticClass:"mb-4"}),_c('p',{staticClass:"font-weight-medium d-inline-flex"},[_vm._v(" PHP "+_vm._s(_vm.moneyFormat(parseFloat(_vm.netAmountTotal)))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('small',_vm._g(_vm._b({},'small',attrs,false),on),[_vm._v(" "+_vm._s(_vm.moneyFormat( parseFloat(_vm.setPercentage(_vm.netAmountTotal, _vm.grossAmountTotal)) ))+"%")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.moneyFormat( parseFloat(_vm.setPercentage(_vm.netAmountTotal, _vm.grossAmountTotal)) ))+"% of Total Gross")])])],1)],1),_c('v-col',{staticClass:"py-4",attrs:{"align":"center","cols":"12","md":"2"}},[_c('v-card',{staticClass:"pa-1"},[_c('p',{staticClass:"font-weight-regular ma-0 overline"},[_vm._v("TOTAL PAGCOR SHARE")]),_c('v-divider',{staticClass:"mb-4"}),_c('p',{staticClass:"font-weight-medium red--text d-inline-flex"},[_vm._v(" PHP "+_vm._s(_vm.moneyFormat(parseFloat(_vm.pagcorShareTotal)))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('small',_vm._g(_vm._b({},'small',attrs,false),on),[_vm._v(" "+_vm._s(_vm.moneyFormat( parseFloat(_vm.setPercentage(_vm.pagcorShareTotal, _vm.grossAmountTotal)) ))+"%")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.moneyFormat( parseFloat(_vm.setPercentage(_vm.pagcorShareTotal, _vm.grossAmountTotal)) ))+"% of Total Gross")])])],1)],1),_c('v-col',{staticClass:"py-4",attrs:{"align":"center","cols":"12","md":"2"}},[_c('v-card',{staticClass:"pa-1"},[_c('p',{staticClass:"font-weight-regular ma-0 overline"},[_vm._v("TOTAL NET INCOME")]),_c('v-divider',{staticClass:"mb-4"}),_c('p',{staticClass:"font-weight-medium d-inline-flex",class:_vm.netIncomeTotal >= 0 ? 'green--text' : 'red--text'},[_vm._v(" PHP "+_vm._s(_vm.moneyFormat(parseFloat(_vm.netIncomeTotal)))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('small',_vm._g(_vm._b({},'small',attrs,false),on),[_vm._v(" "+_vm._s(_vm.moneyFormat( parseFloat(_vm.setPercentage(_vm.netIncomeTotal, _vm.grossAmountTotal)) ))+"%")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.moneyFormat( parseFloat(_vm.setPercentage(_vm.netIncomeTotal, _vm.grossAmountTotal)) ))+"% of Total Gross")])])],1)],1)],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.records,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading, please wait a moment","hide-default-footer":true},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"dense":"","icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.setCardsDialog(item.type)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-basketball ")])],1)]}}],null,true)},[_c('span',[_vm._v("Show Per Game")])])]}},{key:"item.bets",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseInt(item.bets).toLocaleString())+" ")])]}},{key:"item.bets_amount",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v("PHP "+_vm._s(_vm.moneyFormat(parseFloat(item.bets_amount)))+" ")])]}},{key:"item.cards",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(parseInt(item.cards).toLocaleString())+" ")])]}},{key:"item.payout",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"red--text"},[_vm._v("PHP "+_vm._s(_vm.moneyFormat(parseFloat(item.payout)))+" ")])]}},{key:"item.net_amount",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v("PHP "+_vm._s(_vm.moneyFormat(parseFloat(item.net_amount)))+" ")])]}},{key:"item.pagcor_share",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"red--text"},[_vm._v("PHP "+_vm._s(_vm.moneyFormat(parseFloat(item.pagcor_share)))+" ")])]}},{key:"item.net_income",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:item.net_income >= 0 ? 'green--text' : 'red--text'},[_vm._v("PHP "+_vm._s(_vm.moneyFormat(parseFloat(item.net_income)))+" ")])]}}],null,true)})],1)],1),(_vm.cards_dialog)?_c('CardDialog',{attrs:{"cards_details":_vm.cards_details},on:{"setCardsDialogCloseModal":_vm.setCardsDialogCloseModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }