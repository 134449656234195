const url = process.env.VUE_APP_API_URL + "ending/reports/master_agents_token"

export default {
    namespaced: true,

    state: {
        records: [],
        loading: false,
        regions: [],
        regionsLoading: false,
        provinces: [],
        provincesLoading: false,
        cities: [],
        citiesLoading: false,
        agents: [],
        agentsLoading: false
    },

    mutations: {
        setRecords (state, val) {
            state.records = val
        },

        setRecordsLoading (state, val) {
            state.loading = val
        },

        setRegions(state, val) {
            state.regions = val
        },

        setRegionsLoading(state, val) {
            state.regionsLoading = val
        },

        setProvinces(state, val) {
            state.provinces = val
        },

        setProvincesLoading(state, val) {
            state.provincesLoading = val
        },

        setCities(state, val) {
            state.cities = val
        },

        setCitiesLoading(state, val) {
            state.citiesLoading = val
        },

        setAgents(state, val) {
            state.agents = val
        },

        setAgentsLoading(state, val) {
            state.agentsLoading = val
        }
    },

    actions: {
        async getRecords({ commit, rootState }, data) {
            commit("setRecordsLoading", true)

            try {
                const response = await fetch(`${url}?${data}` , {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                })

                const res = await response.json()

                console.log(res)
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setRecords", res.records)

                    return res
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setRecordsLoading", false)
            }
        },

        async getAreas({ commit, rootState }) {
            commit("setRegionsLoading", true)
            commit("setProvincesLoading", true)
            commit("setCitiesLoading", true)
            commit("setAgentsLoading", true)

            try {
                const response = await fetch(`${url}/get_areas` , {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                })

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setRegions", res.regions)
                    commit("setProvinces", res.provinces)
                    commit("setCities", res.cities)
                    commit("setAgents", res.agents)

                    return res
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setRegionsLoading", false)
                commit("setProvincesLoading", false)
                commit("setCitiesLoading", false)
                commit("setAgentsLoading", false)
            }
        },
    }
}
