<template>
  <v-dialog :fullscreen="!$vuetify.breakpoint.mdAndUp" scrollable v-model="dialog" max-width="900">
    <v-card class="shadow-none d-flex flex-column">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-regular">
        <span>Payouts Details</span>

        <v-btn icon @click="close_dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="d-flex flex-column">
        <v-data-table v-model="selected" :headers="headers" :items="records" :search="search" :items-per-page="-1"
          :hide-default-footer="true" :single-select="false" item-key="id" show-select v-if="!records_loading">
          <template v-slot:top>
            <v-text-field ref="search" v-model="search" label="Search" single-line outlined dense
              :disabled="records_loading" autofocus placeholder="Search" hide-details="auto" class="mb-2" />
          </template>

          <template v-slot:[`item.payout`]="{ item }">
            <span>{{ `Php ${money_format(parseFloat(item.payout))}` }}</span>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ format_date(item.created_at) }}</span>
          </template>
        </v-data-table>

        <div v-else>
          <v-skeleton-loader type="table"></v-skeleton-loader>
        </div>
      </v-card-text>

      <v-card-actions>
        <div class="d-flex"
          :class="$vuetify.breakpoint.mdAndUp ? 'justify-space-between align-end' : 'flex-column'"
          :style="$vuetify.breakpoint.mdAndUp ? 'gap: 0' : 'gap: .5rem'" style="width: 100%">
          <span>Selected
            <span class="success--text ">
              {{ number_format(parseInt(selected.length)) }}</span>{{ ` out of ${records.length} amounting of ` }}
            <span class="success--text ">
              {{ `Php ${money_format(parseFloat(total_payouts))}` }}</span>
          </span>

          <div class="d-flex justify-end" style="gap:.30rem">
            <v-btn text dense color="black" @click="close_dialog" class="px-1">
              Cancel
            </v-btn>

            <v-btn depressed dense color="primary" :disabled="records_loading || !selected.length"
              @click="set_payout_details_confirmation_dialog" class="px-2">
              Approve
            </v-btn>
          </div>
        </div>
      </v-card-actions>
    </v-card>

    <PayoutDetailsConfirmation v-if="payout_details_confirmation_dialog"
      :payout_details_confirmation_data="payout_details_confirmation_data" @set_close_payout_details_confirmation_dialog="set_close_payout_details_confirmation_dialog
        " />
  </v-dialog>
</template>

<script>
import formatDatev2 from "@/helpers/formatDatev2";
import responseGet from "@/helpers/api_request_get";
import PayoutDetailsConfirmation from "@/components/investors/PayoutDetailsConfirmation";

export default {
  components: { PayoutDetailsConfirmation },

  props: {
    payout_details_data: {
      type: Object,
    },
  },

  data: () => ({
    search: "",
    selected: [],
    compiled_ids: [],
    total_payouts: 0.0,
    approval_status: false,
    records: [],
    records_loading: false,
    payout_details_confirmation_dialog: false,
    payout_details_confirmation_data: [],
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        class: "text-uppercase",
      },
      {
        text: "Payout",
        align: "right",
        value: "payout",
        class: "text-uppercase",
      },
      {
        text: "Date Won",
        align: "created_at",
        value: "created_at",
        class: "text-uppercase",
      },
    ],
  }),

  computed: {
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.close_dialog();
      },
    },
  },

  mounted() {
    this.doGetPayoutsRaw();
  },

  methods: {
    async doGetPayoutsRaw() {
      this.records_loading = !this.records_loading;

      const response = await responseGet(
        this.set_form_data(),
        "investor/payouts/details"
      );

      if (response.status == 200) {
        this.records = response.data.records;
      }

      this.records_loading = !this.records_loading;
    },

    set_form_data() {
      const form_data = new FormData();

      form_data.append("investor_id", this.payout_details_data.investor_id);
      form_data.append("date_from", this.payout_details_data.date_from ?? "");
      form_data.append("date_to", this.payout_details_data.date_to ?? "");

      return new URLSearchParams(form_data);
    },

    set_payout_details_confirmation_dialog() {
      this.payout_details_confirmation_data = {
        'investor_id': this.payout_details_data.investor_id,
        'compiled_ids': this.compiled_ids,
        'selected': this.selected,
        'total_payouts': this.total_payouts,
      };

      this.payout_details_confirmation_dialog = !this.payout_details_confirmation_dialog;
    },

    set_close_payout_details_confirmation_dialog(status) {
      if (status) {
        this.approval_status = true;
        this.selected = [];
        this.compiled_ids = [];
        this.total_payouts = 0.0;
        this.doGetPayoutsRaw();
      }
      this.payout_details_confirmation_dialog = !this.payout_details_confirmation_dialog;
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    format_date(date) {
      return formatDatev2(date);
    },

    close_dialog() {
      this.$emit("set_close_payout_details_dialog", this.approval_status);
    },
  },

  watch: {
    selected(val) {
      if (val.length) {
        this.compiled_ids = [];
        this.total_payouts = 0;

        this.selected.forEach((element) => {
          this.compiled_ids.push(parseInt(element.id));
          this.total_payouts += parseFloat(element.payout);
        });
      } else {
        this.compiled_ids = [];
        this.total_payouts = 0.0;
      }
    },
  },
};
</script>
