<template>
	<v-dialog scrollable v-model="dialog" max-width="800">
		<v-card>
			<v-card-title class="text-h5"> Manage Agent Player </v-card-title>

			<v-btn icon absolute right class="mt-4" @click="setCloseModal">
				<v-icon size="large"> mdi-close </v-icon>
			</v-btn>

			<v-divider></v-divider>

			<v-card-text class="py-4">
				<v-row dense>
					<v-col>
						<span inline class="mb-1 mt-2 overline">Agent:</span>
						<span inline class="mb-1 mt-2 overline primary--text"> {{ agentName }}</span>
					</v-col>
				</v-row>

				<v-row dense>
					<v-col cols="12" md="6">
						<v-btn :disabled="userLoading" color="primary" @click="setAddPlayerModal">
							<v-icon left> mdi-account-plus </v-icon>
							Add Player
						</v-btn>
					</v-col>

					<v-col cols="12" md="6">
						<v-text-field
							ref="search"
							v-model="search"
							prepend-inner-icon="mdi-magnify"
							placeholder="Search"
							single-line
							:disabled="userLoading"
							hide-details="auto"
							clearable
							outlined
							dense />
					</v-col>
				</v-row>

				<v-divider class="mt-2" />

				<v-row dense>
					<v-col>
						<v-data-table
							:headers="headers"
							:items="agentPlayers"
							:items-per-page="5"
							:search="search"
							:loading="userLoading"
							loading-text="Loading, please wait a moment"
							sort-by.sync="name"
							class="mt-2">
							<template #item.name="{ item }">
								<v-badge content="verified" color="blue" left v-if="item.account_verified === 'Verified'">
									<span> {{ item.name }}</span>
								</v-badge>

								<span v-else> {{ item.name }}</span>
							</template>

							<template v-slot:[`item.action`]="{ item }">
								<v-row align="center" justify="center">
									<v-tooltip right>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												dense
												v-bind="attrs"
												v-on="on"
												icon
												small
												:disabled="userLoading"
												color="red"
												@click="removePlayer(item.id, item.name)">
												<v-icon size="large"> mdi-minus </v-icon>
											</v-btn>
										</template>
										<span>Remove</span>
									</v-tooltip>
								</v-row>
							</template>

							<template v-slot:[`item.is_active`]="{ item }">
								<v-chip small :class="item.is_active == 'Active' ? 'green lighten-5 green--text' : 'red lighten-5 red--text'">
									{{ item.is_active }}
								</v-chip>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<ManageConfirmation v-if="confirmationDialogStatus" :agent_and_player_id="agent_and_player_id" @setConfirmationModal="confirmationModal" />
	</v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ManageConfirmation from '@/components/agents/ManageConfirmation'

export default {
	props: {
		agent_data: {
			type: Object,
		},
	},

	components: { ManageConfirmation },

	data: () => ({
		valid: true,
		search: '',
		headers: [
			{
				text: 'Action',
				sortable: false,
				value: 'action',
				class: 'text-uppercase',
				align: 'center',
			},
			{
				text: 'Name',
				sortable: false,
				value: 'name',
				class: 'text-uppercase',
			},
			{
				text: 'Status',
				sortable: false,
				value: 'is_active',
				class: 'text-uppercase',
			},
		],
		filter: 'Agent Name',
		filters: ['Agent Name', 'Mobile Number', 'Email'],
		isFromManagePlayer: true,
		confirmationDialogStatus: false,
		agent_and_player_id: {},
		confirmationStatus: false,
		agentName: '',
	}),

	created() {
		this.requestAgentPlayers()
		this.agentName = this.agent_data.name
	},

	computed: {
		...mapState('agents', ['userLoading', 'agentPlayers']),

		dialog: {
			get: function () {
				return true
			},
			set: function () {
				this.setCloseModal()
			},
		},
	},

	methods: {
		...mapActions('agents', ['getAgentPlayers']),

		async requestAgentPlayers() {
			await this.getAgentPlayers(this.setAgentPlayersFormData())

			this.$refs.search.focus()
		},

		setCloseModal() {
			this.$emit('setManagePlayerModal', this.confirmationStatus)
		},

		setAddPlayerModal() {
			this.$emit('setAddPlayerModal', this.agent_data.agent_id)
		},

		removePlayer(player_id, player_name) {
			this.agent_and_player_id = {
				agent_id: this.agent_data.agent_id,
				player_id: player_id,
				player_name: player_name,
			}

			this.confirmationDialogStatus = true
		},

		confirmationModal(confirmationStatus) {
			if (confirmationStatus.status == 'confirm') {
				this.confirmationStatus = true

				const results = this.agentPlayers.filter((obj) => {
					return obj.id != this.agent_and_player_id.player_id
				})

				this.$store.commit('agents/setAgentPlayers', results)
			}

			this.confirmationDialogStatus = false
		},

		setAgentPlayersFormData() {
			const formData = new FormData()
			formData.append('agent_id', this.agent_data.agent_id)

			const getData = new URLSearchParams(formData)

			return getData
		},
	},
}
</script>
