<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Players</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Player Withdrawal Account</h2>

    <v-card class="shadow">
      <div class="d-flex justify-space-between align-center bs-py-3 bs-px-4">
        <div class="d-flex">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="secondary"
                v-bind="attrs"
                v-on="on"
                @click="getPlayerWithdrawalAccounts"
              >
                <v-icon>mdi-cached</v-icon>
              </v-btn>
            </template>
            <span>Refresh</span>
          </v-tooltip>
        </div>
        <div class="d-flex" style="align-items: center; gap: 1rem">
          <v-text-field
            v-model="search"
            placeholder="Search"
            single-line
            hide-details="auto"
            :disabled="records_loading"
            clearable
            outlined
            dense
          >
          </v-text-field>

          <PlayerWithdrawalFilter
            :records_loading="records_loading"
            :first_withdrawal_account="first_withdrawal_account"
            @handleFilter="handleFilter"
          />
        </div>
      </div>

      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="records"
        :items-per-page="item_per_page_value"
        :search="search"
        :loading="records_loading"
        loading-text="Loading, please wait a moment"
        hide-default-footer
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :page.sync="page"
        @page-count="pageCount = $event"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="showDetails(item.player_id)"
                icon
                color="primary"
              >
                <v-icon size="large"> mdi-text-box-search-outline </v-icon>
              </v-btn>
            </template>
            <span>Details</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.is_verified`]="{ item }">
          <v-chip
            small
            :class="
              item.is_verified == 'Verified'
                ? 'green lighten-5 green--text'
                : item.is_verified == 'Pending'
                ? 'orange lighten-5 orange--text'
                : item.is_verified == 'Rejected'
                ? 'red lighten-5 red--text'
                : undefined
            "
          >
            {{ item.is_verified }}
          </v-chip>
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDate(item.created_at) }}
        </template>
      </v-data-table>

      <div v-if="records && records.length > 5">
        <v-divider></v-divider>
        <div class="d-flex align-center justify-space-between gap py-4 px-4 px-md-6">
          <div class="d-flex align-center gap">
            <v-select
              :value="item_per_page_value"
              :items="item_per_page"
              outlined
              dense
              label="Items"
              :hide-details="true"
              style="width: 100px"
              @change="item_per_page_value = parseInt($event, 10)"
            ></v-select>
            <div>
              Page
              {{
                page
                  ? formatNumber(parseInt(page))
                  : "0" + "-" + item_per_page_value
                  ? item_per_page_value
                  : "0"
              }}
              of {{ pageCount ? formatNumber(parseInt(pageCount)) : "0" }} -
              {{ ` ${formatNumber(parseInt(records.length))}` }}
            </div>
          </div>
          <v-pagination v-model="page" :length="pageCount" :total-visible="0" />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import PlayerWithdrawalFilter from "@/components/players/player_fund_account/PlayerWithdrawalFilter.vue";
import responseGet from "@/helpers/api_request_get.js";
import dateFormat from "@/helpers/formatDatev2.js";

export default {
  name: "PlayersWithdrawalAccounts",
  components: {
    PlayerWithdrawalFilter,
  },
  data: () => ({
    records: [],
    records_loading: false,

    headers: [
      {
        text: "Action",
        value: "action",
        class: "text-uppercase",
      },
      {
        text: "Outlet",
        value: "bank",
        class: "text-uppercase",
      },
      {
        text: "Account Number",
        value: "account_number",
        class: "text-uppercase",
      },
      {
        text: "Account Name",
        value: "account_name",
        class: "text-uppercase",
      },
      {
        text: "Status",
        value: "is_verified",
        class: "text-uppercase",
      },
      {
        text: "Date Created",
        value: "created_at",
        class: "text-uppercase",
      },
    ],

    sortBy: "created_at",
    sortDesc: true,

    page: 1,
    pageCount: 0,
    item_per_page: [5, 10, 15, 20, 25],
    item_per_page_value: 5,

    search: "",

    date_from: "",
    date_to: "",

    first_withdrawal_account: "",
  }),
  mounted() {
    this.getPlayerWithdrawalAccounts();
  },
  methods: {
    async getPlayerWithdrawalAccounts() {
      this.records_loading = true;

      const form_data = new FormData();
      form_data.append("date_from", this.date_from);
      form_data.append("date_to", this.date_to);
      const params = new URLSearchParams(form_data);

      const res = await responseGet(params, "players/withdrawal_accounts");

      if (res.status == 200) {
        this.records = res.data.withdrawal_accounts;
        this.first_withdrawal_account = res.data.first_withdrawal_accounts.created_at;
      }
      this.records_loading = false;
    },
    showDetails(player_id) {
      this.$router.push({ name: "PlayerDetails", params: { id: player_id } });
    },
    formatNumber(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    formatDate(date) {
      return dateFormat(date, "datetime");
    },
    handleFilter(data) {
      this.date_from = data.date_from;
      this.date_to = data.date_to;

      this.getPlayerWithdrawalAccounts();
    },
  },
};
</script>
