const url = process.env.VUE_APP_API_URL + "ending/reports/payouts"

export default {
    namespaced: true,

    state: {
        records: [],
        loading: false,
        recordsPayoutsPerCard: [],
        recordsPayoutsPerCardLoading: false,
        recordsPayoutsWinners: [],
        recordsPayoutsWinnersLoading: false
    },

    mutations: {
        setRecords (state, val) {
            state.records = val
        },

        setRecordsLoading (state, val) {
            state.loading = val
        },

        setRecordsPayoutsPerCard(state, val) {
            state.recordsPayoutsPerCard = val
        },

        setRecordsPayoutsPerCardLoading(state, val) {
            state.recordsPayoutsPerCardLoading = val
        },

        setRecordsPayoutsWinners(state, val) {
            state.recordsPayoutsWinners = val
        },

        setRecordsPayoutsWinnersLoading(state, val) {
            state.recordsPayoutsWinnersLoading = val
        }
    },

    actions: {
        async getRecords({ commit, rootState }, data) {
            commit("setRecordsLoading", true)

            try {
                const response = await fetch(`${url}?${data}` , {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                })

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setRecords", res.records)

                    return res
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setRecordsLoading", false)
            }
        },

        async getPayoutsPerGame({ commit, rootState }, data) {
            commit("setRecordsPayoutsPerCardLoading", true)

            try {
                const response = await fetch(`${url}/per_card?game_id=${data}` , {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                })

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setRecordsPayoutsPerCard", res.records)

                    return res
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setRecordsPayoutsPerCardLoading", false)
            }
        },

        async getPayoutsWinners({ commit, rootState }, data) {
            commit("setRecordsPayoutsWinnersLoading", true)

            try {
                const response = await fetch(`${url}/winners?card_id=${data}` , {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                })

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setRecordsPayoutsWinners", res.records)

                    return res
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setRecordsPayoutsWinnersLoading", false)
            }
        }
    }
}