<template>
  <div class="pa-4 pa-md-6">
    <v-row>
      <v-col cols="12" md="6" lg="7">
        <v-card>
          <v-card-text class="pb-0">
            <div class="text-center title">
              {{ details.league }}
            </div>
          </v-card-text>
          <v-card-title>
            <v-row justify="center" align="center">
              <v-col
                cols="auto"
                class="text-center"
                align-self="center"
              >
                <v-avatar
                  size="100px"
                  tile
                >
                  <v-img
                    :src="details.team_a_logo"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
                <div>
                  <span class="text-caption">{{ details.team_a_name }} {{ details.team_a_alias }}</span>
                </div>
              </v-col>

              <v-col
                cols="auto"
                class="text-center"
              >
                <span class="text-overline my-2 mx-2 font-italic">vs</span>
              </v-col>
              
              <v-col
                cols="auto"
                class="text-center"
              >
                <v-avatar
                  size="100px"
                  tile
                >
                  <v-img
                    :src="details.team_b_logo"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
                <div>
                  <span class="text-caption">{{ details.team_b_name }} {{ details.team_b_alias }}</span>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <p class="mb-1 overline primary--text">Game Details</p>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody class="table-borderless">
                  <tr>
                    <td style="width: 30%" class="grey--text">Schedule</td>
                    <td>
                      <span>{{ formattedSchedule }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="grey--text">Status</td>
                    <td>
                      <span>{{ details.status || '-' }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div v-if="details.game_results && details.game_results.length">
              <p class="mb-1 mt-3 overline primary--text">Game Result</p>
              <v-simple-table dense class="table-bordered">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase text-center">Quarter</th>
                      <th class="text-uppercase text-center">{{ details.team_a_name }} Score</th>
                      <th class="text-uppercase text-center">{{ details.team_b_name }} Score</th>
                      <th class="text-uppercase text-center">Combination#</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in details.game_results"
                      :key="item.quarter_id"
                    >
                      <td class="text-center">{{ item.quarter }}</td>
                      <td class="text-center">{{ item.team_a_score }}</td>
                      <td class="text-center">{{ item.team_b_score }}</td>
                      <td class="text-center">{{ item.combination }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <p class="mb-1 overline primary--text">Card Details</p>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody class="table-borderless">
                  <tr>
                    <td style="width: 30%" class="grey--text">Card Number</td>
                    <td>
                      <span>#{{ details.card_number }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 30%" class="grey--text">Bet Type</td>
                    <td>
                      <span>{{ details.type }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="grey--text">Bet Amount</td>
                    <td>
                      <span v-if="details.bet_amount">{{ 'PHP ' + moneyFormat(parseFloat(details.bet_amount)) }}</span>
                      <span v-else>-</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="grey--text">Total Bets</td>
                    <td>
                      <span>{{ details.total_bets }} / 100</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="grey--text">Total Bet Amount</td>
                    <td>
                      <span v-if="details.total_bets_amount">{{ 'PHP ' + moneyFormat(parseFloat(details.total_bets_amount)) }}</span>
                      <span v-else>-</span>
                    </td>
                  </tr>
                  <tr v-if="details.generated_file=='Yes'">
                    <td class="grey--text">PDF File</td>
                    <td>
                      <v-btn
                        outlined
                        color="primary"
                        small
                        @click="viewPdf"
                      >
                        <v-icon
                          left
                          dark
                        >
                          mdi-folder-check-outline
                        </v-icon>
                        View
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <p class="mb-1 mt-3 overline primary--text">Card Prize{{ (details.type=='Traditional') ? '' : 's' }}</p>
            <v-simple-table dense class="table-bordered">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-uppercase text-center">Quarter</th>
                    <th class="text-uppercase text-center">Prize</th>
                    <th class="text-uppercase text-center">Winner</th>
                    <!-- <th class="text-uppercase text-center">Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in details.card_prizes"
                    :key="item.quarter_id"
                  >
                    <td class="text-center">{{ item.quarter }}</td>
                    <td class="text-center">{{ (item.is_cash=='Cash') ? 'PHP ' + moneyFormat(parseFloat(item.cash_prize)) : item.other_prize }}</td>
                    <td class="text-center">
                      <v-icon
                        size="large"
                        color="success"
                        v-if="item.player_id"
                      >
                        mdi-check-circle-outline
                      </v-icon>
                      <v-icon
                        size="large"
                        color="error"
                        v-else
                      >
                        mdi-close-circle-outline
                      </v-icon>
                    </td>
                    <!-- <td class="text-center">
                      <div v-if="item.player_id">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-if="item.result=='Lose'"
                              v-bind="attrs"
                              v-on="on"
                              icon
                              small
                              color="primary"
                              @click.prevent="handleClick(item.quarter_id, item.player_id, item.slot_id)"
                            >
                              <v-icon
                                size="large"
                              >
                                mdi-send-circle-outline
                              </v-icon>
                            </v-btn>

                            <v-btn
                              v-else
                              v-bind="attrs"
                              v-on="on"
                              icon
                              small
                              color="success"
                              text
                              style="cursor: auto"
                            >
                              <v-icon
                                size="large"
                              >
                                mdi-check-circle-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span v-if="item.result=='Lose'">Send Prize</span>
                          <span v-else>Prize Sent</span>
                        </v-tooltip>
                      </div>
                      <span v-else>-</span>
                    </td> -->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" lg="5">
        <v-card>
          <v-card-text class="pa-2">
            <ActualCard v-if="details.slots" :cardData="details.slots" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="500"
      persistent      
    >
      <v-card>
        <v-card-title class="text-h5">
          Confirm
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="py-4"
        >
          <p class="mb-0">Are you sure you want to send prize?</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <div class="mt-4 text-right">
            <v-btn
              color="black"
              text
              @click="dialog = false"
            >
              Cancel
            </v-btn>

            <v-btn
              color="primary"
              text
              @click.prevent="handleSubmit"
              :loading="sendPrizeLoading"
            >
              Send
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import formatDate from '@/helpers/formatDate'
import ActualCard from "@/components/ending/cards/ActualCard"

export default {
  components: { ActualCard },
  props: {
    id: {
      required: true
    }
  },
  data: () => ({
    dialog: false,
    quarter_id: '',
    player_id: '',
    slot_id: ''
  }),
  created() {
    if (!this.detailsLoading) {
      this.$store.commit("ending_card_details/setDetails", [])
      this.getDetails(this.id)
    }
  },
  computed: {
    ...mapState("ending_card_details", ["details", "detailsLoading", "sendPrizeError", "sendPrizeLoading"]),
    formattedSchedule() {
      return formatDate(this.details.schedule, true)
    },
  },
  methods: {
    ...mapActions("ending_card_details", ["getDetails", "sendPrize"]),
    datetime(date) {
      return formatDate(date, true)
    },
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    handleClick(quarter_id, player_id, slot_id) {
      this.quarter_id = quarter_id
      this.player_id = player_id
      this.slot_id = slot_id
      this.dialog = true
    },
    async handleSubmit() {
      const formData = new FormData()
      formData.append('game_id', this.details.game_id)
      formData.append('card_id', this.details.card_id)
      formData.append('quarter_id', this.quarter_id)
      formData.append('player_id', this.player_id)
      formData.append('slot_id', this.slot_id)

      if (!this.sendPrizeLoading) {
        await this.sendPrize(formData)

        if (!this.sendPrizeError) {
          this.dialog = false
          
          const winner = this.details.card_prizes.find((record) => record.quarter_id == this.quarter_id && record.player_id == this.player_id && record.slot_id == this.slot_id)
          winner.result = "Win"
        }
      }
    },
    viewPdf() {
      window.open(this.details.pdf_url, '_blank')
    }
  },
  watch: {
    details(val) {
      if (val) {
        // this.betting = (val.betting=='Enabled') ? true : false
      }
    }
  }
}
</script>

<style>
.actual-card {
  border-collapse: collapse;
  width: 100%;
}
.actual-card, .actual-card th, .actual-card td {
  border: 1px solid #000;
}
.actual-card td {
  position: relative;
}
.card-slot-triangle-grey {
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 22px 22px;
  border-color: transparent transparent #BDBDBD transparent;
}

.card-slot-triangle-green {
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 22px 22px;
  border-color: transparent transparent #4CAF50 transparent;
}
</style>