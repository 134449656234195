<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Ending Reports</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Game Card</h2>
    <v-card class="shadow mb-4">
      <v-card-title
        class="text-h6 py-2 font-weight-regular grey--text text--darken-1"
      >
        Select Schedule Date
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-4">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="handleSearch"
        >
          <v-row dense>
            <v-col cols="12" md="4" class="mt-3">
              <v-select
                v-model="gameStatus"
                :items="gameStatuses"
                :rules="[rules.required]"
                label="Game Status"
                dense
                outlined
                hide-details="auto"
              />
            </v-col>

            <v-col cols="12" md="4" class="mt-3">
              <v-menu
                v-model="date_from_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_from"
                    :rules="[rules.required, lessThanDate2]"
                    label="Schedule Date From"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @keyup="date_from_menu = true"
                    @keydown="date_from_menu = false"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_from"
                  @input="date_from_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4" class="mt-3">
              <v-menu
                v-model="date_to_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_to"
                    :rules="[lessThanDate]"
                    label="Schedule Date To (Optional)"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :disabled="dateToAvailability"
                    @keyup="date_to_menu = true"
                    @keydown="date_to_menu = false"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_to"
                  @input="date_to_menu = false"
                  :min="date_from ? date_from : ''"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="league"
                    :items="leagues"
                    item-text="league"
                    item-value="id"
                    label="League (Optional)"
                    dense
                    outlined
                    hide-details="auto"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="team"
                    :items="leagueTeams"
                    :item-text="leagueTeamName"
                    item-value="id"
                    label="Team (Optional)"
                    dense
                    outlined
                    hide-details="auto"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row> -->
          <div class="mt-4">
            <v-btn
              :disabled="!valid"
              type="submit"
              color="primary"
              outlined
              @click.prevent="handleSearch"
              :loading="recordsLoading"
            >
              Search
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <div class="mb-4">
      <div class="bs-row bs-g-3">
        <div class="bs-col-md-6 bs-col-lg-3">
          <v-card class="shadow pa-6">
            <div class="grey--text" style="font-size: 13px; margin-top: 4px">
              Number of Cards
            </div>
            <h2 class="dark--text mb-0">
              {{ parseInt(records.length).toLocaleString() }}
            </h2>
          </v-card>
        </div>
        <div class="bs-col-md-6 bs-col-lg-3">
          <v-card class="shadow pa-6">
            <div class="grey--text" style="font-size: 13px; margin-top: 4px">
              Total Bets
            </div>
            <h2 class="dark--text mb-0">
              {{ parseInt(totalBets).toLocaleString() }}
            </h2>
          </v-card>
        </div>
        <div class="bs-col-md-6 bs-col-lg-3">
          <v-card class="shadow pa-6">
            <div class="grey--text" style="font-size: 13px; margin-top: 4px">
              Total Bet Amount
            </div>
            <h2 class="dark--text mb-0">
              {{ "₱" + moneyFormat(parseFloat(totalBetAmount)) }}
            </h2>
          </v-card>
        </div>
        <div class="bs-col-md-6 bs-col-lg-3">
          <v-card class="shadow pa-6">
            <div class="grey--text" style="font-size: 13px; margin-top: 4px">
              Total Payouts
            </div>
            <h2 class="dark--text mb-0">
              {{ "₱" + moneyFormat(parseFloat(totalPayouts)) }}
            </h2>
          </v-card>
        </div>
      </div>
    </div>

    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  @click="handleSearch"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
            <downloadexcel
              class="d-inline-block"
              :data="records"
              :fields="{
                ID: 'card_id',
                League: 'league',
                Game: {
                  callback: (value) => {
                    return `${value.team_a_name} ${value.team_a_alias} vs ${value.team_b_name} ${value.team_b_alias}`;
                  },
                },
                Schedule: {
                  callback: (value) => {
                    return this.dateFormat(value.schedule);
                  },
                },
                'Card Number': {
                  callback: (value) => {
                    return `#${value.card_number}`;
                  },
                },
                'Prize Type': 'type',
                'Bet Amount': 'bet_amount',
                'Total Bets': {
                  callback: (value) => {
                    return `${value.total_bets} (${value.total_bets}%)`;
                  },
                },
                'Total Bet Amount': 'total_bet_amount',
                'Created Date': {
                  callback: (value) => {
                    return this.dateFormat(value.created_at);
                  },
                },
                'Created Datetime': {
                  callback: (value) => {
                    return this.datetimeFormat(value.created_at);
                  },
                },
              }"
              :name="exportExcelName()"
              type="csv"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="secondary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-file-delimited-outline</v-icon>
                  </v-btn>
                </template>
                <span>Export to CSV</span>
              </v-tooltip>
            </downloadexcel>
          </v-col>
          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="recordsLoading"
              clearable
              outlined
              dense
            >
              <!-- <template v-slot:append-outer>
                <v-tooltip
                  top
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      color="secondary"
                      @click="filter"
                      v-on="on"
                      small
                    >
                      <v-icon>mdi-filter-variant</v-icon>
                    </v-btn>
                  </template>
                  Filter
                </v-tooltip>
              </template> -->
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="recordsFormatted"
          :items-per-page="5"
          :search="search"
          :loading="recordsLoading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="showDetails(item.card_id)"
                  icon
                  small
                  color="primary"
                >
                  <v-icon size="large"> mdi-text-box-search-outline </v-icon>
                </v-btn>
              </template>
              <span>Details</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.game`]="{ item }">
            <div>
              <v-avatar size="36px" tile>
                <v-img :src="item.team_a_logo">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="black"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
              <span class="text-caption"
                >{{ item.team_a_name }} {{ item.team_a_alias }}</span
              >
              <span class="text-overline my-2 mx-2 font-italic">vs</span>
              <v-avatar size="36px" tile>
                <v-img :src="item.team_b_logo">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="black"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
              <span class="text-caption"
                >{{ item.team_b_name }} {{ item.team_b_alias }}</span
              >
            </div>
          </template>

          <template v-slot:[`item.total_bets`]="{ item }">
            <v-progress-linear :color="getColor(item)" height="25">
              <strong>{{ Math.ceil(item.total_bets) }}%</strong>
            </v-progress-linear>
            <!-- {{ item.total_bets }} ({{ item.total_bets }}%) -->
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { format, sub } from "date-fns";
import formRules from "@/helpers/formRules";
import downloadexcel from "vue-json-excel";
import formatDate from "@/helpers/formatDate";
import formatDatev2 from "@/helpers/formatDatev2";

export default {
  components: { downloadexcel },
  data: () => ({
    valid: true,
    rules: formRules,
    date_from: format(sub(new Date(), { days: 30 }), "yyyy-MM-dd"),
    date_from_menu: false,
    date_to: format(new Date(), "yyyy-MM-dd"),
    date_to_menu: false,
    search: "",
    sortBy: "card_id",
    sortDesc: true,
    filterStatus: ["All", "Agent", "Player"],
    gameStatuses: ["All", "Not Started", "Ongoing", "Finished"],
    gameStatus: "All",
    filter: "All",
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      // { text: 'ID', value: 'card_id', align: 'left' },
      {
        text: "League",
        sortable: false,
        value: "league",
        class: "text-uppercase",
      },
      { text: "Game", sortable: false, value: "game", class: "text-uppercase" },
      {
        text: "Schedule",
        sortable: false,
        value: "formattedSchedule",
        class: "text-uppercase",
      },
      {
        text: "Card Number",
        sortable: false,
        value: "formattedCardNumber",
        class: "text-uppercase",
      },
      {
        text: "Prize Type",
        sortable: false,
        value: "type",
        class: "text-uppercase",
      },
      {
        text: "Bet Amount",
        value: "formattedBetAmount",
        class: "text-uppercase",
      },
      { text: "Total Bets", value: "total_bets", class: "text-uppercase" },
      {
        text: "Total Bet Amount",
        value: "formattedTotalBetAmount",
        class: "text-uppercase",
      },
      { text: "Payouts", value: "payout", class: "text-uppercase" },
      {
        text: "Created At",
        value: "formattedCreatedAt",
        class: "text-uppercase",
      },
    ],

    // Filter data
    //createdAtStart: format(sub(new Date(), { days: 30 }), 'yyyy-MM-dd'),
    //createdAtEnd: format(new Date(), 'yyyy-MM-dd'),
    query_filters: {},
  }),
  mounted() {
    this.$store.commit("ending_cards/setRecords", []);

    const urlQuery = this.$route.query;

    if (Object.keys(urlQuery).length) {
      this.filter = urlQuery.filter_by;
      this.gameStatus = urlQuery.game_status;
      this.date_from = urlQuery.created_at_start;
      this.date_to = urlQuery.created_at_end;
    }

    this.handleSearch();
  },
  computed: {
    ...mapState("ending_cards", ["records", "recordsLoading"]),
    recordsFormatted() {
      return this.records.map((record) => {
        return {
          ...record,
          formattedCardNumber: "#" + record.card_number,
          formattedBetAmount:
            "PHP " + this.moneyFormat(parseFloat(record.bet_amount)),
          formattedCreatedAt: formatDate(record.created_at, true),
          formattedSchedule: formatDate(record.schedule, true),
          formattedTotalBetAmount:
            "PHP " + this.moneyFormat(parseFloat(record.total_bet_amount)),
        };
      });
    },
    lessThanDate() {
      if (this.date_from && this.date_to) {
        if (new Date(this.date_to) < new Date(this.date_from)) {
          return "Must be higher or equal than date from.";
        }
      }

      return true;
    },
    lessThanDate2() {
      if (this.date_from && this.date_to) {
        if (new Date(this.date_from) > new Date(this.date_to)) {
          return "Must be lessthan or equal than date to.";
        }
      }

      return true;
    },

    dateToAvailability() {
      if (this.date_from == "" || this.date_from == null) {
        return true;
      }

      return false;
    },

    totalBets() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseInt(obj.total_bets) + total,
          0
        );
      } else {
        return 0;
      }
    },

    totalBetAmount() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.total_bet_amount) + total,
          0
        );
      } else {
        return 0;
      }
    },

    totalPayouts() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.payout) + total,
          0
        );
      } else {
        return 0;
      }
    },
  },
  methods: {
    ...mapActions("ending_cards", ["getRecords"]),
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    dateFormat(date) {
      return formatDatev2(date, "format", "yyyy-MM-dd");
    },
    datetimeFormat(date) {
      return formatDatev2(date, "format", "yyyy-MM-dd HH:mm:ss");
    },
    // filter() {
    //   alert('I am working on this...')
    // },
    // loadRecords() {
    //   if (!this.recordsLoading) {
    //     this.$store.commit("ending_cards/setRecords", [])

    //     const formData = new FormData()
    //     formData.append('created_at_start', this.date_from)
    //     formData.append('created_at_end', this.date_to)

    //     const getData = new URLSearchParams(formData)

    //     this.getRecords(getData)
    //   }
    // },
    exportExcelName() {
      return "Cards " + format(new Date(), "yyyy-MM-dd hh:mm:ss") + ".xls";
    },

    getColor(item) {
      return item.total_bets <= 70 ? "orange" : "green";
    },

    async handleSearch() {
      if (this.$refs.form.validate()) {
        if (!this.recordsLoading) {
          this.$store.commit("ending_cards/setRecords", []);

          const formData = new FormData();
          formData.append("filter_by", this.filter);
          formData.append("game_status", this.gameStatus);
          formData.append("created_at_start", this.date_from);
          formData.append("created_at_end", this.date_to ?? "");

          const getData = new URLSearchParams(formData);

          this.query_filters = {
            filter_by: this.filter,
            game_status: this.gameStatus,
            created_at_start: this.date_from,
            created_at_end: this.date_to,
          };

          this.setQueryString();
          this.getRecords(getData);
        }
      }
    },

    showDetails(card_id) {
      this.setQueryString();

      this.$router.push({ name: "EndingCardDetails", params: { id: card_id } });
    },

    setQueryString() {
      // const currentPath = `/ending_cards?created_at_start=${this.query_filters.created_at_start}&created_at_end=${this.query_filters.created_at_end}`

      // if (currentPath != this.$router.currentRoute.fullPath) {
      //   this.$router.push({
      //     name: this.$route.name,
      //     query: this.query_filters
      //   })
      // }

      const urlQuery = this.$route.query;

      if (
        urlQuery.filter_by != this.filter ||
        urlQuery.game_status != this.gameStatus ||
        urlQuery.created_at_start != this.date_from ||
        urlQuery.created_at_end != this.date_to
      ) {
        this.query_filters = {
          filter_by: this.filter,
          game_status: this.gameStatus,
          created_at_start: this.date_from,
          created_at_end: this.date_to,
        };

        this.$router.push({
          name: this.$route.name,
          query: this.query_filters,
        });
      }
    },
  },

  watch: {
    date_from(val) {
      if (val == "" || val == null) {
        this.date_to = "";
      }
    },
  },
};
</script>
