var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4"},[_c('v-card',{staticClass:"shadow-none"},[_c('v-card-title',[_c('span',[_vm._v("Commission")])]),_c('v-card-text',{staticClass:"d-flex flex-column",staticStyle:{"gap":"1rem"}},[_c('div',{staticClass:"d-flex justify-end align-center"},[_c('div',{staticClass:"d-flex align-center gap"},[_c('v-text-field',{ref:"search",attrs:{"placeholder":"Search","single-line":"","hide-details":"auto","disabled":_vm.records_loading,"clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"grey lighten-4",attrs:{"icon":"","color":"black","disabled":_vm.records_loading},on:{"click":_vm.set_filter_dialog}},on),[_c('v-icon',[_vm._v(" mdi-filter-variant ")])],1)]}}])},[_vm._v(" Filter ")])],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.records_loading,"loading-text":"Loading, please wait a moment","items-per-page":5,"item-key":"investor_id"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"blue lighten-5",attrs:{"dense":"","icon":"","color":"primary","disabled":_vm.records_loading || item.commission == 0 || item.status != 'Active'},on:{"click":function($event){return _vm.set_commission_details(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cash-check ")])],1)]}}],null,true)},[_c('span',[_vm._v("Settle Commissions")])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"px-2",class:item.status == 'Active'
                ? 'green lighten-5 green--text'
                : 'red lighten-5 red--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.commission",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(("Php " + (_vm.money_format(parseFloat(item.commission)))))+" ")]}},{key:"item.bet_count",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.number_format(parseInt(item.bet_count)))+" ")]}},{key:"item.bet_amount",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(("Php " + (_vm.money_format(parseFloat(item.bet_amount)))))+" ")]}}],null,true)})],1)],1),(_vm.filter_dialog)?_c('CommissionFilter',{attrs:{"filter_dialog_data":_vm.filter_dialog_data},on:{"set_close_filter_dialog":_vm.set_close_filter_dialog}}):_vm._e(),(_vm.commission_dialog)?_c('CommissionDetails',{attrs:{"commission_details_data":_vm.commission_dialog_data},on:{"set_close_commission_dialog":_vm.set_close_commission_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }