const url = process.env.VUE_APP_API_URL + "ending/configuration"

export default {
    namespaced: true,

    state: {
        records: [],
        id: 0,
        loading: false,
        error: "",
        errors: [],
    },

    mutations: {
        setRecords(state, val) {
            state.records = val
        },
        setId(state, val) {
            state.id =val
        },
        setLoading(state, val) {
            state.loading = val
        },
        setError(state, val) {
            state.error = val
        },
        setErrors(state, val) {
            state.errors = val
        }
    },

    actions: {
        async getRecords({ commit, rootState }) {
            commit("setLoading", true)
      
            try {
              const response = await fetch(`${url}` , {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${rootState.userToken}`,
                }
              });
      
              const res = await response.json()
              
              if (!response.ok) {
                throw { response, res }
              } else {
                commit("setRecords", res.records)
              }
            } catch(error) {
              const errorStatus = error.response.status
              
              if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
              } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
              }
            } finally {
              commit("setLoading", false)
            }
        },

        async createConfiguration({ commit, rootState }, data) {
            commit("setLoading", true)

            try {
                const response = await fetch(`${url}/create` , {
                    method: 'POST',
                    headers: {
                      'Authorization': `Bearer ${rootState.userToken}`,
                    },
                    body: data
                  });
          
                  const res = await response.json()
                  
                  if (!response.ok) {
                    throw { response, res }
                  } else {
                    commit("setId", res.config_id)
                  }
            } catch (error) {
                const errorStatus = error.response.status
              
              if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
              } else if (errorStatus==422) {
                commit("setErrors", error.res.messages)
              } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
              }
            } finally {
                commit("setLoading", false)
            }
        },

        async updateConfiguration({ commit, rootState }, data) {
            commit("setLoading", true)

            try {
                const response = await fetch(`${url}/update` , {
                    method: 'POST',
                    headers: {
                      'Authorization': `Bearer ${rootState.userToken}`,
                    },
                    body: data
                  });
          
                  const res = await response.json()
                  
                  if (!response.ok) {
                    throw { response, res }
                  } 
            } catch (error) {
                const errorStatus = error.response.status
              
              if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
              } else if (errorStatus==422) {
                commit("setErrors", error.res.messages)
              } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
              }
            } finally {
                commit("setLoading", false)
            }
        }
    }
}