<template>
  <div class="pa-4">
    <v-card class="shadow-none">
      <v-card-title>
        <span>Commission</span>
      </v-card-title>

      <v-card-text class="d-flex flex-column" style="gap: 1rem">
        <div class="d-flex justify-end align-center">
          <div class="d-flex align-center gap">
            <v-text-field
              ref="search"
              v-model="search"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="records_loading"
              clearable
              outlined
              dense
            />
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="grey lighten-4"
                  color="black"
                  v-on="on"
                  :disabled="records_loading"
                  @click="set_filter_dialog"
                >
                  <v-icon> mdi-filter-variant </v-icon>
                </v-btn>
              </template>
              Filter
            </v-tooltip>
          </div>
        </div>

        <v-data-table
          :headers="headers"
          :items="records"
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :loading="records_loading"
          loading-text="Loading, please wait a moment"
          :items-per-page="5"
          item-key="investor_id"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dense
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="blue lighten-5"
                  color="primary"
                  @click="set_commission_details(item)"
                  :disabled="
                    records_loading || item.commission == 0 || item.status != 'Active'
                  "
                >
                  <v-icon> mdi-cash-check </v-icon>
                </v-btn>
              </template>
              <span>Settle Commissions</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              small
              class="px-2"
              :class="
                item.status == 'Active'
                  ? 'green lighten-5 green--text'
                  : 'red lighten-5 red--text'
              "
            >
              {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:[`item.commission`]="{ item }">
            {{ `Php ${money_format(parseFloat(item.commission))}` }}
          </template>

          <template v-slot:[`item.bet_count`]="{ item }">
            {{ number_format(parseInt(item.bet_count)) }}
          </template>

          <template v-slot:[`item.bet_amount`]="{ item }">
            {{ `Php ${money_format(parseFloat(item.bet_amount))}` }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <CommissionFilter
      v-if="filter_dialog"
      :filter_dialog_data="filter_dialog_data"
      @set_close_filter_dialog="set_close_filter_dialog"
    />
    <CommissionDetails
      v-if="commission_dialog"
      :commission_details_data="commission_dialog_data"
      @set_close_commission_dialog="set_close_commission_dialog"
    />
  </div>
</template>

<script>
import responseGet from "@/helpers/api_request_get";
import formatDatev2 from "@/helpers/formatDatev2";
import { startOfMonth } from "date-fns";
import { endOfMonth } from "date-fns";
import CommissionFilter from "@/components/investors/CommissionsFilter";
import CommissionDetails from "@/components/investors/CommissionDetails";
import { mapActions } from "vuex";

export default {
  components: { CommissionFilter, CommissionDetails },

  data: () => ({
    search: "",
    date_from: formatDatev2(
      startOfMonth(
        new Date(
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        )
      ),
      "format",
      "yyyy-MM-dd"
    ),
    date_to: formatDatev2(
      endOfMonth(
        new Date(
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        )
      ),
      "format",
      "yyyy-MM-dd"
    ),
    filter_dialog: false,
    filter_dialog_data: {},
    filter_status: false,
    commission_dialog: false,
    commission_dialog_data: {},
    query_filter: {},
    records: [],
    records_loading: false,
    sortBy: "commission",
    sortDesc: true,
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
      },
      {
        text: "Name",
        value: "name",
        class: "text-uppercase",
      },
      {
        text: "Status",
        value: "status",
        class: "text-uppercase",
      },
      {
        text: "Unpaid Commission",
        align: "right",
        value: "commission",
        class: "text-uppercase",
      },
      {
        text: "Bet Count",
        align: "right",
        value: "bet_count",
        class: "text-uppercase",
      },
      {
        text: "Bet Amount",
        align: "right",
        value: "bet_amount",
        class: "text-uppercase",
      },
    ],
  }),

  mounted() {
    this.check_query_string();
    this.doGetCommissions();
  },

  methods: {
    ...mapActions("logout", ["sendLogoutRequest"]),

    async doGetCommissions() {
      this.records_loading = !this.records_loading;

      const response = await responseGet(this.set_form_data(), "investor/commissions");

      switch (response.status) {
        case 200:
          this.records = response.data.records;
          break;
        case 401:
          await this.sendLogoutRequest();
          this.$router.push({ name: "Home" });
          break;
      }

      this.records_loading = !this.records_loading;
    },

    set_commission_details(item) {
      this.commission_dialog_data = {
        investor_id: item.id,
        date_from: this.date_from,
        date_to: this.date_to,
      };
      this.commission_dialog = !this.commission_dialog;
    },

    set_close_commission_dialog(status) {
      if (status) this.doGetCommissions();
      this.commission_dialog = !this.commission_dialog;
    },

    set_filter_dialog() {
      this.filter_dialog_data = {
        date_from: this.date_from,
        date_to: this.date_to,
      };

      this.filter_dialog = !this.filter_dialog;
    },

    set_close_filter_dialog(filter_data) {
      if (Object.keys(filter_data).length) {
        this.date_from = filter_data.date_from;
        this.date_to = filter_data.date_to;

        this.doGetCommissions();

        this.set_query_string();
      }

      this.filter_dialog = !this.filter_dialog;
    },

    set_query_string() {
      const urlQuery = this.$route.query;
      this.query_filters = {};

      if (urlQuery.date_from != this.date_from || urlQuery.date_to != this.date_to) {
        if (this.date_from != null) this.query_filters["date_from"] = this.date_from;
        if (this.date_to != null) this.query_filters["date_to"] = this.date_to;

        this.$router.replace({
          name: this.$route.name,
          query: this.query_filters,
        });
      }
    },

    check_query_string() {
      const urlQuery = this.$route.query;

      if (Object.keys(urlQuery).length) {
        if (Object.prototype.hasOwnProperty.call(urlQuery, "date_from"))
          this.date_from = urlQuery.date_from;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "date_to"))
          this.date_to = urlQuery.date_to;
      }
    },

    set_form_data() {
      const form_data = new FormData();

      form_data.append("date_from", this.date_from);
      form_data.append("date_to", this.date_to);

      return new URLSearchParams(form_data);
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    format_date(date) {
      return formatDatev2(date, "custom_date");
    },
  },
};
</script>
