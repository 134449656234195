var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Lucky Four Game")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Scheduled Games")]),_c('v-card',{staticClass:"shadow"},[_c('v-card-title',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"2","align-self":"end"}}),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('v-text-field',{ref:"search",attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search","single-line":"","hide-details":"auto","disabled":_vm.getRecordsLoading,"clearable":"","outlined":"","dense":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.searchRecords.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"secondary","disabled":_vm.getRecordsLoading || _vm.leaguesLoading},on:{"click":_vm.openFilterDialog}},on),[_c('v-icon',[_vm._v("mdi-filter-variant")])],1)]}}])},[_vm._v(" Filter ")])]],2)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getRecords,"options":_vm.options,"loading":_vm.getRecordsLoading,"server-items-length":_vm.getTotalRows,"sort-desc":_vm.sortDesc,"loading-text":"Loading... Please wait","hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.showDetails(item.game_id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-text-box-search-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Details")])])]}},{key:"item.game",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-avatar',{attrs:{"size":"36px","tile":""}},[_c('v-img',{attrs:{"src":item.team_a_logo},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey"}})],1)]},proxy:true}],null,true)})],1),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.team_a_name)+" "+_vm._s(item.team_a_alias))]),_c('span',{staticClass:"text-overline my-2 mx-2 font-italic"},[_vm._v("vs")]),_c('v-avatar',{attrs:{"size":"36px","tile":""}},[_c('v-img',{attrs:{"src":item.team_b_logo},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey"}})],1)]},proxy:true}],null,true)})],1),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.team_b_name)+" "+_vm._s(item.team_b_alias))])],1)]}},{key:"item.betting",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",class:item.betting == 'Enabled'
                ? 'green lighten-5 green--text'
                : 'orange lighten-5 orange--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.betting)+" ")])]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",class:item.status
                ? 'green lighten-5 green--text'
                : 'orange lighten-5 orange--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status ? item.status : "Not yet started")+" ")])]}},{key:"item.schedule",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.schedule, true)))])]}},{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.created_at, true)))])]}}],null,true)}),_c('div',{staticClass:"pa-4"},[_c('v-row',{staticClass:"d-flex flex-row-reverse"},[_c('v-col',{attrs:{"cols":"4","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.rowNumbers,"label":"Row per page","outlined":"","dense":""},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-col',[_c('v-chip',{attrs:{"color":"primary","outlined":"","pill":""}},[_vm._v(" Total: "+_vm._s(_vm.getTotalRows)+" ")])],1)],1),_c('Pagination',{attrs:{"storeName":_vm.storeName,"isDisabled":_vm.isDisabled},on:{"paginatePage":_vm.loadRecords}})],1)],1)],1),_c('FilterBetsDialog',{ref:"filterBetsDialog",on:{"closeFilterDialog":_vm.closeFilterDialog},scopedSlots:_vm._u([{key:"selectFields",fn:function(){return [_c('v-select',{attrs:{"items":_vm.filterByLeagueStatus,"item-text":"league","item-value":"league","label":"Select League","dense":"","outlined":"","hide-details":"auto","clearable":""},model:{value:(_vm.filterByLeague),callback:function ($$v) {_vm.filterByLeague=$$v},expression:"filterByLeague"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }