<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Agent Reports</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Kubrador Progress</h2>
    <v-card class="shadow mb-4">
      <v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
        KUBRADOR PROGRESSIVE
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="py-4">
        <v-row>
          <v-col>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="doGetRecords"
            >
              <v-row dense>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="filter"
                    :items="filterOptions"
                    :rules="[rules.required]"
                    label="Filter By"
                    dense
                    outlined
                    hide-details="auto"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-menu
                    v-model="date_from_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_from"
                        :rules="[rules.required, lessThanDate]"
                        :label="`${filter} From`"
                        hide-details="auto"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @keyup="date_from_menu = true"
                        @keydown="date_from_menu = false"
                        clearable
                      />
                    </template>

                    <v-date-picker
                      v-model="date_from"
                      @input="date_from_menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="4">
                  <v-menu
                    v-model="date_to_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :disabled="date_from == ''"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_to"
                        :rules="[lessThanDate2]"
                        :label="`${filter} To (Optional)`"
                        hide-details="auto"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        :disabled="dateToAvailability"
                        @keyup="date_to_menu = true"
                        @keydown="date_to_menu = false"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_to"
                      @input="date_to_menu = false"
                      :min="date_from ? date_from : ''"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row dense class="mt-4">
                <v-col cols="12" md="4">
                  <v-autocomplete
                    ref="agent"
                    v-model="agent_id"
                    :items="agents"
                    item-text="agent"
                    item-value="id"
                    label="Agent"
                    dense
                    outlined
                    hide-details="auto"
                    :disabled="agentsLoading"
                    :loading="agentsLoading"
                    clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete
                    ref="province"
                    v-model="province_id"
                    :items="provinces"
                    item-text="province"
                    item-value="id"
                    label="Province"
                    dense
                    outlined
                    hide-details="auto"
                    :disabled="provincesLoading"
                    :loading="provincesLoading"
                    clearable
                    @change="city_mun_id = 0"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete
                    ref="cityMun"
                    v-model="city_mun_id"
                    :items="province_id ? filteredCityMunicipalities : cityMuns"
                    item-text="cityMuns"
                    item-value="id"
                    label="City/ Municipality"
                    dense
                    outlined
                    hide-details="auto"
                    :loading="cityMunsLoading"
                    :disabled="cityMunsLoading"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col>
                  <v-radio-group
                    :rules="[rules.required]"
                    hide-details="auto"
                    v-model="report_type"
                    row
                  >
                    <v-row dense>
                      <v-col cols="auto" class="ma-1">
                        <v-radio label="Daily" value="Daily" />
                      </v-col>

                      <v-col cols="auto" class="ma-1">
                        <v-radio label="Weekly" value="Weekly" />
                      </v-col>

                      <v-col cols="auto" class="ma-1">
                        <v-radio label="Monthly" value="Monthly" />
                      </v-col>

                      <v-col cols="auto" class="ma-1">
                        <v-radio label="Yearly" value="Yearly" />
                      </v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-row dense class="mt-4">
                <v-col>
                  <v-row dense>
                    <v-col>
                      <v-btn
                        depressed
                        type="submit"
                        color="primary"
                        :loading="topProvincesLoading"
                      >
                        Search
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="d-block">
      <div class="bs-row bs-g-3">
        <div class="bs-col-md-5">
          <v-card class="shadow">
            <v-card-title
              class="text-h6 py-5 font-weight-regular grey--text text--darken-1"
            >
              SUMMARY
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <v-data-table
                :headers="headers2"
                :items="top_provinces"
                :items-per-page="5"
                :search="search"
                :loading="topProvincesLoading"
                loading-text="Loading, please wait a moment"
                mobile-breakpoint="0"
                hide-default-footer
              >
                <template v-slot:[`item.bets`]="{ item }">
                  {{ parseInt(item.bets).toLocaleString() }}
                </template>

                <template v-slot:[`item.bets_amount`]="{ item }">
                  PHP {{ parseInt(item.bets_amount).toLocaleString() }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
        <div class="bs-col-md-7">
          <v-card class="shadow">
            <v-card-title>
              <v-row justify="space-between">
                <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
                  <downloadexcel
                    :data="progressive"
                    :fields="{
                      Date: {
                        callback: (value) => {
                          return scheduleDate(value.final_schedule_date);
                        },
                      },
                      Province: 'province',
                      City: 'city_mun',
                      'Agent Name': 'agent',
                      'Kubrador Name': 'kobrador',
                      'Total Bets': 'bets',
                      'Total Bets Amount': 'bets_amount',
                    }"
                    name="Agent Kubrador Porgressive.xls"
                    type="csv"
                    class="d-inline-block"
                  >
                    <v-btn outlined color="primary"> Export CSV </v-btn>
                  </downloadexcel>

                  <!-- <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                icon
                                                color="secondary"
                                                v-bind="attrs"
                                                v-on="on"
                                                class="mr-1">
                                                <v-icon>mdi-cached</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Refresh</span>
                                        </v-tooltip> -->
                </v-col>

                <v-col cols="12" sm="6" md="6" xl="3">
                  <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Search"
                    single-line
                    hide-details="auto"
                    :disabled="topProvincesLoading"
                    clearable
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="progressive"
                :items-per-page="5"
                :search="search"
                :loading="topProvincesLoading"
                loading-text="Loading, please wait a moment"
              >
                <template v-slot:[`item.final_schedule_date`]="{ item }">
                  <span>{{ scheduleDate(item.final_schedule_date) }}</span>
                </template>

                <template v-slot:[`item.bets`]="{ item }">
                  {{ parseInt(item.bets).toLocaleString() }}
                </template>

                <template v-slot:[`item.bets_amount`]="{ item }">
                  PHP {{ parseInt(item.bets_amount).toLocaleString() }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formRules from "@/helpers/formRules";
import { mapActions, mapState } from "vuex";
import downloadexcel from "vue-json-excel";
import formatDatev2 from "@/helpers/formatDatev2";

export default {
  components: {
    downloadexcel,
  },

  data: () => ({
    valid: true,
    rules: formRules,
    search: "",
    agent_id: "",
    province_id: "",
    city_mun_id: "",
    date_from: "",
    date_to: "",
    date_from_menu: false,
    date_to_menu: false,
    filterOptions: ["Bet Date", "Scheduled Date"],
    filter: "Scheduled Date",
    report_type: "Daily",
    searched_report_type: "",
    headers: [
      {
        text: "DATE",
        value: "final_schedule_date",
        class: "text-uppercase",
        align: "left",
      },
      {
        text: "AGENT",
        value: "agent",
        class: "text-uppercase",
        align: "left",
      },
      {
        text: "KUBRADOR",
        value: "kobrador",
        class: "text-uppercase",
        align: "left",
      },
      {
        text: "PROVINCE",
        value: "province",
        class: "text-uppercase",
        align: "left",
      },
      {
        text: "CITY/ MUNICIPALITY",
        value: "city_mun",
        class: "text-uppercase",
        align: "left",
      },
      {
        text: "TOTAL BETS",
        value: "bets",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "TOTAL BETS AMOUNT",
        value: "bets_amount",
        class: "text-uppercase",
        align: "right",
      },
    ],
    headers2: [
      {
        text: "PROVINCE",
        value: "province",
        class: "text-uppercase",
        sortable: false,
        align: "left",
      },
      {
        text: "TOTAL BETS",
        value: "bets",
        class: "text-uppercase",
        sortable: false,
        align: "right",
      },
      {
        text: "TOTAL BETS AMOUNT",
        value: "bets_amount",
        class: "text-uppercase",
        sortable: false,
        align: "right",
      },
    ],
  }),

  created() {
    if (Object.keys(this.provinces).length == 0) {
      this.doGetProvinces();
    }

    if (Object.keys(this.cityMuns).length == 0) {
      this.doGetCityMuns();
    }

    if (Object.keys(this.agents).length == 0) {
      this.doGetAgents();
    }

    this.$store.commit("kubrador_progressive_provinces/setRecords", []);
    this.$store.commit("kubrador_progressive_provinces/setProgressive", []);
  },

  computed: {
    ...mapState("kubrador_progressive_provinces", [
      "top_provinces",
      "progressive",
      "topProvincesLoading",
    ]),
    ...mapState("agents_monitoring", [
      "records",
      "recordsLoading",
      "provinces",
      "provincesLoading",
      "cityMuns",
      "cityMunsLoading",
      "agents",
      "agentsLoading",
    ]),

    filteredCityMunicipalities() {
      if (this.province_id == 0 || this.province_id == null) {
        return this.cityMuns.filter((cityMun) => {
          return (cityMun.province_id = !0);
        });
      } else {
        return this.cityMuns.filter((cityMun) => {
          return cityMun.province_id == this.province_id;
        });
      }
    },

    lessThanDate() {
      if (this.date_from && this.date_to) {
        if (new Date(this.date_to) < new Date(this.date_from)) {
          return "Must be higher or equal than date from.";
        }
      }

      return true;
    },

    lessThanDate2() {
      if (this.date_from && this.date_to) {
        if (new Date(this.date_from) > new Date(this.date_to)) {
          return "Must be lessthan or equal than date to.";
        }
      }

      return true;
    },

    dateToAvailability() {
      if (this.date_from == "" || this.date_from == null) {
        return true;
      }

      return false;
    },
  },

  methods: {
    ...mapActions("kubrador_progressive_provinces", ["getRecords"]),
    ...mapActions("agents_monitoring", ["getProvinces", "getCityMuns", "getAgents"]),

    async doGetProvinces() {
      await this.getProvinces();
    },

    async doGetCityMuns() {
      await this.getCityMuns();
    },

    async doGetAgents() {
      await this.getAgents();
    },

    async doGetRecords() {
      if (this.$refs.form.validate()) {
        this.searched_report_type = this.report_type;

        this.$store.commit("kubrador_progressive_provinces/setRecords", []);
        this.$store.commit("kubrador_progressive_provinces/setProgressive", []);
        await this.getRecords(this.setFormData());
      }
    },

    setFormData() {
      const formData = new FormData();

      formData.append("filter_by", this.filter);
      formData.append("report_type", this.report_type);
      formData.append("agent_id", this.agent_id ?? "");
      formData.append("player_id", this.player_id ?? "");
      formData.append("city_mun_id", this.city_mun_id ?? "");
      formData.append("province_id", this.province_id ?? "");
      formData.append("date_from", this.date_from ? this.date_from : "");
      formData.append(
        "date_to",
        this.date_to ? this.date_to : this.date_from ? this.date_from : ""
      );

      const getData = new URLSearchParams(formData);

      return getData;
    },

    scheduleDate(sched) {
      if (this.searched_report_type == "Daily") {
        return formatDatev2(sched, "date");
      }

      if (this.searched_report_type == "Weekly") {
        return formatDatev2(sched, "monthweek");
      }

      if (this.searched_report_type == "Monthly") {
        return formatDatev2(sched, "monthyear");
      }

      if (this.searched_report_type == "Yearly") {
        return sched;
      }
    },
  },

  watch: {
    date_from(val) {
      if (val == "" || val == null) {
        this.date_to = "";
      }
    },
  },
};
</script>
