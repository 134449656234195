<template>
    <v-dialog scrollable v-model="dialog" max-width="600px">
        <v-card>
            <v-card-title>
                <v-icon class="mr-2"> mdi-seal-variant </v-icon>

                LIST OF WINNERS

                <v-btn icon absolute right @click="closeModal">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text class="py-4">
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody class="table-borderless">
                      <tr>
                        <td style="width: 30%" class="overline grey--text">CARD NUMBER</td>
                        <td>
                          <span>#{{ setCardPadding(card_id) }}</span>
                        </td>
                      </tr>

                      <tr>
                        <td style="width: 30%" class="overline grey--text">LEAGUE</td>
                        <td>
                          <span>{{ game_details.league }}</span>
                        </td>
                      </tr>

                      <tr>
                        <td style="width: 30%" class="overline grey--text">SCHEDULED GAME</td>
                        <td>
                            <v-avatar
                                size="36px"
                                tile
                            >
                                <v-img
                                :src="game_details.team_a_logo"
                                >
                                <template v-slot:placeholder>
                                    <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                    >
                                    <v-progress-circular
                                        indeterminate
                                        color="black"
                                    ></v-progress-circular>
                                    </v-row>
                                </template>
                                </v-img>
                            </v-avatar>
                            <span class="text-caption">{{ game_details.team_a_name }} {{ game_details.team_a_alias }}</span>
                            <span class="text-overline my-2 mx-2 font-italic">vs</span>
                            <v-avatar
                                size="36px"
                                tile
                            >
                                <v-img
                                :src="game_details.team_b_logo"
                                >
                                <template v-slot:placeholder>
                                    <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                    >
                                    <v-progress-circular
                                        indeterminate
                                        color="black"
                                    ></v-progress-circular>
                                    </v-row>
                                </template>
                                </v-img>
                            </v-avatar>
                            <span class="text-caption">{{ game_details.team_b_name }} {{ game_details.team_b_alias }}</span>
                        </td>
                      </tr>

                      <tr>
                        <td style="width: 30%" class="overline grey--text">TOTAL PAYOUTS</td>
                        <td>
                          <span>PHP {{ moneyFormat(parseFloat(payouts)) }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-row dense>
                    <v-col>
                        <v-data-table
                            :headers="headers"
                            :items="recordsPayoutsWinners"
                            :items-per-page="5"
                            :loading="recordsPayoutsWinnersLoading"
                            loading-text="Loading, please wait a moment"
                            class="mt-4">

                            <template v-slot:[`item.action`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                            icon
                                            small
                                            color="primary"
                                            @click="setListOfWinnersModal(item.card_id)"
                                            class="ma-1">
                                            <v-icon size="large"> mdi-seal-variant </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Show List of Winners</span>
                                </v-tooltip>
                            </template>

                            <template v-slot:[`item.scheduled_game`]="{ item }">
                                <v-avatar
                                    size="36px"
                                    tile
                                >
                                    <v-img
                                    :src="item.team_a_logo"
                                    >
                                    <template v-slot:placeholder>
                                        <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                        >
                                        <v-progress-circular
                                            indeterminate
                                            color="black"
                                        ></v-progress-circular>
                                        </v-row>
                                    </template>
                                    </v-img>
                                </v-avatar>
                                <span class="text-caption">{{ item.team_a_name }} {{ item.team_a_alias }}</span>
                                <span class="text-overline my-2 mx-2 font-italic">vs</span>
                                <v-avatar
                                    size="36px"
                                    tile
                                >
                                    <v-img
                                    :src="item.team_b_logo"
                                    >
                                    <template v-slot:placeholder>
                                        <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                        >
                                        <v-progress-circular
                                            indeterminate
                                            color="black"
                                        ></v-progress-circular>
                                        </v-row>
                                    </template>
                                    </v-img>
                                </v-avatar>
                                <span class="text-caption">{{ item.team_b_name }} {{ item.team_b_alias }}</span>
                            </template>

                            <template v-slot:[`item.bet_amount`]="{ item }">
                                <span>PHP {{ moneyFormat(parseFloat(item.bet_amount)) }} </span>
                            </template>

                            <template v-slot:[`item.prize`]="{ item }">
                                <span>PHP {{ moneyFormat(parseFloat(item.prize)) }} </span>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    props: {
        card_id: {
            type: Number
        },

        game_details: {
            type: Object
        },

        payouts: {
            type: String
        }
    },

    data: () => ({
        headers: [   
            { 
                text: "Bet Type", 
                value: "bet_type", 
                class: "text-uppercase",
                align: "left" 
            },      
            { 
                text: "Bet Amount", 
                value: "bet_amount", 
                class: "text-uppercase",
                align: "right" 
            },
            {
                text: "Name",
                value: "name",
                class: "text-uppercase",
                align: "left" 
            },
            {
                text: "Prize",
                value: "prize",
                class: "text-uppercase",
                align: "right" 
            },
        ],
    }),

    created() {
        this.$store.commit("ending_reports_payouts/setRecordsPayoutsWinners", [])
        this.doGetListOfWinners()
    },

    computed: {
        ...mapState("ending_reports_payouts", ["recordsPayoutsWinners", "recordsPayoutsWinnersLoading"]),

        dialog: {
            get: function() {
                return true
            },
            set: function() {
                this.closeModal()
            }
        }
    },

    methods: {
        ...mapActions("ending_reports_payouts", ["getPayoutsWinners"]),

        setCardPadding(value) {
            return String(value).padStart(6, '0')
        },

        moneyFormat(val) {
            return val.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },

        async doGetListOfWinners() {
            await this.getPayoutsWinners(this.card_id)
        },

        closeModal() {
            this.$emit("setListOfWinnersCloseModal")
        }
    }
}
</script>