<template>
  <v-dialog :persistent="records_loading" v-model="dialog" max-width="1000">
    <v-card class="shadow">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-regular">
        <span>{{ `Verify — ${data.agent}` }}</span>

        <v-btn icon plain @click="close_dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="d-flex flex-column pb-0" style="gap:1rem">
        <div class="d-flex flex-sm-row flex-column justify-space-between" style="gap:1rem">
          <span>Total Commission <strong>{{ `Php ${money_format(parseFloat(total_commission))}` }}</strong></span>

          <v-btn depressed color="primary" @click="set_conirmation_dialog" :disabled="records_loading">
            Verify
          </v-btn>
        </div>
        <v-data-table :headers="headers" :items="records" :loading="records_loading" :items-per-page="5"
          loading-text="Loading... Please wait">

          <template v-slot:[`item.amount`]="{ item }">
            {{ number_format(parseInt(item.amount)) }}
          </template>

          <template v-slot:[`item.commission_percent`]="{ item }">
            {{ `${money_format(parseFloat(item.commission_percent))}%` }}
          </template>

          <template v-slot:[`item.commission_amount`]="{ item }">
            <strong>{{ `Php ${money_format(parseFloat(item.commission_amount))}` }}</strong>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            {{ format_date(item.created_at) }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :class="item.status == 'Pending' ? 'blue-grey lighten-5 blue-grey--text' : item.status == 'Requested' ? 'green lighten-5 green--text' : 'blue lighten-5 blue--text'">
              {{ item.status }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <SpecialCommissionConfirm v-if="confirmation_dialog" :id="parseInt(data.id)"
      @set_close_confirmation_dialog="set_close_confirmation_dialog" />
  </v-dialog>
</template>

<script>
import responseGet from '@/helpers/api_request_get'
import formatDate from '@/helpers/formatDate'
import { mapActions } from 'vuex'
import SpecialCommissionConfirm from '@/components/investors/SpecialCommissionConfirm'

export default {
  components: { SpecialCommissionConfirm },

  props: {
    data: {
      type: Object
    }
  },

  data: () => ({
    records: [],
    records_loading: false,
    confirmation_dialog: false,
    confirmation_data: {},
    headers: [
      {
        text: "Slot",
        value: "slot",
        class: "text-uppercase",
      },
      {
        text: "Bet Amount",
        value: "amount",
        align: "right",
        class: "text-uppercase",
      },
      {
        text: "Commission %",
        value: "commission_percent",
        align: "right",
        class: "text-uppercase",
      },
      {
        text: "Commission",
        value: "commission_amount",
        align: "right",
        class: "text-uppercase",
      },
      {
        text: "Status",
        value: "status",
        class: "text-uppercase",
      },
      {
        text: "Bet Date",
        value: "created_at",
        class: "text-uppercase",
      }
    ]
  }),

  computed: {
    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    },

    total_commission() {
      if (this.records)
        return this.records.reduce((acc, curr) => acc + parseFloat(curr.commission_amount), 0);

      return 0;
    },
  },

  mounted() {
    this.doGetSpecialCommissionRaw()
  },

  methods: {
    ...mapActions("logout", ["sendLogoutRequest"]),

    async doGetSpecialCommissionRaw() {
      this.records_loading = !this.records_loading

      const response = await responseGet(this.set_form_data(), 'investor/special_commission/raw')

      switch (response.status) {
        case 200:
          this.records = response.data.records
          break;
        case 401:
          await this.sendLogoutRequest();
          this.$router.push({ name: "Home" });
          break;
      }

      this.records_loading = !this.records_loading
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('special_settlement_id', this.data.id)

      return new URLSearchParams(form_data)
    },

    set_conirmation_dialog() {
      this.confirmation_dialog = !this.confirmation_dialog
    },

    set_close_confirmation_dialog(status) {
      if (status) this.$emit("set_close_special_commission_verify_dialog", { 'type': true, 'id': this.data.id })
      this.confirmation_dialog = !this.confirmation_dialog
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    format_date(date) {
      return formatDate(date, 'datetime')
    },

    close_dialog() {
      this.$emit("set_close_special_commission_verify_dialog", { 'type': false })
    }
  }
}
</script>