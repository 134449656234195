var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Agents")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Agent List")]),_c('v-card',{staticClass:"shadow"},[_c('v-card-title',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"2","align-self":"end"}},[(this.accountInfo.access.includes(42))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":_vm.newAgent}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,3496068110)},[_c('span',[_vm._v("New Agent")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","color":"secondary"},on:{"click":_vm.doGetAllAgents}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cached")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('v-text-field',{attrs:{"placeholder":"Search","single-line":"","hide-details":"auto","disabled":_vm.loading,"clearable":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"secondary","small":"","disabled":_vm.loading},on:{"click":_vm.showFilter}},on),[_c('v-icon',[_vm._v("mdi-filter-variant")])],1)]}}])},[_vm._v(" Filter ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":5,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading, please wait a moment","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.showAgentInfo(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Update")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","icon":"","color":"primary","disabled":item.is_active == 'Inactive' ? true : false},on:{"click":function($event){return _vm.setAddPlayerModal(item.id, item.mobile_number)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Add Player")])]),(_vm.accountInfo.access.includes(42))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","icon":"","color":"primary","disabled":item.is_active == 'Inactive' ? true : parseInt(item.no_of_player) == 0 ? true : false},on:{"click":function($event){return _vm.setManagePlayerModal(item.id, item.name, item.mobile_number)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-group")])],1)]}}],null,true)},[_c('span',[_vm._v("Manage Agent Player")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","icon":"","color":"primary","disabled":item.is_active == 'Inactive' ? true : false},on:{"click":function($event){return _vm.setResetPasswordModal(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-key-change ")])],1)]}}],null,true)},[_c('span',[_vm._v("Reset Password")])])]}},{key:"item.mobile_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" +63"+_vm._s(item.mobile_number)+" ")]}},{key:"item.no_of_player",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moneyFormat2(parseInt(item.no_of_player)))+" ")]}},{key:"item.commission",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.commission)+"% ")]}},{key:"item.wallet",fn:function(ref){
var item = ref.item;
return [_vm._v(" PHP "+_vm._s(_vm.moneyFormat(parseFloat(item.wallet)))+" ")]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.is_active == 'Active' ? 'green lighten-5 green--text' : 'red lighten-5 red--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.is_active)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.setCreatedAtFormat(item.created_at)))])]}}],null,true)})],1)],1),(_vm.dialog)?_c('CreateUpdate',{attrs:{"agent_id":_vm.agent_id},on:{"setCloseModal":_vm.closeModal}}):_vm._e(),(_vm.filterDialog)?_c('FilterDate',{attrs:{"dates":_vm.date_filter},on:{"setFiltereModal":_vm.closeFilterModal}}):_vm._e(),(_vm.addPlayerDialog)?_c('AddPlayer',{attrs:{"agent_data":_vm.agent_data},on:{"setAddPlayerModal":_vm.closeAddPlayerModal}}):_vm._e(),(_vm.managePlayerDialog)?_c('ManagePlayer',{attrs:{"agent_data":_vm.agent_data},on:{"setManagePlayerModal":_vm.closeManagePlayerModal,"setAddPlayerModal":_vm.setAddPlayerModal}}):_vm._e(),(_vm.resetPasswordDialog)?_c('ResetPassword',{attrs:{"agent_id":_vm.agent_id},on:{"closeResetPasswordModal":_vm.closeResetPasswordModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }