<template>
  <div>
    <v-card class="shadow">
      <v-card-title>
        <v-row no-gutters justify="space-between">
          <v-col cols="auto">
            <span class="text-h6 font-weight-regular grey--text text--darken-2">Monthly Registration Statistics</span>
          </v-col>
          <v-col cols="auto" class="text-right">
            <v-btn
              outlined
              small
              class="text-capitalize font-weight-regular px-2 grey--text text--darken-2"
              @click="filterDialog = true"
              :disabled="playersStatisticsLoading"
            >
              {{ playersStatistics.label }}
              <v-icon small class="ps-1">mdi-chevron-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row dense v-if="playersStatistics.players_monthly_average">
          <v-col class="my-0">
            <span>Monthly Average <span style="color: #4CAF50">{{ moneyFormat(parseInt(playersStatistics.players_monthly_average)) }}</span></span><br/>
            <span>Lowest <span v-if="!playersStatisticsLoading" style="color: #4CAF50">{{ moneyFormat(parseFloat(lowest)) }}</span></span><br/>
            <span>Highest <span v-if="!playersStatisticsLoading" style="color: #4CAF50">{{ moneyFormat(parseFloat(highest)) }}</span></span>
          </v-col>
        </v-row>

        <div v-if="playersStatisticsLoading" class="text-center">
          <v-progress-circular
            :size="70"
            :width="5"
            color="secondary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <LineChart :theChartData="playersStatistics" />
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
      ref="filterDialog"
      v-model="filterDialog"
      persistent
      width="290px"
    >
      <v-date-picker
        v-model="year"
        :active-picker.sync="activePicker"
        full-width
        reactive
        no-title
        @click:year="saveFilter"
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="cancelFilter"
        >
          Cancel
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import formatDatev2 from "@/helpers/formatDatev2"
import LineChart from "@/components/dashboard/registered_players/RegisteredPlayersLineChart.vue"

export default {
  components: { LineChart },
  data: () => ({
    year: formatDatev2((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 'format', 'yyyy'),
    filterDialog: false,
    activePicker: null
  }),
  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("dashboard", [
      "playersStatistics",
      "playersStatisticsLoading"
    ]),

    lowest() {
      const arr = Object.values(this.playersStatistics.players_count)
      const filtered_array = arr.filter(val => val != 0)
      
      if (filtered_array.length != 0) return Math.min(...filtered_array)

      return 0
    },

    highest() {
      const arr = Object.values(this.playersStatistics.players_count)
      return Math.max(...arr)
    }
  },
  mounted() {
    if (this.accountInfo.access.includes(21)) {
      this.doGetData()
    }
  },
  methods: {
    ...mapActions("dashboard", [
      "getPlayersStatistics"
    ]),
    cancelFilter() {
      this.filterDialog = false
    },
    saveFilter() {
      const expYear = this.year.split("-")
      this.year = expYear[0]
      this.$refs.filterDialog.save(this.year)
      this.doGetData()
    },
    async doGetData() {
      if (!this.playersStatisticsLoading) {
        this.$store.commit("dashboard/setPlayersStatistics", [])

        const formData = new FormData()
        formData.append('val', this.year)
        formData.append('type', 'Monthly')

        const getData = new URLSearchParams(formData)

        await this.getPlayersStatistics(getData)
      }
    },
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    },
  },

  watch: {
    filterDialog (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  }
}
</script>