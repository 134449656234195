<template>
    <v-dialog v-model="dialog" max-width="600">
        <v-card :loading="loading">
            <v-card-title class="text-h5"> Reset Password 
                <v-btn icon absolute right @click="setSuccessUpdate">
                    <v-icon size="large">
                    mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="py-4">
                <v-form ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="handleSubmit">

                    <v-row dense class="mt-2">
                        <v-col>
                            <v-text-field
                                ref="new_password"
                                outlined
                                dense
                                label="Password"
                                :rules="(submitStatus) ? [rules.required, rules.minimum(3), rules.strongPassword, newPasswordExist] : []"
                                v-model="new_password"
                                :append-icon="passwordVisibility ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="passwordVisibility ? 'text' : 'password'"
                                @click:append="passwordVisibility = !passwordVisibility"
                                hide-details="auto"/>
                        </v-col>
                    </v-row>

                    <v-row dense class="mt-3">
                        <v-col>
                            <v-text-field
                                ref="confirm_password"
                                outlined
                                dense
                                label="Confirm Password"
                                :rules="(submitStatus) ? [rules.required, rules.minimum(3), rules.strongPassword, passwordConfirmationRule, confirmPasswordExist] : []"
                                v-model="confirm_password"
                                :append-icon="passwordVisibility2 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="passwordVisibility2 ? 'text' : 'password'"
                                @click:append="passwordVisibility2 = !passwordVisibility2"
                                hide-details="auto"/>
                        </v-col>
                    </v-row>

                    <v-row dense class="mt-3">
                        <v-col cols="12" md="6">
                            <v-btn block depressed color="primary" class="ma-1" type="submit">
                                Submit
                            </v-btn>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-btn block depressed class="ma-1" @click="setSuccessUpdate">
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import formRules from "@/helpers/formRules"
import { mapState, mapActions } from 'vuex';

export default {
    props: {
        userId: {
            type: String,
        },
    },
    data: () => ({
        valid: true,
        rules: formRules,
        id: "",
        new_password: '',
        confirm_password: '',
        newPasswordExistText: '',
        confirmPasswordExistText: '',
        passwordVisibility: false,
        passwordVisibility2: false,
        submitStatus: false
    }),
    mounted() {
        this.$refs.new_password.focus()
    },
    computed: {
        ...mapState("ending_users", ["loading", "error", "errors"]),
        dialog: {
            get: function () {
                return true;
            },
            set: function () {
                this.setSuccessUpdate();
            },
        },
        passwordConfirmationRule() {
            return () => this.new_password === this.confirm_password || "Password must match"
        }
    },
    methods: {
        ...mapActions("ending_users", ["resetPassword"]),
        async handleSubmit() {
            this.submitStatus = true

            await new Promise(r => setTimeout(r, 200));

            if (this.$refs.form.validate()) {
                if(!this.loading) {
                    await this.resetPassword(this.setFormData())

                     if (this.error) {
                         this.newPasswordExistText = this.new_password

                         this.$refs.form.validate();
                         this.$refs.new_password.focus();
                     } else if(!this.isObjectEmpty(this.errors)) {
                         this.setErrors()
                     } else {
                        this.setSuccessUpdate()
                     }
                }
            }
        },
        setErrors() {
            let errorFocus = "";

            Object.entries(this.errors).forEach(([key, value]) => {
                if (value) {
                    if (!errorFocus) {
                        errorFocus = key;
                    }

                    switch (key) {
                        case "confirm_password":
                        this.confirmPasswordExistText = this.confirm_password;
                        break;
                    }

                    this.$refs.form.validate();
                    this.$refs[errorFocus].focus();
                }
            });
        },
        newPasswordExist(val) {
            if (val == this.newPasswordExistText) {
                return "The new password must not equal the old password.";
            } else return true;
        },
        confirmPasswordExist(val) {
            if (val == this.confirmPasswordExistText) {
                return "The field does not match the password field.";
            } else return true;
        },
        isObjectEmpty(obj) {
            return Object.keys(obj).length === 0;
        },
        setFormData() {
            const formData = new FormData()

            formData.append("user_id", this.userId)
            formData.append("password", this.new_password)
            formData.append("confirm_password", this.confirm_password)

            return formData
        },
        setSuccessUpdate() {
            this.$emit("successChange")
        }
    }
}
</script>