<template>
  <div class="d-inline-block">
    <span class="me-5 text-overline">{{ (switchStatus) ? 'Enabled' : 'Disabled' }}</span>
    <v-switch
      class="my-0 py-0 d-inline-block"
      dense
      v-model="switchStatus"
      hide-details="true"
      @click.native.prevent.stop.capture="changeConfirm"
    ></v-switch>

    <v-dialog
      v-model="dialog"
      max-width="350"
    >
      <v-card class="py-2">
        <v-card-title class="text-h6 d-block text-center">
          Confirm Betting
        </v-card-title>
        <v-card-text class="text-center">
          Do you really want to {{ (switchStatus) ? 'disable' : 'enable' }} betting?
        </v-card-text>

        <v-card-actions class="d-block text-center">
          <v-btn
            ref="yes"
            color="primary"
            outlined
            small
            @click="handleClick"
            :loading="bettingLoading"
          >
            Yes
          </v-btn>

          <v-btn
            color="black"
            outlined
            small
            @click="dialog = false"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"

export default {
  props: {
    compData: { // Component Data
      type: Object,
      required: true
    }
  },
  data: () => ({
    switchStatus: false,
    dialog: false
  }),
  computed: {
    ...mapState("ending_game_details", ["bettingError", "bettingLoading"])
  },
  methods: {
    ...mapActions("ending_game_details", ["bettingSetting"]),
    changeConfirm() {
      this.dialog = true
    },
    async handleClick() {
      if (!this.bettingLoading) {
        const formData = new FormData()
        formData.append('game_id', this.compData.id)
        formData.append('betting', !this.switchStatus)

        await this.bettingSetting(formData)

        if (!this.bettingError) {
          this.dialog = false

          this.switchStatus = !this.switchStatus

          // no need to emit the new status
        }
      }
    }
  },

  watch: {
    compData(val) {
      if (val) {
        this.switchStatus = (val.betting=='Enabled') ? true : false
      }
    },
    dialog(visible) {
      if (visible) {
        setTimeout(() => {
          this.$refs.yes.$el.focus()
        }, 200);
      }
    }
  }
}
</script>