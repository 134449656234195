<template>
	<div class="pa-4 pa-md-6">
		<div class="grey--text" style="font-size: 13px">Settings</div>
		<h2 class="font-weight-bold mb-3 mb-md-5">Active Users</h2>
		<v-card class="shadow">
			<v-card-title>
				<v-row justify="space-between">
					<v-col cols="12" sm="6" md="4" xl="2" align-self="end">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon color="secondary" @click="loadRecords" v-bind="attrs" v-on="on" class="mr-1">
									<v-icon>mdi-cached</v-icon>
								</v-btn>
							</template>

							<span>Refresh</span>
						</v-tooltip>
					</v-col>

					<v-col cols="12" sm="6" md="4" xl="3">
						<v-text-field
							v-model="search"
							prepend-inner-icon="mdi-magnify"
							placeholder="Search"
							single-line
							hide-details="auto"
							:disabled="recordsLoading"
							clearable
							outlined
							dense />
					</v-col>
				</v-row>
			</v-card-title>

			<v-divider></v-divider>

			<v-card-text class="py-4">
				<v-data-table
					:headers="headers"
					:items="records"
					:items-per-page="5"
					:search="search"
					:loading="recordsLoading"
					loading-text="Loading, please wait a moment"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc">
					<template v-slot:[`item.last_login`]="{ item }">
						<span>{{ setCreatedAtFormat(item.last_login) }}</span>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import formatDatev2 from '@/helpers/formatDatev2'

export default {
	data: () => ({
		search: '',
		headers: [
			{
				text: 'Last Login',
				value: 'last_login',
				class: 'text-uppercase',
			},
			{
				text: 'Name',
				value: 'full_name',
				class: 'text-uppercase',
			},
			{
				text: 'Mobile Number',
				value: 'mobile_number',
				class: 'text-uppercase',
			},
			{
				text: 'Email',
				value: 'email_address',
				class: 'text-uppercase',
			},
		],
		sortBy: 'created_at',
		sortDesc: true,
		createUpdateDialog: false,
		records: [],
		recordsLoading: false,
		data: {},
		apiURL: process.env.VUE_APP_API_URL,
	}),

	computed: {
		...mapState(['userToken']),
	},

	mounted() {
		this.loadRecords()
	},

	methods: {
		...mapMutations(['setError', 'setRefreshError']),

		async loadRecords() {
			this.records = []
			try {
				this.recordsLoading = true
				const response = await fetch(`${this.apiURL}users/active`, {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${this.userToken}`,
					},
				})

				const res = await response.json()
				this.records = res
				if (!response.ok) {
					throw { response, res }
				}
			} catch (error) {
				const errorStatus = error.response.status

				if (errorStatus == 401) {
					this.setError('Unauthorized', { root: true })
				} else {
					this.setRefreshError('Submitted invalid data', { root: true })
				}
			} finally {
				this.recordsLoading = false
			}
		},

		setCreatedAtFormat(date) {
			return formatDatev2(date)
		},
	},
}
</script>
