<template>
  <div class="d-inline-block ma-1">
    <v-btn
      small
      color="primary"
      outlined
      @click.stop="dialog = true"
    >
      Create Bet
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="800"      
    >
      <v-card :loading="prizeConfigurationLoading">
        <v-card-title class="text-h5">
          Create Bet

          <v-btn icon absolute right @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider/>

        <v-card-text
          class="py-4"
        >
          <v-form
            ref="form"
            v-model="valid"
            :disabled="prizeConfigurationLoading"
            lazy-validation
            @submit.prevent="handleSubmit"
          >
            <v-row>
              <v-col cols="12" md="6">
                <v-autocomplete
                  ref="bet_amount"
                  v-model="bet_amount"
                  :items="bet_amounts"
                  :rules="[rules.required]"
                  label="Bet Amount"
                  placeholder="Enter the amount"
                  dense
                  outlined
                  hide-details="auto"
                  @change="setCashPrizes"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="card_count"
                  :rules="[rules.required]"
                  label="Card Count"
                  type="number"
                  placeholder="Enter the card count"
                  dense
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  v-model="bet_type"
                  :rules="[rules.required]"
                  :items="bet_types"
                  label="Bet Type"
                  @change="setCashPrizes"
                  dense
                  outlined
                  hide-details="auto"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0">
                <p class="ma-0">1st Quarter</p>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="a_quarter_prize_type"
                  :rules="[rules.required]"
                  :items="prize_types"
                  label="Prize Type"
                  dense
                  outlined
                  hide-details="auto"
                  @change="setCashPrizes"
                ></v-select>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field v-if="a_quarter_prize_type=='Cash'"
                  v-model="a_quarter_prize_amount"
                  :rules="[rules.required]"
                  label="Prize Amount"
                  type="number"
                  placeholder="Enter the amount"
                  dense
                  outlined
                  hide-details="auto"
                ></v-text-field>
                <v-text-field v-else
                  v-model="a_quarter_prize"
                  :rules="[rules.required]"
                  label="Prize"
                  placeholder="Enter the prize"
                  dense
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pb-0">
                <p class="ma-0">2nd Quarter</p>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="b_quarter_prize_type"
                  :rules="[rules.required]"
                  :items="prize_types"
                  label="Prize Type"
                  dense
                  outlined
                  hide-details="auto"
                  @change="setCashPrizes"
                ></v-select>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field v-if="b_quarter_prize_type=='Cash'"
                  v-model="b_quarter_prize_amount"
                  :rules="[rules.required]"
                  label="Prize Amount"
                  type="number"
                  placeholder="Enter the amount"
                  dense
                  outlined
                  hide-details="auto"
                ></v-text-field>
                <v-text-field v-else
                  v-model="b_quarter_prize"
                  :rules="[rules.required]"
                  label="Prize"
                  placeholder="Enter the prize"
                  dense
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pb-0">
                <p class="ma-0">3rd Quarter</p>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="c_quarter_prize_type"
                  :rules="[rules.required]"
                  :items="prize_types"
                  label="Prize Type"
                  dense
                  outlined
                  hide-details="auto"
                  @change="setCashPrizes"
                ></v-select>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field v-if="c_quarter_prize_type=='Cash'"
                  v-model="c_quarter_prize_amount"
                  :rules="[rules.required]"
                  label="Prize Amount"
                  type="number"
                  placeholder="Enter the amount"
                  dense
                  outlined
                  hide-details="auto"
                ></v-text-field>
                <v-text-field v-else
                  v-model="c_quarter_prize"
                  :rules="[rules.required]"
                  label="Prize"
                  placeholder="Enter the prize"
                  dense
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pb-0">
                <p class="ma-0">4th Quarter {{ d_quarter_prize_type }}</p>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="d_quarter_prize_type"
                  :rules="[rules.required]"
                  :items="prize_types"
                  label="Prize Type"
                  dense
                  outlined
                  hide-details="auto"
                  @change="setCashPrizes"
                ></v-select>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field v-if="d_quarter_prize_type=='Cash'"
                  v-model="d_quarter_prize_amount"
                  :rules="[rules.required]"
                  label="Prize Amount"
                  type="number"
                  placeholder="Enter the amount"
                  dense
                  outlined
                  hide-details="auto"
                ></v-text-field>
                <v-text-field v-else
                  v-model="d_quarter_prize"
                  :rules="[rules.required]"
                  label="Prize"
                  placeholder="Enter the prize"
                  dense
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense justify="end">
              <v-col cols="auto" class="mt-2">
                <v-btn
                  color="black"
                  outlined
                  @click="dialog = false"
                >
                  Cancel
                </v-btn>
              </v-col>

              <v-col cols="auto" class="mt-2">
                <v-btn
                  :disabled="!valid"
                  type="submit"
                  color="primary"
                  :loading="createCardLoading"
                >
                  Create
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <createCardDialog v-if="createCardDialogStatus" :createCardData="createCardData" @setCloseDialog="setCloseDialog"/>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import formRules from '@/helpers/formRules'
import createCardDialog from "@/components/ending/scheduledGames/CreateCardDialog"

export default {
  props: {
    game_id: {
      required: true
    }
  },
  components: {
    createCardDialog
  },
  data: () => ({
    dialog: false,
    valid: true,
    rules: formRules,
    bet_amount: 0,
    bet_amount_added: 0,
    bet_amounts: [],
    card_count: '',
    bet_types: [
      { value: 2, text: "Per Quarter" },
      { value: 1, text: "Traditional" }
    ],
    bet_type: 2,
    prize_types: ["Cash", "Not Cash"],
    a_quarter_prize_type: "Cash",
    a_quarter_prize_amount: '',
    a_quarter_prize: '',
    b_quarter_prize_type: "Cash",
    b_quarter_prize_amount: '',
    b_quarter_prize: '',
    c_quarter_prize_type: "Cash",
    c_quarter_prize_amount: '',
    c_quarter_prize: '',
    d_quarter_prize_type: "Cash",
    d_quarter_prize_amount: '',
    d_quarter_prize: '',
    createCardData: {},
    createCardDialogStatus: false
  }),
  created() {
    this.requestPrizeConfiguration()
  },
  computed: {
    ...mapState("ending_game_details", ["prizeConfiguration", "betAmountConfigs", "prizeConfigurationLoading", "createCardError", "createCardLoading"]),
  },
  methods: {
    ...mapActions("ending_game_details", ["createCard", "getPrizeConfiguration"]),
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        // console.log((this.bet_type == 1) ? this.bet_amount : this.bet_amount_added)
        // const formData = new FormData()
        // formData.append('game_id', this.game_id)
        // formData.append('bet_amount', (this.bet_type == 1) ? this.bet_amount : this.bet_amount_added)
        // formData.append('card_count', this.card_count)
        // formData.append('bet_type', this.bet_type)
        // formData.append('a_quarter_prize_type', this.a_quarter_prize_type)
        // formData.append('a_quarter_prize_amount', this.a_quarter_prize_amount)
        // formData.append('a_quarter_prize', this.a_quarter_prize)
        // formData.append('b_quarter_prize_type', this.b_quarter_prize_type)
        // formData.append('b_quarter_prize_amount', this.b_quarter_prize_amount)
        // formData.append('b_quarter_prize', this.b_quarter_prize)
        // formData.append('c_quarter_prize_type', this.c_quarter_prize_type)
        // formData.append('c_quarter_prize_amount', this.c_quarter_prize_amount)
        // formData.append('c_quarter_prize', this.c_quarter_prize)
        // formData.append('d_quarter_prize_type', this.d_quarter_prize_type)
        // formData.append('d_quarter_prize_amount', this.d_quarter_prize_amount)
        // formData.append('d_quarter_prize', this.d_quarter_prize)

        this.createCardData = {
          'game_id': this.game_id,
          'bet_amount': (this.bet_type == 1) ? this.bet_amount : this.bet_amount_added,
          'card_count': this.card_count,
          'bet_type': this.bet_type,
          'a_quarter_prize_type': this.a_quarter_prize_type,
          'a_quarter_prize_amount': this.a_quarter_prize_amount,
          'a_quarter_prize': this.a_quarter_prize,
          'b_quarter_prize_type': this.b_quarter_prize_type,
          'b_quarter_prize_amount': this.b_quarter_prize_amount,
          'b_quarter_prize': this.b_quarter_prize,
          'c_quarter_prize_type': this.c_quarter_prize_type,
          'c_quarter_prize_amount': this.c_quarter_prize_amount,
          'c_quarter_prize': this.c_quarter_prize,
          'd_quarter_prize_type': this.d_quarter_prize_type,
          'd_quarter_prize_amount': this.d_quarter_prize_amount,
          'd_quarter_prize': this.d_quarter_prize
        }

        this.createCardDialogStatus = true

        // if (!this.createCardLoading) {
        //   await this.createCard(formData)

        //   if (!this.createCardError) {
        //     this.$refs.form.reset()

        //     this.dialog = false

        //     this.$emit('created')
        //   }
        // }
      }
    },

    setCashPrizes() {
      if (this.bet_amount != null) {        
        if (this.bet_type == 1) { // Traditional
          if (this.a_quarter_prize_type == 'Cash') {
            this.a_quarter_prize_amount = this.prizeResult('Traditional Card - 1st Quarter Prize Percent')
          } else {
            this.a_quarter_prize_amount = ''
          }

          if (this.b_quarter_prize_type == 'Cash') {
            this.b_quarter_prize_amount = this.prizeResult('Traditional Card - 2nd Quarter Prize Percent')
          } else {
            this.b_quarter_prize_amount = ''
          }

          if (this.c_quarter_prize_type == 'Cash') {
            this.c_quarter_prize_amount = this.prizeResult('Traditional Card - 3rd Quarter Prize Percent')
          } else {
            this.c_quarter_prize_amount = ''
          }
          
          if (this.d_quarter_prize_type == 'Cash') {
            this.d_quarter_prize_amount = this.prizeResult('Traditional Card - 4th Quarter Prize Percent')
          } else {
            this.d_quarter_prize_amount = ''
          }
        } else { // Per Quarter
          if (this.a_quarter_prize_type == 'Cash') {
            this.a_quarter_prize_amount = this.prizeResult('Per Quarter Card - 1st Quarter Prize Percent')
          } else {
            this.a_quarter_prize_amount = ''
          }

          if (this.b_quarter_prize_type == 'Cash') {
            this.b_quarter_prize_amount = this.prizeResult('Per Quarter Card - 2nd Quarter Prize Percent')
          } else {
            this.b_quarter_prize_amount = ''
          }

          if (this.c_quarter_prize_type == 'Cash') {
            this.c_quarter_prize_amount = this.prizeResult('Per Quarter Card - 3rd Quarter Prize Percent')
          } else {
            this.c_quarter_prize_amount = ''
          }

          if (this.d_quarter_prize_type == 'Cash') {
            this.d_quarter_prize_amount = this.prizeResult('Per Quarter Card - 4th Quarter Prize Percent')
          } else {
            this.d_quarter_prize_amount = ''
          }
        }
      } else {
        this.a_quarter_prize_amount = ''
        this.b_quarter_prize_amount = ''
        this.c_quarter_prize_amount = ''
        this.d_quarter_prize_amount = ''
      }
    },
    prizePercentage(val) {
      const config = this.prizeConfiguration.find(
          (record) => record.config_name == val
        );

      return config.config_value
    },
    percentageResult(percentage) {
      return percentage / 100
    },
    grossPrize() {
      return this.bet_amount * 100
    },
    prizeResult(config_value) {
      return this.percentageResult(this.prizePercentage(config_value) * this.grossPrize())
    },
    async requestPrizeConfiguration() {
      await this.getPrizeConfiguration()
    },

    setCloseDialog(val) {
      if (val) {
        this.$refs.form.reset()
        this.dialog = false
        this.$emit('created')
      } 

      this.createCardDialogStatus = false
    }
  },
  watch: {
    async dialog(visible) {
      if (visible) {
        this.bet_amount = this.betAmountConfigs[0].amount
        this.betAmountConfigs.forEach(element => this.bet_amounts.push(element.amount));
        this.bet_type = 2
        this.a_quarter_prize_type = "Cash"
        this.b_quarter_prize_type = "Cash"
        this.c_quarter_prize_type = "Cash"
        this.d_quarter_prize_type = "Cash"

        this.setCashPrizes()

        setTimeout(() => {
          this.$refs.form.resetValidation()

          this.$refs.bet_amount.focus()
        }, 200);
      }
    },
    bet_amount(val) {
      if(val && this.bet_type == 2) {
        this.betAmountConfigs.forEach(element => {
          if (element.amount == val) {
            this.bet_amount_added = element.amount_added
          }
        });
      }
    },
    bet_type(val) {
      if(val == 2 && this.bet_amount) {
        this.betAmountConfigs.forEach(element => {
          if (element.amount == this.bet_amount) {
            this.bet_amount_added = element.amount_added
          }
        });
      }
    }
  }
}
</script>