<template>
  <div>
    <v-card class="shadow">
      <v-card-title>
        <v-row no-gutters justify="space-between">
          <v-col cols="auto">
            <span class="text-h6 font-weight-regular grey--text text--darken-2">Top 10 Gross<small class="caption">(Combination)</small></span>
          </v-col>
          <v-col cols="auto" class="text-right">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  outlined
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="text-capitalize font-weight-regular px-2 grey--text text--darken-2"
                  :disabled="topGrossCombinationLoading"
                >
                  {{ filter }}
                  <v-icon small class="ps-1">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="item in filters"
                  :key="item"
                  @click="changeFilter(item)"
                  :class="(item==filter) ? 'primary--text' : ''"
                >
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <div class="text-center py-3" v-if="topGrossCombinationLoading">
          <v-progress-circular
            indeterminate
            color="secondary"
          ></v-progress-circular>
        </div>

        <div v-else>
          <div class="text-center py-3" v-if="!topGrossCombination.length">
            <span class="secondary--text">No record found</span>
          </div>
          <v-list class="py-0" v-else>
            <v-list-item v-for="(item, index) in topGrossCombination" :key="item.slot">
              <v-list-item-content>
                <v-list-item-action-text>
                  <span class="caption">{{ parseInt(item.bet_amount).toLocaleString() }} Token{{ (parseInt(item.bet_amount) > 1) ? 's' : '' }}</span>
                </v-list-item-action-text>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-title>
                  <span class="caption secondary--text">Top {{ index + 1 }}</span> <v-chip class="me-3  primary--text" small>{{ item.slot }}</v-chip>
                </v-list-item-title>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
      ref="filterDialog"
      v-model="filterDialog"
      :return-value.sync="filterDate"
      persistent
      width="290px"
    >
      <v-date-picker
        v-model="filterDate"
        scrollable
        range
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="cancelFilter"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="saveFilter"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import formatDatev2 from "@/helpers/formatDatev2"
import { startOfWeek } from 'date-fns'
import { endOfWeek } from 'date-fns'
import { startOfMonth } from 'date-fns'
import { endOfMonth } from 'date-fns'
import { startOfYear } from 'date-fns'
import { endOfYear } from 'date-fns'

export default {
  data: () => ({
    today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    filter: 'Today',
    // selectedFilter: 0,
    filters: ['Today','This Week','This Month','This Year','Custom'],
    filterFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    filterTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    filterDialog: false,
    filterDate: [],
    filterDateText: ''
  }),
  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("dashboard", [
      "topGrossCombination",
      "topGrossCombinationLoading"
    ])
  },
  mounted() {
    if (this.accountInfo.access.includes(21)) {
      this.doGetData()
    }
  },
  methods: {
    ...mapActions("dashboard", [
      "getTopGrossCombination"
    ]),
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    changeFilter(val) {
      if (val=='Today') {
        this.filterFrom = this.today
        this.filterTo = this.today
        this.filter = 'Today'
        this.filterDate = []
        this.doGetData()
      }

      if (val=='This Week') {
        const startWeek = startOfWeek(new Date(this.today), { weekStartsOn: 1 }),
        endWeek = endOfWeek(new Date(this.today), { weekStartsOn: 1 })

        this.filterFrom = formatDatev2(startWeek, 'format', 'yyyy-MM-dd')
        this.filterTo = formatDatev2(endWeek, 'format', 'yyyy-MM-dd')
        this.filter = 'This Week'
        this.filterDate = []
        this.doGetData()
      }

      if (val=='This Month') {
        const startMonth = startOfMonth(new Date(this.today)),
        endMonth = endOfMonth(new Date(this.today))

        this.filterFrom = formatDatev2(startMonth, 'format', 'yyyy-MM-dd')
        this.filterTo = formatDatev2(endMonth, 'format', 'yyyy-MM-dd')
        this.filter = 'This Month'
        this.filterDate = []
        this.doGetData()
      }
       
      if (val=='This Year') {
        const startYear = startOfYear(new Date(this.today)),
        endYear = endOfYear(new Date(this.today))

        this.filterFrom = formatDatev2(startYear, 'format', 'yyyy-MM-dd')
        this.filterTo = formatDatev2(endYear, 'format', 'yyyy-MM-dd')
        this.filter = 'This Year'
        this.filterDate = []
        this.doGetData()
      }
      
      if (val=='Custom') {
        this.filterDialog = true
      }
    },
    cancelFilter() {
      this.filterDialog = false
    },
    saveFilter() {
      if (!this.filterDate.length) {
        // nothing to do
      } else {
        this.$refs.filterDialog.save(this.filterDate)

        const minDate = new Date(this.filterDate[0])

        if (typeof this.filterDate[1] != 'undefined') {
          const maxDate = new Date(this.filterDate[1])

          if (minDate > maxDate) {
            this.filterFrom = formatDatev2(maxDate, 'format', 'yyyy-MM-dd')
            this.filterTo = formatDatev2(minDate, 'format', 'yyyy-MM-dd')
          } else {
            this.filterFrom = formatDatev2(minDate, 'format', 'yyyy-MM-dd')
            this.filterTo = formatDatev2(maxDate, 'format', 'yyyy-MM-dd')
          }

          this.filterDateText = formatDatev2(this.filterFrom, 'date') + ' to ' + formatDatev2(this.filterTo, 'date')

          this.doGetData()
        } else {
          this.filterFrom = formatDatev2(minDate, 'format', 'yyyy-MM-dd')
          this.filterTo = formatDatev2(minDate, 'format', 'yyyy-MM-dd')
          this.filterDateText = formatDatev2(this.filterFrom, 'date')
          this.doGetData()
        }

        this.filter = 'Custom'
      }
    },
    async doGetData() {
      if (!this.topGrossCombinationLoading) {
        this.$store.commit("dashboard/setTopGrossCombination", null)

        const formData = new FormData()
        formData.append('date_from', this.filterFrom)
        formData.append('date_to', this.filterTo)

        const getData = new URLSearchParams(formData)

        await this.getTopGrossCombination(getData)
      }
    },
  }
}
</script>