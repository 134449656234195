<template>
    <v-dialog v-model="dialog" max-width="500px">
        <v-card>
            <v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
                UPDATE RESULT

                <v-btn icon absolute right @click="closeModal">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text class="py-4">
                <v-form 
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    :disabled="updateResultLoading"
                    @submit.prevent="doUpdate"
                >
                    <v-row>
                    <v-col cols="12">
                        <v-text-field
                        ref="teamascore"
                        v-model="teamascore"
                        :rules="[rules.required]"
                        :label="result_data.team_a + ' ' + availableQuarter[0].text +' '+((parseInt(availableQuarter[0].value) < 5) ? 'Quarter' : '')+' Score'"
                        outlined
                        hide-details="auto"
                        dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                        v-model="teambscore"
                        :rules="[rules.required]"
                        :label="result_data.team_b + ' ' + availableQuarter[0].text +' '+((parseInt(availableQuarter[0].value) < 5) ? 'Quarter' : '')+' Score'"
                        outlined
                        hide-details="auto"
                        dense
                        ></v-text-field>
                    </v-col>
                    </v-row>
                    <div class="mt-4 text-right">
                    <v-btn
                        color="black"
                        outlined
                        class="me-2"
                        @click="dialog = false"
                        :disabled="updateResultLoading"
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                        :disabled="isDefaultValue"
                        type="submit"
                        color="primary"
                        outlined
                        :loading="updateResultLoading"
                    >
                        UPDATE
                    </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialogError" max-width="300">
            <v-card class="py-2">
                <v-card-title class="text-h6 d-block text-center">
                Update Failed
                </v-card-title>

                <v-divider/>

                <v-card-text class="py-4 text-center red--text">
                    Update is no longer available.
                </v-card-text>

                <v-card-actions class="d-block text-center">
                <v-btn
                    ref="gotIt"
                    block
                    color="primary"
                    outlined
                    @click="closeModal"
                >
                    GOT IT!
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import formRules from '@/helpers/formRules'
import gameQuarters from '@/data/gameQuarters.json'
import { mapActions, mapState } from 'vuex';

export default {
    props: {
        result_data: {
            type: Object
        }
    },

    data: () => ({
        valid: true,
        rules: formRules,
        gameQuarters: gameQuarters,
        teamascore: '',
        teambscore: '',
        default_a_score: '',
        default_b_score: '',
        updatedData: {},
        dialogError: false,
    }),

    computed: {
        ...mapState("ending_game_details", ["updateResultLoading", "updateResult", "updateResultError"]),

        dialog: {
            get: function () {
                return true;
            },
            set: function () {
                this.closeModal()
            },
        },

        availableQuarter() {
            if (this.result_data) {
                return this.gameQuarters.filter((quarter) => {
                    return quarter.value == this.result_data.quarter_id
                })
            } else {
                return this.gameQuarters
            }
        },

        isDefaultValue() {
            if (this.default_a_score == this.teamascore && this.default_b_score == this.teambscore) {
                return true
            }

            return false
        }
    },

    mounted() {
        if (Object.keys(this.result_data).length) {
            this.teamascore = this.result_data.team_a_score
            this.teambscore = this.result_data.team_b_score
            this.default_a_score = this.result_data.team_a_score
            this.default_b_score = this.result_data.team_b_score

            this.$refs.teamascore.focus()
        }
    },

    methods: {
        ...mapActions("ending_game_details", ["updateGameResult"]),

        async doUpdate() {
            if (this.$refs.form.validate()) {
                await this.updateGameResult(this.setFormData())

                if (this.updateResultError != null) {
                    this.dialogError = true
                } else {
                    this.updatedData = {
                        "result_id": this.result_data.result_id,
                        "quarter_id": this.updateResult.quarter_id,
                        "team_a_score": this.updateResult.team_a_score,
                        "team_b_score": this.updateResult.team_b_score,
                        "combination": this.updateResult.combination ,
                    }
                    this.$emit("setCloseUpdateResultModal", this.updatedData)
                }
            }
        },

        setFormData() {
            const formData = new FormData()

            formData.append("game_id", this.result_data.game_id)
            formData.append("result_id", this.result_data.result_id)
            formData.append("quarter_id", this.result_data.quarter_id)
            formData.append("teamascore", this.teamascore)
            formData.append("teambscore", this.teambscore)

            return formData
        },

        closeModal() {
            this.updatedData = {}
            this.$emit("setCloseUpdateResultModal")
        }
    },

    watch: {
        dialogError(val) {
            if (val) {
                setTimeout(() => {
                    this.$refs.gotIt.$el.focus()
                }, 200);
            }
        }
    }
}
</script>