<template>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    theChartData: {
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        labels: this.theChartData.labels,
        datasets: [
          {
            label: "Players",
            type: "bar",
            data: this.theChartData.players_count,
            hidden: false,
            backgroundColor: "rgba(232,245,233,.3)",
            pointBackgroundColor: "#FFFFFF",
            borderColor: "#4CAF50 ",
            borderWidth: 1,
            borderRadius: 3,
            borderSkipped: false,
            fill: true,
          },
          {
            label: "Target",
            type: "bar",
            data: this.theChartData.target,
            hidden: false,
            backgroundColor: "rgba(207, 216, 220,.3)",
            pointBackgroundColor: "#FFFFFF",
            borderColor: "#CFD8DC ",
            borderWidth: 1,
            borderRadius: 3,
            borderSkipped: false,
            fill: true,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        lineTension: .5,
        plugins: {
          datalabels: {
            display: false,
          }
        }
      }
    }
  }
}
</script>
