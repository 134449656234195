<template>
  <div>
    <v-card class="shadow">
      <div class="d-flex justify-space-between align-center bs-py-3 bs-px-4">
        <h3>Players Statistics</h3>
        <div class="d-flex" style="gap: 1rem">
          <v-menu offset-y left open-on-hover transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                :disabled="playersNewStatisticsLoading"
                class="text-uppercase px-4"
              >
                {{ filter }}
                <v-icon size="22" right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in filters"
                :key="item"
                @click="changeFilter(item)"
                :class="item == filter ? 'v-list-item--active primary--text' : ''"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <v-divider></v-divider>

      <div class="pa-6">
        <v-row>
          <v-col cols="12">
            <div class="mb-5">
              YTD Moving Target:
              <span>{{ moneyFormat(parseInt(target_registration_as_of)) }}</span>
            </div>
            <v-row>
              <v-col cols="12" md="6">
                <div class="d-flex align-center" style="gap: 1rem">
                  <v-avatar size="60" color="purple lighten-5 ma-0" tile class="rounded">
                    <v-icon color="purple"> mdi-bullseye-arrow </v-icon>
                  </v-avatar>
                  <div>
                    <div class="grey--text" style="font-size: 13px; margin-top: 2px">
                      Target
                    </div>
                    <h3 class="black--text">
                      {{ moneyFormat(parseInt(target)) }}
                    </h3>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="d-flex align-center" style="gap: 1rem">
                  <v-avatar class="rounded" size="60" color="pink lighten-5 ma-0" tile>
                    <v-icon color="pink"> mdi-arrow-top-right-bold-outline </v-icon>
                  </v-avatar>
                  <div>
                    <div class="grey--text" style="font-size: 13px; margin-top: 2px">
                      Registered
                    </div>
                    <h3 class="black--text">
                      {{ moneyFormat(parseInt(registered)) }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <small
                            v-bind="attrs"
                            v-on="on"
                            class="green--text"
                            style="
                              font-weight: normal;
                              font-size: 12px;
                              padding-left: 4px;
                            "
                          >
                            {{
                              setPercentageValue(registered, target_registration_as_of)
                            }}%
                          </small>
                        </template>
                        <span
                          >{{
                            setPercentageValue(registered, target_registration_as_of)
                          }}% of moving target</span
                        >
                      </v-tooltip>
                    </h3>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="d-flex align-center" style="gap: 1rem">
                  <v-avatar size="60" color="green lighten-5 ma-0" tile class="rounded">
                    <v-icon color="green"> mdi-account-badge </v-icon>
                  </v-avatar>
                  <div>
                    <div class="grey--text" style="font-size: 13px; margin-top: 2px">
                      Active
                    </div>
                    <h3 class="black--text">
                      {{ moneyFormat(parseInt(active_player)) }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <small
                            v-bind="attrs"
                            v-on="on"
                            class="green--text"
                            style="
                              font-weight: normal;
                              font-size: 12px;
                              padding-left: 4px;
                            "
                          >
                            {{ setPercentageValue(active_player, registered) }}%
                          </small>
                        </template>
                        <span
                          >{{ setPercentageValue(active_player, registered) }}% of
                          Registered Players</span
                        >
                      </v-tooltip>
                    </h3>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="d-flex align-center" style="gap: 1rem">
                  <v-avatar size="60" color="orange lighten-5 ma-0" tile class="rounded">
                    <v-icon color="orange"> mdi-account-plus </v-icon>
                  </v-avatar>
                  <div>
                    <div class="grey--text" style="font-size: 13px; margin-top: 2px">
                      New
                    </div>
                    <h3 class="black--text">
                      {{ moneyFormat(parseInt(new_player)) }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <small
                            v-bind="attrs"
                            v-on="on"
                            class="green--text"
                            style="
                              font-weight: normal;
                              font-size: 12px;
                              padding-left: 4px;
                            "
                          >
                            {{ setPercentageValue(new_player, target) }}%
                          </small>
                        </template>
                        <span
                          >{{ setPercentageValue(new_player, target) }}% of Target
                          Registration</span
                        >
                      </v-tooltip>
                    </h3>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="d-flex align-center" style="gap: 1rem">
                  <v-avatar size="60" color="teal lighten-5 ma-0" tile class="rounded">
                    <v-icon color="teal"> mdi-account-edit </v-icon>
                  </v-avatar>
                  <div>
                    <div class="grey--text" style="font-size: 13px; margin-top: 2px">
                      Verified Bettors
                    </div>
                    <h3 class="black--text">
                      {{ moneyFormat(parseInt(verified_bettors)) }}
                    </h3>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="d-flex align-center" style="gap: 1rem">
                  <v-avatar size="60" color="indigo lighten-5 ma-0" tile class="rounded">
                    <v-icon color="indigo"> mdi-account-cancel </v-icon>
                  </v-avatar>
                  <div>
                    <div class="grey--text" style="font-size: 13px; margin-top: 2px">
                      Unverified Bettors
                    </div>
                    <h3 class="black--text">
                      {{ moneyFormat(parseInt(unverified_bettors)) }}
                    </h3>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="d-flex align-center" style="gap: 1rem">
                  <v-avatar size="60" color="primary lighten-5 ma-0" tile class="rounded">
                    <v-icon color="primary"> mdi-account-check </v-icon>
                  </v-avatar>
                  <div>
                    <div class="grey--text" style="font-size: 13px; margin-top: 2px">
                      Verified {{ filter }}
                    </div>
                    <h3 class="black--text">
                      {{ moneyFormat(parseInt(verified_players)) }}
                    </h3>
                  </div>
                </div>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12" md="6">
                <div class="d-flex align-center" style="gap: 1rem">
                  <v-avatar class="rounded" size="60" color="blue lighten-5 ma-0" tile>
                    <v-icon color="primary"> mdi-printer-pos-check </v-icon>
                  </v-avatar>
                  <div>
                    <div class="grey--text" style="font-size: 13px; margin-top: 2px">
                      Paid Commission
                    </div>
                    <h3 class="black--text">
                      {{ `₱${moneyFormat(parseFloat(this.paid_commission))}` }}
                    </h3>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="d-flex align-center" style="gap: 1rem">
                  <v-avatar size="60" color="indigo lighten-5 ma-0" tile class="rounded">
                    <v-icon color="indigo"> mdi-printer-pos-minus </v-icon>
                  </v-avatar>
                  <div>
                    <div class="grey--text" style="font-size: 13px; margin-top: 2px">
                      Unpaid Commission
                    </div>
                    <h3 class="black--text">
                      {{ `₱${moneyFormat(parseFloat(this.unpaid_commission))}` }}
                    </h3>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div class="d-flex align-center" style="gap: 1rem">
                  <v-avatar class="rounded" size="60" color="cyan lighten-5 ma-0" tile>
                    <v-icon color="cyan"> mdi-wallet </v-icon>
                  </v-avatar>
                  <div>
                    <div class="grey--text" style="font-size: 13px; margin-top: 2px">
                      Players Wallet Balance
                    </div>
                    <h3 class="black--text">
                      {{ `₱${moneyFormat(parseFloat(this.players_wallet_balance))}` }}
                    </h3>
                  </div>
                </div>
              </v-col>
            </v-row> -->
          </v-col>
          <!-- <v-col cols="12" md="7">
            <div class="mb-5">Agents Standing</div>

            <div
              class="rounded overflow-hidden"
              style="min-height: calc(168px + 36px + 137px); border: 1px solid #e3e3e3"
            >
              <v-data-table
                :headers="headers"
                :items="playersNewStatistics.agent_standing"
                :items-per-page="5"
                :loading="playersNewStatisticsLoading"
                loading-text="Loading, please wait a moment"
              >
                <template v-slot:[`item.player`]="{ item }">
                  {{ moneyFormat(parseInt(item.player).toLocaleString()) }}
                </template>

                <template v-slot:[`item.percent`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" style="color: #90caf9">{{
                        `${item.percent}%`
                      }}</span>
                    </template>
                    <span>{{
                      `${item.percent}% of ${
                        item.agent == "No Agent" ? "regular" : "agent"
                      } player`
                    }}</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.bet_count`]="{ item }">
                  {{ moneyFormat(parseInt(item.bet_count).toLocaleString()) }}
                </template>
              </v-data-table>
            </div>
          </v-col> -->
        </v-row>
      </div>
    </v-card>

    <v-dialog
      ref="filterDialog"
      v-model="filterDialog"
      :return-value.sync="filterDate"
      persistent
      width="290px"
    >
      <v-date-picker v-model="filterDate" scrollable range>
        <v-spacer></v-spacer>

        <v-btn text color="primary" @click="cancelFilter"> Cancel </v-btn>

        <v-btn text color="primary" @click="saveFilter"> OK </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import formatDatev2 from "@/helpers/formatDatev2";
import { startOfWeek } from "date-fns";
import { endOfWeek } from "date-fns";
import { startOfMonth } from "date-fns";
import { endOfMonth } from "date-fns";
import { startOfYear } from "date-fns";
import { endOfYear } from "date-fns";
import { mapActions, mapState } from "vuex";
import GetPercentage from "@/helpers/getPercentage";

export default {
  data: () => ({
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    filter: "Today",
    filters: ["Today", "This Week", "This Month", "This Year", "Custom"],
    filterFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    filterTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    filterDialog: false,
    filterDate: [],
    filterDateText: "",
    registered: 0,
    yesterday_player: 0,
    new_player: 0,
    active_player: 0,
    target: 0,
    target_registration_as_of: 0,
    players_wallet_balance: 0,
    paid_commission: 0,
    unpaid_commission: 0,
    verified_players: 0,
    verified_bettors: 0,
    unverified_bettors: 0,
    theChartData: [],
    headers: [
      {
        text: "Name",
        value: "agent",
        class: "text-uppercase",
        align: "left",
      },
      {
        text: "Players",
        value: "player",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Active Players",
        value: "active_player",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Percent",
        value: "percent",
        class: "text-uppercase",
      },
      {
        text: "Bets",
        value: "bet_count",
        class: "text-uppercase",
        align: "right",
      },
    ],
  }),
  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("dashboard", ["playersNewStatistics", "playersNewStatisticsLoading"]),

    total_active_player() {
      return this.playersNewStatistics.agent_standing.reduce((accumulator, object) => {
        return accumulator + parseInt(object.active_player);
      }, 0);
    },
  },
  mounted() {
    if (this.accountInfo.access.includes(21)) {
      this.doGetData();
    }
  },
  methods: {
    ...mapActions("dashboard", ["getPlayersNewStatistics"]),
    changeFilter(val) {
      if (val == "Custom") {
        this.filterDialog = true;
      } else {
        switch (val) {
          case "Today":
            {
              this.filterFrom = this.today;
              this.filterTo = this.today;
              this.filter = val;
              this.filterDate = [];
            }
            break;

          case "This Week":
            {
              const startWeek = startOfWeek(new Date(this.today), {
                  weekStartsOn: 1,
                }),
                endWeek = endOfWeek(new Date(this.today), { weekStartsOn: 1 });

              this.filterFrom = formatDatev2(startWeek, "format", "yyyy-MM-dd");
              this.filterTo = formatDatev2(endWeek, "format", "yyyy-MM-dd");
              this.filter = val;
              this.filterDate = [];
            }
            break;

          case "This Month":
            {
              const startMonth = startOfMonth(new Date(this.today)),
                endMonth = endOfMonth(new Date(this.today));

              this.filterFrom = formatDatev2(startMonth, "format", "yyyy-MM-dd");
              this.filterTo = formatDatev2(endMonth, "format", "yyyy-MM-dd");
              this.filter = val;
              this.filterDate = [];
            }
            break;

          case "This Year":
            {
              const startYear = startOfYear(new Date(this.today)),
                endYear = endOfYear(new Date(this.today));

              this.filterFrom = formatDatev2(startYear, "format", "yyyy-MM-dd");
              this.filterTo = formatDatev2(endYear, "format", "yyyy-MM-dd");
              this.filter = val;
              this.filterDate = [];
            }
            break;
        }

        this.doGetData();
      }
    },

    cancelFilter() {
      this.filterDialog = false;
    },

    saveFilter() {
      if (this.filterDate.length) {
        this.$refs.filterDialog.save(this.filterDate);

        const minDate = new Date(this.filterDate[0]);

        if (typeof this.filterDate[1] != "undefined") {
          const maxDate = new Date(this.filterDate[1]);

          if (minDate > maxDate) {
            this.filterFrom = formatDatev2(maxDate, "format", "yyyy-MM-dd");
            this.filterTo = formatDatev2(minDate, "format", "yyyy-MM-dd");
          } else {
            this.filterFrom = formatDatev2(minDate, "format", "yyyy-MM-dd");
            this.filterTo = formatDatev2(maxDate, "format", "yyyy-MM-dd");
          }

          this.filterDateText =
            formatDatev2(this.filterFrom, "date") +
            " to " +
            formatDatev2(this.filterTo, "date");
        } else {
          this.filterFrom = formatDatev2(minDate, "format", "yyyy-MM-dd");
          this.filterTo = formatDatev2(minDate, "format", "yyyy-MM-dd");
          this.filterDateText = formatDatev2(this.filterFrom, "date");
        }

        this.filter = "Custom";
        this.doGetData();
      }
    },
    async doGetData() {
      if (!this.playersNewStatisticsLoading) {
        this.$store.commit("dashboard/setPlayersNewStatistics", []);

        const formData = new FormData();
        formData.append("is_first_load", true);
        formData.append("date_from", this.filterFrom);
        formData.append("date_to", this.filterTo);

        const getData = new URLSearchParams(formData);

        await this.getPlayersNewStatistics(getData);

        this.registered = this.playersNewStatistics["registered"][0]["registered_player"];
        this.yesterday_player = this.playersNewStatistics["yesterday"][0][
          "yesterday_registered_player"
        ];
        this.new_player = this.playersNewStatistics["new"][0]["newly_registered_player"];
        this.active_player = this.playersNewStatistics["active"][0]["active_player"];
        this.target = this.playersNewStatistics.target_registration.target;
        this.target_registration_as_of = this.playersNewStatistics.target_as_of_registration.target;
        this.players_wallet_balance = this.playersNewStatistics.players_wallet_balance.wallet;
        this.paid_commission = this.playersNewStatistics.paid_commission.amount;
        this.unpaid_commission = this.playersNewStatistics.unpaid_commission.amount;
        this.verified_players = this.playersNewStatistics.verified_players.count;
        this.verified_bettors = this.playersNewStatistics.verified_bettors.count;
        this.unverified_bettors = this.playersNewStatistics.unverified_bettors.count;

        this.theChartData = [];
        this.theChartData.push(parseInt(this.active_player));
        this.theChartData.push(parseInt(this.new_player));
      }
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    reRouteToPlayerList(val) {
      switch (val) {
        case 1:
          this.$router.push({
            name: "PlayerList",
            query: { search_flag: 3, date_from: this.filterFrom, date_to: this.filterTo },
          });
          break;
        case 2:
          this.$router.push({
            name: "PlayerList",
            query: { search_flag: 4, date_from: this.filterFrom, date_to: this.filterTo },
          });
          break;
      }
    },

    setPercentage(val, dividend) {
      if (val != 0 && dividend != 0) {
        return GetPercentage(val, dividend);
      } else {
        return 0;
      }
    },

    setPercentageValue(val, dividend) {
      return this.moneyFormatPercent(parseFloat(this.setPercentage(val, dividend)));
    },

    moneyFormatPercent(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>