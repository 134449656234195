<template>
    <v-dialog scrollable v-model="dialog" max-width="400px">
        <v-card>
            <v-card-title>
                <v-icon class="mr-3">
                    mdi-message-processing
                </v-icon>

                Send Sms

                <v-btn absolute right icon @click="closeDialog">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text class="py-4">
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="sendSms">

                    <v-row dense>
                        <v-col>
                            <v-textarea
                                outlined
                                label="Compose your message here..."
                                v-model="messageContext"
                                :rules="[rules.maximum(160)]"
                                counter="160"
                                rows="7"
                                hide-details="auto"/>
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col auto align="end">
                            <v-btn class="mr-2" @click="closeDialog">Cancel</v-btn>
                            <v-btn color="primary" type="submit" :loading="sendSMSNotifToPlayersLoading">Confirm</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import formRules from '@/helpers/formRules'
import { mapActions, mapState } from 'vuex'

export default {
    props: {
        sendSmsData: {
            type: Object
        }
    },

    data: () => ({
        valid: true,
        messageContext: '',
        rules: formRules
    }),

    mounted() {
        this.messageContext = `Ka Dribol ${this.sendSmsData.league} game! ${this.sendSmsData.team_a_alias} vs ${this.sendSmsData.team_b_alias}, ${this.sendSmsData.game_date} ${this.sendSmsData.game_time}, win up to P70,000 real quick! Tumaya at manalo sa www.gamexsports.com.ph`
    },

    computed: {
        ...mapState("ending_game_details", [
            "sendSMSNotifToPlayersHasError",
            "sendSMSNotifToPlayersLoading"
        ]),

        dialog: {
            get: function () {
                return true
            },
            set: function () {
                this.closeDialog()
            }
        }
    },

    methods: {
        ...mapActions("ending_game_details", ["sendGameSchedule"]),

        async sendSms() {
            if (!this.sendSMSNotifToPlayersLoading) {
                const formData = new FormData();
                formData.append("message", this.messageContext);

                await this.sendGameSchedule(formData);

                if (!this.sendSMSNotifToPlayersHasError) {
                    // this.smsToPlayersSent = 1;

                    // setTimeout(() => {
                    //     this.smsToPlayersSent = 0;
                    // }, 1000);
                    this.$emit("closeSmsDialog", true)
                }
            }
        },

        closeDialog() {
            this.$emit("closeSmsDialog", false)
        }
    }
}
</script>