var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Ending Reports")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Bets")]),_c('v-card',{staticClass:"shadow mb-4"},[_c('v-card-title',{staticClass:"text-h6 py-2 font-weight-regular grey--text text--darken-1"},[_vm._v(" Select "+_vm._s(_vm.filterBy)+" ")]),_c('v-divider'),_c('v-card-text',{staticClass:"py-4"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.loadRecords.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.filterByStatus,"rules":[_vm.rules.required],"label":"Filter By","dense":"","outlined":"","hide-details":"auto","clearable":""},model:{value:(_vm.filterBy),callback:function ($$v) {_vm.filterBy=$$v},expression:"filterBy"}})],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.rules.required, _vm.lessThanDate2],"label":(_vm.filterBy + " From"),"hide-details":"auto","readonly":"","outlined":"","dense":"","clearable":""},on:{"keyup":function($event){_vm.date_from_menu = true},"keydown":function($event){_vm.date_from_menu = false}},model:{value:(_vm.date_from),callback:function ($$v) {_vm.date_from=$$v},expression:"date_from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_from_menu),callback:function ($$v) {_vm.date_from_menu=$$v},expression:"date_from_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.date_from_menu = false}},model:{value:(_vm.date_from),callback:function ($$v) {_vm.date_from=$$v},expression:"date_from"}})],1)],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.lessThanDate],"label":(_vm.filterBy + " To (Optional)"),"hide-details":"auto","readonly":"","outlined":"","dense":"","disabled":_vm.dateToAvailability,"clearable":""},on:{"keyup":function($event){_vm.date_to_menu = true},"keydown":function($event){_vm.date_to_menu = false}},model:{value:(_vm.date_to),callback:function ($$v) {_vm.date_to=$$v},expression:"date_to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_to_menu),callback:function ($$v) {_vm.date_to_menu=$$v},expression:"date_to_menu"}},[_c('v-date-picker',{attrs:{"min":_vm.date_from ? _vm.date_from : ''},on:{"input":function($event){_vm.date_to_menu = false}},model:{value:(_vm.date_to),callback:function ($$v) {_vm.date_to=$$v},expression:"date_to"}})],1)],1)],1),_c('div',{staticClass:"mt-4"},[_c('v-btn',{attrs:{"disabled":!_vm.valid,"type":"submit","color":"primary","outlined":"","loading":_vm.recordsLoading},on:{"click":function($event){$event.preventDefault();return _vm.loadRecords.apply(null, arguments)}}},[_vm._v(" Search ")])],1)],1)],1)],1),_c('v-card',{staticClass:"shadow"},[_c('v-card-title',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"2","align-self":"end"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","color":"secondary"},on:{"click":_vm.loadRecords}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cached")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c('downloadexcel',{staticClass:"d-inline-block",attrs:{"data":_vm.records,"fields":{
								ID: 'bet_id',
								'Player Name': 'player_name',
								'Mobile Number': 'mobile_number',
								League: 'league',
								Game: {
									callback: function (value) {
										return ((value.team_a_name) + " " + (value.team_a_alias) + " vs " + (value.team_b_name) + " " + (value.team_b_alias))
									},
								},
								Schedule: {
									callback: function (value) {
										return this$1.dateFormat(value.schedule)
									},
								},
								'Card Number': {
									callback: function (value) {
										return ("#" + (value.card_number))
									},
								},
								'Bet Amount': 'bet_amount',
								'Combination#': {
									field: 'combination_number',
									callback: function (value) {
										return (value + " ")
									},
								},
								'Game Type': 'game_type',
								Quarter: 'quarter',
								'Bet Status': 'is_winner',
								Payout: 'payout',
								'Bet Date': {
									callback: function (value) {
										return this$1.dateFormat(value.created_at)
									},
								},
								'Bet Datetime': {
									callback: function (value) {
										return this$1.datetimeFormat(value.created_at)
									},
								},
							},"name":_vm.exportExcelName(),"type":"csv"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
							var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-delimited-outline")])],1)]}}])},[_c('span',[_vm._v("Export to CSV")])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search","single-line":"","hide-details":"auto","disabled":_vm.recordsLoading,"clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.recordsFormatted,"items-per-page":5,"search":_vm.search,"loading":_vm.recordsLoading,"loading-text":"Loading, please wait a moment","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.game",fn:function(ref){
							var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
							var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-avatar',{attrs:{"size":"36px","tile":""}},[_c('v-img',{attrs:{"src":item.team_a_logo},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black"}})],1)]},proxy:true}],null,true)})],1),_c('span',{staticClass:"text-overline my-2 mx-2 font-italic"},[_vm._v("vs")]),_c('v-avatar',{attrs:{"size":"36px","tile":""}},[_c('v-img',{attrs:{"src":item.team_b_logo},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black"}})],1)]},proxy:true}],null,true)})],1)],1)]}}],null,true)},[_c('span',[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.team_a_name)+" "+_vm._s(item.team_a_alias))]),_c('span',{staticClass:"text-overline my-2 mx-2 font-italic"},[_vm._v("vs")]),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(item.team_b_name)+" "+_vm._s(item.team_b_alias))])])])],1)]}},{key:"item.is_winner",fn:function(ref){
							var item = ref.item;
return [_c('v-chip',{class:item.is_winner == 'Win' ? 'green lighten-5 green--text' : 'red lighten-5 red--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.is_winner)+" ")])]}},{key:"item.payout",fn:function(ref){
							var item = ref.item;
return [_vm._v(" "+_vm._s(("₱" + (item.payout)))+" ")]}},{key:"item.game_type",fn:function(ref){
							var item = ref.item;
return [(item.quarter)?_c('v-badge',{attrs:{"content":item.quarter}},[_vm._v(" "+_vm._s(item.game_type)+" ")]):_c('span',[_vm._v(_vm._s(item.game_type))])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }