const url = process.env.VUE_APP_API_URL

export default {
  namespaced: true,

  state: {
    records: [],
    recordsLoading: false,
    approveError: null,
    approveLoading: false,
    cashOutId: '',
    loading: false,
    usersData: [],
    error: null,
    errors: [],
    disapproveError: null,
    disapproveLoading: false,
    cashOutPending: 0,
    approvalPasswordLoading: false,
    approvalPasswordError: null,
  },
  mutations: {
    setRecords(state, val) {
      state.records = val
    },
    setUsersInfo(state, val) {
      state.usersData = val
    },
    setCashInId(state, val) {
      state.cashInId = val
    },
    setLoading(state, val) {
      state.loading = val
    },
    setError(state, val) {
      state.val = val
    },
    setErrors(state, val) {
      state.errors = val
    },
    setRecordsLoading(state, val) {
      state.recordsLoading = val
    },
    setApproveError(state, val) {
      state.approveError = val
    },
    setApproveLoading(state, val) {
      state.approveLoading = val
    },
    setDisapproveError(state, val) {
      state.disapproveError = val
    },
    setDisapproveLoading(state, val) {
      state.disapproveLoading = val
    },
    setCashOutPending(state, val) {
      state.cashOutPending = val
    },
    setApprovalPasswordLoading(state, val) {
      state.approvalPasswordLoading = val
    },
    setApprovalPasswordError(state, val) {
      state.approvalPasswordError = val
    },
  },

  actions: {
    async getRecords({ commit, rootState }, data) {
      commit("setRecordsLoading", true)

      try {
        const response = await fetch(`${url}cashout?${data}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setRecords", res.records)
        }
      } catch (error) {
        const errorStatus = error.response.status

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setRecordsLoading", false)
      }
    },

    async getFilteredRecords({ commit, rootState }, data) {
      commit("setRecordsLoading", true)

      try {
        const response = await fetch(`${url}cashout/get_filtered_records?${data}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setRecords", res.records)
        }
      } catch (error) {
        const errorStatus = error.response.status

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setRecordsLoading", false)
      }
    },

    async getPending({ commit, rootState }) {
      commit("setRecordsLoading", true)

      try {
        const response = await fetch(`${url}cashout/pending`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setRecords", res.records)
        }
      } catch (error) {
        const errorStatus = error.response.status

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setRecordsLoading", false)
      }
    },

    async verifyCashout({ commit, rootState }, data) {
      commit("setLoading", true)

      try {
        const response = await fetch(`${url}cashout/verify`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setError", null)
        }
      } catch (error) {
        const errorStatus = error.response.status

        commit("setError", "Error") // Decoy error to detect success

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus == 417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setLoading", false)
      }
    },

    async rejectCashout({ commit, rootState }, data) {
      commit("setLoading", true)

      try {
        const response = await fetch(`${url}cashout/reject`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setError", null)
        }
      } catch (error) {
        const errorStatus = error.response.status

        commit("setError", "Error") // Decoy error to detect success

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus == 417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setLoading", false)
      }
    },

    async approveCashout({ commit, rootState }, data) {
      commit("setLoading", true)

      try {
        const response = await fetch(`${url}cashout/approve`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setError", null)
        }
      } catch (error) {
        const errorStatus = error.response.status

        commit("setError", "Error") // Decoy error to detect success

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus == 404) {
          commit("setPopUpError", error.res.messages.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setLoading", false)
      }
    },

    async disapproveCashout({ commit, rootState }, data) {
      commit("setLoading", true)

      try {
        const response = await fetch(`${url}cashout/disapprove`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setError", null)
        }
      } catch (error) {
        const errorStatus = error.response.status

        commit("setError", "Error") // Decoy error to detect success

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus == 417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setLoading", false)
      }
    },
    async getUsersInfo({ commit, rootState }, data) {
      commit("setLoading", true)
      commit("setError", null)
      commit("setErrors", [])

      try {
        const response = await fetch(`${url}cashin/get_user`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`
          },
          body: data
        })

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setUsersInfo", res)
        }
      } catch (error) {
        const errorStatus = error.response.status

        switch (errorStatus) {
          case 401:
            commit("setError", "Unauthorized", { root: true })
            break;
          case 417:
            commit("setError", error.res.error, { root: true })
            break;
          case 422:
            commit("setErrors", error.res.messages)
            break;
          default:
            commit("setRefreshError", "An error occurred, please try again!", { root: true })
            break;
        }
      } finally {
        commit("setLoading", false)
      }
    },
    async cashOutManual({ commit, rootState }, data) {
      commit("setLoading", true)
      commit("setError", null)
      commit("setErrors", [])

      try {
        const response = await fetch(`${url}cashout/manual`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`
          },
          body: data
        })

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setCashInId", res.cashid_id)
        }
      } catch (error) {
        const errorStatus = error.response.status

        switch (errorStatus) {
          case 401:
            commit("setError", "Unauthorized", { root: true })
            break;
          case 404:
            commit("setPopUpError", error.res.messages.error, { root: true })
            break;
          case 417:
            commit("setError", error.res.error, { root: true })
            break;
          case 422:
            commit("setPopUpError", error.res.error, { root: true })
            break;
          default:
            commit("setRefreshError", "An error occurred, please try again!", { root: true })
            break;
        }
      } finally {
        commit("setLoading", false)
      }
    },

    async approvePassword({ commit, rootState }, data) {
      commit("setApprovalPasswordLoading", true)
      commit("setApprovalPasswordError", null)

      try {
        const response = await fetch(`${url}cashout/approve_password`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`
          },
          body: data
        })

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        }
      } catch (error) {
        const errorStatus = error.response.status

        switch (errorStatus) {
          case 401:
            commit("setError", "Unauthorized", { root: true })
            break;
          case 404:
            commit("setPopUpError", error.res.messages.error, { root: true })
            break;
          case 403:
            commit("setApprovalPasswordError", error.res.messages.error);
            break;
          default:
            commit("setRefreshError", "An error occurred, please try again!", { root: true })
            break;
        }
      } finally {
        commit("setApprovalPasswordLoading", false)
      }
    }
  }
}