<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-card-title class="text-h5"> {{ titleText }} </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="py-4">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="handleSubmit"
        >
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                label="From"
                ref="from"
                outlined
                dense
                :rules="[rules.required]"
                v-model="from"
                hide-details="auto"
                type="number"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="To"
                ref="to"
                outlined
                dense
                :rules="[rules.required]"
                v-model="to"
                hide-details="auto"
                type="number"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="Free Token"
                ref="amount"
                outlined
                dense
                :rules="[rules.required]"
                v-model="amount"
                hide-details="auto"
                type="number"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <div class="mt-4 text-right">
                <v-btn
                  color="black"
                  outlined
                  class="me-2"
                  @click.stop="closeModal"
                >
                  Cancel
                </v-btn>

                <v-btn
                  type="submit"
                  color="primary"
                  outlined
                  :loading="createLoading"
                >
                  {{ buttonText }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";

export default {
  props: {
    depositConfigData: {
      type: Object,
    },
  },
  data: () => ({
    from: "",
    to: "",
    amount: "",
    valid: true,
    rules: formRules,
    id: 0,
    titleText: "Create Deposit Config",
    buttonText: "Create",
    updatedData: [],
    insertedData: [],
  }),
  mounted() {
    if (Object.keys(this.depositConfigData).length != 0) {
      this.id = this.depositConfigData.id;
      this.from = this.depositConfigData.from;
      this.to = this.depositConfigData.to;
      this.amount = this.depositConfigData.amount;
      this.buttonText = "Update";
      this.titleText = "Update Deposit Config";
    }

    setTimeout(() => {
      this.$refs.from.focus();
    }, 300);
  },
  computed: {
    ...mapState("deposit_config", [
      "createLoading",
      "depositConfigId",
      "createError",
      "createErrors",
    ]),

    dialog: {
      get: function () {
        return true;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
  },
  methods: {
    ...mapActions("deposit_config", ["create", "update"]),

    closeModal() {
      this.$emit("closeModal", this.updatedData, this.insertedData);
    },

    async handleSubmit() {
      if (this.$refs.form.validate()) {
        if (!this.createLoading) {
          if (Object.keys(this.depositConfigData).length != 0) {
            await this.update(this.setFormData());
            if (!this.createError && this.isObjectEmpty(this.createErrors)) {
              this.closeModal();
              this.$refs.form.reset();
            } else {
              alert("Error occurred, Please refresh the page");
            }
          } else {
            await this.create(this.setFormData());
            if (!this.createError && this.isObjectEmpty(this.createErrors)) {
              this.setInsertedData();
              this.closeModal();
              this.$refs.form.reset();
            } else {
              alert("Error occurred, Please refresh the page");
            }
          }
        }
      }
    },

    setInsertedData() {
      this.insertedData = {
        id: this.depositConfigId,
        from: this.from,
        to: this.to,
        amount: this.amount
      };
    },

    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    setFormData() {
      const formData = new FormData();
      if (Object.keys(this.depositConfigData).length != 0) {
        formData.append("id", this.id);
        this.updatedData = {
          id: this.id,
          from: this.from,
          to: this.to,
          amount: this.amount
        };
      }
      formData.append("from", this.from);
      formData.append("to", this.to);
      formData.append("amount", this.amount);
      return formData;
    },
  },
};
</script>