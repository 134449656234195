<template>
  <div class="pa-4">
    <div class="grey--text" style="font-size: 13px">Ending Reports</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Players</h2>

    <v-card class="shadow-none">
      <v-card-text class="d-flex flex-column pb-0" style="gap:1rem">
        <div class="d-flex flex-sm-row flex-column justify-space-between" style="gap:1rem">
          <div class="d-flex" style="gap:.30rem">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="grey lighten-5" v-bind="attrs" v-on="on" @click="doGetPlayers"
                  :disabled="records_loading">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <downloadexcel :data="records" :fields="{
                  'TYPE': 'type',
                  'AGENT': 'agent',
                  'NAME': 'name',
                  'MOBILE NUMBER': {
                    callback: (value) => {
                      return `+63${value.mobile_number}`
                    },
                  },
                  'EMAIL': 'email',
                  'ACCOUNT STATUS': 'account_status',
                  'ACTIVE STATUS': 'status',
                  'REGISTERED DATE': {
                    callback: (value) => {
                      return format_date_time(value.created_at)
                    },
                  },
                }" :name="downloadExcelName()" type="csv" class="d-inline-block">
                  <v-btn class="grey lighten-5" icon color="secondary" v-bind="attrs" v-on="on"
                    :disabled="records_loading">
                    <v-icon>mdi-file-delimited-outline</v-icon>
                  </v-btn>
                </downloadexcel>
              </template>
              <span>Export to Excel</span>
            </v-tooltip>
          </div>

          <div class="d-flex" style="gap:.30rem">
            <v-text-field v-model="search" autofocus dense outlined label="Search" placeholder="Search" hide-details
              :disabled="records_loading" />

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="grey lighten-5" v-bind="attrs" v-on="on" @click="set_filter_dialog"
                  :disabled="records_loading">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </div>
        </div>

        <v-data-table :headers="headers" :items="records" :search="search" :loading="records_loading"
          loading-text="Loading, please wait a moment" :items-per-page="5">

          <template v-slot:[`item.mobile_number`]="{ item }">
            +63{{ item.mobile_number }}
          </template>

          <template v-slot:[`item.account_status`]="{ item }">
            <v-chip small
              :class="item.account_status == 'Verified' ? 'green lighten-5 green--text' : item.account_status == 'Pending' ? 'orange lighten-5 orange--text' : item.account_status == 'Rejected' ? 'red lighten-5 red--text' : 'blue lighten-5 blue--text'">
              {{ item.account_status }}
            </v-chip>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip small :class="item.status == 'Active' ? 'green lighten-5 green--text' : 'red lighten-5 red--text'">
              {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ format_date(item.created_at) }}
                </span>
              </template>
              <span>{{ format_time(item.created_at) }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <Filters v-if="filter_dialog" :data="filter_data" @set_close_filter_dialog="set_close_filter_dialog" />
  </div>
</template>

<script>
import { format } from "date-fns";
import formatDatev2 from '@/helpers/formatDatev2'
import Filters from '@/components/reports/PlayersFilter'
import responseGet from '@/helpers/api_request_get'
import { mapActions } from "vuex"
import downloadexcel from "vue-json-excel"

export default {
  components: { Filters, downloadexcel },

  data: () => ({
    records: [],
    records_loading: false,
    search: '',
    filter: 0,
    first_name: '',
    last_name: '',
    mobile_number: '',
    date_from: format(new Date(), "yyyy-MM-dd"),
    date_to: format(new Date(), "yyyy-MM-dd"),
    filter_dialog: false,
    filter_data: {},
    headers: [
      {
        text: "Name",
        value: "name",
        class: "text-uppercase",
      },
      {
        text: "Type",
        value: "type",
        class: "text-uppercase",
      },
      {
        text: "Agent",
        value: "agent",
        align: "right",
        class: "text-uppercase",
      },
      {
        text: "Mobile Number",
        value: "mobile_number",
        align: "right",
        class: "text-uppercase",
      },
      {
        text: "Email",
        value: "email",
        class: "text-uppercase",
      },
      {
        text: "Account Status",
        value: "account_status",
        class: "text-uppercase",
      },
      {
        text: "Status",
        value: "status",
        class: "text-uppercase",
      },
      {
        text: "Registered Date",
        value: "created_at",
        class: "text-uppercase",
      },
    ]
  }),

  mounted() {
    this.check_query_string();
    this.doGetPlayers()
  },

  methods: {
    ...mapActions("logout", ["sendLogoutRequest"]),

    async doGetPlayers() {
      this.records = []
      this.records_loading = !this.records_loading

      const response = await responseGet(this.set_form_data(), 'ending_reports/players')

      switch (response.status) {
        case 200:
          this.records = response.data.records
          break;
        case 401:
          await this.sendLogoutRequest();
          this.$router.push({ name: "Home" });
          break;
      }

      this.records_loading = !this.records_loading
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('filter', this.filter)

      switch (this.filter) {
        case 0:
          form_data.append('date_from', this.date_from)
          form_data.append('date_to', this.date_to)
          break;
        case 1:
          form_data.append('first_name', this.first_name)
          form_data.append('last_name', this.last_name)
          break;
        case 2:
          form_data.append('mobile_number', this.mobile_number)
          break;
      }

      return new URLSearchParams(form_data)
    },

    set_filter_dialog() {
      this.filter_data = {
        'filter': this.filter,
        'first_name': this.first_name,
        'last_name': this.last_name,
        'mobile_number': this.mobile_number,
        'date_from': this.date_from,
        'date_to': this.date_to
      }
      this.filter_dialog = !this.filter_dialog
    },

    set_close_filter_dialog(data) {
      if (data.type) {
        this.filter = data.filter
        this.first_name = ''
        this.last_name = ''
        this.mobile_number = ''
        this.date_from = null
        this.date_to = null

        switch (data.filter) {
          case 0:
            this.date_from = data.date_from
            this.date_to = data.date_to
            break;
          case 1:
            this.first_name = data.first_name
            this.last_name = data.last_name
            break;
          case 2:
            this.mobile_number = data.mobile_number
            break;
        }

        this.doGetPlayers()
        this.set_query_string()
      }

      this.filter_dialog = !this.filter_dialog
    },

    set_query_string() {
      const urlQuery = this.$route.query;
      this.query_filters = {};

      switch (this.filter) {
        case 0: {
          if (urlQuery.filter != this.filter ||
            urlQuery.date_from != this.date_from ||
            urlQuery.date_to != this.date_to) {

            this.query_filters["filter"] = this.filter;
            if (this.date_from != null) this.query_filters["date_from"] = this.date_from;
            if (this.date_to != null) this.query_filters["date_to"] = this.date_to;

            this.$router.replace({
              name: this.$route.name,
              query: this.query_filters,
            });
          }
        }
          break;
        case 1: {
          if (urlQuery.filter != this.filter ||
            urlQuery.first_name != this.first_name ||
            urlQuery.last_name != this.last_name) {

            this.query_filters["filter"] = this.filter;
            if (this.first_name != null && this.first_name != '') this.query_filters["first_name"] = this.first_name;
            if (this.last_name != null && this.last_name != '') this.query_filters["last_name"] = this.last_name;

            this.$router.replace({
              name: this.$route.name,
              query: this.query_filters,
            });
          }
        }
          break;
        case 2: {
          if (urlQuery.filter != this.filter ||
            urlQuery.mobile_number != this.mobile_number) {

            this.query_filters["filter"] = this.filter;
            if (this.mobile_number != null && this.mobile_number != '') this.query_filters["mobile_number"] = this.mobile_number;

            this.$router.replace({
              name: this.$route.name,
              query: this.query_filters,
            });
          }
        }
          break;
      }
    },

    check_query_string() {
      const urlQuery = this.$route.query;

      if (Object.keys(urlQuery).length) {
        if (Object.prototype.hasOwnProperty.call(urlQuery, "filter"))
          this.filter = parseInt(urlQuery.filter);
        if (Object.prototype.hasOwnProperty.call(urlQuery, "first_name"))
          this.first_name = urlQuery.first_name;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "last_name"))
          this.last_name = urlQuery.last_name;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "mobile_number"))
          this.mobile_number = urlQuery.mobile_number;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "date_from"))
          this.date_from = urlQuery.date_from;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "date_to"))
          this.date_to = urlQuery.date_to;
      }
    },

    format_date(date) {
      return formatDatev2(date, 'custom_date')
    },

    format_time(date) {
      return formatDatev2(date, 'timeOnly')
    },

    format_date_time(date) {
      return formatDatev2(date, 'datetime')
    },

    downloadExcelName() {
      return "Registered Players Report " + formatDatev2(new Date()) + ".xls";
    },
  }
}
</script>