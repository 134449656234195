<template>
	<!--<div class="bs-row bs-col-md-6">
      <div class="grey--text" style="font-size: 13px">Account</div>
      <h2 class="font-weight-bold mb-3 mb-md-5">Change Password</h2>
      <v-card :loading="loading" class="shadow">
        <v-card-title
          class="text-h6 pa-4 font-weight-regular grey--text text--darken-1 overline"
        >
          Set your new password
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-4">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            :disabled="loading"
            @submit.prevent="handleSubmit"
          >
            <v-alert v-if="changePasswordStatus" dense text type="success">
              Successfully changed!
            </v-alert>

            <v-row dense>
              <v-col>
                <v-text-field
                  ref="current_password"
                  v-model="current_password"
                  :rules="[
                    rules.required,
                    rules.minimum(6),
                    rules.strongPassword,
                    currentPasswordError,
                  ]"
                  :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showCurrentPassword ? 'text' : 'password'"
                  label="Current Password"
                  placeholder="Enter current password"
                  prepend-inner-icon="mdi-lock"
                  outlined
                  dense
                  @click:append="showCurrentPassword = !showCurrentPassword"
                  hide-details="auto"
                  class="mt-3"
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-text-field
                  ref="password"
                  v-model="password"
                  :rules="[
                    rules.required,
                    rules.minimum(6),
                    rules.strongPassword,
                    passwordCurrentAndNewRule,
                  ]"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  label="New Password"
                  placeholder="Enter new password"
                  prepend-inner-icon="mdi-lock"
                  outlined
                  dense
                  @click:append="showPassword = !showPassword"
                  hide-details="auto"
                  class="mt-3"
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <v-text-field
                  ref="confirm_password"
                  v-model="confirm_password"
                  :rules="[
                    rules.required,
                    rules.minimum(6),
                    rules.strongPassword,
                    passwordConfirmationRule,
                    passwordCurrentAndNewRule,
                  ]"
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  label="Confirm Password"
                  placeholder="Enter confirmation password"
                  prepend-inner-icon="mdi-lock"
                  outlined
                  dense
                  @click:append="showConfirmPassword = !showConfirmPassword"
                  hide-details="auto"
                  class="mt-3"
                />
              </v-col>
            </v-row>

            <v-row dense class="mt-3">
              <v-col>
                <v-btn :disabled="loading" block depressed color="primary" type="submit">
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>-->
	<div style="height: 100%" class="d-flex flex-column justify-center">
		<span class="align-self-center"> ERROR 404 NOT FOUND </span>
	</div>
</template>

<script>
import formRules from '@/helpers/formRules'
import { mapActions, mapState } from 'vuex'

export default {
	data: () => ({
		valid: true,
		rules: formRules,
		current_password: '',
		password: '',
		confirm_password: '',
		showCurrentPassword: false,
		showPassword: false,
		showConfirmPassword: false,
		passwordMatch: '',
		passwordConfirmMatch: '',
		currentPasswordText: '',
		changePasswordStatus: false,
	}),

	created() {
		setTimeout(() => {
			this.$refs.current_password.focus()
		}, 200)
	},

	computed: {
		...mapState('ending_users', ['loading', 'error', 'errors']),

		passwordConfirmationRule() {
			return () => this.password === this.confirm_password || 'Password must match'
		},

		passwordCurrentAndNewRule() {
			return () =>
				(this.current_password != this.password && this.current_password != this.confirm_password) ||
				'Old password must not match in new password'
		},
	},

	methods: {
		...mapActions('ending_users', ['changePassword']),

		async handleSubmit() {
			if (this.$refs.form.validate()) {
				await this.changePassword(this.setChangePasswordFormData())

				if (!this.error && this.isObjectEmpty(this.errors)) {
					this.changePasswordStatus = true
					this.$refs.form.reset()
				} else this.setErrors()
			}
		},

		setChangePasswordFormData() {
			const formData = new FormData()

			formData.append('current_password', this.current_password)
			formData.append('password', this.password)
			formData.append('confirm_password', this.confirm_password)

			return formData
		},

		setErrors() {
			let errorFocus = ''

			Object.entries(this.errors).forEach(([key, value]) => {
				if (value) {
					if (!errorFocus) {
						errorFocus = key
					}

					switch (key) {
						case 'error':
							this.currentPasswordErrorText = this.current_password
							break
					}

					this.$refs.form.validate()
					this.$refs['current_password'].focus()
				}
			})
		},

		isObjectEmpty(obj) {
			return Object.keys(obj).length === 0
		},

		currentPasswordError(val) {
			if (val == this.currentPasswordErrorText) {
				return 'Incorrect password.'
			} else {
				return true
			}
		},
	},

	watch: {
		changePasswordStatus(val) {
			if (val) {
				setTimeout(() => {
					this.changePasswordStatus = false
				}, 3000)
			}
		},
	},
}
</script>
