const url = process.env.VUE_APP_API_URL + "ending/"

export default {
    namespaced: true,
  
    state: {
        records: []  ,
        recordsLoading: false,
        provinces: [],
        provincesLoading: false,
        cityMuns: [],
        cityMunsLoading: false,
        agents: [],
        agentsLoading: false
    },

    mutations: {
        setRecords(state, val) {
            state.records = val
        },

        setRecordsLoading(state, val) {
            state.recordsLoading = val
        },

        setProvinces(state, val) {
            state.provinces = val
        },

        setProvincesLoading(state, val) {
            state.provincesLoading = val
        },

        setCityMuns(state, val) {
            state.cityMuns = val
        },

        setCityMunsLoading(state, val) {
            state.cityMunsLoading = val
        },

        setAgents(state, val) {
            state.agents = val
        },

        setAgentsLoading(state, val) {
            state.agentsLoading = val
        }
    },
  
    actions: {
        async getProvinces({ commit, rootState }) {
            commit("setProvincesLoading", true)

            try {
                const response = await fetch(`${url}provinces` , {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setProvinces", res.provinces)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
                } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setProvincesLoading", false)
            }
        },

        async getCityMuns({ commit, rootState }) {
            commit("setCityMunsLoading", true)

            try {
                const response = await fetch(`${url}city_muns` , {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setCityMuns", res.city_muns)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
                } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setCityMunsLoading", false)
            }
        },

        async getAgents({ commit, rootState }) {
            commit("setAgentsLoading", true)

            try {
                const response = await fetch(`${url}agents` , {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setAgents", res.agents)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
                } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setAgentsLoading", false)
            }
        },

        async getKobradors({ commit, rootState }, data) {
            commit("setRecordsLoading", true)

            try {
                const response = await fetch(`${url}monitoring/kobradors?${data}` , {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setRecords", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
                } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setRecordsLoading", false)
            }
        },

        async getPlayersByBet({ commit, rootState }, data) {
            commit("setRecordsLoading", true)

            try {
                const response = await fetch(`${url}monitoring/by_bets?${data}` , {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setRecords", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
                } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setRecordsLoading", false)
            }
        },

        async getPlayersBySchedule({ commit, rootState }, data) {
            commit("setRecordsLoading", true)

            try {
                const response = await fetch(`${url}monitoring/by_schedule?${data}` , {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setRecords", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
                } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setRecordsLoading", false)
            }
        },
    }
  }