<template>
  <div class="pa-4 pa-md-6">
    <v-row>
      <v-col cols="12">
        <v-card :loading="detailsLoading">
          <v-card-text class="pb-0">
            <div class="text-center title">
              {{ details.league }}

              <!-- <v-col cols="auto" absolute right class="text-right pe-2 d-none d-sm-block"> -->
              <v-btn
                small
                color="primary"
                @click="dialog = true"
                absolute
                right
                class="pe-2 d-none d-sm-inline-block"
              >
                <v-icon left> mdi-pencil </v-icon>
                Edit
              </v-btn>
              <!-- </v-col> -->

              <!-- <v-col cols="auto" absolute right class="text-right pe-2 d-block d-sm-none"> -->
              <v-btn
                icon
                color="primary"
                @click="dialog = true"
                absolute
                right
                class="pe-2 d-inline-block d-sm-none"
              >
                <v-icon size="large"> mdi-pencil </v-icon>
              </v-btn>
              <!-- </v-col> -->
            </div>
          </v-card-text>

          <v-card-title>
            <v-row justify="center" align="center">
              <v-col cols="auto" class="text-center" align-self="center">
                <v-avatar size="100px" tile>
                  <v-img :src="details.team_a_logo">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <div>
                  <span class="text-caption"
                    >{{ details.team_a_name }} {{ details.team_a_alias }}</span
                  >
                </div>
              </v-col>

              <v-col cols="auto" class="text-center">
                <span class="text-overline my-2 mx-2 font-italic">vs</span>
              </v-col>

              <v-col cols="auto" class="text-center">
                <v-avatar size="100px" tile>
                  <v-img :src="details.team_b_logo">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <div>
                  <span class="text-caption"
                    >{{ details.team_b_name }} {{ details.team_b_alias }}</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <p class="mb-1 overline primary--text">Game Details</p>

                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody class="table-borderless">
                      <tr>
                        <td style="width: 30%" class="grey--text">Schedule</td>

                        <td>
                          <span>{{ formattedSchedule }}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="grey--text">Status</td>

                        <td>
                          <span>{{ details.status || "-" }}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="grey--text">Created At</td>

                        <td>
                          <span>{{ formattedCreatedAt }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <p
                  class="mb-1 mt-3 overline primary--text"
                  v-if="accountInfo.access.includes(10)"
                >
                  Settings
                </p>

                <v-simple-table dense v-if="accountInfo.access.includes(10)">
                  <template v-slot:default>
                    <tbody class="table-borderless">
                      <tr>
                        <td style="width: 30%" class="grey--text">Betting</td>

                        <td>
                          <SettingsBetting
                            v-if="details"
                            :compData="{
                              id: id,
                              betting: details.betting,
                            }"
                          />
                        </td>
                      </tr>

                      <tr v-if="accountInfo.access.includes(60)">
                        <td style="width: 30%" class="grey--text">
                          Lucky Four
                        </td>

                        <td>
                          <SettingsLuckyFour
                            v-if="details"
                            :compData="{
                              id: id,
                              is_lucky_four: details.is_lucky_four,
                            }"
                          />
                        </td>
                      </tr>

                      <tr v-if="accountInfo.access.includes(71)">
                        <td style="width: 30%" class="grey--text">
                          Power Ball
                        </td>

                        <td>
                          <SettingsPowerBall
                            v-if="details"
                            :compData="{
                              id: id,
                              is_power_ball: details.is_power_ball,
                            }"
                          />
                        </td>
                      </tr>

                      <!-- <tr>
                        <td style="width: 30%" class="grey--text">
                          SMS to Players
                        </td>
                        <td>
                          <v-btn
                            outlined
                            color="primary"
                            small
                            @click="setSendSmsDialog"
                            :loading="sendSMSNotifToPlayersLoading"
                            :disabled="detailsLoading"
                          >
                            <v-icon left dark> mdi-message-processing </v-icon>

                            <span v-if="!smsToPlayersSent">Send SMS</span>
                            <span v-else>Sent</span>
                          </v-btn>
                        </td>
                      </tr> -->
                      <!-- sendSMSToPlayers -->

                      <!-- <tr
                        v-if="
                          details.status == null &&
                          accountInfo.access.includes(55)
                        "
                      >
                        <td style="width: 30%" class="grey--text">Action</td>

                        <td>
                          <v-btn
                            outlined
                            color="orange"
                            small
                            @click="postponeDialog = true"
                            :disabled="detailsLoading"
                          >
                            <v-icon left dark> mdi-cancel </v-icon>

                            <span>Postpone</span>
                          </v-btn>
                        </td>
                      </tr> -->
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>

              <v-col cols="12" md="4">
                <p
                  class="mb-1 mt-0 md-mt-3 overline primary--text"
                  v-if="accountInfo.access.includes(33)"
                >
                  PDF Generation
                </p>

                <v-simple-table dense v-if="accountInfo.access.includes(33)">
                  <template v-slot:default>
                    <tbody class="table-borderless">
                      <tr>
                        <td style="width: 40%" class="grey--text">Action</td>

                        <td>
                          <v-btn
                            outlined
                            color="primary"
                            small
                            @click="generatePdf"
                            :loading="generatePdfLoading"
                            :disabled="disableGenerate"
                          >
                            <v-icon left dark>
                              mdi-folder-upload-outline
                            </v-icon>
                            Generate
                          </v-btn>
                        </td>
                      </tr>

                      <tr>
                        <td class="grey--text">Generated Cards</td>

                        <td>
                          <span v-if="details.generated_pdf_cards"
                            >{{
                              parseInt(
                                details.generated_pdf_cards
                              ).toLocaleString()
                            }}
                            of {{ totalCards.toLocaleString() }} Cards</span
                          >
                          <span v-else class="ms-2">-</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="grey--text">Email Generated Files</td>

                        <td>
                          <v-btn
                            outlined
                            color="primary"
                            small
                            @click="sendGeneratePdf"
                            :loading="sendGeneratePdfLoading"
                            :disabled="!parseInt(details.generated_pdf_cards)"
                          >
                            <v-icon left dark> mdi-email-outline </v-icon>

                            <span v-if="!emailSent">Send Email</span>
                            <span v-else>Sent</span>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mt-6">
          <v-card-text>
            <v-row>
              <v-col>
                <p class="ma-0 overline primary--text">Bet Amounts</p>
              </v-col>

              <v-spacer />

              <v-col class="text-right">
                <ViewCards :id="id" />
                <CreateCard
                  :game_id="details.game_id"
                  @created="loadBetAmounts"
                  v-if="accountInfo.access.includes(12)"
                />
              </v-col>
            </v-row>

            <v-simple-table dense class="table-bordered">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-uppercase text-center">Amount</th>

                    <th class="text-uppercase text-center">Bet Type</th>

                    <th class="text-uppercase text-center">Total Cards</th>

                    <th class="text-uppercase text-center">Subtracted Cards</th>
                    <!-- <th class="text-uppercase text-center">Cards ({{ details.percentCard }}% Up Bets) </th> -->
                    <th class="text-uppercase text-center">
                      Card Exceed at {{ cardThreshold }}%
                    </th>

                    <th class="text-uppercase text-center">Action</th>
                  </tr>
                </thead>

                <tbody v-if="betAmounts && betAmounts.length">
                  <tr v-for="item in betAmounts" :key="item.bet_amount_id">
                    <td class="text-center">
                      {{ "PHP " + moneyFormat(parseFloat(item.amount)) }}
                    </td>

                    <td class="text-center">
                      {{ item.bet_type }}
                    </td>

                    <td class="text-center">
                      {{ parseInt(item.card_count).toLocaleString() }}
                    </td>

                    <td class="text-center">
                      {{ parseInt(item.card_subtracted).toLocaleString() }}
                    </td>

                    <td class="text-center">
                      {{ parseInt(item.card_exceed).toLocaleString() }}
                    </td>

                    <td class="text-center">
                      <AddCard
                        :compData="{
                          game_id: id,
                          bet_amount_id: item.bet_amount_id,
                          amount: item.amount,
                          bet_type: item.bet_type,
                        }"
                        @created="loadBetAmounts"
                        v-if="accountInfo.access.includes(12)"
                      />

                      <SubtractCard
                        :compData="{
                          game_id: id,
                          bet_amount_id: item.bet_amount_id,
                          amount: item.amount,
                          bet_type: item.bet_type,
                        }"
                        @subtracted="loadBetAmounts"
                        v-if="accountInfo.access.includes(12)"
                      />

                      <div class="d-inline-block">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              :to="{
                                name: 'EndingGameCard',
                                params: {
                                  game_id: id,
                                  league: details.league,
                                  bet_amount_id: item.bet_amount_id,
                                  bet_type_id: item.bet_type_id,
                                },
                              }"
                              target="_blank"
                              small
                              color="primary"
                            >
                              <v-icon size="large"> mdi-cards </v-icon>
                            </v-btn>
                          </template>
                          <span>View Card</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="text-center text-caption grey--text" colspan="5">
                      No bet amounts yet
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
        <v-card class="mt-6" v-if="accountInfo.access.includes(11)">
          <v-card-text>
            <v-row>
              <v-col>
                <p class="ma-0 overline primary--text">Game Result</p>
              </v-col>
              <v-spacer></v-spacer>
              <v-col class="text-right">
                <CreateResult
                  :gameData="this.details"
                  @success="updateResults"
                />
              </v-col>
            </v-row>
            <v-simple-table dense class="table-bordered">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-uppercase text-center">Quarter</th>
                    <th class="text-uppercase text-center">
                      {{ details.team_a_name }} Score
                    </th>
                    <th class="text-uppercase text-center">
                      {{ details.team_b_name }} Score
                    </th>
                    <th class="text-uppercase text-center">Combination#</th>
                    <th class="text-uppercase text-center">Action</th>
                  </tr>
                </thead>
                <tbody v-if="details.results && details.results.length">
                  <tr v-for="item in details.results" :key="item.quarter_id">
                    <td class="text-center">
                      {{ item.quarter }}
                    </td>

                    <td class="text-center">
                      {{ item.team_a_score }}
                    </td>

                    <td class="text-center">
                      {{ item.team_b_score }}
                    </td>

                    <td class="text-center">
                      {{ item.combination }}
                    </td>

                    <td class="text-center">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            icon
                            small
                            color="primary"
                            @click="showUpdateResult(item)"
                          >
                            <v-icon size="large">
                              mdi-square-edit-outline
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Update</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="text-center text-caption grey--text" colspan="5">
                      No result yet
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
        <v-card
          v-if="
            $store.state.accountInfo.access.includes(34) ||
            $store.state.accountInfo.access.includes(9)
          "
          class="mt-6"
        >
          <v-card-text>
            <p class="ma-0 overline primary--text">Winners</p>
            <v-row justify="space-between">
              <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-1"
                      @click="loadGameWinners"
                    >
                      <v-icon>mdi-cached</v-icon>
                    </v-btn>
                  </template>
                  <span>Refresh</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="winnersFormatted"
              :items-per-page="5"
              :loading="gameWinnersLoading"
              loading-text="Loading, please wait a moment"
            >
              <template v-slot:[`item.action`]="{ item }">
                <div v-if="$store.state.accountInfo.access.includes(8)">
                  <v-tooltip right v-if="item.bet_status == 'For Verification'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        small
                        color="primary"
                        @click="confirmVerify(item)"
                      >
                        <v-icon size="large">
                          mdi-check-underline-circle-outline
                        </v-icon>
                      </v-btn>
                    </template>

                    <span>Verify</span>
                  </v-tooltip>

                  <v-tooltip right v-if="item.bet_status == 'Verified'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        small
                        color="primary"
                        @click="confirmUnverify(item)"
                      >
                        <v-icon size="large"> mdi-arrow-u-left-bottom </v-icon>
                      </v-btn>
                    </template>
                    <span>Unverify</span>
                  </v-tooltip>

                  <v-btn
                    v-if="item.bet_status == 'Approved'"
                    icon
                    small
                    style="pointer-events: none"
                    class="green--text"
                  >
                    <v-icon size="large">
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </v-btn>
                </div>

                <div v-if="$store.state.accountInfo.access.includes(9)">
                  <v-btn
                    v-if="item.bet_status == 'For Verification'"
                    icon
                    small
                    style="pointer-events: none"
                    class="grey--text"
                  >
                    <v-icon size="large">
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </v-btn>

                  <v-tooltip right v-if="item.bet_status == 'Verified'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        small
                        color="primary"
                        @click="confirmApprove(item)"
                      >
                        <v-icon size="large">
                          mdi-checkbox-marked-circle-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Approve</span>
                  </v-tooltip>

                  <v-btn
                    v-if="item.bet_status == 'Approved'"
                    icon
                    small
                    style="pointer-events: none"
                    class="green--text"
                  >
                    <v-icon size="large">
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </v-btn>
                </div>
              </template>

              <template v-slot:[`item.formattedCardNumber`]="{ item }">
                <router-link
                  :to="{
                    name: 'EndingCardDetails',
                    params: { id: item.card_id },
                  }"
                  >{{ item.formattedCardNumber }}</router-link
                >
              </template>

              <template v-slot:[`item.bet_status`]="{ item }">
                <v-chip
                  small
                  :class="
                    item.bet_status == 'Verified'
                      ? 'blue lighten-5 blue--text'
                      : item.bet_status == 'Approved'
                      ? 'green lighten-5 green--text'
                      : 'orange lighten-5 orange--text'
                  "
                >
                  {{ item.bet_status }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="verifyDialog" max-width="300">
      <v-card class="py-2">
        <v-card-title class="text-h6 d-block text-center">
          Confirm Verify
        </v-card-title>
        <v-card-text class="text-center">
          Do you really want to verify?
        </v-card-text>

        <v-card-actions class="d-block text-center">
          <v-btn
            ref="verifyYes"
            color="primary"
            outlined
            small
            @click="verify"
            :loading="verifyWinnerLoading"
          >
            Yes
          </v-btn>

          <v-btn color="black" outlined small @click="verifyDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="unverifyDialog" max-width="300">
      <v-card class="py-2">
        <v-card-title class="text-h6 d-block text-center">
          Confirm Unverify
        </v-card-title>
        <v-card-text class="text-center">
          Do you really want to unverify?
        </v-card-text>

        <v-card-actions class="d-block text-center">
          <v-btn
            ref="unverifyYes"
            color="primary"
            outlined
            small
            @click="unverify"
            :loading="unverifyWinnerLoading"
          >
            Yes
          </v-btn>

          <v-btn color="black" outlined small @click="unverifyDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="approveDialog" max-width="300">
      <v-card class="py-2">
        <v-card-title class="text-h6 d-block text-center">
          Confirm Approve
        </v-card-title>
        <v-card-text class="text-center">
          Do you really want to approve?
        </v-card-text>

        <v-card-actions class="d-block text-center">
          <v-btn
            ref="approveYes"
            color="primary"
            outlined
            small
            @click="approve"
            :loading="approveWinnerLoading"
          >
            Yes
          </v-btn>

          <v-btn color="black" outlined small @click="approveDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="insufficientDialog" max-width="300">
      <v-card class="py-2">
        <v-card-title class="text-h6 d-block text-center">
          Insufficient
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="py-4 text-center red--text">
          Agent points are insufficient.
        </v-card-text>

        <v-card-actions class="d-block text-center">
          <v-btn
            block
            color="primary"
            outlined
            @click="insufficientDialog = false"
          >
            GOT IT!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="postponeDialog" max-width="300">
      <v-card class="py-2">
        <v-card-title class="text-h6 d-block text-center">
          Confirm Postpone
        </v-card-title>

        <v-card-text class="text-center">
          Do you really want to tag this as postponed?
        </v-card-text>

        <v-card-actions class="d-block text-center">
          <v-btn
            ref="approveYes"
            color="primary"
            outlined
            small
            @click="postponeHandler"
            :loading="postponeLoading"
          >
            Yes
          </v-btn>

          <v-btn color="black" outlined small @click="postponeDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <UpdateGame v-if="dialog" :id="id" @setCloseUpdateModal="setUpdateModal" />

    <UpdateResult
      v-if="dialogUpdateResult"
      :result_data="result_data"
      @setCloseUpdateResultModal="setCloseUpdateResultModal"
    />

    <SendSmsDialog
      v-if="sendSmsDialog"
      :sendSmsData="sendSmsData"
      @closeSmsDialog="closeSmsDialog"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formatDate from "@/helpers/formatDate";
import formatDate2 from "@/helpers/formatDatev2";
import CreateResult from "@/components/ending/scheduledGames/CreateResult";
import CreateCard from "@/components/ending/scheduledGames/CreateCard";
import ViewCards from "@/components/ending/scheduledGames/ViewCards";
import SettingsBetting from "@/components/ending/scheduledGames/settings/Betting";
import AddCard from "@/components/ending/scheduledGames/AddCard";
import SubtractCard from "@/components/ending/scheduledGames/SubtractCard";
import UpdateResult from "@/components/ending/scheduledGames/UpdateResult";
import UpdateGame from "@/components/ending/scheduledGames/UpdateGame";
import SendSmsDialog from "@/components/ending/sendSms/SendSmsDialog";
import SettingsLuckyFour from "@/components/lucky_four/game_details/EnableLuckyFourBtn";
import SettingsPowerBall from "@/components/power_ball/EnablePowerBallBtn";

export default {
  components: {
    CreateResult,
    SettingsBetting,
    CreateCard,
    ViewCards,
    AddCard,
    SubtractCard,
    UpdateResult,
    UpdateGame,
    SendSmsDialog,
    SettingsLuckyFour,
    SettingsPowerBall,
  },

  props: {
    id: {
      required: true,
    },
  },

  data: () => ({
    dialog: false,
    betting: false,
    dialogUpdateResult: false,
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      {
        text: "Player Name",
        sortable: false,
        value: "player_name",
        class: "text-uppercase",
      },
      {
        text: "Card Number",
        sortable: false,
        value: "formattedCardNumber",
        class: "text-uppercase",
      },
      {
        text: "Combination#",
        sortable: false,
        value: "combination",
        class: "text-uppercase",
      },
      {
        text: "Quarter",
        sortable: false,
        value: "quarter",
        class: "text-uppercase",
      },
      {
        text: "Bet Amount",
        sortable: false,
        value: "formattedBetAmount",
        class: "text-uppercase",
      },
      {
        text: "Prize",
        sortable: false,
        value: "formattedPrize",
        class: "text-uppercase",
      },
      { text: "Status", value: "bet_status", class: "text-uppercase" },
    ],
    verifyDialog: false,
    verifyBetId: "",
    verifyQuarterId: "",
    unverifyDialog: false,
    unverifyBetId: "",
    unverifyQuarterId: "",
    approveDialog: false,
    approveBetId: "",
    approveQuarterId: "",
    emailSent: 0,
    insufficientDialog: false,
    smsToPlayersSent: 0,
    result_data: {},
    postponeDialog: false,
    sendSmsDialog: false,
    sendSmsData: {},
  }),

  created() {
    if (!this.detailsLoading) {
      this.$store.commit("ending_game_details/setDetails", []);
      this.getDetails(this.id);
    }

    if (this.accountInfo.access.includes(12)) {
      this.loadBetAmounts();
    }

    if (this.accountInfo.access.includes(34)) {
      this.loadGameWinners();
    }
  },

  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("ending_game_details", [
      "details",
      "detailsLoading",
      "cardThreshold",
      "betAmounts",
      "betAmountsLoading",
      "gameWinners",
      "gameWinnersLoading",
      "verifyWinnerError",
      "verifyWinnerLoading",
      "unverifyWinnerError",
      "unverifyWinnerLoading",
      "approveWinnerError",
      "approveWinnerLoading",
      "generatePdfError",
      "generatePdfLoading",
      "sendGeneratePdfError",
      "sendGeneratePdfLoading",
      "sendSMSNotifToPlayersHasError",
      "sendSMSNotifToPlayersLoading",
      "postponeLoading",
      "postponeError",
    ]),

    formattedSchedule() {
      return formatDate(this.details.schedule, true);
    },

    formattedCreatedAt() {
      return formatDate(this.details.created_at, true);
    },

    winnersFormatted() {
      if (this.gameWinners) {
        return this.gameWinners.map((winner) => {
          return {
            ...winner,
            formattedCardNumber: "#" + winner.card_number,
            formattedBetAmount:
              "PHP " + this.moneyFormat(parseFloat(winner.bet_amount)),
            formattedPrize:
              winner.is_cash == "Cash"
                ? "PHP " + this.moneyFormat(parseFloat(winner.prize))
                : winner.prize,
          };
        });
      } else {
        return [];
      }
    },

    totalCards() {
      if (this.betAmounts) {
        return this.betAmounts.reduce(
          (total, obj) => parseInt(obj.card_count) + total,
          0
        );
      } else {
        return 0;
      }
    },

    disableGenerate() {
      if (parseInt(this.details.generated_pdf_cards) > 0) {
        if (parseInt(this.details.generated_pdf_cards) === this.totalCards) {
          return true;
        } else {
          return false;
        }
      } else {
        if (parseInt(this.details.generated_pdf_cards) === this.totalCards) {
          return true;
        } else {
          return false;
        }
      }
    },
  },

  methods: {
    ...mapActions("ending_game_details", [
      "getDetails",
      "getBetAmounts",
      "getGameWinners",
      "verifyWinner",
      "unverifyWinner",
      "approveWinner",
      "generateCardsPdf",
      "sendGenerateCardsPdf",
      "sendSMSNotifToPlayers",
      "doPostponeGame",
    ]),

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    loadBetAmounts() {
      if (!this.betAmountsLoading) {
        this.$store.commit("ending_game_details/setBetAmounts", []);
        this.getBetAmounts(this.id);
      }
    },

    loadGameWinners() {
      if (!this.gameWinnersLoading) {
        this.$store.commit("ending_game_details/setGameWinners", []);
        this.getGameWinners(this.id);
      }
    },

    updateResults(result) {
      this.details.results.push({
        result_id: result.result_id,
        quarter_id: result.quarter_id,
        quarter: result.quarter,
        team_a_score: result.team_a_score,
        team_b_score: result.team_b_score,
        combination: result.combination,
      });

      this.details.status = result.game_status;

      this.loadGameWinners();
    },

    confirmVerify(item) {
      this.verifyDialog = true;
      this.verifyBetId = item.bet_id;
      this.verifyQuarterId = item.quarter_id;
    },

    async verify() {
      const formData = new FormData();
      formData.append("game_id", this.id);
      formData.append("bet_id", this.verifyBetId);
      formData.append("quarter_id", this.verifyQuarterId);

      if (!this.verifyWinnerLoading) {
        await this.verifyWinner(formData);

        if (!this.verifyWinnerError) {
          this.verifyDialog = false;

          this.gameWinners.find(
            (winner) =>
              winner.bet_id == this.verifyBetId &&
              winner.quarter_id == this.verifyQuarterId
          ).bet_status = "Verified";
        }
      }
    },

    confirmUnverify(item) {
      this.unverifyDialog = true;
      this.unverifyBetId = item.bet_id;
      this.unverifyQuarterId = item.quarter_id;
    },

    async unverify() {
      const formData = new FormData();
      formData.append("game_id", this.id);
      formData.append("bet_id", this.unverifyBetId);
      formData.append("quarter_id", this.unverifyQuarterId);

      if (!this.unverifyWinnerLoading) {
        await this.unverifyWinner(formData);

        if (!this.unverifyWinnerError) {
          this.unverifyDialog = false;

          this.gameWinners.find(
            (winner) =>
              winner.bet_id == this.unverifyBetId &&
              winner.quarter_id == this.unverifyQuarterId
          ).bet_status = "For Verification";
        }
      }
    },

    confirmApprove(item) {
      this.approveDialog = true;
      this.approveBetId = item.bet_id;
      this.approveQuarterId = item.quarter_id;
    },

    async approve() {
      const formData = new FormData();
      formData.append("game_id", this.id);
      formData.append("bet_id", this.approveBetId);
      formData.append("quarter_id", this.approveQuarterId);

      if (!this.approveWinnerLoading) {
        await this.approveWinner(formData);

        if (!this.approveWinnerError) {
          this.approveDialog = false;

          this.gameWinners.find(
            (winner) =>
              winner.bet_id == this.approveBetId &&
              winner.quarter_id == this.approveQuarterId
          ).bet_status = "Approved";
        } else {
          this.approveDialog = false;
          this.insufficientDialog = true;
        }
      }
    },

    generatePdf() {
      const text = "Are you sure you want to generate?";
      if (confirm(text) == true) {
        this.doGeneratePdf();
      }
    },

    async doGeneratePdf() {
      const formData = new FormData();
      formData.append("game_id", this.id);

      if (!this.generatePdfLoading) {
        const res = await this.generateCardsPdf(formData);

        if (!this.generatePdfError) {
          this.details.generated_pdf_cards =
            parseInt(this.details.generated_pdf_cards) + res.generated;
        }
      }
    },

    sendGeneratePdf() {
      const text = "Are you sure you want to send email?";
      if (confirm(text) == true) {
        this.doSendGeneratePdf();
      }
    },

    async doSendGeneratePdf() {
      const formData = new FormData();
      formData.append("game_id", this.id);

      if (!this.sendGeneratePdfLoading) {
        await this.sendGenerateCardsPdf(formData);

        if (!this.sendGeneratePdfError) {
          this.emailSent = 1;

          setTimeout(() => {
            this.emailSent = 0;
          }, 1000);
        }
      }
    },

    sendSMSToPlayers() {
      const text = "Are you sure you want to send sms?";
      if (confirm(text) == true) {
        this.doSendSMSToPlayers();
      }
    },

    async doSendSMSToPlayers() {
      if (!this.sendSMSNotifToPlayersLoading) {
        const formData = new FormData();
        formData.append("game_id", this.id);

        await this.sendSMSNotifToPlayers(formData);

        if (!this.sendSMSNotifToPlayersHasError) {
          this.smsToPlayersSent = 1;

          setTimeout(() => {
            this.smsToPlayersSent = 0;
          }, 10000);
        }
      }
    },

    setUpdateModal(updated_game_details) {
      this.dialog = false;

      if (updated_game_details.action == "update") {
        this.$store.commit("ending_game_details/setDetails", []);
        this.getDetails(this.id);
      }
    },

    showUpdateResult(item) {
      this.dialogUpdateResult = true;
      this.result_data = {
        game_id: this.id,
        quarter_id: item.quarter_id,
        quarter: item.quarter,
        result_id: item.result_id,
        combination: item.combination,
        team_a_score: item.team_a_score,
        team_b_score: item.team_b_score,
        team_a: this.details.team_a_name,
        team_b: this.details.team_b_name,
      };
    },

    setCloseUpdateResultModal(updatedData) {
      this.dialogUpdateResult = false;

      if (updatedData) {
        this.details.results[
          parseInt(updatedData.quarter_id) - 1
        ].team_a_score = updatedData.team_a_score;
        this.details.results[
          parseInt(updatedData.quarter_id) - 1
        ].team_b_score = updatedData.team_b_score;
        this.details.results[parseInt(updatedData.quarter_id) - 1].combination =
          updatedData.combination;

        this.loadGameWinners();
      }
    },

    postponeHandler() {
      const formData = new FormData();

      formData.append("game_id", this.id);

      if (!this.postponeLoading) {
        this.doPostponeGame(formData);

        if (!this.postponeError) {
          this.postponeDialog = false;
          this.$store.commit("ending_game_details/setDetails", []);
          this.getDetails(this.id);
        }
      }
    },

    setSendSmsDialog() {
      this.sendSmsData["league"] = this.details.league;
      this.sendSmsData["team_a_alias"] = this.details.team_a_alias;
      this.sendSmsData["team_b_alias"] = this.details.team_b_alias;
      this.sendSmsData["game_date"] = formatDate2(
        this.details.schedule,
        "monthDay"
      );
      this.sendSmsData["game_time"] = formatDate2(
        this.details.schedule,
        "timeOnly"
      );
      this.sendSmsDialog = true;
    },

    closeSmsDialog(smsStatus) {
      if (smsStatus) {
        this.smsToPlayersSent = 1;

        setTimeout(() => {
          this.smsToPlayersSent = 0;
        }, 1000);
      }

      this.sendSmsDialog = false;
    },
  },
  watch: {
    details(val) {
      if (val) {
        this.betting = val.betting == "Enabled" ? true : false;
      }
    },

    verifyDialog(visible) {
      if (visible) {
        setTimeout(() => {
          this.$refs.verifyYes.$el.focus();
        }, 200);
      }
    },

    unverifyDialog(visible) {
      if (visible) {
        setTimeout(() => {
          this.$refs.unverifyYes.$el.focus();
        }, 200);
      }
    },

    approveDialog(visible) {
      if (visible) {
        setTimeout(() => {
          this.$refs.approveYes.$el.focus();
        }, 200);
      }
    },
  },
};
</script>