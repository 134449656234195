<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Players</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Reject Reasons</h2>
    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="insertReason"
                >
                  <v-icon>mdi-plus-box-outline</v-icon>
                </v-btn>
              </template>

              <span>Add Reason</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="3" class="d-flex">
            <v-text-field
              v-model="search"
              ref="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search Reason"
              single-line
              hide-details="auto"
              :disabled="getRecordsLoading"
              @keydown.enter.prevent="searchRecords"
              clearable
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="getRecords"
          :options.sync="options"
          :loading="getRecordsLoading"
          :server-items-length="getTotalRows"
          :sort-desc.sync="sortDesc"
          loading-text="Loading... Please wait"
          hide-default-footer
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dense
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  color="primary"
                  @click="updateReason(item)"
                >
                  <v-icon size="large"> mdi-square-edit-outline </v-icon>
                </v-btn>
              </template>

              <span>Update</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.is_active`]="{ item }">
            <v-chip
              :class="
                item.is_active === 'Yes'
                  ? 'green lighten-5 green--text'
                  : 'red lighten-5 red--text'
              "
              >{{ item.is_active === "Yes" ? "Active" : "Inactive" }}</v-chip
            >
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ formatDate(item.created_at, true) }}</span>
          </template>
        </v-data-table>
        <div class="pa-4">
          <v-row class="d-flex flex-row-reverse mt-4">
            <v-col cols="4" lg="2">
              <v-select
                v-model="itemsPerPage"
                :items="rowNumbers"
                label="Row per page"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col>
              <v-chip color="primary" outlined pill>
                Total:
                {{ getTotalRows }}
              </v-chip>
            </v-col>
          </v-row>
          <Pagination
            :storeName="storeName"
            :isDisabled="isDisabled"
            @paginatePage="loadRecords"
          />
        </div>
      </v-card-text>
    </v-card>
    <!------------------------------------>
    <UpsertDialog
      ref="upsertDialog"
      :isCreate="isCreate"
      :loading="getUpsertLoading"
      :width="400"
      @upsertDialog="upsertDialog"
    >
      <template #textFields>
        <v-text-field
          ref="reason"
          v-model="form.reason"
          :rules="[rules.required]"
          label="Reason"
          outlined
          dense
          hide-details="auto"
          clearable
        />
      </template>
      <template #selectFields>
        <v-select
          :rules="[rules.required]"
          v-model="form.category"
          :items="categories"
          label="Select Category"
          dense
          outlined
          hide-details="auto"
          clearable
        />
      </template>
      <template #switchFields>
        <v-switch
          v-model="form.isActive"
          v-show="!isCreate"
          :label="form.isActive ? 'Active' : 'Inactive'"
          color="success"
          hide-details
        />
      </template>
    </UpsertDialog>

    <v-snackbar v-model="snackbarModel" :timeout="2000" top color="success">
      {{ isCreate ? "Created" : "Updated" }} successfully
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbarModel = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
//import { format } from "date-fns";
import formatDate from "@/helpers/formatDate";
import Pagination from "@/components/Pagination";
import UpsertDialog from "@/components/UpsertDialog";
import formRules from "@/helpers/formRules";

export default {
  components: { Pagination, UpsertDialog },
  data: () => ({
    rules: formRules,
    search: "",
    sortBy: "created_at",
    sortDesc: true,
    itemsCountPage: 5,
    rowNumbers: [5, 10, 20, 50, 100, 500],
    categories: ["Invalid Selfie", "Invalid ID", "Invalid Occupation", "Others"],
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      {
        text: "Reason",
        value: "reason",
        class: "text-uppercase",
      },
      {
        text: "Category",
        value: "category",
        class: "text-uppercase",
      },
      {
        text: "Status",
        value: "is_active",
        class: "text-uppercase",
      },
      {
        text: "Created At",
        value: "created_at",
        class: "text-uppercase",
      },
    ],
    options: {},
    form: {
      id: 0,
      reason: null,
      category: null,
      isActive: false,
    },
    storeName: "player_reject_reasons",
    currentSearchValue: "",
    isCreate: true,
  }),

  computed: {
    ...mapGetters("player_reject_reasons", [
      "getRecords",
      "getRecordsLoading",
      "getUpsertLoading",
      "getShowSnackbar",
      "getTotalRows",
      "getCurrentPage",
    ]),

    itemsPerPage: {
      get() {
        return this.itemsCountPage;
      },

      set(val) {
        this.itemsCountPage = val;
      },
    },

    snackbarModel: {
      get() {
        return this.getShowSnackbar;
      },

      set(val) {
        this.setShowSnackbar(val);
      },
    },

    isDisabled() {
      return this.currentSearchValue !== this.search;
    },

    isRecordsEmpty() {
      return this.getRecords.length <= 0;
    },
  },

  methods: {
    ...mapActions("player_reject_reasons", [
      "fetchRecords",
      "insertRecords",
      "updateRecords",
    ]),
    ...mapMutations("player_reject_reasons", [
      "setCurrentPage",
      "setShowSnackbar",
    ]),

    formatDate,

    searchRecords() {
      this.currentSearchValue = this.search;
      this.setCurrentPage(1);
      this.loadRecords();
      setTimeout(() => {
        this.$refs.search.focus();
      }, 300);
    },

    loadRecords() {
      if (!this.getRecordsLoading) {
        const formData = new FormData();

        formData.append("search", this.currentSearchValue);
        formData.append("itemsPerPage", this.itemsPerPage);
        formData.append("page", this.getCurrentPage);
        formData.append("sortBy", this.sortBy ? this.sortBy : "");
        formData.append("sortDesc", this.sortDesc ? true : "");

        const getData = new URLSearchParams(formData);
        this.fetchRecords(getData);
      }
    },

    async upsertDialog(data) {
      const dataToPass = {
        reason: this.form.reason,
        category: this.form.category,
        isActive: this.isCreate ? "Yes" : this.form.isActive ? "Yes" : "No",
      };

      if (data.method === "create") {
        await this.insertRecords(dataToPass);
      } else if (data.method === "update") {
        await this.updateRecords({ data: dataToPass, id: this.form.id });
      }

      this.$refs.upsertDialog.isDialogOpen = false;
      this.$refs.upsertDialog.$refs.form.reset();
      this.loadRecords();
    },

    insertReason() {
      this.form.id = 0;
      this.form.reason = null;
      this.form.category = null;
      this.form.isActive = true;
      this.isCreate = true;
      this.$refs.upsertDialog.isDialogOpen = true;
      setTimeout(() => {
        this.$refs.reason.focus();
      }, 300);
    },

    updateReason(item) {
      this.form.id = item.id;
      this.form.reason = item.reason;
      this.form.category = item.category;
      this.form.isActive = item.is_active === "Yes" ? true : false;
      this.isCreate = false;
      this.$refs.upsertDialog.isDialogOpen = true;
      setTimeout(() => {
        this.$refs.reason.focus();
      }, 300);
    },
  },

  watch: {
    search(val) {
      if (val === null) {
        this.search = "";
      }
    },

    options() {
      if (this.options.sortBy[0]) {
        this.sortBy = this.options.sortBy[0];
      }

      if (this.options.sortDesc[0] != undefined) {
        this.sortDesc = this.options.sortDesc[0];
      }

      this.loadRecords();
    },

    itemsPerPage(oldVal) {
      if (oldVal) {
        if (!this.getRecordsLoading) {
          this.options.itemsPerPage = this.itemsPerPage;
          this.searchRecords();
        }
      }
    },
  },
};
</script>
