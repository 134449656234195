<template>
    <v-dialog
      v-model="dialog"
      max-width="700"      
    >
      <v-card>
        <v-card-title class="text-h5">
          Filter
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="py-4"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="proceedToSearch"
          >
            <v-row>
              <v-col cols="12" md="12">
                <v-autocomplete
                  ref="league"
                  v-model="filterLeague"
                  :items="leagues"
                  item-text="league"
                  item-value="id"
                  label="League"
                  dense
                  outlined
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6" class="mt-4">
                <v-menu
                  v-model="schedule_date_start_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="schedule_date_start"
                      :rules="[rules.required]"
                      label="Schedule Date Start"
                      hide-details="auto"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @keyup="schedule_date_start_menu=true"
                      @keydown="schedule_date_start_menu=false"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="schedule_date_start"
                    @input="schedule_date_start_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" class="mt-4">
                <v-menu
                  v-model="schedule_date_end_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="schedule_date_end"
                      label="Schedule Date End (Optional)"
                      hide-details="auto"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @keyup="schedule_date_end_menu=true"
                      @keydown="schedule_date_end_menu=false"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="schedule_date_end"
                    @input="schedule_date_end_menu = false"
                    :min="(schedule_date_start) ? schedule_date_start : ''"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="6" class="mt-2">
                <v-btn
                  depressed
                  block
                  type="submit"
                  color="primary"
                >
                  Filter
                </v-btn>
              </v-col>

              <v-col cols="12" md="6" class="mt-2">
                <v-btn
                  depressed
                  block
                  outlined
                  color="black"
                  @click="cancelSearch"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from "vuex"
import formRules from '@/helpers/formRules'

export default {
    props: {
        filter_data: {
            type: Object
        },
    },
    data: () => ({
        valid: true,
        rules: formRules,
        filterLeague: '',
        schedule_date_start: '',
        schedule_date_start_menu: false,
        schedule_date_end: '',
        schedule_date_end_menu: false,
        filter_details: {}
    }), 
    computed: {
        ...mapState("power_ball_games", ["leagues"]),
        dialog: {
            get: function () {
                return true;
            },
            set: function () {
                this.filter_details = { "action": "cancel" }
                this.closeModal()
            },
        }
    },
    mounted() {
        this.schedule_date_start = this.filter_data.date_from
        this.schedule_date_end = this.filter_data.date_to

        if (this.filter_data.league) {
            this.filterLeague = this.filter_data.league
        }
    },
    methods: {
        closeModal() {
            this.$emit("setCloseModal", this.filter_details)
        },
        proceedToSearch() {
            if (this.$refs.form.validate()) {
                this.filter_details = { 
                    "action": "search", 
                    "date_from": this.schedule_date_start, 
                    "date_to": (this.schedule_date_end) ?? this.schedule_date_start, 
                    "league": (this.filterLeague) ?? 0
                }
                this.closeModal()
            }
        }, 
        cancelSearch() {
            this.filter_details = { "action": "cancel" }
            this.closeModal()
        }
    }
}
</script>