<template>
    <div>
        <v-dialog
            v-model="dialog"
            max-width="500">
            <v-card>
                <v-card-title class="text-h5">
                    Filter
                </v-card-title>

                <v-divider/>
                    <v-card-text class="py-4">
                        <v-form ref="form"
                            v-model="valid"
                            lazy-validation
                            @submit.prevent="proceedToSearch">

                            <v-row>
                                <v-col>
                                        <v-menu
                                        v-model="date_from_menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            v-model="date_from"
                                            :rules="[rules.required, lessThanDate2]"
                                            label="Date From"
                                            hide-details="auto"
                                            readonly
                                            outlined   
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                            @keyup="date_from_menu=true"
                                            @keydown="date_from_menu=false"
                                            clearable
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date_from"
                                            @input="date_from_menu = false"
                                        ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                        <v-menu
                                        v-model="date_to_menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                            v-model="date_to"
                                            :rules="[lessThanDate2]"
                                            label="Date To (Optional)"
                                            hide-details="auto"
                                            readonly
                                            outlined   
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                            @keyup="date_to_menu=true"
                                            @keydown="date_to_menu=false"
                                            :disabled="dateToAvailability"
                                            clearable
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date_to"
                                            @input="date_to_menu = false"
                                            :min="(date_from) ? date_from : ''"
                                        ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                            </v-row>

                             <v-row>
                                <v-col>
                                    <v-select
                                        v-model="filter"
                                        :items="filterStatus"
                                        :rules="[rules.required]"
                                        label="Status"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        clearable/>
                                </v-col>
                            </v-row>

                            <v-row dense>
                                <v-col>
                                    <v-btn
                                        block
                                        depressed
                                        :disabled="!valid"
                                        :loading="recordsLoading"
                                        type="submit"
                                        color="primary">
                                        Search
                                    </v-btn>
                                </v-col>

                                <v-col>
                                    <v-btn
                                        block
                                        depressed
                                        :loading="recordsLoading"
                                        type="button"
                                        @click="cancelSearch">
                                        Cancel
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import formRules from '@/helpers/formRules'

export default {
    props: {
        dates: {
             type: Object,
        }
    },
    data: () => ({
        valid: true,
        rules: formRules,
        date_from:'',
        date_from_menu: false,
        date_to: '',
        date_to_menu: false,
        dates_filter: [],
        filterStatus: ['All', 'Pending', 'Verified', 'Rejected', 'Approved', 'Disapproved'],
        filter: 'Pending'
    }),
    mounted() {
        this.date_from = this.dates.date_from
        this.date_to = this.dates.date_to
        this.filter = this.dates.filter
    },
    computed: {
        ...mapState("cashout", ["recordsLoading"]),
        lessThanDate() { 
            if ((this.date_from) && (this.date_to)) {
                if (new Date(this.date_to) < new Date(this.date_from)) {
                    return 'Must be higher or equal than date from.'
                } 
            } 
            
            return true
        },
        lessThanDate2() { 
            if ((this.date_from) && (this.date_to)) {
                if (new Date(this.date_from) > new Date(this.date_to)) {
                return 'Must be lessthan or equal than date to.'
                }
            } 
            
            return true
        },

        dateToAvailability() {
            if (this.date_from == '' || this.date_from == null) {
                return true
            }

            return false
        },

        dialog: {
            get: function () {
                return true;
            },
            set: function () {
                this.cancelSearch()
            },
        },
    },

    methods: {
        closeModal() {
            this.$emit("setCloseModal", this.dates_filter)
        },
        cancelSearch() {
            this.dates_filter = {
                "filter_status": 'cancel',
                "filter_date_from": this.date_from,
                "filter_date_to":  (this.date_to) ?? this.date_from,
                "filter":  this.filter,
            }
        
            this.closeModal();
        },
        proceedToSearch() {
            this.dates_filter = {
                "filter_status": 'search',
                "filter_date_from": this.date_from,
                "filter_date_to":  (this.date_to) ?? this.date_from,
                "filter":  this.filter,
            }
        
            this.closeModal();
        }
    },

    watch: {
        date_from (val) {
            if (val == '' || val == null) {
                this.date_to = ''
            }
        }
    }
}
</script>