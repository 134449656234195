<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <Navbar v-if="!$route.meta.hideNavbar" />
    <v-main style="padding-top: 64px !important">
      <router-view />
    </v-main>

    <!-- Popup Error -->
    <v-dialog v-model="popUpErrorModal" persistent max-width="500">
      <v-card>
        <v-card-title class="text-h5">
          <span class="orange--text text--darken-1">Warning</span>
        </v-card-title>
        <v-card-text>{{ popUpError }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closePopUpErrorModal"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";
import { mapState } from "vuex";

export default {
  components: { Navbar },
  data: () => ({
    popUpErrorModal: false,
  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    ...mapState(["popUpError"]),
  },
  methods: {
    closePopUpErrorModal() {
      this.popUpErrorModal = false;
      this.$store.commit("setPopUpError", null);
    },
  },
  watch: {
    popUpError(val) {
      if (val) {
        this.popUpErrorModal = true;
      }
    },
  },
};
</script>
