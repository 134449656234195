var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Players")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Player List")]),_c('v-card',{staticClass:"shadow"},[_c('div',{staticClass:"d-flex justify-space-between align-center bs-py-3 bs-px-4"},[_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":_vm.doGetPlayers}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cached")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('downloadexcel',{staticClass:"d-inline-block",attrs:{"data":_vm.records,"fields":{
                'PLAYER ID': 'id',
                'FIRST NAME': 'first_name',
                'MIDDLE NAME': 'middle_name',
                'LAST NAME': 'last_name',
                'MOBILE NUMBER': {
                  callback: function (value) {
                    return ("+63" + (value.mobile_number));
                  },
                },
                EMAIL: 'email',
                'MARITAL STATUS': 'marital_status',
                BIRTHDATE: 'birthdate',
                NATIONALITY: 'nationality',
                STREET: 'street',
                BARANGAY: 'barangay',
                'CITY/ MUNICIPALITY': 'city_municipality',
                PROVINCE: 'province',
                'SOURCE OF INCOME': 'source_of_income',
                'NATURE OF WORK': 'nature_of_work',
                'NATURE OF BUSINESS': 'nature_of_business',
                'VERIFICATION STATUS': 'account_verified',
                'ACCOUNT STATUS': 'account_status',
                'ADMIN DEACTIVATE REASON': 'admin_deactivate_reason',
                'PLAYER DEACTIVATE REASON': 'player_deactivate_reason',
                'REGISTERED DATE': {
                  callback: function (value) {
                    return _vm.format_date(value.created_at);
                  },
                },
              },"name":_vm.downloadExcelName(),"type":"csv"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-delimited-outline")])],1)],1)]}}])},[_c('span',[_vm._v("Export to EXCEL")])])],1),_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center","gap":"1rem"}},[_c('v-text-field',{attrs:{"placeholder":"Search","single-line":"","hide-details":"auto","disabled":_vm.records_loading,"clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"secondary","disabled":_vm.records_loading},on:{"click":_vm.set_filter_dialog}},on),[_c('v-icon',[_vm._v("mdi-filter-variant")])],1)]}}])},[_vm._v(" Filter ")])]],2)]),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":_vm.item_per_page_value,"search":_vm.search,"loading":_vm.records_loading,"loading-text":"Loading, please wait a moment","hide-default-footer":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"page":_vm.page},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.show_details(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-text-box-search-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Details")])])]}},{key:"item.mobile_number",fn:function(ref){
              var item = ref.item;
return [_vm._v(" +63"+_vm._s(item.mobile_number)+" ")]}},{key:"item.account_verified",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{class:item.account_verified == 'Verified'
                ? 'green lighten-5 green--text'
                : item.account_verified == 'Pending'
                ? 'orange lighten-5 orange--text'
                : item.account_verified == 'Rejected'
                ? 'red lighten-5 red--text'
                : 'blue lighten-5 blue--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.account_verified)+" ")])]}},{key:"item.verified_at",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.format_date(item.verified_at)))])]}},{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.format_date(item.created_at)))])]}},{key:"item.others",fn:function(ref){
                var item = ref.item;
return [_c('PlayerOtherDetails',{attrs:{"data":{
              marital_status: item.marital_status,
              birthdate: item.birthdate,
              nationality: item.nationality,
              source_of_income: item.source_of_income,
              nature_of_work: item.nature_of_work,
              nature_of_business: item.nature_of_business,
              address: item.address,
              account_status: item.account_status,
              admin_reason: item.admin_deactivate_reason,
              player_reason: item.player_deactivate_reason,
              active_status: item.account_status,
            }}})]}}],null,true)}),(_vm.records && _vm.records.length != 0)?_c('div',[_c('v-divider'),_c('div',{staticClass:"d-flex align-center justify-space-between gap py-4 px-4 px-md-6"},[_c('div',{staticClass:"d-flex align-center gap"},[_c('v-select',{staticStyle:{"width":"100px"},attrs:{"value":_vm.item_per_page_value,"items":_vm.item_per_page,"outlined":"","dense":"","label":"Items","hide-details":true},on:{"change":function($event){_vm.item_per_page_value = parseInt($event, 10)}}}),_c('div',[_vm._v(" Page "+_vm._s(_vm.page ? _vm.number_format(parseInt(_vm.page)) : "0" + "-" + _vm.item_per_page_value ? _vm.item_per_page_value : "0")+" of "+_vm._s(_vm.pageCount ? _vm.number_format(parseInt(_vm.pageCount)) : "0")+" - "+_vm._s((" " + (_vm.number_format(parseInt(_vm.records.length)))))+" ")])],1),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":0},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1):_vm._e()],1)],1),(_vm.filter_dialog)?_c('Filters',{attrs:{"data":_vm.filter_data},on:{"set_close_filter_dialog":_vm.set_close_filter_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }