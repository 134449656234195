<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Monitoring</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Game Card</h2>
    <v-card class="shadow">
      <v-card-title
        class="text-h6 pa-4 font-weight-regular grey--text text--darken-1 overline"
      >
        <v-row class="d-flex space-between">
          <v-col cols="12" md="6" lg="9">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  @click="loadRecords"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>

            Monitoring per league
          </v-col>

          <v-col cols="12" md="6" lg="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              clearable
              outlined
              dense
            >
              <template v-slot:append-outer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon color="secondary" v-on="on" small @click="showFilter">
                      <v-icon>mdi-filter-variant</v-icon>
                    </v-btn>
                  </template>
                  Filter
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-4">
        <v-data-table
          :headers="getHeader"
          :items="records"
          :items-per-page="5"
          :search="search"
          :loading="loading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dense
                  v-bind="attrs"
                  v-on="on"
                  :icon="$vuetify.breakpoint.mdAndUp ? true : false"
                  small
                  :outlined="$vuetify.breakpoint.mdAndUp ? false : true"
                  color="primary"
                  @click="showGames(item.id)"
                >
                  <v-icon size="large" v-if="$vuetify.breakpoint.mdAndUp">
                    mdi-basketball
                  </v-icon>

                  {{ $vuetify.breakpoint.mdAndUp ? "" : "View Per Game" }}
                </v-btn>
              </template>
              <span>View Per Game</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.logo`]="{ item }">
            <div>
              <v-avatar size="36px" tile>
                <v-img :src="item.logo">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="black" />
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </div>
          </template>

          <template v-slot:[`item.cards`]="{ item }">
            {{ parseInt(item.cards).toLocaleString() }}
          </template>

          <template v-slot:[`item.used_cards`]="{ item }">
            {{ parseInt(item.used_cards).toLocaleString() }} ({{
              getPercentage(item.cards, item.used_cards)
            }}%)
          </template>

          <template v-slot:[`item.un_used_cards`]="{ item }">
            {{ parseInt(item.un_used_cards).toLocaleString() }} ({{
              getPercentage(item.cards, item.un_used_cards)
            }}%)
          </template>

          <template v-slot:[`item.greater_than`]="{ item }">
            <v-chip small :class="getColor(item.cards, item.greater_than)">
              {{ parseInt(item.greater_than).toLocaleString() }} ({{
                getPercentage(item.cards, item.greater_than)
              }}%)
            </v-chip>
          </template>

          <template v-slot:[`item.less_than`]="{ item }">
            {{
              (parseInt(item.less_than) + parseInt(item.un_used_cards)).toLocaleString()
            }}
            ({{
              getPercentage(
                item.cards,
                parseInt(item.less_than) + parseInt(item.un_used_cards)
              )
            }}%)
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <DateFilter
      v-if="dateFilterDialog"
      :data_filters="data_filters"
      @setFilterCloseModal="setFilterCloseModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { format } from "date-fns";
import DateFilter from "@/components/monitoring/cardMonitoring/Filter";

export default {
  components: { DateFilter },

  data: () => ({
    search: "",
    sortBy: "id",
    sortDesc: true,
    date_from: format(new Date(), "yyyy-MM-dd"),
    date_to: format(new Date(), "yyyy-MM-dd"),
    dateFilterDialog: false,
    data_filters: {},
    defaultLoad: "unfiltered",
  }),

  created() {
    const urlQuery = this.$route.query;

    if (Object.keys(urlQuery).length) {
      this.defaultLoad = urlQuery.default_load;
      this.date_from = urlQuery.date_from;
      this.date_to = urlQuery.date_to;
    }

    this.loadRecords();
  },

  computed: {
    ...mapState("card_monitoring", [
      "loading",
      "records",
      "error",
      "errors",
      "cardTreshHold",
    ]),

    getHeader() {
      return [
        {
          text: "Action",
          sortable: false,
          value: "action",
          class: "text-uppercase",
          align: "center",
        },
        // { text: "ID", value: "id", align: "left" },
        {
          text: "League",
          sortable: false,
          value: "league",
          class: "text-uppercase",
        },
        {
          text: "Logo",
          sortable: false,
          value: "logo",
          class: "text-uppercase",
        },
        {
          text: "Card(s)",
          sortable: false,
          value: "cards",
          class: "text-uppercase",
          align: "right",
        },
        // {
        //     text: "Card(s) with Bet",
        //     sortable: false,
        //     value: "used_cards",
        //     class: "text-uppercase",
        //     align: "right"
        // },
        // {
        //     text: "Card(s) w/o Bet",
        //     sortable: false,
        //     value: "un_used_cards",
        //     class: "text-uppercase",
        //     align: "right"
        // },
        {
          text: `With >= ${this.cardTreshHold}%`,
          sortable: false,
          value: "greater_than",
          class: "text-uppercase",
          align: "right",
        },
        {
          text: `With < ${this.cardTreshHold}%`,
          sortable: false,
          value: "less_than",
          class: "text-uppercase",
          align: "right",
        },
      ];
    },
  },

  methods: {
    ...mapActions("card_monitoring", ["getRecords"]),

    async loadRecords() {
      this.$store.commit("card_monitoring/setCardTreshHold", 0);
      this.$store.commit("card_monitoring/setRecords", []);

      await this.getRecords(this.setRecordFormdata());
    },

    setRecordFormdata() {
      const formData = new FormData();

      formData.append("default_load", this.defaultLoad);
      formData.append("date_from", this.date_from);
      formData.append("date_to", this.date_to);

      const getData = new URLSearchParams(formData);

      return getData;
    },

    getPercentage(cards, val) {
      return ((val / cards) * 100).toFixed(2);
    },

    getColor(cards, val) {
      const percentage = (val / cards) * 100;
      let color = "";

      if (percentage == 100) {
        color = "red lighten-5 red--text";
      } else if (percentage > 90) {
        color = "orange lighten-5 orange--text";
      } else if (percentage > 70) {
        color = "amber lighten-5 amber--text";
      } else if (percentage > 50) {
        color = "green lighten-5 green--text";
      }

      return color;
    },

    showFilter() {
      this.dateFilterDialog = true;

      this.data_filters = {
        date_from: this.date_from,
        date_to: this.date_to,
      };
    },

    setFilterCloseModal(data_filter) {
      this.dateFilterDialog = false;

      if (data_filter.status == "search") {
        this.defaultLoad = "filtered";
        this.date_from = data_filter.date_from;
        this.date_to = data_filter.date_to;

        this.setQueryString();
        this.loadRecords();
      }
    },

    setQueryString() {
      const urlQuery = this.$route.query;

      if (urlQuery.date_from != this.date_from || urlQuery.date_to != this.date_to) {
        this.query_filters = {
          default_load: this.defaultLoad,
          date_from: this.date_from,
          date_to: this.date_to,
        };

        this.$router.push({
          name: this.$route.name,
          query: this.query_filters,
        });
      }
    },

    showGames(id) {
      this.setQueryString();
      this.$store.commit("card_monitoring/setRecords", []);
      this.$store.commit("card_monitoring/setLeague", {});
      this.$router.push({
        name: "GameCardPerGameMonitoring",
        params: {
          id: id,
          p_default_load: this.defaultLoad,
          p_date_from: this.date_from,
          p_date_to: this.date_to,
        },
      });
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>
