<template>
  <v-dialog scrollable v-model="dialog" fullscreen>
    <v-card>
      <v-card-title class="text-h6 py-4 font-weight-regular grey--text text--darken-1">
        Players
        <v-btn icon absolute right @click="setCloseModal">
          <v-icon size="large"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <div>
          <span inline class="overline">Agent:</span>
          <span inline class="overline primary--text">
            {{ player_data.agent_name }}
          </span>
        </div>
        <v-row align="end" dense class="py-4">
          <v-col cols="12" md="8">
            <downloadexcel
              :data="players"
              :fields="{
                ID: 'id',
                Province: 'province',
                City: 'city',
                'Agent Name': 'player_name',
                'Fund Token': 'fund_token',
                'Total Bets': 'total_bets',
                'Total Bets Amount': 'total_bets_amount',
                'Total CashIn': 'total_cashin',
                'Wallet Token': 'wallet_token',
                'Unremitted Amount': 'unremitted_amount',
              }"
              name="Players.xls"
              type="csv"
              class="d-inline-block"
            >
              <v-btn color="primary" outlined small depressed> Export CSV </v-btn>
            </downloadexcel>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="playersLoading"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-data-table
          :headers="headers"
          :items="players"
          :items-per-page="5"
          :search="search"
          :loading="playersLoading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dense
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  color="primary"
                  @click="showGames(item.id, item.player_name)"
                >
                  <v-icon size="large"> mdi-basketball </v-icon>
                </v-btn>
              </template>
              <span>View Per Game</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.total_bets`]="{ item }">
            {{ parseInt(item.total_bets).toLocaleString() }}
          </template>

          <template v-slot:[`item.total_bets_amount`]="{ item }">
            {{ moneyFormat(parseFloat(item.total_bets_amount)) }}
          </template>

          <template v-slot:[`item.fund_token`]="{ item }">
            {{ moneyFormat(parseFloat(item.fund_token)) }}
          </template>

          <template v-slot:[`item.total_cashin`]="{ item }">
            {{ moneyFormat(parseFloat(item.total_cashin)) }}
          </template>

          <template v-slot:[`item.wallet_token`]="{ item }">
            {{ moneyFormat(parseFloat(item.wallet_token)) }}
          </template>

          <template v-slot:[`item.unremitted_amount`]="{ item }">
            <span :class="item.unremitted_amount != 0 ? 'red--text' : ''">{{
              moneyFormat(parseFloat(item.unremitted_amount))
            }}</span>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ setCreatedAtFormat(item.created_at) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <Games
      v-if="gamesDialog"
      :game_data="game_data"
      @setGameCloseModal="setGameCloseModal"
    />
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Games from "@/components/agent_reports/Games";
import downloadexcel from "vue-json-excel";

export default {
  props: {
    player_data: {
      type: Object,
    },
  },

  components: {
    Games,
    downloadexcel,
  },

  data: () => ({
    search: "",
    sortBy: "id",
    sortDesc: true,
    gamesDialog: false,
    game_data: {},
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      // { text: "ID", value: "id", align: "left" },
      {
        text: "Province",
        value: "province",
        class: "text-uppercase",
      },
      {
        text: "City",
        value: "city",
        class: "text-uppercase",
      },
      {
        text: "Agent Name",
        sortable: false,
        value: "player_name",
        class: "text-uppercase",
      },
      {
        text: "Fund Token",
        value: "fund_token",
        class: "text-uppercase",
      },
      {
        text: "Total Bets",
        value: "total_bets",
        class: "text-uppercase",
      },
      {
        text: "Total Bets Amount",
        value: "total_bets_amount",
        class: "text-uppercase",
      },
      {
        text: "Total CashIn",
        value: "total_cashin",
        class: "text-uppercase",
      },
      {
        text: "Wallet Token",
        value: "wallet_token",
        class: "text-uppercase",
      },
      {
        text: "Unremitted Amount",
        value: "unremitted_amount",
        class: "text-uppercase",
      },
    ],
  }),

  created() {
    this.loadPlayers();
  },

  computed: {
    ...mapState("agents_report", ["playersLoading", "players"]),

    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.setCloseModal();
      },
    },
  },

  methods: {
    ...mapActions("agents_report", ["getPlayers"]),

    async loadPlayers() {
      await this.getPlayers(this.setPlayersFormData());
    },

    setPlayersFormData() {
      const formData = new FormData();
      formData.append("agent_id", this.player_data.id);
      formData.append("date_from", this.player_data.date_from);
      formData.append("date_to", this.player_data.date_to);

      const getData = new URLSearchParams(formData);

      return getData;
    },

    setCloseModal() {
      this.$emit("setPlayerCloseModal");
    },

    showGames(id, player_name) {
      this.$store.commit("agents_report/setGames", []);
      this.game_data = {
        agent_id: this.player_data.id,
        player_id: id,
        agent_name: this.player_data.agent_name,
        player_name: player_name,
        date_from: this.player_data.date_from,
        date_to: this.player_data.date_to,
      };

      this.gamesDialog = true;
    },

    setGameCloseModal() {
      this.gamesDialog = false;
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>
