var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","fullscreen":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 py-4 font-weight-regular grey--text text--darken-1"},[_vm._v(" Players "),_c('v-btn',{attrs:{"icon":"","absolute":"","right":""},on:{"click":_vm.setCloseModal}},[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-close ")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('div',[_c('span',{staticClass:"overline",attrs:{"inline":""}},[_vm._v("Agent:")]),_c('span',{staticClass:"overline primary--text",attrs:{"inline":""}},[_vm._v(" "+_vm._s(_vm.player_data.agent_name)+" ")])]),_c('v-row',{staticClass:"py-4",attrs:{"align":"end","dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('downloadexcel',{staticClass:"d-inline-block",attrs:{"data":_vm.players,"fields":{
              ID: 'id',
              Province: 'province',
              City: 'city',
              'Agent Name': 'player_name',
              'Fund Token': 'fund_token',
              'Total Bets': 'total_bets',
              'Total Bets Amount': 'total_bets_amount',
              'Total CashIn': 'total_cashin',
              'Wallet Token': 'wallet_token',
              'Unremitted Amount': 'unremitted_amount',
            },"name":"Players.xls","type":"csv"}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","small":"","depressed":""}},[_vm._v(" Export CSV ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search","single-line":"","hide-details":"auto","disabled":_vm.playersLoading,"clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.players,"items-per-page":5,"search":_vm.search,"loading":_vm.playersLoading,"loading-text":"Loading, please wait a moment","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.showGames(item.id, item.player_name)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-basketball ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Per Game")])])]}},{key:"item.total_bets",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.total_bets).toLocaleString())+" ")]}},{key:"item.total_bets_amount",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moneyFormat(parseFloat(item.total_bets_amount)))+" ")]}},{key:"item.fund_token",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moneyFormat(parseFloat(item.fund_token)))+" ")]}},{key:"item.total_cashin",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moneyFormat(parseFloat(item.total_cashin)))+" ")]}},{key:"item.wallet_token",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moneyFormat(parseFloat(item.wallet_token)))+" ")]}},{key:"item.unremitted_amount",fn:function(ref){
            var item = ref.item;
return [_c('span',{class:item.unremitted_amount != 0 ? 'red--text' : ''},[_vm._v(_vm._s(_vm.moneyFormat(parseFloat(item.unremitted_amount))))])]}},{key:"item.created_at",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.setCreatedAtFormat(item.created_at)))])]}}],null,true)})],1)],1),(_vm.gamesDialog)?_c('Games',{attrs:{"game_data":_vm.game_data},on:{"setGameCloseModal":_vm.setGameCloseModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }