<template>
  <tr>
    <td
      v-for="item in slotData"
      :key="item.slot_id"
      style="width: 100px"
    >
      {{ item.slot }}
      <v-tooltip v-if="item.bet_id" top color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-bind="attrs"
            v-on="on"
            x-small
            color="success"
            text-color="white"
            class="ms-1 px-2"
          >
            ID #{{ item.player_id }}
          </v-chip>
        </template>
        <span>{{ item.player_name }} at {{ datetime(item.created_at) }}</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <div v-if="item.bet_id" class="card-slot-triangle-green"></div>
      <div v-else class="card-slot-triangle-grey"></div>
    </td>
  </tr>
</template>

<script>
import formatDate from '@/helpers/formatDate'

export default {
  props: {
    slotData: {
      required: true
    }
  },
  methods: {
    datetime(date) {
      return formatDate(date, true)
    },
  }
}
</script>