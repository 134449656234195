<template>
  <div class="notifications">
    <v-menu
      offset-y
      left
      transition="slide-y-transition"
      style="height: 500px !important"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          style="position: relative; width: 36px !important; height: 36px !important"
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            v-if="countUnreadNotifications != 0"
            :content="countUnreadNotifications >= 9 ? '9+' : countUnreadNotifications"
            color="red"
            style="position: absolute; top: 5px; right: 10px; z-index: 5"
          >
          </v-badge>
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </template>
      <h3 class="white font-weight-bold pa-4">Notifications</h3>
      <v-divider></v-divider>
      <div style="position: relative; max-height: 50vh; overflow-y: auto">
        <v-list
          style="max-width: 350px; padding: 0 !important"
          v-if="notifications && notifications.length != 0"
        >
          <v-list-item
            two-line
            link
            v-for="(item, key) in notifications"
            :key="key"
            @click="redirectTo(item.route, item.id, item.user_id, item.is_readed)"
            style="position: relative; padding: 0 !important"
          >
            <v-list-item-content
              class="pa-4"
              :class="item.is_readed == 'No' ? 'blue lighten-5' : undefined"
              style="position: relative"
            >
              <v-badge
                inline
                style="position: absolute; top: 1rem; right: 1rem; transform: scale(0.25)"
                v-if="item.is_readed == 'No'"
              ></v-badge>
              <!-- <v-list-item-title class="font-weight-bold mb-1">
                  <div class="d-flex justify-space-between bs-gap-4">
                    <span>{{ item.title }}</span>
                    <v-badge
                      inline
                      style="transform: scale(0.25)"
                      v-if="item.is_readed == 'No'"
                    ></v-badge>
                  </div>
                </v-list-item-title> -->
              <div class="d-flex bs-gap-3">
                <div class="d-inline-block">
                  <div
                    class="d-flex justify-center align-center rounded-circle overflow-hidden"
                    style="height: 36px; width: 36px"
                    v-if="item.selfie"
                  >
                    <img
                      :class="setElementClass(item.user_id)"
                      :src="item.selfie"
                      class="d-inline-block"
                      style="width: auto; height: 100%"
                      @error="handleSelfieError(item.user_id)"
                    />
                  </div>
                </div>

                <div class="d-block">
                  <v-list-item-subtitle class="mb-3">
                    <div class="pe-4 pe-md-6">
                      {{ trimmedDescription(item.description) }}
                    </div>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    class="grey--text"
                    style="font-size: 12px !important"
                  >
                    {{ dateFormat(item.created_at) }}
                  </v-list-item-subtitle>
                </div>
              </div>
            </v-list-item-content>
            <!-- <v-menu
                  offset-y
                  left
                  transition="slide-y-transition"
                  style="width: 150px !important"
                  v-if="item.is_readed == 'No'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="action-button white shadow"
                      style="position: absolute; bottom: 1rem; right: 1rem"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item link @click.prevent="readNotification(item.id)">
                      <v-list-item-title> Mark as read </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu> -->
          </v-list-item>
        </v-list>
        <v-list class="pa-0" v-else>
          <div class="text-center pa-4">No notification!</div>
        </v-list>
      </div>
    </v-menu>

    <!-- Notification Snackbar -->
    <v-snackbar v-model="snackbar.bar" :timeout="snackbar.timeout" :vertical="true">
      <h3 class="font-weight-bold mb-3">{{ snackbar.title }}</h3>
      <div class="grey--text">{{ trimmedDescription(snackbar.description) }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import responseGet from "@/helpers/api_request_get.js";
import responsePost from "@/helpers/api_request_post.js";
import formatDate from "@/helpers/formatDatev2.js";
import { pusherConnect, pusherAddWithdrawalAccountSubscribe } from "@/pusher/config.js";

export default {
  name: "Notifications",
  data: () => ({
    notifications: [],
    notification_loading: false,

    snackbar: {
      bar: false,
      title: "",
      description: "",
      timeout: 10000,
    },
  }),
  mounted() {
    this.getNotifications();
    this.withdrawalPusher();
    this.askNotificationPermission();
  },
  computed: {
    countUnreadNotifications() {
      return this.notifications.filter((item) => item.is_readed == "No").length;
    },
  },
  methods: {
    handleSelfieError(user_id) {
      let selfie = document.querySelectorAll(".selfie_" + user_id);
      selfie.forEach((element) => {
        element.src =
          "https://img.icons8.com/material/24/AAAAAA/user-male-circle--v1.png";
      });
    },
    setElementClass(user_id) {
      return `selfie_${user_id}`;
    },
    withdrawalPusher() {
      // Connect to pusher
      pusherConnect();

      // Subscribe to token channel
      const tokenChannel = pusherAddWithdrawalAccountSubscribe();

      const showNotificationCallback = (data) => {
        this.getNotifications();

        if ("Notification" in window) {
          Notification.requestPermission().then(function (permission) {
            if (permission === "granted") {
              var notification = new Notification(data.body.title, {
                body: data.body.description,
                icon: "gamex-logo.png",
              });

              // notification.onclick = function () {
              //   this.redirectTo(
              //     data.body.route,
              //     data.body.id.toString(),
              //     data.body.user_id,
              //     "No"
              //   );
              // };

              setTimeout(function () {
                notification.close();
              }, 10000);
            }
          });
        } else {
          this.snackbar.title = data.body.title;
          this.snackbar.description = data.body.description;
          this.snackbar.bar = true;
        }
      };

      tokenChannel.bind("show-notification", showNotificationCallback);
    },
    async getNotifications() {
      this.notification_loading = true;

      const res = await responseGet("", "notifications");

      if (res.status == 200) {
        this.notifications = res.data.notifications;
      }

      this.notification_loading = false;
    },
    trimmedDescription(description) {
      if (description.length > 75) {
        return description.substring(0, 75) + " ...";
      }
    },
    dateFormat(date) {
      return formatDate(date, "datetime");
    },
    redirectTo(route, id, user_id, is_readed) {
      if (is_readed == "No") {
        this.readNotification(id);
      }

      const params = this.$route.params;

      if (this.$route.name != route && params.id != user_id) {
        this.$router.push({ name: route, params: { id: user_id } });
      } else if (this.$route.name == route && params.id != user_id) {
        this.$router.replace({ params: { id: user_id } });
        location.reload();
      }
    },
    async readNotification(id) {
      const form_data = new FormData();
      form_data.append("id", id);

      const res = await responsePost(form_data, "notifications/read");

      if (res.status == 200) {
        this.getNotifications();
      }
    },
    askNotificationPermission() {
      // Checking if the browser supports notifications
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
        return;
      }

      // Requesting permission for notifications
      Notification.requestPermission();
    },
  },
};
</script>
