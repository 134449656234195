<template>
  <div style="height: 100%">
    <v-card class="shadow" style="height: 100%">
      <div class="d-flex justify-space-between align-center bs-py-3 bs-px-4">
        <h3>Monthly Cash-In</h3>
        <v-btn
          text
          class="text-uppercase px-4"
          @click="filterDialog = true"
          :disabled="cashinMonthlyLoading"
        >
          {{ cashinMonthly.label }}
        </v-btn>
      </div>

      <v-divider></v-divider>

      <div class="d-flex align-center justify-center pa-6">
        <div v-if="cashinMonthlyLoading" class="text-center" style="height: 100%">
          <div class="d-flex align-center" style="gap: 1rem">
            <v-progress-circular
              :size="20"
              :width="3"
              color="secondary"
              indeterminate
            ></v-progress-circular>
            <span>Fetching chart information</span>
          </div>
        </div>
        <div style="width: 100%" v-else>
          <LineChart :theChartData="cashinMonthly" style="margin-top: -15px" />
        </div>
      </div>

      <div v-if="!cashinMonthlyLoading">
        <v-divider></v-divider>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th class="text-right grey--text">Average</th>
                <th class="text-right grey--text">Lowest</th>
                <th class="text-right grey--text">Highest</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="grey--text">
                  <i class="far fa-circle fa-xs mr-4" style="color: #00b6fe"></i>Cashin
                </td>
                <td class="text-right">
                  <span>
                    {{
                      cashinMonthly.cashin_monthly_average
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(
                            parseInt(cashinMonthly.cashin_monthly_average)
                          )}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      cashinMonthly.cashin_monthly_average && lowest
                        ? "₱" + `${moneyFormatHundredthsPlace(parseFloat(lowest))}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      cashinMonthly.cashin_monthly_average && highest
                        ? "₱" + `${moneyFormatHundredthsPlace(parseFloat(highest))}`
                        : "₱0"
                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="grey--text">
                  <i class="far fa-circle fa-xs mr-4" style="color: #ec407a"></i>Payouts
                </td>
                <td class="text-right">
                  <span>
                    {{
                      cashinMonthly.payouts_monthly_average
                        ? "₱" +
                          `${moneyFormatHundredthsPlace(
                            parseInt(cashinMonthly.payouts_monthly_average)
                          )}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      cashinMonthly.payouts_monthly_average && lowestPayout
                        ? "₱" + `${moneyFormatHundredthsPlace(parseFloat(lowestPayout))}`
                        : "₱0"
                    }}
                  </span>
                </td>
                <td class="text-right">
                  <span>
                    {{
                      cashinMonthly.payouts_monthly_average && highestPayout
                        ? "₱" + `${moneyFormatHundredthsPlace(parseFloat(highestPayout))}`
                        : "₱0"
                    }}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>

    <v-dialog ref="filterDialog" v-model="filterDialog" persistent width="290px">
      <v-date-picker
        v-model="year"
        :active-picker.sync="activePicker"
        full-width
        reactive
        no-title
        @click:year="saveFilter"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cancelFilter"> Cancel </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formatDatev2 from "@/helpers/formatDatev2";
import LineChart from "@/components/dashboard/cashin_statistics/CashinMonthlyLineChart.vue";

export default {
  components: { LineChart },
  data: () => ({
    year: formatDatev2(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      "format",
      "yyyy"
    ),
    filterDialog: false,
    activePicker: null,
  }),
  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("dashboard", ["cashinMonthly", "cashinMonthlyLoading"]),

    lowest() {
      const arr = Object.values(this.cashinMonthly.cashin_statisitcs);
      const filtered_array = arr.filter((val) => val != 0);

      if (filtered_array.length != 0) return Math.min(...filtered_array);

      return 0;
    },

    highest() {
      const arr = Object.values(this.cashinMonthly.cashin_statisitcs);
      return Math.max(...arr);
    },

    lowestPayout() {
      const arr = Object.values(this.cashinMonthly.payouts_statistics);
      const filtered_array = arr.filter((val) => val != 0);

      if (filtered_array.length != 0) return Math.min(...filtered_array);

      return 0;
    },

    highestPayout() {
      const arr = Object.values(this.cashinMonthly.payouts_statistics);
      return Math.max(...arr);
    },
  },
  mounted() {
    if (this.accountInfo.access.includes(21)) {
      this.doGetData();
    }
  },
  methods: {
    ...mapActions("dashboard", ["getCashinMonthly"]),
    cancelFilter() {
      this.filterDialog = false;
    },
    saveFilter() {
      const expYear = this.year.split("-");
      this.year = expYear[0];
      this.$refs.filterDialog.save(this.year);
      this.doGetData();
    },
    async doGetData() {
      if (!this.cashinMonthlyLoading) {
        this.$store.commit("dashboard/setCashinMonthly", []);

        const formData = new FormData();
        formData.append("val", this.year);
        formData.append("type", "Monthly");

        const getData = new URLSearchParams(formData);

        await this.getCashinMonthly(getData);
      }
    },
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    moneyFormatHundredthsPlace(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },

  watch: {
    filterDialog(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>
