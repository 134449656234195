<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Power Ball Reports</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Bets</h2>
    <v-card class="shadow mb-4">
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="loadRecords"
        >
          <v-row>
            <v-col cols="12" md="3">
              <v-select
                v-model="filterBy"
                :items="filterByStatus"
                :rules="[rules.required]"
                label="Filter By"
                dense
                outlined
                hide-details="auto"
                clearable
              />
            </v-col>

            <v-col cols="12" md="3">
              <v-menu
                v-model="date_from_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_from"
                    :rules="[rules.required]"
                    :label="`${filterBy} From`"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @keyup="date_from_menu = true"
                    @keydown="date_from_menu = false"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_from"
                  @input="date_from_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="3">
              <v-menu
                v-model="date_to_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_to"
                    :label="`${filterBy} To (Optional)`"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :disabled="dateToAvailability"
                    @keyup="date_to_menu = true"
                    @keydown="date_to_menu = false"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_to"
                  @input="date_to_menu = false"
                  :min="date_from ? date_from : ''"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="3">
              <v-btn
                :disabled="!valid"
                type="submit"
                color="primary"
                @click.prevent="loadRecords"
                :loading="recordsLoading"
              >
                Search
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  @click="loadRecords"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
            <downloadexcel
              class="d-inline-block"
              :data="records"
              :fields="{
                ID: 'bet_id',
                'Player Name': 'player_name',
                Game: {
                  callback: (value) => {
                    return `${value.team_a_name} ${value.team_a_alias} vs ${value.team_b_name} ${value.team_b_alias}`;
                  },
                },
                Schedule: {
                  callback: (value) => {
                    return this.dateFormat(value.schedule);
                  },
                },
                'Bet Amount': 'total_bet_amount',
                Combinations: {
                  field: 'combinations',
                  callback: (value) => {
                    return `${value} `;
                  },
                },
                'Power Balls': 'power_balls',
                'Bet Status': 'bet_status',
                'Bet Date': {
                  callback: (value) => {
                    return this.dateFormat(value.created_at);
                  },
                },
                'Bet Datetime': {
                  callback: (value) => {
                    return this.datetimeFormat(value.created_at);
                  },
                },
              }"
              :name="exportExcelName()"
              type="csv"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="secondary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-file-delimited-outline</v-icon>
                  </v-btn>
                </template>
                <span>Export to CSV</span>
              </v-tooltip>
            </downloadexcel>
          </v-col>
          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="recordsLoading"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4">
        <v-data-table
          :headers="headers"
          :items="recordsFormatted"
          :items-per-page="5"
          :search="search"
          :loading="recordsLoading"
          loading-text="Loading... Please wait"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.game`]="{ item }">
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-avatar size="36px" tile>
                      <v-img :src="item.team_a_logo">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span class="text-overline my-2 mx-2 font-italic">vs</span>

                    <v-avatar size="36px" tile>
                      <v-img :src="item.team_b_logo">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                  </div>
                </template>
                <span>
                  <span class="text-caption"
                    >{{ item.team_a_name }} {{ item.team_a_alias }}</span
                  >
                  <span class="text-overline my-2 mx-2 font-italic">vs</span>
                  <span class="text-caption"
                    >{{ item.team_b_name }} {{ item.team_b_alias }}</span
                  >
                </span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.bet_status`]="{ item }">
            <v-chip
              small
              class="font-weight-bold"
              :class="
                item.bet_status == 'Win'
                  ? 'green lighten-5 green--text'
                  : 'red lighten-5 red--text'
              "
            >
              {{ item.bet_status }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { format } from "date-fns";
import formRules from "@/helpers/formRules";
import downloadexcel from "vue-json-excel";
import formatDate from "@/helpers/formatDate";
import formatDatev2 from "@/helpers/formatDatev2";
import responseGet from "@/helpers/api_request_get";

export default {
  components: { downloadexcel },

  data: () => ({
    valid: true,
    rules: formRules,
    date_from: format(new Date(), "yyyy-MM-dd"),
    date_from_menu: false,
    date_to: "",
    date_to_menu: false,
    search: "",
    sortBy: "bet_id",
    sortDesc: true,
    filterByStatus: ["Bet Date", "Scheduled Date"],
    filterBy: "Bet Date",
    headers: [
      {
        text: "Player Name",
        sortable: false,
        value: "player_name",
        class: "text-uppercase",
      },
      { text: "Game", sortable: false, value: "game", class: "text-uppercase" },
      { text: "Schedule", value: "formattedSchedule", class: "text-uppercase" },
      {
        text: "Bet Amount",
        value: "formattedBetAmount",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Combinations",
        sortable: false,
        value: "combinations",
        class: "text-uppercase",
      },
      {
        text: "Power Balls",
        sortable: false,
        value: "power_balls",
        class: "text-uppercase",
      },
      { text: "Bet Status", value: "bet_status", class: "text-uppercase" },
      {
        text: "Payout",
        value: "formattedPayout",
        class: "text-uppercase",
        align: "right",
      },
      { text: "Bet At", value: "formattedCreatedAt", class: "text-uppercase" },
    ],
    records: [],
    recordsLoading: false,
  }),

  computed: {
    recordsFormatted() {
      return this.records.map((record) => {
        return {
          ...record,
          formattedBetAmount:
            "₱ " + this.moneyFormat(parseFloat(record.total_bet_amount)),
          formattedCreatedAt: formatDate(record.created_at, true),
          formattedSchedule: formatDate(record.schedule, true),
          formattedPayout: "₱ " + this.moneyFormat(parseFloat(record.payout)),
        };
      });
    },

    dateToAvailability() {
      if (this.date_from == "" || this.date_from == null) {
        return true;
      }
      return false;
    },
  },

  methods: {
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    dateFormat(date) {
      return formatDatev2(date, "format", "yyyy-MM-dd");
    },

    datetimeFormat(date) {
      return formatDatev2(date, "format", "yyyy-MM-dd HH:mm:ss");
    },

    async loadRecords() {
      if (this.$refs.form.validate()) {
        if (!this.recordsLoading) {
          try {
            this.recordsLoading = true;

            const data = `filter_by=${this.filterBy}&created_at_start=${this.date_from}&created_at_end=${this.date_to}`;
            const response = await responseGet(data, "power_ball/bets");

            if (response.status == 200) {
              this.records = response.data;
            }
          } catch (error) {
            alert("Something went wrong, please");
          } finally {
            this.recordsLoading = false;
          }
        }
      }
    },

    exportExcelName() {
      return (
        "Power Ball Bets " + format(new Date(), "yyyy-MM-dd hh:mm:ss") + ".xls"
      );
    },
  },

  watch: {
    date_from(val) {
      if (val == "" || val == null) {
        this.date_to = "";
      }
    },
  },
};
</script>