var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Create League ")]),_c('v-divider'),_c('v-card-text',{staticClass:"py-4"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"league",attrs:{"label":"Name","outlined":"","dense":"","rules":[
                        _vm.rules.required,
                        _vm.rules.alphaSpace,
                        _vm.rules.minimum(3),
                        _vm.leagueExist
                        ],"hide-details":"auto"},model:{value:(_vm.league),callback:function ($$v) {_vm.league=$$v},expression:"league"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"logo",attrs:{"label":"Logo Url","outlined":"","dense":"","rules":[
                        _vm.rules.required,
                        _vm.rules.minimum(3) ],"hide-details":"auto"},model:{value:(_vm.logo),callback:function ($$v) {_vm.logo=$$v},expression:"logo"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-radio-group',{attrs:{"row":"","hide-details":"auto"},model:{value:(_vm.isLocal),callback:function ($$v) {_vm.isLocal=$$v},expression:"isLocal"}},[_c('v-radio',{attrs:{"label":"Local","value":"Local"}}),_c('v-radio',{attrs:{"label":"International","value":"International"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{staticClass:"d-inline-block",attrs:{"color":"success","label":("Status: " + _vm.statusText)},on:{"change":_vm.setToggleStatus},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"mt-4 text-right"},[_c('v-btn',{staticClass:"me-2",attrs:{"color":"black","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.setData.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","outlined":"","loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }