<template>
  <v-snackbar v-model="snackbar" :color="color" :timeout="timeout" :top="top">
    <v-icon class="me-2"> {{ icon }} </v-icon>
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="snackbar = false" small>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    snackbar: false,
    color: "",
    icon: "",
    message: "",
    timeout: 5000,
    top: true,
  }),

  methods: {
    show(color, icon, message, timeout, top) {
      this.color = color;
      this.icon = icon;
      this.message = message;
      this.timeout = timeout ?? this.timeout;
      this.top = top ?? this.top;
      this.snackbar = true;
    },
  },
};
</script>
