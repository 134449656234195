<template>
    <v-dialog v-model="dialog" max-width="600">
        <v-card>
            <v-card-title>
                <v-icon class="mr-2">mdi-cash-plus</v-icon>  Commission Payment

                <v-btn icon absolute right @click="setCloseModal">
                    <v-icon> mdi-close </v-icon>
                </v-btn>
            </v-card-title>

            <v-card-subtitle class="my-0 py-0">
                Master Agent: <strong>{{ payCommissionData.agent }}</strong>
            </v-card-subtitle>

            <v-divider></v-divider>

            <v-card-text class="px-0 py-4">
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="filterHandler"
                    :disabled="commissionLoading || paidCommissionLoading"
                    class="mx-6"
                >
                    <v-row dense v-if="alertStatus">
                        <v-col>
                            <v-alert dense outlined type="error">
                                {{ alertMessage }}
                            </v-alert>
                        </v-col>
                    </v-row>

                    <v-row dense class="my-1">
                        <v-col>
                            <span class="text-h6 mr-2">Unpaid Commission</span>

                            <span class="text-h6" :class="total_commission != '0.00' ? 'green--text' : 'red--text'">
                                <strong >Php {{ moneyFormat(parseFloat(total_commission)) }}</strong>
                            </span>
                        </v-col>
                    </v-row>

                    <v-row dense class="mt-2">
                        <v-col cols="12" md="5" class="mt-2">
                            <v-menu
                                v-model="date_from_menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="date_from"
                                        :rules="[rules.required, lessThanDate]"
                                        label="Bet Date From"
                                        hide-details="auto"
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        @keyup="date_from_menu = true"
                                        @keydown="date_from_menu = false"
                                        clearable
                                    />
                                </template>

                                <v-date-picker
                                    v-model="date_from"
                                    @input="date_from_menu = false"
                                    :min="payCommissionData.min_date"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" md="5" class="mt-2">
                            <v-menu
                                v-model="date_to_menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                :disabled="date_from == ''"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="date_to"
                                        label="Bet Date To (Optional)"
                                        hide-details="auto"
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        :disabled="dateToAvailability"
                                        @keyup="date_to_menu = true"
                                        @keydown="date_to_menu = false"
                                        clearable
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date_to"
                                    @input="date_to_menu = false"
                                    :min="date_from ? date_from : ''"
                                    :max="date_to_max_date"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" md="2" class="mt-2">
                            <v-btn
                                block
                                color="primary"
                                depressed
                                class="py-5"
                                @click="filterHandler"
                                :loading="commissionLoading"
                                >Filter</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-form>

                <v-divider class="mt-4 mb-2" />

                <v-row dense class="mx-5">
                    <v-col cols="12" md="6">
                        <v-btn
                            block
                            color="primary"
                            depressed
                            class="py-5 mt-2"
                            @click="confirmDialog = true"
                            :disabled="
                                total_commission == '0.00' ||
                                commissionLoading ||
                                alertStatus
                            "
                            :loading="paidCommissionLoading"
                            >Pay</v-btn
                        >
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-btn
                            block
                            outlined
                            depressed
                            class="py-5 mt-2"
                            @click="setCloseModal"
                            >Cancel</v-btn
                        >
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog
            v-model="confirmDialog"
            max-width="300"
            overlay-color="#000000"
            overlay-opacity=".7"
        >
            <v-card>
                <v-btn
                    icon
                    @click="confirmDialog = false"
                    absolute
                    right
                    style="top: 5px; right: 5px"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-card-title> Confirm Payment </v-card-title>

                <v-card-subtitle class="py-2">
                    You're about to pay agent P{{
                        moneyFormat(parseFloat(total_commission))
                    }}
                </v-card-subtitle>

                <v-card-text class="mt-3">
                    <v-row no-gutters>
                        <v-col>
                            <v-btn
                                color="primary"
                                depressed
                                block
                                @click="payHandler"
                            >
                                Confirm
                            </v-btn>
                        </v-col>

                        <v-col cols="auto" style="width: 10px"></v-col>

                        <v-col>
                            <v-btn
                                dark
                                color="black"
                                depressed
                                @click="confirmDialog = false"
                                block
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import { format, sub } from "date-fns";
import formRules from "@/helpers/formRules";
import { mapActions, mapState } from "vuex";

export default {
    props: {
        payCommissionData: {
            type: Object,
        },
    },

    data: () => ({
        rules: formRules,
        valid: true,
        date_from: null,
        date_to: null,
        item_count: '',
        date_from_menu: false,
        date_to_menu: false,
        total_commission: 0,
        alertStatus: false,
        alertMessage: '',
        confirmDialog: false,
        date_from_last_value: null,
        date_to_last_value: null,
        date_to_max_date: format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd')
    }),

    created() {
        this.total_commission = this.payCommissionData.unpaid_commission
        this.date_from = this.payCommissionData.min_date
        this.date_from_last_value = this.date_from
        this.date_to = this.payCommissionData.max_date
        this.date_to_last_value = this.date_to
        this.item_count = this.payCommissionData.item_count
    },

    computed: {
        ...mapState("agents_commission_payments", [
            "error",
            "commission",
            "commissionLoading",
            "paidCommission",
            "paidCommissionLoading",
        ]),

        dialog: {
            get: function () {
                return true;
            },
            set: function () {
                this.setCloseModal();
            },
        },

        lessThanDate() {
            if (this.date_from && this.date_to) {
                if (new Date(this.date_to) < new Date(this.date_from)) {
                    return "Must be higher or equal than date from.";
                }
            }

            return true;
        },

        dateToAvailability() {
            if (this.date_from == "" || this.date_from == null) {
                return true;
            }

            return false;
        },
    },

    methods: {
        ...mapActions("agents_commission_payments", [
            "getCommission",
            "payCommission",
        ]),

        async filterHandler() {
            if (!this.recordsLoading) {
                this.$store.commit("agents_commission_payments/setCommission", []);
                await this.getCommission(this.setFormData());

                this.total_commission = this.commission[0].total_commission ?? 0;
                this.item_count = this.commission[0].item_count

                this.date_from_last_value = this.date_from
                this.date_to_last_value = this.date_to
            }
        },

        setFormData() {
            const formData = new FormData();

            formData.append("agent_id", this.payCommissionData.id);
            formData.append("date_from", this.date_from);
            formData.append("date_to", this.date_to ?? this.date_from);

            return new URLSearchParams(formData);
        },

        moneyFormat(val) {
            return val.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },

        setCloseModal() {
            this.$emit("setPayCommissionCloseDialog", false);
        },

        async payHandler() {
            if (!this.paidCommissionLoading) {
                this.alertStatus = false;
                this.confirmDialog = false;
                await this.payCommission(this.setPayFormData());

                if (this.error == "417") {
                    this.alertStatus = true;
                    this.alertMessage = "Someone updated this account, this will refreshed after 5 seconds!"
                } else if (this.error == "418") {
                    this.alertMessage = "Something went wrong in payment insertion, this will refreshed after 5 seconds!"
                } else {
                    this.$emit("setPayCommissionCloseDialog", true);
                }
            }
        },

        setPayFormData() {
            const formData = new FormData();

            formData.append("agent_id", this.payCommissionData.id);
            formData.append("max_id", this.payCommissionData.max_id);
            formData.append("date_from", this.date_from);
            formData.append("date_to", this.date_to ?? this.date_from);
            formData.append("commission", this.total_commission);
            formData.append("item_count", this.item_count);

            return formData;
        },
    },

    watch: {
        date_from(val) {
            // if (val) {
            //     this.date_to = format(new Date(), "yyyy-MM-dd");
            // } else {
            //     this.date_to = null;
            // }

            if (!val) { this.date_to = null }

            if (val != this.date_from_last_value) {
                this.total_commission = '0.00'
            }
        },

        date_to(val) {
            if (val != this.date_to_last_value) {
                this.total_commission = '0.00'
            } 
        },

        alertStatus(val) {
            if (val) {
                setTimeout(() => {
                    this.$emit("setPayCommissionCloseDialog", true);
                }, 5000);
            }
        },
    },
};
</script>