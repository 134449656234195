const url = process.env.VUE_APP_API_URL + "ending/cards"

export default {
  namespaced: true,

  state: {
    details: [],
    detailsLoading: false,
    sendPrizeError: null,
    sendPrizeLoading: false,
  },
  mutations: {
    setDetails (state, val) {
      state.details = val
    },
    setDetailsLoading (state, val) {
      state.detailsLoading = val
    },
    setSendPrizeError(state, val) {
      state.sendPrizeError = val
    },
    setSendPrizeLoading(state, val) {
      state.sendPrizeLoading = val
    },
  },

  actions: {
    async getDetails({ commit, rootState }, data) {
      commit("setDetailsLoading", true)

      try {
        const response = await fetch(`${url}/details/${data}` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setDetails", res.details)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setPopUpError", "Something went wrong, please try again!", { root: true })
        }
      } finally {
        commit("setDetailsLoading", false)
      }
    },

    async sendPrize({ commit, rootState }, data) {
      commit("setSendPrizeLoading", true)

      try {
        const response = await fetch(`${url}/send_prize`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setSendPrizeError", null)
        }
      } catch(error) {
        const errorStatus = error.response.status

        commit("setSendPrizeError", "Error") // Decoy error to detect success
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus==417) {
          commit("setPopUpError", error.res.error, { root: true })
        } else {
          commit("setRefreshError", "An error occurred, please try again!", { root: true })
        }
      } finally {
        commit("setSendPrizeLoading", false)
      }
    },
  }
}