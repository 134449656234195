<template>
  <div class="pa-4 pa-md-6">
    <v-row>
      <v-col cols="12">
        <div class="grey--text" style="font-size: 13px">PAGCOR Reports</div>
        <h2 class="font-weight-bold mb-3 mb-md-5">Transactional</h2>
        <v-card class="shadow">
          <v-card-title
            class="text-h6 py-2 font-weight-regular grey--text text--darken-1"
          >
            Select Transaction Date
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-4">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="handleSubmit"
            >
              <v-row>
                <v-col cols="12" md="6">
                  <v-menu
                    v-model="date_from_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_from"
                        :rules="[rules.required]"
                        label="Transaction Date From"
                        hide-details="auto"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @keyup="date_from_menu = true"
                        @keydown="date_from_menu = false"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_from"
                      @input="date_from_menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu
                    v-model="date_to_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_to"
                        :rules="[lessThanDate]"
                        label="Transaction Date To (Optional)"
                        hide-details="auto"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @keyup="date_to_menu = true"
                        @keydown="date_to_menu = false"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_to"
                      @input="date_to_menu = false"
                      :min="date_from ? date_from : ''"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <div class="mt-4">
                <v-btn
                  :disabled="!valid"
                  type="submit"
                  color="primary"
                  outlined
                  @click.prevent="handleSubmit"
                  :loading="recordsLoading"
                >
                  Submit
                </v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title
            class="text-h6 py-2 font-weight-regular grey--text text--darken-1"
          >
            Transactions
            <v-spacer></v-spacer>
            <div>
              <downloadexcel
                :data="exportExcelData()"
                :fields="{
                  'Date Time': 'date_time',
                  'Time of Bet': 'time_of_bet',
                  'Settle Time': 'settle_time',
                  'Channel Type': 'channel_type',
                  'Transaction Type': 'transaction_type',
                  'Transaction ID': 'transaction_id',
                  'Player ID': 'player_id',
                  Amount: 'amount',
                  Brand: 'brand',
                  Sport: 'sport',
                  'Ticket Status': 'ticket_status',
                  'Prematch Live': 'prematch_live',
                  'Kiosk Terminal': 'kiosk_terminal',
                  Outlet: 'outlet',
                  'Game Type': 'game_type',
                }"
                :name="downloadExcelName()"
                type="csv"
                class="d-inline-block"
                v-if="records.length"
              >
                <v-btn color="primary" outlined small depressed> Export CSV </v-btn>
              </downloadexcel>
              <v-btn
                color="primary"
                outlined
                small
                depressed
                @click="exportToPDF"
                class="ms-2 d-inline-block"
                :disabled="exportPDFLoading"
                :loading="exportPDFLoading"
                v-if="records.length"
              >
                Export PDF
              </v-btn>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="headers"
              :items="records"
              :items-per-page="5"
              :loading="recordsLoading"
              loading-text="Loading, please wait a moment"
              mobile-breakpoint="0"
            >
              <template v-slot:[`item.date_time`]="{ item }">
                <span v-if="item.transaction_id">{{ formatTheDate(item.date_time) }}</span>
                <span v-else>{{ formatTheDate2(item.dates) }}</span>
              </template>
              <template v-slot:[`item.time_of_bet`]="{ item }">
                <span>{{ formatTheDate(item.time_of_bet) }}</span>
              </template>
              <template v-slot:[`item.settle_time`]="{ item }">
                <span>{{ formatTheDate(item.settle_time) }}</span>
              </template>
              <template v-slot:[`item.transaction_type`]="{ item }">
                <span v-if="item.transaction_id">{{ item.transaction_type }}</span>
                <span v-else>No Activity</span>
              </template>
              <!-- <template v-slot:[`item.bet_amount`]="{ item }">
                <span v-if="item.bet_amount">{{ parseInt(item.bet_amount).toLocaleString() }}</span>
              </template> -->
              <template v-slot:[`item.amount`]="{ item }">
                <span v-if="item.amount">{{
                  parseInt(item.amount).toLocaleString()
                }}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";
import formatDatev2 from "@/helpers/formatDatev2";
import downloadexcel from "vue-json-excel";
// import html2pdf from 'html2pdf.js'

export default {
  components: { downloadexcel },
  data: () => ({
    valid: true,
    rules: formRules,
    date_from: "",
    date_from_menu: false,
    date_to: "",
    date_to_menu: false,
    headers: [
      { text: "Date Time", value: "date_time", class: "text-uppercase" },
      { text: "Time of Bet", value: "time_of_bet", class: "text-uppercase" },
      { text: "Settle Time", value: "settle_time", class: "text-uppercase" },
      { text: "Channel Type", value: "channel_type", class: "text-uppercase" },
      { text: "Transaction Type", value: "transaction_type", class: "text-uppercase" },
      { text: "Transaction ID", value: "transaction_id", class: "text-uppercase" },
      { text: "Player ID", value: "player_id", class: "text-uppercase" },
      // { text: 'Bet Amount', value: 'bet_amount', class: 'text-uppercase' },
      // { text: 'Payout Amount', value: 'payout_amount', class: 'text-uppercase' },
      { text: "Amount", value: "amount", class: "text-uppercase" },
      { text: "Brand", value: "brand", class: "text-uppercase" },
      { text: "Sport", value: "sport", class: "text-uppercase" },
      { text: "Ticket Status", value: "ticket_status", class: "text-uppercase" },
      { text: "Prematch Live", value: "prematch_live", class: "text-uppercase" },
      { text: "Kiosk Terminal", value: "kiosk_terminal", class: "text-uppercase" },
      { text: "Outlet", value: "outlet", class: "text-uppercase" },
      { text: "Game Type", value: "game_type", class: "text-uppercase" },
    ],
  }),
  created() {
    this.$store.commit("ending_pagcor_reports_transactional/setRecords", []);
  },
  computed: {
    ...mapState("ending_pagcor_reports_transactional", [
      "records",
      "recordsLoading",
      "exportPDFLoading",
    ]),
  },
  methods: {
    ...mapActions("ending_pagcor_reports_transactional", [
      "getRecords",
      "doExportPDF",
      "doDeleteExported",
    ]),
    lessThanDate(val) {
      if (val) {
        if (new Date(val) < new Date(this.date_from)) {
          return "Must be higher or equal than date from.";
        }
      }

      return true;
    },
    formatTheDate(sched) {
      return formatDatev2(sched);
    },
    formatTheDate2(sched) {
      return formatDatev2(sched, 'date');
    },
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        const formData = new FormData();
        formData.append("date_from", this.date_from === null ? "" : this.date_from);
        formData.append("date_to", this.date_to === null ? "" : this.date_to);

        const getData = new URLSearchParams(formData);

        if (!this.recordsLoading) {
          this.$store.commit("ending_pagcor_reports_transactional/setRecords", []);

          await this.getRecords(getData);
        }
      }
    },
    exportExcelData() {
      return this.records.map((record) => {
        return {
          date_time: (record.transaction_id) ? this.formatTheDate(record.date_time) : this.formatTheDate2(record.dates),
          time_of_bet: (record.transaction_id) ? this.formatTheDate(record.time_of_bet) : "",
          settle_time: (record.transaction_id) ? this.formatTheDate(record.settle_time) : "",
          channel_type: record.channel_type,
          transaction_type: (record.transaction_id) ? record.transaction_type : "No Activity",
          transaction_id: record.transaction_id,
          player_id: record.player_id,
          // bet_amount: (record.bet_amount) ? parseInt(record.bet_amount).toLocaleString() : '',
          // payout_amount: (record.payout_amount) ? parseInt(record.payout_amount).toLocaleString() : '',
          amount: record.amount ? parseInt(record.amount).toLocaleString() : "",
          brand: record.brand,
          sport: record.sport,
          ticket_status: record.ticket_status,
          prematch_live: record.prematch_live,
          kiosk_terminal: record.kiosk_terminal,
          game_type: record.game_type,
        };
      });
    },
    async exportToPDF() {
      /* html2pdf(this.$refs.datatable, {
        filename: 'Transactional Report ' + formatDatev2(new Date()) + '.pdf',
        image: { type: 'jpeg' },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: 'in', format: 'government-letter', orientation: 'portrait' }
      }) */

      const pdfFileName = formatDatev2(new Date(), "format", "yyyyMMddHHmmss");

      const formData = new FormData();
      formData.append("date_from", this.date_from === null ? "" : this.date_from);
      formData.append("date_to", this.date_to === null ? "" : this.date_to);
      formData.append("request_name", pdfFileName);

      const getData = new URLSearchParams(formData);

      if (!this.exportPDFLoading) {
        const res = await this.doExportPDF(getData);

        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Transactional Report " + pdfFileName + ".pdf");
        document.body.appendChild(link);
        link.click();

        // Delete file
        this.deleteFile(pdfFileName);
      }
    },
    deleteFile(pdfFileName) {
      const formData = new FormData();
      formData.append("filename", pdfFileName);

      const getData = new URLSearchParams(formData);

      this.doDeleteExported(getData);
    },
    downloadExcelName() {
      return "Transactional Report " + formatDatev2(new Date()) + ".xls";
    },
  },
};
</script>
