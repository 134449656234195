<template>
  <v-dialog persistent v-model="dialog" max-width="400">
    <v-card :loading="createCardLoading">
      <v-card-title>
        <v-icon class="mr-2 green--text"> mdi-checkbox-marked-circle-outline </v-icon>
        Confirm

        <v-btn icon absolute right @click="closeDialog(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text class="py-4">
        <span
          >· You are about to create a card with amount of
          <span class="green--text">{{ `₱${createCardData.bet_amount}` }}</span></span
        ><br />
        <span
          >· Type of Game -
          <b>{{
            createCardData.bet_type == "1" ? "Traditional" : "Per Quarter"
          }}</b></span
        ><br />
        <span
          >· Number of card{{
            `${
              createCardData.card_count > "1"
                ? "s " + createCardData.card_count
                : " " + createCardData.card_count
            }`
          }}</span
        ><br />

        <div class="mt-3"><b>Prizes</b></div>

        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>1st Quarter</td>
                <td>{{ createCardData.a_quarter_prize_amount }}</td>
              </tr>

              <tr>
                <td>2nd Quarter</td>
                <td>{{ createCardData.b_quarter_prize_amount }}</td>
              </tr>

              <tr>
                <td>3rd Quarter</td>
                <td>{{ createCardData.c_quarter_prize_amount }}</td>
              </tr>

              <tr>
                <td>4th Quarter</td>
                <td>{{ createCardData.d_quarter_prize_amount }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row dense justify="end" class="mt-4">
          <v-col cols="auto">
            <v-btn text @click="closeDialog(false)"> Cancel </v-btn>
          </v-col>

          <v-col cols="auto">
            <v-btn
              depressed
              color="primary"
              :loading="createCardLoading"
              @click="handleSubmit"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    createCardData: {
      type: Object,
    },
  },

  computed: {
    ...mapState("ending_game_details", ["createCardError", "createCardLoading"]),

    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.closeDialog(false);
      },
    },
  },

  methods: {
    ...mapActions("ending_game_details", ["createCard"]),

    async handleSubmit() {
      const formData = new FormData();
      formData.append("game_id", this.createCardData.game_id);
      formData.append("bet_amount", this.createCardData.bet_amount);
      formData.append("card_count", this.createCardData.card_count);
      formData.append("bet_type", this.createCardData.bet_type);
      formData.append("a_quarter_prize_type", this.createCardData.a_quarter_prize_type);
      formData.append(
        "a_quarter_prize_amount",
        this.createCardData.a_quarter_prize_amount
      );
      formData.append("a_quarter_prize", this.createCardData.a_quarter_prize);
      formData.append("b_quarter_prize_type", this.createCardData.b_quarter_prize_type);
      formData.append(
        "b_quarter_prize_amount",
        this.createCardData.b_quarter_prize_amount
      );
      formData.append("b_quarter_prize", this.createCardData.b_quarter_prize);
      formData.append("c_quarter_prize_type", this.createCardData.c_quarter_prize_type);
      formData.append(
        "c_quarter_prize_amount",
        this.createCardData.c_quarter_prize_amount
      );
      formData.append("c_quarter_prize", this.createCardData.c_quarter_prize);
      formData.append("d_quarter_prize_type", this.createCardData.d_quarter_prize_type);
      formData.append(
        "d_quarter_prize_amount",
        this.createCardData.d_quarter_prize_amount
      );
      formData.append("d_quarter_prize", this.createCardData.d_quarter_prize);

      if (!this.createCardLoading) {
        await this.createCard(formData);

        if (!this.createCardError) {
          this.closeDialog(true);
        }
      }
    },

    closeDialog(val) {
      this.$emit("setCloseDialog", val);
    },
  },
};
</script>
