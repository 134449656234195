<template>
  <v-container fluid>
    <v-card class="mt-3">
      <v-card-title class="h3 py- font-weight-regular grey--text text--darken-1">
        Commission
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-4">
        <v-form
          ref="form"
          v-model="valid"
          :disabled="recordsLoading"
          @submit.prevent="handleSubmit()"
        >
          <v-row :no-gutters="!$vuetify.breakpoint.mdAndUp">
            <v-col cols="12" md="4">
              <v-select
                :items="options"
                label="Status"
                v-model="selectedOption"
                outlined
                dense
              ></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-menu
                v-model="date_from_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_from"
                    :rules="[rules.required]"
                    label="Date From"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @keyup="date_from_menu = true"
                    @keydown="date_from_menu = false"
                    clearable
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="date_from"
                  @input="date_from_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4">
              <v-menu
                v-model="date_to_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_to"
                    :rules="[lessThanDate]"
                    label="Date To"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @keyup="date_to_menu = true"
                    @keydown="date_to_menu = false"
                    clearable
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="date_to"
                  @input="date_to_menu = false"
                  :min="date_from"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <div>
            <v-btn
              :disabled="!valid"
              type="submit"
              color="primary"
              outlined
              :loading="recordsLoading"
              class="mr-2"
            >
              Submit
            </v-btn>

            <downloadexcel
              :data="records"
              :fields="json_fields"
              :name="downloadExcelName()"
              type="csv"
              class="d-inline-block"
              :header="excelHeader"
            >
              <v-btn color="success" depressed :disabled="recordsLoading"> EXPORT </v-btn>
            </downloadexcel>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="mt-3">
      <v-data-table
        :headers="headers"
        :items="records"
        :items-per-page="5"
        :search="search"
        :loading="recordsLoading"
        loading-text="Loading... Please wait"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-slot:[`item.Schedule`]="{ item }">
          <span>{{ format_short_date(item.Schedule) }}</span>
        </template>

        <template v-slot:[`item.BetDate`]="{ item }">
          <span>{{ format_short_date(item.BetDate) }}</span>
        </template>

        <template v-slot:[`item.BetAmount`]="{ item }">
          <span>{{ `₱${money_format(parseFloat(item.BetAmount))}` }}</span>
        </template>

        <template v-slot:[`item.Percentage`]="{ item }">
          <span>{{ `${money_format(parseFloat(item.Percentage))}%` }}</span>
        </template>

        <template v-slot:[`item.CommAmount`]="{ item }">
          <span>{{ `₱${money_format(parseFloat(item.CommAmount))}` }}</span>
        </template>

        <template v-slot:[`item.IsPaid`]="{ item }">
        <v-chip small :color="item.IsPaid=='Yes' ? '#C8E6C9' : '#FFCDD2'"
                :text-color="item.IsPaid=='Yes' ? '#1B5E20' : '#B71C1C'"
                class="ma-2"
                >{{item.IsPaid}}</v-chip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import formatDate from "@/helpers/formatDate";
import formatDatev2 from "@/helpers/formatDatev2";
import formRules from "@/helpers/formRules";
import requestGet from "@/helpers/api_request_get";
import { format } from 'date-fns'
import downloadexcel from "vue-json-excel";

export default {
  components: { downloadexcel },

  data: () => ({
    valid: true,
    rules: formRules,
    date_from: format(new Date(), 'yyyy-MM-dd'),
    date_from_menu: false,
    date_to: null,
    date_to_menu: false,
    sortBy: "schedule",
    sortDesc: true,
    search: "",
    headers: [
      { text: "Schedule Date", value: "Schedule", class: "text-uppercase" },
      { text: "Bet Date", value: "BetDate", class: "text-uppercase" },
      { text: "Agent", value: "AgentName", class: "text-uppercase" },
      { text: "Agent Type", value: "AgentType", class: "text-uppercase" },
      { text: "Sub Type", value: "SubType", class: "text-uppercase" },
      { text: "Combination", value: "slot", class: "text-uppercase", align: "center" },
      { text: "Bet Amount", value: "BetAmount", class: "text-uppercase", align: "right" },
      {
        text: "Percentage",
        value: "Percentage",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Commission",
        value: "CommAmount",
        class: "text-uppercase",
        align: "right",
      },
      { text: "Status", value: "IsPaid", class: "text-uppercase", align: "center" },
    ],
    options: ["All", "Paid", "Unpaid"],
    selectedOption: "Unpaid",
    records: [],
    recordsLoading: false,
    json_fields: {
      "Schedule Date": "Schedule",
      "Bet Date": "BetDate",
      Agent: "AgentName",
      "Agent Type": "AgentType",
      "Sub Type": "SubType",
      Combination: "slot",
      "Bet Amount": "BetAmount",
      Percentage: "Percentage",
      Commission: "CommAmount",
      Status: "IsPaid",
    },
    query_filters: {},
    excelHeader: "Commission List",
  }),

  computed: {
    lessThanDate() {
      if (this.date_to) {
        if (new Date(this.date_to) < new Date(this.date_from)) {
          return "Must be higher or equal than date from.";
        }
      }

      return true;
    },
  },

  mounted() {
    const urlQuery = this.$route.query;

    if (Object.keys(urlQuery).length) {
      this.setQueryStringData(urlQuery);
    }

    this.loadRecords();
  },

  methods: {
    async loadRecords() {
      this.recordsLoading = true;

      const formData = new FormData();
      formData.append(
        "status",
        this.selectedOption == "All"
          ? "All"
          : this.selectedOption == "Paid"
          ? "Yes"
          : "No"
      );
      formData.append("date_from", this.date_from ?? "");
      formData.append("date_to", this.date_to ?? this.date_from ?? "");

      const getData = new URLSearchParams(formData);

      const response = await requestGet(getData, "agents/commission");
      if (response.status == 200) this.records = response.data.records;

      this.recordsLoading = false;
    },

    setFormData() {
      const formData = new FormData();
      formData.append("status", this.selectedOption);
      formData.append("date_from", this.date_from);
      formData.append("date_to", this.date_to);

      const getData = new URLSearchParams(formData);
      return getData;
    },

    format_short_date(val) {
      return formatDatev2(val, "Shortdate");
    },

    update(item) {
      console.log(item);
    },

    async handleSubmit() {
      if (this.$refs.form.validate() && !this.recordsLoading) {
        this.recordsLoading = true;

        const formData = new FormData();
        formData.append(
          "status",
          this.selectedOption == "All"
            ? "All"
            : this.selectedOption == "Paid"
            ? "Yes"
            : "No"
        );
        formData.append("date_from", this.date_from ?? "");
        formData.append("date_to", this.date_to ?? this.date_from ?? "");

        const getData = new URLSearchParams(formData);

        const response = await requestGet(getData, "agents/commission");
        if (response.status == 200) {
          this.records = response.data.records;
          this.date_to = this.date_to ?? this.date_from;
          this.setQueryString();
        }

        this.recordsLoading = false;
      }
    },

    formatDates(val) {
      return formatDate(val, "date");
    },

    formatDateAndTime(val) {
      return formatDate(val, "datetime");
    },

    number_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    setRoute() {
      this.$router.replace({
        name: this.$route.name,
        query: this.query_filters,
      });
    },

    setQueryString() {
      const urlQuery = this.$route.query;

      if (
        urlQuery.date_from != this.date_from ||
        urlQuery.date_to != this.date_to ||
        urlQuery.status != this.selectedOption
      ) {
        this.query_filters = {
          status: this.selectedOption,
          date_from: this.date_from,
          date_to: this.date_to,
        };

        // if (this.selectedOption != '') this.query_filters['status'] = this.selectedOption
        // if (this.date_from != '') this.query_filters['date_from'] = this.date_from
        // if (this.date_to != '') this.query_filters['date_to'] = this.date_to

        console.log(this.date_to);

        this.setRoute();
      }
    },

    setQueryStringData(urlQuery) {
      this.date_from = urlQuery.date_from;
      this.date_to = urlQuery.date_to;
      this.selectedOption = urlQuery.status;
    },

    downloadExcelName() {
      return (
        "Commission Report " +
        formatDatev2(this.date_from) +
        " to " +
        formatDatev2(this.date_to) +
        ".xls"
      );
    },
  },
};
</script>
