<template>
    <div class="my-10">
        <v-row justify="center">
            <v-col cols="12" md="6">
                <v-card :loading="forgotLoading">
                    <v-card-title class="font-weight-medium grey--text">
                        Forgot Password
                    </v-card-title>

                    <v-divider/>

                    <v-card-text class="py-4">
                        <v-form ref="form" :disabled="forgotLoading" @submit.prevent="handleSubmit">
                            <v-alert
                                dense
                                outlined
                                type="error"
                                v-if="alertMessage">
                                {{errorMessage}}
                            </v-alert>

                            <p class="font-weight-regular">Your new password must be different from previously used passwords.</p>

                            <v-row dense class="mt-4">
                                <v-col>
                                    <v-text-field
                                        ref="new_password"
                                        outlined
                                        dense
                                        label="New Password"
                                        :rules="[rules.required, rules.minimum(6)]"
                                        v-model="new_password"
                                        :append-icon="passwordVisibility ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="passwordVisibility ? 'text' : 'password'"
                                        @click:append="passwordVisibility = !passwordVisibility"
                                        hide-details="auto"
                                    />
                                </v-col>
                            </v-row>

                            <v-row dense class="mt-4">
                                <v-col>
                                    <v-text-field
                                        ref="confirm_password"
                                        outlined
                                        dense
                                        label="Confirm Password"
                                        :rules="[rules.required, rules.minimum(6), passwordConfirmationRule]"
                                        v-model="confirm_password"
                                        :append-icon="passwordVisibility2 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="passwordVisibility2 ? 'text' : 'password'"
                                        @click:append="passwordVisibility2 = !passwordVisibility2"
                                        hide-details="auto"
                                    />
                                </v-col>
                            </v-row>

                            <v-row dense class="mt-4">
                            <v-col>
                                <v-btn block
                                    color="primary"
                                    type="submit"
                                    :disabled="forgotLoading"
                                >
                                    CHANGE
                                </v-btn>
                            </v-col>
                        </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-dialog persistent v-model="successStatus" max-width="400">
                <v-card>
                    <v-card-title>
                        <span class="green--text text--darken-1">Successfully Reset Password</span> 

                        <v-btn icon absolute right @click="successStatus = false">
                            <v-icon size="large">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-divider/>

                    <v-card-text class="py-4">
                        <v-btn block
                            color="primary"
                            type="button"
                            @click="proceedToLogin"
                        >
                            PROCEED TO LOGIN
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import formRules from '@/helpers/formRules'
import { mapActions, mapState } from 'vuex';

export default {
    props: {
        id: String
    },

    data: () => ({
        rules: formRules,
        new_password: "",
        confirm_password: "",
        passwordVisibility: false,
        passwordVisibility2: false,
        submitStatus: false,
        alertMessage: false,
        successStatus: false,
    }),

    computed: {
        ...mapState("forgot_password", ["forgotLoading", "error", "errors", "errorMessage"]),

        passwordConfirmationRule() {
            return () =>
                this.new_password === this.confirm_password || "Password must match";
        },
    },

    methods: {
        ...mapActions("forgot_password", ["doResetPassword"]),

        async handleSubmit() {
            this.submitStatus = true
            this.alertMessage = false

            await new Promise(r => setTimeout(r, 200));

            if (this.$refs.form.validate()) {
                const formData = new FormData()
                formData.append('id', this.id)
                formData.append('new_password', this.new_password)
                formData.append('confirm_password', this.confirm_password)

                await this.doResetPassword(formData)

                 if (!this.error && !Object.keys(this.errors).length && !this.errorMessage) {
                    this.$refs.form.reset()
                    this.successStatus = true
                } else {
                    this.alertMessage = true
                    this.$refs.new_password.focus()
                }
            }
        },

        proceedToLogin() {
            this.$router.push({ name: 'Home' })
        }
    }
}
</script>