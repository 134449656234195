const url = process.env.VUE_APP_API_URL

export default {
  namespaced: true,

  state: {
    records: [],
    recordsLoading: false,
    saveRecord: [],
    saveLoading: false,
    saveError: 0,
    updateRecord: []
  },
  mutations: {
    setRecords (state, val) {
      state.records = val
    },

    setRecordsLoading (state, val) {
      state.recordsLoading = val
    },

    setSaveRecord(state, val) {
      state.saveRecord = val
    },

    setSaveLoading (state, val) {
      state.saveLoading = val
    },

    setSaveError (state, val) {
      state.saveError = val
    },

    setUpdateRecord(state, val) {
      state.updateRecord = val
    }
  },

  actions: {
    async getRecords({ commit, rootState }) {
      commit("setRecordsLoading", true)

      try {
        const response = await fetch(`${url}outlets`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setRecords", res.records)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setRecordsLoading", false)
      }
    },
    
    async saveOutlet({ commit, rootState }, data) {
      commit("setSaveLoading", true)

      try {
        const response = await fetch(`${url}outlets/save`, {
          method: "POST",
          headers: {
              'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setSaveRecord", res.records)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus == 403) {
          commit("setSaveError", 403)
        } else if (errorStatus == 417) {
          commit("setSaveError", 417)
        } else if (errorStatus == 422) {
          commit("setSaveError", 422)
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setSaveLoading", false)
      }
    },

    async updateOutlet({ commit, rootState }, data) {
      commit("setSaveLoading", true)

      try {
        const response = await fetch(`${url}outlets/update`, {
          method: "POST",
          headers: {
              'Authorization': `Bearer ${rootState.userToken}`,
          },
          body: data
        })

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setUpdateRecord", res.records)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else if (errorStatus == 403) {
          commit("setSaveError", 403)
        } else if (errorStatus == 417) {
          commit("setSaveError", 417)
        } else if (errorStatus == 422) {
          commit("setSaveError", 422)
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setSaveLoading", false)
      }
    },
  }
}