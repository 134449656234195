const url = process.env.VUE_APP_API_URL + "agents"

export default {
    namespaced: true,   

    state: {
        records: [],
        loading: false,
        agentInfo: [],
        playerInfo: [],
        agentPlayers: [],
        userLoading: false,
        addPlayerLoading: false,
        removePlayerLoading: false,
        error: null,
        errors: [],
        agentId: 0,
        outlets: [],
        outletLoading: false
    },

    mutations: {
        setRecords(state, val) {
            state.records = val
        },

        setAgentInfo(state, val) {
            state.agentInfo = val
        },

        setPlayerInfo(state, val) {
            state.playerInfo = val
        },

        setAgentPlayers(state, val) {
            state.agentPlayers = val
        },

        setLoading(state, val) {
            state.loading = val
        },

        setUserLoading(state, val) {
            state.userLoading = val
        },

        setAddPlayerLoading(state, val) {
            state.addPlayerLoading = val
        },

        setRemovePlayerLoading(state, val) {
            state.removePlayerLoading = val
        },

        setAgentId(state, val) {
            state.agentId = val
        },

        setError(state, val) {
            state.error = val
        },

        setErrors(state, val) {
            state.errors = val
        },

        setOutlets(state, val) {
            state.outlets = val
        },

        setOutletLoading(state, val) {
            state.outletLoading = val
        }
    },

    actions: {
        async getOutlets({ commit, rootState }) {
            commit("setOutletLoading", true)

            try {
                const response = await fetch(`${url}/outlets` , {
                  method: 'GET',
                  headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                  }
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                  throw { response, res }
                } else {
                  commit("setOutlets", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
                } finally {
                commit("setOutletLoading", false)
            }
        },

        async getAllAgents({ commit, rootState }) {
            commit("setLoading", true)

            try {
                const response = await fetch(`${url}/all` , {
                  method: 'GET',
                  headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                  }
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                  throw { response, res }
                } else {
                  commit("setRecords", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
                } finally {
                commit("setLoading", false)
            }
        },

        async getAgents({ commit, rootState }, data) {
            commit("setLoading", true)

            try {
                const response = await fetch(`${url}?${data}` , {
                  method: 'GET',
                  headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                  }
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                  throw { response, res }
                } else {
                  commit("setRecords", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
                } finally {
                commit("setLoading", false)
            }
        },

        async getAgentInfo({ commit, rootState }, data) {
            commit("setUserLoading", true)

            try {
                const response = await fetch(`${url}/info?${data}` , {
                  method: 'GET',
                  headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                  }
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                  throw { response, res }
                } else {
                  commit("setAgentInfo", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setUserLoading", false)
            }
        },

        async getAgentPlayers({ commit, rootState }, data) {
            commit("setUserLoading", true)

            try {
                const response = await fetch(`${url}/players?${data}` , {
                  method: 'GET',
                  headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                  }
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                  throw { response, res }
                } else {
                  commit("setAgentPlayers", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setUserLoading", false)
            }
        },

        async createAgent({ commit, rootState }, data) {
            commit("setUserLoading", true)
            commit("setError", null)
            commit("setErrors", [])

            try {
                const response = await fetch(`${url}/create`, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    },
                    body: data
                })

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else commit("setAgentId", res.agent_id)
            } catch (error) {
                const errorStatus = error.response.status

                switch(errorStatus) {
                    case 401:
                      commit("setError", "Unauthorized", { root: true })
                      break;
                    case 404:
                      commit("setError", error.res.messages.error)
                      break;
                    case 409:
                      commit("setErrors", error.res.messages)
                      break;
                    case 417:
                      commit("setPopUpErrror", error.res.error, { root: true })
                      break;
                    case 422:
                        commit("setErrors", error.res.messages) 
                      break;
                    default:
                      commit("setRefreshError", "An error occured, please try again!", { root: true })
                  } 
            } finally {
                commit("setUserLoading", false)
            }
        },

        async updateAgent({ commit, rootState }, data) {
            commit("setUserLoading", true)
            commit("setError", null)

            try {
                const response = await fetch(`${url}/update`, {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${rootState.userToken}`,
                    },
                    body: data
                })

                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } 
            } catch (error) {
                const errorStatus = error.response.status

                switch(errorStatus) {
                    case 401:
                      commit("setError", "Unauthorized", { root: true })
                      break;
                    case 404:
                      commit("setError", error.res.messages.error)
                      break;
                    default:
                      commit("setRefreshError", "An error occured, please try again!", { root: true })
                  } 
            } finally {
                commit("setUserLoading", false)
            }
        },

        async getPlayerInfo({ commit, rootState }, data) {
            commit("setAddPlayerLoading", true)
            commit("setError", null)
            commit("setErrors", [])

            try {
                const response = await fetch(`${url}/player_info?${data}` , {
                  method: 'GET',
                  headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                  }
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                  throw { response, res }
                } else {
                  commit("setPlayerInfo", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else if (errorStatus==409) {
                    commit("setErrors", error.res.messages)
                } else if (errorStatus==422) {
                    commit("setErrors", error.res.messages)
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setAddPlayerLoading", false)
            }
        },

        async addPlayer({ commit, rootState }, data) {
            commit("setAddPlayerLoading", true)
            commit("setError", null)
            commit("setErrors", [])

            try {
                const response = await fetch(`${url}/add` , {
                  method: 'POST',
                  headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                  },
                  body: data
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                  throw { response, res }
                } 
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else if (errorStatus==409) {
                    commit("setErrors", error.res.messages)
                } else if (errorStatus==422) {
                    commit("setErrors", error.res.messages)
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setAddPlayerLoading", false)
            }
        },

        async resetPassword({ commit, rootState }, data) {
            commit("setRemovePlayerLoading", true)
            commit("setError", null)

            try {
                const response = await fetch(`${url}/reset` , {
                    method: 'POST',
                    headers: {
                      'Authorization': `Bearer ${rootState.userToken}`,
                    },
                    body: data
                  });
          
                  const res = await response.json()
                  
                  if (!response.ok) {
                    throw { response, res }
                  } 
            } catch (error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else if (errorStatus==403) {
                    commit("setErrors", error.res.messages)
                } else if (errorStatus==409) {
                    commit("setErrors", error.res.messages)
                } else if (errorStatus==422) {
                    commit("setErrors", error.res.messages)
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setRemovePlayerLoading", false)
            }
        }
    }
}