<template>
  <div>
    <v-card class="mt-6">
      <v-card-text>
        <v-row>
          <v-col>
            <p class="ma-0 overline primary--text">Power Ball Winners</p>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="text-right">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                  @click="loadRecords"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="winnersFormatted"
          :items-per-page="5"
          :loading="recordsLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-row>
              <div v-if="$store.state.accountInfo.access.includes(73)">
                <v-tooltip right v-if="item.bet_status == 'For Verification'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      small
                      color="primary"
                      @click="confirmVerify(item)"
                    >
                      <v-icon size="large">
                        mdi-check-underline-circle-outline
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>Verify</span>
                </v-tooltip>

                <v-tooltip right v-if="item.bet_status == 'Verified'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      small
                      color="primary"
                      @click="confirmUnverify(item)"
                    >
                      <v-icon size="large"> mdi-arrow-u-left-bottom </v-icon>
                    </v-btn>
                  </template>
                  <span>Unverify</span>
                </v-tooltip>

                <v-btn
                  v-if="item.bet_status == 'Approved'"
                  icon
                  small
                  style="pointer-events: none"
                  class="green--text"
                >
                  <v-icon size="large">
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                </v-btn>
              </div>

              <div v-if="$store.state.accountInfo.access.includes(74)">
                <v-btn
                  v-if="item.bet_status == 'For Verification'"
                  icon
                  small
                  style="pointer-events: none"
                  class="grey--text"
                >
                  <v-icon size="large">
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                </v-btn>

                <v-tooltip right v-if="item.bet_status == 'Verified'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      small
                      color="primary"
                      @click="confirmApprove(item)"
                    >
                      <v-icon size="large">
                        mdi-checkbox-marked-circle-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Approve</span>
                </v-tooltip>

                <v-btn
                  v-if="item.bet_status == 'Approved'"
                  icon
                  small
                  style="pointer-events: none"
                  class="green--text"
                >
                  <v-icon size="large">
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                </v-btn>
              </div>
            </v-row>
          </template>

          <template v-slot:[`item.bet_status`]="{ item }">
            <v-chip
              small
              class="font-weight-bold"
              :class="
                item.bet_status == 'Verified'
                  ? 'blue lighten-5 blue--text'
                  : item.bet_status == 'Approved'
                  ? 'green lighten-5 green--text'
                  : 'orange lighten-5 orange--text'
              "
            >
              {{ item.bet_status }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="verifyDialog" max-width="300">
      <v-card class="py-2">
        <v-card-title class="text-h6 d-block text-center">
          Confirm Verify
        </v-card-title>
        <v-card-text class="text-center">
          Do you really want to verify?
        </v-card-text>

        <v-card-actions class="d-block text-center">
          <v-btn
            ref="verifyYes"
            color="primary"
            outlined
            small
            @click="verifyWinner"
            :loading="verifyLoading"
          >
            Yes
          </v-btn>

          <v-btn color="grey" outlined small @click="verifyDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="unverifyDialog" max-width="300">
      <v-card class="py-2">
        <v-card-title class="text-h6 d-block text-center">
          Confirm Unverify
        </v-card-title>
        <v-card-text class="text-center">
          Do you really want to unverify?
        </v-card-text>

        <v-card-actions class="d-block text-center">
          <v-btn
            ref="unverifyYes"
            color="primary"
            outlined
            small
            @click="unverifyWinner"
            :loading="unverifyLoading"
          >
            Yes
          </v-btn>

          <v-btn color="grey" outlined small @click="unverifyDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="approveDialog" max-width="300">
      <v-card class="py-2">
        <v-card-title class="text-h6 d-block text-center">
          Confirm Approve
        </v-card-title>
        <v-card-text class="text-center">
          Do you really want to approve?
        </v-card-text>

        <v-card-actions class="d-block text-center">
          <v-btn
            ref="approveYes"
            color="primary"
            outlined
            small
            @click="approveWinner"
            :loading="approveLoading"
          >
            Yes
          </v-btn>

          <v-btn color="grey" outlined small @click="approveDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="insufficientDialog" max-width="300">
      <v-card class="py-2">
        <v-card-title class="text-h6 d-block text-center">
          Insufficient
        </v-card-title>

        <v-divider />

        <v-card-text class="py-4 text-center red--text">
          Agent points are insufficient.
        </v-card-text>

        <v-card-actions class="d-block text-center">
          <v-btn
            block
            color="primary"
            outlined
            @click="insufficientDialog = false"
          >
            GOT IT!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data: () => ({
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      {
        text: "Player Name",
        sortable: false,
        value: "player_name",
        class: "text-uppercase",
      },
      {
        text: "Combinations",
        sortable: false,
        value: "combinations",
        class: "text-uppercase",
      },
      {
        text: "Power Balls",
        sortable: false,
        value: "power_balls",
        class: "text-uppercase",
      },
      {
        text: "Bet Amount",
        sortable: false,
        value: "formattedBetAmount",
        class: "text-uppercase",
      },
      {
        text: "Prize",
        sortable: false,
        value: "formattedPrize",
        class: "text-uppercase",
      },
      { text: "Status", value: "bet_status", class: "text-uppercase" },
    ],
    verifyDialog: false,
    verifyBetId: "",
    unverifyDialog: false,
    unverifyBetId: "",
    approveDialog: false,
    approveBetId: "",
    insufficientDialog: false,
  }),

  mounted() {
    this.loadRecords();
  },

  computed: {
    ...mapState("power_ball_winners", [
      "records",
      "recordsLoading",
      "verifyError",
      "verifyLoading",
      "unverifyError",
      "unverifyLoading",
      "approveError",
      "approveLoading",
    ]),

    winnersFormatted() {
      if (this.records) {
        return this.records.map((winner) => {
          return {
            ...winner,
            formattedBetAmount:
              "PHP " + this.moneyFormat(parseFloat(winner.bet_amount)),
            formattedPrize: "PHP " + this.moneyFormat(parseFloat(winner.prize)),
          };
        });
      } else {
        return [];
      }
    },
  },

  methods: {
    ...mapActions("power_ball_winners", [
      "getRecords",
      "verify",
      "unverify",
      "approve",
    ]),

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    loadRecords() {
      if (!this.recordsLoading) {
        this.$store.commit("power_ball_winners/setRecords", []);
        this.getRecords(this.$route.params.id);
      }
    },

    confirmVerify(item) {
      this.verifyDialog = true;
      this.verifyBetId = item.id;
    },

    async verifyWinner() {
      const formData = new FormData();
      formData.append("game_id", this.$route.params.id);
      formData.append("bet_id", this.verifyBetId);

      if (!this.verifyLoading) {
        await this.verify(formData);

        if (!this.verifyError) {
          this.verifyDialog = false;

          this.records.find(
            (winner) => winner.id == this.verifyBetId
          ).bet_status = "Verified";
        }
      }
    },

    confirmUnverify(item) {
      this.unverifyDialog = true;
      this.unverifyBetId = item.id;
    },

    async unverifyWinner() {
      const formData = new FormData();
      formData.append("game_id", this.$route.params.id);
      formData.append("bet_id", this.unverifyBetId);

      if (!this.unverifyLoading) {
        await this.unverify(formData);

        if (!this.unverifyError) {
          this.unverifyDialog = false;

          this.records.find(
            (winner) => winner.id == this.unverifyBetId
          ).bet_status = "For Verification";
        }
      }
    },

    confirmApprove(item) {
      this.approveDialog = true;
      this.approveBetId = item.id;
    },

    async approveWinner() {
      const formData = new FormData();
      formData.append("game_id", this.$route.params.id);
      formData.append("bet_id", this.approveBetId);

      if (!this.approveLoading) {
        await this.approve(formData);

        if (!this.approveError) {
          this.approveDialog = false;

          this.records.find(
            (winner) => winner.id == this.approveBetId
          ).bet_status = "Approved";
        } else {
          this.approveDialog = false;
          this.insufficientDialog = true;
        }
      }
    },
  },
};
</script>