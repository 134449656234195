<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card>
      <v-card-title class="text-h5"> Create User </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="py-4">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="handleSubmit"
        >
          <v-row>
            <v-col cols="12" md="4">
              <v-subheader class="pa-0">First Name</v-subheader>
              <v-text-field
                ref="first_name"
                outlined
                dense
                :rules="[rules.required, rules.alphaSpace, rules.minimum(3)]"
                v-model="first_name"
                hide-details="auto"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-subheader class="pa-0">Middle Name</v-subheader>
              <v-text-field
                ref="middle_name"
                outlined
                dense
                :rules="[rules.required, rules.alphaSpace, rules.minimum(3)]"
                v-model="middle_name"
                hide-details="auto"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-subheader class="pa-0">Last Name</v-subheader>
              <v-text-field
                ref="last_name"
                outlined
                dense
                :rules="[rules.required, rules.alphaSpace, rules.minimum(3)]"
                v-model="last_name"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-subheader class="pa-0">Mobile Number</v-subheader>
              <v-text-field
                ref="mobile_number"
                outlined
                dense
                :rules="[
                  rules.required,
                  rules.validMobileNumber,
                  mobileNumberExist,
                ]"
                maxlength="10"
                v-model="mobile_number"
                hide-details="auto"
                prefix="+63"
                @keyup="removeMobileNumberLeadingZero"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-subheader class="pa-0">Email Address</v-subheader>
              <v-text-field
                ref="email_address"
                outlined
                dense
                :rules="[rules.required, rules.validEmail, emailAddressExist]"
                v-model="email_address"
                hide-details="auto"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-subheader class="pa-0">Birthday Date</v-subheader>
              <v-menu
                ref="birthdate_menu"
                v-model="birthdate_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="birthDate"
                    :rules="[rules.required]"
                    dense
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @keyup="birthdate_menu = true"
                    @keydown="birthdate_menu = false"
                    hide-details="auto"
                  />
                </template>

                <v-date-picker
                  v-model="birthDate"
                  :active-picker.sync="birthdateActivePicker"
                  :max="maxBirthdate()"
                  :min="minBirthdate()"
                  no-title
                  @change="$refs.birthdate_menu.save(birthDate)"
                />
              </v-menu>
            </v-col>
          </v-row>

          <v-row v-if="passwordAvailabilityStatus">
            <v-col cols="12" md="4">
              <v-subheader class="pa-0">Password</v-subheader>
              <v-text-field
                ref="password"
                outlined
                dense
                :rules="[rules.required, rules.strongPassword]"
                v-model="password"
                :append-icon="passwordVisibility ? 'mdi-eye' : 'mdi-eye-off'"
                :type="passwordVisibility ? 'text' : 'password'"
                @click:append="passwordVisibility = !passwordVisibility"
                hide-details="auto"
              />
            </v-col>

            <v-col cols="12" md="4">
              <v-subheader class="pa-0">Confirm Password</v-subheader>
              <v-text-field
                ref="confirm_password"
                outlined
                dense
                :rules="[rules.required, rules.strongPassword, passwordConfirmationRule]"
                v-model="confirm_password"
                :append-icon="passwordVisibility2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="passwordVisibility2 ? 'text' : 'password'"
                @click:append="passwordVisibility2 = !passwordVisibility2"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row class="mt-8 pl-4">
            <h2>User Access</h2>
          </v-row>

          <v-row v-if="!userAccessStatus" class="pl-4 red--text">
            <p>You must select atleast one (1) access.</p>
          </v-row>

          <v-row class="pl-4">
            <v-col cols="12">
              <template v-for="(item, i) in userAccess">
                <v-row :key="`item-${i}`">
                  <v-switch
                    v-model="access"
                    color="primary"
                    :label="item.access"
                    :value="item.access_id"
                    :key="`item-${i}`"
                    class="d-inline-block"
                  />
                </v-row>
              </template>
            </v-col>
          </v-row>

          <v-divider class="my-4" />

          <v-row>
            <v-col cols="12" md="6">
              <v-switch
                v-model="status"
                color="success"
                :label="`Status: ${statusText}`"
                @change="setToggleStatus"
                class="d-inline-block"
              />
            </v-col>

            <v-col cols="12" md="6">
              <div class="mt-4 text-right">
                <v-btn color="grey darken-1" outlined class="me-2" @click.stop="setData">
                  Cancel
                </v-btn>

                <v-btn
                  type="submit"
                  color="primary"
                  outlined
                  :loading="loading"
                >
                  {{ buttonText }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";
import { format, subYears } from "date-fns";

export default {
  props: {
    usersData: {
      type: Object,
    },
  },
  data: () => ({
    valid: true,
    rules: formRules,
    birthdate_menu: false,
    birthdateActivePicker: null,
    passwordVisibility: false,
    passwordVisibility2: false,
    insertedData: [],
    updatedData: [],
    id: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    mobile_number: "",
    email_address: "",
    birthDate: "",
    password: "",
    confirm_password: "",
    access: [],
    status: true,
    statusText: "Active",
    buttonText: "Create",
    userAccessStatus: true,
    emailAddressExistText: "",
    mobileNumberExistText: "",
    passwordAvailabilityStatus: true,
  }),
  created() {
    this.getUserAccess();
  },
  mounted() {
    if (Object.keys(this.usersData).length != 0) {
        this.id = this.usersData.user_id
        this.first_name = this.usersData.first_name
        this.middle_name = this.usersData.middle_name
        this.last_name = this.usersData.last_name
        this.mobile_number = this.usersData.mobile_number
        this.email_address = this.usersData.email_address
        this.birthDate = this.usersData.birthdate
        this.password = this.usersData.password
        this.confirm_password = this.usersData.password
        this.access = Array.from(this.usersData.access)
        this.status = this.usersData.is_active === "Active" ? true : false
        this.buttonText = "Update"
        this.passwordAvailabilityStatus = false
    }
    
    setTimeout(() => {
      this.$refs.first_name.focus();
    }, 300);
  },
  computed: {
    ...mapState("ending_users", [
      "userId",
      "loading",
      "userAccess",
      "error",
      "errors",
    ]),
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.setData();
      },
    },
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirm_password || "Password must match";
    },
  },
  methods: {
    ...mapActions("ending_users", [
      "getUserAccess",
      "createUser",
      "updateUser",
    ]),
    maxBirthdate() {
      return format(
        subYears(new Date(format(new Date(), "yyyy, MM, dd")), 21),
        "yyyy-MM-dd"
      );
    },
    minBirthdate() {
      return format(
        subYears(new Date(format(new Date(), "yyyy, MM, dd")), 100),
        "yyyy-MM-dd"
      );
    },
    removeMobileNumberLeadingZero() {
      this.mobile_number = this.mobile_number.replace(/^0+/, "");
    },
    setData() {
      this.$emit("setData", this.insertedData, this.updatedData);
    },
    setToggleStatus() {
      this.statusText = this.status === true ? "Active" : "Inactive";
    },
    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    isUserAccessNotEmpty() {
      if (this.access.length != 0) {
        this.userAccessStatus = true;
        return true;
      } else {
        this.userAccessStatus = false;
        return false;
      }
    },
    mobileNumberExist(val) {
      if (val == this.mobileNumberExistText) {
        return "Mobile number already exists.";
      } else return true;
    },
    emailAddressExist(val) {
      if (val == this.emailAddressExistText) {
        return "Email address already exists.";
      } else return true;
    },
    async handleSubmit() {
      this.isUserAccessNotEmpty();
      if (this.$refs.form.validate() && this.userAccessStatus) {
        if (!this.loading) {
          switch(this.isObjectEmpty(this.usersData)) {
              case true:
                  await this.createUser(this.setFormData())
                  
                  if (!this.error && this.isObjectEmpty(this.errors)) {
                      this.setInsertedData()
                      this.setData()
                      this.$refs.form.reset();
                  } else this.setErrors()
                  break;
              case false:
                  await this.updateUser(this.setFormData())
                  
                  if (!this.error && this.isObjectEmpty(this.errors)) {
                      this.setUpdatedData()
                      this.setData()
                      this.$refs.form.reset();
                  } else this.setErrors()
                  break;
          }
        }
      }
    },
    setErrors() {
      let errorFocus = "";

      Object.entries(this.errors).forEach(([key, value]) => {
        if (value) {
          if (!errorFocus) {
            errorFocus = key;
          }

          switch (key) {
            case "mobile_number":
              this.mobileNumberExistText = this.mobile_number;
              break;
            case "email_address":
              this.emailAddressExistText = this.email_address;
              break;
          }

          this.$refs.form.validate();
          this.$refs[errorFocus].focus();
        }
      });
    },
    setFormData() {
      const formData = new FormData();

      if (!this.isObjectEmpty(this.usersData)) {
        formData.append("user_id", this.id);
      }

      formData.append("first_name", this.first_name);
      formData.append("middle_name", this.middle_name);
      formData.append("last_name", this.last_name);
      formData.append("mobile_number", this.mobile_number);
      formData.append("email_address", this.email_address);
      formData.append("birthdate", this.birthDate);
      formData.append("access", this.access);
      formData.append("password", this.password);
      formData.append("is_active", this.status === true ? 'Active' : 'Inactive');

      return formData;
    },
    setInsertedData() {
      var today = new Date();

      this.insertedData = {
        user_id: this.userId,
        first_name: this.first_name,
        middle_name: this.middle_name,
        last_name: this.last_name,
        mobile_number: this.mobile_number,
        email_address: this.email_address,
        birthDate: this.birthDate,
        access: this.access,
        is_active: this.status === true ? "Active" : "Inactive",
        created_at: today,
      };
    },
    setUpdatedData() {
      this.updatedData = {
        "user_id": this.id,
        "first_name": this.first_name,
        "middle_name": this.middle_name,
        "last_name": this.last_name,
        "mobile_number": this.mobile_number,
        "email_address": this.email_address,
        "birthdate": this.birthDate,
        "access": this.access,
        "is_active": this.status === true ? "Active" : "Inactive",
      };
    },
  },
  watch: {
    birthdate_menu(val) {
      val && setTimeout(() => (this.birthdateActivePicker = "YEAR"));
    },
  },
};
</script>