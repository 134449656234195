<template>
  <div class="pa-4 pa-md-6">
    <v-row>
      <v-col>
        <div class="grey--text" style="font-size: 13px">Players</div>
        <h2 class="font-weight-bold mb-3 mb-md-5">Cash In</h2>
        <v-card class="shadow">
          <v-card-title>
            <v-row justify="space-between">
              <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
                <v-tooltip top v-if="accountInfo.access.includes(28)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="showApprovalPasswordDialog"
                    >
                      <v-icon>mdi-cash-plus</v-icon>
                    </v-btn>
                  </template>

                  <span>Manual Cash In</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="secondary"
                      @click="refreshRecords"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-1"
                    >
                      <v-icon>mdi-cached</v-icon>
                    </v-btn>
                  </template>
                  <span>Refresh</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <downloadexcel
                      :data="records"
                      :fields="{
                        'Player ID': 'player_id',
                        'Player Name': 'player_name',
                        'Mobile Number': {
                          callback: (value) => {
                            return `+63${value.mobile_number}`;
                          },
                        },
                        Bank: 'payment_method',
                        'Reference Number': 'reference_number',
                        Token: {
                          callback: (value) => {
                            return moneyFormat(parseFloat(value.amount));
                          },
                        },
                        Type: 'type',
                        Remarks: 'remarks',
                        Status: 'status',
                        'Requested Date': {
                          callback: (value) => {
                            return formatDateRequestedDate(value.created_at);
                          },
                        },
                      }"
                      :name="downloadExcelName()"
                      type="csv"
                      class="d-inline-block"
                    >
                      <v-btn icon color="secondary" v-bind="attrs" v-on="on">
                        <v-icon>mdi-file-delimited-outline</v-icon>
                      </v-btn>
                    </downloadexcel>
                  </template>
                  <span>Export to EXCEL</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" sm="6" md="4" xl="3">
                <v-text-field
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Search"
                  single-line
                  hide-details="auto"
                  :disabled="recordsLoading"
                  clearable
                  outlined
                  dense
                >
                  <template v-slot:append-outer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          color="secondary"
                          @click="showFilter"
                          v-on="on"
                          small
                          :disabled="recordsLoading"
                        >
                          <v-icon>mdi-filter-variant</v-icon>
                        </v-btn>
                      </template>
                      Filter
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="headers"
              :items="recordsFormatted"
              :items-per-page="5"
              :search="search"
              :loading="recordsLoading"
              loading-text="Loading, please wait a moment"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            >
              <template v-slot:[`item.action`]="{ item }">
                <div
                  class="text-center d-flex align-center justify-space-around"
                >
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        small
                        color="primary"
                        @click="setViewDetails(item)"
                      >
                        <v-icon size="large"> mdi-account-details </v-icon>
                      </v-btn>
                    </template>
                    <span>View Details</span>
                  </v-tooltip>

                  <v-tooltip v-if="hasAccess(item.status)" right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        small
                        color="primary"
                        @click="setUsersData(item)"
                        class="d-sm-inline"
                        :disabled="
                          item.type == 'Paymaya Service' ||
                          item.type == 'GCash Service'
                        "
                      >
                        <v-icon size="large"> mdi-folder-check-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ action }}</span>
                  </v-tooltip>
                </div>
              </template>

              <template v-slot:[`item.mobile_number`]="{ item }">
                {{ `+63${item.mobile_number}` }}
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  small
                  :class="
                    item.status == 'Approved' || item.status == 'Verified'
                      ? 'green lighten-5 green--text'
                      : item.status == 'Pending'
                      ? 'orange lighten-5 orange--text'
                      : 'red lighten-5 red--text'
                  "
                >
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="approveDialog" max-width="300">
      <v-card class="py-2">
        <v-card-title class="text-h6 d-block text-center">
          Confirm Approve
        </v-card-title>
        <v-card-text class="text-center">
          Do you really want to approve?
        </v-card-text>

        <v-card-actions class="d-block text-center">
          <v-btn
            ref="approveYes"
            color="primary"
            outlined
            small
            @click="handleSubmitApprove"
            :loading="approveLoading"
          >
            Yes
          </v-btn>

          <v-btn color="black" outlined small @click="approveDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="disapproveDialog" max-width="500">
      <v-card class="py-2">
        <v-card-title class="text-h6 d-block text-center">
          Confirm Disapprove
        </v-card-title>
        <v-card-text class="text-center">
          Do you really want to disapprove?
        </v-card-text>

        <v-row class="ma-1">
          <v-col cols="12">
            <v-textarea
              label="Remarks"
              no-resize
              outlined
              rows="2"
              hide-details="auto"
              v-model="remarks"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-card-actions class="d-block text-center">
          <div class="text-right">
            <v-btn
              ref="disapproveYes"
              color="primary"
              outlined
              small
              @click="handleSubmitDisapprove"
              :loading="disapproveLoading"
              class="ma-1"
            >
              Yes
            </v-btn>

            <v-btn
              color="black"
              outlined
              small
              @click="disapproveDialog = false"
              class="ma-1"
            >
              No
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="approvalPasswordDialog" max-width="350">
      <v-card>
        <v-card-title>Approval</v-card-title>
        <v-divider />
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="handleApprovalPassword"
        >
          <v-card-text class="pa-4">
            <v-alert type="error" outlined dense v-if="approvalPasswordError">{{
              approvalPasswordError
            }}</v-alert>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  ref="password"
                  v-model="password"
                  :rules="[rules.required]"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  label="Password"
                  outlined
                  dense
                  @click:append="showPassword = !showPassword"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pl-2 pr-2 mb-1">
              <v-col cols="12" md="6" class="pa-1">
                <v-btn
                  block
                  type="submit"
                  depressed
                  :loading="approvalPasswordLoading"
                  color="primary"
                >
                  Approve
                </v-btn>
              </v-col>

              <v-col cols="12" md="6" class="pa-1">
                <v-btn block depressed @click="closeApprovalPasswordDialog">
                  CANCEL
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>

    <FormDialog
      v-if="cashInDialogStatus"
      :approvalPassword="approvalPassword"
      @setModalStatus="setCashInDialogStatus"
    />
    <Validation
      v-if="validationDialogStatus"
      :usersData="usersData"
      :viewDetailsStatus="viewDetailsStatus"
      :action="action"
      @closeModal="setData"
    />
    <SearchFilter
      v-if="filterDialogStatus"
      :dates="dates"
      @setCloseModal="closeModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { format } from "date-fns";
import formatDate from "@/helpers/formatDate";
import formatDatev2 from "@/helpers/formatDatev2";
import formRules from "@/helpers/formRules";
import FormDialog from "@/components/ending/cashIn/FormDialog";
import Validation from "@/components/ending/cashIn/Validation";
import SearchFilter from "@/components/ending/cashIn/SearchFilter";
import downloadexcel from "vue-json-excel";

export default {
  components: { FormDialog, Validation, SearchFilter, downloadexcel },
  data: () => ({
    valid: true,
    rules: formRules,
    date_from: format(new Date(), "yyyy-MM-dd"),
    date_from_menu: false,
    date_to: format(new Date(), "yyyy-MM-dd"),
    date_to_menu: false,
    dates: {},
    validationDialogStatus: false,
    cashInDialogStatus: false,
    filterDialogStatus: false,
    search: "",
    sortBy: "created_at",
    sortDesc: true,
    headers: [
      {
        text: "Actions",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
        width: 90,
      },
      {
        text: "Player Name",
        value: "player_name",
        class: "text-uppercase",
        sortable: false,
      },
      {
        text: "Mobile Number",
        value: "mobile_number",
        class: "text-uppercase",
      },
      // {
      //   text: "Player Type",
      //   value: "player_type",
      //   class: "text-uppercase",
      //   sortable: false,
      // },
      { text: "Bank", value: "payment_method", class: "text-uppercase" },
      {
        text: "Reference Number",
        sortable: false,
        value: "reference_number",
        class: "text-uppercase",
      },
      { text: "Token", value: "fomattedAmount", class: "text-uppercase" },
      { text: "Type", value: "type", class: "text-uppercase" },
      { text: "Remarks", value: "remarks", class: "text-uppercase" },
      { text: "Status", value: "status", class: "text-uppercase" },
      {
        text: "Requested Date",
        value: "formattedCreatedAt",
        class: "text-uppercase",
      },
    ],
    approvalPasswordDialog: false,
    showPassword: false,
    approvalPassword: null,
    password: null,
    // Filter data
    approveDialog: false,
    approveId: "",
    disapproveDialog: false,
    disapproveId: "",
    remarks: "",
    usersData: {},
    viewDetailsStatus: false,
    action: "",
    filterStatus: ["All", "Pending", "Approved", "Disapproved"],
    filter: "All",
    query_filters: {},
  }),
  created() {
    const urlQuery = this.$route.query;

    if (Object.keys(urlQuery).length) {
      this.date_from = urlQuery.date_from;
      this.date_to = urlQuery.date_to;
      this.filter = urlQuery.filter;
    }
    // else {
    //   if (this.accountInfo.access.includes(52)) {
    //     this.filter = 'Pending'
    //   } else {
    //     this.filter = 'Verified'
    //   }
    // }

    if (this.accountInfo.access.includes(38)) {
      this.loadPending();
    } else {
      this.loadRecords();
    }
  },
  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("cashin", [
      "records",
      "recordsLoading",
      "approveError",
      "approveLoading",
      "disapproveError",
      "disapproveLoading",
      "approvalPasswordLoading",
      "approvalPasswordError",
    ]),
    recordsFormatted() {
      return this.records.map((record) => {
        return {
          ...record,
          fomattedAmount: this.moneyFormat(parseFloat(record.amount)),
          formattedCreatedAt: formatDate(record.created_at, true),
          formattedEvaluatedAt: formatDate(record.evaluated_at, true),
        };
      });
    },
    lessThanDate() {
      if (this.date_from && this.date_to) {
        if (new Date(this.date_to) < new Date(this.date_from)) {
          return "Must be higher or equal than date from.";
        }
      }

      return true;
    },
    lessThanDate2() {
      if (this.date_from && this.date_to) {
        if (new Date(this.date_from) > new Date(this.date_to)) {
          return "Must be lessthan or equal than date to.";
        }
      }

      return true;
    },
  },
  methods: {
    ...mapActions("cashin", [
      "getRecords",
      "getPending",
      "getFilteredRecords",
      "approveCashin",
      "disapproveCashin",
      "approvePassword",
    ]),
    ...mapMutations("cashin", ["setApprovalPasswordError"]),
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    showFilter() {
      if (!this.loading) {
        this.dates = {
          date_from: this.date_from,
          date_to: this.date_to,
          filter: this.filter,
        };
        this.filterDialogStatus = true;
      }
    },
    closeModal(dates_filter) {
      this.filterDialogStatus = false;

      if (dates_filter.filter_status == "search") {
        this.date_from = dates_filter.filter_date_from;
        this.date_to = dates_filter.filter_date_to;
        this.filter = dates_filter.filter;

        this.setQueryString();

        if (this.accountInfo.access.includes(38)) {
          this.getFilteredRecords(this.setFilterFormData());
        } else {
          this.loadRecords();
        }
      }
    },
    handleSearch() {
      if (this.$refs.form.validate()) {
        if (!this.recordsLoading) {
          this.$store.commit("cashin/setRecords", []);

          this.getRecords(this.setFilterFormData());
        }
      }
    },
    setFilterFormData() {
      const formData = new FormData();

      formData.append("filter", this.filter);
      formData.append("date_from", this.date_from);
      formData.append("date_to", this.date_to);

      const getData = new URLSearchParams(formData);

      return getData;
    },
    handleClickApprove(id) {
      this.approveId = id;
      this.approveDialog = true;
    },
    async handleSubmitApprove() {
      const formData = new FormData();
      formData.append("cashin_id", this.approveId);

      if (!this.approveLoading) {
        await this.approveCashin(formData);

        if (!this.approveError) {
          this.approveDialog = false;

          this.records.find((record) => record.id == this.approveId).status =
            "Approved";
        }
      }
    },
    handleClickDisapprove(id) {
      this.disapproveId = id;
      this.disapproveDialog = true;
    },
    async handleSubmitDisapprove() {
      const formData = new FormData();
      formData.append("cashin_id", this.disapproveId);
      formData.append("remarks", this.remarks);

      if (!this.disapproveLoading) {
        await this.disapproveCashin(formData);

        if (!this.disapproveError) {
          this.disapproveDialog = false;

          this.records.find((record) => record.id == this.disapproveId).status =
            "Disapproved";
        }
      }
    },
    async loadRecords() {
      if (!this.recordsLoading) {
        this.$store.commit("cashin/setRecords", []);

        await this.getRecords(this.setFilterFormData());
      }
    },
    async loadPending() {
      if (!this.recordsLoading) {
        this.$store.commit("cashin/setRecords", []);

        await this.getPending();
      }
    },
    refreshRecords() {
      if (this.accountInfo.access.includes(38)) {
        this.loadPending();
      } else {
        this.loadRecords();
      }
    },
    setCashInDialogStatus(submitStatus) {
      this.cashInDialogStatus = !this.cashInDialogStatus;

      if (!this.cashInDialogStatus && submitStatus) {
        this.approvalPassword = null;
        this.loadRecords();
      }

      this.viewDetailsStatus = false;
    },
    setViewDetails(item) {
      this.validationDialogStatus = true;
      this.usersData = item;
      this.viewDetailsStatus = true;
    },
    setUsersData(item) {
      this.validationDialogStatus = true;
      this.usersData = item;
      this.viewDetailsStatus = false;
    },
    setData(data) {
      this.validationDialogStatus = false;

      switch (data.action) {
        case "Verify":
          this.records.find((record) => record.id == data.playerId).status =
            "Verified";

          if (!this.records.find((record) => record.status == "Pending")) {
            if (this.accountInfo.access.includes(38)) {
              this.loadPending();
            } else {
              this.loadRecords();
            }
          }
          break;
        case "Approve":
          this.records.find((record) => record.id == data.playerId).status =
            "Approved";

          if (!this.records.find((record) => record.status == "Pending")) {
            if (this.accountInfo.access.includes(38)) {
              this.loadPending();
            } else {
              this.loadRecords();
            }
          }
          break;
        case "Rejected":
          this.records.find((record) => record.id == data.playerId).status =
            "Rejected";
          break;
        case "Disapprove":
          this.records.find((record) => record.id == data.playerId).status =
            "Disapproved";
          break;
      }
    },
    setQueryString() {
      const urlQuery = this.$route.query;

      if (
        urlQuery.date_from != this.date_from ||
        urlQuery.date_to != this.date_to ||
        urlQuery.filter != this.filter
      ) {
        this.query_filters = {
          date_from: this.date_from,
          date_to: this.date_to,
          filter: this.filter,
        };

        this.$router.push({
          name: this.$route.name,
          query: this.query_filters,
        });
      }
    },
    hasAccess(request_status) {
      let status = false;

      if (this.accountInfo.access.includes(52) && request_status == "Pending") {
        this.action = "Verify";
        status = true;
      }
      if (
        this.accountInfo.access.includes(26) &&
        request_status == "Verified"
      ) {
        this.action = "Approve";
        status = true;
      }

      return status;
    },

    formatDateRequestedDate(value) {
      return formatDate(value, true);
    },

    downloadExcelName() {
      return "CashIn Report " + formatDatev2(new Date()) + ".xls";
    },

    showApprovalPasswordDialog() {
      this.setApprovalPasswordError(null);
      this.approvalPasswordDialog = true;
      setTimeout(() => {
        this.$refs.password.focus();
      }, 200);
    },

    closeApprovalPasswordDialog() {
      this.approvalPasswordDialog = false;
      this.$refs.form.reset();
    },

    async handleApprovalPassword() {
      if (this.$refs.form.validate()) {
        const formData = new FormData();
        formData.append("password", this.password);

        await this.approvePassword(formData);

        if (!this.approvalPasswordError) {
          this.approvalPassword = this.password;
          this.closeApprovalPasswordDialog();
          this.setCashInDialogStatus();
        } else {
          setTimeout(() => {
            this.$refs.password.focus();
          }, 200);
        }
      }
    },
  },

  watch: {
    approveDialog(visible) {
      if (visible) {
        setTimeout(() => {
          this.$refs.approveYes.$el.focus();
        }, 200);
      }
    },
    disapproveDialog(visible) {
      if (visible) {
        setTimeout(() => {
          this.$refs.disapproveYes.$el.focus();
        }, 200);
      }
    },
  },
};
</script>
