var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Monitoring")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Game Card")]),_c('v-card',{staticClass:"shadow"},[_c('v-card-title',{staticClass:"text-h6 pa-4 font-weight-regular grey--text text--darken-1 overline"},[_c('v-row',{staticClass:"d-flex space-between"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"9"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"icon":"","color":"secondary"},on:{"click":_vm.loadRecords}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cached")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_vm._v(" Monitoring per league ")],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search","single-line":"","hide-details":"auto","clearable":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"secondary","small":""},on:{"click":_vm.showFilter}},on),[_c('v-icon',[_vm._v("mdi-filter-variant")])],1)]}}])},[_vm._v(" Filter ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"headers":_vm.getHeader,"items":_vm.records,"items-per-page":5,"search":_vm.search,"loading":_vm.loading,"loading-text":"Loading, please wait a moment","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","icon":_vm.$vuetify.breakpoint.mdAndUp ? true : false,"small":"","outlined":_vm.$vuetify.breakpoint.mdAndUp ? false : true,"color":"primary"},on:{"click":function($event){return _vm.showGames(item.id)}}},'v-btn',attrs,false),on),[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-icon',{attrs:{"size":"large"}},[_vm._v(" mdi-basketball ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.mdAndUp ? "" : "View Per Game")+" ")],1)]}}],null,true)},[_c('span',[_vm._v("View Per Game")])])]}},{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-avatar',{attrs:{"size":"36px","tile":""}},[_c('v-img',{attrs:{"src":item.logo},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black"}})],1)]},proxy:true}],null,true)})],1)],1)]}},{key:"item.cards",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.cards).toLocaleString())+" ")]}},{key:"item.used_cards",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.used_cards).toLocaleString())+" ("+_vm._s(_vm.getPercentage(item.cards, item.used_cards))+"%) ")]}},{key:"item.un_used_cards",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.un_used_cards).toLocaleString())+" ("+_vm._s(_vm.getPercentage(item.cards, item.un_used_cards))+"%) ")]}},{key:"item.greater_than",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:_vm.getColor(item.cards, item.greater_than),attrs:{"small":""}},[_vm._v(" "+_vm._s(parseInt(item.greater_than).toLocaleString())+" ("+_vm._s(_vm.getPercentage(item.cards, item.greater_than))+"%) ")])]}},{key:"item.less_than",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((parseInt(item.less_than) + parseInt(item.un_used_cards)).toLocaleString())+" ("+_vm._s(_vm.getPercentage( item.cards, parseInt(item.less_than) + parseInt(item.un_used_cards) ))+"%) ")]}}],null,true)})],1)],1),(_vm.dateFilterDialog)?_c('DateFilter',{attrs:{"data_filters":_vm.data_filters},on:{"setFilterCloseModal":_vm.setFilterCloseModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }