<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">PAGCOR Reports</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Game</h2>
    <v-card class="shadow mb-4">
      <v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
        Search Games
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-4">
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="handleSubmit">
          <v-row>
            <v-col cols="12" md="6">
              <v-menu
                v-model="schedule_date_start_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="schedule_date_start"
                    :rules="[rules.required]"
                    label="Schedule Date Start"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @keyup="schedule_date_start_menu = true"
                    @keydown="schedule_date_start_menu = false"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="schedule_date_start"
                  @input="schedule_date_start_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="schedule_date_end_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="schedule_date_end"
                    :rules="[lessThanDate]"
                    label="Schedule Date End (Optional)"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @keyup="schedule_date_end_menu = true"
                    @keydown="schedule_date_end_menu = false"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="schedule_date_end"
                  @input="schedule_date_end_menu = false"
                  :min="schedule_date_start ? schedule_date_start : ''"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="league"
                :items="leagues"
                item-text="league"
                item-value="id"
                label="League (Optional)"
                dense
                outlined
                hide-details="auto"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model="team"
                :items="leagueTeams"
                :item-text="leagueTeamName"
                item-value="id"
                label="Team (Optional)"
                dense
                outlined
                hide-details="auto"
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="league"
                    :items="leagues"
                    item-text="league"
                    item-value="id"
                    label="Game (Optional)"
                    dense
                    outlined
                    hide-details="auto"
                    clearable
                  ></v-autocomplete>
                </v-col> -->
            <v-col cols="12" md="6">
              <v-select
                v-model="game_status"
                :items="game_statuses"
                label="Game Status"
                dense
                outlined
                hide-details="auto"
                clearable
              ></v-select>
            </v-col>
          </v-row>
          <div class="mt-4">
            <v-btn
              :disabled="!valid"
              type="submit"
              color="primary"
              outlined
              @click.prevent="handleSubmit"
              :loading="recordsLoading"
            >
              Search
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="shadow">
      <v-card-title>
        <v-row align="end" justify="space-between">
          <v-col cols="auto" class="py-1">
            <span class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
              Games
            </span>
          </v-col>
          <v-col v-if="records.length" cols="auto" class="text-right py-1">
            <downloadexcel
              :data="exportExcelDataWithTotal()"
              :fields="{
                ID: 'id',
                League: 'league',
                Game: 'game',
                'Game Status': 'game_status',
                'Schedule Date': 'schedule',
                'Gross Sales': 'game_total_bet_amount',
                Payouts: 'game_total_prizes',
                'Gross Gaming Revenue': 'net_amount',
                'PAGCOR Share': 'pagcor_share',
              }"
              :name="downloadExcelName()"
              type="csv"
              class="d-inline-block"
            >
              <v-btn color="primary" outlined small depressed> Export CSV </v-btn>
            </downloadexcel>
            <v-btn
              color="primary"
              outlined
              small
              depressed
              @click="exportToPDF"
              class="mx-2 d-inline-block"
            >
              Export PDF
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-title>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          placeholder="Search"
          single-line
          hide-details="auto"
          clearable
          outlined
          dense
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <div ref="datatable">
        <v-card-text class="pa-0">
          <v-data-table
            :headers="headers"
            :items="records"
            :items-per-page="-1"
            :search="search"
            :loading="recordsLoading"
            loading-text="Loading, please wait a moment"
            mobile-breakpoint="0"
            hide-default-footer
          >
            <template v-slot:[`item.game`]="{ item }">
              <div v-if="item.game_id">
                <v-avatar size="36px" tile>
                  <v-img :src="item.team_a_logo">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
                <span class="text-caption"
                  >{{ item.team_a_name }} {{ item.team_a_alias }}</span
                >
                <span class="text-overline my-2 mx-2 font-italic">vs</span>
                <v-avatar size="36px" tile>
                  <v-img :src="item.team_b_logo">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
                <span class="text-caption"
                  >{{ item.team_b_name }} {{ item.team_b_alias }}</span
                >
              </div>
              <div v-else>No Activity</div>
            </template>
            <template v-slot:[`item.schedule`]="{ item }">
              <span  v-if="item.game_id">{{ scheduleDate(item.schedule) }}</span>
              <span v-else>{{ scheduleDate1(item.dates) }}</span>
            </template>
            <template v-slot:[`item.game_total_bet_amount`]="{ item }">
              <span v-if="item.game_id">{{
                "PHP " + moneyFormat(parseFloat(item.game_total_bet_amount))
              }}</span>
            </template>
            <template v-slot:[`item.game_total_prizes`]="{ item }">
              <span v-if="item.game_id">{{ "PHP " + moneyFormat(parseFloat(item.game_total_prizes)) }}</span>
            </template>
            <template v-slot:[`item.net_amount`]="{ item }">
              <span v-if="item.game_id">{{ "PHP " + moneyFormat(parseFloat(item.net_amount)) }}</span>
            </template>
            <template v-slot:[`item.pagcor_share`]="{ item }">
              <span v-if="item.game_id">{{ "PHP " + moneyFormat(parseFloat(item.pagcor_share)) }}</span>
            </template>
            <template v-if="records.length" v-slot:[`body.append`]>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right"><strong>TOTAL</strong></td>
                <td>
                  <strong>{{
                    "PHP " + moneyFormat(parseFloat(totalGrossAmount))
                  }}</strong>
                </td>
                <td>
                  <strong>{{
                    "PHP " + moneyFormat(parseFloat(totalDistributedPrizes))
                  }}</strong>
                </td>
                <td>
                  <strong>{{ "PHP " + moneyFormat(parseFloat(totalNetAmount)) }}</strong>
                </td>
                <td>
                  <strong>{{
                    "PHP " + moneyFormat(parseFloat(totalPagcorShare))
                  }}</strong>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";
import formatDatev2 from "@/helpers/formatDatev2";
import downloadexcel from "vue-json-excel";
import html2pdf from "html2pdf.js";

export default {
  components: { downloadexcel },
  data: () => ({
    valid: true,
    rules: formRules,
    search: "",
    schedule_date_start: "",
    schedule_date_start_menu: false,
    schedule_date_end: "",
    schedule_date_end_menu: false,
    league: "",
    team: "",
    game_status: "All",
    headers: [
      // { text: 'ID', value: 'game_id', align: 'left' },
      { text: "League", sortable: false, value: "league", class: "text-uppercase" },
      { text: "Game", sortable: false, value: "game", class: "text-uppercase" },
      {
        text: "Game Status",
        sortable: false,
        value: "game_status",
        class: "text-uppercase",
      },
      { text: "Schedule Date", value: "schedule", class: "text-uppercase" },
      { text: "Gross Sales", value: "game_total_bet_amount", class: "text-uppercase" },
      { text: "Payouts", value: "game_total_prizes", class: "text-uppercase" },
      { text: "Gross Gaming Revenue", value: "net_amount", class: "text-uppercase" },
      { text: "PAGCOR Share", value: "pagcor_share", class: "text-uppercase" },
    ],
    game_statuses: ["All", "Not Started", "Ongoing", "Finished"],
  }),
  created() {
    this.$store.commit("ending_pagcor_reports_game/setRecords", []);

    if (!this.$store.state.leagues.length) {
      this.$store.dispatch("getEndingComponents");
    }
  },
  computed: {
    ...mapState(["leagues", "teams"]),
    ...mapState("ending_pagcor_reports_game", ["records", "recordsLoading"]),
    leagueTeams() {
      return this.teams.filter((team) => {
        return team.league_id == parseInt(this.league);
      });
    },
    totalGrossAmount() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.game_total_bet_amount) + total,
          0
        );
      } else {
        return 0;
      }
    },
    totalDistributedPrizes() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.game_total_prizes) + total,
          0
        );
      } else {
        return 0;
      }
    },
    totalNetAmount() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseFloat(obj.net_amount) + total, 0);
      } else {
        return 0;
      }
    },
    totalPagcorShare() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.pagcor_share) + total,
          0
        );
      } else {
        return 0;
      }
    },
    exportExcelData() {
      return this.records.map((record) => {
        return {
          id: record.game_id,
          league: record.league,
          game: (record.game_id) ?
            record.team_a_name +
            " " +
            record.team_a_alias +
            " vs " +
            record.team_b_name +
            " " +
            record.team_b_alias : "No Activity",
          game_status: record.game_status,
          schedule: (record.game_id) ? this.scheduleDate(record.schedule) : this.scheduleDate1(record.dates),
          game_total_bet_amount: (record.game_id) ? 
            "PHP " + this.moneyFormat(parseFloat(record.game_total_bet_amount)) : "",
          game_total_prizes: (record.game_id) ? 
            "PHP " + this.moneyFormat(parseFloat(record.game_total_prizes)) : "",
          net_amount: (record.game_id) ? "PHP " + this.moneyFormat(parseFloat(record.net_amount)) : "",
          pagcor_share: (record.game_id) ? "PHP " + this.moneyFormat(parseFloat(record.pagcor_share)) : "",
        };
      });
    },
  },
  methods: {
    ...mapActions("ending_pagcor_reports_game", ["getRecords"]),
    lessThanDate(val) {
      if (val) {
        if (new Date(val) < new Date(this.schedule_date_start)) {
          return "Must be higher or equal than schedule date start.";
        }
      }

      return true;
    },
    leagueTeamName: (team) => team.team_name + " " + team.team_alias,
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    scheduleDate(sched) {
      return formatDatev2(sched);
    },
    scheduleDate1(sched) {
      return formatDatev2(sched, 'date');
    },
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        const formData = new FormData();
        formData.append(
          "schedule_date_start",
          this.schedule_date_start === null ? "" : this.schedule_date_start
        );
        formData.append(
          "schedule_date_end",
          this.schedule_date_end === null ? "" : this.schedule_date_end
        );
        formData.append("league_id", this.league === null ? "" : this.league);
        formData.append("team_id", this.team === null ? "" : this.team);
        formData.append("game_status", this.game_status === null ? "" : this.game_status);

        const getData = new URLSearchParams(formData);

        if (!this.recordsLoading) {
          this.$store.commit("ending_pagcor_reports_game/setRecords", []);

          await this.getRecords(getData);
        }
      }
    },
    exportExcelDataWithTotal() {
      const dataTotal = {
        game_id: "",
        league: "",
        game: "",
        game_status: "",
        schedule: "TOTAL",
        game_total_bet_amount:
          "PHP " + this.moneyFormat(parseFloat(this.totalGrossAmount)),
        game_total_prizes:
          "PHP " + this.moneyFormat(parseFloat(this.totalDistributedPrizes)),
        net_amount: "PHP " + this.moneyFormat(parseFloat(this.totalNetAmount)),
        pagcor_share: "PHP " + this.moneyFormat(parseFloat(this.totalPagcorShare)),
      };

      return [...this.exportExcelData, dataTotal];
    },
    exportToPDF() {
      html2pdf(this.$refs.datatable, {
        filename: "Ending Game Report " + formatDatev2(new Date()) + ".pdf",
        image: { type: "jpeg" },
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: { unit: "in", format: "government-letter", orientation: "portrait" },
      });
    },
    downloadExcelName() {
      return "Ending Game Report " + formatDatev2(new Date()) + ".xls";
    },
  },
};
</script>
