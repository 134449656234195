<template>
  <v-card class="bs-shadow-none">
    <div class="pa-4 pa-md-6">
      <v-form @submit.prevent="rejectAccount" ref="form">
        <h3 class="mb-3">Reject</h3>
        <p class="grey--text mb-4 mb-md-6">
          You're about to reject {{ fund_data.account_number }}, please confirm to
          proceed.
        </p>
        <v-autocomplete
          v-model="reason"
          :items="setReasons"
          :rules="[rules.required]"
          outlined
          dense
          autofocus
          hide-details="auto"
        ></v-autocomplete>
        <div class="d-flex justify-end bs-gap-2 mt-4 mt-md-6">
          <v-btn text depressed @click="closeDialog()" :disabled="loading">Cancel</v-btn>
          <v-btn color="red" dark depressed :loading="loading" type="submit"
            >Confirm</v-btn
          >
        </div>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import response_post from "@/helpers/api_request_post.js";
import form_rules from "@/helpers/formRules.js";

export default {
  data: () => ({
    rules: form_rules,
    loading: false,
    reasons: [],
    reason: "",
  }),
  props: {
    fund_data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    setReasons() {
      const reasons = [
        `Not registered to ${this.fund_data.account_type}`,
        `${this.fund_data.account_type} account name doesn't match to your name`,
      ];

      return reasons;
    },
  },
  methods: {
    async rejectAccount() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const form_data = new FormData();
        form_data.append("bank_id", this.fund_data.account_id);
        form_data.append("player_id", this.fund_data.player_id);
        form_data.append("reason", this.reason);

        const res = await response_post(form_data, "bank_account/reject");

        this.$emit("send_message", {
          type: "reject",
          status: res.data.status,
          message: res.data.message,
        });

        this.loading = false;
      }
    },
    closeDialog() {
      this.$emit("close_dialog", "reject");
    },
  },
};
</script>
