const url = process.env.VUE_APP_API_URL + "agents_report"

export default {
    namespaced: true,   

    state: {
        loading: false,
        playersLoading: false,
        gamesLoading: false,
        records: [],
        players: [],
        games: [],
        error: null,
        errors: []
    },

    mutations: {
        setLoading(state, val) {
            state.loading = val
        },

        setPlayersLoading(state, val) {
            state.playersLoading = val
        },

        setGamesLoading(state, val) {
            state.gamesLoading = val
        },

        setPlayers(state, val) {
            state.players = val
        },

        setGames(state, val) {
            state.games = val
        },

        setRecords(state, val) {
            state.records = val
        },

        setError(state, val) {
            state.error = val
        },

        setErrors(state, val) {
            state.errors = val
        }
    },

    actions: {

        async getRecords({ commit, rootState }, data) {
            commit("setLoading", true)
      
            try {
                const response = await fetch(`${url}/player_bets?${data}` , {
                    method: 'GET',
                    headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setRecords", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                 commit("setLoading", false)
            }
        },

        async getPlayers({ commit, rootState }, data) {
            commit("setPlayersLoading", true)
      
            try {
                const response = await fetch(`${url}/players?${data}` , {
                    method: 'GET',
                    headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setPlayers", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                 commit("setPlayersLoading", false)
            }
        },

        async getGames({ commit, rootState }, data) {
            commit("setGamesLoading", true)
      
            try {
                const response = await fetch(`${url}/games?${data}` , {
                    method: 'GET',
                    headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setGames", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                 commit("setGamesLoading", false)
            }
        },

        async getProgress({ commit, rootState }, data) {
            commit("setLoading", true)
      
            try {
                const response = await fetch(`${url}/progress?${data}` , {
                    method: 'GET',
                    headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                    }
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                    throw { response, res }
                } else {
                    commit("setRecords", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                 commit("setLoading", false)
            }
        },
    }
}