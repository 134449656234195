<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card>
      <v-card-title class="text-h5"> Create Team </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="py-4">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="handleSubmit"
        >
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                ref="league_id"
                v-model="league_id"
                :rules="[rules.required]"
                :items="leagues"
                item-text="league"
                item-value="id"
                label="League"
                dense
                outlined
                hide-details="auto"
                @change="resetNameAndAliasText"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Name"
                ref="team_name"
                outlined
                dense
                :rules="[
                  rules.required,
                  rules.minimum(3),
                  nameExist,
                ]"
                v-model="team_name"
                hide-details="auto"
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                label="Alias"
                ref="team_alias"
                outlined
                dense
                :rules="[
                  rules.minimum(3),
                  aliasExist,
                ]"
                v-model="team_alias"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Logo Url"
                outlined
                dense
                :rules="[rules.required]"
                v-model="logo"
                hide-details="auto"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-switch
                v-model="status"
                color="success"
                :label="`Status: ${statusText}`"
                @change="setToggleStatus"
                class="d-inline-block"
              />
            </v-col>

            <v-col cols="12" md="6">
              <div class="mt-4 text-right">
                <v-btn
                  color="black"
                  outlined
                  class="me-2"
                  @click.stop="closeModal"
                >
                  Cancel
                </v-btn>

                <v-btn
                  type="submit"
                  color="primary"
                  outlined
                  :loading="createTeamLoading"
                >
                  {{ buttonText }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";

export default {
  props: {
    teamData: {
      type: Object,
    },
  },
  data: () => ({
    team_name: "",
    team_alias: "",
    valid: true,
    rules: formRules,
    id: 0,
    league_id: "",
    leagueText: "",
    // name: '',
    nameExistText: "",
    // alias: '',
    aliasExistText: "",
    logo: "",
    status: true,
    statusText: "Active",
    buttonText: "Create",
    updatedData: [],
    insertedData: [],
  }),
  created() {
    if (!this.$store.state.leagues.length) {
      this.$store.dispatch("getEndingComponents");
    }
  },
  mounted() {
    if (Object.keys(this.teamData).length != 0) {
      this.id = this.teamData.id;
      this.league_id = this.teamData.league_id;
      this.team_name = this.teamData.team_name;
      this.team_alias = this.teamData.team_alias;
      this.logo = this.teamData.logo;
      this.status = this.teamData.isActive === "Active" ? true : false;
      this.statusText = this.teamData.isActive === "Active" ? "Active" : "Inactive";
      this.buttonText = "Update";
    }

    setTimeout(() => {
      this.$refs.league_id.focus();
    }, 300);
  },
  computed: {
    ...mapState(["leagues"]),
    ...mapState("ending_teams", [
      "createTeamLoading",
      "teamId",
      "createTeamError",
      "createTeamErrors",
    ]),
    dialog: {
      get: function () {
        return true;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
    leagueName() {
      return this.leagues.find((league) => {
        return league.id == this.league_id;
      });
    },
  },
  methods: {
    ...mapActions("ending_teams", ["createTeam", "updateTeam"]),
    closeModal() {
      this.$emit("closeModal", this.updatedData, this.insertedData);
    },
    nameExist(val) {
      if (val == this.nameExistText) {
        return `"${val}" already exist`;
      } else return true;
    },
    aliasExist(val) {
      if (val != '') {
        if (val == this.aliasExistText) {
          return `"${val}" already exist`;
        } else return true;
      } else return true
    },
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        if (!this.createTeamLoading) {
          if (Object.keys(this.teamData).length != 0) {
            await this.updateTeam(this.setFormData());

            if (!this.createTeamError && this.isObjectEmpty(this.createTeamErrors)) {
              this.closeModal();
              this.$refs.form.reset();
            } else {
              this.setErrors();
            }
          } else {
            await this.createTeam(this.setFormData());

            if (
              !this.createTeamError &&
              this.isObjectEmpty(this.createTeamErrors)
            ) {
              this.setInsertedData();
              this.closeModal();
              this.$refs.form.reset();
            } else {
              this.setErrors();
            }
          }
        }
      }
    },
    setInsertedData() {
      var today = new Date();

      this.insertedData = {
        team_id: this.teamId,
        league: this.leagueName.league,
        league_id: this.league_id,
        team_name: this.team_name,
        team_alias: this.team_alias,
        logo: this.logo,
        is_active: this.statusText === "Active" ? "Active" : "Inactive",
        created_at: today,
      };
    },
    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    setToggleStatus() {
      this.statusText = this.status === true ? "Active" : "Inactive";
    },
    setFormData() {
      const formData = new FormData();

      if (Object.keys(this.teamData).length != 0) {
        formData.append("team_id", this.id);

        this.updatedData = {
          id: this.id,
          leagueText: this.leagueName.league,
          league_id: this.league_id,
          team_name: this.team_name,
          team_alias: this.team_alias,
          isActive: this.statusText,
          logo: this.logo,
        };
      }

      formData.append("league_id", this.league_id);
      formData.append("team_name", this.team_name);
      formData.append("team_alias", this.team_alias);
      formData.append("logo", this.logo);
      formData.append(
        "is_active",
        this.status === true ? "Active" : "Inactive"
      );

      return formData;
    },
    setErrors() {
      let errorFocus = "";

      Object.entries(this.createTeamErrors).forEach(([key, value]) => {
        if (value) {
          if (!errorFocus) {
            errorFocus = key;
          }

          switch (key) {
            case "team_name":
              this.nameExistText = this.team_name;
              break;
            case "team_alias":
              this.aliasExistText = this.team_alias;
              break;
          }

          this.$refs.form.validate();
          this.$refs[errorFocus].focus();
        }
      });
    },

    resetNameAndAliasText() {
      this.nameExistText = ""
      this.aliasExistText = ""
    }
  },
  // watch: {
  //   dialog(visible) {
  //     if (!visible) {
  //       setTimeout(() => {
  //         this.$refs.league.focus()
  //       }, 200);
  //     }
  //   }
  // }
};
</script>