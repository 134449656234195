<template>
  <div class="d-block">
    <v-btn text class="red--text" depressed @click.stop="dialog = true">
      Reject
    </v-btn>
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <div class="pa-4 pa-md-6">
          <h3 class="font-weight-bold mb-3">Reject</h3>
          <p class="grey--text mb-4">
            You're about to reject this player's account, this means that you
            already checked the validity of this player's valid id and selfie.
          </p>
          <v-form ref="form">
            <v-autocomplete
              outlined
              dense
              label="Reason"
              v-model="reason"
              :items="reject_reasons"
              item-text="reason"
              item-value="id"
              :rules="[rules.required]"
              hide-details="auto"
              class="mb-4"
            ></v-autocomplete>
            <div class="d-flex justify-end align-center gap-sm">
              <v-btn text @click="dialog = false"> Cancel </v-btn>
              <v-btn
                @click="playerReject()"
                color="error"
                depressed
                :loading="rejectLoading"
              >
                Reject
              </v-btn>
              <!-- <v-btn
                color="error"
                depressed
                @click="rejectAndNext()"
                :disabled="rejectLoading || !next_player_id"
              >
                Reject & Next
              </v-btn> -->
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import form_rules from "@/helpers/formRules";
import response_post from "@/helpers/api_request_post.js";
import response_get from "@/helpers/api_request_get.js";

export default {
  props: {
    modalData: {
      type: Object,
      required: true,
    },

    // next_player_id: String,
  },
  data: () => ({
    rules: form_rules,
    dialog: false,
    rejectLoading: false,
    reject_reasons: [],
    reason: 0,
  }),
  mounted() {
    this.getPlayerRejectReasons();
  },
  methods: {
    async playerReject() {
      this.rejectLoading = true;

      if (this.$refs.form.validate()) {
        const form_data = new FormData();

        form_data.append("player_id", this.modalData.id);
        form_data.append("reject_reason_id", this.reason);
        const res = await response_post(form_data, "players/reject");

        if (res.status == 200) {
          this.$emit("getDetails");
          this.dialog = false;
          this.reason = "";
        } else {
          this.$store.commit(
            "setPopUpError",
            "Something went wrong, please try again!"
          );
        }
      }

      this.rejectLoading = false;
    },
    async getPlayerRejectReasons() {
      const res = await response_get("", "players/get_reject_reasons");

      if (res.status == 200) {
        //const reasons = [];
        // res.data.reasons.forEach(function (reason) {
        //   reasons.push(`${reason}`);
        // });
        this.reject_reasons = res.data.reasons;
      }
    },
    // rejectAndNext() {
    //   if (this.$refs.form.validate()) {
    //     this.playerReject();
    //     this.$emit("proceed_to_next_player");
    //   }
    // },
  },
};
</script>
