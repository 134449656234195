const url = process.env.VUE_APP_API_URL + "commission_payments"

export default {
    namespaced: true,   

    state: {
        records: [],
        recordsLoading: false,
        commission: [],
        commissionLoading: false,
        paidCommission: [],
        paidCommissionLoading: false,
        error: null
    },

    mutations: {
        setRecords(state, val) {
            state.records = val
        },

        setRecordsLoading(state, val) {
            state.recordsLoading = val
        },

        setCommission(state, val) {
            state.commission = val
        },

        setCommissionLoading(state, val) {
            state.commissionLoading = val
        },

        setPaidCommission(state, val) {
            state.paidCommission = val
        },

        setPaidCommissionLoading(state, val) {
            state.paidCommissionLoading = val
        },

        setError(state, val) {
            state.error = val
        },
    },

    actions: {
        async getRecords({ commit, rootState }) {
            commit("setRecordsLoading", true)

            try {
                const response = await fetch(`${url}` , {
                  method: 'GET',
                  headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                  }
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                  throw { response, res }
                } else {
                  commit("setRecords", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setRecordsLoading", false)
            }
        },

        async getCommission({ commit, rootState }, data) {
            commit("setCommissionLoading", true)

            try {
                const response = await fetch(`${url}/commission?${data}` , {
                  method: 'GET',
                  headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                  }
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                  throw { response, res }
                } else {
                  commit("setCommission", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setCommissionLoading", false)
            }
        },

        async payCommission({ commit, rootState }, data) {
            commit("setPaidCommissionLoading", true)
            commit("setError", null)

            try {
                const response = await fetch(`${url}/pay_commission` , {
                  method: 'POST',
                  headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                  },
                  body: data
                });
        
                const res = await response.json()
                
                if (!response.ok) {
                  throw { response, res }
                } else {
                  commit("setPaidCommission", res.records)
                }
            } catch(error) {
                const errorStatus = error.response.status
                
                if (errorStatus==417) {
                    commit("setError", '417')
                } else if (errorStatus==401) {
                    commit("setError", "Unauthorized", { root: true })
                } else {
                    commit("setRefreshError", "Submitted invalid data", { root: true })
                }
            } finally {
                commit("setPaidCommissionLoading", false)
            }
        }
    }
}