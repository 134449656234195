<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Investors</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Investor List</h2>

    <v-card class="shadow">
      <div class="d-flex justify-space-between align-center bs-py-3 bs-px-4">
        <div class="d-flex">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="black"
                v-bind="attrs"
                v-on="on"
                @click="set_create_update_dialog"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>New</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="black"
                dark
                v-bind="attrs"
                v-on="on"
                @click="loadRecords"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Refresh</span>
          </v-tooltip>
        </div>
        <div class="d-flex" style="align-items: center; gap: 1rem">
          <div class="d-flex align-center gap">
            <v-text-field
              ref="search"
              v-model="search"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="records_loading"
              clearable
              outlined
              dense
            >
            </v-text-field>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon color="black" v-on="on" @click="set_filter_dialog()">
                  <v-icon> mdi-filter-variant </v-icon>
                </v-btn>
              </template>
              Filter
            </v-tooltip>
          </div>
        </div>
      </div>

      <v-divider></v-divider>

      <v-data-table
        :headers="headers"
        :items="records"
        :items-per-page="itemPerPage"
        :search="search"
        :loading="records_loading"
        loading-text="Loading, please wait a moment"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-slot:[`item.action`]="{ item }">
          <div class="d-flex align-center">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="primary"
                  @click="showInvestorInfo(item)"
                >
                  <v-icon> mdi-square-edit-outline </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="primary"
                  @click="set_manage_sub_users_dialog(item)"
                >
                  <v-icon> mdi-account-supervisor </v-icon>
                </v-btn>
              </template>
              <span>Manage Sub Accounts</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.mobile_number`]="{ item }">
          {{ "+63" + item.mobile_number }}
        </template>

        <template v-slot:[`item.base_commission`]="{ item }">
          <span>{{ moneyFormat(parseFloat(item.base_commission)) }}%</span>
        </template>

        <template v-slot:[`item.commission`]="{ item }">
          <span>{{ moneyFormat(parseFloat(item.commission)) }}%</span>
        </template>

        <template v-slot:[`item.sub_commission`]="{ item }">
          <span>{{ moneyFormat(parseFloat(item.sub_commission)) }}%</span>
        </template>

        <template v-slot:[`item.category`]="{ item }">
          <v-chip
            small
            class="font-weight-bold"
            :class="
              item.category == 'Regular'
                ? 'green lighten-5 green--text'
                : 'orange lighten-5 orange--text'
            "
          >
            {{ item.category }}
          </v-chip>
        </template>

        <template v-slot:[`item.wallet`]="{ item }">
          {{ `Php ${moneyFormat(parseFloat(item.wallet))}` }}
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          <span>{{ setCreatedAtFormat(item.created_at) }}</span>
        </template>
      </v-data-table>
    </v-card>

    <CreateUpdate
      v-if="create_update_dialog"
      :investor_data="investor_data"
      @set_close_create_update_dialog="set_close_create_update_dialog"
    />
    <FilterDialog
      v-if="filterDialog"
      :filter_data="filter_data"
      @close_filter_dialog="close_filter_dialog"
    />
    <ManageSubUsers
      v-if="manage_sub_user_dialog"
      :investor_id="investor_id"
      @set_close_manage_sub_users_dialog="set_close_manage_sub_users_dialog"
    />
  </div>
</template>

<script>
import CreateUpdate from "@/components/investors/CreateUpdate";
import responseGet from "@/helpers/api_request_get";
import { format /* , sub */ } from "date-fns";
import FilterDialog from "@/components/investors/FilterInvestors";
import formatDatev2 from "@/helpers/formatDatev2";
import ManageSubUsers from "@/components/investors/ManageSubUsers";
import { mapActions } from "vuex";

export default {
  components: { CreateUpdate, FilterDialog, ManageSubUsers },

  data: () => ({
    search: "",
    investor_data: {},
    create_update_dialog: false,
    manage_sub_user_dialog: false,
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
      },
      {
        text: "Territory",
        value: "territory",
        class: "text-uppercase",
      },
      {
        text: "Investor Name",
        value: "investor_name",
        class: "text-uppercase",
      },
      {
        text: "Mobile Number",
        value: "mobile_number",
        class: "text-uppercase",
      },
      {
        text: "Base %",
        value: "base_commission",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Commission %",
        value: "commission",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Sub %",
        value: "sub_commission",
        class: "text-uppercase",
        align: "right",
      },
      { text: "Category", value: "category", class: "text-uppercase", align: "start" },
      { text: "Wallet", value: "wallet", class: "text-uppercase", align: "right" },
      { text: "Link Type", value: "link_type", class: "text-uppercase", align: "start" },
      { text: "Date Created", value: "created_at", class: "text-uppercase" },
    ],
    records: [],
    records_loading: false,
    itemPerPage: 10,
    sortBy: "created_at",
    sortDesc: true,
    filter_selected: 1,
    date_from: "2023-03-04", //format(new Date(), 'yyyy-MM-dd'), // format(sub(new Date(), { days: 30 }), 'yyyy-MM-dd'),
    date_to: format(new Date(), "yyyy-MM-dd"),
    query_filters: {},
    filter_status: false,
    filterDialog: false,
    investor_id: 0,
  }),

  mounted() {
    const urlQuery = this.$route.query;

    if (Object.keys(urlQuery).length) {
      this.filter_selected = parseInt(urlQuery.filter);

      switch (this.filter_selected) {
        case 1: {
          if (Object.prototype.hasOwnProperty.call(urlQuery, "date_from"))
            this.date_from = urlQuery.date_from;
          if (Object.prototype.hasOwnProperty.call(urlQuery, "date_to"))
            this.date_to = urlQuery.date_to;
          break;
        }
        case 2: {
          if (Object.prototype.hasOwnProperty.call(urlQuery, "first_name"))
            this.first_name = urlQuery.first_name;
          if (Object.prototype.hasOwnProperty.call(urlQuery, "last_name"))
            this.last_name = urlQuery.last_name;
          break;
        }
        case 3: {
          if (Object.prototype.hasOwnProperty.call(urlQuery, "mobile_number"))
            this.mobile_number = urlQuery.mobile_number;
          break;
        }
      }
    }

    this.loadRecords();
  },

  methods: {
    ...mapActions("logout", ["sendLogoutRequest"]),

    set_create_update_dialog() {
      this.investor_data = {};
      this.create_update_dialog = true;
    },

    set_close_create_update_dialog(action, createdUpdatedData) {
      this.create_update_dialog = false;

      if (action == "create") {
        if (Object.keys(createdUpdatedData).length != 0) {
          this.records = [...this.records, createdUpdatedData];
        }
      }

      if (action == "update") {
        if (Object.keys(createdUpdatedData).length != 0) {
          const investorData = this.records.find(
            (record) => record.investor_id == createdUpdatedData.investor_id
          );

          investorData.investor_id = createdUpdatedData.investor_id;
          investorData.territory = createdUpdatedData.territory;
          investorData.investor_name = createdUpdatedData.investor_name;
          investorData.mobile_number = createdUpdatedData.mobile_number;
          investorData.base_commission = createdUpdatedData.base_commission;
          investorData.commission = createdUpdatedData.commission;
          investorData.sub_commission = createdUpdatedData.sub_commission;
          investorData.category = createdUpdatedData.category;
          investorData.wallet = createdUpdatedData.wallet;
          investorData.link_type = createdUpdatedData.link_type;
          investorData.status = createdUpdatedData.status;
        }
      }
    },

    set_manage_sub_users_dialog(item) {
      this.investor_id = parseInt(item.investor_id);
      this.manage_sub_user_dialog = !this.manage_sub_user_dialog;
    },

    set_close_manage_sub_users_dialog() {
      this.manage_sub_user_dialog = !this.manage_sub_user_dialog;
    },

    async loadRecords() {
      if (!this.records_loading) {
        this.records_loading = true;
        this.records = [];

        const response = await responseGet(this.setFormData(), "investors");

        switch (response.status) {
          case 200:
            this.records = response.data.records;
            this.set_query_string();
            break;
          case 401:
            await this.sendLogoutRequest();
            this.$router.push({ name: "Home" });
            break;
        }

        this.records_loading = false;
      }
    },

    setCreatedAtFormat(date) {
      return formatDatev2(date);
    },

    setFormData() {
      const formData = new FormData();

      formData.append("filter", this.filter_selected);

      switch (this.filter_selected) {
        case 1:
          formData.append("date_from", this.date_from);
          formData.append("date_to", this.date_to ?? this.date_from);
          break;
        case 2:
          if (this.first_name) formData.append("first_name", this.first_name);
          if (this.last_name) formData.append("last_name", this.last_name);
          break;
        case 3:
          formData.append("mobile_number", this.mobile_number);
          break;
      }

      return new URLSearchParams(formData);
    },

    set_query_string() {
      const urlQuery = this.$route.query;
      this.query_filters = {};
      this.query_filters["filter"] = this.filter_selected;

      switch (this.filter_selected) {
        case 1: {
          if (
            urlQuery.filter != this.filter_selected ||
            urlQuery.date_from != this.date_from ||
            urlQuery.date_to != this.date_to
          ) {
            this.query_filters["filter"] = this.filter_selected;

            if (this.date_from != null) this.query_filters["date_from"] = this.date_from;
            if (this.date_to != null) this.query_filters["date_to"] = this.date_to;

            this.$router.replace({
              name: this.$route.name,
              query: this.query_filters,
            });
          }
          break;
        }
        case 2: {
          if (
            urlQuery.filter != this.filter_selected ||
            urlQuery.first_name != this.first_name ||
            urlQuery.last_name != this.last_name
          ) {
            if (this.first_name != null && this.first_name != "")
              this.query_filters["first_name"] = this.first_name;
            if (this.last_name != null && this.last_name != "")
              this.query_filters["last_name"] = this.last_name;

            this.$router.replace({
              name: this.$route.name,
              query: this.query_filters,
            });
          }
          break;
        }
        case 3: {
          if (
            urlQuery.filter != this.filter_selected ||
            urlQuery.mobile_number != this.mobile_number
          ) {
            if (this.mobile_number != null)
              this.query_filters["mobile_number"] = this.mobile_number;

            this.$router.replace({
              name: this.$route.name,
              query: this.query_filters,
            });
          }
          break;
        }
      }
    },

    set_filter_dialog() {
      this.filter_data = {
        date_from: this.date_from,
        date_to: this.date_to,
        first_name: this.first_name,
        last_name: this.last_name,
        mobile_number: this.mobile_number,
        filter: this.filter_selected,
      };

      this.filterDialog = true;
    },

    close_filter_dialog(data) {
      if (Object.keys(data).length) {
        this.filter_selected = data.filter;

        switch (this.filter_selected) {
          case 1:
            this.date_from = data.date_from;
            this.date_to = data.date_to;
            break;
          case 2:
            this.first_name = data.first_name;
            this.last_name = data.last_name;
            break;
          case 3:
            this.mobile_number = data.mobile_number;
            break;
        }

        this.filter_selected = data.filter;
        this.filter_status = true;

        this.loadRecords();
      } else this.filter_status = false;

      this.filterDialog = false;
    },

    showInvestorInfo(val) {
      this.investor_data = val;
      this.create_update_dialog = true;
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>
