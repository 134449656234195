<template>
	<v-list dense class="header-navigation pt-0">
		<v-list-item link router :to="{ name: 'Dashboard' }" color="primary" v-if="dashboard">
			<img src="https://img.icons8.com/3d-fluency/94/null/combo-chart.png" class="mr-4" style="width: 24px; height: 24px" />

			<v-list-item-content>
				<v-list-item-title>Dashboard</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<v-subheader v-if="investors.length" class="mt-3"> Investors </v-subheader>

		<v-list-item v-for="link in investors" :key="link.title" link router :to="{ name: link.route }" color="primary">
			<img :src="link.icon" class="mr-4" style="width: 24px; height: 24px" />

			<v-list-item-content>
				<v-list-item-title>{{ link.text }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<v-subheader v-if="agents.length" class="mt-3"> Agents </v-subheader>

		<v-list-item v-for="link in agents" :key="link.title" link router :to="{ name: link.route }" color="primary">
			<img :src="link.icon" class="mr-4" style="width: 24px; height: 24px" />

			<v-list-item-content>
				<v-list-item-title>{{ link.text }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<v-subheader v-if="players.length" class="mt-3"> Players </v-subheader>

		<v-list-item v-for="link in players" :key="link.title" link router :to="{ name: link.route }" color="primary">
			<img :src="link.icon" class="mr-4" style="width: 24px; height: 24px" />

			<v-list-item-content>
				<v-list-item-title>{{ link.text }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<v-subheader v-if="ending_game.length" class="mt-3"> Ending Game </v-subheader>

		<v-list-item v-for="link in ending_game" :key="link.title" link router :to="{ name: link.route }" color="primary">
			<img :src="link.icon" class="mr-4" style="width: 24px; height: 24px" />

			<v-list-item-content>
				<v-list-item-title>{{ link.text }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<v-subheader v-if="ending_reports.length" class="mt-3"> Ending Reports </v-subheader>

		<v-list-item v-for="link in ending_reports" :key="link.title" link router :to="{ name: link.route }" color="primary">
			<img :src="link.icon" class="mr-4" style="width: 24px; height: 24px" />

			<v-list-item-content>
				<v-list-item-title>{{ link.text }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<v-subheader v-if="lucky_four_game.length"> Lucky Four Game </v-subheader>
		<v-list-item v-for="link in lucky_four_game" :key="link.title" link router :to="{ name: link.route }" color="primary">
			<img :src="link.icon" class="mr-4" style="width: 24px; height: 24px" />
			<v-list-item-content>
				<v-list-item-title>{{ link.text }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<v-subheader v-if="lucky_four_reports.length" class="mt-3"> Lucky Four Reports </v-subheader>
		<v-list-item v-for="link in lucky_four_reports" :key="link.title" link router :to="{ name: link.route }" color="primary">
			<img :src="link.icon" class="mr-4" style="width: 24px; height: 24px" />
			<v-list-item-content>
				<v-list-item-title>{{ link.text }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<v-subheader v-if="power_ball_game.length" class="mt-3"> Power Ball Game </v-subheader>
		<v-list-item v-for="link in power_ball_game" :key="link.title" link router :to="{ name: link.route }" color="primary">
			<img :src="link.icon" class="mr-4" style="width: 24px; height: 24px" />
			<v-list-item-content>
				<v-list-item-title>{{ link.text }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<v-subheader v-if="power_ball_reports.length" class="mt-3"> Power Ball Reports </v-subheader>

		<v-list-item v-for="link in power_ball_reports" :key="link.title" link router :to="{ name: link.route }" color="primary">
			<img :src="link.icon" class="mr-4" style="width: 24px; height: 24px" />
			<v-list-item-content>
				<v-list-item-title>{{ link.text }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<v-subheader v-if="pagcor_reports.length" class="mt-3"> PAGCOR Reports </v-subheader>

		<v-list-item v-for="link in pagcor_reports" :key="link.title" link router :to="{ name: link.route }" color="primary">
			<img :src="link.icon" class="mr-4" style="width: 24px; height: 24px" />

			<v-list-item-content>
				<v-list-item-title>{{ link.text }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<v-subheader v-if="agent_reports.length" class="mt-3"> Agent Reports </v-subheader>

		<v-list-item v-for="link in agent_reports" :key="link.title" link router :to="{ name: link.route }" color="primary">
			<img :src="link.icon" class="mr-4" style="width: 24px; height: 24px" />

			<v-list-item-content>
				<v-list-item-title>{{ link.text }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<v-subheader v-if="agent_monitoring.length" class="mt-3"> Agent Monitoring </v-subheader>

		<v-list-item v-for="link in agent_monitoring" :key="link.title" link router :to="{ name: link.route }" color="primary">
			<img :src="link.icon" class="mr-4" style="width: 24px; height: 24px" />

			<v-list-item-content>
				<v-list-item-title>{{ link.text }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<v-subheader v-if="card_monitoring.length" class="mt-3"> Monitoring </v-subheader>

		<v-list-item v-for="link in card_monitoring" :key="link.title" link router :to="{ name: link.route }" color="primary">
			<img :src="link.icon" class="mr-4" style="width: 24px; height: 24px" />

			<v-list-item-content>
				<v-list-item-title>{{ link.text }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>

		<v-subheader v-if="settings.length" class="mt-3"> Settings </v-subheader>

		<v-list-item v-for="link in settings" :key="link.title" link router :to="{ name: link.route }" color="primary">
			<img :src="link.icon" class="mr-4" style="width: 24px; height: 24px" />

			<v-list-item-content>
				<v-list-item-title>{{ link.text }}</v-list-item-title>
			</v-list-item-content>
		</v-list-item>
	</v-list>
</template>

<script>
import { mapState } from 'vuex'

export default {
	data: () => ({
		dashboard: false,
		investors: [],
		agents: [],
		players: [],
		ending_game: [],
		lucky_four_game: [],
		power_ball_game: [],
		ending_reports: [],
		lucky_four_reports: [],
		power_ball_reports: [],
		pagcor_reports: [],
		agent_reports: [],
		agent_monitoring: [],
		card_monitoring: [],
		settings: [],
	}),
	computed: {
		...mapState(['accountInfo', 'userData']),
		...mapState('cashin', ['cashInPending']),
		...mapState('cashout', ['cashOutPending']),
	},
	mounted() {
		if (this.accountInfo && Object.keys(this.accountInfo).length) {
			if (Object.keys(this.accountInfo.access).length) {
				if (this.accountInfo.access.includes(16)) {
					this.dashboard = true
				}

				/* PLAYERS */
				if (this.accountInfo.access.includes(1)) {
					this.players = [
						...this.players,
						{
							text: 'Player List',
							icon: 'https://img.icons8.com/3d-fluency/94/null/menu.png',
							route: 'PlayerList',
						},
					]
				}

				if (this.accountInfo.access.includes(1)) {
					this.players = [
						...this.players,
						{
							text: 'Player Withdrawal Accounts',
							icon: 'https://img.icons8.com/3d-fluency/94/null/menu.png',
							route: 'PlayersWithdrawalAccounts',
						},
					]
				}

				if (this.accountInfo.access.includes(2)) {
					this.players = [
						...this.players,
						{
							text: 'Cash In',
							icon: 'https://img.icons8.com/3d-fluency/94/null/money-transfer.png',
							route: 'CashIn',
						},
					]
				}

				if (this.accountInfo.access.includes(3)) {
					this.players = [
						...this.players,
						{
							text: 'Cash Out',
							icon: 'https://img.icons8.com/3d-fluency/94/null/money-bag.png',
							route: 'CashOut',
						},
					]
				}

				if (this.accountInfo.access.includes(1)) {
					this.players = [
						...this.players,
						{
							text: 'Restricted Player List',
							icon: 'https://img.icons8.com/3d-fluency/94/null/menu.png',
							route: 'RestrictedPlayerList',
						},
					]
				}

				if (this.accountInfo.access.includes(69)) {
					this.players = [
						...this.players,
						{
							text: 'Deposit Config',
							icon: 'https://img.icons8.com/3d-fluency/94/null/gear--v1.png',
							route: 'DepositConfig',
						},
					]
				}

				if (this.accountInfo.access.includes(83)) {
					this.players = [
						...this.players,
						{
							text: 'Reject Reasons',
							icon: 'https://img.icons8.com/3d-fluency/94/null/menu.png',
							route: 'PlayerRejectReasons',
						},
					]
				}

				/* INVESTORS */

				if (this.accountInfo.access.includes(58)) {
					this.investors = [
						...this.agents,
						{
							text: 'Investor List',
							icon: 'https://img.icons8.com/3d-fluency/94/null/administrator-male.png',
							route: 'Investors',
						},
						{
							text: 'Payouts Settlement',
							icon: 'https://img.icons8.com/3d-fluency/94/null/sales-performance.png',
							route: 'PayoutsSettlement',
						},
						{
							text: 'Commission Settlement',
							icon: 'https://img.icons8.com/3d-fluency/94/null/sales-performance.png',
							route: 'CommissionsSettlement',
						},
						{
							text: 'Master Agent List',
							icon: 'https://img.icons8.com/3d-fluency/94/null/menu.png',
							route: 'MasterAgents',
						},
						{
							text: 'Special Commission Settlement',
							icon: 'https://img.icons8.com/fluency/48/null/cash-in-hand.png',
							route: 'SpecialCommissions',
						},
						{
							text: 'Referral Links',
							icon: 'https://img.icons8.com/fluency/48/share--v2.png',
							route: 'ReferralLinks',
						},
					]
				}

				/* AGENTS */

				if (this.accountInfo.access.includes(41)) {
					this.agents = [
						...this.agents,
						{
							text: 'Agent List',
							icon: 'https://img.icons8.com/3d-fluency/94/null/menu.png',
							route: 'AgentList',
						},
					]
				}

				if (this.accountInfo.access.includes(42)) {
					this.agents = [
						...this.agents,
						{
							text: 'Cash In',
							icon: 'https://img.icons8.com/3d-fluency/94/null/money-transfer.png',
							route: 'AgentCashIn',
						},
					]
				}

				if (this.accountInfo.access.includes(54)) {
					this.agents = [
						...this.agents,
						{
							text: 'Commission Payments',
							icon: 'https://img.icons8.com/fluency/48/null/cash-in-hand.png',
							route: 'AgentCommissionPayments',
						},
					]
				}

				/* Ending */
				if (this.accountInfo.access.includes(17)) {
					this.ending_game = [
						...this.ending_game,
						{
							text: 'Scheduled Games',
							icon: 'https://img.icons8.com/3d-fluency/94/null/tear-off-calendar.png',
							route: 'EndingScheduledGames',
						},
					]
				}

				if (this.accountInfo.access.includes(18)) {
					this.ending_game = [
						...this.ending_game,
						{
							text: 'Teams',
							icon: 'https://img.icons8.com/3d-fluency/94/null/conference.png',
							route: 'EndingTeams',
						},
					]
				}

				if (this.accountInfo.access.includes(19)) {
					this.ending_game = [
						...this.ending_game,
						{
							text: 'Leagues',
							icon: 'https://img.icons8.com/3d-fluency/94/null/trophy.png',
							route: 'EndingLeagues',
						},
					]
				}

				if (this.accountInfo.access.includes(20)) {
					this.ending_game = [
						...this.ending_game,
						{
							text: 'Config',
							icon: 'https://img.icons8.com/3d-fluency/94/null/gear--v1.png',
							route: 'EndingConfig',
						},
					]
				}

				/* LUCKY FOUR */
				if (this.accountInfo.access.includes(59)) {
					this.lucky_four_game = [
						...this.lucky_four_game,
						{
							text: 'Scheduled Games',
							icon: 'https://img.icons8.com/3d-fluency/94/null/tear-off-calendar.png',
							route: 'LuckyFourGames',
						},
					]
				}

				if (this.accountInfo.access.includes(63)) {
					this.lucky_four_game = [
						...this.lucky_four_game,
						{
							text: 'Prizes Settings',
							icon: 'https://img.icons8.com/3d-fluency/94/null/gear--v1.png',
							route: 'LuckyFourPrizesSettings',
						},
					]
				}

				/* POWER BALL */
				if (this.accountInfo.access.includes(70)) {
					this.power_ball_game = [
						...this.power_ball_game,
						{
							text: 'Scheduled Games',
							icon: 'https://img.icons8.com/3d-fluency/94/null/tear-off-calendar.png',
							route: 'PowerBallScheduledGames',
						},
					]
				}

				/* PAGCOR REPORTS */
				if (this.accountInfo.access.includes(13)) {
					this.pagcor_reports = [
						...this.pagcor_reports,
						{
							text: 'Ending Game',
							icon: 'https://img.icons8.com/3d-fluency/94/null/basketball.png',
							route: 'PagcorGameReport',
						},
						{
							text: 'Lucky Four Game',
							icon: 'https://img.icons8.com/3d-fluency/94/null/basketball.png',
							route: 'LuckyFourPagcorGameReport',
						},
					]
				}

				if (this.accountInfo.access.includes(14)) {
					this.pagcor_reports = [
						...this.pagcor_reports,
						{
							text: 'Ending Progress',
							icon: 'https://img.icons8.com/3d-fluency/94/null/positive-dynamic.png',
							route: 'PagcorProgressReport',
						},
						{
							text: 'Lucky Four Progress',
							icon: 'https://img.icons8.com/3d-fluency/94/null/positive-dynamic.png',
							route: 'LuckyFourPagcorProgressReport',
						},
					]
				}

				if (this.accountInfo.access.includes(22)) {
					this.pagcor_reports = [
						...this.pagcor_reports,
						{
							text: 'Transactional',
							icon: 'https://img.icons8.com/3d-fluency/94/null/bill.png',
							route: 'PagcorTransactionalReport',
						},
					]
				}

				/* ENDING REPORTS */
				if (this.accountInfo.access.includes(4)) {
					this.ending_reports = [
						...this.ending_reports,
						{
							text: 'Bets',
							icon: 'https://img.icons8.com/3d-fluency/94/null/receive-cash.png',
							route: 'EndingBets',
						},
					]
				}

				if (this.accountInfo.access.includes(5)) {
					this.ending_reports = [
						...this.ending_reports,
						{
							text: 'Game Card',
							icon: 'https://img.icons8.com/3d-fluency/94/null/documents.png',
							route: 'EndingCards',
						},
					]
				}

				if (this.accountInfo.access.includes(35)) {
					this.ending_reports = [
						...this.ending_reports,
						{
							text: 'Game',
							icon: 'https://img.icons8.com/3d-fluency/94/null/basketball.png',
							route: 'GameReport',
						},
					]
				}

				if (this.accountInfo.access.includes(36)) {
					this.ending_reports = [
						...this.ending_reports,
						{
							text: 'Progress',
							icon: 'https://img.icons8.com/3d-fluency/94/null/positive-dynamic.png',
							route: 'ProgressReport',
						},
					]
				}

				if (this.accountInfo.access.includes(37)) {
					this.ending_reports = [
						...this.ending_reports,
						{
							text: 'Transactional',
							icon: 'https://img.icons8.com/3d-fluency/94/null/bill.png',
							route: 'TransactionalReport',
						},
					]
				}

				if (this.accountInfo.access.includes(46)) {
					this.ending_reports = [
						...this.ending_reports,
						{
							text: 'Cash In',
							icon: 'https://img.icons8.com/3d-fluency/94/null/money-transfer.png',
							route: 'CashInReport',
						},
					]
				}

				if (this.accountInfo.access.includes(47)) {
					this.ending_reports = [
						...this.ending_reports,
						{
							text: 'Cash Out',
							icon: 'https://img.icons8.com/3d-fluency/94/null/money-bag.png',
							route: 'CashOutReport',
						},
					]
				}

				if (this.accountInfo.access.includes(48)) {
					this.ending_reports = [
						...this.ending_reports,
						{
							text: 'Cards',
							icon: 'https://img.icons8.com/3d-fluency/94/null/documents.png',
							route: 'CardsReport',
						},
					]
				}

				if (this.accountInfo.access.includes(49)) {
					this.ending_reports = [
						...this.ending_reports,
						{
							text: 'Payouts',
							icon: 'https://img.icons8.com/3d-fluency/94/null/cash-in-hand.png',
							route: 'PayoutsReport',
						},
					]
				}

				if (this.accountInfo.access.includes(50)) {
					this.ending_reports = [
						...this.ending_reports,
						{
							text: 'Top Winning Combination',
							icon: 'https://img.icons8.com/3d-fluency/94/null/prize.png',
							route: 'TopWinningCombination',
						},
					]
				}

				if (this.accountInfo.access.includes(51)) {
					this.ending_reports = [
						...this.ending_reports,
						{
							text: "Master Agent's Token",
							icon: 'https://img.icons8.com/3d-fluency/94/null/coins.png',
							route: 'MasterAgentsToken',
						},
					]
				}

				if (this.accountInfo.access.includes(67)) {
					this.ending_reports = [
						...this.ending_reports,
						{
							text: 'Players',
							icon: 'https://img.icons8.com/3d-fluency/94/null/bill.png',
							route: 'Players',
						},
					]
				}

				if (this.accountInfo.access.includes(68)) {
					this.ending_reports = [
						...this.ending_reports,
						{
							text: 'League Bets',
							icon: 'https://img.icons8.com/3d-fluency/94/null/receive-cash.png',
							route: 'LeaguesBet',
						},
					]
				}

				/* Lucky Four REPORTS */
				if (this.accountInfo.access.includes(59)) {
					this.lucky_four_reports = [
						...this.lucky_four_reports,
						{
							text: 'Bets',
							icon: 'https://img.icons8.com/3d-fluency/94/null/receive-cash.png',
							route: 'LuckyFourBets',
						},
					]

					if (this.accountInfo.access.includes(35)) {
						this.lucky_four_reports = [
							...this.lucky_four_reports,
							{
								text: 'Game',
								icon: 'https://img.icons8.com/3d-fluency/94/null/basketball.png',
								route: 'LuckyFourGameReport',
							},
						]
					}
				}

				/* POWER BALL REPORTS */
				if (this.accountInfo.access.includes(76)) {
					this.power_ball_reports = [
						...this.power_ball_reports,
						{
							text: 'Bets',
							icon: 'https://img.icons8.com/3d-fluency/94/null/receive-cash.png',
							route: 'PowerBallBets',
						},
					]
				}

				/* AGENT REPORTS */
				if (this.accountInfo.access.includes(45)) {
					this.agent_reports = [
						...this.agent_reports,
						{
							text: 'Bets',
							icon: 'https://img.icons8.com/3d-fluency/94/null/receive-cash.png',
							route: 'AgentBets',
						},
					]
				}

				if (this.accountInfo.access.includes(45)) {
					this.agent_reports = [
						...this.agent_reports,
						{
							text: 'Progress',
							icon: 'https://img.icons8.com/3d-fluency/94/null/positive-dynamic.png',
							route: 'AgentProgress',
						},
					]
				}

				if (this.accountInfo.access.includes(45)) {
					this.agent_reports = [
						...this.agent_reports,
						{
							text: 'Kubrador Progress',
							icon: 'https://img.icons8.com/3d-fluency/94/null/positive-dynamic.png',
							route: 'AgentKubradorProgress',
						},
					]
				}

				if (this.accountInfo.access.includes(45)) {
					this.agent_reports = [
						...this.agent_reports,
						{
							text: 'Commission',
							icon: 'https://img.icons8.com/3d-fluency/94/null/sales-performance.png',
							route: 'AgentCommission',
						},
					]
				}

				/* AGENT MONITORING */
				if (this.accountInfo.access.includes(44)) {
					this.agent_monitoring = [
						...this.agent_monitoring,
						{
							text: 'Agent Kubradors',
							icon: 'https://img.icons8.com/3d-fluency/94/null/administrator-male.png',
							route: 'AgentPlayersMonitoring',
						},
					]
				}

				/* MONITORING */
				if (this.accountInfo.access.includes(43)) {
					this.card_monitoring = [
						...this.card_monitoring,
						{
							text: 'Game Card',
							icon: 'https://img.icons8.com/3d-fluency/94/null/documents.png',
							route: 'GameCardMonitoring',
						},
					]
				}

				/* SETTINGS */
				if (this.accountInfo.access.includes(15)) {
					this.settings = [
						...this.settings,
						{
							text: 'Users',
							icon: 'https://img.icons8.com/3d-fluency/94/null/conference-call--v1.png',
							route: 'Users',
						},
					]
				}

				if (this.accountInfo.access.includes(56)) {
					this.settings = [
						...this.settings,
						{
							text: 'Outlets',
							icon: 'https://img.icons8.com/3d-fluency/94/null/home.png',
							route: 'Outlets',
						},
					]
				}

				if (this.accountInfo.access.includes(57)) {
					this.settings = [
						...this.settings,
						{
							text: 'Advertisements',
							icon: 'https://img.icons8.com/3d-fluency/94/null/pictures-folder.png',
							route: 'AdsImages',
						},
					]
				}

				// if (this.accountInfo.access.includes(57)) {
				//   this.settings = [
				//     ...this.settings,
				//     {
				//       text: "Advisories",
				//       icon: "https://img.icons8.com/3d-fluency/94/null/info.png",
				//       route: "Advisories",
				//     },
				//   ];
				// }

				if (this.accountInfo.access.includes(75)) {
					this.settings = [
						...this.settings,
						{
							text: 'SMS',
							icon: 'https://img.icons8.com/3d-fluency/94/speech-bubble-with-dots.png',
							route: 'Sms',
						},
					]
				}

				if (this.accountInfo.access.includes(79)) {
					this.settings = [
						...this.settings,
						{
							text: 'News and Updates',
							icon: 'https://img.icons8.com/3d-fluency/94/megaphone.png',
							route: 'NewsAndUpdates',
						},
					]
				}

				if (this.accountInfo.id == 13) {
					this.settings = [
						...this.settings,
						{
							text: 'Admin Access',
							icon: 'https://img.icons8.com/3d-fluency/94/key.png',
							route: 'AdminAccess',
						},
					]
				}

				if (this.userData.uid == 76) {
					this.settings = [
						...this.settings,
						{
							text: 'Active Users',
							icon: 'https://img.icons8.com/?size=80&id=FUZiNN6aw2Rb&format=png',
							route: 'ActiveUsers',
						},
					]
				}
			}
		}
	},
}
</script>

<style scoped>
.header-navigation .v-list-item .v-icon {
	transform: translateY(3px) !important;
}
</style>
