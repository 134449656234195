var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Ending Reports")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Transactional")]),_c('v-card',{staticClass:"shadow mb-4"},[_c('v-card-title',{staticClass:"text-h6 py-2 font-weight-regular grey--text text--darken-1"},[_vm._v(" Select Transaction Date ")]),_c('v-divider'),_c('v-card-text',{staticClass:"py-4"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.rules.required],"label":"Transaction Date From","hide-details":"auto","readonly":"","outlined":"","dense":"","clearable":""},on:{"keyup":function($event){_vm.date_from_menu = true},"keydown":function($event){_vm.date_from_menu = false}},model:{value:(_vm.date_from),callback:function ($$v) {_vm.date_from=$$v},expression:"date_from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_from_menu),callback:function ($$v) {_vm.date_from_menu=$$v},expression:"date_from_menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.date_from_menu = false}},model:{value:(_vm.date_from),callback:function ($$v) {_vm.date_from=$$v},expression:"date_from"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.lessThanDate],"label":"Transaction Date To (Optional)","hide-details":"auto","readonly":"","outlined":"","dense":"","clearable":""},on:{"keyup":function($event){_vm.date_to_menu = true},"keydown":function($event){_vm.date_to_menu = false}},model:{value:(_vm.date_to),callback:function ($$v) {_vm.date_to=$$v},expression:"date_to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date_to_menu),callback:function ($$v) {_vm.date_to_menu=$$v},expression:"date_to_menu"}},[_c('v-date-picker',{attrs:{"min":_vm.date_from ? _vm.date_from : ''},on:{"input":function($event){_vm.date_to_menu = false}},model:{value:(_vm.date_to),callback:function ($$v) {_vm.date_to=$$v},expression:"date_to"}})],1)],1)],1),_c('div',{staticClass:"mt-4"},[_c('v-btn',{attrs:{"disabled":!_vm.valid,"type":"submit","color":"primary","outlined":"","loading":_vm.recordsLoading},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1),_c('v-card',{staticClass:"shadow"},[_c('v-card-title',{staticClass:"text-h6 py-2 font-weight-regular grey--text text--darken-1"},[_vm._v(" Transactions "),_c('v-spacer'),_c('div',[(_vm.records.length)?_c('downloadexcel',{staticClass:"d-inline-block",attrs:{"data":_vm.exportExcelData(),"fields":{
            'Date Time': 'date_time',
            'Time of Bet': 'time_of_bet',
            'Settle Time': 'settle_time',
            'Channel Type': 'channel_type',
            'Transaction Type': 'transaction_type',
            'Transaction ID': 'transaction_id',
            'Player ID': 'player_id',
            Name: 'name',
            'Mobile Number': {
              callback: function (value) {
                return ("+63" + (value.mobile_number));
              },
            },
            'Bet Amount': 'amount',
            'Payout Amount': 'payout_amount',
            Brand: 'brand',
            Sport: 'sport',
            'Ticket Status': 'ticket_status',
            'Prematch Live': 'prematch_live',
            'Kiosk Terminal': 'kiosk_terminal',
            Outlet: 'outlet',
            'Game Type': 'game_type',
          },"name":_vm.downloadExcelName(),"type":"csv"}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","small":"","depressed":""}},[_vm._v(" Export CSV ")])],1):_vm._e(),(_vm.records.length)?_c('v-btn',{staticClass:"ms-2 d-inline-block",attrs:{"color":"primary","outlined":"","small":"","depressed":"","disabled":_vm.exportPDFLoading,"loading":_vm.exportPDFLoading},on:{"click":_vm.exportToPDF}},[_vm._v(" Export PDF ")]):_vm._e()],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":5,"loading":_vm.recordsLoading,"loading-text":"Loading, please wait a moment","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.date_time",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatTheDate(item.date_time)))])]}},{key:"item.time_of_bet",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatTheDate(item.time_of_bet)))])]}},{key:"item.mobile_number",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(("+63" + (item.mobile_number))))])]}},{key:"item.settle_time",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatTheDate(item.settle_time)))])]}},{key:"item.amount",fn:function(ref){
          var item = ref.item;
return [(item.amount)?_c('span',[_vm._v(_vm._s(parseInt(item.amount).toLocaleString()))]):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }