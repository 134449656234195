const url = process.env.VUE_APP_API_URL 

export default {
  namespaced: true,

  state: {
    records: [],
    recordsLoading: false,
    saveError: null,
    saveLoading:false,
    record: [],
    recordLoading: false
  },
  mutations: {
    setRecords (state, val) {
      state.records = val
    },

    setRecordsLoading (state, val) {
      state.recordsLoading = val
    },

    setRecord (state, val) {
        state.record = val
    },

    setRecordLoading (state, val) {
        state.recordLoading = val
    },

    saveError(state, val) {
        state.saveError = val
    },

    saveLoading(state, val) {
        state.saveLoading = val
    }
  },

  actions: {
    async getRecords({ commit, rootState }) {
      commit("setRecordsLoading", true)

      try {
        const response = await fetch(`${url}ads_images` , {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()
        
        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setRecords", res.records)
        }
      } catch(error) {
        const errorStatus = error.response.status
        
        if (errorStatus==401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setRecordsLoading", false)
      }
    },

    async getRecord({ commit, rootState }, data) {
        commit("setRecordLoading", true)
  
        try {
          const response = await fetch(`${url}ads_image?${data}` , {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${rootState.userToken}`,
            }
          });
  
          const res = await response.json()
          
          if (!response.ok) {
            throw { response, res }
          } else {
            commit("setRecord", res.record)
          }
        } catch(error) {
          const errorStatus = error.response.status
          
          if (errorStatus==401) {
            commit("setError", "Unauthorized", { root: true })
          } else {
            commit("setRefreshError", "Submitted invalid data", { root: true })
          }
        } finally {
          commit("setRecordLoading", false)
        }
      },

    async saveAdsImage({ commit, rootState }, data) {
        commit("saveLoading", true)
        commit("saveError", null)

        try {
            const response = await fetch(`${url}ads_images/upload` , {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                },
                body: data
            });

            const res = await response.json()
            
            if (!response.ok) {
                throw { response, res }
            } else {
                commit("setRecords", res.records)
            }
        } catch(error) {
            const errorStatus = error.response.status            

            if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
            } else if (errorStatus == 417) {
                commit("saveError", "417")
            } else if (errorStatus == 422) {
                commit("saveError", "422")
            } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
            }
        } finally {
            commit("saveLoading", false)
        }
    },

    async updateAdsImage({ commit, rootState }, data) {
        commit("saveLoading", true)
        commit("saveError", null)

        try {
            const response = await fetch(`${url}ads_images/update` , {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${rootState.userToken}`,
                },
                body: data
            });

            const res = await response.json()
            
            if (!response.ok) {
                throw { response, res }
            } else {
                commit("setRecords", res.records)
            }
        } catch(error) {
            const errorStatus = error.response.status            

            if (errorStatus==401) {
                commit("setError", "Unauthorized", { root: true })
            } else {
                commit("setRefreshError", "Submitted invalid data", { root: true })
            }
        } finally {
            commit("saveLoading", false)
        }
    }
  }
}