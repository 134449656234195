<template>
    <v-dialog v-model="dialog" max-width="500px">
        <v-card :loading="addCardLoading">
            <v-card-title>
                Add Card 

                <v-btn icon absolute right @click="cancelSearch">
                    <v-icon size="large">
                        mdi-close
                    </v-icon>
                </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text class="pa-4">
                <v-form 
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="setAddCard"
                >
                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody class="table-borderless">
                                <tr>
                                    <td style="width: 20%" class="grey--text">Amount </td>
                                    <td>
                                        <span inline class="mb-1 mt-1 overline primary--text">PHP {{ game_data.amount }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 20%" class="grey--text">Bet Type</td>
                                    <td>
                                        <span inline class="mb-1 mt-1 overline primary--text">{{ game_data.bet_type }}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <v-row dense>
                        <v-col>
                            <v-text-field
                                ref="card_count"
                                v-model="card_count"
                                :rules="(addCardStatus) ? [rules.required] : []"
                                label="Card Count"
                                type="number"
                                placeholder="Enter the card count"
                                dense
                                outlined
                                hide-details="auto"
                                class="mt-4"/>
                        </v-col>
                    </v-row>

                    <v-row dense class="mt-3">
                         <v-col cols="12" md="6">
                            <v-btn block depressed color="primary" :disabled="addCardLoading" type="submit">
                                Submit
                            </v-btn>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-btn block depressed :disabled="addCardLoading" @click="cancelSearch">
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import formRules from "@/helpers/formRules";
import { mapActions, mapState } from 'vuex';

export default {
    props: {
        game_data: {
            type: Object
        }
    },

    data: () => ({
        valid: true,
        rules: formRules,
        card_count: '',
        card_data: {},
        addCardStatus: false,
    }),

    created() {
        setTimeout(() => {
          this.$refs.card_count.focus()
        }, 200);
    },

    computed: {
        ...mapState("card_monitoring", ["addCardLoading", "addCardError"]),

        dialog: {
            get: function () {
                return true;
            },
            set: function () {
                this.cancelSearch()
            },
        },
    },

    methods: {
        ...mapActions("card_monitoring", ["addCard"]),

        async setAddCard() {
            if (this.$refs.form.validate()) {
                 await this.addCard(this.setAddCardFormData())

                if (!this.addCardError) {
                    this.addCardStatus = true
                    this.card_data = {
                        "status": 'addCard',
                        "bet_amount_id": this.game_data.bet_amount_id,
                        "card_count": this.card_count
                    }

                    this.$emit("setAddCardCloseModal", this.card_data)
                }
            }
        },

        setAddCardFormData() {
            const formData = new FormData()
            formData.append("game_id", this.game_data.game_id)
            formData.append("bet_amount_id", this.game_data.bet_amount_id)
            formData.append("card_count", this.card_count)

            return formData
        },

        cancelSearch() {
            this.card_data = { "status": 'cancel' }
            this.$emit("setAddCardCloseModal", this.card_data)
        }
    }
}
</script>