<template>
	<div class="d-flex align-center" style="min-height: calc(100vh - 64px)">
		<div class="bs-container" style="margin-top: -64px">
			<div class="bs-row">
				<div class="bs-col-md-4 mx-auto">
					<v-card class="shadow pa-6 pa-md-8">
						<v-img src="@/assets/gamex.png" max-width="150" contain class="mb-4" alt="GameX Sports Logo"></v-img>
						<h2 class="font-weight-bold mb-2">Sign In</h2>
						<p class="grey--text mb-10">Please sign-in to your account and start managing our games.</p>
						<!--<v-form ref="form" @submit.prevent="!isOtpLogin ? loginUsingPassword() : loginUsingOTP()">
							<div class="d-flex flex-column gap">
								<div style="position: relative">
									<v-text-field
										ref="mobile_number"
										v-model="mobile_number"
										:rules="[rules.required, rules.validMobileNumber, mobileNumberError]"
										label="Mobile Number"
										prefix="+63"
										outlined
										dense
										maxlength="10"
										hide-details="auto"></v-text-field>
									<div
										v-if="isOtpLogin"
										class="d-flex align-center pe-3"
										style="margin-top: 9px; position: absolute; top: 0; right: 0; z-index: 2">
										<a
											@click.prevent="requestOTP"
											:style="
												!hasValidMobileNumber || sendOtpLoading || !isAllowedRequestOTP
													? 'pointer-events: none; opacity: 0.5;'
													: ''
											">
											<v-progress-circular
												v-if="sendOtpLoading"
												indeterminate
												:size="14"
												:width="2"
												color="primary"></v-progress-circular>
											<span v-else>Send OTP</span>
											<span v-if="countDown >= 0 && !isAllowedRequestOTP">{{ ` (${countDown})` }}</span>
										</a>
									</div>
								</div>
								<v-text-field
									v-if="!isOtpLogin"
									ref="password"
									v-model="password"
									:rules="[rules.required, passwordError]"
									:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
									:type="showPassword ? 'text' : 'password'"
									label="Password"
									outlined
									dense
									@click:append="showPassword = !showPassword"
									hide-details="auto"></v-text-field>
								<div v-else>
									<v-text-field
										ref="otp"
										v-model="otp"
										:rules="[rules.required, rules.minimum(6), rules.numeric, otpError]"
										label="OTP"
										dense
										placeholder="XXXXXX"
										outlined
										maxlength="6"
										hide-details="auto"></v-text-field>
								</div>
							</div>
							<div class="d-flex justify-space-between mt-3">
								<a href="#" class="primary--text" style="text-decoration: none" @click.prevent="isOtpLogin = !isOtpLogin">
									{{ isOtpLogin ? 'Password Login' : 'OTP Login' }}
								</a>
								<a href="#" class="primary--text" style="text-decoration: none" @click.prevent="forgotPasswordDialog = true">
									Forgot Password?
								</a>
							</div>
							<v-btn depressed block class="position-relative mt-6" color="primary" type="submit" :loading="loading">
								<div class="d-flex align-center" style="position: absolute; top: 0; left: 0; bottom: 0">
									<i class="fas fa-sign-in-alt me-4" style="margin-left: -4px"></i>
								</div>
								Sign In
							</v-btn>
						</v-form>-->
						<v-form ref="form" @submit.prevent="loginUsingOTP()">
							<div class="d-flex flex-column gap">
								<div style="position: relative">
									<v-text-field
										ref="mobile_number"
										v-model="mobile_number"
										:rules="[rules.required, rules.validMobileNumber, mobileNumberError]"
										label="Mobile Number"
										prefix="+63"
										outlined
										dense
										maxlength="10"
										hide-details="auto"></v-text-field>
									<!-- Otp Request Button -->
									<div class="d-flex align-center pe-3" style="margin-top: 9px; position: absolute; top: 0; right: 0; z-index: 2">
										<a
											@click.prevent="requestOTP"
											:style="
												!hasValidMobileNumber || sendOtpLoading || !isAllowedRequestOTP
													? 'pointer-events: none; opacity: 0.5;'
													: ''
											">
											<v-progress-circular
												v-if="sendOtpLoading"
												indeterminate
												:size="14"
												:width="2"
												color="primary"></v-progress-circular>
											<span v-else>Send OTP</span>
											<span v-if="countDown >= 0 && !isAllowedRequestOTP">{{ ` (${countDown})` }}</span>
										</a>
									</div>
								</div>
								<!-- Password Field -->
								<!-- OTP Field -->
								<div>
									<v-text-field
										ref="otp"
										v-model="otp"
										:rules="[rules.required, rules.minimum(6), rules.numeric, otpError]"
										label="OTP"
										dense
										placeholder="XXXXXX"
										outlined
										maxlength="6"
										hide-details="auto"></v-text-field>
								</div>
							</div>
							<v-btn depressed block class="position-relative mt-6" color="primary" type="submit" :loading="loading">
								<div class="d-flex align-center" style="position: absolute; top: 0; left: 0; bottom: 0">
									<i class="fas fa-sign-in-alt me-4" style="margin-left: -4px"></i>
								</div>
								Sign In
							</v-btn>
						</v-form>
					</v-card>
				</div>
			</div>

			<ForgotPassword v-if="forgotPasswordDialog" @setCloseModal="setCloseModal" />

			<Snackbar ref="snackbar" />
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isObjectEmpty } from '@/helpers/form'
import formRules from '@/helpers/formRules'
import ForgotPassword from '@/components/forgot_password/ForgotPassword.vue'
import Snackbar from '@/components/Snackbar.vue'

export default {
	components: {
		ForgotPassword,
		Snackbar,
	},

	data: () => ({
		rules: formRules,
		mobile_number: '',
		mobileNumberErrorText: '',
		mobileNumberErrorDesc: '',
		showPassword: false,
		password: '',
		otp: '',
		otpErrorText: '',
		otpErrorDesc: '',
		passwordErrorText: '',
		forgotPasswordDialog: false,
		isOtpLogin: false,
		countDown: 0,
		// logoPath: require("@/assets/gamex_logo.png")
	}),

	computed: {
		...mapState(['userData', 'accountInfo']),
		...mapState('login', ['error', 'accountError', 'errors', 'loading', 'sendOtpError', 'sendOtpLoading', 'isSuccessResponse']),

		isAllowedRequestOTP() {
			return this.countDown <= 0
		},

		hasValidMobileNumber() {
			return (
				this.mobile_number && this.rules.validMobileNumber(this.mobile_number) === true && this.mobileNumberError(this.mobile_number) === true
			)
		},
	},
	mounted() {
		if (!(localStorage.getItem('mobile_number') === null)) {
			this.mobile_number = localStorage.getItem('mobile_number')
			this.$refs.password.focus()
		} else {
			this.$refs.mobile_number.focus()
		}
	},
	methods: {
		...mapActions('login', ['doLogin', 'otpLogin', 'requestLoginOTP']),

		mobileNumberError(val) {
			if (val == this.mobileNumberErrorText) {
				return this.mobileNumberErrorDesc
			} else {
				return true
			}
		},

		passwordError(val) {
			if (val == this.passwordErrorText) {
				return 'Wrong password.'
			} else {
				return true
			}
		},

		otpError(val) {
			if (val == this.otpErrorText) {
				return this.otpErrorDesc
			} else {
				return true
			}
		},

		async requestOTP() {
			this.clearMobileNumberError()

			const formData = new FormData()
			formData.append('mobile_number', this.mobile_number)

			await this.requestLoginOTP(formData)

			// OTP error
			if (this.sendOtpError) {
				this.mobileNumberErrorText = this.mobile_number
				this.mobileNumberErrorDesc = this.sendOtpError

				this.$refs.mobile_number.validate()
				this.$refs.mobile_number.focus()

				if (this.mobileNumberErrorDesc.includes('30 minutes')) {
					this.countDown = 1800
					await this.countDownTimer()
					this.clearMobileNumberError()
				}
				// OTP success
			} else if (this.isSuccessResponse) {
				this.$refs.snackbar.show('success', 'mdi-check-circle-outline', 'The OTP has been sent to your phone.', null, null)
				this.countDown = 60
				await this.countDownTimer()
			}
		},

		async loginUsingPassword() {
			this.mobileNumberErrorText = ''
			this.mobileNumberErrorDesc = ''
			this.passwordErrorText = ''

			if (this.$refs.form.validate()) {
				if (!this.loading) {
					const formData = new FormData()
					formData.append('mobile_number', this.mobile_number)
					formData.append('password', this.password)

					const res = await this.doLogin(formData)

					if (!this.error && !this.accountError && isObjectEmpty(this.errors)) {
						if (this.accountInfo.login_verified) {
							/* if (this.userData.uid==12) {
                this.$router.push({ name: "GameReport" })
              } else {
                this.$router.push({ name: "Dashboard" })
              } */
							this.$router.push({ name: res })
						} else {
							this.$router.push({ name: 'LoginVerification' })
						}
					} else {
						if (this.accountError) {
							if (this.accountError == 'Account is not active.') {
								this.mobileNumberErrorText = this.mobile_number
								this.mobileNumberErrorDesc = this.accountError
								this.$refs.mobile_number.validate()
								this.$refs.mobile_number.focus()
							} else {
								this.passwordErrorText = this.password
								this.$refs.password.validate()
								this.$refs.password.focus()
							}
						}

						if (!isObjectEmpty(this.errors)) {
							let errorFocus = ''

							Object.entries(this.errors).forEach(([key, value]) => {
								if (value) {
									if (!errorFocus) {
										errorFocus = key
									}

									if (key == 'mobile_number') {
										this.mobileNumberErrorText = this.mobile_number
										this.mobileNumberErrorDesc = 'This mobile number is not registered.'
									}
								}
							})

							this.$refs.form.validate()

							this.$refs[errorFocus].focus()
						}
						/* if (this.error) {
              // pop up take over
            } else {
              let errorFocus = ''

              Object.entries(this.errors).forEach(([key, value]) => {
                if (value) {
                  if (!errorFocus) {
                    errorFocus = key
                  }
                  
                  if (key=='mobile_number') {
                    this.mobileNumberErrorText = this.mobile_number
                  }
                }
              })

              this.$refs.form.validate()

              this.$refs[errorFocus].focus()
            } */
					}
				}
			}
		},

		async loginUsingOTP() {
			this.clearMobileNumberError()
			this.passwordErrorText = ''

			if (this.$refs.form.validate()) {
				const formData = new FormData()
				formData.append('mobile_number', this.mobile_number)
				formData.append('otp', this.otp)

				const res = await this.otpLogin(formData)

				// Login error
				if (this.error) {
					if (this.error.includes('active')) {
						this.mobileNumberErrorText = this.mobile_number
						this.mobileNumberErrorDesc = this.error
						this.$refs.mobile_number.validate()
						this.$refs.mobile_number.focus()
					} else if (this.error.includes('OTP')) {
						this.otpErrorText = this.otp
						this.otpErrorDesc = this.error
						this.$refs.otp.validate()
						this.$refs.otp.focus()
					}
				} else if (!isObjectEmpty(this.errors)) {
					if (this.errors.mobile_number.length) {
						this.mobileNumberErrorText = this.mobile_number
						this.mobileNumberErrorDesc = this.errors.mobile_number
						this.$refs.mobile_number.validate()
						this.$refs.mobile_number.focus()
					}

					if (this.errors.otp.length) {
						this.otpErrorText = this.otp
						this.otpErrorDesc = this.errors.otp
						this.$refs.otp.validate()
						this.$refs.otp.focus()
					}
				} else if (this.isSuccessResponse) {
					// Login Success
					if (this.accountInfo.login_verified) {
						this.$router.push({ name: res })
					} else {
						this.$router.push({ name: 'LoginVerification' })
					}
				}
			}
		},

		setCloseModal() {
			this.forgotPasswordDialog = false
		},

		clearMobileNumberError() {
			this.mobileNumberErrorText = ''
			this.mobileNumberErrorDesc = ''
		},

		countDownTimer() {
			return new Promise((resolve) => {
				const countdownInterval = setInterval(() => {
					if (this.countDown > 0) {
						--this.countDown
					} else {
						clearInterval(countdownInterval)
						resolve()
					}
				}, 1000)
			})
		},
	},
	watch: {
		mobile_number(val) {
			this.mobile_number = val.replace(/^0+/, '')
		},

		isOtpLogin(val) {
			if (!val) {
				if (this.mobile_number) {
					setTimeout(() => {
						this.$refs.password.focus()
					}, 100)
				} else {
					this.$refs.mobile_number.focus()
				}
			} else {
				this.$refs.mobile_number.focus()
			}
		},
	},
}
</script>
