<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card>
      <v-card-title class="text-h5"> {{ titleText }} </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="py-4">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="handleSubmit"
        >
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                label="Name"
                ref="full_name"
                outlined
                dense
                :rules="[rules.required, rules.minimum(3)]"
                v-model="full_name"
                hide-details="auto"
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                label="Gender"
                ref="gender"
                outlined
                dense
                :items="['Male', 'Female']"
                :rules="[rules.required]"
                v-model="gender"
                hide-details="auto"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <div class="mt-4 text-right">
                <v-btn
                  color="black"
                  outlined
                  class="me-2"
                  @click.stop="closeModal"
                >
                  Cancel
                </v-btn>

                <v-btn
                  type="submit"
                  color="primary"
                  outlined
                  :loading="createLoading"
                >
                  {{ buttonText }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";

export default {
  props: {
    playerData: {
      type: Object,
    },
  },
  data: () => ({
    full_name: "",
    gender: "Male",
    valid: true,
    rules: formRules,
    id: 0,
    titleText: "Create Restricted Player",
    buttonText: "Create",
    updatedData: [],
    insertedData: [],
  }),
  mounted() {
    if (Object.keys(this.playerData).length != 0) {
      this.id = this.playerData.id;
      this.full_name = this.playerData.full_name;
      this.gender = this.playerData.gender;
      this.buttonText = "Update";
      this.titleText = "Update Restricted Player";
    }
    setTimeout(() => {
      this.$refs.full_name.focus();
    }, 300);
  },
  computed: {
    ...mapState("restricted_players", [
      "createLoading",
      "restrictedPlayerId",
      "createError",
      "createErrors",
    ]),
    dialog: {
      get: function () {
        return true;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
  },
  methods: {
    ...mapActions("restricted_players", ["create", "update"]),
    closeModal() {
      this.$emit("closeModal", this.updatedData, this.insertedData);
    },
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        if (!this.createLoading) {
          if (Object.keys(this.playerData).length != 0) {
            await this.update(this.setFormData());
            if (!this.createError && this.isObjectEmpty(this.createErrors)) {
              this.closeModal();
              this.$refs.form.reset();
            } else {
              alert("Error occurred, Please refresh the page");
            }
          } else {
            await this.create(this.setFormData());
            if (!this.createError && this.isObjectEmpty(this.createErrors)) {
              this.setInsertedData();
              this.closeModal();
              this.$refs.form.reset();
            } else {
              alert("Error occurred, Please refresh the page");
            }
          }
        }
      }
    },
    setInsertedData() {
      var today = new Date();
      this.insertedData = {
        restricted_player_id: this.restrictedPlayerId,
        full_name: this.full_name,
        gender: this.gender,
        created_at: today,
      };
    },
    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    setFormData() {
      const formData = new FormData();
      if (Object.keys(this.playerData).length != 0) {
        formData.append("restricted_player_id", this.id);
        this.updatedData = {
          id: this.id,
          full_name: this.full_name,
          gender: this.gender,
        };
      }
      formData.append("full_name", this.full_name);
      formData.append("gender", this.gender);
      return formData;
    },
  },
};
</script>