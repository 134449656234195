<template>
  <div class="pa-4 pa-md-6">
    <v-row>
      <v-col cols="12">
        <v-card :loading="detailsLoading">
          <v-card-text class="pb-0">
            <div class="text-center title">
              {{ details.league }}

              <v-btn
                small
                color="secondary"
                @click="$router.go(-1)"
                absolute
                right
                class="pe-2 d-none d-sm-inline-block"
              >
                <v-icon left> mdi-keyboard-backspace </v-icon>
                Back
              </v-btn>

              <v-btn
                icon
                color="secondary"
                @click="$router.go(-1)"
                absolute
                right
                class="d-inline-block d-sm-none"
              >
                <v-icon size="large"> mdi-keyboard-backspace </v-icon>
              </v-btn>
            </div>
          </v-card-text>

          <v-card-title>
            <v-row justify="center" align="center">
              <v-col cols="auto" class="text-center" align-self="center">
                <v-avatar size="100px" tile>
                  <v-img :src="details.team_a_logo">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <div>
                  <span class="text-caption"
                    >{{ details.team_a_name }} {{ details.team_a_alias }}</span
                  >
                </div>
              </v-col>

              <v-col cols="auto" class="text-center">
                <span class="text-overline my-2 mx-2 font-italic">vs</span>
              </v-col>

              <v-col cols="auto" class="text-center">
                <v-avatar size="100px" tile>
                  <v-img :src="details.team_b_logo">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <div>
                  <span class="text-caption"
                    >{{ details.team_b_name }} {{ details.team_b_alias }}</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <p class="mb-1 overline primary--text">Game Details</p>

                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody class="table-borderless">
                      <tr>
                        <td style="width: 30%" class="grey--text">Schedule</td>

                        <td>
                          <span>{{ formattedSchedule }}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="grey--text">Status</td>

                        <td>
                          <span>{{ details.status || "-" }}</span>
                        </td>
                      </tr>

                      <tr>
                        <td class="grey--text">Created At</td>

                        <td>
                          <span>{{ formattedCreatedAt }}</span>
                        </td>
                      </tr>

                      <tr>
                        <td style="width: 30%" class="grey--text">Bet Count</td>
                        <td>
                          {{ parseInt(details.bet_count) | numberWithCommas }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <p
                  class="mb-1 mt-3 overline primary--text"
                  v-if="accountInfo.access.includes(60)"
                >
                  Settings
                </p>

                <v-simple-table dense v-if="accountInfo.access.includes(60)">
                  <template v-slot:default>
                    <tbody class="table-borderless">
                      <tr>
                        <td style="width: 30%" class="grey--text">
                          Lucky Four
                        </td>

                        <td>
                          <EnableLuckyFourBtn
                            v-if="details"
                            :compData="{
                              id: id,
                              is_lucky_four: details.is_lucky_four,
                            }"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>

              <!-- <v-col cols="12" md="4">
                <p
                  class="mb-1 mt-0 md-mt-3 overline primary--text"
                  v-if="accountInfo.access.includes(59)"
                >
                  PDF Generation
                </p>

                <v-simple-table dense v-if="accountInfo.access.includes(59)">
                  <template v-slot:default>
                    <tbody class="table-borderless">
                      <tr>
                        <td style="width: 40%" class="grey--text">Action</td>

                        <td>
                          <v-btn
                            outlined
                            color="primary"
                            small
                            @click="generatePdf"
                            :loading="generatePdfLoading"
                            :disabled="disableGenerate"
                          >
                            <v-icon left dark>
                              mdi-folder-upload-outline
                            </v-icon>
                            Generate
                          </v-btn>
                        </td>
                      </tr>

                      <tr>
                        <td></td>
                        <td></td>
                      </tr>

                      <tr>
                        <td class="grey--text">Email Generated Files</td>

                        <td>
                          <v-btn
                            outlined
                            color="primary"
                            small
                            @click="sendGeneratePdf"
                            :loading="sendGeneratePdfLoading"
                            :disabled="!disableGenerate"
                          >
                            <v-icon left dark> mdi-email-outline </v-icon>

                            <span v-if="!emailSent">Send Email</span>
                            <span v-else>Sent</span>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col> -->

              <v-col cols="12" md="4" v-if="accountInfo.access.includes(59)">
                <v-row>
                  <v-col>
                    <p class="mb-1 mt-0 md-mt-3 overline primary--text">
                      Result
                    </p>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="2" class="text-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="secondary"
                          v-bind="attrs"
                          v-on="on"
                          class="mr-1"
                          @click="getDetails(id)"
                        >
                          <v-icon>mdi-cached</v-icon>
                        </v-btn>
                      </template>
                      <span>Refresh</span>
                    </v-tooltip>
                  </v-col>
                </v-row>

                <v-simple-table dense class="table-bordered">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-uppercase text-center">Quarter</th>
                        <th class="text-uppercase text-center">Result</th>
                      </tr>
                    </thead>
                    <tbody v-if="details.results && details.results.length">
                      <tr
                        v-for="item in details.results"
                        :key="item.quarter_id"
                      >
                        <td class="text-center">
                          {{ item.quarter }}
                        </td>
                        <td class="text-center">
                          {{ item.result }}
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td
                          class="text-center text-caption grey--text"
                          colspan="5"
                        >
                          No result yet
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mt-6" v-if="accountInfo.access.includes(11)">
          <v-card-text>
            <v-row>
              <v-col>
                <p class="ma-0 overline primary--text">Prizes</p>
              </v-col>
            </v-row>
            <v-simple-table dense class="table-bordered">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-uppercase text-center">Action</th>
                    <th class="text-uppercase text-center">Initial Amount</th>
                    <th class="text-uppercase text-center">Bet Amount</th>
                    <th class="text-uppercase text-center">Percentage</th>
                    <th class="text-uppercase text-center">Amount Added</th>
                    <th class="text-uppercase text-center">
                      Three Digit Prize
                    </th>
                    <th class="text-uppercase text-center">Jackpot Prize</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :disabled="disableRefreshJP"
                            icon
                            small
                            color="primary"
                            @click="confirmDialog = true"
                          >
                            <v-icon size="large">
                              mdi-database-refresh-outline
                            </v-icon>
                          </v-btn>
                        </template>

                        <span>Refresh Jackpot Prize</span>
                      </v-tooltip>
                    </td>
                    <td class="text-center">
                      ₱{{ moneyFormat(parseFloat(details.initial_amount)) }}
                    </td>
                    <td class="text-center">
                      ₱{{ moneyFormat(parseFloat(details.bet_amount)) }}
                    </td>
                    <td class="text-center">
                      {{
                        moneyFormat(parseFloat(details.percentage_deduction))
                      }}
                    </td>
                    <td class="text-center">
                      ₱{{ moneyFormat(parseFloat(details.amount_added)) }}
                    </td>
                    <td class="text-center">
                      ₱{{ moneyFormat(parseFloat(details.three_digits_prize)) }}
                    </td>
                    <td class="text-center">
                      ₱{{ moneyFormat(parseFloat(details.jackpot_prize)) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>

        <Winners
          class="mt-6"
          v-if="
            $store.state.accountInfo.access.includes(61) ||
            $store.state.accountInfo.access.includes(62)
          "
        />
      </v-col>
    </v-row>

    <v-dialog v-model="confirmDialog" max-width="360">
      <v-card class="py-2">
        <v-card-title class="text-h6 d-block text-center">
          Refresh Jackpot Prize
        </v-card-title>
        <v-card-text class="text-center">
          Are you sure you want to refresh jackpot prize? This action cannot be
          undone.
        </v-card-text>

        <v-card-actions class="d-block text-center">
          <v-btn
            ref="yes"
            color="primary"
            outlined
            small
            @click="refreshJackpotPrize()"
            :loading="refreshJackpotPrizeLoading"
          >
            Yes
          </v-btn>

          <v-btn color="grey" outlined small @click="confirmDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import formatDate from "@/helpers/formatDate";
import EnableLuckyFourBtn from "@/components/lucky_four/game_details/EnableLuckyFourBtn";
import Winners from "@/components/lucky_four/Winners";

export default {
  components: {
    EnableLuckyFourBtn,
    Winners,
  },

  props: {
    id: {
      required: true,
    },
  },

  data: () => ({
    confirmDialog: false,
    emailSent: 0,
    disableGenerate: false,
  }),

  created() {
    if (!this.detailsLoading) {
      this.$store.commit("lucky_four_game_details/setDetails", []);
      this.getDetails(this.id);
    }

    if (
      this.accountInfo.access.includes(61) ||
      this.accountInfo.access.includes(62)
    ) {
      this.loadGameWinners();
    }
  },

  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("lucky_four_game_details", [
      "details",
      "detailsLoading",
      "refreshJackpotPrizeLoading",
      "luckyFourPrizes",
      "generatePdfLoading",
      "sendGeneratePdfLoading",
    ]),

    formattedSchedule() {
      return formatDate(this.details.schedule, true);
    },

    formattedCreatedAt() {
      return formatDate(this.details.created_at, true);
    },

    disableRefreshJP() {
      if (this.refreshJackpotPrizeLoading || this.details.game_status_id) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions("lucky_four_game_details", [
      "getDetails",
      "getGameWinners",
      "doRefreshJackpotPrize",
      "generateBetsPdf",
      "sendGenerateBetsPdf",
    ]),

    loadGameWinners() {
      if (!this.gameWinnersLoading) {
        this.$store.commit("lucky_four_game_details/setGameWinners", []);
        this.getGameWinners(this.id);
      }
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    generatePdf() {
      const text = "Are you sure you want to generate?";
      if (confirm(text) == true) {
        this.doGeneratePdf();
      }
    },

    async doGeneratePdf() {
      const formData = new FormData();
      formData.append("game_id", this.id);

      if (!this.generatePdfLoading) {
        await this.generateBetsPdf(formData);
        if (!this.generatePdfError) {
          this.disableGenerate = true;
        }
      }
    },

    sendGeneratePdf() {
      const text = "Are you sure you want to send email?";
      if (confirm(text) == true) {
        this.doSendGeneratePdf();
      }
    },

    async doSendGeneratePdf() {
      const formData = new FormData();
      formData.append("game_id", this.id);

      if (!this.sendGeneratePdfLoading) {
        await this.sendGenerateBetsPdf(formData);

        if (!this.sendGeneratePdfError) {
          this.emailSent = 1;

          setTimeout(() => {
            this.emailSent = 0;
          }, 1000);
        }
      }
    },

    async refreshJackpotPrize() {
      this.confirmDialog = false;
      const formData = new FormData();
      formData.append("game_id", this.id);
      await this.doRefreshJackpotPrize(formData);

      if (Object.keys(this.luckyFourPrizes).length) {
        this.details.amount_added = this.luckyFourPrizes.amount_added;
        this.details.jackpot_prize = this.luckyFourPrizes.jackpot_prize;
      }
    },
  },

  filters: {
    numberWithCommas(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>