const url = process.env.VUE_APP_API_URL + "lucky_four/games"

export default {
  namespaced: true,

  state: {
    records: [],
    recordsLoading: false,
    totalPage: 0,
    totalRows: 0,
    currentPage: 1,
  },

  getters: {
    getRecords: state => state.records,
    getRecordsLoading: state => state.recordsLoading,
    getTotalPage: state => state.totalPage,
    getTotalRows: state => state.totalRows,
    getCurrentPage: state => state.currentPage,
  },

  mutations: {
    setRecords(state, val) {
      state.records = val
    },
    setRecordsLoading(state, val) {
      state.recordsLoading = val
    },
    setTotalPage(state, val) {
      state.totalPage = val
    },
    setTotalRows(state, val) {
      state.totalRows = val
    },
    setCurrentPage(state, val) {
      state.currentPage = val
    },
  },

  actions: {
    async fetchRecords({ commit, rootState }, data) {
      commit("setRecordsLoading", true)

      try {
        const response = await fetch(`${url}?${data}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${rootState.userToken}`,
          }
        });

        const res = await response.json()

        if (!response.ok) {
          throw { response, res }
        } else {
          commit("setRecords", res.records)
          commit("setTotalPage", res.totalPage)
          commit("setTotalRows", res.totalRows)
        }
      } catch (error) {
        const errorStatus = error.response.status

        if (errorStatus == 401) {
          commit("setError", "Unauthorized", { root: true })
        } else {
          commit("setRefreshError", "Submitted invalid data", { root: true })
        }
      } finally {
        commit("setRecordsLoading", false)
      }
    },
  }
}