<template>
	<div>
		<v-navigation-drawer v-model="drawer" app class="bs-shadow">
			<v-list class="pt-2 pb-1">
				<v-list-item class="ma-0">
					<v-list-item-content>
						<v-img src="@/assets/gamex.png" max-width="150" contain alt="GameX Sports Logo" style="margin: 0 auto"></v-img>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<NavbarLinks />
		</v-navigation-drawer>

		<v-app-bar
			class="shadow px-md-0"
			app
			style="
				background-color: rgb(0, 182, 254) !important;
				height: 64px !important;
				display: flex !important;
				align-items: center !important;
				z-index: 4;
			">
			<v-btn
				icon
				class="grey lighten-5"
				color="black"
				style="position: relative; width: 36px !important; height: 36px !important; margin-left: unset !important"
				@click="drawer = !drawer">
				<transition name="rotate">
					<div v-if="drawer" class="d-flex align-center justify-center" style="position: absolute; top: 0; right: 0; bottom: 0; left: 0">
						<v-icon> mdi-close </v-icon>
					</div>
				</transition>
				<transition name="rotate">
					<div v-if="!drawer" class="d-flex align-center justify-center" style="position: absolute; top: 0; right: 0; bottom: 0; left: 0">
						<v-icon> mdi-menu </v-icon>
					</div>
				</transition>
			</v-btn>

			<v-spacer></v-spacer>

			<div class="bs-d-flex align-center bs-gap-3">
				<Notifications />

				<v-menu offset-y left transition="slide-y-transition">
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" text>
							{{ userData ? userData.first_name : '' }}
							<v-icon size="22" right>mdi-chevron-down</v-icon>
						</v-btn>
					</template>
					<v-list>
						<!--<v-list-item link @click="changePassword">
              <v-list-item-title> Change Password </v-list-item-title>
            </v-list-item>-->
						<!--<v-divider></v-divider>-->
						<v-list-item link @click="logout"> Logout </v-list-item>
					</v-list>
				</v-menu>
			</div>
		</v-app-bar>

		<!-- Error -->
		<v-dialog v-model="unauthorizedModal" persistent max-width="500">
			<v-card>
				<v-card-title class="text-h5">
					<span class="red--text text--darken-1">Warning</span>
				</v-card-title>
				<v-card-text>You are not authorized, you will be logged out.</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="logout"> Okay </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Refresh Errror -->
		<v-dialog v-model="refreshErrorModal" persistent max-width="500">
			<v-card>
				<v-card-title class="text-h5">
					<span class="red--text text--darken-1">Warning</span>
				</v-card-title>
				<v-card-text>{{ refreshError }}</v-card-text>
				<v-card-text>This page will be refreshed.</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="refreshPage"> Okay </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Overlay -->
		<v-overlay :value="overlay" opacity="1">
			<v-row justify="center" align="center">
				<v-col cols="auto" class="mr-4"> Logging out, please wait... </v-col>

				<v-col cols="auto">
					<v-progress-circular indeterminate color="white" />
				</v-col>
			</v-row>
		</v-overlay>
	</div>
</template>

<script>
import NavbarLinks from '@/components/NavbarLinks'
import Notifications from '@/components/Notifications'
import { mapState, mapActions } from 'vuex'

export default {
	components: { NavbarLinks, Notifications },
	data: () => ({
		drawer: null,
		unauthorizedModal: false,
		refreshErrorModal: false,

		overlay: false,
	}),
	computed: {
		...mapState(['userData', 'error', 'refreshError']),
		...mapState('logout', ['isLoading']),
	},
	methods: {
		...mapActions('logout', ['sendLogoutRequest']),
		changePassword() {
			this.$router.push({ name: 'ChangePassword' })
		},
		async logout() {
			if (!this.isLoading) {
				this.drawer = false
				this.overlay = true
				await this.sendLogoutRequest()
				this.$router.push({ name: 'Home' })
			}
		},
		refreshPage() {
			location.reload()
		},
	},
	watch: {
		error(val) {
			if (val) {
				this.unauthorizedModal = true
			}
		},
		refreshError(val) {
			if (val) {
				this.refreshErrorModal = true
			}
		},
	},
}
</script>
