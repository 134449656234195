var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":!_vm.$vuetify.breakpoint.mdAndUp,"persistent":"","scrollable":"","max-width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"bs-shadow"},[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between"},[_c('span',[_vm._v("Commission Details")]),_c('v-btn',{attrs:{"icon":"","disabled":_vm.records_loading},on:{"click":_vm.close_dialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"search":_vm.search,"items-per-page":-1,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"hide-default-footer":true,"single-select":false,"item-key":"reference","show-select":"","loading":_vm.records_loading,"loading-text":"Loading, please wait a moment"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{ref:"search",attrs:{"label":"Search","single-line":"","outlined":"","dense":"","disabled":_vm.records_loading,"autofocus":"","placeholder":"Search","hide-details":"auto"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(("Php " + (_vm.money_format(parseFloat(item.amount))))))])]}},{key:"item.commission_percent",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((_vm.number_format(parseInt(item.commission_percent))) + "%")))])]}},{key:"item.commission_amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(("Php " + (_vm.money_format(parseFloat(item.commission_amount))))))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.format_date(item.created_at)))])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions',[_c('div',{staticClass:"d-flex align-center justify-space-between gap",staticStyle:{"width":"100%"}},[_c('span',[_vm._v(" "+_vm._s(_vm.number_format(parseInt(_vm.selected.length)))+" Selected "+_vm._s((" out of " + (_vm.records.length) + " amounting of Php " + _vm.total_commission))+" ")]),_c('div',{staticClass:"d-flex gap-sm"},[_c('v-btn',{attrs:{"text":"","depressed":"","color":"grey","disabled":_vm.records_loading},on:{"click":_vm.close_dialog}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"depressed":"","color":"primary","disabled":_vm.records_loading || !_vm.selected.length},on:{"click":_vm.set_commission_details_dialog}},[_vm._v(" Submit ")])],1)])])],1),(_vm.commission_details_confirmation_dialog)?_c('CommissionDetailsConfirmation',{attrs:{"commission_details_confirmation_data":_vm.commission_details_confirmation_data},on:{"set_close_commission_details_dialog":_vm.set_close_commission_details_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }