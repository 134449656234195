<template>
  <div style="height: 100%">
    <v-card class="shadow" style="height: 100%">
      <div class="d-flex justify-space-between align-center bs-py-3 bs-px-4">
        <h3>Monthly Bets</h3>
        <v-btn
          text
          class="text-uppercase px-4"
          @click="filterDialog = true"
          :disabled="betsMonthlyStatisticsLoading"
        >
          {{ betsMonthlyStatistics.label }}
        </v-btn>
      </div>

      <v-divider></v-divider>

      <div class="d-flex align-center justify-center pa-6">
        <div v-if="betsMonthlyStatisticsLoading" class="text-center" style="height: 100%">
          <div class="d-flex align-center" style="gap: 1rem">
            <v-progress-circular
              :size="20"
              :width="3"
              color="secondary"
              indeterminate
            ></v-progress-circular>
            <span>Fetching chart information</span>
          </div>
        </div>
        <div style="width: 100%" v-else>
          <LineChart :theChartData="betsMonthlyStatistics" style="margin-top: -15px" />
        </div>
      </div>

      <div v-if="!betsMonthlyStatisticsLoading">
        <v-divider></v-divider>

        <div class="pa-6">
          <v-row class="text-center">
            <v-col md="4">
              <div class="d-flex justify-center" style="gap: 1rem">
                <div>
                  <div class="mb-2" style="margin-top: 3px; font-size: 13px">Average</div>
                  <h3 style="color: #00B6FE">
                    {{
                      betsMonthlyStatistics.bet_monthly_average
                        ? `${moneyFormat(
                            parseInt(betsMonthlyStatistics.bet_monthly_average)
                          )}`
                        : "0"
                    }}
                  </h3>
                </div>
              </div>
            </v-col>
            <v-col md="4">
              <div class="d-flex justify-center" style="gap: 1rem">
                <div>
                  <div class="mb-2" style="margin-top: 3px; font-size: 13px">Lowest</div>
                  <h3 style="color: #00B6FE">
                    {{
                      betsMonthlyStatistics.bet_monthly_average && lowest
                        ? `${moneyFormat(parseFloat(lowest))}`
                        : "0"
                    }}
                  </h3>
                </div>
              </div>
            </v-col>
            <v-col md="4">
              <div class="d-flex justify-center" style="gap: 1rem">
                <div>
                  <div class="mb-2" style="margin-top: 3px; font-size: 13px">Highest</div>
                  <h3 style="color: #00B6FE">
                    {{
                      betsMonthlyStatistics.bet_monthly_average && highest
                        ? `${moneyFormat(parseFloat(highest))}`
                        : "0"
                    }}
                  </h3>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card>

    <v-dialog ref="filterDialog" v-model="filterDialog" persistent width="290px">
      <v-date-picker
        v-model="year"
        :active-picker.sync="activePicker"
        full-width
        reactive
        no-title
        @click:year="saveFilter"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cancelFilter"> Cancel </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formatDatev2 from "@/helpers/formatDatev2";
import LineChart from "@/components/dashboard/bets_statistics/BetsMonthlyLineChart.vue";

export default {
  components: { LineChart },
  data: () => ({
    year: formatDatev2(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      "format",
      "yyyy"
    ),
    filterDialog: false,
    activePicker: null,
  }),
  props: {
    game_id: Number,
  },
  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("dashboard", ["betsMonthlyStatistics", "betsMonthlyStatisticsLoading"]),

    lowest() {
      const arr = Object.values(this.betsMonthlyStatistics.bet_statistics);
      const filtered_array = arr.filter((val) => val != 0);

      if (filtered_array.length != 0) return Math.min(...filtered_array);

      return 0;
    },

    highest() {
      const arr = Object.values(this.betsMonthlyStatistics.bet_statistics);
      return Math.max(...arr);
    },

    lowestPayout() {
      const arr = Object.values(this.cashinMonthly.payouts_statistics);
      const filtered_array = arr.filter((val) => val != 0);

      if (filtered_array.length != 0) return Math.min(...filtered_array);

      return 0;
    },

    highestPayout() {
      const arr = Object.values(this.cashinMonthly.payouts_statistics);
      return Math.max(...arr);
    },
  },
  mounted() {
    if (this.accountInfo.access.includes(21)) {
      this.doGetData();
    }
  },
  methods: {
    ...mapActions("dashboard", ["getBetsMonthylStatistics"]),
    cancelFilter() {
      this.filterDialog = false;
    },
    saveFilter() {
      const expYear = this.year.split("-");
      this.year = expYear[0];
      this.$refs.filterDialog.save(this.year);
      this.doGetData();
    },
    async doGetData() {
      if (!this.cashinMonthlyLoading) {
        this.$store.commit("dashboard/setBetsMonthlyStatistics", []);

        const formData = new FormData();
        formData.append("val", this.year);
        formData.append("type", "Monthly");
        formData.append("game_id", this.game_id);

        const getData = new URLSearchParams(formData);

        await this.getBetsMonthylStatistics(getData);
      }
    },
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
  },

  watch: {
    filterDialog(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    game_id() {
      if (this.accountInfo.access.includes(21)) {
        this.doGetData();
      }
    },
  },
};
</script>
