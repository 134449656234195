<template>
  <v-dialog persistent v-model="dialog" max-width="400">
    <v-card>
      <v-card-title class="text-h6 font-weight-regular">
        <span>Confirm</span>
      </v-card-title>

      <v-card-text class="d-flex flex-column" style="gap:1rem">
        <v-alert v-if="alert_dialog" dense class="mb-0"
          :class="verify_status ? 'green lighten-5 green--text' : 'red lighten-5 red--text'">
          {{ alert_message }}
        </v-alert>

        <span>You're about to process this request, do really want proceed?</span>
      </v-card-text>

      <v-card-actions>
        <div class="d-flex justify-end" style="width:100%;gap:.30rem">
          <v-btn depressed class="px-2 grey lighten-5" :disabled="verify_loading || verify_status" @click="close_dialog">
            No
          </v-btn>

          <v-btn depressed color="primary" class="px-2" :loading="verify_loading" :disabled="verify_status"
            @click="doVerify">
            Yes
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import responsePost from '@/helpers/api_request_post'
import { mapActions } from "vuex"

export default {
  props: {
    id: {
      type: Number
    }
  },

  data: () => ({
    verify_status: false,
    verify_loading: false,
    alert_dialog: false,
    alert_message: ''
  }),

  computed: {
    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    }
  },

  methods: {
    ...mapActions("logout", ["sendLogoutRequest"]),

    async doVerify() {
      this.verify_loading = !this.verify_loading

      const response = await responsePost(this.set_form_data(), 'investor/special_commission/verify')
      this.alert_dialog = true

      switch (response.status) {
        case 200:
          this.verify_status = true
          this.alert_message = 'Successfully verified'
          break
        case 409:
          this.alert_message = 'Something went wrong'
          break
        case 401:
          await this.sendLogoutRequest();
          this.$router.push({ name: "Home" });
          break
      }

      this.verify_loading = !this.verify_loading
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('special_settlement_id', this.id)

      return form_data
    },

    close_dialog() {
      this.$emit("set_close_confirmation_dialog", false)
    }
  },

  watch: {
    verify_status(val) {
      if (val) {
        setTimeout(() => {
          this.$emit("set_close_confirmation_dialog", true)
        }, 2000);
      }
    }
  }
}
</script>