<template>
  <div class="d-inline-block">
    <v-btn
      small
      color="primary"
      outlined
      @click.stop="dialog = true"
      :disabled="!availableQuarter.length"
    >
      Create Result
    </v-btn>

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title class="text-h6"> Create Result </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-4">
          <v-form
            v-if="availableQuarter.length"
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleSubmit"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  ref="teamascore"
                  v-model="teamascore"
                  :rules="[rules.required]"
                  :label="
                    gameData.team_a_name +
                    ' ' +
                    availableQuarter[0].text +
                    ' ' +
                    (parseInt(availableQuarter[0].value) < 5 ? 'Quarter' : '') +
                    ' Score'
                  "
                  outlined
                  hide-details="auto"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="teambscore"
                  :rules="[rules.required]"
                  :label="
                    gameData.team_b_name +
                    ' ' +
                    availableQuarter[0].text +
                    ' ' +
                    (parseInt(availableQuarter[0].value) < 5 ? 'Quarter' : '') +
                    ' Score'
                  "
                  outlined
                  hide-details="auto"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <div class="mt-4 text-right">
              <v-btn color="grey" outlined class="me-2" @click="dialog = false">
                Cancel
              </v-btn>

              <v-btn
                :disabled="!valid"
                type="submit"
                color="primary"
                outlined
                @click.prevent="handleSubmit"
                :loading="createResultLoading"
              >
                Create
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";
import gameQuarters from "@/data/gameQuarters.json";

export default {
  props: {
    gameData: {
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    valid: true,
    rules: formRules,
    gameQuarters: gameQuarters,
    teamascore: "",
    teambscore: "",
  }),
  computed: {
    ...mapState("ending_game_details", ["createResultError", "createResultLoading"]),
    availableQuarter() {
      if (this.gameData.results) {
        return this.gameQuarters.filter((quarter) => {
          return !this.gameData.results.find((result) => {
            return parseInt(result.quarter_id) === quarter.value;
          });
        });
      } else {
        return this.gameQuarters;
      }
    },
  },
  methods: {
    ...mapActions("ending_game_details", ["createResult"]),
    async handleSubmit() {
      if (this.$refs.form.validate()) {
        const formData = new FormData();
        formData.append("game_id", this.gameData.game_id);
        formData.append("quarter_id", this.availableQuarter[0].value);
        formData.append("teamascore", this.teamascore);
        formData.append("teambscore", this.teambscore);

        if (!this.createResultLoading) {
          const res = await this.createResult(formData);

          if (!this.createResultError) {
            this.$refs.form.reset();
            this.dialog = false;

            this.$emit("success", res);
          }
        }
      }
    },
  },
  watch: {
    dialog(visible) {
      if (visible) {
        setTimeout(() => {
          this.$refs.teamascore.focus();
        }, 200);
      }
    },
  },
};
</script>
