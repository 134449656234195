var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4 pa-md-6"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px"}},[_vm._v("Investors")]),_c('h2',{staticClass:"font-weight-bold mb-3 mb-md-5"},[_vm._v("Investor List")]),_c('v-card',{staticClass:"shadow"},[_c('div',{staticClass:"d-flex justify-space-between align-center bs-py-3 bs-px-4"},[_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"black"},on:{"click":_vm.set_create_update_dialog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("New")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"black","dark":""},on:{"click":_vm.loadRecords}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center","gap":"1rem"}},[_c('div',{staticClass:"d-flex align-center gap"},[_c('v-text-field',{ref:"search",attrs:{"placeholder":"Search","single-line":"","hide-details":"auto","disabled":_vm.records_loading,"clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"black"},on:{"click":function($event){return _vm.set_filter_dialog()}}},on),[_c('v-icon',[_vm._v(" mdi-filter-variant ")])],1)]}}])},[_vm._v(" Filter ")])],1)])]),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":_vm.itemPerPage,"search":_vm.search,"loading":_vm.records_loading,"loading-text":"Loading, please wait a moment","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.showInvestorInfo(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.set_manage_sub_users_dialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-account-supervisor ")])],1)]}}],null,true)},[_c('span',[_vm._v("Manage Sub Accounts")])])],1)]}},{key:"item.mobile_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("+63" + item.mobile_number)+" ")]}},{key:"item.base_commission",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moneyFormat(parseFloat(item.base_commission)))+"%")])]}},{key:"item.commission",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moneyFormat(parseFloat(item.commission)))+"%")])]}},{key:"item.sub_commission",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moneyFormat(parseFloat(item.sub_commission)))+"%")])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",class:item.category == 'Regular'
              ? 'green lighten-5 green--text'
              : 'orange lighten-5 orange--text',attrs:{"small":""}},[_vm._v(" "+_vm._s(item.category)+" ")])]}},{key:"item.wallet",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(("Php " + (_vm.moneyFormat(parseFloat(item.wallet)))))+" ")]}},{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.setCreatedAtFormat(item.created_at)))])]}}],null,true)})],1),(_vm.create_update_dialog)?_c('CreateUpdate',{attrs:{"investor_data":_vm.investor_data},on:{"set_close_create_update_dialog":_vm.set_close_create_update_dialog}}):_vm._e(),(_vm.filterDialog)?_c('FilterDialog',{attrs:{"filter_data":_vm.filter_data},on:{"close_filter_dialog":_vm.close_filter_dialog}}):_vm._e(),(_vm.manage_sub_user_dialog)?_c('ManageSubUsers',{attrs:{"investor_id":_vm.investor_id},on:{"set_close_manage_sub_users_dialog":_vm.set_close_manage_sub_users_dialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }