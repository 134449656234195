<template>
    <v-dialog scrollable v-model="dialog" max-width="600">
        <v-card>
            <v-card-title>
                {{ header }} 
            </v-card-title>

            <v-divider/>

            <v-card-text class="pa-4">
                <v-form ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="handleSubmit">

                    <v-row>
                        <v-col cols="12">
                        <v-subheader class="pa-0">Name</v-subheader>
                        <v-text-field ref="name"
                            outlined
                            dense
                            :rules="[rules.required, nameExist]"
                            v-model="name"
                            hide-details="auto"
                        />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-subheader class="pa-0">Description</v-subheader>
                            <v-text-field ref="description"
                                outlined
                                dense
                                :rules="[rules.required]"
                                v-model="description"
                                hide-details="auto"
                            />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-subheader class="pa-0">Value</v-subheader>
                            <v-text-field ref="value"
                                outlined
                                dense
                                :rules="[rules.required]"
                                v-model="value"
                                hide-details="auto"
                            />
                        </v-col>
                    </v-row>

                    <v-row class="pl-2 pr-2 mb-2">
                        <v-col cols="12" md="6" class="pa-1">
                            <v-btn block
                                type="submit"
                                depressed
                                :loading="loading"
                                color="primary">
                                {{ buttonText }}
                            </v-btn>
                        </v-col>

                        <v-col cols="12" md="6" class="pa-1">
                            <v-btn block
                                depressed
                                @click="cancelTransaction">
                                CANCEL
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex"
import formRules from '@/helpers/formRules'

export default {
    props: {
        configurationDetails: {
            type: Object,
        },
    },
    data: () => ({
        name: "",
        description: "",
        value: "",
        nameExistText: "",
        rules: formRules,
        valid: true,
        header: 'Create New',
        buttonText: 'SUBMIT',
        data: []
    }),

    mounted() {
        if (Object.keys(this.configurationDetails).length != 0) {
            this.header = 'Update Configuration Details'
            this.buttonText = 'UPDATE'
            this.name = this.configurationDetails.config_name
            this.description = this.configurationDetails.config_desc
            this.value = this.configurationDetails.config_value
        }

        setTimeout(() => {
            this.$refs.name.focus()
        }, 300);        
    },

    computed: {
        ...mapState("ending_configuration", ["loading", "id", "error", "errors"]),
        dialog: {
            get: function () {
                return true;
            },
            set: function () {
                this.closeModal();
            },
        },
    },

    methods: {
        ...mapActions("ending_configuration", ["createConfiguration", "updateConfiguration"]),
        async handleSubmit() {
            switch (this.buttonText) {
                case "SUBMIT": {
                     if (this.$refs.form.validate()) {
                        if (!this.loading) {
                            await this.createConfiguration(this.setSubmitFormData())

                            if (!this.error && this.isObjectEmpty(this.errors)) {
                                this.data = {
                                    "action": "Submit",
                                    "id": this.id,
                                    "name": this.name,
                                    "description": this.description,
                                    "value": this.value
                                }
                                this.closeModal()
                            } else this.setErrors()
                        }
                    }

                    break;
                }
                   
                case "UPDATE": {
                    if (this.$refs.form.validate()) {
                        if (!this.loading) {
                            await this.updateConfiguration(this.setSubmitFormData())

                            if (!this.error && this.isObjectEmpty(this.errors)) {
                                this.data = {
                                    "action": "Update",
                                    "id": this.configurationDetails.id,
                                    "name": this.name,
                                    "description": this.description,
                                    "value": this.value
                                }
                                this.closeModal()
                            } else this.setErrors()
                        }
                    }

                    break;
                }                    
            }
        },
        setSubmitFormData() {
            const formData = new FormData()

            if (this.configurationDetails) {
                formData.append("id", this.configurationDetails.id)    
            }

            formData.append("name", this.name)
            formData.append("description", this.description)
            formData.append("value", this.value)

            return formData
        },
        setErrors() {
            let errorFocus = "";

            Object.entries(this.errors).forEach(([key, value]) => {
            if (value) {
                if (!errorFocus) {
                    errorFocus = key;
                }

                if (key == "name") {
                    this.nameExistText = this.name;
                }

                this.$refs.form.validate();
                this.$refs[errorFocus].focus();
                }
            });
        },
        closeModal() {
            this.$emit("closeModal", this.data)
        },
        cancelTransaction() {
            this.data = {}
            this.closeModal()
        },
        nameExist(val) {
            if (val == this.nameExistText) {
                return "Configuration already exist.";
            } else return true;
        },
        isObjectEmpty(obj) {
          return Object.keys(obj).length === 0;
        },
    }
}
</script>