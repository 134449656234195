<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Power Ball Game</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Scheduled Games</h2>
    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  @click="loadRecords"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
            <downloadexcel
              class="d-inline-block"
              :data="records"
              :fields="{
                ID: 'game_id',
                League: 'league',
                Game: {
                  callback: (value) => {
                    return `${value.team_a_name} ${value.team_a_alias} vs ${value.team_b_name} ${value.team_b_alias}`;
                  },
                },
                Schedule: 'schedule',
                Betting: 'betting',
                Status: 'status',
                'Created At': 'created_at',
              }"
              :name="exportExcelName()"
              type="csv"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="secondary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-file-delimited-outline</v-icon>
                  </v-btn>
                </template>
                <span>Export to EXCEL</span>
              </v-tooltip>
            </downloadexcel>
          </v-col>
          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="recordsLoading"
              clearable
              outlined
              dense
            >
              <template v-slot:append-outer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      color="secondary"
                      @click="filter"
                      v-on="on"
                      small
                    >
                      <v-icon>mdi-filter-variant</v-icon>
                    </v-btn>
                  </template>
                  Filter
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="-1"
          :search="search"
          :loading="recordsLoading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="showDetails(item.game_id)"
                  icon
                  small
                  color="primary"
                >
                  <v-icon size="large"> mdi-text-box-search-outline </v-icon>
                </v-btn>
              </template>
              <span>Details</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.game`]="{ item }">
            <div>
              <v-avatar size="36px" tile>
                <v-img :src="item.team_a_logo">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="black"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
              <span class="text-caption"
                >{{ item.team_a_name }} {{ item.team_a_alias }}</span
              >
              <span class="text-overline my-2 mx-2 font-italic">vs</span>
              <v-avatar size="36px" tile>
                <v-img :src="item.team_b_logo">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="black"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
              <span class="text-caption"
                >{{ item.team_b_name }} {{ item.team_b_alias }}</span
              >
            </div>
          </template>

          <template v-slot:[`item.betting`]="{ item }">
            <v-chip
              small
              :class="
                item.betting == 'Enabled'
                  ? 'green lighten-5 green--text'
                  : 'orange lighten-5 orange--text'
              "
            >
              {{ item.betting }}
            </v-chip>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              small
              :class="
                item.status
                  ? 'green lighten-5 green--text'
                  : 'orange lighten-5 orange--text'
              "
            >
              {{ item.status ? item.status : "Not yet started" }}
            </v-chip>
          </template>

          <template v-slot:[`item.schedule`]="{ item }">
            <span>{{ formatTheDate(item.schedule) }}</span>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ formatTheDate(item.created_at) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <FilterGame
      v-if="filterDialog"
      :filter_data="filter_data"
      @setCloseModal="setCloseModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { format } from "date-fns";
import downloadexcel from "vue-json-excel";
import formatDate from "@/helpers/formatDate";
import FilterGame from "@/components/power_ball/FilterScheduledGames";

export default {
  components: { downloadexcel, FilterGame },
  data: () => ({
    search: "",
    sortBy: "schedule",
    sortDesc: true,
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      { text: "League", sortable: false, value: "league", class: "text-uppercase" },
      { text: "Game", sortable: false, value: "game", class: "text-uppercase" },
      { text: "Schedule", value: "schedule", class: "text-uppercase" },
      { text: "Betting", value: "betting", class: "text-uppercase" },
      { text: "Status", sortable: false, value: "status", class: "text-uppercase" },
      { text: "Created At", value: "created_at", class: "text-uppercase" },
    ],
    filterDialog: false,
    filterLeague: "",
    schedule_date_start: "",
    schedule_date_end: "",

    // Filter data
    createdAtStart: format(new Date(), "yyyy-MM-dd"),
    createdAtEnd: format(new Date(), "yyyy-MM-dd"),

    // Filters
    filter_data: {},
    query_filters: {},
  }),
  created() {
    this.schedule_date_start = this.createdAtStart;
    this.schedule_date_end = this.createdAtEnd;
    this.query_filters = {
      date_from: this.schedule_date_start,
      date_to: this.schedule_date_end,
      league: "",
    };

    const urlQuery = this.$route.query;
    if (Object.keys(urlQuery).length) {
      this.schedule_date_start = urlQuery.date_from;
      this.schedule_date_end = urlQuery.date_to;
      this.filterLeague = urlQuery.league;
      this.setFilterData();
    } else {
      this.loadRecords();
    }
  },
  computed: {
    ...mapState("power_ball_games", ["records", "recordsLoading", "leagues"]),
  },
  methods: {
    ...mapActions("power_ball_games", ["getRecords", "getFilteredData"]),

    formatTheDate(theDate) {
      return formatDate(theDate, true);
    },

    showDetails(game_id) {
      this.$router.push({ name: "PowerBallGameDetails", params: { id: game_id } });
    },

    filter() {
      this.filter_data = {
        date_from: this.schedule_date_start,
        date_to: this.schedule_date_end,
        league: this.filterLeague,
      };
      this.filterDialog = true;
    },

    loadRecords() {
      if (!this.recordsLoading) {
        this.$store.commit("power_ball_games/setRecords", []);
        const formData = new FormData();
        formData.append("created_at_start", this.createdAtStart);
        formData.append("created_at_end", this.createdAtEnd);
        const getData = new URLSearchParams(formData);
        this.filters = {
          date_from: this.createdAtStart,
          date_to: this.createdAtEnd,
          league_id: 0,
        };
        this.getRecords(getData);
      }
    },
    setQueryString() {
      const currentPath = `/power_ball_scheduled_games?date_from=${this.query_filters.date_from}&date_to=${this.query_filters.date_to}&league=${this.query_filters.league}`;
      if (currentPath != this.$router.currentRoute.fullPath) {
        this.$router.push({
          name: this.$route.name,
          query: this.query_filters,
        });
      }
    },

    setCloseModal(filter_details) {
      this.filterDialog = false;
      if (filter_details.action == "search") {
        this.schedule_date_start = filter_details.date_from;
        this.schedule_date_end = filter_details.date_to;
        this.filterLeague = filter_details.league;
        this.query_filters = {
          date_from: filter_details.date_from,
          date_to: filter_details.date_to,
          league: filter_details.league,
        };
        this.setQueryString();
        this.setFilterData();
      }
    },

    setFilterData() {
      const formData = new FormData();
      formData.append("date_from", this.schedule_date_start);
      formData.append("date_to", this.schedule_date_end);
      formData.append("league", this.filterLeague);
      const getData = new URLSearchParams(formData);
      this.getFilteredData(getData);
    },

    exportExcelName() {
      return "Power Ball Games " + format(new Date(), "yyyy-MM-dd hh:mm:ss") + ".xls";
    },
  },
};
</script>
