<template>
  <v-dialog
    v-model="dialog"
    :max-width="!is_regular_player_or_sales_representative ? 1300 : 1200"
    persistent
  >
    <v-card>
      <div class="d-flex align-center justify-space-between px-6 py-4">
        <h3>
          {{ supervisor_name + " — " + supervisor_sub_agent_title + " List" }}
        </h3>
        <v-btn icon @click="close_supervisor_dialog()">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <div class="d-flex justify-end px-6 py-4">
        <div class="search-wrapper">
          <v-text-field
            ref="search"
            v-model="search"
            placeholder="Search"
            single-line
            hide-details="auto"
            :disabled="loading"
            clearable
            outlined
            dense
          >
          </v-text-field>
        </div>
      </div>

      <v-divider></v-divider>

      <v-data-table
        :headers="table_headers"
        :items="supervisor_sub_agent"
        :items-per-page="items_per_page"
        :search="search"
        :loading="loading"
        loading-text="Loading, please wait a moment"
        :sort-by.sync="sort_by"
        :sort-desc.sync="sort_desc"
      >
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>

        <template v-slot:[`item.mobile_number`]="{ item }">
          {{ "+63" + item.mobile_number }}
        </template>

        <template v-slot:[`item.betting`]="{ item }">
          <v-chip
            small
            :class="
              item.betting == 'Enabled'
                ? 'green lighten-5 green--text'
                : 'red lighten-5 red--text'
            "
          >
            {{ item.betting }}
          </v-chip>
        </template>

        <template v-slot:[`item.cashin`]="{ item }">
          <v-chip
            small
            :class="
              item.cashin == 'Enabled'
                ? 'green lighten-5 green--text'
                : 'red lighten-5 red--text'
            "
          >
            {{ item.cashin }}
          </v-chip>
        </template>

        <template v-slot:[`item.cashout`]="{ item }">
          <v-chip
            small
            :class="
              item.cashout == 'Enabled'
                ? 'green lighten-5 green--text'
                : 'red lighten-5 red--text'
            "
          >
            {{ item.cashout }}
          </v-chip>
        </template>

        <template v-slot:[`item.is_active`]="{ item }">
          <v-chip
            small
            :class="
              item.is_active == 'Active'
                ? 'green lighten-5 green--text'
                : 'red lighten-5 red--text'
            "
          >
            {{ item.is_active }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    table_headers: [
      { text: "Name", value: "name", class: "text-uppercase" },
      { text: "Mobile Number", value: "mobile_number", class: "text-uppercase" },
      { text: "Betting", value: "betting", class: "text-uppercase" },
      { text: "Cash In", value: "cashin", class: "text-uppercase" },
      { text: "Cash Out", value: "cashout", class: "text-uppercase" },
      { text: "Status", value: "is_active", class: "text-uppercase" },
    ],
    supervisor_sub_agent: [],
    loading: false,
    search: null,
    items_per_page: 5,
    sort_by: "name",
    sort_desc: true,
  }),
  mounted() {
    if (this.supervisor_sub_agent_title == "Sales Representative") {
      this.get_supervisor_sales_representative();
    } else {
      this.get_supervisor_regular_player();
    }
  },

  props: {
    supervisor_sub_agent_title: String,
    supervisor_id: String,
    supervisor_name: String,
  },
  name: "MasterAgentSubAgents",
  methods: {
    async get_supervisor_sales_representative() {
      let url = process.env.VUE_APP_API_URL;
      let token = localStorage.getItem("userToken");

      this.loading = true;

      await axios
        .get(url + "agents/sales_supervisor_sales_representative", {
          params: { id: this.supervisor_id },
          headers: { Authorization: `Bearer ` + token },
        })
        .then((res) => {
          this.supervisor_sub_agent = res.data.records;
        });

      this.loading = false;
    },
    async get_supervisor_regular_player() {
      let url = process.env.VUE_APP_API_URL;
      let token = localStorage.getItem("userToken");

      this.loading = true;

      await axios
        .get(url + "agents/sales_supervisor_regular_player", {
          params: { id: this.supervisor_id },
          headers: { Authorization: `Bearer ` + token },
        })
        .then((res) => {
          this.supervisor_sub_agent = res.data.records;
        });

      this.loading = false;
    },
    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    close_supervisor_dialog() {
      this.$emit("close_supervisor_dialog", false);
    },
  },
  computed: {
    dialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.close_supervisor_dialog();
      },
    },
    is_regular_player_or_sales_representative() {
      if (
        this.supervisor_sub_agent_title == "Regular Player" ||
        this.supervisor_sub_agent_title == "Sales Representative"
      ) {
        return true;
      }

      return false;
    },
  },
};
</script>

<style scoped>
.search-wrapper {
  display: inline-block;
}
.search {
  width: 400px;
}

@media (max-width: 768px) {
  .search-wrapper {
    width: 100% !important;
    display: block !important;
  }
  .search {
    width: 100% !important;
  }
}
</style>
