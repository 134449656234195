<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Investors</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Payouts Settlement</h2>

    <v-card class="shadow">
      <!-- <div class="bs-py-3 bs-px-4">
        <h3>Payouts</h3>
      </div>

      <v-divider></v-divider> -->

      <div class="d-flex justify-end align-center bs-py-3 bs-px-4" style="gap: 1rem">
        <div class="d-inline-flex align-center justify-end gap">
          <v-text-field ref="search" v-model="search" placeholder="Search" single-line hide-details="auto"
            :disabled="records_loading" clearable outlined dense />
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon color="black" v-on="on" @click="set_filter_dialog" :disabled="records_loading">
                <v-icon> mdi-filter-variant </v-icon>
              </v-btn>
            </template>
            Filter
          </v-tooltip>
        </div>
      </div>

      <v-divider></v-divider>

      <v-data-table :headers="headers" :items="records" :search="search" :loading="records_loading"
        loading-text="Loading, please wait a moment" :items-per-page="5" item-key="investor_id">
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dense v-bind="attrs" v-on="on" icon color="primary" @click="set_payout_details_dialog(item)"
                :disabled="records_loading">
                <v-icon> mdi-database-eye-outline </v-icon>
              </v-btn>
            </template>
            <span>View Payouts</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.payout`]="{ item }">
          <span>{{ `Php ${money_format(parseFloat(item.payout))}` }}</span>
        </template>

        <template v-slot:[`item.dates`]="{ item }">
          {{ `${format_date(item.min_date)} — ${format_date(item.max_date)}` }}
        </template>
      </v-data-table>
    </v-card>

    <PayoutsFilter v-if="filter_dialog" :filter_dialog_data="filter_dialog_data"
      @set_close_filter_dialog="set_close_filter_dialog" />
    <PayoutDetails v-if="payout_details_dialog" :payout_details_data="payout_details_data"
      @set_close_payout_details_dialog="set_close_payout_details_dialog" />
  </div>
</template>

<script>
import responseGet from "@/helpers/api_request_get";
import formatDatev2 from "@/helpers/formatDatev2";
import PayoutsFilter from "@/components/investors/PayoutsFilter";
import PayoutDetails from "@/components/investors/PayoutDetails";
import { mapActions } from "vuex";

export default {
  components: { PayoutDetails, PayoutsFilter },

  data: () => ({
    search: "",
    date_from: null,
    date_to: null,
    filter_dialog: false,
    filter_dialog_data: {},
    filter_status: false,
    query_filter: {},
    records: [],
    records_loading: false,
    payout_details_dialog: false,
    payout_details_data: [],
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
      },
      {
        text: "Name",
        align: "start",
        value: "name",
        class: "text-uppercase",
      },
      {
        text: "Payout",
        align: "right",
        value: "payout",
        class: "text-uppercase",
      },
      {
        text: "Covered Dates",
        align: "start",
        value: "dates",
        class: "text-uppercase",
      },
    ],
  }),

  mounted() {
    this.check_query_string();
    this.doGetInvestorPayouts();
  },

  methods: {
    ...mapActions("logout", ["sendLogoutRequest"]),

    async doGetInvestorPayouts() {
      this.records_loading = !this.records_loading;

      const response = await responseGet(this.set_form_data(), "investor/payouts");

      switch (response.status) {
        case 200:
          this.records = response.data.records;
          break;
        case 401:
          await this.sendLogoutRequest();
          this.$router.push({ name: "Home" });
          break;
      }

      this.records_loading = !this.records_loading;
    },

    set_payout_details_dialog(item) {
      this.payout_details_data = {
        investor_id: item.investor_id,
        date_from: this.date_from,
        date_to: this.date_to,
      };
      this.payout_details_dialog = !this.payout_details_dialog;
    },

    set_close_payout_details_dialog(status) {
      if (status) this.doGetInvestorPayouts();
      this.payout_details_dialog = !this.payout_details_dialog;
    },

    set_form_data() {
      const form_data = new FormData();

      form_data.append("date_from", this.date_from ?? "");
      form_data.append("date_to", this.date_to ?? "");

      return new URLSearchParams(form_data);
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    set_filter_dialog() {
      this.filter_dialog_data = {
        date_from: this.date_from,
        date_to: this.date_to,
      };

      this.filter_dialog = !this.filter_dialog;
    },

    set_close_filter_dialog(filter_data) {
      if (Object.keys(filter_data).length) {
        this.date_from = filter_data.date_from;
        this.date_to = filter_data.date_to;

        this.doGetInvestorPayouts();

        this.set_query_string();
      }

      this.filter_dialog = !this.filter_dialog;
    },

    set_query_string() {
      const urlQuery = this.$route.query;
      this.query_filters = {};

      if (urlQuery.date_from != this.date_from || urlQuery.date_to != this.date_to) {
        if (this.date_from != null) this.query_filters["date_from"] = this.date_from;
        if (this.date_to != null) this.query_filters["date_to"] = this.date_to;

        this.$router.replace({
          name: this.$route.name,
          query: this.query_filters,
        });
      }
    },

    check_query_string() {
      const urlQuery = this.$route.query;

      if (Object.keys(urlQuery).length) {
        if (Object.prototype.hasOwnProperty.call(urlQuery, "date_from"))
          this.date_from = urlQuery.date_from;
        if (Object.prototype.hasOwnProperty.call(urlQuery, "date_to"))
          this.date_to = urlQuery.date_to;
      }
    },

    format_date(date) {
      return formatDatev2(date, "custom_date");
    },
  },
};
</script>
