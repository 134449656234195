<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Agent Reports</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Bets</h2>
    <v-card class="shadow mb-4">
      <v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
        Select Bet Date
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-4">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          :disabled="loading"
          @submit.prevent="handleSubmit"
        >
          <v-row>
            <v-col cols="12" md="6">
              <v-menu
                v-model="date_from_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_from"
                    :rules="[rules.required, lessThanDate]"
                    label="Bet Date From"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @keyup="date_from_menu = true"
                    @keydown="date_from_menu = false"
                    clearable
                  />
                </template>

                <v-date-picker
                  v-model="date_from"
                  @input="date_from_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="date_to_menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_to"
                    :rules="[lessThanDate2]"
                    label="Bet Date To (Optional)"
                    hide-details="auto"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :disabled="dateToAvailability"
                    @keyup="date_to_menu = true"
                    @keydown="date_to_menu = false"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_to"
                  @input="date_to_menu = false"
                  :min="date_from ? date_from : ''"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row dense class="mt-4">
            <v-col>
              <v-btn
                :disabled="!valid"
                type="submit"
                color="primary"
                outlined
                :loading="loading"
                :block="$vuetify.breakpoint.mdAndUp ? false : true"
              >
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                  @click="loadRecords"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="loading"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pa-4">
        <v-data-table
          :headers="headers"
          :items="records"
          :items-per-page="-1"
          :search="search"
          :loading="loading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          mobile-breakpoint="0"
          hide-default-footer
        >
          <template v-slot:[`item.action`]="{ item }">
            <!-- <v-row align="center" justify="center"> -->
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dense
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  color="primary"
                  @click="showPlayers(item.id, item.agent_name)"
                >
                  <v-icon size="large"> mdi-account-eye </v-icon>
                </v-btn>
              </template>
              <span>View Player(s)</span>
            </v-tooltip>
            <!-- </v-row> -->
          </template>

          <template v-slot:[`item.total_bets`]="{ item }">
            {{ parseInt(item.total_bets).toLocaleString() }}
          </template>

          <template v-slot:[`item.total_bets_amount`]="{ item }">
            PHP {{ moneyFormat(parseFloat(item.total_bets_amount)) }}
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ setCreatedAtFormat(item.created_at) }}</span>
          </template>

          <template v-if="records.length" v-slot:[`body.append`]>
            <tr>
              <td></td>
              <td class="text-right"><strong>TOTAL</strong></td>
              <td>
                <strong>{{ parseInt(totalBets).toLocaleString() }}</strong>
              </td>
              <td>
                <strong>{{ "PHP " + moneyFormat(parseFloat(totalBetAmount)) }}</strong>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <Players
      v-if="playerDialog"
      :player_data="player_data"
      @setPlayerCloseModal="setPlayerCloseModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";
import { format, sub } from "date-fns";
import Players from "@/components/agent_reports/Players";

export default {
  components: {
    Players,
  },

  data: () => ({
    valid: true,
    rules: formRules,
    search: "",
    date_from: format(sub(new Date(), { days: 30 }), "yyyy-MM-dd"),
    date_to: format(new Date(), "yyyy-MM-dd"),
    date_from_menu: false,
    date_to_menu: false,
    query_filters: {},
    playerDialog: false,
    player_data: {},
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      // { text: "ID", value: "id", align: "left" },
      {
        text: "Agent Name",
        sortable: false,
        value: "agent_name",
        class: "text-uppercase",
      },
      {
        text: "Total Bets",
        sortable: false,
        value: "total_bets",
        class: "text-uppercase",
      },
      {
        text: "Total Amount",
        sortable: false,
        value: "total_bets_amount",
        class: "text-uppercase",
      },
    ],
    sortBy: "id",
    sortDesc: true,
  }),

  created() {
    const urlQuery = this.$route.query;

    if (Object.keys(urlQuery).length) {
      this.date_from = urlQuery.date_from;
      this.date_to = urlQuery.date_to;
    }

    this.loadRecords();
  },

  computed: {
    ...mapState(["accountInfo"]),
    ...mapState("agents_report", ["loading", "error", "errors", "records"]),

    lessThanDate() {
      if (this.date_from && this.date_to) {
        if (new Date(this.date_to) < new Date(this.date_from)) {
          return "Must be higher or equal than date from.";
        }
      }

      return true;
    },

    lessThanDate2() {
      if (this.date_from && this.date_to) {
        if (new Date(this.date_from) > new Date(this.date_to)) {
          return "Must be lessthan or equal than date to.";
        }
      }

      return true;
    },

    dateToAvailability() {
      if (this.date_from == "" || this.date_from == null) {
        return true;
      }

      return false;
    },

    totalBets() {
      if (this.records) {
        return this.records.reduce((total, obj) => parseFloat(obj.total_bets) + total, 0);
      } else {
        return 0;
      }
    },

    totalBetAmount() {
      if (this.records) {
        return this.records.reduce(
          (total, obj) => parseFloat(obj.total_bets_amount) + total,
          0
        );
      } else {
        return 0;
      }
    },
  },

  methods: {
    ...mapActions("agents_report", ["getRecords"]),

    async loadRecords() {
      await this.getRecords(this.setRecordsFormData());
    },

    async handleSubmit() {
      await this.getRecords(this.setRecordsFormData());

      if (!this.error && this.isObjectEmpty(this.errors)) {
        this.setQueryString();
      }
    },

    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    setRecordsFormData() {
      const formData = new FormData();
      formData.append("date_from", this.date_from);
      formData.append("date_to", this.date_to);

      const getData = new URLSearchParams(formData);

      return getData;
    },

    showPlayers(id, agent_name) {
      this.$store.commit("agents_report/setPlayers", []);
      this.player_data = {
        id: id,
        agent_name: agent_name,
        date_from: this.date_from,
        date_to: this.date_to,
      };
      this.playerDialog = true;
    },

    setPlayerCloseModal() {
      this.playerDialog = false;
    },

    setQueryString() {
      const urlQuery = this.$route.query;

      if (urlQuery.date_from != this.date_from || urlQuery.date_to != this.date_to) {
        this.query_filters = {
          date_from: this.date_from,
          date_to: this.date_to,
        };

        this.$router.push({
          name: this.$route.name,
          query: this.query_filters,
        });
      }
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },

  watch: {
    date_from(val) {
      if (val == "" || val == null) {
        this.date_to = "";
      }
    },
  },
};
</script>
