<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Ending Reports</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Bets</h2>
    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  @click="requestGameCards"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>

            <downloadexcel
              class="d-inline-block"
              :data="cards"
              :fields="{
                ID: 'card_id',
                'Card Number': 'card_number',
                Game: {
                  callback: () => {
                    return `${details.team_a_name} vs ${details.team_b_name}`;
                  },
                },
                'Type Of Bet': 'type',
                'Bet Amount': {
                  callback: (value) => {
                    return value.bet_amount;
                  },
                },
                'Bet Amount': {
                  callback: (value) => {
                    return `PHP ${value.bet_amount}`;
                  },
                },
                'Total Bets': {
                  callback: (value) => {
                    return `${value.total_bets} (${value.total_bets}%)`;
                  },
                },
                'Created At': 'created_at',
              }"
              :name="exportExcelName()"
              type="csv"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="secondary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-file-export-outline</v-icon>
                  </v-btn>
                </template>
                <span>Export to EXCEL</span>
              </v-tooltip>
            </downloadexcel>
          </v-col>

          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="loading"
              clearable
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="pb-0">
        <div class="text-center title">
          {{ details.league }}
        </div>
        <div class="text-center title">
          <span class="text-caption">{{ setCreatedAtFormat(details.schedule) }}</span>
        </div>
      </v-card-text>

      <v-row justify="center" align="center" class="mb-4">
        <v-col cols="auto" class="text-center" align-self="center">
          <v-avatar size="100px" tile>
            <v-img :src="details.team_a_logo">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="black"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
          <div>
            <span class="text-caption"
              >{{ details.team_a_name }} {{ details.team_a_alias }}</span
            >
          </div>
        </v-col>

        <v-col cols="auto" class="text-center">
          <span class="text-overline my-2 mx-2 font-italic">vs</span>
        </v-col>

        <v-col cols="auto" class="text-center">
          <v-avatar size="100px" tile>
            <v-img :src="details.team_b_logo">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="black"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>
          <div>
            <span class="text-caption"
              >{{ details.team_b_name }} {{ details.team_b_alias }}</span
            >
          </div>
        </v-col>
      </v-row>

      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="cards"
          :items-per-page="5"
          :search="search"
          :loading="loading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :to="{ name: 'EndingCardDetails', params: { id: item.card_id } }"
                  icon
                  small
                  color="primary"
                >
                  <v-icon size="large"> mdi-text-box-search-outline </v-icon>
                </v-btn>
              </template>
              <span>Details</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.total_bets`]="{ item }">
            <v-progress-linear
              :color="getColor(item)"
              height="25"
              class="d-none d-md-block"
            >
              <strong>{{ Math.ceil(item.total_bets) }}%</strong>
            </v-progress-linear>

            <strong class="d-block d-sm-none">{{ Math.ceil(item.total_bets) }}%</strong>
          </template>

          <template v-slot:[`item.card_number`]="{ item }">
            <span>{{ setCardNumberFormat(item) }}</span>
          </template>

          <template v-slot:[`item.bet_amount`]="{ item }">
            <span>{{ setBetAmount(item) }}</span>
          </template>

          <template v-slot:[`item.total_bet_amount`]="{ item }">
            <span>{{ setTotalBetAmount(item) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formatDatev2 from "@/helpers/formatDatev2";
import downloadexcel from "vue-json-excel";
import { format } from "date-fns";

export default {
  components: { downloadexcel },
  props: {
    id: {
      required: true,
    },
  },
  data: () => ({
    search: "",
    sortBy: "card_id",
    sortDesc: true,
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      // { text: 'ID', value: 'card_id', align: 'left' },
      {
        text: "Card Number",
        sortable: false,
        value: "card_number",
        class: "text-uppercase",
      },
      { text: "Prize Type", sortable: false, value: "type", class: "text-uppercase" },
      { text: "Bet Amount", value: "bet_amount", class: "text-uppercase" },
      { text: "Total Bets", value: "total_bets", class: "text-uppercase" },
      { text: "Total Bet Amount", value: "total_bet_amount", class: "text-uppercase" },
    ],
  }),
  created() {
    this.requestGameCards();
  },
  computed: {
    ...mapState("game_card", ["details", "cards", "loading", "error", "errors"]),
  },
  methods: {
    ...mapActions("game_card", ["getGameCards"]),
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    async requestGameCards() {
      this.$store.commit("game_card/setCards", []);

      if (!this.loading) {
        await this.getGameCards(this.setGameCardFormData());
      }
    },
    setGameCardFormData() {
      const formData = new FormData();
      formData.append("game_id", this.id);

      const urlSearchParams = new URLSearchParams(formData);

      return urlSearchParams;
    },
    setCreatedAtFormat(date) {
      return formatDatev2(date);
    },
    setBetAmount(item) {
      return "PHP " + this.moneyFormat(parseFloat(item.bet_amount));
    },
    setTotalBetAmount(item) {
      return "PHP " + this.moneyFormat(parseFloat(item.bet_amount * item.total_bets));
    },
    getColor(item) {
      return item.total_bets <= 70 ? "orange" : "green";
    },
    setCardNumberFormat(item) {
      return `#${item.card_number}`;
    },
    exportExcelName() {
      return (
        "Game ID " +
        this.id +
        " Game Cards [ " +
        this.details.team_a_name +
        " vs " +
        this.details.team_b_name +
        " ] " +
        format(new Date(), "yyyy-MM-dd hh:mm:ss") +
        ".xls"
      );
    },
  },
};
</script>
