<template>
	<div class="pa-4 pa-md-6">
		<div class="grey--text" style="font-size: 13px">Agents</div>
		<h2 class="font-weight-bold mb-3 mb-md-5">Agent List</h2>

		<v-card class="shadow">
			<v-card-title>
				<v-row justify="space-between">
					<v-col cols="12" sm="6" md="4" xl="2" align-self="end">
						<v-tooltip top v-if="this.accountInfo.access.includes(42)">
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon color="secondary" v-bind="attrs" v-on="on" @click="newAgent">
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</template>

							<span>New Agent</span>
						</v-tooltip>

						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn icon color="secondary" @click="doGetAllAgents" v-bind="attrs" v-on="on" class="mr-1">
									<v-icon>mdi-cached</v-icon>
								</v-btn>
							</template>
							<span>Refresh</span>
						</v-tooltip>
					</v-col>

					<v-col cols="12" sm="6" md="4" xl="3">
						<v-text-field
							v-model="search"
							placeholder="Search"
							single-line
							hide-details="auto"
							:disabled="loading"
							clearable
							outlined
							dense>
							<template v-slot:append-outer>
								<v-tooltip top>
									<template v-slot:activator="{ on }">
										<v-btn icon color="secondary" @click="showFilter" v-on="on" small :disabled="loading">
											<v-icon>mdi-filter-variant</v-icon>
										</v-btn>
									</template>
									Filter
								</v-tooltip>
							</template>
						</v-text-field>
					</v-col>
				</v-row>
			</v-card-title>

			<v-divider></v-divider>

			<v-card-text class="pa-0">
				<v-data-table
					:headers="headers"
					:items="records"
					:items-per-page="5"
					:search="search"
					:loading="loading"
					loading-text="Loading, please wait a moment"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc">
					<template v-slot:[`item.action`]="{ item }">
						<!-- <v-row align="center" justify="center"> -->
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn dense v-bind="attrs" v-on="on" icon color="primary" @click="showAgentInfo(item.id)">
									<v-icon> mdi-square-edit-outline </v-icon>
								</v-btn>
							</template>
							<span>Update</span>
						</v-tooltip>

						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									dense
									v-bind="attrs"
									v-on="on"
									icon
									color="primary"
									@click="setAddPlayerModal(item.id, item.mobile_number)"
									:disabled="item.is_active == 'Inactive' ? true : false">
									<v-icon> mdi-plus </v-icon>
								</v-btn>
							</template>
							<span>Add Player</span>
						</v-tooltip>

						<v-tooltip top v-if="accountInfo.access.includes(42)">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									dense
									icon
									v-bind="attrs"
									v-on="on"
									color="primary"
									@click="setManagePlayerModal(item.id, item.name, item.mobile_number)"
									:disabled="item.is_active == 'Inactive' ? true : parseInt(item.no_of_player) == 0 ? true : false">
									<v-icon>mdi-account-group</v-icon>
								</v-btn>
							</template>

							<span>Manage Agent Player</span>
						</v-tooltip>

						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									dense
									v-bind="attrs"
									v-on="on"
									icon
									color="primary"
									:disabled="item.is_active == 'Inactive' ? true : false"
									@click="setResetPasswordModal(item.id)">
									<v-icon> mdi-key-change </v-icon>
								</v-btn>
							</template>
							<span>Reset Password</span>
						</v-tooltip>
						<!-- </v-row> -->
					</template>

					<template v-slot:[`item.mobile_number`]="{ item }"> +63{{ item.mobile_number }} </template>

					<template v-slot:[`item.no_of_player`]="{ item }">
						{{ moneyFormat2(parseInt(item.no_of_player)) }}
					</template>

					<template v-slot:[`item.commission`]="{ item }"> {{ item.commission }}% </template>

					<template v-slot:[`item.wallet`]="{ item }"> PHP {{ moneyFormat(parseFloat(item.wallet)) }} </template>

					<template v-slot:[`item.is_active`]="{ item }">
						<v-chip small :class="item.is_active == 'Active' ? 'green lighten-5 green--text' : 'red lighten-5 red--text'">
							{{ item.is_active }}
						</v-chip>
					</template>

					<template v-slot:[`item.created_at`]="{ item }">
						<span>{{ setCreatedAtFormat(item.created_at) }}</span>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>

		<CreateUpdate v-if="dialog" :agent_id="agent_id" @setCloseModal="closeModal" />
		<FilterDate v-if="filterDialog" :dates="date_filter" @setFiltereModal="closeFilterModal" />
		<AddPlayer v-if="addPlayerDialog" :agent_data="agent_data" @setAddPlayerModal="closeAddPlayerModal" />
		<ManagePlayer
			v-if="managePlayerDialog"
			:agent_data="agent_data"
			@setManagePlayerModal="closeManagePlayerModal"
			@setAddPlayerModal="setAddPlayerModal" />
		<ResetPassword v-if="resetPasswordDialog" :agent_id="agent_id" @closeResetPasswordModal="closeResetPasswordModal" />
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { format, sub } from 'date-fns'
import formatDatev2 from '@/helpers/formatDatev2'
import CreateUpdate from '@/components/agents/CreateUpdate'
import FilterDate from '@/components/agents/Filter'
import AddPlayer from '@/components/agents/AddPlayer'
import ManagePlayer from '@/components/agents/ManagePlayer'
import ResetPassword from '@/components/agents/ResetPassword'

export default {
	components: {
		CreateUpdate,
		FilterDate,
		AddPlayer,
		ManagePlayer,
		ResetPassword,
	},
	data: () => ({
		dialog: false,
		agent_id: 0,
		agent_data: {},
		filterDialog: false,
		addPlayerDialog: false,
		managePlayerDialog: false,
		resetPasswordDialog: false,
		search: '',
		headers: [
			{
				text: 'Action',
				value: 'action',
				class: 'text-uppercase',
			},
			{
				text: 'Name',
				value: 'name',
				class: 'text-uppercase',
			},
			{
				text: 'Mobile Number',
				value: 'mobile_number',
				class: 'text-uppercase',
			},
			{
				text: 'Email',
				value: 'email',
				class: 'text-uppercase',
			},
			{
				text: 'Username',
				value: 'username',
				class: 'text-uppercase',
			},
			{
				text: 'No. of Player',
				value: 'no_of_player',
				class: 'text-uppercase',
			},
			{
				text: 'No. of Player (Verified)',
				value: 'no_of_player_verified',
				class: 'text-uppercase',
			},
			{
				text: 'Commission',
				value: 'commission',
				class: 'text-uppercase',
			},
			{
				text: 'Wallet',
				value: 'wallet',
				class: 'text-uppercase',
			},
			{
				text: 'Status',
				value: 'is_active',
				class: 'text-uppercase',
			},
			{
				text: 'Created At',
				value: 'created_at',
				class: 'text-uppercase',
			},
		],
		sortBy: 'no_of_player',
		sortDesc: true,
		filter_by: 'Date Created',
		date_from: format(sub(new Date(), { days: 30 }), 'yyyy-MM-dd'),
		date_to: format(new Date(), 'yyyy-MM-dd'),
		first_name: '',
		last_name: '',
		mobile_number: '',
		email: '',
		date_filter: {},
		query_filter: {},
		search_status: false,
	}),

	created() {
		const urlQuery = this.$route.query

		if (Object.keys(urlQuery).length) {
			switch (urlQuery.filter_by) {
				case 'Agent Name':
					this.first_name = urlQuery.first_name
					this.last_name = urlQuery.last_name
					break
				case 'Date Created':
					this.date_from = urlQuery.date_from
					this.date_to = urlQuery.date_to
					break
				case 'Mobile Number':
					this.mobile_number = urlQuery.mobile_number
					break
				case 'Email':
					this.email = urlQuery.email
					break
			}

			this.loadRecords()
		} else {
			this.doGetAllAgents()
		}
	},

	computed: {
		...mapState(['accountInfo']),
		...mapState('agents', ['records', 'agentId', 'loading', 'errors']),
	},

	methods: {
		...mapActions('agents', ['getAgents', 'getAllAgents']),
		newAgent() {
			this.$store.commit('agents/setAgentInfo', [])
			this.dialog = true
		},

		setAddPlayerModal(agent_id, mobile_number) {
			this.agent_data = {
				agent_id: agent_id,
				mobile_number: mobile_number,
			}

			this.addPlayerDialog = true
		},

		setManagePlayerModal(agent_id, name, mobile_number) {
			this.$store.commit('agents/setAgentPlayers', [])
			this.agent_data = {
				agent_id: agent_id,
				name: name,
				mobile_number: mobile_number,
			}
			this.managePlayerDialog = true
		},

		setResetPasswordModal(agent_id) {
			this.agent_id = parseInt(agent_id)
			this.resetPasswordDialog = true
		},

		showAgentInfo(agent_id) {
			//this.setQueryString()
			this.agent_id = parseInt(agent_id)
			this.dialog = true
		},

		showFilter() {
			switch (this.filter_by) {
				case 'Agent Name':
					this.date_filter = {
						filter_by: this.filter_by,
						first_name: this.first_name,
						last_name: this.last_name,
					}
					break
				case 'Date Created':
					this.date_filter = {
						filter_by: this.filter_by,
						date_from: this.date_from,
						date_to: this.date_to,
					}
					break
				case 'Mobile Number':
					this.date_filter = {
						filter_by: this.filter_by,
						mobile_number: this.mobile_number,
					}
					break
				case 'Email':
					this.date_filter = {
						filter_by: this.filter_by,
						email: this.email,
					}
					break
			}

			this.filterDialog = true
		},

		closeModal(insertedAgentInfo, updatedAgentInfo) {
			this.dialog = false

			if (Object.keys(insertedAgentInfo).length != 0) {
				if (this.records) {
					this.$store.commit('agents/setRecords', [insertedAgentInfo, ...this.records])
				} else {
					this.$store.commit('agents/setRecords', [insertedAgentInfo])
				}
			}

			if (Object.keys(updatedAgentInfo).length != 0) {
				const agentData = this.records.find((record) => record.id == updatedAgentInfo.agent_id)

				agentData.name = updatedAgentInfo.name
				agentData.mobile_number = updatedAgentInfo.mobile_number
				agentData.email = updatedAgentInfo.email
				agentData.username = updatedAgentInfo.username
				agentData.commission = updatedAgentInfo.commission
				agentData.wallet = updatedAgentInfo.wallet
				agentData.is_active = updatedAgentInfo.is_active
			}

			this.agent_id = 0
		},

		closeFilterModal(dates_filter) {
			this.filterDialog = false

			if (dates_filter.filter_status == 'search') {
				this.filter_by = dates_filter.filter_by

				switch (dates_filter.filter_by) {
					case 'Agent Name':
						this.first_name = dates_filter.filter_first_name
						this.last_name = dates_filter.filter_last_name
						break
					case 'Date Created':
						this.date_from = dates_filter.filter_date_from
						this.date_to = dates_filter.filter_date_to
						break
					case 'Mobile Number':
						this.mobile_number = dates_filter.filter_mobile_number
						break
					case 'Email':
						this.email = dates_filter.filter_email
						break
				}
				this.setQueryString()
				this.loadRecords()
			}
		},

		closeAddPlayerModal(addedPlayer) {
			this.addPlayerDialog = false

			if (addedPlayer.status == 'add') {
				const agentData = this.records.find((record) => record.id == addedPlayer.agent_id)

				let currentNoOfPlayer = parseInt(agentData.no_of_player)

				agentData.no_of_player = currentNoOfPlayer + 1
			}
		},

		closeManagePlayerModal(confirmationStatus) {
			if (confirmationStatus) {
				this.loadRecords()
			}
			this.managePlayerDialog = false
		},

		closeResetPasswordModal() {
			this.resetPasswordDialog = false
		},

		setQueryString() {
			const urlQuery = this.$route.query

			switch (this.filter_by) {
				case 'Agent Name':
					if (urlQuery.first_name != this.first_name || urlQuery.last_name != this.last_name) {
						this.query_filters = {
							filter_by: this.filter_by,
							first_name: this.first_name,
							last_name: this.last_name,
						}

						this.setRoute()
					}
					break
				case 'Date Created':
					if (urlQuery.date_from != this.date_from || urlQuery.date_to != this.date_to) {
						this.query_filters = {
							filter_by: this.filter_by,
							date_from: this.date_from,
							date_to: this.date_to,
						}

						this.setRoute()
					}
					break
				case 'Mobile Number':
					if (urlQuery.mobile_number != this.mobile_number) {
						this.query_filters = {
							filter_by: this.filter_by,
							mobile_number: this.mobile_number,
						}

						this.setRoute()
					}
					break
				case 'Email':
					if (urlQuery.email != this.email) {
						this.query_filters = {
							filter_by: this.filter_by,
							email: this.email,
						}

						this.setRoute()
					}
					break
			}
		},

		setRoute() {
			this.$router.push({
				name: this.$route.name,
				query: this.query_filters,
			})
		},

		loadRecords() {
			this.$store.commit('agents/setRecords', [])
			this.getAgents(this.setFilterFormData())
		},

		doGetAllAgents() {
			this.$store.commit('agents/setRecords', [])
			this.getAllAgents()
		},

		setFilterFormData() {
			const formData = new FormData()
			formData.append('filter_by', this.filter_by)

			switch (this.filter_by) {
				case 'Agent Name':
					formData.append('first_name', this.first_name)
					formData.append('last_name', this.last_name)
					break
				case 'Date Created':
					formData.append('date_from', this.date_from)
					formData.append('date_to', this.date_to)
					break
				case 'Mobile Number':
					formData.append('mobile_number', this.mobile_number)
					break
				case 'Email':
					formData.append('email', this.email)
					break
			}

			const getData = new URLSearchParams(formData)

			return getData
		},

		setCreatedAtFormat(date) {
			return formatDatev2(date)
		},

		moneyFormat(val) {
			return val.toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			})
		},

		moneyFormat2(val) {
			return val.toLocaleString(undefined, {
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			})
		},
	},
}
</script>
