<template>
    <v-dialog scrollable :fullscreen="fullscreen_status" v-model="dialog" max-width="1200px">
        <v-card>
            <v-card-title class="text-h6 py-2 font-weight-regular grey--text text--darken-1">
                <v-icon class="mr-2"> mdi-basketball </v-icon> PER GAME

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon 
                            absolute 
                            right 
                            class="mr-8" 
                            v-bind="attrs"
                            v-on="on" 
                            @click="fullscreen_status = !fullscreen_status">
                            <v-icon size="large">
                                {{ `mdi-${(fullscreen_status) ? 'dock-window' : 'window-maximize'}` }}
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>{{ `${(fullscreen_status) ? 'Normal View' : 'Maximize'}` }}</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon absolute right @click="closeModal">
                            <v-icon size="large"
                                v-bind="attrs"
                                v-on="on">
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Close</span>
                </v-tooltip>
            </v-card-title>

            <v-divider/>

            <v-card-text class="py-4">
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    @submit.prevent="doGetRecordPerGame">

                    <v-row dense>
                        <v-col cols="12" md="4" class="mt-2">
                            <v-select
                                v-model="game_status"
                                :items="game_statuses"
                                label="Game Status"
                                dense
                                outlined
                                hide-details="auto"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="4" class="mt-2">
                            <v-menu
                                v-model="date_from_menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto">

                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="date_from"
                                        :rules="[rules.required, lessThanDate]"
                                        label="Scheduled Date From"
                                        hide-details="auto"
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                        @keyup="date_from_menu=true"
                                        @keydown="date_from_menu=false"
                                        clearable/>
                                </template>

                                <v-date-picker
                                v-model="date_from"
                                @input="date_from_menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" md="4" class="mt-2">
                            <v-menu
                                v-model="date_to_menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                :disabled="date_from == ''"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="date_to"
                                    label="Scheduled Date To (Optional)"
                                    hide-details="auto"
                                    readonly
                                    outlined
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    :disabled="dateToAvailability"
                                    @keyup="date_to_menu=true"
                                    @keydown="date_to_menu=false"
                                    clearable
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date_to"
                                    @input="date_to_menu = false"
                                    :min="(date_from) ? date_from : ''"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col cols="12" md="4" class="mt-2">
                            <v-select
                                v-model="game_type"
                                :items="game_types"
                                label="Game Type"
                                dense
                                outlined
                                hide-details="auto"
                            ></v-select>
                        </v-col>
                    </v-row>

                    <v-row dense class="mt-2">
                        <v-col>
                            <v-row dense>
                                <v-col>
                                    <v-btn
                                        depressed
                                        type="submit"
                                        color="primary"
                                        :loading="recordsPerGameLoading">
                                        Search
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>

                <v-divider class="mt-4"/>

                <!-- <v-card class="my-4">
                    <v-card-text> -->
                        <v-row dense>
                            <v-col align="center" cols="12" md="2" class="mt-4">
                                <!-- <v-card  class="shadow ">
                                    <v-card-title class="font-weight-regular  pa-0 overline justify-center">
                                        TOTAL CARDS
                                    </v-card-title>

                                    <v-card-text class="py-2 text-center" style="font-size:18px">
                                        {{ parseInt(cardsTotal).toLocaleString() }}
                                    </v-card-text>
                                </v-card> -->

                                <v-card class="pa-1">
                                    <p class="font-weight-regular ma-0 overline">TOTAL CARDS</p>
                                    <v-divider class="mb-4"/>
                                    <p class="font-weight-medium">{{ parseInt(cardsTotal).toLocaleString() }}</p>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row dense>
                            <v-col align="center" cols="12" md="2" class="py-4">
                                <!-- <v-card  class="shadow ">
                                    <v-card-title class="font-weight-regular pa-0 overline justify-center">
                                        TOTAL BETS
                                    </v-card-title>

                                    <v-card-text class="py-2 text-center" style="font-size:18px">
                                        {{ parseInt(betsTotal).toLocaleString() }}
                                    </v-card-text>
                                </v-card> -->

                                <v-card class="pa-1">
                                    <p class="font-weight-regular ma-0 overline">TOTAL BETS</p>
                                    <v-divider class="mb-4"/>
                                    <p class="font-weight-medium">{{ parseInt(betsTotal).toLocaleString() }}</p>
                                </v-card>
                            </v-col>

                            <v-col align="center" cols="12" md="2" class="py-4">
                                <!-- <v-card  class="shadow ">
                                    <v-card-title class="font-weight-regular pa-0 overline justify-center">
                                        TOTAL GROSS AMOUNT
                                    </v-card-title>

                                    <v-card-text class="py-2 text-center" style="font-size:18px">
                                        PHP {{ moneyFormat(parseFloat(grossAmountTotal)) }}
                                    </v-card-text>
                                </v-card> -->

                                <v-card class="pa-1">
                                    <p class="font-weight-regular ma-0 overline">TOTAL GROSS AMOUNT</p>
                                    <v-divider class="mb-4"/>
                                    <p class="font-weight-medium">PHP {{ moneyFormat(parseFloat(grossAmountTotal)) }}</p>
                                </v-card>
                            </v-col>

                            <v-col align="center" cols="12" md="2" class="py-4">
                                <!-- <v-card  class="shadow ">
                                    <v-card-title class="font-weight-regular pa-0 overline justify-center">
                                        TOTAL PAYOUT
                                    </v-card-title>

                                    <v-card-text class="py-2 text-center" style="font-size:18px">
                                        <span class="red--text">PHP {{ moneyFormat(parseFloat(payoutTotal)) }}</span> 
                                    </v-card-text>
                                </v-card> -->

                                <v-card class="pa-1">
                                    <p class="font-weight-regular ma-0 overline">TOTAL PAYOUT</p>
                                    <v-divider class="mb-4"/>
                                    <p class="font-weight-medium red--text">PHP {{ moneyFormat(parseFloat(payoutTotal)) }}</p>
                                </v-card>
                            </v-col>

                            <v-col align="center" cols="12" md="2" class="py-4">
                                <!-- <v-card>
                                    <v-card-title class="font-weight-regular pa-0 overline justify-center">
                                        TOTAL NET AMOUNT
                                    </v-card-title>

                                    <v-card-text class="py-2 text-center" style="font-size:18px">
                                        PHP {{ moneyFormat(parseFloat(netAmountTotal)) }}
                                    </v-card-text>
                                </v-card> -->

                                 <v-card class="pa-1">
                                    <p class="font-weight-regular ma-0 overline">TOTAL NET AMOUNT</p>
                                    <v-divider class="mb-4"/>
                                    <p class="font-weight-medium red--text">PHP {{ moneyFormat(parseFloat(netAmountTotal)) }}</p>
                                </v-card>
                            </v-col>

                            <v-col align="center" cols="12" md="2" class="py-4">
                                <!-- <v-card  class="shadow ">
                                    <v-card-title class="font-weight-regular pa-0 overline justify-center">
                                        TOTAL PAGCOR SHARE
                                    </v-card-title>

                                    <v-card-text class="py-2 text-center" style="font-size:18px">
                                        <span class="red--text">PHP {{ moneyFormat(parseFloat(pagcorShareTotal)) }}</span>
                                    </v-card-text>
                                </v-card> -->

                                <v-card class="pa-1">
                                    <p class="font-weight-regular ma-0 overline">TOTAL PAGCOR SHARE</p>
                                    <v-divider class="mb-4"/>
                                    <p class="font-weight-medium red--text">PHP {{ moneyFormat(parseFloat(pagcorShareTotal)) }}</p>
                                </v-card>
                            </v-col>

                            <v-col align="center" cols="12" md="2" class="py-4">
                                <!-- <v-card  class="shadow ">
                                    <v-card-title class="font-weight-regular pa-0 overline justify-center">
                                        TOTAL NET INCOME
                                    </v-card-title>

                                    <v-card-text class="py-2 text-center" style="font-size:18px">
                                        <span :class="(netIncomeTotal >= 0) ? 'green--text' : 'red--text'">PHP {{ moneyFormat(parseFloat(netIncomeTotal)) }}</span>
                                    </v-card-text>
                                </v-card> -->

                                <v-card class="pa-1">
                                    <p class="font-weight-regular ma-0 overline">TOTAL NET INCOME</p>
                                    <v-divider class="mb-4"/>
                                    <p class="font-weight-medium red--text" :class="(netIncomeTotal >= 0) ? 'green--text' : 'red--text'">PHP {{ moneyFormat(parseFloat(netIncomeTotal)) }}</p>
                                </v-card>
                            </v-col>
                        </v-row>
                    <!-- </v-card-text>
                </v-card> -->

                <v-row justify="space-between">
                    <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
                        <downloadexcel
                            :data="recordsPerGame" 
                            :fields="{
                                'Schedule Game': 'team',
                                'Bets': 'bets',
                                'Cards': 'cards',
                                'Bets': 'bets',
                                'Gross Sales': 'bets_amount',
                                'Payout': 'payout',
                                'Gross Gaming Revenue': 'net_amount',
                                'PAGCOR Share': 'pagcor_share',
                                'Net Amount': 'net_income',
                            }"
                            name="Cash In Report Per Game.xls"
                            type="csv"
                            class="d-inline-block">
                                <v-btn outlined color="primary" >
                                    Export CSV
                                </v-btn>
                        </downloadexcel>
                    </v-col>

                    <v-col cols="12" sm="6" md="4" xl="3">
                        <v-text-field
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            placeholder="Search"
                            single-line
                            hide-details="auto"
                            :disabled="recordsPerGameLoading"
                            clearable
                            outlined
                            dense>
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-divider class="mt-4"/>

                <v-data-table
                    :headers="headers"
                    :items="recordsPerGame"
                    :items-per-page="5"
                    :search="search"
                    :loading="recordsPerGameLoading"
                    loading-text="Loading, please wait a moment"
                    class="mt-4">

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    small
                                    color="primary"
                                    @click="showCards(item)"
                                    class="ma-1">
                                    <v-icon size="large"> mdi-cards </v-icon>
                                </v-btn>
                            </template>
                            <span>Show Cards</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:[`item.team`]="{ item }">
                        <v-avatar
                            size="36px"
                            tile
                        >
                            <v-img
                            :src="item.team_a_logo"
                            >
                            <template v-slot:placeholder>
                                <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                                >
                                <v-progress-circular
                                    indeterminate
                                    color="black"
                                ></v-progress-circular>
                                </v-row>
                            </template>
                            </v-img>
                        </v-avatar>
                        <span class="text-caption">{{ item.team_a_name }} {{ item.team_a_alias }}</span>
                        <span class="text-overline my-2 mx-2 font-italic">vs</span>
                        <v-avatar
                            size="36px"
                            tile
                        >
                            <v-img
                            :src="item.team_b_logo"
                            >
                            <template v-slot:placeholder>
                                <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                                >
                                <v-progress-circular
                                    indeterminate
                                    color="black"
                                ></v-progress-circular>
                                </v-row>
                            </template>
                            </v-img>
                        </v-avatar>
                        <span class="text-caption">{{ item.team_b_name }} {{ item.team_b_alias }}</span>
                    </template>

                    <template v-slot:[`item.bets`]="{ item }">
                        <span>{{ parseInt(item.bets).toLocaleString() }} </span>
                    </template>

                    <template v-slot:[`item.bets_amount`]="{ item }">
                        <span>PHP {{ moneyFormat(parseFloat(item.bets_amount)) }} </span>
                    </template>

                    <template v-slot:[`item.cards`]="{ item }">
                        <span>{{ parseInt(item.cards).toLocaleString() }} </span>
                    </template>

                    <template v-slot:[`item.payout`]="{ item }">
                        <span class="red--text">PHP {{ moneyFormat(parseFloat(item.payout)) }} </span>
                    </template>

                    <template v-slot:[`item.net_amount`]="{ item }">
                        <span>PHP {{ moneyFormat(parseFloat(item.net_amount)) }} </span>
                    </template>

                    <template v-slot:[`item.pagcor_share`]="{ item }">
                        <span class="red--text">PHP {{ moneyFormat(parseFloat(item.pagcor_share)) }} </span>
                    </template>

                    <template v-slot:[`item.net_income`]="{ item }">
                        <span :class="(item.net_income >= 0) ? 'green--text' : 'red--text' ">PHP {{ moneyFormat(parseFloat(item.net_income)) }} </span>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import downloadexcel from 'vue-json-excel'
import formRules from '@/helpers/formRules'
import { mapActions, mapState } from 'vuex'

export default {
    props: {
        cards_details: {
            type: Object
        }
    },

    components: {
        downloadexcel
    },

    data: () => ({
        valid: true,
        search: '',
        rules: formRules,
        date_from: null,
        date_to: null,
        date_from_menu: false,
        date_to_menu: false,
        game_status: 'All',
        game_statuses: ['All', 'Not Started', 'Ongoing', 'Finished'],
        game_type: '',
        game_types: ['Per Quarter', 'Traditional'],
        fullscreen_status: false,
        headers: [
            {
                text: "Action",
                value: "actions",
                class: "text-uppercase",
                align: "center" 
            },
            {
                text: "Scheduled Game",
                value: "team",
                class: "text-uppercase",
                align: "left" 
            },
            {
                text: "Cards",
                value: "cards",
                class: "text-uppercase",
                align: "right" 
            },
            { 
                text: "Bets", 
                value: "bets", 
                class: "text-uppercase",
                align: "right" 
            },
            {
                text: "Gross Sales",
                value: "bets_amount",
                class: "text-uppercase",
                align: "right" 
            },
            {
                text: "Payout",
                value: "payout",
                class: "text-uppercase",
                align: "right"
            },
            {
                text: "Gross Gaming Revenue",
                value: "net_amount",
                class: "text-uppercase",
                align: "right"
            },
            {
                text: "PAGCOR Share",
                value: "pagcor_share",
                class: "text-uppercase",
                align: "right"
            },
            {
                text: "Net Amount",
                value: "net_income",
                class: "text-uppercase",
                align: "right"
            },
        ],
    }),
    
    mounted() {
        if (Object.keys(this.cards_details).length) {
            if ( Object.prototype.hasOwnProperty.call(this.cards_details, 'game_status')) this.game_status = this.cards_details.game_status
            if ( Object.prototype.hasOwnProperty.call(this.cards_details, 'date_from')) this.date_from = this.cards_details.date_from
            if ( Object.prototype.hasOwnProperty.call(this.cards_details, 'date_to')) this.date_to = this.cards_details.date_to
            if ( Object.prototype.hasOwnProperty.call(this.cards_details, 'game_type')) this.game_type = this.cards_details.game_type

            this.doLoadRecords()
        }
    },

    computed: {
        ...mapState("ending_reports_cards", ["recordsPerGame", "recordsPerGameLoading"]),

        dialog: {
            get: function() {
                return true
            },
            set: function() {
                this.closeModal()
            }
        },

        lessThanDate() { 
            if (this.date_from && this.date_to) {
                if (new Date(this.date_to) < new Date(this.date_from)) {
                    return 'Must be higher or equal than date from.'
                } 
            } 
            
            return true
        },

        dateToAvailability() {
            if (this.date_from == '' || this.date_from == null) {
                return true
            }

            return false
        },

        cardsTotal() {
            if (this.recordsPerGame) {
                return this.recordsPerGame.reduce((total, obj) => parseInt(obj.cards) + total ,0)
            } else {
                return 0
            }
        },

        betsTotal() {
            if (this.recordsPerGame) {
                return this.recordsPerGame.reduce((total, obj) => parseInt(obj.bets) + total ,0)
            } else {
                return 0
            }
        },

        grossAmountTotal() {
            if (this.recordsPerGame) {
                return this.recordsPerGame.reduce((total, obj) => parseFloat(obj.bets_amount) + total ,0)
            } else {
                return 0
            }
        },

        payoutTotal() {
            if (this.recordsPerGame) {
                return this.recordsPerGame.reduce((total, obj) => parseFloat(obj.payout) + total ,0)
            } else {
                return 0
            }
        },

        netAmountTotal() {
            if (this.recordsPerGame) {
                return this.recordsPerGame.reduce((total, obj) => parseFloat(obj.net_amount) + total ,0)
            } else {
                return 0
            }
        },

        pagcorShareTotal() {
            if (this.recordsPerGame) {
                return this.recordsPerGame.reduce((total, obj) => parseFloat(obj.pagcor_share) + total ,0)
            } else {
                return 0
            }
        },

        netIncomeTotal() {
            if (this.recordsPerGame) {
                return this.recordsPerGame.reduce((total, obj) => parseFloat(obj.net_income) + total ,0)
            } else {
                return 0
            }
        },
    },

    methods: {
        ...mapActions("ending_reports_cards", ["getRecordsPerGame"]),

        closeModal() {
            this.$emit("setCardsDialogCloseModal")
        },

        moneyFormat(val) {
            return val.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        },

        async doLoadRecords() {
            this.$store.commit("ending_reports_cards/setRecordsPerGame", [])

            await this.getRecordsPerGame(this.setFormData())
        },

        async doGetRecordPerGame() {
            if (this.$refs.form.validate()) {
                if (!this.recordsPerGameLoading) {
                    this.$store.commit("ending_reports_cards/setRecordsPerGame", [])

                    await this.getRecordsPerGame(this.setFormData())
                }
            }
        },

        setFormData() {
            const formData = new FormData()

            formData.append("game_status", this.game_status)
            formData.append("date_from", this.date_from)
            formData.append("date_to", ((this.date_to) ?? this.date_from))
            formData.append("game_type", this.game_type)

            const getData = new URLSearchParams(formData) 

            return getData
        },

        showCards(item) {
             let route = this.$router.resolve({ name: 'CardsPerGame', params: { id: item.id, type_id: ((this.game_type == 'Traditional' ? 1 : 2))}})
            window.open(route.href);
        }
    },

    watch: {
        date_from (val) {
            if (val == '' || val == null) {
                this.date_to = ''
            }
        }
    }
}
</script>