<template>
  <v-dialog :fullscreen="!$vuetify.breakpoint.mdAndUp" persistent scrollable v-model="dialog" max-width="600">
    <v-card class="shadow-none d-flex flex-column">
      <v-card-title class="d-flex justify-space-between text-h6 font-weight-regular">
        <span>Confirm</span>

        <v-btn icon @click="close_dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- <v-alert dense v-if="approval_status" :type="approval_status" class="mx-2 my-2">
        {{ approval_status == 'success' ? 'Success' : 'Failed to approve' }}
      </v-alert> -->

      <v-card-text class="d-flex flex-column">
        <v-data-table :headers="headers" :items="payout_details_confirmation_data.selected" :search="search"
          :items-per-page="-1" :hide-default-footer="true">

          <template v-slot:top>
            <v-text-field ref="search" v-model="search" label="Search" single-line outlined dense autofocus
              placeholder="Search" hide-details="auto"/>
          </template>

          <template v-slot:[`item.payout`]="{ item }">
            <span>{{ `Php ${money_format(parseFloat(item.payout))}` }}</span>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ format_date(item.created_at) }}</span>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <div class="d-flex flex-column" style="gap:.75rem; width: 100%;">
          <v-text-field v-model="mode_of_payment" autofocus outlined dense label="Mode of payment (Optional)"
            placeholder="eg. GCash, Paymaya or any banks" hide-details="auto" />

          <v-text-field v-model="reference" outlined dense label="Reference (Optional)" placeholder="eg. 9812345678"
            hide-details="auto" />

          <v-textarea v-model="remarks" auto-grow outlined label="Remarks (Optional)" rows="2" row-height="20"
            placeholder="For additional info purposes" hide-details="auto" />

          <div class="d-flex" :class="$vuetify.breakpoint.mdAndUp ? 'justify-space-between align-end' : 'flex-column'"
            :style="$vuetify.breakpoint.mdAndUp ? 'gap: 0' : 'gap: .5rem'" style="width: 100%;">
            <span>Total Payouts <span class="success--text ">{{
              `Php ${money_format(parseFloat(payout_details_confirmation_data.total_payouts))}` }}</span></span>

            <div class="d-flex justify-end" style="gap:.30rem">
              <v-btn text dense color="black" @click="close_dialog" class="px-2">
                Cancel
              </v-btn>

              <v-btn depressed color="primary" class="px-2" :disabled="approval_status_loading || approval_status"
                :loading="approval_status_loading" @click="doApprovePayouts">Confirm </v-btn>
            </div>
          </div>
        </div>
      </v-card-actions>
    </v-card>

    <PayoutDetailsPrint v-if="payout_print_dialog" :payout_print_data="payout_print_data"
      @set_close_payout_print_dialog="set_close_payout_print_dialog" />
  </v-dialog>
</template>

<script>
import responsePost from '@/helpers/api_request_post'
import formatDatev2 from "@/helpers/formatDatev2"
import PayoutDetailsPrint from '@/components/investors/PayoutDetailsPrint'

export default {
  components: { PayoutDetailsPrint },

  props: {
    payout_details_confirmation_data: {
      type: Object
    }
  },

  data: () => ({
    search: '',
    total_payouts: 0.00,
    approval_data: [],
    approval_status: false,
    approval_status_loading: false,
    payout_print_dialog: false,
    payout_print_data: {},
    mode_of_payment: '',
    reference: '',
    remarks: '',
    headers: [
      {
        text: 'Name',
        align: 'start',
        value: 'name',
        class: "text-uppercase"
      },
      {
        text: 'Payout',
        align: 'right',
        value: 'payout',
        class: "text-uppercase"
      },
      {
        text: 'Date Won',
        align: 'created_at',
        value: 'created_at',
        class: "text-uppercase"
      },
    ]
  }),

  computed: {
    dialog: {
      get: function () {
        return true
      },
      set: function () {
        this.close_dialog()
      }
    }
  },

  methods: {
    async doApprovePayouts() {
      this.approval_status_loading = !this.approval_status_loading

      const response = await responsePost(this.set_form_data(), 'investor/approve')

      if (response.status == 200) {
        this.approval_status = !this.approval_status
        this.approval_data = response.data.records

        this.payout_print_data = {
          settlement_code: this.approval_data.settlement_id,
          total_payouts: this.payout_details_confirmation_data.total_payouts,
          selected: this.payout_details_confirmation_data.selected,
          min_date: this.format_date2(this.approval_data.min_date),
          max_date: this.format_date2(this.approval_data.max_date)
        }

        this.payout_print_dialog = !this.payout_print_dialog
      } else this.approval_status = 'error'

      this.approval_status_loading = !this.approval_status_loading
    },

    set_form_data() {
      const form_data = new FormData()

      form_data.append('investor_id', this.payout_details_confirmation_data.investor_id)
      form_data.append('payout_ids', JSON.stringify(this.payout_details_confirmation_data.compiled_ids))
      form_data.append('total_payouts', this.payout_details_confirmation_data.total_payouts)
      form_data.append('mode_of_payment', this.mode_of_payment)
      form_data.append('reference', this.reference)
      form_data.append('remarks', this.remarks)

      return form_data
    },

    set_close_payout_print_dialog() {
      this.payout_print_dialog = !this.payout_print_data
    },

    money_format(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    format_date(date) {
      return formatDatev2(date)
    },

    format_date2(date) {
      return formatDatev2(date, 'custom_date')
    },

    close_dialog() {
      this.$emit("set_close_payout_details_confirmation_dialog", this.approval_status)
    },
  },

  // watch: {
  //   approval_status(val) {
  //     switch (val) {
  //       case 'success':
  //         // setTimeout(() => {
  //         //   this.approval_status = ''
  //         //   this.$emit("set_close_payout_details_confirmation_dialog", true)
  //         // }, 2000)
  //         // break
  //         break
  //       case 'error':
  //         setTimeout(() => {
  //           this.approval_status = ''
  //         }, 5000)
  //         break
  //     }
  //   }
  // }
}
</script>