<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Lucky Four Games</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Prize Settings</h2>
    <v-card class="shadow">
      <v-card-title>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="secondary"
              @click="loadRecords"
              v-bind="attrs"
              v-on="on"
              class="mr-1"
            >
              <v-icon>mdi-cached</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center text-uppercase">Action</th>
                <th class="text-center text-uppercase">Jackpot Prize</th>
                <th class="text-center text-uppercase">Three Digits Prize</th>
                <th class="text-center text-uppercase">Initial Amount</th>
                <th class="text-center text-uppercase">
                  Prize Pool Percentage
                </th>
                <th class="text-center text-uppercase">Bet Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loading">
                <td colspan="6" class="text-center">Loading... Please wait.</td>
              </tr>
              <tr v-else v-for="item in records" :key="item.id">
                <td class="text-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        color="primary"
                        @click="setUpdateRecord(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon size="large"> mdi-pencil-outline </v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </td>
                <td class="text-right">
                  ₱{{ moneyFormat(parseFloat(item.jackpot_prize)) }}
                </td>
                <td class="text-right">
                  ₱{{ moneyFormat(parseFloat(item.three_digits_prize)) }}
                </td>
                <td class="text-right">
                  ₱{{ moneyFormat(parseFloat(item.initial_amount)) }}
                </td>
                <td class="text-center">
                  {{ moneyFormat(parseFloat(item.percentage_deduction)) }}
                </td>
                <td class="text-right">
                  ₱{{ moneyFormat(parseFloat(item.bet_amount)) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <div class="mt-5">
      <h3>Logs</h3>
    </div>

    <v-card>
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  @click="loadRecords"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="loading"
              clearable
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text class="pa-4">
        <v-data-table
          :headers="headers"
          :items-per-page="5"
          :search="search"
          :items="logs"
          :loading="loading"
          loading-text="Loading... Please wait"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.jackpot_prize`]="{ item }">
            <div class="text-right">
              <span>₱{{ moneyFormat(parseFloat(item.jackpot_prize)) }}</span>
            </div>
          </template>
          <template v-slot:[`item.three_digits_prize`]="{ item }">
            <div class="text-right">
              <span
                >₱{{ moneyFormat(parseFloat(item.three_digits_prize)) }}</span
              >
            </div>
          </template>
          <template v-slot:[`item.initial_amount`]="{ item }">
            <div class="text-right">
              <span>₱{{ moneyFormat(parseFloat(item.initial_amount)) }}</span>
            </div>
          </template>
          <template v-slot:[`item.percentage_deduction`]="{ item }">
            <div class="text-center">
              <span>{{
                moneyFormat(parseFloat(item.percentage_deduction))
              }}</span>
            </div>
          </template>
          <template v-slot:[`item.bet_amount`]="{ item }">
            <div class="text-right">
              <span>₱{{ moneyFormat(parseFloat(item.bet_amount)) }}</span>
            </div>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ formatTheDate(item.created_at) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog scrollable v-model="dialog" max-width="600">
      <v-card>
        <v-card-title> Update Record </v-card-title>

        <v-divider />

        <v-card-text class="pa-4">
          <v-alert type="info" class="py-2" dark
            >Settings will be applied to upcoming games.</v-alert
          >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleSubmit"
          >
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  ref="jackpotPrize"
                  v-model="jackpotPrize"
                  label="Jackpot Prize"
                  :rules="[rules.required, rules.decimal]"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  ref="threeDigitsPrize"
                  v-model="threeDigitsPrize"
                  label="Three Digits Prize"
                  :rules="[rules.required, rules.decimal]"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  ref="initialAmount"
                  v-model="initialAmount"
                  label="Initial Amount"
                  :rules="[rules.required, rules.decimal]"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  ref="percentageDeduction"
                  v-model="percentageDeduction"
                  label="Percentage Deduction"
                  :rules="[rules.required, rules.decimal]"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  ref="betAmount"
                  v-model="betAmount"
                  label="Bet Amount"
                  :rules="[rules.required, rules.decimal]"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="pl-2 pr-2 mb-2">
              <v-col cols="12" md="6" class="pa-1">
                <v-btn
                  block
                  type="submit"
                  depressed
                  :loading="loading"
                  color="primary"
                >
                  Update
                </v-btn>
              </v-col>

              <v-col cols="12" md="6" class="pa-1">
                <v-btn block depressed @click="dialog = false"> CANCEL </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formRules from "@/helpers/formRules";
import formatDate from "@/helpers/formatDate";

export default {
  data: () => ({
    search: "",
    sortBy: "id",
    sortDesc: true,
    headers: [
      {
        text: "Jackpot Prize",
        value: "jackpot_prize",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Three Digits Prize",
        value: "three_digits_prize",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Initial Amount",
        value: "initial_amount",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Percentage Deduction",
        value: "percentage_deduction",
        class: "text-uppercase",
        align: "center",
      },
      {
        text: "Bet Amount",
        value: "bet_amount",
        class: "text-uppercase",
        align: "right",
      },
      {
        text: "Date Updated",
        value: "created_at",
        class: "text-uppercase",
        align: "center",
      },
    ],

    dialog: false,
    rules: formRules,
    valid: true,
    id: "",
    jackpotPrize: "",
    threeDigitsPrize: "",
    initialAmount: "",
    percentageDeduction: "",
    betAmount: "",
  }),

  created() {
    this.loadRecords();
  },

  computed: {
    ...mapState("lucky_four_prizes_settings", [
      "loading",
      "records",
      "logs",
      "error",
    ]),
  },

  methods: {
    ...mapActions("lucky_four_prizes_settings", [
      "getRecords",
      "updateRecord",
      "getLogs",
    ]),

    loadRecords() {
      this.$store.commit("lucky_four_prizes_settings/setRecords", []);
      this.$store.commit("lucky_four_prizes_settings/setLogs", []);
      this.getRecords();
      this.getLogs();
    },

    setUpdateRecord(item) {
      this.dialog = true;
      this.id = item.id;
      this.jackpotPrize = item.jackpot_prize;
      this.threeDigitsPrize = item.three_digits_prize;
      this.initialAmount = item.initial_amount;
      this.percentageDeduction = item.percentage_deduction;
      this.betAmount = item.bet_amount;
    },

    async handleSubmit() {
      if (this.$refs.form.validate()) {
        if (!this.loading) {
          const formData = new FormData();
          formData.append("id", this.id);
          formData.append("jackpotPrize", this.jackpotPrize);
          formData.append("threeDigitsPrize", this.threeDigitsPrize);
          formData.append("initialAmount", this.initialAmount);
          formData.append("percentageDeduction", this.percentageDeduction);
          formData.append("betAmount", this.betAmount);

          await this.updateRecord(formData);

          if (!this.error) {
            const recordData = this.records.find(
              (record) => record.id == this.id
            );

            recordData.jackpot_prize = this.jackpotPrize;
            recordData.three_digits_prize = this.threeDigitsPrize;
            recordData.initial_amount = this.initialAmount;
            recordData.percentage_deduction = this.percentageDeduction;
            recordData.bet_amount = this.betAmount;

            this.getLogs();
            this.dialog = false;
          } else {
            alert("Something went wrong!");
          }
        }
      }
    },

    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    formatTheDate(theDate) {
      return formatDate(theDate, true);
    },
  },
};
</script>
