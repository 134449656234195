var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 py-2 font-weight-regular grey--text text--darken-1"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-seal-variant ")]),_vm._v(" Winners (Slot "+_vm._s(_vm.filters.slot)+") "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","absolute":"","right":""},on:{"click":_vm.closeModal}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")])],1)]}}])},[_c('span',[_vm._v("Close")])])],1),_c('v-divider'),_c('v-card-text',{staticClass:"py-4"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('downloadexcel',{staticClass:"d-inline-block",attrs:{"data":_vm.winners,"fields":{
                            'Bet Type': 'bet_type',
                            'Name': 'name',
                            'Bet Amount': 'bet_amount',
                            'Total Payout': 'total_payout',
                        },"name":"Combination Winners.xls","type":"csv"}},[_c('v-btn',{attrs:{"outlined":"","color":"primary"}},[_vm._v(" Export CSV ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search","single-line":"","hide-details":"auto","clearable":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-4",attrs:{"align":"center","cols":"12","md":"4"}},[_c('v-card',{staticClass:"pa-1"},[_c('p',{staticClass:"font-weight-regular ma-0 overline"},[_vm._v(" TOTAL NUMBER OF WINNERS ")]),_c('v-divider',{staticClass:"mb-4"}),_c('p',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(parseInt(_vm.winners.length).toLocaleString())+" ")])],1)],1),_c('v-col',{staticClass:"py-4",attrs:{"align":"center","cols":"12","md":"4"}},[_c('v-card',{staticClass:"pa-1"},[_c('p',{staticClass:"font-weight-regular ma-0 overline"},[_vm._v(" TOTAL BET AMOUNT ")]),_c('v-divider',{staticClass:"mb-4"}),_c('p',{staticClass:"font-weight-medium"},[_vm._v(" PHP "+_vm._s(_vm.moneyFormat(parseFloat(_vm.totalBetAmount)))+" ")])],1)],1),_c('v-col',{staticClass:"py-4",attrs:{"align":"center","cols":"12","md":"4"}},[_c('v-card',{staticClass:"pa-1"},[_c('p',{staticClass:"font-weight-regular ma-0 overline"},[_vm._v(" TOTAL PAYOUTS ")]),_c('v-divider',{staticClass:"mb-4"}),_c('p',{staticClass:"font-weight-medium"},[_vm._v(" PHP "+_vm._s(_vm.moneyFormat(parseFloat(_vm.totalPayouts)))+" ")])],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.winners,"items-per-page":5,"search":_vm.search,"loading":_vm.winnersLoading,"loading-text":"Loading, please wait a moment"},scopedSlots:_vm._u([{key:"item.bet_amount",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v("PHP "+_vm._s(_vm.moneyFormat(parseFloat(item.bet_amount)))+" ")])]}},{key:"item.total_payout",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v("PHP "+_vm._s(_vm.moneyFormat(parseFloat(item.total_payout)))+" ")])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }