<template>
  <v-dialog v-model="confirmationDialog" max-width="360">
    <v-card class="text-center">
      <v-icon large color="info" class="mt-3">mdi-information-outline</v-icon>

      <v-card-title class="text-h6">
        {{ title }}
      </v-card-title>

      <v-card-text> {{ text }} </v-card-text>

      <v-card-actions>
        <v-row dense>
          <v-col>
            <v-btn
              :color="closeBtnColor"
              class="text-capitalize"
              @click="confirmationDialog = false"
              block
            >
              {{ closeBtnText }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              :color="saveBtnColor"
              class="text-capitalize"
              @click="onSubmit"
              block
            >
              {{ saveBtnText }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["confirmationData"],

  data: () => ({
    title: null,
    text: null,
    closeBtnText: null,
    closeBtnColor: null,
    saveBtnText: null,
    saveBtnColor: null,
  }),

  mounted() {
      this.title = this.confirmationData.title;
      this.text = this.confirmationData.text;
      this.closeBtnText = this.confirmationData.closeBtnText;
      this.closeBtnColor = this.confirmationData.closeBtnColor;
      this.saveBtnText = this.confirmationData.saveBtnText;
      this.saveBtnColor = this.confirmationData.saveBtnColor;
  },

  computed: {
    confirmationDialog: {
      get: function () {
        return true;
      },
      set: function () {
        this.$emit("closeConfirmationDialog");
      },
    },
  },

  methods: {
    onSubmit() {
      this.$emit("onSubmit");
    },
  },
};
</script>