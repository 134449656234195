<template>
  <div class="pa-4 pa-md-6">
    <div class="grey--text" style="font-size: 13px">Ending Game</div>
    <h2 class="font-weight-bold mb-3 mb-md-5">Leagues</h2>
    <v-card class="shadow">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12" sm="6" md="4" xl="2" align-self="end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="createLeague"
                >
                  <v-icon>mdi-plus-box-outline</v-icon>
                </v-btn>
              </template>

              <span>Create League</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  @click="setLeagues"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                >
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="secondary" v-bind="attrs" v-on="on">
                  <v-icon>mdi-file-export-outline</v-icon>
                </v-btn>
              </template>
              <span>Export to EXCEL</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" sm="6" md="4" xl="3">
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              single-line
              hide-details="auto"
              :disabled="loading"
              clearable
              outlined
              dense
            >
              <!-- <template v-slot:append-outer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      color="secondary"
                      @click="filter"
                      v-on="on"
                      small
                    >
                      <v-icon>mdi-filter-variant</v-icon>
                    </v-btn>
                  </template>
                  Filter
                </v-tooltip>
              </template> -->
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="leagues"
          :items-per-page="5"
          :search="search"
          :loading="loading"
          loading-text="Loading, please wait a moment"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  color="primary"
                  @click="
                    setLeagueData(
                      item.league_id,
                      item.league,
                      item.logo,
                      item.is_local,
                      item.is_active
                    )
                  "
                >
                  <v-icon size="large"> mdi-square-edit-outline </v-icon>
                </v-btn>
              </template>
              <span>Update</span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.logo`]="{ item }">
            <div>
              <v-avatar size="36px" tile>
                <v-img :src="item.logo">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        color="black"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </div>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ setCreatedAtFormat(item.created_at) }}</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <FormDialog
      v-if="dialogStatus"
      :leagueData="leagueData"
      class="mr-1"
      @setData="setData"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import formatDatev2 from "@/helpers/formatDatev2";
import FormDialog from "@/components/ending/leagues/FormDialog";

export default {
  components: {
    FormDialog,
  },
  data: () => ({
    dialogStatus: false,
    search: "",
    headers: [
      {
        text: "Action",
        sortable: false,
        value: "action",
        class: "text-uppercase",
        align: "center",
      },
      // { text: "ID", value: "league_id", align: "left" },
      {
        text: "League",
        sortable: false,
        value: "league",
        class: "text-uppercase",
      },
      {
        text: "Logo",
        sortable: false,
        value: "logo",
        class: "text-uppercase",
      },
      {
        text: "Is Local",
        sortable: false,
        value: "is_local",
        class: "text-uppercase",
      },
      {
        text: "Created At",
        value: "created_at",
        class: "text-uppercase",
      },
    ],
    sortBy: "league_id",
    sortDesc: true,
    leagueData: {},
  }),
  created() {
    this.setLeagues();
  },
  computed: {
    ...mapState("ending_leagues", ["loading", "leagues", "error", "errors"]),
  },
  methods: {
    ...mapActions("ending_leagues", ["getLeagues"]),
    setLeagues() {
      this.getLeagues();
    },
    createLeague() {
      this.dialogStatus = true;
      this.leagueData = {};
    },
    setLeagueData(league_id, league, logo, is_local, is_active, created_at) {
      this.dialogStatus = true;
      this.leagueData = { league_id, league, logo, is_local, is_active, created_at };
    },
    setData(insertedData, updatedData) {
      this.dialogStatus = false;

      if (Object.keys(insertedData).length != 0) {
        this.$store.commit("ending_leagues/setLeagues", [insertedData, ...this.leagues]);
      } else if (Object.keys(updatedData).length != 0) {
        const leagueData = this.leagues.find(
          (league) => league.league_id == updatedData.league_id
        );

        leagueData.league_id = updatedData.league_id;
        leagueData.league = updatedData.league;
        leagueData.logo = updatedData.logo;
        leagueData.is_local = updatedData.is_local;
        leagueData.is_active = updatedData.is_active;
      }
    },
    setCreatedAtFormat(date) {
      return formatDatev2(date);
    },
  },
};
</script>
