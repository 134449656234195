<template>
  <PolarArea
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    style="width: 100%; height: 435px"
  />
</template>

<script>
import { PolarArea } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadialLinearScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, RadialLinearScale);

export default {
  name: "PolarAreaChart",
  components: {
    PolarArea,
  },
  props: {
    chartId: {
      type: String,
      default: "polar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    cssClasses: {
      default: "",
      type: String,
    },
    plugins: {
      type: Array,
      default: () => [
        {
          beforeInit(chart) {
            // Get a reference to the original fit function
            const originalFit = chart.legend.fit;

            // Override the fit function
            chart.legend.fit = function fit() {
              // Call the original function and bind scope in order to use `this` correctly inside it
              originalFit.bind(chart.legend)();
              // Change the height as suggested in other answers
              this.height += 24;
            };
          },
        },
      ],
    },
    theChartData: {
      type: Object,
    },
  }, //"#B3E5FC"
  data() {
    return {
      chartData: {
        labels: [
          "Gross Sales",
          "Payouts",
          "Gross Gaming Revenue",
          "PAGCOR Share",
          "Net Amount",
        ],
        datasets: [
          {
            label: "Gross Sales",
            backgroundColor: [
              "rgba(171, 71, 188, 0.1)",
              "rgba(236, 64, 122, 0.1)",
              "rgba(255, 167, 38, 0.1)",
              "rgba(92, 107, 192, 0.1)",
              "rgba(102, 187, 106, 0.1)",
            ],
            pointBackgroundColor: "rgba(179,181,198,1)",
            borderColor: [
              "rgb(171, 71, 188)",
              "rgb(236, 64, 122)",
              "rgb(255, 167, 38)",
              "rgb(92, 107, 192)",
              "rgb(102, 187, 106)",
            ],
            borderWidth: 1,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(179,181,198,1)",
            data: [
              this.theChartData.game_total_bet_amount,
              this.theChartData.game_total_prizes,
              this.theChartData.net_amount,
              this.theChartData.pagcor_share,
              this.theChartData.net_income,
            ],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scale: {
          display: false,
        },
        plugins: {
          legend: {
            display: true,
            position: "top",
            labels: {
              font: {
                size: 13,
                family: "Poppins",
              },
              padding: 16,
              boxWidth: 12,
              boxHeight: 12,
            },
          },
        },
      },
    };
  },
};
</script>
